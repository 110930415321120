import { Component, OnInit } from "@angular/core";
import { DialogConfig } from "libs/framwork/dialog/src/lib/dialog-config";
import { DialogRef } from "libs/framwork/dialog/src/lib/dialog-ref";
import { Router } from "@angular/router";
import { FormGroup } from "@angular/forms";

@Component({
  selector: "pg-dialog",
  templateUrl: "./dialog-container.component.html",
  styleUrls: ["./dialog-container.component.css"]
})
export class DialogContainerComponent implements OnInit {
  parentComponentData: string;
  formGroup: FormGroup;

  dialogSetting: string;
  constructor(
    public dialogData: DialogConfig,
    private dialogRef: DialogRef,
    public router: Router
  ) {}

  ngOnInit(): void {
    this.parentComponentData = this.dialogData.data.value;
  }

  onClose() {
    this.dialogRef.close(true);
  }

  onSave() {
    this.dialogRef.close(false);
  }
}
