<form [formGroup]="frmGroup" *ngIf="!isDependentDropdown">
  <label *ngIf="fieldInfo.showLabel" [fieldInfo]="fieldInfo" acForLable>
    {{ fieldInfo.label }}<span *ngIf="fieldInfo.validation?.required">*</span>
  </label>
  <hcc-custom-validation [validations]="fieldInfo.validation">
    <ng-select
      [readonly]="InputConfig?.isDisabled || isDependentDropdown"
      [items]="InputConfig.selectConfig.options"
      inputValidateRef
      [appendTo]="InputConfig?.selectConfig?.appendTo"
      [bindLabel]="InputConfig.selectConfig.optionConfig.displayKey"
      [control]="control"
      [formGroup]="frmGroup"
      [formControlName]="control"
      [multiple]="InputConfig.multiple"
      [placeholder]="getPlaceHolder()"
      tabindex="0"
      [clearable]="true"
      [selectOnTab]="true"
      [virtualScroll]="true"
    ></ng-select>
    <!-- <ngx-select-dropdown
      tabindex="0"
      inputValidateRef
      [control]="control"
      [formGroup]="frmGroup"
      [multiple]="InputConfig.multiple"
      [formControlName]="control"
      [options]="InputConfig.selectConfig.options"
      [config]="InputConfig.selectConfig.optionConfig"
      [(_value)]="InputConfig.selectConfig.selectedOption"
      (change)="change($event.value)"
    ></ngx-select-dropdown> -->
  </hcc-custom-validation>
</form>
