import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { Router, NavigationStart } from "@angular/router";

import { OktaAuthService, OKTA_CONFIG, OktaConfig } from "@okta/okta-angular";

//import * as OktaSignIn from "@okta/okta-signin-widget";
// @ts-ignore
import OktaSignIn from '@okta/okta-signin-widget';
import { AppConfigService } from "@tmhcc/global-services";
import { getBaseUrl } from "libs/util/string-util";

@Component({
  selector: "app-secure",
  template: `
    <!-- Container to inject the Sign-In Widget -->
    <div id="okta-signin-container"></div>
  `
})
export class OktaLoginComponent implements OnDestroy {
  signIn;
  widget;

  constructor(
    private oktaAuth: OktaAuthService,
    private router: Router,
    protected configService: AppConfigService,
    @Inject(OKTA_CONFIG) protected config: OktaConfig
  ) {
    this.widget = new OktaSignIn({
      baseUrl: getBaseUrl(this.config.issuer), //'https://dev-652851.okta.com',
      //loginRedirect: this.config.redirectUri,
      redirectUri: this.config.redirectUri,
      logo: "/assets/images/logo-tmhcc.svg",      
      scopes: [
        "openid",
        "email",
        "profile",
        "address",
        "phone",
        "offline_access"
      ],
      authParams: {
        pkce: false,
        issuer: this.config.issuer,
        //onSessionExpired: () => {}
      }
    }); 
    this.signIn = oktaAuth;
  }

  async ngOnInit() {
    document.getElementById("topheader").classList.add("hide");
    const authenticated = await this.signIn.isAuthenticated();
    if (authenticated) {
      await this.oktaAuth.logout("/login");
    } else {
      this.widget.renderEl(
        {
          el: "#okta-signin-container"
        },
        res => {
          //debugger;
          if (res.status === "SUCCESS") {
            console.log("login sccessfully");
            let url = sessionStorage.getItem("okta-app-url");
            //url = (this.configService.config.baseHref || '') + (url || '');
            url = (url || '');
            this.signIn.loginRedirect(url, {
              sessionToken: res.session.token
            });
            // Hide the widget
            this.widget.hide();
          }
        },
        err => {
          throw err;
        }
      );
    }
  }
  ngOnDestroy() {
    this.widget.remove();
  }
}
