import { Routes, Router } from "@angular/router";
import { OktaAuthGuard } from "@tmhcc/framwork/okta-login";
import { SaveChangeGaurd } from "@tmhcc/global-services";
import { PreloadMasterDataGuard } from "./preload-master-data.guard";

export function onAuthRequired(oktaAuth, injector) {
  // Redirect the user to your custom login page
  const router = injector.get(Router);
  router.navigate(["/login"]);
}

const routes: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then(m => m.DashboardModule),
    // canActivate: [PreloadMasterDataGuard]
    //canActivate: [OktaAuthGuard, PreloadMasterDataGuard],
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: "info",
    loadChildren: () =>
      import("./quote/general-info/general-info.module").then(
        m => m.GeneralInfoModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: "events",
    loadChildren: () =>
      import("./quote/events/events.module").then(m => m.EventsModule),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: "quote",
    loadChildren: () =>
      import("./quote/quote-step/quote-step.module").then(
        m => m.QuoteStepModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: "subjectivity",
    loadChildren: () =>
      import("./quote/subjectivity/subjectivity.module").then(
        m => m.SubjectivityModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: "bind",
    loadChildren: () =>
      import("./quote/bind/bind.module").then(m => m.BindModule),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: "bind-summary",
    loadChildren: () =>
      import("./quote/bind-summary/bind-summary.module").then(
        m => m.BindSummaryModule
      ),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: "policy-forms",
    loadChildren: () =>
      import("./quote/forms/forms.module").then(m => m.FormsModule),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: "document",
    loadChildren: () =>
      import("./quote/document/document.module").then(m => m.DocumentModule),
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  },
  {
    path: "knr-dashboard",
    loadChildren: () =>
      import("./knr-dashboard/knr-dashboard.module").then(m => m.KnrDashboardModule),    
    canActivate: [OktaAuthGuard],
    data: {
      onAuthRequired
    }
  }  
];

export const EcoRoutes = routes;
