import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { throwError } from "rxjs/internal/observable/throwError";

export abstract class BaseHttpService {
  constructor(protected http: HttpClient) {}

  protected returnObservableForStaticData<T>(data: T): Observable<T> {
    return new Observable(observer => {
      observer.next(data);
      observer.complete();
    });
  }

  protected handleError(err) {
    return throwError(err);
  }
}
