import { DateService } from "@tmhcc/framwork/services";
import { FieldTypes } from "@tmhcc/framwork/form-inputs";
import { EventGridRow } from "@tmhcc/models";
import { DialogConfig } from "@tmhcc/framwork/dialog";
import { FormType } from "./form-type.enum";
import { Country, StateOrProvince } from "@tmhcc/data-service";
import { CommonContentService, Labels } from "@tmhcc/content-service";
import { Validators } from "@angular/forms";
import { DateValidator } from "./date-validator";
import {
  UserConfigurableDataElements,
  DataElementRespons
} from "@tmhcc/data-service/def/eco-master";

export interface EventDialogConfigData {
  eventGridRow?: EventGridRow;
  countryList?: CountryData[];
  stateList?: StateData[];
  policyType?: PolicyData[];
  isDialogForEdit?: boolean;
  value?: any;
  addedEditedEvents?: boolean;
  openAsAlert?: boolean;
}

interface PolicyData {
  name?: string;
  id?: number;
}

interface StateData {
  name?: string;
  id?: string;
}

interface CountryData {
  name?: string;
  id?: string;
}

export class EventDialogFieldConfig {
  defaultCountry: string;
  mindate: Date;
  defaultInitialValue =
    (this.dialogData.data && this.dialogData.data.eventGridRow) ||
    new EventGridRow();
  getLabels: Labels;
  constructor(
    private dateService: DateService,
    public dialogData: DialogConfig<EventDialogConfigData>,
    public contentService: CommonContentService
  ) {
    this.mindate = new Date();
    this.mindate.setDate(this.mindate.getDate() + 1);
    this.getLabels = this.contentService.getLabels();
  }

  newEventFieldConfig() {
    const seperator = " : ";
    return {
      eventDetailsFields: [
        {
          config: {
            value: this.defaultInitialValue.eventType || "",
            selectConfig: {
              options: this.dialogData.data.policyType,
              optionConfig: {
                displayKey: "name",
                search: true,
                limitTo: 3
              },
              multiple: false
            },
            optionField: 1,
            validation: [Validators.required]
          },
          fieldInfo: {
            fieldName: "eventType",
            label: this.getLabels.eventType + seperator,
            fieldType: FieldTypes.LookupList,
            showLabel: true,
            value: this.defaultInitialValue.eventType || "",
            validation: {
              required: this.getLabels.eventTypeRequiredValidationLbl
            }
          },
          colClass: "col-md-6"
        },
        {
          config: {
            value: this.defaultInitialValue.eventDescription || "",
            optionField: 1
          },
          fieldInfo: {
            fieldName: "eventDescription",
            label: this.getLabels.eventDescription + seperator,
            decimal: 0,
            fieldType: FieldTypes.Text,
            showLabel: true,
            value: this.defaultInitialValue.eventDescription || ""
          },
          colClass: "col-md-6"
        },
        {
          config: {
            value: this.defaultInitialValue.eventVenue || "",
            optionField: 2,
            validation: [Validators.required]
          },
          fieldInfo: {
            fieldName: "eventVenue",
            label: this.getLabels.eventVenue + seperator,
            decimal: 0,
            fieldType: FieldTypes.Text,
            showLabel: true,
            value: this.defaultInitialValue.eventVenue || "",
            validation: {
              required: this.getLabels.eventVenueRequiredValidationLbl
            }
          },
          colClass: "col-md-6"
        },
        {
          config: {
            value: this.defaultInitialValue.address || "",
            optionField: 2,
            validation: [Validators.required]
          },
          fieldInfo: {
            fieldName: "address",
            label: this.getLabels.address + seperator,
            decimal: 0,
            fieldType: FieldTypes.Text,
            showLabel: true,
            value: this.defaultInitialValue.address || "",
            validation: {
              required: this.getLabels.addressRequiredValidationLbl
            }
          },
          colClass: "col-md-6"
        },
        {
          config: {
            value: this.defaultInitialValue.city || "",
            optionField: 3,
            validation: [Validators.required]
          },
          fieldInfo: {
            value: this.defaultInitialValue.city || "",
            fieldName: "city",
            label: this.getLabels.city + seperator,
            decimal: 0,
            fieldType: FieldTypes.Text,
            showLabel: true,
            validation: {
              required: this.getLabels.cityRequiredValidationLbl
            }
          },
          colClass: "col-md-6 col-lg-3"
        },
        {
          config: {
            value: this.defaultInitialValue.state || "",
            selectConfig: {
              selectedOption: [],
              options: this.dialogData.data.stateList,
              optionConfig: {
                displayKey: "name",
                search: true,
                clearOnSelection: true
              },
              multiple: false
            },
            optionField: 3
          },
          fieldInfo: {
            fieldName: "state",
            value: this.defaultInitialValue.state || "",
            label: this.getLabels.state + seperator,
            fieldType: FieldTypes.LookupList,
            showLabel: true
          },
          colClass: "col-md-6 col-lg-3"
        },
        {
          config: {
            value: this.defaultInitialValue.zipCode || "",
            optionField: 3
          },
          fieldInfo: {
            fieldName: "zipCode",
            label: this.getLabels.zipCode + seperator,
            value: this.defaultInitialValue.zipCode || "",
            decimal: 0,
            fieldType: FieldTypes.Text,
            showLabel: true
          },
          colClass: "col-md-6 col-lg-3"
        },
        {
          config: {
            value: this.defaultInitialValue.country || "",
            selectConfig: {
              options: this.dialogData.data.countryList,
              optionConfig: {
                displayKey: "name",
                search: true
                // limitTo: 3
              },
              multiple: false
            },
            optionField: 3,
            validation: [Validators.required]
          },
          fieldInfo: {
            fieldName: "country",
            label: this.getLabels.country + seperator,
            fieldType: FieldTypes.LookupList,
            value: this.defaultInitialValue.country || "",
            showLabel: true,
            validation: {
              required: this.getLabels.countryRequiredValidationLbl
            }
          },
          colClass: "col-md-6 col-lg-3"
        },
        {
          config: {
            value: this.defaultInitialValue.eventStartDate
              ? this.dateService.toShortDate(
                  this.dateService.moment(
                    this.defaultInitialValue.eventStartDate.toISOString()
                  )
                )
              : "",
            datePickerConfig: {
              dateInputFormat: this.dateService.getFormat(),
              isCalanderRequired: true,
              showWeekNumbers: false,
              // minDate: this.mindate,
              outSideClick: false,
              adaptivePosition: true,
              isAnimated: true,
              placement: "top",
              container: "body"
            },
            optionField: 4,
            validation: [Validators.required]
          },
          fieldInfo: {
            fieldName: "eventStartDate",
            label: this.getLabels.eventStartDate + seperator,
            decimal: 0,
            fieldType: FieldTypes.Date,
            showLabel: true,
            value: this.defaultInitialValue.eventStartDate
              ? this.dateService.toShortDate(
                  this.dateService.moment(
                    this.defaultInitialValue.eventStartDate.toISOString()
                  )
                )
              : "",
            validation: {
              required: this.getLabels.esdRequiredValidationLbl,
              pastDate: this.getLabels.esdPastDateValidationLbl,
              invalidDate: this.getLabels.esdInvalidDateValidationLbl
            }
          },
          colClass: "col-md-6 col-lg-3"
        },
        {
          config: {
            value: this.defaultInitialValue.eventEndDate
              ? this.dateService.toShortDate(
                  this.dateService.moment(
                    this.defaultInitialValue.eventEndDate.toISOString()
                  )
                )
              : "",
            datePickerConfig: {
              dateInputFormat: this.dateService.getFormat(),
              isCalanderRequired: true,
              // minDate: this.mindate,
              outSideClick: false,
              adaptivePosition: true,
              isAnimated: true,
              placement: "top",
              container: "body"
            },
            optionField: 4,
            validation: [Validators.required]
          },
          fieldInfo: {
            fieldName: "eventEndDate",
            label: this.getLabels.eventEndDate + seperator,
            decimal: 0,
            fieldType: FieldTypes.Date,
            value: this.defaultInitialValue.eventEndDate
              ? this.dateService.toShortDate(
                  this.dateService.moment(
                    this.defaultInitialValue.eventEndDate.toISOString()
                  )
                )
              : "",
            showLabel: true,
            validation: {
              required: this.getLabels.eedRequiredValidationLbl,
              pastDate: this.getLabels.eedPastDateValidationLbl,
              invalidDate: this.getLabels.eedInvalidDateValidationLbl
            }
          },
          colClass: "col-md-6 col-lg-3"
        },
        {
          config: {
            value: this.defaultInitialValue.eventLimitOfInsurance || "",
            optionField: 4,
            numericConfig: {
              type: "currency"
            },
            validation: [Validators.required, Validators.pattern(/^\d*\.?\d*$/)]
          },
          fieldInfo: {
            fieldName: "eventLimitOfInsurance",
            label: this.getLabels.eventLimitInsurance + seperator,
            decimal: 0,
            fieldType: FieldTypes.Numeric,
            value: this.defaultInitialValue.eventLimitOfInsurance || "",
            showLabel: true,
            validation: {
              required: this.getLabels.limitofInsRequiredValidationLbl,
              pattern: "Invalid Event Limit of Insurance"
            }
          },
          colClass: "col-md-6 col-lg-3"
        },
        {
          config: {
            value: this.defaultInitialValue.eventDeductible || "",
            optionField: 4,
            numericConfig: {
              type: "currency"
            },
            validation: [Validators.pattern(/^\d*\.?\d*$/)]
          },
          fieldInfo: {
            fieldName: "eventDeductible",
            label: this.getLabels.eventDeductible + seperator,
            decimal: 0,
            fieldType: FieldTypes.Numeric,
            value: this.defaultInitialValue.eventDeductible || "",
            showLabel: false,
            validation: {
              pattern: "Invalid Event Deductible",
              exceedPercent: "Percentage can not exceed 100",
              radioSelection: "Please select value or percentage"
            }
          },
          colClass: "col-md-6 col-lg-3"
        },
        //---
        {
          config: {
            value: this.defaultInitialValue.revenue || "",
            optionField: 4,
            numericConfig: {
              type: "currency"
            },
            validation: [Validators.pattern(/^\d*\.?\d*$/)]
          },
          fieldInfo: {
            fieldName: "revenue",
            label: this.getLabels.eventRevenueLbl + seperator,
            decimal: 0,
            fieldType: FieldTypes.Numeric,
            value: this.defaultInitialValue.revenue || "",
            showLabel: true            
          },
          colClass: "col-md-6 col-lg-3"
        },
        {
          config: {
            value: this.defaultInitialValue.expenses || "",
            optionField: 4,
            numericConfig: {
              type: "currency"
            },
            validation: [Validators.pattern(/^\d*\.?\d*$/)]
          },
          fieldInfo: {
            fieldName: "expenses",
            label: this.getLabels.eventExpenseLbl + seperator,
            decimal: 0,
            fieldType: FieldTypes.Numeric,
            value: this.defaultInitialValue.expenses || "",
            showLabel: true            
          },
          colClass: "col-md-6 col-lg-3"
        },
        {
          config: {
            value: this.defaultInitialValue.insPersSpkr1 || "",
            optionField: 4           
          },
          fieldInfo: {
            fieldName: "insPersSpkr1",
            label: this.getLabels.eventInsuredPersonLbl + seperator,
            decimal: 0,
            fieldType: FieldTypes.Text,
            value: this.defaultInitialValue.insPersSpkr1 || "",
            showLabel: true          
          },
          colClass: "col-md-6"
        }        
        //--//
      ],
      //--
      additionalPersSpkr : [
        {
          config: {
            value: this.defaultInitialValue.insPersSpkr2 || "",
            optionField: 2          
          },
          fieldInfo: {
            fieldName: "insPersSpkr2",
            label: this.getLabels.eventInsuredPersonLbl + seperator,
            decimal: 0,
            fieldType: FieldTypes.Text,
            value: this.defaultInitialValue.insPersSpkr2 || "",
            showLabel: true          
          },
          colClass: "col-md-6"
        },
        {
          config: {
            value: this.defaultInitialValue.insPersSpkr3 || "",
            optionField: 2           
          },
          fieldInfo: {
            fieldName: "insPersSpkr3",
            label: this.getLabels.eventInsuredPersonLbl + seperator,
            decimal: 0,
            fieldType: FieldTypes.Text,
            value: this.defaultInitialValue.insPersSpkr3 || "",
            showLabel: true          
          },
          colClass: "col-md-6"
        },
        {
          config: {
            value: this.defaultInitialValue.insPersSpkr4 || "",
            optionField: 2         
          },
          fieldInfo: {
            fieldName: "insPersSpkr4",
            label: this.getLabels.eventInsuredPersonLbl + seperator,
            decimal: 0,
            fieldType: FieldTypes.Text,
            value: this.defaultInitialValue.insPersSpkr4 || "",
            showLabel: true          
          },
          colClass: "col-md-6"
        },
        {
          config: {
            value: this.defaultInitialValue.insPersSpkr5 || "",
            optionField: 2          
          },
          fieldInfo: {
            fieldName: "insPersSpkr5",
            label: this.getLabels.eventInsuredPersonLbl + seperator,
            decimal: 0,
            fieldType: FieldTypes.Text,
            value: this.defaultInitialValue.insPersSpkr5 || "",
            showLabel: true          
          },
          colClass: "col-md-6"
        }
      ], //--
      entryLevelFields: [
        {
          fieldName: "baseRate",
          label: this.getLabels.baseRate,
          fieldType: FieldTypes.Numeric,
          required: true,
          value: this.defaultInitialValue.baseRate || "",
          placeholder: "%",
          optionalField: {
            rowNumber: 1,
            isLabelEditable: false
          },
          validation: {
            required: this.getLabels.baseRateRequiredLbl,
            pattern: this.getLabels.baseRatePatternLbl
          }
        },
        {
          fieldName: "windstorm",
          label: this.getLabels.windstorm,
          fieldType: FieldTypes.Numeric,
          placeholder: "%",
          value: this.defaultInitialValue.windstorm || "",
          optionalField: {
            rowNumber: 1,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "strike",
          label: this.getLabels.strike,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.strike || "",
          optionalField: {
            rowNumber: 1,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "earthquake",
          label: "Earthquake",
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.earthquake || "",
          optionalField: {
            rowNumber: 2,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "winterWeather",
          label: this.getLabels.winterWeather,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.winterWeather || "",
          optionalField: {
            rowNumber: 2,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "wildfire",
          label: this.getLabels.wildfire,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.wildfire || "",
          optionalField: {
            rowNumber: 2,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "csTornadoHail",
          label: this.getLabels.csTornadoHail,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.csTornadoHail || "",
          optionalField: {
            rowNumber: 3,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "civilCommotion",
          label: this.getLabels.civilCommotion,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.civilCommotion || "",
          optionalField: {
            rowNumber: 3,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "cyber",
          label: this.getLabels.cyber,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.cyber || "",
          optionalField: {
            rowNumber: 3,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "era",
          label: this.getLabels.era,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.era || "",
          optionalField: {
            rowNumber: 4,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "nationalMounting",
          label: this.getLabels.nationalMounting,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.nationalMounting || "",
          optionalField: {
            rowNumber: 4,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "limitedTerrorism",
          label: this.getLabels.limitedTerrorism,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.limitedTerrorism || "",
          optionalField: {
            rowNumber: 5,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "tria",
          label: this.getLabels.tria,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.tria || "",
          optionalField: {
            rowNumber: 5,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        // {
        //   fieldName: "terrorism",
        //   label: this.getLabels.terrorism,
        //   fieldType: FieldTypes.Text,
        //   placeholder: "%",
        //   value: this.defaultInitialValue.terrorism || "",
        //   optionalField: {
        //     rowNumber: 5,
        //     isLabelEditable: false
        //   },
        //   validation: {
        //     pattern: "Invalid"
        //   }
        // },
        {
          fieldName: "threat",
          label: this.getLabels.threat,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.threat || "",
          optionalField: {
            rowNumber: 5,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        }
        // {
        //   fieldName: "nucBioChemTerrorism",
        //   label: this.getLabels.nucBioChemTerrorism,
        //   fieldType: FieldTypes.Text,
        //   placeholder: "%",
        //   value: this.defaultInitialValue.nucBioChemTerrorism || "",
        //   optionalField: {
        //     rowNumber: 5,
        //     isLabelEditable: false
        //   },
        //   validation: {
        //     pattern: "Invalid"
        //   }
        // }
      ],
      additionalPerilsFields: [
        {
          fieldName: "communicableDisease",
          label: this.getLabels.communicableDisease,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.communicableDisease || "",
          optionalField: {
            rowNumber: 1,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "limitedWar",
          label: this.getLabels.limitedWar,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.limitedWar || "",
          optionalField: {
            rowNumber: 1,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "additionalPerilLabel1",
          label: this.getLabels.additionalPerilLabel1,
          fieldType: FieldTypes.Text,
          value:
            this.defaultInitialValue.additionalPerilLabel1 ||
            "Additional Peril 1 :",
          optionalField: {
            rowNumber: 1,
            isLabelEditable: true,
            editableType: FormType.LABEL
          }
        },
        {
          fieldName: "additionalPerilValue1",
          label: this.getLabels.additionalPerilValue1,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.additionalPerilValue1 || "",
          optionalField: {
            rowNumber: 1,
            isLabelEditable: true,
            editableType: FormType.VALUE
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "war",
          label: this.getLabels.war,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.war || "",
          optionalField: {
            rowNumber: 2,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "nuclearHazard",
          label: this.getLabels.nuclearHazard,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.nuclearHazard || "",
          optionalField: {
            rowNumber: 2,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "additionalPerilLabel2",
          label: this.getLabels.additionalPerilLabel2,
          fieldType: FieldTypes.Text,
          value:
            this.defaultInitialValue.additionalPerilLabel2 ||
            "Additional Peril 2 :",
          optionalField: {
            rowNumber: 2,
            isLabelEditable: true,
            editableType: FormType.LABEL
          }
        },
        {
          fieldName: "additionalPerilValue2",
          label: this.getLabels.additionalPerilValue2,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.additionalPerilValue2 || "",
          optionalField: {
            rowNumber: 2,
            isLabelEditable: true,
            editableType: FormType.VALUE
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "nonAppearance",
          label: this.getLabels.nonAppearance,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.nonAppearance || "",
          optionalField: {
            rowNumber: 3,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "bioChemTerrIncl",
          label: this.getLabels.bioChemTerrIncl,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.bioChemTerrIncl || "",
          optionalField: {
            rowNumber: 3,
            isLabelEditable: false
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "additionalPerilLabel3",
          label: this.getLabels.additionalPerilLabel3,
          fieldType: FieldTypes.Text,
          value:
            this.defaultInitialValue.additionalPerilLabel3 ||
            "Additional Peril 3 :",
          optionalField: {
            rowNumber: 3,
            isLabelEditable: true,
            editableType: FormType.LABEL
          },
          validation: {
            pattern: "Invalid"
          }
        },
        {
          fieldName: "additionalPerilValue3",
          label: this.getLabels.additionalPerilValue3,
          fieldType: FieldTypes.Text,
          placeholder: "%",
          value: this.defaultInitialValue.additionalPerilValue3 || "",
          optionalField: {
            rowNumber: 3,
            isLabelEditable: true,
            editableType: FormType.VALUE
          },
          validation: {
            pattern: "Invalid"
          }
        }
      ],
      eventDeductibleRadioField: [
        {
          fieldName: "eventDeductibleType",
          label: "Value",
          fieldType: FieldTypes.Radio,
          showLabel: false
        },
        {
          fieldName: "eventDeductibleType",
          label: "%",
          fieldType: FieldTypes.Radio,
          showLabel: false
        }
      ]
    };
  }

  getStateData(stateList: StateOrProvince[]) {
    let stateData = [];
    stateList.forEach((state: StateOrProvince) => {
      if (state.Description && state.Description.trim() !== "") {
        stateData.push({
          name: state.Description,
          id: state.CountryCode
        });
      }
    });
    return stateData;
  }

  getCountryData(countryList: Country[]): any[] {
    let countryData = [];
    countryList.forEach((country: Country) => {
      if (country.Description) {
        countryData.push({
          name: country.Description,
          id: country.Id
        });
      }
    });
    countryData.sort((a: any, b: any) => a.name.localeCompare(b.name));
    return countryData;
  }

  updateStateList(countryCode: string) {
    const stateData = this.dialogData.data;
    if (stateData && stateData.stateList.length !== 0) {
      stateData.stateList.filter(value => {
        if (value.id === countryCode) {
          return true;
        } else {
          return false;
        }
      });
    }
  }

  setDefaultCountry(countryCode: string): CountryData {
    let defaultCountry: CountryData;
    this.dialogData.data.countryList.forEach((country: CountryData) => {
      if (country.id === countryCode) {
        defaultCountry = country;
      }
    });
    return defaultCountry;
  }

  getPolicyType(elements: UserConfigurableDataElements[]) {
    let policyData: PolicyData[] = [];
    if (elements && elements.length !== 0) {
      const { DataElementResponses }: UserConfigurableDataElements =
        elements.find((element: UserConfigurableDataElements) => {
          return element.ElementCode === "EventType";
        }) || null;
      if (DataElementResponses && DataElementResponses.length !== 0) {
        DataElementResponses.forEach((eventType: DataElementRespons) => {
          policyData.push({
            name: eventType.ResponseValue,
            id: eventType.DataElementResponseId
          });
        });
      }
    }
    return policyData;
  }
}
