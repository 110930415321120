export class SubjectivitiesDataResponse {
    constructor(
        public subjectivities: Subjectivities[]
    ) { }
}
export class Subjectivities{
    id:string;
    value:string;
}    

export class KnRSubjectivityGridRow {
    policySubjectivityId: number;
    description: string;
    checked: boolean;
    isBlank: boolean;
    constructor() {}
  }
  
  export class KnRSubjectivityGrid {
    subjectivityData: KnRSubjectivityGridRow[];
  }
  
  export class KnRSaveMiscellaneousChangesModel {
    PolicyTxnId: number;
    PolicyId: number;
    PolicyNum?: string;
    PolicyTxnNum: number;
    TxnStatusCode?: string;
  }
  