import { Types } from "@tmhcc/framwork/grid";
import { DialogSize } from "@tmhcc/framwork/dialog";
import { DateService } from "@tmhcc/framwork/services";
import { EventGridColumns } from "./events.component";
import { EventDeductibleType } from "@tmhcc/models";

export class EventConfig {
  hideHeaderX: any;
  constructor(public dateService: DateService) {}

  gridSettingConfig = {
    pageLength: 10,
    searching: false,
    paging: true,
    ordering: true
  };

  eventColumnSettingsConfig = [
    {
      fieldInfo: {
        fieldName: "eventType",
        label: "EVENT TYPE"
      },
      columnType: Types.default
    },
    {
      fieldInfo: {
        fieldName: "eventDescription",
        label: "EVENT NAME"
      },
      columnType: Types.link
    },
    {
      fieldInfo: {
        fieldName: "eventVenue",
        label: "EVENT VENUE"
      },
      columnType: Types.default
    },
    {
      fieldInfo: {
        fieldName: "startDate",
        label: "START DATE"
      },
      columnType: Types.default
    },
    {
      fieldInfo: {
        fieldName: "endDate",
        label: "END DATE"
      },
      columnType: Types.default
    },
    {
      fieldInfo: {
        fieldName: "limitOfInsurance",
        label: "LIMIT OF INSURANCE"
      },
      columnType: Types.currency
    }
  ];

  dialogModalSettingConfig = {
    title: "Event Details",
    overflow: true,
    hideFooter: true,
    dialogSize: DialogSize.XL
  };

  dialogModalSettingConfigForDelete = {
    title: "Delete confirmation",
    overflow: true,
    hideFooter: true,
    dialogSize: DialogSize.LG
  };

  dialogModalSettingConfigForValidate = {
    title: "Invalid record found",
    overflow: true,
    hideFooter: true,
    dialogSize: DialogSize.LG,
    hideSave: true
  };
  dialogModalSettingConfigForDirtycheck = {
    title: "",
    overflow: true,
    hideFooter: true,
    dialogSize: DialogSize.LG,
    hideSave: true
  };
  dialogQuoteVersionModalSetting = {
    title: "Warning!",
    hideHeader: false,
    hideFooter: true,
    hideSave: true,
    hideHeaderX:true,
    SaveButton: "Yes",
    CancelButton: "Ok",
    dialogSize: DialogSize.LG
  };
  dialogCopyalertModalSetting={
    title: "Warning!",
    hideHeader: false,
    hideFooter: true,
    hideSave: true,
    hideHeaderX:false,
    SaveButton: "Yes",
    CancelButton: "Ok",
    dialogSize: DialogSize.LG
  }

  validateSpreadsheetConfig(
    value: EventGridColumns,
    invalidRecordList: number[]
  ) {
    if (!value.address) {
      invalidRecordList.push(value.index);
      return;
    }
    if (!value.eventType) {
      invalidRecordList.push(value.index);
      return;
    }
    if (!value.eventVenue) {
      invalidRecordList.push(value.index);
      return;
    }
    if (value.eventType && !value.eventType[0]) {
      invalidRecordList.push(value.index);
      return;
    }
    if (!value.city) {
      invalidRecordList.push(value.index);
      return;
    }
    if (!value.country) {
      invalidRecordList.push(value.index);
      return;
    }
    if (!value.limitOfInsurance) {
      invalidRecordList.push(value.index);
      return;
    }
    if (!value.country) {
      invalidRecordList.push(value.index);
      return;
    }
    if (
      value.eventDeductibleType === EventDeductibleType.PERCENTAGE &&
      value.eventDeductible > 100
    ) {
      invalidRecordList.push(value.index);
      return;
    }
    if (!value.baseRate) {
      invalidRecordList.push(value.index);
      return;
    }
    // if (!value.startDate || this.dateService.isBeforeToday(value.startDate)) {
    //   invalidRecordList.push(value.index);
    //   return;
    // }
    // if (!value.endDate || this.dateService.isBeforeToday(value.endDate)) {
    //   invalidRecordList.push(value.index);
    //   return;
    // }
    if (!value.startDate || !value.endDate || this.dateService.isBeforeDate(value.endDate, value.startDate)) {
      invalidRecordList.push(value.index);
      return;
    }
  }
}

export enum RiskResponseType {
  FreeTextResponse = "TXT",
  MoneyResponse = "MNY",
  DateResponse = "DT ",
  ChooseOneComboResponse = "SLT",
  DecimalResponse = "DEC",
  CombinationResponse = "CMB"
}

export const RiskModel = {
  additionalPerilValue1: {
    name: "RatingInput",
    type: RiskResponseType.DecimalResponse,
    isAP: true,
    isAPLabel: false,
    rowNum: 1
  },
  additionalPerilValue2: {
    name: "RatingInput",
    type: RiskResponseType.DecimalResponse,
    isAP: true,
    isAPLabel: false,
    rowNum: 2
  },
  additionalPerilValue3: {
    name: "RatingInput",
    type: RiskResponseType.DecimalResponse,
    isAP: true,
    isAPLabel: false,
    rowNum: 3
  },
  additionalPerilLabel1: {
    name: "AP1",
    type: RiskResponseType.FreeTextResponse,
    isAP: true,
    isAPLabel: true,
    rowNum: 1
  },
  additionalPerilLabel2: {
    name: "AP1",
    type: RiskResponseType.FreeTextResponse,
    isAP: true,
    isAPLabel: true,
    rowNum: 2
  },
  additionalPerilLabel3: {
    name: "AP1",
    type: RiskResponseType.FreeTextResponse,
    isAP: true,
    isAPLabel: true,
    rowNum: 3
  },
  address: {
    name: "VenueAdd",
    type: RiskResponseType.FreeTextResponse
  },
  baseRate: {
    name: "BaseRate",
    type: RiskResponseType.DecimalResponse
  },
  bioChemTerrIncl: {
    name: "BioChemTerr",
    type: RiskResponseType.DecimalResponse
  },
  city: {
    name: "VenueCty",
    type: RiskResponseType.FreeTextResponse
  },
  civilCommotion: {
    name: "Civil Commotion",
    type: RiskResponseType.DecimalResponse
  },
  communicableDisease: {
    name: "Communicable Disease",
    type: RiskResponseType.DecimalResponse
  },
  country: {
    name: "Country",
    type: RiskResponseType.FreeTextResponse
  },
  csTornadoHail: {
    name: "CSTH",
    type: RiskResponseType.DecimalResponse
  },
  cyber: {
    name: "Cyber",
    type: RiskResponseType.DecimalResponse
  },
  earthquake: {
    name: "Earthquake",
    type: RiskResponseType.DecimalResponse
  },
  era: {
    name: "ERA",
    type: RiskResponseType.DecimalResponse
  },
  eventDeductible: {
    name: "EventDed",
    type: RiskResponseType.CombinationResponse
  },
  eventDescription: {
    name: "EventDesc",
    type: RiskResponseType.FreeTextResponse
  },
  eventEndDate: {
    name: "EndDt",
    type: RiskResponseType.DateResponse
  },
  eventLimitOfInsurance: {
    name: "EventLimit",
    type: RiskResponseType.MoneyResponse
  },
  eventStartDate: {
    name: "StartDt",
    type: RiskResponseType.DateResponse
  },
  eventType: {
    name: "EventType",
    type: RiskResponseType.ChooseOneComboResponse
  },
  eventVenue: {
    name: "VenueNm",
    type: RiskResponseType.FreeTextResponse
  },
  limitedTerrorism: {
    name: "LimitedTerrorism",
    type: RiskResponseType.DecimalResponse
  },
  limitedWar: {
    name: "LimitedWar",
    type: RiskResponseType.DecimalResponse
  },
  nationalMounting: {
    name: "NationalMounting",
    type: RiskResponseType.DecimalResponse
  },
  nonAppearance: {
    name: "NonAppearance",
    type: RiskResponseType.DecimalResponse
  },
  // nucBioChemTerrorism: {
  //   name: "NucBioChemTerrorism",
  //   type: RiskResponseType.DecimalResponse
  // },
  threat: {
    name: "Threat",
    type: RiskResponseType.DecimalResponse
  },
  nuclearHazard: {
    name: "NuclearHazard",
    type: RiskResponseType.DecimalResponse
  },
  state: {
    name: "VenueSt",
    type: RiskResponseType.FreeTextResponse
  },
  strike: {
    name: "Strike",
    type: RiskResponseType.DecimalResponse
  },
  // terrorism: {
  //   name: "Terrorism",
  //   type: RiskResponseType.DecimalResponse
  // },
  tria: {
    name: "TRIA",
    type: RiskResponseType.DecimalResponse
  },
  war: {
    name: "War",
    type: RiskResponseType.DecimalResponse
  },
  wildfire: {
    name: "Wildfire",
    type: RiskResponseType.DecimalResponse
  },
  windstorm: {
    name: "Windstorm",
    type: RiskResponseType.DecimalResponse
  },
  winterWeather: {
    name: "WinterWeather",
    type: RiskResponseType.DecimalResponse
  },
  zipCode: {
    name: "VenueZp",
    type: RiskResponseType.FreeTextResponse
  },
  revenue: {
    name: "Revenue",
    type: RiskResponseType.MoneyResponse
  },
  expenses: {
    name: "Expenses",
    type: RiskResponseType.MoneyResponse
  },
  insPersSpkr1: {
    name: "InsPersSpkr",
    type: RiskResponseType.FreeTextResponse,
    rowNum: 1
  },
  insPersSpkr2: {
    name: "InsPersSpkr",
    type: RiskResponseType.FreeTextResponse,
    rowNum: 2
  },
  insPersSpkr3: {
    name: "InsPersSpkr",
    type: RiskResponseType.FreeTextResponse,
    rowNum: 3
  },
  insPersSpkr4: {
    name: "InsPersSpkr",
    type: RiskResponseType.FreeTextResponse,
    rowNum: 4
  }, 
  insPersSpkr5: {
    name: "InsPersSpkr",
    type: RiskResponseType.FreeTextResponse,
    rowNum: 5
  }
};
