import { AppConfigService } from "libs/global-services";
import { createGuid } from "libs/util/uuid";
import { isEmpty, isAnyEmpty } from "libs/util/null-util";
import { environment } from 'apps/policy-admin/src/environments/environment';

export function getWindow(): any {
  return window;
}

export function initApp(appName: string, configService: AppConfigService) {
  return (): Promise<any> => {
    return configService.init().then(() => {
      if (isEmpty(appName)) {
        console.log("Application Name is empty.");
      }
      if (isEmpty(environment.appConfig)) {
        console.log("Application config not found.");
      }

      const config = environment.appConfig;

      if (appName !== config.appName) {
        console.log(
          "APP NAME does not match." + appName + " vs " + config.appName
        );
      }

      if (
        isAnyEmpty(
          config.appKey,
          config.dataServiceBaseUrl,
          //config.dataServiceApiKey
        )
      ) {
        console.log("Application config is incomplete.");
      }
    });
  };
}

export function getTimeStamp(): string {
  return new Date().getTime().toString();
}

export function getSessionId(): string {
  return createGuid();
}

export const getBaseUri = (): string => {
  return window.location.origin;
};
