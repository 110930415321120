<div class="container">
  <ec-page-header></ec-page-header>
  <div class="row">
    <div class="col-md-12 ">
      <ec-quote-policy-info></ec-quote-policy-info>
      <ec-page-tab></ec-page-tab>
      <div class="clearfix"></div>
      <form [formGroup]="frmGroup" [attr.disabled]="isPolicyBind">
        <div class="row">
          <div class="col-12 col-md-12 mb-3 mt-3">
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div class="form-row mt-4 mb-2">
                  <div class="col-md-8 col-lg-5">
                    <h5 class="header-tab">
                      {{ getLabels.QuptOptionPageHeading }}
                    </h5>
                  </div>
                  <div class="col-md-4 col-lg-2 mb-3">
                    <div class="quoteVersionList" *ngIf="isQuoteVersion">
                      <hcc-select
                        [control]="selectConfig.fieldInfo.fieldName"
                        [frmGroup]="frmGroup"
                        [fieldInfo]="selectConfig.fieldInfo"
                        [InputConfig]="selectConfig.config"
                      >
                      </hcc-select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12 col-lg-8 bindVersion">
                    <div *ngIf="showOptionTable">
                      <ac-grid-table
                        (childAllMatrixData)="slectedQuoteOption($event)"
                        [parentDivName]="'QuoteVersion'"
                        [gridSetting]="gridSetting"
                        [records]="recordsList"
                        [columnSettings]="columnSettings"
                        [isCustomizedGrid]="true"
                      ></ac-grid-table>
                    </div>
                  </div>
                  <div class="col-md-12 col-lg-4 mt-1">
                    <ec-policy-summary
                      [currentUWQuoteID]="currentUWQuoteID"
                    ></ec-policy-summary>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <hr class="divider-dark" />
  <div class="button-row">
    <button type="button" (click)="backButtonPressed()" class="btn btn-primary">
      Back
    </button>
    <button
      type="button"
      (click)="saveAndRefresh()"
      class="btn btn-primary"
      [disabled]="isPolicyBind"
    >
      SAVE &amp; REFRESH
    </button>

    <button type="button" (click)="saveNext()" class="btn btn-primary">
      Next
    </button>
  </div>
</div>
