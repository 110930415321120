<form [formGroup]="frmGroup">
  <label *ngIf="fieldInfo.showLabel" [fieldInfo]="fieldInfo" acForLable>
    {{ fieldInfo.label }}<span *ngIf="fieldInfo.validation?.required">*</span>
  </label>

  <hcc-custom-validation
    *ngIf="fieldInfo.validation; else withoutValidation"
    [validations]="fieldInfo.validation"
  >
    <div class="input-group date-field nopadding">
      <input
        type="text"
        class="form-control input-sm"
        placement="bottom"
        [value]="value"
        inputValidateRef
        [control]="control"
        [formGroup]="frmGroup"
        [bsConfig]="getDatePickerConfig()"
        [isOpen]="isCalanderOpen"
        [maxDate]="InputConfig.datePickerConfig.maxDate"
        [minDate]="InputConfig.datePickerConfig.minDate"
        #dp="bsDatepicker"
        hccDateFormat
        (bsValueChange)="onChange($event)"
        bsDatepicker
        [formControlName]="control"
        [readOnly]="InputConfig?.isDisabled"
      />
      <div
        class="input-group-append"
        *ngIf="InputConfig.datePickerConfig.isCalanderRequired"
      >
        <span class="input-group-text pointer" (click)="calanderClicked()">
          <span class="fas fa-calendar-alt"></span>
        </span>
      </div>
    </div>
  </hcc-custom-validation>

  <ng-template #withoutValidation>
    <div class="input-group date-field nopadding">
      <input
        type="text"
        class="form-control input-sm"
        placement="bottom"
        [value]="value"
        inputValidateRef
        [control]="control"
        [formGroup]="frmGroup"
        [bsConfig]="getDatePickerConfig()"
        [isOpen]="isCalanderOpen"
        [maxDate]="InputConfig.datePickerConfig.maxDate"
        [minDate]="InputConfig.datePickerConfig.minDate"
        #dp="bsDatepicker"
        hccDateFormat
        (bsValueChange)="onChange($event)"
        bsDatepicker
        [formControlName]="control"
        [readOnly]="InputConfig?.isDisabled"
      />
      <div
        class="input-group-append"
        *ngIf="InputConfig.datePickerConfig.isCalanderRequired"
      >
        <span class="input-group-text pointer" (click)="calanderClicked()">
          <span class="fas fa-calendar-alt"></span>
        </span>
      </div>
    </div>
  </ng-template>
</form>
