import { Component, OnInit } from "@angular/core";
import { QuoteStepStoreService } from "../../quote/store/facade/quote-step-store.service";
import { QuotePolicyStepStore } from "../../quote/store/state/quote-policy-step.state";

@Component({
  selector: "ec-quote-policy-info",
  templateUrl: "./quote-policy-info.component.html",
  styleUrls: ["./quote-policy-info.component.scss"]
})
export class QuotePolicyInfoComponent implements OnInit {
  quotePolicyLabel: string;
  constructor(private quoteStepStoreService: QuoteStepStoreService) {}
  quotePolicyStepStore: QuotePolicyStepStore;
  policyNumber: Number;
  insuredName: string;
  brokerName: string;
  policyType: string;
  brokerId: number;
  insuredContactId: number;

  ngOnInit(): void {
    this.quoteStepStoreService.data$.subscribe((data: QuotePolicyStepStore) => {
      this.quotePolicyStepStore = data;
      if (
        this.quotePolicyStepStore &&
        this.quotePolicyStepStore.policyTxnData
      ) {
        if (
          this.quotePolicyStepStore.policyTxnData.ProcessStatusCode == "*" ||
          this.quotePolicyStepStore.policyTxnData.ProcessStatusCode == "I"
        ) {
          this.quotePolicyLabel = "Policy Number";
        } else {
          this.quotePolicyLabel = "Quote Number";
        }
        this.policyNumber = this.quotePolicyStepStore.policyTxnData.PolicyNum;
        this.policyType = this.quotePolicyStepStore.policyTxnData.PolicyTypeDescription;
        this.brokerId = this.quotePolicyStepStore.policyTxnData.PolicyProducerContactId;
        this.insuredContactId = this.quotePolicyStepStore.policyTxnData.InsuredContactId;
        let policyContact = this.quotePolicyStepStore.policyTxnData
          .PolicyContacts;
        policyContact.forEach(items => {
          if (items.ContactId == this.insuredContactId) {
            this.insuredName = items.FullName;
          }
          if (items.ContactId == this.brokerId) {
            this.brokerName = items.FullName;
          }
        });
      }
    });
  }
}
