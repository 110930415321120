<!-- <div class="breadcrumb-progress">
  <div class="progress-line"></div>
  <div
    class="breadcrumb-element"
    *ngFor="let element of entity.titles; let i = index"
  >
    <div class="progress-box" [ngClass]="getClasses(i)">{{ i + 1 }}</div>
    <div class="progress-text">{{ element }}</div>
  </div>
</div> -->
<div class="search-step">
  <ul>
    <li
      [ngClass]="getClasses(i)"
      *ngFor="let element of entity.titles; let i = index"
    >
      <a
        href="javascript:void(0);"
        [ngStyle]="{
          cursor: !(entity?.isDisabled)[i] ? 'pointer' : 'not-allowed'
        }"
        (click)="stepNavigate(entity, i)"
      >
        {{ i + 1 }}
        <span>{{ element }}</span>
      </a>
    </li>
  </ul>
</div>
