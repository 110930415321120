import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  forwardRef,
  OnChanges,
  Output,
  SimpleChanges
} from "@angular/core";

import {
  FormArray,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ControlContainer,
  FormGroupDirective
} from "@angular/forms";
import { CustomService } from "../shared/grid-form.service";
import { GridDefinition, GridOptions } from "../shared/grid.interface";
import { Subject, BehaviorSubject } from "rxjs";
import { CoreInputValidationService } from "@tmhcc/framwork/core-inputs";
import { DialogConfig, DialogService } from "@tmhcc/framwork/dialog";
@Component({
  selector: "ac-custom-grid",
  templateUrl: "./custom-grid-table.component.html",
  styleUrls: ["./custom-grid-table.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomGridTableComponent),
      multi: true
    }
  ]
})
export class CustomGridTableComponent implements OnInit, OnChanges {
  @Input() gridForm: FormGroup;
  @Input() GridDefinition: GridDefinition;
  @Input() GridSetting: GridOptions;
  @Input() tableLabel?: string;
  @Input() parentSubject: Subject<boolean>;
  @Output() isGridFormValid: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  dtOptions: DataTables.Settings = {};
  customForm: FormGroup;
  validForm: boolean;
  genderList: any;
  dummyList: any;
  selectedValuesList: any = [];

  constructor(
    private validationService: CoreInputValidationService,
    private customService: CustomService,
    private dialogConfig: DialogConfig,
    private dialogService: DialogService,
    public controlContainer: ControlContainer,
    public parentForm: FormGroupDirective
  ) {}

  ngOnInit() {
    this.genderList = ["02/11/2014", "02/11/2015"];
    this.dtOptions = this.customService.inputTableOptions();
    if (this.GridDefinition.Rows.length > 0) {
      this.setServiceData();
    }

    this.parentForm.form.valueChanges.subscribe(data => {});
    this.addGroupToParent();
    this.gridForm.valueChanges.subscribe(value => {
      console.log(value, "====grid form value");
    });

    this.parentSubject.subscribe(event => {
      if (event) {
        this.saveData();
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.GridDefinition.currentValue != undefined) {
      let rows = <FormArray>this.parentForm.form.get("tableRows");
      this.parentForm.form.get("tableRows");
    }
  }

  clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0);
    }
  };

  private addGroupToParent(): void {}

  setServiceData() {
    let gridData = this.GridDefinition.Rows;
    let gridColumnData = this.GridDefinition.Columns;
    gridData.forEach(element => {
      this.tableRows.unshift(
        this.customService.populateExistItem(element, gridColumnData)
      );
    });
  }

  addRowInForm() {
    this.tableRows.unshift(
      this.customService.createRowItem(this.GridDefinition.Columns)
    );
    this.validationService.setFormSubmission(false);
  }

  deleteElementFromForm(index: number) {
    (<FormArray>this.parentForm.form.get("tableRows")).removeAt(index);
  }

  openAddPopup(data) {
    // const accordionPopupData = {
    //   value: data
    // };
    // this.dialogConfig.data = accordionPopupData;
    // this.dialogConfig.modalSetting = {
    //   title: 'Setup Reserve',
    //   hideHeader: false,
    //   hideFooter: true,
    //   hideSave: false,
    //   hideHeaderX: false
    // };
    // const ref = this.dialogService.open(
    //   ClaimPolicySetupComponent,
    //   this.dialogConfig
    // );
    // ref.afterClosed.subscribe(result => {
    //   if (result != undefined) {
    //     this.RowData.unshift({
    //       Lob: result.Lob.Description,
    //       Risk: result.Risk.Description,
    //       CoverageType: result.CoverageType.CovDesc,
    //       Reserve: result.Reserve.Description,
    //       Currency: result.Currency.Description,
    //       ReserveAmt: result.ReserveAmt
    //     });
    //     this.SetupRreserveGridDefinition = {
    //       Columns: this.ColumnDefinition,
    //       Rows: this.RowData,
    //       RowsPerPage: 10
    //     };
    //     this.resetChildForm();
    //   } else {
    //     return;
    //   }
    // });
  }

  async deleteIndividualRow(index: number) {
    this.deleteElementFromForm(index);
    document.getElementById("table-row-" + index).style.visibility = "collapse";
  }

  get tableRows() {
    return (<FormArray>this.parentForm.form.get("tableRows")).controls;
  }

  saveData() {
    let dataArray = [];
    this.parentForm.form.controls.tableRows["controls"].forEach(item =>
      dataArray.push(item.value)
    );
    this.parentForm.form.value.tableRows = dataArray;

    if (
      this.parentForm.form.controls.tableRows["controls"] == undefined ||
      this.parentForm.form.controls.tableRows["controls"].length == 0
    ) {
      this.validForm = true;
    } else {
      for (let a of this.parentForm.form.controls.tableRows["controls"]) {
        if (a.valid) {
          this.validForm = true;
        } else {
          this.validForm = false;
          this.validationService.setFormSubmission(true);
          break;
        }
      }
    }
    if (this.validForm) {
      this.isGridFormValid.next(true);
    } else {
      this.isGridFormValid.next(false);
      this.parentForm.form.controls["tableRows"]["controls"].forEach(
        (item, index) => {
          Object.keys(
            this.parentForm.form.controls["tableRows"]["controls"][index][
              "controls"
            ]
          ).forEach(field => {
            const control = this.parentForm.form.controls["tableRows"][
              "controls"
            ][index].get(field);
            if (control !== null) {
              control.markAsTouched();
            }
          });
        }
      );
    }
  }

  saveSelectedValues(values) {
    // console.log('====', values);
    alert(JSON.stringify(values));
  }

  selectedValues(data) {
    console.log("====", data);
  }
}
