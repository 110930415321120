import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ec-knr-dashboard',
  templateUrl: './knr-dashboard.component.html',
  styleUrls: ['./knr-dashboard.component.scss']
})
export class KnrDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    var element = document.getElementById("bannerCaption");
    element.innerHTML = "Kidnap & Ransom"
  }

}
