import { Directive, Input, OnInit } from "@angular/core";
import {
  NgControl,
  Form,
  FormControlDirective,
  FormGroupDirective
} from "@angular/forms";

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: "[inputValidateRef]"
})
export class InputRefDirective {
  constructor(private formGroup: FormGroupDirective) {}
  @Input() control: string;
  get hasError() {
    return this.formGroup.form.get(this.control).invalid;
  }

  get errors() {
    if (this.hasError && this.formGroup.form.get(this.control).errors) {
      return this.formGroup.form.get(this.control).errors;
    }
    return "";
  }
}
