import { Types } from "@tmhcc/framwork/grid";
import { DialogSize } from "@tmhcc/framwork/dialog";
import { CommonContentService } from "@tmhcc/content-service";
import { FieldType } from "@microsoft/applicationinsights-common";
import { FieldTypes } from "@tmhcc/framwork/form-inputs/src";

export class KnRSubjectivityConfig {
  constructor(public commonContentService: CommonContentService) {}

  gridSettingConfig = {
    searching: false,
    paging: false,
    ordering: false,
    responsive: true,
    emptyTable: this.commonContentService.getLabels().emptyTableMsg
  };

  columnSettingsReadOnlyConfig = [
    {
      fieldInfo: {
        fieldName: "description",
        label: "Description"
      },
      columnType: Types.default
    },
    {
      fieldInfo: {
        fieldName: "complete",
        label: "Complete?",
        fieldType: FieldTypes.Readonly
      },
      columnType: Types.checkbox
    }
  ];

  columnSettingsEditableConfig = [
    {
      fieldInfo: {
        fieldName: "description",
        label: "Description"
      },
      columnType: Types.default
    },
    {
      fieldInfo: {
        fieldName: "complete",
        label: "Complete?"
      },
      columnType: Types.checkbox
    }
  ];

  dialogModalSettingConfig = {
    //title: "ADD/EDIT SUBJECTIVITIES:",
    overflow: true,
    hideFooter: true,
    dialogSize: DialogSize.LG,
    hideHeaderX: true,
    hideHeader: true
  };
  dialogModalSettingConfigForDelete = {
    title: 'Delete confirmation',
    hideHeaderX: false,
    overflow: true,
    hideFooter: true,
    dialogSize: DialogSize.LG
  };
}
