import { Provider } from "@angular/core";
import { CurrencyPipe } from "@angular/common";
export { Moment } from "moment";

import { CurrencyService } from "./currency.service";
import { DateService } from "./date.service";

export * from "./def/date.def";
export * from "./date.service";
export * from "./currency.service";
export * from "./def/currency.def";

export const AcServiceProviders: Provider[] = [
  CurrencyPipe,
  CurrencyService,
  DateService
];
export { ErrorHandlerService } from "./error-handle.service";
export { ErrorInterceptor } from "./http-interceptor";
export { ErrorService } from "./error.service";
