import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe, DatePipe, TitleCasePipe } from "@angular/common";
@Pipe({
  name: "gridCellFormatter"
})
export class GridCellFormatterPipe implements PipeTransform {
  constructor(
    private currencyPipe: CurrencyPipe,
    private datePipe: DatePipe,
    private titleCasePipe: TitleCasePipe
  ) {}
  transform(value: any, format: string): any {
    if (value && format) {
      if (format === "currency") {
        return this.currencyPipe.transform(value, "USD", true, "1.2-2");
      } else if (format === "date") {
        return this.datePipe.transform(value, "MM/dd/yyyy");
      } else if (format === "string") {
        return this.titleCasePipe.transform(value);
      }
    }
    return value;
  }
}
