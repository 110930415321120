import { Pipe, PipeTransform } from "@angular/core";
import { Moment, DateService } from "@tmhcc/framwork/services";

@Pipe({ name: "shortDate" })
export class ShortDatePipe implements PipeTransform {
  constructor(private dateService: DateService) {}

  transform(value: Moment | string): any {
    if (!value || !this.dateService.isValidDate(value)) {
      return "";
    }
    return this.dateService.toShortDate(this.dateService.moment(value));
  }
}

@Pipe({ name: "longDate" })
export class LongDatePipe implements PipeTransform {
  constructor(private dateService: DateService) {}

  transform(value: Moment | string): any {
    if (!value || !this.dateService.isValidDate(value)) {
      return "";
    }
    return this.dateService.toLongDate(this.dateService.moment(value));
  }
}
