<div class="container" style="width: 85%;">  
    <div *ngIf="AccFormGroup">
      <form [formGroup]="AccFormGroup" (submit)="onSubmit()">
        <h5 class="header-tab mt-3">Product Type</h5>
        <div class="form-row mt-3">
            <div class="col-sm-6 col-md-4" *ngFor="let items of accountSetupInfoField">
                <hcc-input-generator
                [frmGroup]="AccFormGroup"
                [fieldInfo]="items.fieldInfo"
                [control]="items.fieldInfo.fieldName"
                [InputConfig]="items?.config"
             ></hcc-input-generator>
            </div>
        </div>
        <h5 class="header-tab mt-3">First Named Insured</h5>
        <div class="form-row mt-3">
          <div class="col-sm-6 col-md-4" *ngFor="let items of firstNamedSetupInfoField">
              <hcc-input-generator
              [frmGroup]="AccFormGroup"
              [fieldInfo]="items.fieldInfo"
              [control]="items.fieldInfo.fieldName"
              [InputConfig]="items?.config"
           ></hcc-input-generator>
          </div>
      </div>
      <h5 class="header-tab mt-3">Underwriting Information</h5>
      <div class="form-row mt-3">
        <div class="col-sm-6 col-md-4" *ngFor="let items of underwritingInfoField">
            <hcc-input-generator
            [frmGroup]="AccFormGroup"
            [fieldInfo]="items.fieldInfo"
            [control]="items.fieldInfo.fieldName"
            [InputConfig]="items?.config"
         ></hcc-input-generator>
         </div>
       </div>
       <h5 class="header-tab mt-3">Broker Information</h5>
       <div class="form-row mt-3">
         <div class="col-sm-6 col-md-3" *ngFor="let items of brokerInformationInfoField">
             <hcc-input-generator
             [frmGroup]="AccFormGroup"
             [fieldInfo]="items.fieldInfo"
             [control]="items.fieldInfo.fieldName"
             [InputConfig]="items?.config"
           ></hcc-input-generator>
         </div>
      </div>
      <div class="row text-right mt-4 mb-4">        
         <div class="col-md-3"></div>
         <div class="col-md-3"></div>
          <div class="col-md-3">
            <button
              [disabled]="false"
              class="btn btn-primary w-100"
              type="button"
              (click)="onBackClick();"
            >
              Back
            </button>
          </div>
          <div class="col-md-3">
            <button
              [disabled]="false"
              class="btn btn-primary w-100"
              type="submit"
            >
              Save & Next
            </button>
          </div>                
      </div>
    </form>
  </div>
</div>