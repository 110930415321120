import { Injectable } from "@angular/core";
import { Select } from "@ngxs/store";
import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { Observable } from "rxjs";
import { EventGridRow } from "@tmhcc/models";
import {
  AddEvent,
  AddEvents,
  EditEvent,
  DeleteAllAddedEvents,
  SaveEvents,
  DeleteEvents
} from "../state/quote.actions";
import { QuoteState, QuoteStore } from "../state/quote.state";
import { createUniqueId } from "libs/util/uuid";
import { SaveEventRequest } from "@tmhcc/data-service";

@Injectable({ providedIn: "root" })
export class QuoteStoreService {
  @Select(QuoteState)
  public data$: Observable<QuoteStore>;
  public data: QuoteStore;

  constructor() {
    this.data$.subscribe((value: QuoteStore) => (this.data = value));
  }

  @Dispatch()
  public addEvent(eventGridRow: EventGridRow) {
    return new AddEvent({ ...eventGridRow, customID: createUniqueId() });
  }

  @Dispatch()
  public addEvents(eventGridRows: EventGridRow[]) {
    return new AddEvents(eventGridRows);
  }

  @Dispatch()
  public editEvent(eventGridRow: EventGridRow) {
    return new EditEvent(eventGridRow);
  }

  @Dispatch()
  public deleteAllAddedEvents() {
    return new DeleteAllAddedEvents();
  }

  @Dispatch()
  public deleteEvents(eventIds: string[]) {
    return new DeleteEvents(eventIds);
  }

  @Dispatch()
  public saveEvents(saveEventRequest: SaveEventRequest) {
    return new SaveEvents(saveEventRequest);
  }

  public getEventById(id: string): EventGridRow {
    let result: EventGridRow;
    const eventList = this.data.eventList;
    if (eventList.length !== 0) {
      result = new EventGridRow();
      const foundEvent = eventList.find((value: EventGridRow) => {
        return id === value.customID;
      });
      result = { ...foundEvent };
    }
    return result;
  }
}
