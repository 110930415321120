import { NgModule } from "@angular/core";
import { Routes, RouterModule,Router } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { DialogModule, DialogConfig } from '@tmhcc/framwork/dialog';
import { ReactiveFormsModule,FormsModule,FormGroupDirective } from '@angular/forms';
import { OktaAuthGuard } from '@okta/okta-angular';
import { CommonModule } from "@angular/common";
import { KnrViewSearchModule } from './knr-view-search/knr-view-search.module';
import { KnrDashboardComponent } from './knr-dashboard.component';
import { KnrPolicyQuoteService } from "libs/data-service/kidnap-ransom/knr-policy-quote.service";
import { KnrDashboardState } from "./knr-store/state/knr-dashbard.state";
import {KnrDashboardResolver} from "./knr-dashboard-resolver";
import { KnrSearchComponent } from './knr-search/knr-search.component';
import { KnrSearchModule } from './knr-search/knr-search.module';
import { KnrViewSearchComponent } from './knr-view-search/knr-view-search.component';
import { KnrAccountSetupComponent } from './knr-account-setup/account-setup/knr-account-setup.component';
import { KnrAccountSetupModule } from './knr-account-setup/knr-account-setup.module';
import { CoreInputsModule } from "@tmhcc/framwork/core-inputs";
import {KnrRatingModule} from "./knr-rating/knr-rating.module";
import { QuoteInfoCoveragesModule } from './quote-info-coverages/quote-info-coverages.module';
import { KnRSubjectivityModule } from "./knr-subjectivities/subjectivity.module";
export function onAuthRequired(oktaAuth, injector) {
  const router = injector.get(Router);
  router.navigate(['/login']);
}
const routes: Routes = [
  {
    path: "",
    component: KnrDashboardComponent,
    resolve: { KnrDashboardResolver },
    children: [           
      {
        path: 'account-setup',
        component: KnrAccountSetupComponent       
      }
     ]
  }
];

@NgModule({
  declarations: [KnrDashboardComponent],   
  imports: [
    RouterModule.forChild(routes),    
    ReactiveFormsModule,
    FormsModule,
    DialogModule,
    CommonModule,
    KnrViewSearchModule,
    KnrSearchModule,
    KnrAccountSetupModule,
    [NgxsModule.forFeature([KnrDashboardState])],
    CoreInputsModule,
    KnrRatingModule,
    QuoteInfoCoveragesModule,
    KnRSubjectivityModule
  ],
  providers: [    
    DialogConfig,
    OktaAuthGuard,
    FormGroupDirective,
    KnrPolicyQuoteService,
    KnrDashboardResolver
  ],  
  //entryComponents: [KnrSearchComponent,KnrViewSearchComponent, KnrAccountSetupComponent],
  exports: [RouterModule,KnrDashboardComponent]
})
export class KnrDashboardModule { }
