import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { AppConfigService } from "@tmhcc/global-services";
import { BaseContentService } from "./base-content-service";
import { Errors, Labels } from "./def/common-content";

@Injectable()
export class CommonContentService extends BaseContentService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }

  private errors: Errors;
  private labels: Labels;

  getErrors(): Errors {
    if (!this.errors) {
      // replace contenct service here
      this.errors = this.staticErrors;
    }

    return this.errors;
    // return new Observable(observer => {
    //   observer.next(this.errors);
    //   observer.complete();
    // });
  }

  getLabels(): Labels {
    if (!this.labels) {
      //replace contenct service here
      this.labels = this.staticLabels;
    }

    return this.labels;
  }

  // replace this when content is pulled from sitecore or content delivery system
  private readonly staticErrors: Errors = {
    invalidLossDate: "Invalid data returned from service. Invalid loss date.",
    invalidDate: "Invalid data returned from service. Invalid date.",
    invalidPemiumCheck: "Please select all the premium option.",
    bindSelectErrorValidationMsg: "Select Quote"
  };

  private readonly staticLabels: Labels = {
    welcome: "Welcome",
    searchTypeLabel: "Quote",
    searchByLabel: "SEARCH BY",
    quoteTableHeading: "QUOTES",
    policyTableHeading: "POLICIES",
    searchByQuotes: "Quotes",
    searchByPolicies: "Policies",
    quoteNumber: "Quote Number",
    policyNumber: "Policy Number",
    insuredName: "Insured Name",
    quoteCreatedDate: "Quote Created Date",
    policyEffectiveDate: "Effective Date",
    policyEffectiveDate1: "Policy Effective Date",
    searchLabel: "SEARCH",
    creationDate: "Created Date",
    expirationDate: "Expiration Date",
    broker: "Broker Name",
    underwriter: "Underwriter",
    status: "Status",
    grossPremium: "Gross Premium",
    stepFirstLbl: "Step 1 - General Information",
    stepSecondLbl: "Step 2 - Events",
    stepThirdLbl: "Step 3 - Quotes",
    stepLastLbl: "Step 4 - Bind",
    quoteHeading: "QUOTE INFORMATION",
    insuredHeading: "INSURED INFORMATION",
    brokerHeading: "BROKER INFORMATION",
    underwriterHeading: "UNDERWRITING INFORMATION",
    ecoProductTypeLbl: "Product",
    quoteInsuredLabel: "Insured Name",
    quoteAddressFieldLbl: "Address",
    quoteCityFieldLbl: "City",
    quoteStateFieldLbl: "State",
    quoteZipCodeFieldLbl: "Zip Code",
    quoteCountryFieldLbl: "Country",
    quoteBrokerFieldLBl: "Broker",
    quoteSeriesFieldLBL: "iSeries Broker Number",
    quoteUnderwriterFieldLbl: "Underwriter",
    quoteUWAssistentFieldLbl: "Underwriter Assistant",
    //Event Labels
    eventType: "Event Type",
    eventName: "Event Name",
    eventDescription: "Event Name",
    eventVenue: "Event Venue",
    address: "Address",
    city: "City",
    state: "State",
    zipCode: "Zip Code",
    country: "Country",
    eventStartDate: "Event Start Date",
    eventEndDate: "Event End Date",
    eventLimitInsurance: "Event Limit of Insurance",
    eventDeductible: "Event Deductible",
    baseRate: "Base Rate",
    windstorm: "Windstorm",
    strike: "Strike",
    earthquake: "Earthquake",
    winterWeather: "Winter Weather",
    wildfire: "Wildfire",
    csTornadoHail: "CS/Tornado/Hail",
    civilCommotion: "Civil Commotion",
    cyber: "Cyber",
    era: "ERA",
    nationalMounting: "National Mourning",
    limitedTerrorism: "Limited Terrorism",
    terrorism: "Terrorism",
    tria:"TRIA",
    threat:"Threat",
    nucBioChemTerrorism: "Nuc Bio-Chem Terrorism (or Malicious Use)",
    communicableDisease: "Communicable Disease",
    limitedWar: "Limited War",
    additionalPerilLabel1: "Additional Peril 1",
    additionalPerilValue1: "Additional Peril 1",
    war: "War",
    nuclearHazard: "Nuclear Hazard",
    additionalPerilLabel2: "Additional Peril 2",
    additionalPerilValue2: "Additional Peril 2",
    nonAppearance: "Non-Appearance",
    bioChemTerrIncl: "Bio-Chem Terr Incl. Mal Use",
    additionalPerilLabel3: "Additional Peril 3",
    additionalPerilValue3: "Additional Peril 3",
    eventInformationLbl: "EVENT INFORMATION",
    optionsLbl: "OPTIONS",
    downloadTemplateLbl: "Download Template",
    copyQuoteLbl: "Copy Quote",
    uploadTemplateLbl: "Upload Template",
    addNewEventLbl: "ADD NEW EVENT",
    emptyTableMsg: "No Result Found",
    eventTypeRequiredValidationLbl: "Please enter Event Type",
    addressRequiredValidationLbl: "Please enter Address",
    cityRequiredValidationLbl: "Please enter City",
    countryRequiredValidationLbl: "Please enter Country",
    esdRequiredValidationLbl: "Please enter Event Start Date",
    esdPastDateValidationLbl: "Event Start Date can not be past date",
    esdInvalidDateValidationLbl:
      "Event Start Date should be less than Event End Date",
    eedRequiredValidationLbl: "Please enter Event End Date",
    eedPastDateValidationLbl: "Event End Date can not be past date",
    eedInvalidDateValidationLbl:
      "Event End Date should be more than Event Start Date",
    limitofInsRequiredValidationLbl: "Please enter Event Limit of Insurance",
    eventVenueRequiredValidationLbl:"Please enter Event Venue",
    baseRateRequiredLbl: "Required",
    baseRatePatternLbl: "Invalid",
    dirtyFormMsg:
      "Exiting this window will cause any unsaved data to be lost. Are you sure you want to continue?",
    quoteSummaryHeading: "QUOTE SUMMARY",
    quoteSummaryLimitOfInssurence: "limit of Insurance",
    summaryPolicyExpiryDate: "Policy Expiration Date:",
    QuoteSummaryFillingResponsibility: "Filing Responsibility",
    QuoteSummaryTaxState: "Tax State",
    QuoteSummaryEventInformation: "PREMIUM OPTIONS :",
    QuoteSummaryDownLoadQuoteHeading: "Download Quote",
    QuoteSummaryDescription: "Quote Description",
    QuoteExpirationDate: "Quote Expiration Date",
    addEditSubjectivityLbl: "ADD/EDIT SUBJECTIVITIES",
    subjectivityHeadingLbl: "SUBJECTIVITIES",
    QuptOptionPageHeading: "EVENT LEVEL QUOTE OPTIONS:",
    FillingResponsibilityLabel: "Filing Responsibility",
    TaaxStateLabel: "Tax State",
    step1Save: "Step1Save",
    currencyCode: "USD",
    summaryTextInfo:
      "*Above premiums do not include state Surplus Lines Taxes and/or Fees as allowable by state law/jurisdiction",
    policyExpLbl: "Policy Expiration Date:",
    policyEffLabl: "Policy Effective Date:",
    limitOfInsurance: "Limit of Insurance:",
    download: "Download",
    policyFormName: "FORMS NAME",
    policyFormNumber: "FORM NUMBER",
    policyFormDelete: "DELETE",
    policyFormHeading: "FORMS",
    slectFormInputLabel: "Select Form",
    selctFornInputReqMsg: "Select Form",
    selectQuoteVersionInputLabel: "Select Quote",
    insured: "INSURED:",
    agency: "AGENCY:",
    policyperiod: "POLICY PERIOD:",
    premiumsummary: "PREMIUM SUMMARY:",
    commission: "COMMISSION:",
    premium: "Premium",
    taxesandfee: "TAXES & FEES:",
    back: "BACK",
    bindpolicy: "BIND POLICY",
    policynum: "POLICY NUMBER:",
    policySummary: "POLICY SUMMARY",
    commissionsmall: "Commission:",
    endorsement: "ENDORSEMENT:",
    firstLossLimitEndorsement: "First Loss Limit Endorsement?",
    changewithRespecttoCalculationofLoss:
      "Change with Respect to Calculation of Loss?",
    carrierName: "Carrier Name:",
    bindQuoteDescription: "UW Quote Desc",
    bindSavedStepDescription: "Saved step 4 details",
    bindSelectFieldLabel: "Select Quote",
    quoteDownloadSuccessTitle: "Downloaded successfully",
    quoteDownloadSuccessDesc: "The quote was downloaded successfully",
    quotePreventDownloadTitle: "Can not download",
    quotePreventDownloadDesc: "Save & Refresh page prior to downloading quote",
    quotePolicyTypeValidationText: "Please select Product",
    quoteInsuredValidaionText: "Please enter Insured Name",
    quoteAddressValidationText: "Please enter Address",
    quoteCityRequiredValidationText: "Please enter City",
    quoteValidCityText: "Please enter a valid  City",
    quoteStateValidationText: "Please select State",
    quoteZiprequiredValidationText: "Please enter Zip Code",
    quoteZipMaxlengthValidationText: "Please enter a valid Zip Code",
    quoteZipMinlengthValidationText: "Please enter a valid Zip Code",
    quoteCountryValidationText: "Please select Country",
    quoteBrokerValidationText: "Please select Broker",
    quoteUnderwriterValidationText: "Please select Underwriter",
    standardPolicyOption1Label: "OPTION 1: Base Premium Excluding Terrorism",
    standardPolicyOption2Label: "OPTION 2: Full Terrorism Excluding NBCT",
    standardPolicyOption3Label: "OPTION 3: Full Terrorism Including NBCT",
    standardAdditionalPerlsLabel: "Additional Perils Premium:",
    tradeShowPolicyOption1Label:"OPTION 1:",
    tradeShowPolicyOption2Label: "OPTION 2:",
    tradeShowPolicyOption3Label: "OPTION 3:",
    tradeShowPolicyOption4Label: "OPTION 4:",
    tradeShowAdditionalPerlsLabel: "Additional Perils Premium:",
    tradeShowPolicyOption1SubLabel:"Base Premium",
    tradeShowPolicyOption2SubLabel:"Time & Distance Terrorism",
    tradeShowPolicyOption3SubLabel: "TRIA",
    tradeShowPolicyOption4SubLabel: "TRIA plus Threat",
    dirtyQuoteVersionChkMsg:"Please save the events to proceed",
    copyQuoteAlertWarning:"Please add atleast one event to make a copy quote.",

    eventRevenueLbl:"Revenue",
    eventExpenseLbl:"Expense",
    eventInsuredPersonLbl:"Insured Person/Speaker"
  };
}
