import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class CoreInputValidationService {
  private isFormSubmitted = new BehaviorSubject<boolean>(false);
  formSubmission = this.isFormSubmitted.asObservable();

  setFormSubmission(submitState) {
    this.isFormSubmitted.next(submitState);
  }
}
