import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { BaseDataService } from "./base-data-service";
import { AppConfigService } from "@tmhcc/global-services";
import { DateService } from "@tmhcc/framwork/services";
import { CommonContentService } from "@tmhcc/content-service";
import { CopyQuoteResponse, CopyQuoteRequest } from "./def/copy-quote";
import { CopyQuoteModel } from "@tmhcc/models";
@Injectable()
export class CopyQuoteService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    private commonContentService: CommonContentService
  ) {
    super(http, configService);
  }
  createCopyQuote(payload: CopyQuoteRequest): Observable<CopyQuoteModel> {
    const url = `${this.serviceBaseUrl}/CopyQuote`;
    return this.post<CopyQuoteResponse>(url, { body: payload })
      .pipe
      // map(data => this.mapToQuoteGridRow(data)),
      // catchError(this.handleError)
      ();
  }
}
