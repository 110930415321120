<div class="container">
  <ec-page-header></ec-page-header>
  <div class="row">
    <div class="col-md-12 ">
      <ec-quote-policy-info></ec-quote-policy-info>
      <ec-page-tab></ec-page-tab>
      <div class="form-row mt-4 mb-4">
        <div class="col-md-7 col-lg-8">
          <h5 class="header-tab">{{ getLabels.policyFormHeading }}</h5>
        </div>
        <div class="col-md-5 col-lg-4">
          <button
            [ngClass]="{ formDisabled: isPolicyBind == true }"
            (click)="openFormsDialog()"
            class="btn btn-secondary float-right"
            data-toggle="modal"
            data-target="#addForms"
            [disabled]="isPolicyBind"
          >
            ADD FORMS
          </button>
        </div>
      </div>
      <form [formGroup]="endoseformgroup">
        <div
          *ngIf="showTable"
          class="policyEndorsmentForms"
          [ngClass]="{ formDisabled: isPolicyBind == true }"
        >
          <ac-grid-table
            [parentDivName]="'policyForms'"
            [gridSetting]="gridSetting"
            [records]="recordsList"
            [columnSettings]="tableSettingsPolicyforms"
            (childDeleteRecord)="deleteOptionalForm($event)"
            [isCustomizedGrid]="true"
          >
          </ac-grid-table>
        </div>
        <hr class="divider-dark mt-3" />
        <div
          class="comment-note"
          [ngClass]="{ formDisabled: isPolicyBind == true }"
        >
          <hcc-textarea-input
            [control]="fieldcommentInfo.fieldName"
            [frmGroup]="endoseformgroup"
            [fieldInfo]="fieldcommentInfo"
          >
          </hcc-textarea-input>
        </div>
      </form>
    </div>
  </div>
  <hr class="divider-dark" />
  <div class="button-row">
    <button type="button" (click)="backButtonPressed()" class="btn btn-primary">
      Back
    </button>
    <button
      type="button"
      (click)="saveAndRefresh()"
      class="btn btn-primary"
      [disabled]="isPolicyBind"
    >
      SAVE &amp; REFRESH
    </button>

    <button type="button" (click)="saveAndNext()" class="btn btn-primary">
      Next
    </button>
  </div>
</div>
