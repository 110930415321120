import {
  Underwriters,
  Country,
  StateOrProvince,
  ClaimHandler,
  PolicyTypes,
  UnderwriterAssistants,
  SubmissionTypes,
  RenewalOptions,
  TermTypes,
  PaymentPlans,
  UserConfigurableDataElements,
  ConfigSubjectivities,
  WritingCompaniesByStates
} from "libs/data-service/def/eco-master";

export class EcoMaster {
  Underwriters: ClaimHandler[];
  Countries: Country[];
  StateOrProvinces: StateOrProvince[];
  ClaimHandler: ClaimHandler[];
  PolicyTypes: PolicyTypes[];
  UnderwriterAssistants: UnderwriterAssistants[];
  SubmissionTypes: SubmissionTypes[];
  RenewalOptions: RenewalOptions[];
  TermTypes: TermTypes[];
  PaymentPlans: PaymentPlans[];
  UserConfigurableDataElements: UserConfigurableDataElements[];
  ConfigSubjectivities: ConfigSubjectivities[];
  WritingCompaniesByStates: WritingCompaniesByStates[];
  FormsetTypes: FormsetTypes[];
  TaxesAndFees: TaxesAndFees[];
}

export class FormsetTypes {
  FormSetId: number;
  FormSetTypeCode: string;
}

export class TaxesAndFees {
  TaxId: number;
  StateId: number;
}
