export class QuoteResponseData{
    QuoteDetails:QuoteDetail;
}
export class QuoteDetail{
    QuoteId?:string;
    QuoteNumber?:string;
    ProductType?: ProductType;
    Insured?:Insured;
    UnderwritingInformation?: UnderwritingInformation;
    BrokerInformation?:BrokerInformation;
}

export class ProductType{
    QuoteNumber?:string;
    Lob:string;
    PolicyType:string;
    Carrier: string;
    Currency: string;
    Territory: string;
}
export class Insured{
    InsuredName:string;
    CodeName:string;
    NamedPolicy:string;    
    Address: InsuredAddress;
    Class:string;   
}
export class InsuredAddress
{
    Street?: string;
    SuiteOrUnit?: string;
    City: string;
    State: string
    ZipCode: string;
    Country: string;
}
export class UnderwritingInformation
{
    Underwriter: string;
    UnderwriterAssistant?: string;
}
export class BrokerInformation
{
    BrokerName:string;
    BrokerCommissionPercentage:number
    BrokerNumber:string;
    BrokerAddress:BrokerAddress;
    ContactName:string;
}
export class BrokerAddress
{
    Street?:string;
    SuiteOrUnit?:string;
    City:string;
    State:string;
    ZipCode:string;
    Country:string;
}
export class UpdateQuoteRequest
{
    QuoteId? : string;
    QuoteNumber?: string;
    PolicyFrom: string;
    PolicyTo: string;
    TimeZoneOffset: string;
    ProductType: ProductTypeUpdate;
    Insured:Insured;
    UnderwritingInformation: UnderwritingInformation;
    BrokerInformation:BrokerInformation;
}
export class ProductTypeUpdate{    
    Lob:string;
    PolicyType:string;
    Carrier: string;
    Currency: string;
    Territory: string;
}