import { Injectable } from "@angular/core";
import { FieldTypes } from "@tmhcc/framwork/form-inputs";
import { Validators } from "@angular/forms";
import { CommonContentService } from "@tmhcc/content-service";
import { DateService } from "@tmhcc/framwork/services";
import { BsDatepickerDirective } from "ngx-bootstrap/datepicker";
import { valHooks } from "jquery";
import { BasePremium, CoverageModifiers, LimitModification, ModifiedBasePremium, RiskQualityModifiers, WorkplaceViolence } from "@tmhcc/models/classes/knr-rating-data.model";
@Injectable()
export class RatingDefinitionService {
  constructor() {}
  
  basePremiumConfig(data:BasePremium) {
    return{
      basePremiumConfigList: [
        {          
          fieldInfo: {
             
            fieldName: "keyemployees",
            label: "Key Employees:",            
            fieldType: FieldTypes.Numeric,
            showLabel: true,
            validation: {
              required: "Required field"
            }
          },
          config: { 
            isDisabled: false,
            validation: [Validators.required]              
          },                   
      },     
      {
        fieldInfo: {
           
          fieldName: "allotheremployees",
          label: "All Other Employees:",
          fieldType:  FieldTypes.Numeric,
          showLabel: true,
          validation: {
            required: "Required field"
          }
        },
        config: {
            isDisabled: false,
            validation: [Validators.required]    
        }
      },
      {          
        fieldInfo: {           
          fieldName: "assetsize",
          label: "Asset Size:",            
          fieldType:  FieldTypes.LookupList,
          showLabel: true,                  
          validation: {
            required: "Please select"
          }  
        },
        config: {          
          selectConfig: {
            selectedOption: [],
            options: data.assetSize.map((item) => {
              return item.value;
           }),
            optionConfig: {
              displayKey: "value",
              search: true,
              limitTo: 3              
            },
            multiple: false,            
          },
          validation: [Validators.required]
        }              
        },  
        {          
            fieldInfo: {
             
            fieldName: "worldwideexpo",
            label: "Worldwide Exposure:",            
            fieldType:  FieldTypes.Numeric,
            showLabel: true,
            validation: {
              required: "Required field"
            }  
            },
            config: { 
            isDisabled: false,
            validation: [Validators.required],
            numericConfig: {
              type: "currency"
            },                 
            },                   
        }, 
      {
        fieldInfo: {
           
          fieldName: "companyvisibility",
          label: "Company Visibility:",
          fieldType: FieldTypes.LookupList,
          showLabel: true, 
          validation: {
            required: "Please select"
          }          
        },
        config: {          
          selectConfig: {
            selectedOption: [],
            options: data.companyVisibility.map((item) => {
              return item.value;
           }),
            optionConfig: {
              displayKey: "value",
              search: true,
              limitTo: 3              
            },
            multiple: false,            
          },
          validation: [Validators.required]
        }
      },
      {
        fieldInfo: {
           
          fieldName: "individualvisibility",
          label: "Individual Visibility:",
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: "Please select"
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: data.individualVisibility.map((item) => {
              return item.value;
           }),
            optionConfig: {
              displayKey: "value",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      },
      {          
        fieldInfo: {
           
          fieldName: "basepremium",
          label: "Base Premium:",            
          fieldType:  FieldTypes.Numeric,
          showLabel: true,          
        },
        config: { 
          isDisabled: true, 
          numericConfig: {
            type: "currency"
          },                       
        },                   
      }      
    ]
  }
 };

 modifiedBasePremiumConfig(data:ModifiedBasePremium) {
    return{
        modifiedBasePremiumConfigList: [
        {          
          fieldInfo: {
             
            fieldName: "grossrevenue",
            label: "Gross Revenue:",            
            fieldType:  FieldTypes.LookupList,
            showLabel: true,
            validation: {
              required:"Please select"
            }
          },
          config: {  
            numericConfig: {
              type: "currency"
            },          
            selectConfig: {
              selectedOption: [],
              options: data.grossRevenue.map((item) => {
                return item.value;
             }),
              optionConfig: {
                displayKey: "value",
                search: true,
                limitTo: 3,              
              },
              multiple: false,             
            },
            validation: [Validators.required] 
          }               
      },  
      {
        fieldInfo: {           
          fieldName: "deductible",
          label: "Deductible:",
          fieldType: FieldTypes.LookupList,
          showLabel: true, 
          validation: {
            required: "Please select"
          }        
        },
        config: {  
          numericConfig: {
            type: "currency"
          },          
          selectConfig: {
            selectedOption: [],
            options: data.deductible.map((item) => {
              return item.value;
           }),
            optionConfig: {
              displayKey: "value",
              search: true,
              limitTo: 3,              
            },
            multiple: false,             
          },
          validation: [Validators.required] 
        }
      },
      {
        fieldInfo: {
           
          fieldName: "specialindustry",
          label: "Special Industry Vulnerability:",
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: "Please select"
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: data.specialIndustry.map((item) => {
              return item.value;
           }),
            optionConfig: {
              displayKey: "value",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      },
      {
        fieldInfo: {
           
          fieldName: "claims",
          label: "Claims:",
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: "Please select"
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: data.claims.map((item) => {
              return item.value;
           }),
            optionConfig: {
              displayKey: "value",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      },
      {          
        fieldInfo: {
           
          fieldName: "modifiedbasepremium",
          label: "Modified Base Premium:",            
          fieldType:  FieldTypes.Numeric,
          showLabel: true, 
                 
        },
        config: { 
          isDisabled: true,
          numericConfig: {
            type: "currency"
          },                       
        },                   
      }      
    ]
  }
 };
 limitModificationConfig(data:LimitModification) {
    return{
        limitModificationConfigList: [
        {          
          fieldInfo: {
             
            fieldName: "limitofinsurence",
            label: "Limit of Insurance:",            
            fieldType: FieldTypes.LookupList,
            showLabel: true,
            validation: {
              required: "Please select"
            }
          },
          config: {
            validation: [Validators.required],
            selectConfig: {
              selectedOption: [],
              options: data.limitOfInsurance.map((item) => {
                return item.value;
             }),
              optionConfig: {
                displayKey: "value",
                search: true,
                limitTo: 3
              },
              multiple: false
            }
          }                  
      },  
      {
        fieldInfo: {
           
          fieldName: "dissappearenceandinvst",
          label: "Dissappearance & Investigation:",
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: "Please select"
          }        
        },
        config: {          
          selectConfig: {
            selectedOption: [],
            options: data.disappearanceAndInvestigation.map((item) => {
              return item.value;
           }),
            optionConfig: {
              displayKey: "value",
              search: true,
              limitTo: 3
            },
            multiple: false,            
          },
          validation: [Validators.required]
        }
      },
      {
        fieldInfo: {
           
          fieldName: "treatresponse",
          label: "Threat Response:",
          fieldType: FieldTypes.LookupList,
          showLabel: true,  
          validation: {
            required: "Please select"
          }        
        },
        config: {    
          validation: [Validators.required],      
          selectConfig: {             
            selectedOption: [],
            options: data.threatResponse.map((item) => {
              return item.value;
           }),
            optionConfig: {
              displayKey: "value",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      },
      {
        fieldInfo: {
           
          fieldName: "recallresponse",
          label: "Recall Response:",
          fieldType: FieldTypes.LookupList,
          showLabel: true, 
          validation: {
            required: "Please select"
          }         
        },
        config: {          
          selectConfig: {
            selectedOption: [],
            options: data.recallResponse.map((item) => {
              return item.value;
           }),
            optionConfig: {
              displayKey: "value",
              search: true,
              limitTo: 3
            },
            multiple: false,            
          },
          validation: [Validators.required]
        }
      },
      {
        fieldInfo: {
           
          fieldName: "personalbelongings",
          label: "Personal Belongings:",
          fieldType: FieldTypes.LookupList,
          showLabel: true, 
          validation: {
            required: "Please select"
          }         
        },
        config: {          
          selectConfig: {
            selectedOption: [],
            options: data.personalBelongings.map((item) => {
              return item.value;
           }),
            optionConfig: {
              displayKey: "value",
              search: true,
              limitTo: 3
            },
            multiple: false,            
          },
          validation: [Validators.required]
        }
      },
      {
        fieldInfo: {
           
          fieldName: "travelsecevoption",
          label: "Travel Security Evac Option:",
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: "Please select"
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: data.travelSecurityEvacOption.map((item) => {
              return item.value;
           }),
            optionConfig: {
              displayKey: "value",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      },
      {
        fieldInfo: {
           
          fieldName: "travelsecevolimit",
          label: "Travel Security Evac Limit:",
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: "Please select"
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: data.travelSecurityEvacLimit.map((item) => {
              return item.value;
           }),
            optionConfig: {
              displayKey: "value",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      },
      {
        fieldInfo: {
           
          fieldName: "expsecevoption",
          label: "Expatriate Security Evac Option:",
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: "Please select"
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: data.expatriateSecurityEvacOption.map((item) => {
              return item.value;
           }),
            optionConfig: {
              displayKey: "value",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      },
      {
        fieldInfo: {
           
          fieldName: "expsecevolimit",
          label: "Expatriate Security Evac Limit:",
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: "Please select"
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: data.expatriateSecurityEvacLimit.map((item) => {
              return item.value;
           }),
            optionConfig: {
              displayKey: "value",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      },      
      {          
        fieldInfo: {
           
          fieldName: "modifiedlimitpremium",
          label: "Modified Limit Premium:",            
          fieldType:  FieldTypes.Numeric,
          showLabel: true
        },
        config: { 
          isDisabled: true,
          numericConfig: {
            type: "currency"
          },               
        },                   
      }      
    ]
  }
 };
 coverageModifiersConfig(data:CoverageModifiers){
    return {
        coverageModifiersConfigList:[
            {
                fieldInfo: {
                   
                  fieldName: "adanddlimit",
                  label: "AD&D Limit:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,  
                  validation: {
                    required: "Please select"
                  }        
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.aDandDLimit.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation: [Validators.required]
                }
              },
              {
                fieldInfo: {
                   
                  fieldName: "childabductionlimit",
                  label: "Child Abduction Limit:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,
                  validation: {
                    required: "Please select"
                  }          
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.childAbductionLimit.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation: [Validators.required]
                }
              },
              {
                fieldInfo: {
                   
                  fieldName: "wwinclusibledeductible",
                  label: "WW Inclusive Deductible:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,  
                  validation: {
                    required: "Please select"
                  }        
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.wwInclusiveDeductible.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                   
                  },
                  validation: [Validators.required]
                }
              },
              {
                fieldInfo: {
                   
                  fieldName: "terrinclusibledeductible",
                  label: "Territorial Inclusive Deductible:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true, 
                  validation: {
                    required: "Please select"
                  }         
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.territorialInclusiveDeductible.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                   
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "wwinclusiveaggrigate",
                  label: "WW Inclusive Aggrigate:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true, 
                  validation: {
                    required: "Please select"
                  }         
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.wwInclusiveAggregate.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "territorialexclusions",
                  label: "Territorial Exclusions:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true, 
                  validation: {
                    required: "Please select"
                  }         
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.territorialExclusions.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "territorialsubinclaggr",
                  label: "Territorial Sub-Limit/Inclusive Aggregate:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true, 
                  validation: {
                    required: "Please select"
                  }         
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.territorialSubLimitInclusiveAggregate.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "amendedtravel",
                  label: "Amended Travel:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,  
                  validation: {
                    required: "Please select"
                  }        
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.amendedTravel.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "amendedinsuredpersons",
                  label: "Amended Insured Persons:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,  
                  validation: {
                    required: "Please select"
                  }        
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.amendedInsuredPersons.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "lossofearnings",
                  label: "Loss of Earnings:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,  
                  validation: {
                    required: "Please select"
                  }        
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.lossOfEarnings.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "amendedthreat",
                  label: "Amended Threat:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,  
                  validation: {
                    required: "Please select"
                  }        
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.amendedThreat.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "amendeddisappearance",
                  label: "Amended Disappearance:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,
                  validation: {
                    required: "Please select"
                  }          
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.amendedDisappearance.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "exclusionaryendorsement",
                  label: "Exclusionary Endorsement:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true, 
                  validation: {
                    required: "Please select"
                  }         
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.exclusionaryEndorsement.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            }
        ]
    }
 }

 riskQualityModifiersConfig(data:RiskQualityModifiers){
    return {
        riskQualityModifiersConfigList:[            
              {
                fieldInfo: {
                   
                  fieldName: "riskadvisory",
                  label: "Risk Advisory:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,  
                  validation: {
                    required: "Please select"
                  }        
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.riskAdvisory.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                   
                  },
                  validation:[Validators.required]
                }
              },
              {
                fieldInfo: {
                   
                  fieldName: "risktravel",
                  label: "Risk Travel:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true, 
                  validation: {
                    required: "Please select"
                  }         
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.riskTravel.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "riskconsultant",
                  label: "Risk Consultant:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,  
                  validation: {
                    required: "Please select"
                  }        
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.riskConsultant.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "riskcmrecom",
                  label: "Risk Crisis Management Recommendations:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,   
                  validation: {
                    required: "Please select"
                  }       
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.riskCMRecom.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "riskcmplan",
                  label: "Risk Crisis Management Plan:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,   
                  validation: {
                    required: "Please select"
                  }       
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.riskCMPlan.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "riskproduct",
                  label: "Risk Product:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,
                  validation: {
                    required: "Please select"
                  }          
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.riskProduct.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "riskproductanimal",
                  label: "Product research and devleopment involving animal testing:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,   
                  validation: {
                    required: "Please select"
                  }       
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.riskProductAnimal.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "riskunityportal",
                  label: "Unity Resources Group Portal subscription provided by response team:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,  
                  validation: {
                    required: "Please select"
                  }        
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.riskUnityPortal.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                   
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "riskunityguidelines",
                  label: "Receipt of crisis management planning and executive protection guidelines booklet:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,     
                  validation: {
                    required: "Please select"
                  }     
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.riskUnityGuidelines.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "riskunitymeeting",
                  label: "Pre-crisis meeting with response team operatives:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,   
                  validation: {
                    required: "Please select"
                  }       
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.riskUnityMeeting.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "riskunityproposal",
                  label: "Written pre-crisis response plan proposal by response team:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true, 
                  validation: {
                    required: "Please select"
                  }         
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.riskUnityProposal.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                   
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "riskunitytelephone",
                  label: "Telephone advice from response team:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true, 
                  validation: {
                    required: "Please select"
                  }         
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.riskUnityTelephone.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
            },
            {
                fieldInfo: {
                   
                  fieldName: "riskcountryreports",
                  label: "Risk Country Reports:",
                  fieldType: FieldTypes.LookupList,
                  showLabel: true,  
                  validation: {
                    required: "Please select"
                  }        
                },
                config: {          
                  selectConfig: {
                    selectedOption: [],
                    options: data.riskCountryReports.map((item) => {
                      return item.value;
                   }),
                    optionConfig: {
                      displayKey: "value",
                      search: true,
                      limitTo: 3
                    },
                    multiple: false,                    
                  },
                  validation:[Validators.required]
                }
              },
        ]
    }
  } 

  workplaceVoilancesConfig() {
    return { workplaceVoilancesConfigList: [
      {
        fieldInfo: {          
          fieldName: 'workplacevoilance',
          label: 'Workplace Violence:',
          fieldType: FieldTypes.Radio,
          showLabel: true,               
        },
        config: {         
          isDisabled: false,
          radioCofig: {
            radioGroup: [
              {
                checked: false,
                label: 'Yes',
                value: 'workplacevoilance', 
                style: 'col-6 col-lg-6 pl-0 float-left'               
              },
              {
                checked: true,
                label: 'No',
                value: 'workplacevoilance', 
                style: 'col-6 col-lg-6 pl-0 float-left'               
              }
            ]
          }
        },
        isDisabled: false,  
        colClass: 'col-3'      
      }
    ]
   }
  }
  workplaceVoilanceInputConfig(data:WorkplaceViolence){   
      return { workplaceVoilanceInputConfigList:[
        {
          fieldInfo: {             
            fieldName: "noofemployees",
            label: "Number of Employees:",
            fieldType: FieldTypes.LookupList,
            showLabel: true                   
          },
          config: {          
            selectConfig: {
              selectedOption: [],
              options: data.numberOfEmployees.map((item) => {
                return item.value;
             }),
              optionConfig: {
                displayKey: "value",
                search: true,
                limitTo: 3
              },
              multiple: false,                    
            }            
          }
        },
        {
          fieldInfo: {             
            fieldName: "limitofliability",
            label: "Limit of Liability:",
            fieldType: FieldTypes.LookupList,
            showLabel: true                   
          },
          config: {          
            selectConfig: {
              selectedOption: [],
              options: data.limitOfLiability.map((item) => {
                return item.value;
             }),
              optionConfig: {
                displayKey: "value",
                search: true,
                limitTo: 3
              },
              multiple: false,                    
            }            
          }
        },
        {
          fieldInfo: {             
            fieldName: "businessoperations",
            label: "Business Operations:",
            fieldType: FieldTypes.LookupList,
            showLabel: true                   
          },
          config: {          
            selectConfig: {
              selectedOption: [],
              options: data.businessOperations.map((item) => {
                return item.value;
             }),
              optionConfig: {
                displayKey: "value",
                search: true,
                limitTo: 3
              },
              multiple: false,                    
            }            
          }
        },
        {
          fieldInfo: {             
            fieldName: "nooflocations",
            label: "Number of Locations:",
            fieldType: FieldTypes.LookupList,
            showLabel: true                   
          },
          config: {          
            selectConfig: {
              selectedOption: [],
              options: data.numberOfLocations.map((item) => {
                return item.value;
             }),
              optionConfig: {
                displayKey: "value",
                search: true,
                limitTo: 3
              },
              multiple: false,                    
            }            
          }
        },
        {
          fieldInfo: {             
            fieldName: "workplacereductions",
            label: "Workplace Reduction:",
            fieldType: FieldTypes.LookupList,
            showLabel: true                   
          },
          config: {          
            selectConfig: {
              selectedOption: [],
              options: data.workplaceReduction.map((item) => {
                return item.value;
             }),
              optionConfig: {
                displayKey: "value",
                search: true,
                limitTo: 3
              },
              multiple: false,                    
            }            
          }
        },
        {
          fieldInfo: {             
            fieldName: "worplacepractices",
            label: "Workplace Practices:",
            fieldType: FieldTypes.LookupList,
            showLabel: true                   
          },
          config: {          
            selectConfig: {
              selectedOption: [],
              options: data.workplacePractices.map((item) => {
                return item.value;
             }),
              optionConfig: {
                displayKey: "value",
                search: true,
                limitTo: 3
              },
              multiple: false,                    
            }            
          }
        },
        {
          fieldInfo: {             
            fieldName: "securitycontrols",
            label: "Security Controls:",
            fieldType: FieldTypes.LookupList,
            showLabel: true                   
          },
          config: {          
            selectConfig: {
              selectedOption: [],
              options: data.securityControls.map((item) => {
                return item.value;
             }),
              optionConfig: {
                displayKey: "value",
                search: true,
                limitTo: 3
              },
              multiple: false,                    
            }            
          }
        },
        {
          fieldInfo: {             
            fieldName: "priorclaims",
            label: "Prior Claims:",
            fieldType: FieldTypes.LookupList,
            showLabel: true                   
          },
          config: {          
            selectConfig: {
              selectedOption: [],
              options: data.priorClaims.map((item) => {
                return item.value;
             }),
              optionConfig: {
                displayKey: "value",
                search: true,
                limitTo: 3
              },
              multiple: false,                    
            }            
          }
        },
        {
          fieldInfo: {             
            fieldName: "financialstability",
            label: "Finanical Stability:",
            fieldType: FieldTypes.LookupList,
            showLabel: true                   
          },
          config: {          
            selectConfig: {
              selectedOption: [],
              options: data.financialStability.map((item) => {
                return item.value;
             }),
              optionConfig: {
                displayKey: "value",
                search: true,
                limitTo: 3
              },
              multiple: false,                    
            }            
          }
        },
        {
          fieldInfo: {             
            fieldName: "yearsinbusiness",
            label: "Years in Business:",
            fieldType: FieldTypes.LookupList,
            showLabel: true                   
          },
          config: {          
            selectConfig: {
              selectedOption: [],
              options: data.yearsInBusiness.map((item) => {
                return item.value;
             }),
              optionConfig: {
                displayKey: "value",
                search: true,
                limitTo: 3
              },
              multiple: false,                    
            }            
          }
        },
        {
          fieldInfo: {             
            fieldName: "maactivity",
            label: "M&A Activity:",
            fieldType: FieldTypes.LookupList,
            showLabel: true                   
          },
          config: {          
            selectConfig: {
              selectedOption: [],
              options: data.mandAActivity.map((item) => {
                return item.value;
             }),
              optionConfig: {
                displayKey: "value",
                search: true,
                limitTo: 3
              },
              multiple: false,                    
            }            
          }
        },
        {
          fieldInfo: {             
            fieldName: "riskcompexity",
            label: "Risk Complexity:",
            fieldType: FieldTypes.LookupList,
            showLabel: true                   
          },
          config: {          
            selectConfig: {
              selectedOption: [],
              options: data.riskComplexity.map((item) => {
                return item.value;
             }),
              optionConfig: {
                displayKey: "value",
                search: true,
                limitTo: 3
              },
              multiple: false,                    
            }            
          }
        },
        {          
          fieldInfo: {           
            fieldName: "wpvpremium",
            label: "WPV Premium:",            
            fieldType:  FieldTypes.Numeric,
            showLabel: true,           
          },
          config: { 
            isDisabled: true,            
            numericConfig: {
              type: "currency"
            },              
          }                
        }
       ]
     }    
  }
  medEvacRadioConfig() {
    return{ medEvacRadioConfigList : [
      {
        fieldInfo: {          
          fieldName: 'medeva',
          label: 'Med Evac:',
          fieldType: FieldTypes.Radio,
          showLabel: true,                   
        },
        config: {         
          isDisabled: false,
          radioCofig: {
            radioGroup: [
              {
                checked: false,
                label: 'Yes',
                value: 'medeva', 
                style: 'col-6 col-lg-6 pl-0 float-left'               
              },
              {
                checked: true,
                label: 'No',
                value: 'medeva', 
                style: 'col-6 col-lg-6 pl-0 float-left'               
              }
            ]
          },          
        },
        isDisabled: false,  
        colClass: 'col-sm-6 col-md-10'      
       }      
    ]
   }
  } 
  medEvacInputConfig() {
    return{ medEvacInputConfigList : [      
       {          
        fieldInfo: {           
          fieldName: "nooftraveldays",
          label: "Number of Travel Days:",            
          fieldType:  FieldTypes.Numeric,
          showLabel: true,                  
        },
        config: { 
          isDisabled: false,                              
        }                
     },
     {
      fieldInfo: {         
        fieldName: "costs",
        label: "Costs:",
        fieldType: FieldTypes.LookupList,
        showLabel: true,
        
      },
      config: {                
        selectConfig: {
          selectedOption: [],
          options: ["Above Average","Average","Below Average"],
          optionConfig: {
            displayKey: "costs",
            search: true,
            limitTo: 3
          },
          multiple: false,                    
        },       
      }
    },
     {          
      fieldInfo: {           
        fieldName: "medevacpremium",
        label: "Med Evac Premium:",            
        fieldType:  FieldTypes.Numeric,
        showLabel: true,
       
      },
      config: { 
        isDisabled: true,                    
      }                
     }
    ]
   }
  } 
  customPremiumConfig() {
    return{
      customPremiumConfigList: [
        {          
          fieldInfo: {           
            fieldName: "custompremium",
            label: "Custom Premium:",            
            fieldType:  FieldTypes.Numeric,
            showLabel: true,           
          },
          config: { 
            isDisabled: false,            
            numericConfig: {
              type: "currency"
            },              
          }                
       }
     ] 
    }
  }  
  finalPremiumConfig() {
    return {
      finalPremiumConfigList: [
        {          
          fieldInfo: {           
            fieldName: "oneyearpremium",
            label: "One Year Premium:",            
            fieldType:  FieldTypes.Numeric,
            showLabel: true
          },
          config: { 
            isDisabled: true,
            numericConfig: {
              type: "currency"
            },               
          }                
       },
       {          
        fieldInfo: {          
          fieldName: "twoyearpremium",
          label: "Two Year Premium:",            
          fieldType:  FieldTypes.Numeric,
          showLabel: true
        },
        config: { 
          isDisabled: true,
          numericConfig: {
            type: "currency"
          },               
        }                
     },
     {          
      fieldInfo: {
         
        fieldName: "threeyearpremium",
        label: "Three Year Premium:",            
        fieldType:  FieldTypes.Numeric,
        showLabel: true
      },
      config: { 
        isDisabled: true,
        numericConfig: {
          type: "currency"
        },               
       }                
      }
     ] 
    }
  } 
}