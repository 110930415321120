<div class="col-md-12 mt-2 search-container">
  <!-- <h5 class="header-tab mt-2">{{ getLabels.searchByLabel }}</h5> -->
  
  <form [formGroup]="searchForm" novalidate (submit)="onSubmit()">
    <div class="form-row mt-2 mb-3">
      <div class="form-group col-md-6 col-lg-3">
        <label for="">Policy Number</label>
        <!-- <input type="" class="form-control" id="" placeholder="" value=""> -->
        <input
          type="text"
          class="form-control"
          acInputText
          [value]="entityNumberVal"
          formControlName="entitySearchField"
          [fieldInfo]="entityNumber"
          [name]="entityNumber.fieldName"
          [required]="entityNumber.required"
          (input)="onControlValueChange('field1Val', $event.target.value)"
          updateEvent="blur"
          autocomplete="off"
        />
      </div> 
      
      <div class="col-md-6 col-lg-2 mt-4 text-right">
        <!-- <button
          [disabled]="!isSubmitValid"
          class="btn btn-secondary w-100"
          type="submit"
        >
          Search
        </button> -->
        <button       
        class="btn btn-secondary w-100"
        type="submit"
      >
        Search
      </button>
      </div>

     
    </div>
  </form>
  
</div>
