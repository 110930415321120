import { Injectable } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormArray,
  Validators,
  FormControl
} from "@angular/forms";
import { ColumnDefinition } from "./grid.interface";
import { GridOptions } from "./grid.interface";
import { InputConfig } from "@tmhcc/framwork/form-inputs";

@Injectable({
  providedIn: "root"
})
export class CustomService {
  constructor(private fb: FormBuilder) {}

  tableRows: FormArray;

  initForm() {
    return this.fb.group({
      tableRows: this.fb.array([])
    });
  }

  defaultInitialRow(columnsSetting: Array<ColumnDefinition>) {
    return this.fb.group({
      tableRows: this.fb.array([this.createRowItem(columnsSetting)])
    });
  }

  createRowItem(columnsSetting: Array<InputConfig>): FormGroup {
    let group = {};
    columnsSetting.forEach(input_template => {
      let validators;
      if (input_template.config && input_template.config.validation) {
        validators = input_template.config.validation;
      } else {
        validators = null;
      }
      group[input_template.fieldInfo.fieldName] = new FormControl(
        "",
        Validators.compose(validators)
      );
    });
    return this.fb.group(group);
  }

  populateExistItem(rowItem, columnsSetting: Array<InputConfig>) {
    let group = {};
    Object.keys(rowItem).forEach((input, index) => {
      columnsSetting.forEach(column => {
        if (input == column.fieldInfo.fieldName) {
          let validators;
          if (column.config && column.config.validation) {
            validators = column.config.validation;
          } else {
            validators = null;
          }
          group[input] = new FormControl(
            Object.values(rowItem)[index],
            Validators.compose(validators)
          );
        }
      });
      //group[input] = new FormControl(Object.values(rowItem)[index]);
    });
    return this.fb.group(group);
  }

  normalTableOptions() {
    return {
      pagingType: "full_numbers",
      lengthChange: false,
      searching: false,
      processing: true
    };
  }

  inputTableOptions() {
    return {
      pagingType: "full_numbers",
      paginate: false,
      language: {
        emptyTable: "",
        zeroRecords: "",
        paginate: {
          previous: '<i class="fa fa-angle-left" aria-hidden="true"></i>',
          next: '<i class="fa fa-angle-right" aria-hidden="true"></i>',
          first: '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
          last: '<i class="fa fa-angle-double-right" aria-hidden="true"></i>'
        }
      },
      searching: false,
      lengthChange: false,
      ordering: false,
      info: false,
      scrollX: true
    };
  }

  setGridDataOptions(inputSetting: GridOptions) {
    return {
      pagingType: "full_numbers",
      language: {
        emptyTable: inputSetting.emptyTable ? inputSetting.emptyTable : "",
        zeroRecords: "",
        paginate: {
          previous: '<i class="fa fa-angle-left" aria-hidden="true"></i>',
          next: '<i class="fa fa-angle-right" aria-hidden="true"></i>',
          first: '<i class="fa fa-angle-double-left" aria-hidden="true"></i>',
          last: '<i class="fa fa-angle-double-right" aria-hidden="true"></i>'
        }
      },
      pageLength: inputSetting.pageLength ? inputSetting.pageLength : 10,
      lengthChange: false,
      searching: inputSetting.hasOwnProperty("searching")
        ? inputSetting.searching
        : false,
      info: false,
      processing: true,
      paging: inputSetting.hasOwnProperty("paging")
        ? inputSetting.paging
        : true,
      ordering: inputSetting.hasOwnProperty("ordering")
        ? inputSetting.ordering
        : true,
      order:[[0,'desc']],
      // order: [
      //   [
      //     inputSetting.orderBy ? inputSetting.orderBy : 1,
      //     inputSetting.orderAscending ? inputSetting.orderAscending : "asc"
      //   ]
      // ],
      scrollX: true
    };
  }
}
