import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  AfterViewInit
} from "@angular/core";
import { ColumnDefinition, GridOptions,EventTypeFromGrid } from "../shared/grid.interface";
import { CustomService } from "../shared/grid-form.service";

@Component({
  selector: "ac-grid-table",
  templateUrl: "./grid-table.component.html",
  styleUrls: ["./grid-table.component.scss"]
})
export class GridTableComponent implements OnInit, OnChanges, AfterViewInit {
  dtOptions: DataTables.Settings = {};
  @Input() columnSettings: ColumnDefinition[];
  @Input() gridSetting: GridOptions;
  @Input() isCheckbox: boolean;
  @Input() isRadioBox: boolean;
  @Input() isDeleteOption: boolean;
  @Input() parentDivName: string;
  @Input() records: any[];
  @Input() selectedRecords: any[];
  @Input() invalidRecordList: number[];

  //new added
  @Input() isCustomizedGrid: boolean;

  @Output() public childEvent = new EventEmitter();
  @Output() public childEventForNavigation = new EventEmitter();
  @Output() public childEventForDelete = new EventEmitter();
  @Output() public childEventForView = new EventEmitter();
  @Output() public childEventForChecked = new EventEmitter();
  @Output() public childEventForRadioButton = new EventEmitter();
  @Output() public childCehckBoxMatrixEvent = new EventEmitter();
  @Output() public childAllMatrixData = new EventEmitter();
  @Output() public childDeleteRecord = new EventEmitter();

  @Output() public downloadPolicyDocument = new EventEmitter();
  @Output() public eventForStandardGrid: EventEmitter<
    object
  > = new EventEmitter<object>();

  // static variables
  linkData: any;
  viewData: any;
  iconData: any;
  checkboxData: any;
  customData: any = [];
  dateData: any = [];

  columnMaps: ColumnDefinition[];
  selectedValuesList: any = [];
  selectedRadioValuesList: any = [];
  selectedMatrixCheckBoxList: any = [];
  isReadOnly: boolean;
  constructor(private customService: CustomService) { }

  ngOnInit() {
    //	this.dtOptions = this.customService.normalTableOptions();
    if (this.records) {
      this.records.forEach(element => {
        if (element.readOnly) {
          this.isReadOnly = true;
        }
      });
    }
    this.setGlobalCheckBoxValues();
  }

  ngOnChanges(): void {
    if (this.gridSetting) {
      this.dtOptions = this.customService.setGridDataOptions(this.gridSetting);
    }
    if (!this.isCheckbox) {
      this.isCheckbox = false;
    }
    // if(!this.radioButton){
    //   this.isRadioSelected=false;
    // }

    if (!this.isDeleteOption) {
      this.isDeleteOption = false;
    }

    if (!this.isRadioBox) {
      this.isRadioBox = false;
    }

    if (!this.isCustomizedGrid) {
      this.isCustomizedGrid = false;
    }

    if (this.columnSettings) {
      // when settings provided
      if (!this.isCustomizedGrid) {
        this.setCustomData(this.columnSettings);
      } else {
        this.columnMaps = this.columnSettings;
      }

      //  this.columnMaps = this.columnSettings;
    }
    this.setGlobalCheckBoxValues();
  }
  setGlobalCheckBoxValues() {
    if (this.records) {
      const resultObj1 = Object.values(this.records).every(
        item => item["checkedOption1"] === true
      );
      if (resultObj1 == true) {
        $(".checkedOption1").prop("checked", true);
      } else {
        $(".checkedOption1").prop("checked", false);
      }
      const resultObj2 = Object.values(this.records).every(
        item => item["checkedOption2"] === true
      );
      if (resultObj2 == true) {
        $(".checkedOption2").prop("checked", true);
      } else {
        $(".checkedOption2").prop("checked", false);
      }
      const resultObj3 = Object.values(this.records).every(
        item => item["checkedOption3"] === true
      );
      if (resultObj3 == true) {
        $(".checkedOption3").prop("checked", true);
      } else {
        $(".checkedOption3").prop("checked", false);
      }
      const resultObj4 = Object.values(this.records).every(
        item => item["checkedOption4"] === true
      );
      if (resultObj4 == true) {
        $(".checkedOption4").prop("checked", true);
      } else {
        $(".checkedOption4").prop("checked", false);
      }
    }
  }
  deleteRow(index) {
    this.eventForStandardGrid.emit({
      key: EventTypeFromGrid.delete,
      divId: this.parentDivName,
      value: index
    });
  }
  ngAfterViewInit() {
    this.dtOptions.columnDefs = [
      {
        targets: this.returnNonSortable(),
        orderable: false,
        orderData: []
      }
    ];

    if (this.selectedRecords && this.selectedRecords.length > 0) {
      this.selectedRecords.forEach(element => {
        let index = this.getIndexFromArray(this.records, element);
        $("#" + this.parentDivName)
          .find("#checkBoxId_" + index)
          .prop("checked", true);
        this.selectedValuesList.push(element);
      });
      this.saveSelectedValues();
    }
  }

  returnNonSortable() {
    let data = [];
    if (this.isCheckbox || this.isRadioBox) {
      data.push(0);
    }
    if (this.isDeleteOption) {
      if (this.isCheckbox || this.isRadioBox) {
        data.push(this.columnSettings.length + 1);
      } else {
        data.push(this.columnMaps.length);
      }
    }
    if (this.columnMaps) {
      this.columnMaps.forEach((element, index) => {
        if (
          element.hasOwnProperty("isSortable") &&
          element.isSortable === false
        ) {
          if (this.isCheckbox || this.isRadioBox) {
            data.push(index + 1);
          } else {
            data.push(index);
          }
        }
      });
    }
    return data;
  }

  selectAllCheckbox(event: any, value: any) {
    this.selectedValuesList = [];
    if (event.currentTarget.checked) {
      if (value) {
        this.selectedValuesList = [...value];
        this.records.forEach(value => {
          value.checked = true;
        });
      }
    } else {
      this.records.forEach(value => {
        value.checked = false;
      });
    }
    this.saveSelectedValues();
  }
  selectAllRadioButton(event: any, value: any, column: any, controlName: any) {
    // window.alert(column);

    if (event.currentTarget.checked) {
      if (value) {
        this.selectedRadioValuesList = [...value];

        this.records.forEach(value => {
          this.columnMaps.forEach(element => {
            if (
              element.controlInfo &&
              element.columnEventType == "radioCheckBoxMatrix"
            ) {
              if (element.controlInfo.controlName == controlName) {
                value[element.controlInfo.controlName] = false;
                value[element.controlInfo.controlName] = true;
              } else {
                value[element.controlInfo.controlName] = false;
                $("." + element.controlInfo.controlName).prop("checked", false);
              }
            }
          });
        });
      }
    } else {
      let i = 0;
      this.selectedRadioValuesList = [];
      this.records.forEach(value => {
        value[controlName] = false;
        $(".radioButton_" + column).prop("checked", false);
        $(".allRadioSelected").prop("checked", false);
        i++;
      });
    }
    this.saveSelectedRadiosValues();
  }
  selectAllMatrixCheckBox(
    event: any,
    value: any,
    column: any,
    controlName: any
  ) {
    if (event.currentTarget.checked) {
      if (value) {
        this.selectedRadioValuesList = [...value];
        this.records.forEach(value => {
          value[controlName] = true;
        });
      }
    } else {
      this.records.forEach(value => {
        value[controlName] = false;
      });
    }
    this.saveSelectedRadiosValues();
  }
  selectCheckValue(e, value: any) {
    if (e.currentTarget.checked) {
      if (value) {
        this.selectedValuesList.push(value);
        this.saveSelectedValues();
      }
    } else {
      this.selectedValuesList.splice(
        this.getIndexFromArray(this.selectedValuesList, value),
        1
      );
      this.records[this.getIndexFromArray(this.records, value)].checked = false;
      this.saveSelectedValues();
    }
    if (this.isCheckbox) {
      this.checkUncheckSelectAll(e.currentTarget.checked);
    }
  }

  checkUncheckSelectAll(checked: any) {
    if (checked) {
      if (
        this.records &&
        this.selectedValuesList &&
        this.records.length === this.selectedValuesList.length
      ) {
        $("#selectAllCheckboxId").prop("checked", true);
      }
    } else {
      $("#selectAllCheckboxId").prop("checked", false);
    }
  }

  selectRadioValueList(e, value: any, columnindex: number, controlName: any) {
    if (e.currentTarget.checked) {
      if (value) {
        this.columnMaps.forEach(element => {
          if (
            element.controlInfo &&
            element.columnEventType == "radioCheckBoxMatrix"
          ) {
            if (element.controlInfo.controlName == controlName) {
              value[element.controlInfo.controlName] = true;
            } else {
              value[element.controlInfo.controlName] = false;
              $("." + element.controlInfo.controlName).prop("checked", false);
            }
          }
        });
        this.getIndexFromMatrixArray(this.selectedRadioValuesList, value);
        this.selectedRadioValuesList.push(value);
        this.saveSelectedRadiosValues();
      }
    } else {
      this.saveSelectedRadiosValues();
    }
    this.setCheckboxOnAllSelectedValues(controlName);
  }
  isMatrixOptionalChecked(
    e,
    value: any,
    columnindex: number,
    controlName: any
  ) {
    if (e.currentTarget.checked) {
      if (value) {
        this.columnMaps.forEach(element => {
          if (
            element.controlInfo &&
            element.columnEventType == "matrixCheckBox"
          ) {
            if (element.controlInfo.controlName == controlName) {
              value[element.controlInfo.controlName] = true;
            }
          }
        });
      }
    } else {
      value[controlName] = false;
    }
    this.getIndexFromMatrixArray(this.selectedRadioValuesList, value);
    this.selectedRadioValuesList.push(value);
    this.saveSelectedRadiosValues();
    this.setCheckboxOnAllSelectedValues(controlName);
  }
  compareMatrixObjects(o1, o2) {
    return Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every(p => o1[p] === o2[p])
      ? true
      : false;
  }
  setCheckboxOnAllSelectedValues(controlName) {
    if (this.records.length == this.selectedRadioValuesList.length) {
      const resultObj1 = Object.values(this.selectedRadioValuesList).every(
        item => item[controlName] === true
      );
      if (resultObj1 == true) {
        $("." + controlName).prop("checked", true);
      } else {
        $("." + controlName).prop("checked", false);
      }
    } else {
      const checked = Object.values(this.records).every(
        item => item[controlName] === true
      );
      if (checked == true) {
        $("." + controlName).prop("checked", true);
      } else {
        $("." + controlName).prop("checked", false);
      }
    }
  }
  getIndexFromMatrixArray(arrayToCompare, objectToCompare) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < arrayToCompare.length; i++) {
      if (this.compareMatrixObjects(arrayToCompare[i], objectToCompare)) {
        this.selectedRadioValuesList.splice(i, 1);
      }
    }
  }
  getIndexFromArray(arrayToCompare, objectToCompare) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < arrayToCompare.length; i++) {
      if (this.compareObjects(arrayToCompare[i], objectToCompare)) {
        return i;
      }
    }
  }
  compareObjects(o1, o2) {
    return Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every(p => o1[p] === o2[p])
      ? true
      : false;
  }

  selectRadioValue(e, value: any) {
    if (e.currentTarget.checked) {
      // this.selectedValuesList = [];
      // if (value) {
      // 	this.selectedValuesList.push(value);
      // }
      if (value) {
        this.childEvent.emit(value);
      }
    }
  }
  saveSelectedRadiosValues() {
    this.childAllMatrixData.emit(this.selectedRadioValuesList);
  }

  saveSelectedValues() {
    this.childEvent.emit(this.selectedValuesList);
  }
  saveSelectedMatrixCheckedValues() {
    this.childCehckBoxMatrixEvent.emit(this.selectedMatrixCheckBoxList);
  }

  resetCheckBox() {
    this.selectedValuesList = [];
    //	$('input[type=checkbox]').prop('checked', false);
    console.log(this.parentDivName);
    $("#" + this.parentDivName)
      .find("input[type=checkbox]:checked")
      .prop("checked", false);
    $("#" + this.parentDivName)
      .find("input[type=radio]")
      .prop("checked", false);
  }

  selectedLink(selectedValue) {
    this.childEventForNavigation.emit(selectedValue);
  }

  // deleteRow(index) {
  //   this.childEventForDelete.emit(index);
  // }

  viewRowRecords(data) {
    this.childEventForView.emit(data);
  }

  isChecked(data) {
    this.childEventForChecked.emit(data);
  }
  isRadioSelected(data) {
    this.childEventForRadioButton.emit(data);
  }
  isDeleteRecord(data) {
    this.childDeleteRecord.emit(data);
  }
  validateRecord(index: number, isDeleted: boolean, readOnly: boolean) {
    if (this.invalidRecordList && this.invalidRecordList.length !== 0) {
      if (this.invalidRecordList.includes(index)) {
        return "table-danger";
      }
    } else if (isDeleted == true) {
      return "is-hidden";
    } else {
      return "";
    }
  }
  trackByFun(index, map) {
    return index;
  }

  setCustomData(fields) {
    this.columnMaps = [];
    fields.forEach(element => {
      if (element.columnType === "link") {
        this.linkData = element;
      } else if (element.columnType === "view") {
        this.viewData = element;
      } else if (element.columnType === "checkbox") {
        this.checkboxData = element;
      }
      else if (element.columnType === "date") {
        this.dateData.push(element);
      }
      else {
        if (element.columnType === "currency") {
          element.alignment = "text-right";
        } else {
          element.alignment = "text-left";
        }
        if (
          element.fieldInfo.optionalField &&
          element.fieldInfo.optionalField === "icon"
        ) {
          this.iconData = element;
        }
        this.columnMaps.push(element);
      }
      if (
        element.columnEventType === "radioCheckBoxMatrix" ||
        element.columnEventType === "matrixCheckBox"
      ) {
        this.customData.push(element);
      }
    });
  }
}
