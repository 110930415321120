import {
  PolicyTxnRequest,
  PolicyTxnData,
  LOBs,
  UWQuotes,
  EndorsementForms
} from "@tmhcc/models";
import { IssueNewBusinessRequest } from "@tmhcc/data-service";

export class QuotePolicyTxn {
  public static readonly type = "[QuotePolicyTxn] action";
  constructor(public polcyTxnRequest: PolicyTxnRequest) {}
}

export class UpdateQuotePolicyTxnData {
  public static readonly type = "[UpdateQuotePolicyTxnData] action";
  constructor(public uwQuoteId: number, public payload: LOBs[]) {}
}

export class IssueNewBusiness {
  public static readonly type = "[IssueNewBusiness] action";
  constructor(public issueNewBusinessRequest: IssueNewBusinessRequest) {}
}

export class UpdatePolicyData {
  public static readonly type = "[UpdatePolicyData] action";
  constructor(public payload: UWQuotes[]) {}
}

export class AddOptionalForm {
  public static readonly type = "[AddOptionalForm] action";
  constructor(public optionalForms: EndorsementForms) {}
}
