import {
  Component,
  forwardRef,
  ViewChild,
  HostListener,
  DoCheck,
  Input
} from "@angular/core";
import { InputBase } from "../input-base";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { BsDatepickerDirective } from "ngx-bootstrap/datepicker";
import { Datepicker } from "@tmhcc/framwork/form-inputs";

@Component({
  selector: "hcc-datepicker-input",
  templateUrl: "./date-picker.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ]
})
export class DatePickerComponent extends InputBase<string> implements DoCheck {
  datepickerConfig: Datepicker;
  isCalanderOpen = false;
  @ViewChild("dp", { static: false })
  datepicker: BsDatepickerDirective;
  // @Input() isDatePicker: boolean = true;
  @HostListener("window:scroll")
  onScrollEvent() {
    if (
      this.InputConfig != null &&
      this.InputConfig.hasOwnProperty("datePickerConfig") &&
      this.InputConfig.datePickerConfig.hasOwnProperty("hideOnScroll")
    ) {
      if (this.InputConfig.datePickerConfig.hideOnScroll) {
        this.datepicker.hide();
      }
    }
  }

  ngDoCheck() {
    if (
      this.InputConfig.datePickerConfig.hasOwnProperty("isCalanderRequired")
    ) {
      if (
        !this.InputConfig.datePickerConfig.isCalanderRequired &&
        this.datepicker
      ) {
        this.datepicker.hide();
      }
    }
  }

  onChange(event) {
    if (event) {
      this.frmGroup.get(this.fieldInfo.fieldName).setValue(event);
    }
  }

  constructor() {
    super();
  }

  getDatePickerConfig() {
    this.datepickerConfig = {
      isAnimated: this.InputConfig.datePickerConfig.isAnimated,
      adaptivePosition: this.InputConfig.datePickerConfig.adaptivePosition,
      dateInputFormat: this.InputConfig.datePickerConfig.dateInputFormat,
      returnFocusToInput: this.InputConfig.datePickerConfig.returnFocusToInput,
      selectFromOtherMonth: this.InputConfig.datePickerConfig
        .selectFromOtherMonth,
      containerClass:
        this.InputConfig.datePickerConfig.containerClass || "theme-dark-blue",
      showWeekNumbers: this.InputConfig.datePickerConfig.showWeekNumbers
    };
    return this.datepickerConfig;
  }

  calanderClicked() {
    this.isCalanderOpen = !this.isCalanderOpen;
  }
}
