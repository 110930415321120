import {
  Directive,
  HostListener,
  ElementRef,
  OnInit,
  Input,
  AfterViewInit,
  Renderer2,
  ChangeDetectorRef,
  Attribute
} from "@angular/core";
import { CurrencyService } from "@tmhcc/framwork/services";
import { TextFormElement } from "../text/text-form-element";
@Directive({
  selector: "[HccMyCurrency]"
})
export class MyCurrencyDirective extends TextFormElement
  implements OnInit, AfterViewInit {
  private elBase: any;
  constructor(
    private customCurrencyPipe: CurrencyService,
    protected renderer: Renderer2,
    protected el: ElementRef,
    protected csRef: ChangeDetectorRef,
    @Attribute("updateEvent") protected updateEvent
  ) {
    super(renderer, el, csRef, updateEvent);
  }
  ngOnInit() {
    this.elBase = this.el.nativeElement;
  }

  ngAfterViewInit(): void {
    this.updateValue();
  }

  @HostListener("focus", ["$event.target.value", "$event"])
  onFocus(value, event) {
    this.elBase.value = this.elBase.value.replace(
      /[,$,R$,kr,€,$,₹,¥,₩,R,£,zł,Rs]/g,
      ""
    );
    // if (event.which == 9) {
    // 	return false;
    // }
    this.elBase.select();
  }

  @HostListener("blur", ["$event.target.value"])
  onBlur(value) {
    if (isNaN(parseFloat(value))) {
      this.elBase.value = "";
      return;
    }
    //  this.elBase.value = this.customCurrencyPipe.toCurrency(value);
    this.updateValue();
  }

  updateValue() {
    if (this.fieldInfo.currencyType) {
      this.elBase.value = this.customCurrencyPipe.toCurrency(
        this.elBase.value,
        this.fieldInfo.currencyType
      );
    } else {
      this.elBase.value = this.customCurrencyPipe.toCurrency(this.elBase.value);
    }
  }

  @HostListener("keydown", ["$event"])
  onKeyDown(event) {
    let e = <KeyboardEvent>event;
    if (
      [46, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if (
      (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
      (e.keyCode < 96 || e.keyCode > 105)
    ) {
      e.preventDefault();
    }
  }
}
