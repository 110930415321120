import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  FieldInfo,
  InputConfig,
  FieldTypes
} from "@tmhcc/framwork/form-inputs/src";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { CoreInputValidationService } from "@tmhcc/framwork/core-inputs";
import { DialogConfig, DialogRef, DialogSize } from "@tmhcc/framwork/dialog";
import { QuoteStepStoreService } from "../../store/facade/quote-step-store.service";
import { QuotePolicyStepStore } from "../../store/state/quote-policy-step.state";
import {
  FormsDialogConfig,
  FormsDialogConfigData
} from "./forms-dialog.config";
import { FormsECOService, EndorsmentFormsRequest } from "@tmhcc/data-service";
import { CommonContentService } from "@tmhcc/content-service";
import { EndorsementForms } from "@tmhcc/models";

@Component({
  selector: "ec-forms-dialog",
  templateUrl: "./forms-dialog.component.html",
  styleUrls: ["./forms-dialog.component.scss"]
})
export class FormsDialogComponent extends FormsDialogConfig
  implements OnInit, OnDestroy {
  formgroup: FormGroup;
  quotePolicyStepStore: QuotePolicyStepStore;
  currentUWQuoteID: number;
  recordOptionalFormList: EndorsementForms[];
  isFormValid: boolean = false;
  constructor(
    private formBuilder: FormBuilder,
    private validationService: CoreInputValidationService,
    private dialogRef: DialogRef,
    public formsECOService: FormsECOService,
    public dialogData: DialogConfig<FormsDialogConfigData>,
    public contentService: CommonContentService
  ) {
    super(contentService);
  }

  ngOnInit(): void {
    this.initFormGroup();
    if (this.dialogData.data.optionalForms) {
      this.recordOptionalFormList = this.dialogData.data.optionalForms;
      this.FormsSelectConfig.config.selectConfig.options = this.recordOptionalFormList;
    }
    this.formgroup.valueChanges.subscribe(value => {
      if (!this.formgroup.invalid) {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
    });
  }
  initFormGroup() {
    const controls = {};
    controls[this.FormSelectList.fieldName] = ["", Validators.required];
    this.formgroup = this.formBuilder.group(controls);
  }

  /***  Saved Form Integration's****/
  saveOptionalForms() {
    if (!this.isFormValid) {
      this.validationService.setFormSubmission(true);
    } else {
      const optionalFormVsalue = this.mapNewEndorsmentForm();
      this.dialogRef.close({ optionalFormVsalue });
    }
  }
  mapNewEndorsmentForm() {
    if (this.formgroup.controls["policyforms"].value) {
      let formValue = this.formgroup.controls["policyforms"].value;
      return formValue;
    }
  }
  /*** Delete Forms***/
  onClose() {
    this.isFormValid = true;
    this.dialogRef.close(false);
  }
  FormSelectList: FieldInfo = {
    fieldName: "policyforms",
    label: this.getLabels.slectFormInputLabel,
    fieldType: FieldTypes.LookupList,
    placeholder: this.getLabels.slectFormInputLabel,
    showLabel: false,
    validation: {
      required: this.getLabels.selctFornInputReqMsg
    }
  };
  ngOnDestroy() {
    this.validationService.setFormSubmission(false);
  }
  FormsSelectConfig: InputConfig = {
    config: {
      selectConfig: {
        options: [],
        optionConfig: {
          displayKey: "FormName",
          search: true,
          limitTo: 3
        },
        multiple: false
      }
    },
    fieldInfo: this.FormSelectList
  };
}
