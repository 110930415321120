import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { QuoteCreateStoreService } from "../store/facade/quote-create-store.service";
import { QuoteCreateRequest } from "libs/data-service/def/quote-new-bussiness";

@Injectable()
export class QuoteCreateResolver implements Resolve<any> {
  constructor(private quoteCreateStoreService: QuoteCreateStoreService) {}

  resolve() {
    // const quoteCreateRequest: QuoteCreateRequest={

    // };
    // this.quoteCreateStoreService.createNewQuote(quoteCreateRequest);
    return true;
  }
}
