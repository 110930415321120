<div class="row mt-3">
  <div class="col-6 col-md-3 col-lg-2">
    <label>{{ quotePolicyLabel }}</label>
    <div>
      <strong>{{ policyNumber }}</strong>
    </div>
  </div>
  <div class="col-6 col-md-3 col-lg-2 text-break">
    <label>Insured</label>
    <div>
      <strong>{{ insuredName }}</strong>
    </div>
  </div>
  <div class="col-6 col-md-3 col-lg-2 text-break">
    <label>Broker</label>
    <div>
      <strong>{{ brokerName }}</strong>
    </div>
  </div>
  <div class="col-6 col-md-3 col-lg-2">
    <label>Product</label>
    <div>
      <strong>{{ policyType }}</strong>
    </div>
  </div>
</div>
