import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { DashboardComponent } from "./dashboard.component";
import { ViewSearchModule } from "./view-search/view-search.module";
import { DashbardNavModule } from "./dashbard-nav/dashbard-nav.module";
import { SearchQuoteModule } from "./search-quote/search-quote.module";
import { NgxsModule } from "@ngxs/store";
import { DashboardStateDwld } from "./store/state/dashbard.state";
import { DashboardResolver } from "./dashboard-resolver";
import { QuotePolicySearchService,PolicyDownloadService } from "@tmhcc/data-service";
import { DialogModule, DialogConfig } from '@tmhcc/framwork/dialog';
import { SearchPolicyModule } from "./search-policy/search-policy.module";
import { PolicyDialogComponent } from './policy-dialog/policy-dialog.component';
import { ReactiveFormsModule,FormsModule,FormGroupDirective } from '@angular/forms';
import { OktaAuthGuard } from '@okta/okta-angular';
import { CommonModule } from "@angular/common";
const routes: Routes = [
  {
    path: "",
    component: DashboardComponent,
    resolve: { DashboardResolver }
  }
];

@NgModule({
  declarations: [DashboardComponent, PolicyDialogComponent],
  imports: [
    RouterModule.forChild(routes),
    ViewSearchModule,
    //SearchQuoteModule,
    //DashbardNavModule,
    [NgxsModule.forFeature([DashboardStateDwld])],
    SearchPolicyModule,
    ReactiveFormsModule,
    FormsModule,
    DialogModule,
    CommonModule
  ],
  providers: [QuotePolicySearchService, 
    DashboardResolver,
    PolicyDownloadService,
    DialogConfig,
    OktaAuthGuard,
    FormGroupDirective
  ],
  exports: [RouterModule]
})
export class DashboardModule {}
