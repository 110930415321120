import { Pipe, PipeTransform } from "@angular/core";
import { PageTabItem } from "@tmhcc/framwork/models";

@Pipe({
  name: "pageTabItem"
})
export class PageTabItemPipe implements PipeTransform {
  transform(value: PageTabItem, selectedId: any): boolean {
    return value.id === selectedId;
  }
}
