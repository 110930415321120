import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { AppConfigService } from "@tmhcc/global-services";
import { AppConfig } from "@tmhcc/models";
import { HttpOptions, BaseHttpService } from "@tmhcc/framwork/http";
import { environment } from 'apps/policy-admin/src/environments/environment';

export abstract class BaseContentService extends BaseHttpService {
  protected config: AppConfig;
  protected serviceBaseUrl: string;

  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http);

    //this.config = configService.config;
    this.serviceBaseUrl = environment.appConfig.dataServiceBaseUrl;
  }

  protected get<T>(url: string, options?: HttpOptions): Observable<T> {
    return this.http.get<T>(url, options);
  }

  protected post<T>(url: string, options?: HttpOptions): Observable<T> {
    return this.http.post<T>(url, options.body, {
      params: options.params,
      headers: options.headers
    });
  }
}
