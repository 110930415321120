import { Component, ChangeDetectorRef, OnInit } from "@angular/core";
import { DialogService, DialogConfig } from "@tmhcc/framwork/dialog";
import {
  SubjectivityGridRow,
  SubjectivityGrid,
  PolicyTxnData,
  PolicyTxnRequest
} from "@tmhcc/models";
import { DateService } from "@tmhcc/framwork/services";
import { CommonContentService, Labels } from "@tmhcc/content-service";
import { SubjectivityDialogConfigData } from "./subjectivity-dialog/subjectivity-dialog.config";
import { SubjectivityConfig } from "./subjectivity.config";
import { SubjectivityDialogComponent } from "./subjectivity-dialog/subjectivity-dialog.component";
import { GridOptions, ColumnDefinition } from "@tmhcc/framwork/grid/src";
import {
  SubjectivityService,
  GetPolicySubjectivitiesRequest,
  SaveSubjectiviyRequest,
  SubjectivityRequest,
  SaveMiscellaneousChangesRequest
} from "@tmhcc/data-service";
import { Router } from "@angular/router";
import { LoaderService } from "@tmhcc/framwork/loader/src";
import { QuoteStepStoreService } from "../store/facade/quote-step-store.service";
import { QuotePolicyStepStore } from "../store/state/quote-policy-step.state";
import { take } from "rxjs/operators";
import { AllowedRoute } from "../../shared/page-header/page-header-navigation/page-header-navigation-def";
import { PageHeaderService } from "../../shared/page-header/page-header.service";

@Component({
  selector: "ec-subjectivity",
  templateUrl: "./subjectivity.component.html",
  styleUrls: ["./subjectivity.component.scss"]
})
export class SubjectivityComponent extends SubjectivityConfig
  implements OnInit {
  constructor(
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    public dateService: DateService,
    private contentService: CommonContentService,
    private dialogConfig: DialogConfig<SubjectivityDialogConfigData>,
    private subjectivityService: SubjectivityService,
    private router: Router,
    private pageHeaderService: PageHeaderService,
    private quoteStepStoreService: QuoteStepStoreService,
    private loaderService: LoaderService,
  ) {
    super(contentService);
    this.getLabels = this.contentService.getLabels();
  }
  getLabels: Labels;
  hasUnsavedData: boolean;
  gridSetting: GridOptions;
  records: SubjectivityGridRow[];
  selectedRecords: SubjectivityGridRow[];
  columnSettings: ColumnDefinition[];
  policyTxnStoreData: PolicyTxnData;
  defaultList: SubjectivityGrid;
  showTable: boolean;
  policyTxnIdValue: string;
  isPolicyBound: boolean;
  disableAddEditSub: boolean;
  index: number;
  payload: GetPolicySubjectivitiesRequest = {
    PolicyTxnId: Number(this.policyTxnIdValue)
  };

  ngOnInit(): void {
    this.loaderService.show();
    this.isPolicyBound = false;
    this.hasUnsavedData = false;
    this.showTable = false;
    this.records = [];
    this.selectedRecords = [];
    this.initPolicyId();
    this.gridSetup();
    this.getProgressIndex();
  }

  getProgressIndex() {
    this.pageHeaderService.getBadgeCount.subscribe(badgeCount => {
      this.index = badgeCount;
    });
  }

  initPolicyId() {
    this.quoteStepStoreService.data$
      .pipe(take(1))
      .subscribe((data: QuotePolicyStepStore) => {
        if (data && data.policyTxnData && data.policyTxnData.PolicyTxnId) {
          this.policyTxnIdValue = data.policyTxnData.PolicyTxnId;
          this.setupPolicyInformation(data);
          this.getSubjectivityList();
        } else {
          this.router.navigate([AllowedRoute.Dashboard]);
        }
      });
  }

  private setupPolicyInformation(data: QuotePolicyStepStore) {
    data.policyTxnData.PolicyMovement.forEach((Object: any, index: number) => {
      if (data.policyTxnData.PolicyMovement[index].Status) {
        if (
          data.policyTxnData.PolicyMovement[index].Status.toLowerCase() ===
          "completed"
        ) {
          this.isPolicyBound = true;
        }
      }
    });
    if (this.isPolicyBound) {
      this.disableAddEditSub = true;
    } else {
      this.disableAddEditSub = false;
    }
  }

  getSubjectivityList() {
    this.loaderService.show();
    this.payload.PolicyTxnId = Number(this.policyTxnIdValue);
    this.subjectivityService.getSubjectivity(this.payload).subscribe(data => {
      if (data && data.subjectivityData) {
        this.defaultList = data;
        this.records = [];
        this.cdr.detectChanges();
        this.records = data.subjectivityData;
        this.loaderService.hide();
      }
      else{
        this.loaderService.hide();
      }
    });
  }

  gridSetup() {
    this.gridSetting = this.gridSettingConfig;
    if (this.isPolicyBound) {
      this.columnSettings = this.columnSettingsEditableConfig;
    } else {
      this.columnSettings = this.columnSettingsReadOnlyConfig;
    }
  }

  onChecked(record: any) {
    record.checked = !record.checked;
  }

  selectRecord(record: any) {}

  openNewSubjectivityDialog() {
    this.dialogConfig.modalSetting = this.dialogModalSettingConfig;
    this.dialogConfig.data = {
      subjectivity: this.defaultList ? this.defaultList : null
    };

    const ref = this.dialogService.open(
      SubjectivityDialogComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {
      if (result) {
        this.getSubjectivityList();
      }
      console.log(result);
    });
  }

  onBackClicked() {
    if (this.index === 2) {
      this.router.navigate([AllowedRoute.Quote]);
    } else if (this.index === 3) {
      this.router.navigate([AllowedRoute.Bind]);
    }
  }

  onSaveSubjectivity(){
    if (this.isPolicyBound) {
      const request = this.prepareMiscellaneousSaveRequest();
      this.saveMiscellaneous(request);
    } else {
      const request = this.prepareSubjectivitySaveRequest();
      const routToNext=true;
      this.saveSubjectivity(request,routToNext);
    }
  }



  onSaveClicked() {
    if (this.isPolicyBound) {
      const request = this.prepareMiscellaneousSaveRequest();
      this.saveMiscellaneous(request);
    } else {
      const request = this.prepareSubjectivitySaveRequest();
      const routToNext=false;
      this.saveSubjectivity(request,routToNext);
    }
  }

  saveMiscellaneous(request: SaveMiscellaneousChangesRequest) {
    // this.loaderService.hide();
    // setTimeout(() => {
    //   this.loaderService.hide();
    // }, 10000);
    // this.loaderService.hide();
    this.subjectivityService
      .saveMiscellaneousChangesECO(request)
      .subscribe(data => {
        //this.loaderService.hide();
      });
  }

  prepareSubjectivitySaveRequest(): SaveSubjectiviyRequest {
    let saveRequest: SaveSubjectiviyRequest;

    saveRequest = {
      PolicyTxnId: Number(this.policyTxnIdValue),
      Subjectivities: this.prepareSubjectivityList()
    };
    return saveRequest;
  }

  prepareMiscellaneousSaveRequest(): SaveMiscellaneousChangesRequest {
    let saveMiscellaneousRequest: SaveMiscellaneousChangesRequest;
    saveMiscellaneousRequest = {
      PolicyTxnId: Number(this.policyTxnIdValue),
      Subjectivities: this.prepareSubjectivityList()
    };
    return saveMiscellaneousRequest;
  }

  prepareSubjectivityList(): SubjectivityRequest[] {
    const subjectivityList: SubjectivityRequest[] = [];
    if (this.records && this.records.length !== 0) {
      this.records.forEach((gridRow: SubjectivityGridRow) => {
        let subjectivity: SubjectivityRequest;
        subjectivity = {
          IsResolved: gridRow.checked,
          PolicySubjectivityId: gridRow.policySubjectivityId,
          SubjectivityDescription: gridRow.description,
          SubjectivityTypeId: 1
        };
        subjectivityList.push(subjectivity);
      });
    }
    return subjectivityList;
  }

  saveSubjectivity(request,routToNext) {
    this.loaderService.show();
    this.subjectivityService.saveSubjectivity(request).subscribe(data => {
      if (data) {
        //   this.records = data.subjectivityData;
        this.loaderService.hide();
        if (this.index === 2 && routToNext) {
          this.router.navigate([AllowedRoute.Quote]);
        } else if (this.index === 3 && routToNext) {
          this.router.navigate([AllowedRoute.Summary]);
        }
      }
      this.loaderService.hide();
    });
  }
}
