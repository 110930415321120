import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { BaseDataService } from "./base-data-service";
import { AppConfigService } from "@tmhcc/global-services";
import { map, catchError } from "rxjs/operators";
import { isEmpty } from "@tmhcc/util";
import { EcoMasterRequest, EcoMasterResponse } from "./def/eco-master";
import { EcoMaster } from "libs/models/classes/eco-master-data";

@Injectable()
export class EcoMasterData extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }

  masterData: EcoMaster;

  getMasterData() {
    return this.masterData;
  }

  EcoMasterData(payload: EcoMasterRequest): Observable<EcoMaster> {
    const url = `${this.serviceBaseUrl}/PolicyMasterData/${payload.SubCompanyCode}`;

    return this.get<EcoMasterResponse>(url).pipe(
      map(data => {
        this.masterData = this.masterPolicyMapper(data);
        return this.masterData;
      }),
      catchError(this.handleError)
    );
  }

  ecoPolicySearch(policyNumber: number): Observable<EcoMaster> {
    const url = `${this.serviceBaseUrl}/PolicyMasterData/${policyNumber}/ECO`;

    return this.get<EcoMasterResponse>(url).pipe(
      map(data => {
        this.masterData = this.masterPolicyMapper(data);
        return this.masterData;
      }),
      catchError(this.handleError)
    );
  }

  fetchMasterDataAsync(payload: EcoMasterRequest): Observable<EcoMaster> {
    if (isEmpty(payload.SubCompanyCode)) {
      throw new Error("SubCompany must be set for claim master data.");
    }
    const url = `${this.serviceBaseUrl}/PolicyMasterData/${payload.SubCompanyCode}`;
    return this.get<EcoMasterResponse>(url).pipe(
      map(data => {
        this.masterData = this.masterPolicyMapper(data);
        return this.masterData;
      }),
      catchError(this.handleError)
    );
  }

  fetchMasterDataAsyncWithPolicyType(
    policyNumber: number
  ): Observable<EcoMaster> {
    if (!policyNumber) {
      throw new Error("policyNumber must be set for claim master data.");
    }
    const url = `${this.serviceBaseUrl}/PolicyMasterData/${policyNumber}/ECO`;
    return this.get<EcoMasterResponse>(url).pipe(
      map(data => {
        this.masterData = this.masterPolicyMapper(data);
        return this.masterData;
      }),
      catchError(this.handleError)
    );
  }

  private masterPolicyMapper(data: EcoMasterResponse): EcoMaster {
    let {
      Countries,
      StateOrProvinces,
      Underwriters,
      ClaimHandler,
      PolicyTypes,
      UnderwriterAssistants,
      SubmissionTypes,
      RenewalOptions,
      TermTypes,
      PaymentPlans,
      UserConfigurableDataElements,
      ConfigSubjectivities,
      WritingCompaniesByStates,
      FormsetTypes,
      TaxesAndFees
    } = data;

    let entitiyserach: EcoMaster = {
      Countries,
      StateOrProvinces,
      Underwriters,
      ClaimHandler,
      PolicyTypes,
      UnderwriterAssistants,
      SubmissionTypes,
      RenewalOptions,
      TermTypes,
      PaymentPlans,
      UserConfigurableDataElements,
      ConfigSubjectivities,
      WritingCompaniesByStates,
      FormsetTypes,
      TaxesAndFees
    };

    return entitiyserach;
  }
}
