<form *ngIf="!formSkipCheck" [formGroup]="frmGroup" (ngSubmit)="onSubmit()">
  <!-- SECTION 1 -->
  <div
    *ngFor="let fieldRow of eventDetailsFieldRows; let fieldRowIndex = index"
    class="form-row"
  >
    <ng-container *ngFor="let items of eventDetailsFields">
      <div
        *ngIf="
          items.fieldInfo.fieldName === 'eventDeductible' &&
          items.config.optionField === fieldRowIndex + 1
        "
        [ngClass]="items.colClass ? items.colClass : ''"
      >
        <div>
          {{ items.fieldInfo.label }}
        </div>
        <div class="row px-3">
          <div class="col-3 event-deductible">
            <div class="row">
              <input
                id="eve-value"
                type="radio"
                acRadiobutton
                value="value"
                formControlName="eventDeductibleType"
                name="eventDeductibleType"
                (input)="onEventDedTypeChange($event.target.value)"
                updateEvent="blur"
              />
              <label for="eve-value">{{
                newEventFieldConfig().eventDeductibleRadioField[0].label
              }}</label>
            </div>
            <div class="row">
              <input
                id="eve-percent"
                type="radio"
                acRadiobutton
                value="percent"
                formControlName="eventDeductibleType"
                name="eventDeductibleType"
                (input)="onEventDedTypeChange($event.target.value)"
                updateEvent="blur"
              />
              <label for="eve-percent">{{
                newEventFieldConfig().eventDeductibleRadioField[1].label
              }}</label>
            </div>
          </div>
          <div class="col-9 pr-0">
            <hcc-number-input
              [control]="items.fieldInfo.fieldName"
              [frmGroup]="frmGroup"
              [fieldInfo]="items.fieldInfo"
              [InputConfig]="
                eventDedCurrentValue === 'percent' ? null : items.config
              "
            ></hcc-number-input>
          </div>
        </div>
      </div>
      <div
        *ngIf="
          items.fieldInfo.fieldName !== 'eventDeductible' &&
          items.config.optionField === fieldRowIndex + 1
        "
        [ngClass]="items.colClass ? items.colClass : ''"
      >
        <hcc-input-generator
          [frmGroup]="frmGroup"
          [fieldInfo]="items.fieldInfo"
          [control]="items.fieldInfo.fieldName"
          [InputConfig]="items?.config"
        ></hcc-input-generator>
      </div>
    </ng-container>
  </div>
  
    <button
      type="button"
      (click)="toggleAdditionalPersSpkr()"
      class="show-peril-button"
     >
    {{ showAdditionalPersSpkr ? hidePersSpkr : showPersSpkr }}
    </button>  
    <div
     *ngFor="let fieldRow of eventDetailsFieldRows; let fieldRowIndex = index"     
     >
    <div class="row" *ngIf=showAdditionalPersSpkr>
    <ng-container  *ngFor="let items of additionalPersSpkr">  
      <div 
      *ngIf="
          items.fieldInfo.fieldName !== 'eventDeductible' &&
          items.config.optionField === fieldRowIndex + 1
        "
      [ngClass]="items.colClass ? items.colClass : ''" >  
      <hcc-input-generator
        [frmGroup]="frmGroup"
        [fieldInfo]="items.fieldInfo"
        [control]="items.fieldInfo.fieldName"
        [InputConfig]="items?.config"
      ></hcc-input-generator> 
      </div>     
     </ng-container>   
    </div> 
  </div>
  <hr />
  <!-- SECTION 2 -->
  <h2 class="mt-3 mb-3 modal-label">Event Level - Rating Inputs (%)</h2>
  <div class="modal-table">
    <table class="table table-bordered">
      <tbody>
        <ng-container
          *ngFor="let items of entryLevelFieldRows; let fieldRowIndex = index"
        >
          <tr class="d-flex">
            <ng-container *ngFor="let item of entryLevelFields">
              <td
                *ngIf="item.optionalField.rowNumber === fieldRowIndex + 1"
                class="col-3 label-bg"
              >
                {{ item.label }} : {{ item.required ? "*" : "" }}
                <!-- <div *ngIf="baseRateHasError">{{item.validation.required}}</div> -->
              </td>
              <td
                style="width: 20%"
                *ngIf="item.optionalField.rowNumber === fieldRowIndex + 1"
                class="col-1 value"
              >
                <!-- <input type="number"
                          autocomplete="off"
                          class="form-control text-center"
                          acInputText
                          [value]="item.value"
                          [required]="item.required"
                          [name]="item.fieldName"
                          (input)="
                      onControlValueChange(item.fieldName, $event.target.value)
                    "
                          updateEvent="blur"
                          placeholder="%" /> -->
                <hcc-text-input
                  [control]="item.fieldName"
                  [frmGroup]="frmGroup"
                  [fieldInfo]="item"
                >
                </hcc-text-input>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <!-- SECTION 3 -->
  <button
    type="button"
    (click)="toggleAdditionalPerils()"
    class="show-peril-button"
  >
    {{ showAdditionalPerils ? hidePerilsLabel : showPerilsLabel }}
  </button>

  <div *ngIf="showAdditionalPerils" class="modal-table mt-2">
    <table class="table table-bordered">
      <tbody>
        <ng-container
          *ngFor="
            let items of additionalPerilsFieldRows;
            let fieldRowIndex = index
          "
        >
          <tr class="d-flex">
            <ng-container *ngFor="let item of additionalPerilsFields">
              <ng-container *ngIf="!item.optionalField.isLabelEditable">
                <td
                  *ngIf="item.optionalField.rowNumber === fieldRowIndex + 1"
                  class="col-3 label-bg"
                >
                  {{ item.label }} :
                </td>
                <td
                  style="width: 20%"
                  *ngIf="item.optionalField.rowNumber === fieldRowIndex + 1"
                  class="col-1 value"
                >
                  <!-- <input type="number"
                            autocomplete="off"
                            class="form-control text-center"
                            acInputText
                            [value]="item.value"
                            [required]="item.required"
                            [name]="item.fieldName"
                            (input)="
                        onControlValueChange(item.fieldName, $event.target.value)
                      "
                            updateEvent="blur"
                            placeholder="%" /> -->
                  <hcc-text-input
                    [control]="item.fieldName"
                    [frmGroup]="frmGroup"
                    [fieldInfo]="item"
                  >
                  </hcc-text-input>
                </td>
              </ng-container>
              <ng-container *ngIf="item.optionalField.isLabelEditable">
                <td
                  *ngIf="
                    item.optionalField.editableType ===
                      formEditableType.LABEL &&
                    item.optionalField.rowNumber === fieldRowIndex + 1
                  "
                  class="col-3"
                >
                  <input
                    type="text"
                    autocomplete="off"
                    class="form-control text-left"
                    acInputText
                    [value]="item.value"
                    [required]="item.required"
                    [name]="item.fieldName"
                    (input)="
                      onControlValueChange(item.fieldName, $event.target.value)
                    "
                    updateEvent="blur"
                  />
                </td>
                <td
                  style="width: 20%"
                  *ngIf="
                    item.optionalField.editableType ===
                      formEditableType.VALUE &&
                    item.optionalField.rowNumber === fieldRowIndex + 1
                  "
                  class="col-1 value"
                >
                  <!-- <input type="number"
                            autocomplete="off"
                            class="form-control text-center"
                            acInputText
                            [value]="item.value"
                            [required]="item.required"
                            [name]="item.fieldName"
                            (input)="
                        onControlValueChange(item.fieldName, $event.target.value)
                      "
                            updateEvent="blur"
                            placeholder="%" /> -->
                  <hcc-text-input
                    [control]="item.fieldName"
                    [frmGroup]="frmGroup"
                    [fieldInfo]="item"
                  >
                  </hcc-text-input>
                </td>
              </ng-container>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <!-- FOOTER -->
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      data-dismiss="modal"
      (click)="onDialogCancel()"
    >
      Cancel
    </button>
    <button type="submit" class="btn btn-primary">
      Save
    </button>
    <!-- <button type="button"
              (click)="onDialogSave()"
              class="btn btn-primary">
        Save
      </button> -->
  </div>
</form>

<div *ngIf="formSkipCheck">
  <div class="modal-content">
    <div class="modal-header"><h5 class="modal-title">Alert</h5></div>
    <div class="modal-body">
      Exiting this window will cause any unsaved data to be lost. Are you sure
      you want to continue?
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        data-dismiss="modal"
        (click)="addEventPopupSkipCancel()"
      >
        No
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="addEventPopupSkipConfirm()"
      >
        Yes
      </button>
    </div>
  </div>
</div>
