import { Component, OnInit, OnDestroy, Inject } from "@angular/core";

import { OktaAuthService } from "@okta/okta-angular";
import { AppConfigService } from '@tmhcc/global-services';
import { environment } from 'apps/policy-admin/src/environments/environment';

@Component({
  selector: "app-logout",
  template: ``
})
export class LogoutComponent implements OnInit, OnDestroy {
  constructor(private oktaAuth: OktaAuthService,
    protected configService: AppConfigService) {}

  async ngOnInit() {
    sessionStorage.clear();
    localStorage.clear();
    const url = `${environment.appConfig.baseHref}/login`;
    await this.oktaAuth.logout(url);
  }

  ngOnDestroy() {}
}
