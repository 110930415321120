import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild
} from "@angular/core";
import { FieldInfo, FieldTypes } from "@tmhcc/framwork/form-inputs";
import { FormGroup, FormBuilder } from "@angular/forms";
import {
  BsDatepickerDirective,
  BsDatepickerConfig
} from "ngx-bootstrap/datepicker";
import { Subscription } from "rxjs";
import { SearchType } from "@tmhcc/models";
import { DashoardStoreService } from "../store/facade/dashbard-store.service";
import { DashboardStoreDwld } from "../store/state/dashbard.state";
import {
  DashboardSearchRequest,
  PolicySearchRequest,
  DwldPolicySearchRequest
} from "@tmhcc/data-service";
import { CommonContentService } from "@tmhcc/content-service";
import { Labels } from "@tmhcc/content-service";
import { DateService } from "@tmhcc/framwork/services";
import { LoaderService } from "@tmhcc/framwork/loader";
import {
  Router  
} from "@angular/router";
@Component({
  selector: 'ec-search-policy',
  templateUrl: './search-policy.component.html',
  styleUrls: ['./search-policy.component.scss']
})
export class SearchPolicyComponent implements OnInit {
  //Default Values
  entityNumberVal: string = null;
  insuredNameVal: string = null;
  entityStartDateVal: string = null;
  entityEndDateVal: string = null;
  searchByQuoteVal: boolean = true;
  searchByPolicyVal: boolean = false;
  searchFirstTime: boolean = false;
  searchForm: FormGroup;
  searchBy: SearchType;
  searchByEnum = SearchType;
  searchByQuote: FieldInfo = {
    fieldName: "quote",
    label: "Quote Number",
    fieldType: FieldTypes.Radio
  };
  searchByPolicy: FieldInfo = {
    fieldName: "policy",
    label: "Policy Number",
    fieldType: FieldTypes.Radio
  };
  entityNumber: FieldInfo = {
    fieldName: "entityNumber",
    label: "entityNumber",
    fieldType: FieldTypes.Text
  };
  insuredName: FieldInfo = {
    fieldName: "insuredName",
    label: "insuredName",
    fieldType: FieldTypes.Text
  };
  entityStartDate: FieldInfo = {
    fieldName: "entityStartDate",
    label: "entityStartDate",
    fieldType: FieldTypes.Date
  };
  valuSub = new Subscription();
  isSubmitValid: boolean = true;
  @ViewChild("dp", { static: true }) datepicker: BsDatepickerDirective;
  datepickerConfig = {};
  datepickerSettings = {
    elementName: "date-picker",
    className: "form-control",
    formControlName: "datePickerInput",
    tooltip: "",
    placeholder: "",
    type: "input",
    label: "Loss Date",
    onFocus: "dummyFunction",
    dateFormat: "MM/DD/YYYY",
    isAnimated: true,
    inAdaptive: true,
    hideOnScroll: false,
    adaptivePosition: true,
    returnFocusToInput: false,
    selectDateFromOtherMonth: false,
    outSideClick: true,
    colorTheme: "theme-dark-blue",
    value: ""
  };
  isEDCalanderOpen = false;
  isSDCalanderOpen = false;
  dashboardStoreData: DashboardStoreDwld;
  getLabels: Labels;
  public calendarHideWeek: Partial<BsDatepickerConfig>;
  constructor(
    private fb: FormBuilder,
    private dashoardStoreService: DashoardStoreService,
    private contentService: CommonContentService,
    private dateService: DateService,
    private loaderservice: LoaderService,
    public router: Router,
  ) {
    this.getLabels = this.contentService.getLabels();
    /**
     * We Use below code to hide the hide the week no from calendar
     */
    this.calendarHideWeek = Object.assign(
      {},
      {
        containerClass: "theme-dark-blue",
        showWeekNumbers: false
      }
    );
  }

  ngOnInit(): void {
    this.searchBy = SearchType.QUOTE;
    this.setSubscription();
    this.buildSearchForm();
    this.setDatePickerConfig();
    this.onSearchFormChanges();
  }

  setSubscription() {
    const dwlddashboardSearchRequest: DwldPolicySearchRequest = {
      policyNumber:"",
      dateBoundIntervalStart: null,
      dateBoundIntervalEnd : null
    };
    //this.dashoardStoreService.setSearchType(this.searchBy);
    //this.dashoardStoreService.getDashboardData({ SubCompanyCode: "ECO" });
    this.dashoardStoreService.data$.subscribe((data: DashboardStoreDwld) => {
      this.dashboardStoreData = data;
    });
    //this.dashoardStoreService.getDwldPolicyDataMock("TTB",dwlddashboardSearchRequest);
  }

  onSearchFormChanges() {
    this.valuSub.add(
      this.searchForm.valueChanges.subscribe(value => {
        this.searchForm = this.searchForm;
        if (
          this.searchForm.valid &&
          (this.searchForm.controls.entitySearchField.value ||
            this.searchForm.controls.insuredNameField.value ||
            this.searchForm.controls.startDateField.value ||
            this.searchForm.controls.endDateField.value)
        ) {
          this.isSubmitValid = true;
        } else {
          this.isSubmitValid = false;
        }
      })
    );
  }

  onSearchByChange(SearchByName: string) {
    this.searchFirstTime = false;
    this.searchBy =
      SearchByName === SearchType.QUOTE ? SearchType.QUOTE : SearchType.POLICY;
    this.loaderservice.show();
    // setTimeout(() => {
    //   this.dashoardStoreService.setSearchType(this.searchBy);
    //   this.dashoardStoreService.getDashboardData({ SubCompanyCode: "ECO" });
    //   this.searchForm.reset();
    //   this.loaderservice.hide();
    // }, 500);
  }

  buildSearchForm() {
    this.searchForm = this.fb.group({
      entitySearchField: [this.entityNumberVal],
      insuredNameField: [this.insuredNameVal],
      startDateField: [this.entityStartDateVal],
      endDateField: [this.entityEndDateVal]
    });
  }

  getDatePickerConfig() {
    return this.datepickerConfig;
  }

  setDatePickerConfig() {
    this.datepickerConfig = {
      isAnimated: this.datepickerSettings.isAnimated,
      adaptivePosition: this.datepickerSettings.adaptivePosition,
      dateInputFormat: this.datepickerSettings.dateFormat,
      returnFocusToInput: this.datepickerSettings.returnFocusToInput,
      selectFromOtherMonth: this.datepickerSettings.selectDateFromOtherMonth,
      containerClass: this.datepickerSettings.colorTheme
    };
  }

  onControlValueChange(feildName: string, val: string) {
    this[feildName] = val;

    /**
     * Reset the result if user blank all input and Data fields
     * this.searchFirstTime=true if user search the result using search button.
     */
    let _insuredName = this.searchForm.get("insuredNameField").value;
    let _quoteNumber = this.searchForm.get("entitySearchField").value;
    let _quoteCreatedDate = this.searchForm.get("startDateField").value;
    let _quoteExpirationDate = this.searchForm.get("endDateField").value;
    
    if (_quoteCreatedDate) {
      let quoteCreatedDate = Date.parse(_quoteCreatedDate);
      if (
        isNaN(quoteCreatedDate) == true ||
        !(_quoteCreatedDate.getYear() > 0)
      ) {
        this.searchForm.controls.startDateField.setValue("");
      }
    }

    if (_quoteExpirationDate) {
      let _expirationDate = Date.parse(_quoteExpirationDate);
      if (
        isNaN(_expirationDate) == true ||
        !(_quoteExpirationDate.getYear() > 0)
      ) {
        this.searchForm.controls.endDateField.setValue("");
      }
    }
  }
  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  onSubmit() {//debugger;
    this.searchFirstTime = true;
    if (this.searchForm.valid) {
      this.loaderservice.show();     
      if (this.searchForm.get("entitySearchField").value) {
        this.searchForm
          .get("entitySearchField")
          .setValue(this.searchForm.get("entitySearchField").value.trim());
      }
      const {
        entitySearchField,
        insuredNameField,
        startDateField,
        endDateField
      } = this.searchForm.value;     
      const startDate = this.dateService.now().subtract(40, "days").toISOString();
      const endDate = this.dateService.now().subtract(3, "days").toISOString();
      //debugger;
      let searchType = sessionStorage.ServiceType ? sessionStorage.ServiceType : 'TTB'
      if(entitySearchField === null || entitySearchField === "")
      {
        const dwlddashboardSearchRequest: DwldPolicySearchRequest = {
          policyNumber:"",
          dateBoundIntervalStart: startDate,
          dateBoundIntervalEnd : endDate
        };        
        this.dashoardStoreService.getDwldPolicyDataMock(searchType,dwlddashboardSearchRequest);
      }
      else
      {
        const dwlddashboardSearchRequest: DwldPolicySearchRequest = {
          policyNumber:entitySearchField,
          dateBoundIntervalStart: null,
          dateBoundIntervalEnd : null
        };        
        this.dashoardStoreService.getDwldPolicyDataMock(searchType,dwlddashboardSearchRequest);
      }     

      setTimeout(() => {
        this.loaderservice.hide();
      }, 4000);
      
    }
  }
  searchPolicies(
    policyNumber: string,
    insuredName: string,
    startDate: string,
    endDate: string
  ) {
    if (startDate) {
      let startDateVal = new Date(startDate);
      let policyStartDate = startDateVal.toString();
      startDate = this.convert(policyStartDate);
    } else {
      startDate = null;
    }
    if (endDate) {
      let endDateVal = new Date(endDate);
      let policyEndDate = endDateVal.toString();
      endDate = this.convert(policyEndDate);
    } else {
      endDate = null;
    }
    const dashboardSearchRequest: DashboardSearchRequest = {
      PolicyNumber: policyNumber ? policyNumber : null,
      InsuredName: insuredName ? insuredName : null,
      CreatedDate: startDate,
      ExpirationDate: endDate,
      SubCompanyCode: "ECO"
    };
   // this.dashoardStoreService.getDashboardData(dashboardSearchRequest);
  }
  searchQuotes(
    quoteNumber: string,
    insuredName: string,
    startDate: string,
    endDate: string
  ) {
    if (startDate) {
      let startDateVal = new Date(startDate);
      let quoteStartDate = startDateVal.toString();
      startDate = this.convert(quoteStartDate);
    } else {
      startDate = null;
    }
    if (endDate) {
      let endDateVal = new Date(endDate);
      let quoteEndDate = endDateVal.toString();
      endDate = this.convert(quoteEndDate);
    } else {
      endDate = null;
    }

    const dashboardSearchRequest: DashboardSearchRequest = {
      QuoteNumber: quoteNumber ? quoteNumber : null,
      InsuredName: insuredName ? insuredName : null,
      CreatedDate: startDate,
      ExpirationDate: endDate,
      SubCompanyCode: "ECO"
    };
   // this.dashoardStoreService.getDashboardData(dashboardSearchRequest);
  }
  LoadKnrDashboard()
  {
    this.router.navigate(["/knr-dashboard"]);
  }
}

