import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { PageHeaderModule } from "../../shared/page-header/page-header.module";
import { GridModule } from "@tmhcc/framwork/grid";
import { PageTabInfoModule } from "./../../shared/page-tab/page-tab-info.module";
import { ReactiveFormsModule } from "@angular/forms";
import { CoreInputsModule } from "@tmhcc/framwork/core-inputs";
import { SaveChangeGaurd } from "@tmhcc/global-services";
import { FormsComponent } from "./forms.component";
import { QuotePolicyInfoModule } from "../../shared/quote-policy-info/quote-policy-info.module";
import { FormsDialogComponent } from "./forms-dialog/forms-dialog.component";
import { DialogModule, DialogConfig } from "@tmhcc/framwork/dialog";

const routes: Routes = [
  { path: "", component: FormsComponent, canDeactivate: [SaveChangeGaurd] }
];

@NgModule({
  declarations: [FormsComponent, FormsDialogComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    PageHeaderModule,
    GridModule,
    PageTabInfoModule,
    CoreInputsModule,
    QuotePolicyInfoModule,
    DialogModule
  ],
  providers: [SaveChangeGaurd, DialogConfig],
  exports: [RouterModule],
  entryComponents: [FormsDialogComponent]
})
export class FormsModule {}
