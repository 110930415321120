import { Injectable } from "@angular/core";
import { FieldTypes } from "@tmhcc/framwork/form-inputs";
import { Validators } from "@angular/forms";
import { CommonContentService } from "@tmhcc/content-service";
import { DateService } from "@tmhcc/framwork/services";
import { BsDatepickerDirective } from "ngx-bootstrap/datepicker";
import { valHooks } from "jquery";
import { BasePremium, CoverageModifiers, LimitModification, ModifiedBasePremium, RiskQualityModifiers, WorkplaceViolence } from "@tmhcc/models/classes/knr-rating-data.model";
@Injectable()
export class QuoteInfoCoveragesDefinitionService {
    constructor(private dateService: DateService) {
    }

    termInfoConfig(data: any) {       
        return {
            termInfoConfigList: [
                {
                    fieldInfo: {
                        fieldName: "termlength",
                        label: "Term Length:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options: data.TermLengths.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                },
                {
                 config: {
                       value: 
                       this.dateService.toShortDate(
                        this.dateService.moment(
                          new Date().toISOString()
                        )
                      ),
                          
                        datePickerConfig: {
                            dateInputFormat: this.dateService.getFormat(),
                            isCalanderRequired: true,
                            showWeekNumbers: false,
                            // minDate: this.mindate,
                            outSideClick: false,
                            adaptivePosition: false,
                            isAnimated: true,
                            placement: "top",
                            container: "body"
                        },
                        optionField: 4,
                        validation: [Validators.required]
                    },
                    fieldInfo: {
                        fieldName: "effectivedate",
                        label: "Effective Date:",
                        decimal: 0,
                        fieldType: FieldTypes.Date,
                        showLabel: true,
                        required: true,
                        value: 
                        this.dateService.toShortDate(
                         this.dateService.moment(
                           new Date().toISOString()
                         )
                       ),
                        validation: {
                            required: "Required",
                            pastDate: "Date Passed",
                            invalidDate: "Invalid Date"
                        }
                    },
                    colClass: "col-md-6 col-lg-3"
                },
                {
                    config: {
                        value: null,
                        datePickerConfig: {
                            dateInputFormat: this.dateService.getFormat(),
                            isCalanderRequired: false,
                            showWeekNumbers: false,
                            // minDate: this.mindate,
                            outSideClick: false,
                            adaptivePosition: false,
                            isAnimated: true,
                            placement: "top",
                            container: "body"
                        },
                        optionField: 4,
                        validation: [Validators.required],
                        isDisabled:true
                    },
                    fieldInfo: {
                        fieldName: "expirationdate",
                        label: "Expiration Date: ",
                        decimal: 0,
                        fieldType: FieldTypes.Date,
                        required: true,
                        showLabel: true,
                        value: null,
                        validation: {
                            required: "Required",
                            pastDate: "Date Passed",
                            invalidDate: "Invalid Date"
                        }                        
                    },
                    colClass: "col-md-6 col-lg-3"                   
                },
                {
                    fieldInfo: {
                        fieldName: "additionalentities",
                        label: "Additional Entities:",
                        fieldType: FieldTypes.Text,
                        showLabel: true
                    },
                    config: {                        
                    }
                },
            ]
        }
    };
    limitOfLiability(data:any) {
        return {
            limitOfLiabilityList: [
                {

                    fieldInfo: {
                        fieldName: "ransom",
                        label: "Ransom:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options:  data.ransom.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                },
                {
                    fieldInfo: {
                        fieldName: "ransomdeductible",
                        label: "Ransom Deductible:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options:  data.ransomDeductible.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                },
                {
                    fieldInfo: {
                        fieldName: "personalbelongings",
                        label: "Personal Belongings:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options: data.personalBelongings.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                },
                {
                    fieldInfo: {
                        fieldName: "transit",
                        label: "Transit:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options:  data.transit.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                },
                {
                    fieldInfo: {
                        fieldName: "legalliability",
                        label: "Legal Liability:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options:  data.legalLiability.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                },
                {
                    fieldInfo: {
                        fieldName: "additionalexpenses",
                        label: "Additional Expenses:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options:  data.additionalExpenses.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                },
                {
                    fieldInfo: {
                        fieldName: "crisisresponsefees",
                        label: "Crisis Response Fees:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options:  data.crisisResponseFees.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                },
                {
                    fieldInfo: {
                        fieldName: "recallexpenses",
                        label: "Recall Expenses",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options:  data.recallExpenses.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                },
                {
                    fieldInfo: {
                        fieldName: "aaforallinsuredlosses",
                        label: "AA for all Insured Losses:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options:  data.aaForAllInsuredLosses.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                },
                {
                    fieldInfo: {
                        fieldName: "accidentalddperinsuredperson",
                        label: "Accidental D&D Per Insured Person:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options:  data.accidentalDAndDPerInsuredPerson.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                },
                {
                    fieldInfo: {
                        fieldName: "accidentalperevent",
                        label: "Accidental D&D Per Event:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options: data.accidentalDAndDPerEvent.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                }

            ]
        }
    }
    additionalCoveredEvent(data: any) {        
        return {
            additionalCoveredEventList: [
                {
                    fieldInfo: {
                        fieldName: "threatresponseexpense",
                        label: "Threat Response Expense:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options: data.threatResponseExpense.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                    
                },
                {
                    fieldInfo: {
                        fieldName: "threatannualaggregate",
                        label: "Threat Annual Aggregate:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options: data.threatAnnualAggregate.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                    
                },
                {
                    fieldInfo: {
                        fieldName: "threatindeminityperiod",
                        label: "Threat Indeminity Period:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options: data.threadIndemnityPeriod.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                    
                },
                {
                    fieldInfo: {
                        fieldName: "threatwaitingperiod",
                        label: "Threat Waiting Period:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options: data.threatWaitingPeriod.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                    
                },
                {
                    fieldInfo: {
                        fieldName: "disappearanceinvestigationexpense",
                        label: "Disappearance Investigation Expense:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options: data.disappearanceInvestigationExpense.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                    
                },
                {
                    fieldInfo: {
                        fieldName: "disappearnaceannualaggregate",
                        label: "Disappearnace Annual Aggregate:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options: data.disappearanceAnnualAggregate.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                    
                },
                {
                    fieldInfo: {
                        fieldName: "disappearanceindeminityperiod",
                        label: "Disappearance Indeminity Period:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options: data.disappearanceIndemnityPeriod.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                    
                },
                {
                    fieldInfo: {
                        fieldName: "disappearancewaitingperiod",
                        label: "Disappearance Waiting Period:",
                        fieldType: FieldTypes.LookupList,
                        showLabel: true,
                        validation: {
                            required: "Please select"
                        }
                    },
                    config: {
                        selectConfig: {
                            selectedOption: [],
                            options: data.disappearanceWaitingPeriod.map((item) => {
                                return item.value;
                             }),
                            optionConfig: {
                                displayKey: "value",
                                search: true,
                                limitTo: 3,
                            },
                            multiple: false,
                        },
                        validation: [Validators.required]
                    }
                    
                }
            ]
        }
    }
}