import { Injectable } from "@angular/core";
import { Select } from "@ngxs/store";
import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { Observable } from "rxjs";
import { KnrDashboardData, SetSearchType,DashboardDataDwld } from "../state/knr-dashbard.actions";
import { KnrDashboardState,KnrDashboardStore } from "../state/knr-dashbard.state";
import { DashboardSearchRequest,DwldPolicySearchRequest } from "@tmhcc/data-service";
import { SearchType } from "@tmhcc/models";
import { KnrPolicySearchRequest } from "@tmhcc/data-service/def/knr-quote-policy-search";

@Injectable({ providedIn: "root" })
export class KnrDashoardStoreService {
  @Select(KnrDashboardState)
  public datad$: Observable<KnrDashboardStore>;

  constructor() {}

  @Dispatch()
  public getKnrPolicyData(dashboardSearchRequest: KnrPolicySearchRequest) {
    return new KnrDashboardData(dashboardSearchRequest);
  } 
}
