import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputBase } from '../input-base';
import { has } from '@tmhcc/util';

@Component({
  selector: 'hcc-radio-button',
  templateUrl: './radio-button.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true
    }
  ]
})
export class RadioButtonComponent extends InputBase<string> implements OnInit {
  constructor() {
    super();
  }
  toolTipText = '';
  ngOnInit() {
    if (has(this.fieldInfo, 'label') && this.fieldInfo.label.length > 30) {
      this.toolTipText = this.fieldInfo.label;
    }
  }
  OnRadioChange(val) {
    super.writeValue(val);
    this.frmGroup.get(this.fieldInfo.fieldName).setValue(val);
    //super.writeValue(val);
  }
}

