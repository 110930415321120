import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef
} from "@angular/core";
import { DialogConfig, DialogRef, DialogSize } from "@tmhcc/framwork/dialog";
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormGroupDirective,
  FormControl
} from "@angular/forms";
import { InputConfig, FieldInfo } from "@tmhcc/framwork/form-inputs";
import { DateService } from "@tmhcc/framwork/services";
import { FormType } from "./form-type.enum";
import { EventGridRow, EventDeductibleType } from "@tmhcc/models";
import { QuoteStoreService } from "../../store/facade/quote-store.service";
import {
  EventDialogFieldConfig,
  EventDialogConfigData
} from "./event-dialog-field.config";
import { CoreInputValidationService } from "@tmhcc/framwork/core-inputs";
import { EcoMasterData } from "@tmhcc/data-service";
import { CommonContentService } from "@tmhcc/content-service";
import { DateValidator } from "./date-validator";
import { EventDeductibleValidator } from "./event-deductible.validator";

@Component({
  selector: "event-dialog-dialog",
  templateUrl: "./event-dialog.component.html",
  styleUrls: ["./event-dialog.component.scss"]
})
export class EventDialogComponent extends EventDialogFieldConfig
  implements OnInit, OnDestroy {
  defaultInitialValue: EventGridRow;
  dialogSetting: string;

  frmGroup: FormGroup;
  eventDetailsFields: InputConfig[];
  eventDetailsFieldRows = [];
  entryLevelFields: FieldInfo[];
  entryLevelFieldRows = [];
  additionalPerilsFields: FieldInfo[];
  additionalPerilsFieldRows = [];
  showAdditionalPerils: boolean;
  showPerilsLabel: string;
  hidePerilsLabel: string;
  formEditableType: any;
  eventDialogFormControl: Object = {};
  formSkipCheck: boolean;
  eventDeductableRadioFields: FieldInfo[];
  eventDedCurrentValue: any;
  additionalPersSpkr :  InputConfig[];
  showAdditionalPersSpkr: boolean;
  showPersSpkr: string;
  hidePersSpkr: string;

  constructor(
    private formBuilder: FormBuilder,
    public dialogData: DialogConfig<EventDialogConfigData>,
    private dialogRef: DialogRef,
    dateService: DateService,
    private quoteStoreService: QuoteStoreService,
    private validationService: CoreInputValidationService,
    private masterData: EcoMasterData,
    public contentService: CommonContentService,
    private cdr: ChangeDetectorRef
  ) {
    super(dateService, dialogData, contentService);
  }

  ngOnInit(): void {
    const {
      Countries,
      StateOrProvinces,
      UserConfigurableDataElements
    } = this.masterData.getMasterData();
    this.dialogData.data = {
      ...this.dialogData.data,
      countryList: this.getCountryData(Countries),
      stateList: this.getStateData(StateOrProvinces),
      policyType: this.getPolicyType(UserConfigurableDataElements)
    };
    this.initialAssignments();
    this.formSetup();

    /**
     * Below we check enter date is valid or also we checking the enter year
     * TODO
     */
    this.frmGroup.controls["eventStartDate"].valueChanges.subscribe(value => {
      let eventStartdate = value;
      if (eventStartdate) {
        let _startDate = Date.parse(eventStartdate);
        if (isNaN(_startDate) == true || !(eventStartdate.getYear() > 0)) {
          this.frmGroup.controls.eventStartDate.setValue("");
        }
      }
    });

    this.frmGroup.controls["eventEndDate"].valueChanges.subscribe(value => {
      let eventEndDate = value;
      if (eventEndDate) {
        let _endDate = Date.parse(eventEndDate);
        if (isNaN(_endDate) == true || !(eventEndDate.getYear() > 0)) {
          this.frmGroup.controls.eventEndDate.setValue("");
        }
      }
    });
  }

  initialAssignments() {
    // Set Master Data
    this.dialogData.data.addedEditedEvents = false;
    this.formSkipCheck = false;
    this.eventDetailsFieldRows.length = 4;
    this.entryLevelFieldRows.length = 5;
    this.additionalPerilsFieldRows.length = 3;
    this.showAdditionalPerils = false;
    this.showPerilsLabel = "[+] Show Additional Perils:";
    this.hidePerilsLabel = "[-] Hide Additional Perils:";
    this.formEditableType = FormType;
    this.showAdditionalPersSpkr = false;
    
    this.showPersSpkr = "[+] Show Additional Insured Person/Speakers:";
    this.hidePersSpkr = "[-] Hide Additional Insured Person/Speakers:";
  }

  formSetup() {
    this.eventDetailsFields = this.newEventFieldConfig().eventDetailsFields;
    this.additionalPersSpkr = this.newEventFieldConfig().additionalPersSpkr;
    this.entryLevelFields = this.newEventFieldConfig().entryLevelFields;
    this.additionalPerilsFields = this.newEventFieldConfig().additionalPerilsFields;
    this.eventDeductableRadioFields = this.newEventFieldConfig().eventDeductibleRadioField;
    this.setFormControl(
      this.eventDetailsFields,
      this.additionalPersSpkr,
      this.entryLevelFields,
      this.additionalPerilsFields
    );
  }

  setEventDeductible() {
    if (this.dialogData.data.isDialogForEdit) {
      const dialogEventGrid: EventGridRow = this.dialogData.data.eventGridRow;
      if (dialogEventGrid.eventDeductible) {
        if (dialogEventGrid.eventDeductibleType === EventDeductibleType.VALUE) {
          this.frmGroup.controls["eventDeductibleType"].setValue(
            EventDeductibleType.VALUE
          );
        } else if (
          dialogEventGrid.eventDeductibleType === EventDeductibleType.PERCENTAGE
        ) {
          this.frmGroup.controls["eventDeductibleType"].setValue(
            EventDeductibleType.PERCENTAGE
          );
        }
      }
    }
  }

  setFormControl(
    eventDetailsFields: InputConfig[],
    additionalPersSpkr : InputConfig[],
    entryLevelFields: FieldInfo[],    
    additionalPerilsFields: FieldInfo[]
  ) {
    eventDetailsFields.forEach((element: InputConfig) => {
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      this.eventDialogFormControl[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        Validators.compose(validators)
      ];
    });

    additionalPersSpkr.forEach((element: InputConfig) => {
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      this.eventDialogFormControl[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        Validators.compose(validators)
      ];
    });

    entryLevelFields.forEach((element: FieldInfo) => {
      let validators;
      if (element.validation) {
        validators = element.validation;
      } else {
        validators = null;
      }
      this.eventDialogFormControl[element.fieldName] = [
        element.value,
        this.getValidations(validators)
      ];
    });

    additionalPerilsFields.forEach((element: FieldInfo) => {
      let validators;
      if (element.validation) {
        validators = element.validation;
      } else {
        validators = null;
      }
      const isEditableLabel = [
        "additionalPerilLabel1",
        "additionalPerilLabel2",
        "additionalPerilLabel3"
      ].every(value => {
        return value !== element.fieldName;
      });

      this.eventDialogFormControl[element.fieldName] = [
        element.value,
        isEditableLabel ? this.getValidations(validators) : null
      ];
    });
    this.eventDialogFormControl[
      this.eventDeductableRadioFields[1].fieldName
    ] = [];
    this.eventDialogFormControl[
      this.eventDeductableRadioFields[0].fieldName
    ] = [];
    this.frmGroup = this.formBuilder.group(this.eventDialogFormControl, {
      validators: [
        DateValidator("eventStartDate", "eventEndDate"),
        EventDeductibleValidator("eventDeductibleType", "eventDeductible")
      ]
    });

    //Set validations
    this.frmGroup.controls["state"].valueChanges.subscribe(value => {
      $('ngx-select-dropdown[ng-reflect-name="state"]').removeClass(
        "hide-selected"
      );
    });
    this.frmGroup.controls["country"].valueChanges.subscribe(value => {
      const filteredValue: InputConfig = this.newEventFieldConfig().eventDetailsFields.find(
        (value: InputConfig) => {
          return value.fieldInfo.fieldName === "state";
        }
      );
      if (value) {
        const filteredState = filteredValue.config.selectConfig.options.filter(
          data => {
            return data.id === value.id;
          }
        );
        if (filteredState && filteredState.length !== 0) {
          filteredState.sort((a: any, b: any) => a.name.localeCompare(b.name));
        }
        this.newEventFieldConfig().eventDetailsFields.forEach(
          (value: InputConfig, index) => {
            if (value.fieldInfo.fieldName === "state") {
              this.newEventFieldConfig().eventDetailsFields[
                index
              ].config.selectConfig.options = [];
              this.newEventFieldConfig().eventDetailsFields[
                index
              ].config.selectConfig.options = filteredState;
              this.eventDetailsFields[index].config.selectConfig.options = [];
              this.eventDetailsFields[
                index
              ].config.selectConfig.options = filteredState;
            }
          }
        );

        // Resetting state on country change
        const currentState = this.frmGroup.controls["state"].value;
        if (currentState && currentState.id !== value.id) {
          this.frmGroup.controls["state"].reset();
          this.frmGroup.controls["state"].setValue({});
          filteredValue.config.selectConfig.selectedOption = [];
          filteredValue.fieldInfo.value = "";
          filteredValue.config.value = "";
          $('ngx-select-dropdown[ng-reflect-name="state"]').addClass(
            "hide-selected"
          );
          this.cdr.detectChanges();
        }
      }
    });

    // filtering state on initial event load
    const selectedCountry = this.frmGroup.controls["country"].value;
    if (selectedCountry) {
      const stateList: InputConfig = this.newEventFieldConfig().eventDetailsFields.find(
        (value: InputConfig) => {
          return value.fieldInfo.fieldName === "state";
        }
      );
      const countryList: InputConfig = this.newEventFieldConfig().eventDetailsFields.find(
        (value: InputConfig) => {
          return value.fieldInfo.fieldName === "country";
        }
      );
      const filteredCountry = countryList.config.selectConfig.options.find(
        data => {
          if (
            data.name &&
            selectedCountry &&
            data.name.toLowerCase() === selectedCountry.toLowerCase()
          ) {
            return true;
          } else {
            return false;
          }
        }
      );
      if (filteredCountry) {
        const filteredStates = stateList.config.selectConfig.options.filter(
          data => {
            return data.id === filteredCountry.id;
          }
        );

        if (filteredStates && filteredStates.length !== 0) {
          this.newEventFieldConfig().eventDetailsFields.forEach(
            (value: InputConfig, index) => {
              if (value.fieldInfo.fieldName === "state") {
                this.newEventFieldConfig().eventDetailsFields[
                  index
                ].config.selectConfig.options = [];
                this.newEventFieldConfig().eventDetailsFields[
                  index
                ].config.selectConfig.options = filteredStates;
                this.eventDetailsFields[index].config.selectConfig.options = [];
                this.eventDetailsFields[
                  index
                ].config.selectConfig.options = filteredStates;
              }
            }
          );
        }
      }
    }

    this.setEventDeductible();

    // Default Country
    if (!this.dialogData.data.isDialogForEdit) {
      this.frmGroup.get("country").setValue(this.setDefaultCountry("USA"));
    }

    // Event end date defaulting logic
    this.frmGroup.controls["eventStartDate"].valueChanges.subscribe(value => {
      if (!this.frmGroup.get("eventEndDate").value) {
        this.frmGroup
          .get("eventEndDate")
          .setValue(this.frmGroup.get("eventStartDate").value);
      }
    });
  }

  onEventDedTypeChange(value: any) {
    this.eventDedCurrentValue = value;
  }

  getValidations(validators: any): any[] {
    let result = [];
    for (var key in validators) {
      if (validators.hasOwnProperty(key)) {
        if (Validators[key]) {
          result.push(Validators[key]);
        }
      }
    }
    result.push(Validators.pattern(/^\d*\.?\d*$/));
    return result;
  }

  onSubmit() {
    this.validationService.setFormSubmission(true);
    if (this.frmGroup.valid) {
      const eventGridRow = this.mapNewEventForm();
      if (this.dialogData.data.isDialogForEdit) {
        const editedEventGridRow = this.mapEditedEventGrid(
          eventGridRow,
          this.dialogData.data.eventGridRow.customID
        );
        this.quoteStoreService.editEvent(editedEventGridRow);
      } else {
        this.quoteStoreService.addEvent(eventGridRow);
      }
      this.dialogData.data.addedEditedEvents = true;
      this.dialogRef.close();
    }
  }
  mapEditedEventGrid(formValue: EventGridRow, id: string): EventGridRow {
    let result: EventGridRow = new EventGridRow();
    result = { ...formValue };
    result.customID = id;
    return result;
  }

  onClose() {
    this.dialogRef.close();
  }

  checkFormData() {
    const fields = [];
    for (const field in this.frmGroup.controls) {
      if (this.frmGroup.controls.hasOwnProperty(field)) {
        if (!field.includes("additionalPerilLabel")) {
          fields.push(this.frmGroup.get(field).value);
        }
      }
    }
    const result = fields.filter(field => !!field);
    const valid = result.length !== 0;
    return valid
      ? null
      : {
          oneFilledOut: true
        };
  }

  onDialogCancel() {
    if (!this.checkFormData() && this.frmGroup.dirty) {
      this.dialogData.modalSetting.dialogSize = DialogSize.LG;
      this.dialogData.modalSetting.hideHeader = true;
      this.formSkipCheck = true;
    } else {
      document.body.classList.remove("overflow-hidden");
      this.dialogRef.close();
    }
  }

  onDialogSave() {}

  mapNewEventForm(): EventGridRow {
    let eventGridRow = new EventGridRow();
    for (var key in this.frmGroup.controls) {
      if (this.frmGroup.controls.hasOwnProperty(key)) {
        let formValue = this.frmGroup.controls[key].value;
        eventGridRow[key] =
          formValue && formValue.name ? formValue.name : formValue;
        if (key === "state" && this.isEmpty(eventGridRow[key])) {
          eventGridRow[key] = "";
        }
      }
    }
    return eventGridRow;
  }

  isEmpty(map) {
    for (var key in map) {
      if (map.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  toggleAdditionalPerils() {
    this.showAdditionalPerils = !this.showAdditionalPerils;
  }

  toggleAdditionalPersSpkr() {
    this.showAdditionalPersSpkr = !this.showAdditionalPersSpkr;
  }

  onControlValueChange(feildName: string, val: string) {
    this.frmGroup.get(feildName).setValue(val);
  }

  ngOnDestroy(): void {
    this.validationService.setFormSubmission(false);
  }

  addEventPopupSkipCancel() {
    this.dialogData.modalSetting.hideHeader = false;
    this.dialogData.modalSetting.dialogSize = DialogSize.XL;
    this.formSkipCheck = false;
  }

  addEventPopupSkipConfirm() {
    this.dialogData.modalSetting.hideHeader = false;
    this.dialogData.modalSetting.dialogSize = DialogSize.XL;
    document.body.classList.remove("overflow-hidden");
    this.dialogRef.close();
  }
}
