import { Component, OnInit } from '@angular/core';
import { LoaderService } from "@tmhcc/framwork/loader";
import { Router } from "@angular/router";
import { PageHeaderService } from "../../shared/page-header/page-header.service";
import { ColumnDefinition, Types, GridOptions } from "@tmhcc/framwork/grid";
import { KnrPolicyQuoteService } from "libs/data-service/kidnap-ransom/knr-policy-quote.service";
import {      
  KnrPolicySearchRequest
} from "@tmhcc/data-service/def/knr-quote-policy-search";
import {      
  KnrPolicySearchResponse,KnrPolicySearchResult
} from "../../../../../models/classes/knr-policy-gird.model";
import { CommonContentService } from "@tmhcc/content-service";
import { KnrDashoardStoreService} from "../knr-store/facade/knr-dashbard-store.service";
import {KnrDashboardStore} from "../knr-store/state/knr-dashbard.state";

@Component({
  selector: 'ec-knr-view-search',
  templateUrl: './knr-view-search.component.html',
  styleUrls: ['./knr-view-search.component.scss']
})
export class KnrViewSearchComponent implements OnInit {

  quotePolicyList : any = []; 
  gridSetting: GridOptions;
  tblSettingsForKnrQuotePolicyDetails: ColumnDefinition[];
  constructor(
    private loaderService : LoaderService,
    private knrPolicyQuoteService : KnrPolicyQuoteService,
    private router: Router,
    private commonContentService: CommonContentService,   
    private knrDashoardStoreService: KnrDashoardStoreService, 
  ) {  }

  ngOnInit(): void {
    sessionStorage.removeItem('_quoteId');  
    this.gridSetting = {
      pageLength: 10,
      searching: false,
      paging: true,
      ordering: true,
      orderAscending: 'desc',
      orderBy: 0,
      emptyTable: this.commonContentService.getLabels().emptyTableMsg
    };
    this.getPageControlsData();
    
  }
  getPageControlsData()
  {
    this.loaderService.show();   
    this.knrPolicyQuoteService.getAccountSetupData().subscribe(data =>{  
      this.knrPolicyQuoteService.getRatingData().subscribe(data => {
        this.knrPolicyQuoteService.getQuoteInfoCoveragesData().subscribe(data => {
          this.getQuotePolicyList();
          this.loaderService.hide();
        });
      });      
    });
  }
  getQuotePolicyList()
  {
    this.setCoumnHeadingForKnrQuotePolicyDetails();   
    this.loaderService.show();
    this.knrDashoardStoreService.datad$.subscribe((data: KnrDashboardStore) => {        
      if(data)
       {
         this.quotePolicyList = data.knrPolicyDataList;
       }     
      setTimeout(() => {
        this.loaderService.hide();
      }, 3000);
    });    
  }
  onViewPolicyDetailClick(event)
  {    
    sessionStorage.setItem('_quoteId',event.QuoteId);
    this.router.navigate(['/knr-dashboard/account-setup']);
  }
  setCoumnHeadingForKnrQuotePolicyDetails() {    
    this.tblSettingsForKnrQuotePolicyDetails = [ 
      {
        fieldInfo: {
          fieldName: "QuoteNumber",
          label: "Quote Number"
        },
        columnType: Types.link
      },
      {
        fieldInfo: {
          fieldName: "PolicyNumber",
          label: "Policy Number"
        },
        columnType: Types.default
      },
      {
        fieldInfo: {
          fieldName: "InsuredName",
          label: "Insured Name"
        },
        columnType: Types.default
      },     
      {
        fieldInfo: {
          fieldName: "Broker",
          label: "Broker"
        },
        columnType: Types.default
      },
      {
        fieldInfo: {
          fieldName: "Limit",
          label: "Limit"
        },
        columnType: Types.currency
      },
      {
        fieldInfo: {
          fieldName: "Status",
          label: "Status"
        },
        columnType: Types.default
      },
      {
        fieldInfo: {
          fieldName: "GrossPremium",
          label: "Gross Premium"
        },
        columnType: Types.currency
      },
      {
        fieldInfo: {
          fieldName: "Underwriter",
          label: "Underwriter"
        },
        columnType: Types.default
      }
    ];
  }
}
