import { Injectable } from "@angular/core";
import { isMoment, Moment } from "moment";
import moment from "moment-timezone";
import { AppConfigService } from "libs/global-services";
import { DateFormats, LongDateFormats } from "./def/date.def";
import { environment } from 'apps/policy-admin/src/environments/environment';
@Injectable()
export class DateService {
  readonly defaultTimezone: string;
  readonly format: DateFormats;
  readonly longFormat: LongDateFormats;
  readonly minMoment: Moment;

  readonly abbrs = {
    EST: "Eastern Standard Time",
    EDT: "Eastern Daylight Time",
    CST: "Central Standard Time",
    CDT: "Central Daylight Time",
    MST: "Mountain Standard Time",
    MDT: "Mountain Daylight Time",
    PST: "Pacific Standard Time",
    PDT: "Pacific Daylight Time"
  };

  public constructor(configService: AppConfigService) {
    this.defaultTimezone = this.abbrs.CST;
    moment.tz.setDefault(this.defaultTimezone);
    this.format = this.convertToDateFormat(environment.appConfig.datePattern);
    this.longFormat = this.convertToLognDateFormat(this.format);
    this.minMoment = this.momentFromShortISO("1900-01-01");
  }

  public getFormat(): DateFormats {
    return this.format;
  }

  public getLongFormat(): LongDateFormats {
    return this.longFormat;
  }

  public now(): Moment {
    return moment();
  }

  public isMoment(_moment): boolean {
    return isMoment(_moment) ? _moment.isValid() : false;
  }

  public isValidDate(value: string | Moment): boolean {
    try {
      return this.moment(value).isValid();
    } catch (error) {
      return false;
    }
  }

  public moment(value: string | Moment | Date): Moment {
    this.throwErrorIfNull(value);

    let _moment: Moment;

    if (typeof value === "string") {
      if (value.length === this.format.length)
        _moment = this.momentFromShortDate(value);
      else if (value.length < this.longFormat.length)
        _moment = this.momentFromLongDate(value);
      else _moment = moment(value);
    } else if (value instanceof Date) {
      _moment = moment(value);
    } else if (this.isMoment(value)) {
      _moment = value;
    } else {
      this.throwErrorInvalidFormat(value);
    }

    this.throwErrorIfNotAValidMoment(_moment);

    return _moment;
  }

  public momentFromShortDate(value: string): Moment {
    return this.momentFromShort(value, this.format);
  }

  public momentFromShortISO(value: string): Moment {
    return this.momentFromShort(value, DateFormats.ISO);
  }

  public momentFromLongDate(value: string): Moment {
    return this.momentFromLong(value, this.longFormat);
  }

  public momentFromLongISO(value: string): Moment {
    return this.momentFromLong(value, LongDateFormats.ISO);
  }

  public toShortDate(value: Moment): string {
    return this.toShort(value, this.format);
  }

  public toShortDateWithFormat(value: Moment, format: DateFormats): string {
    return this.toShort(value, format);
  }

  public toShortISO(value: Moment): string {
    return this.toShort(value, DateFormats.ISO);
  }

  public toLongDate(value: Moment): string {
    return this.toLong(value, this.longFormat);
  }

  public toLongISO(value: Moment): string {
    return this.toLong(value, LongDateFormats.ISO);
  }

  public toDate(_moment: Moment): Date {
    this.throwErrorIfNotAValidMoment(_moment);

    return _moment.toDate();
  }

  private toShort(value: Moment, format: DateFormats): string {
    this.throwErrorIfNotAValidMoment(value);

    return value.format(format);
  }

  private toLong(value: Moment, longFormat: LongDateFormats): string {
    this.throwErrorIfNotAValidMoment(value);

    return value.format(longFormat);
  }

  private momentFromShort(value: string, format: DateFormats): Moment {
    if (!value || typeof value !== "string" || value.length !== format.length)
      this.throwErrorInvalidFormat(value);

    const _moment = moment(value, format);

    this.throwErrorIfNotAValidMoment(_moment);

    return _moment;
  }

  private momentFromLong(value: string, longFormat: LongDateFormats): Moment {
    if (!value || typeof value !== "string" || value.length < longFormat.length)
      this.throwErrorInvalidFormat(value);

    const _moment = moment(value, longFormat);

    this.throwErrorIfNotAValidMoment(_moment);

    return _moment;
  }

  public isSameDate(firstDate: string, secondDate: string) {
    return this.momentFromShortDate(firstDate).isSame(
      this.momentFromShortDate(secondDate),
      "day"
    );
  }

  public isBeforeDate(thisDate: string, anotherDate: string) {
    return this.momentFromShortDate(thisDate).isBefore(
      this.momentFromShortDate(anotherDate),
      "day"
    );
  }

  public isAfterDate(thisDate: string, anotherDate: string) {
    return this.momentFromShortDate(thisDate).isAfter(
      this.momentFromShortDate(anotherDate),
      "day"
    );
  }

  public isBeforeToday(date: string) {
    return this.momentFromShortDate(date).isBefore(this.now(), "day");
  }

  public currentdate() {
    const today = this.now();
    today.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    return today.toDate();
  }

  public isToday(date: string) {
    const today = moment().toString();
    return this.momentFromShortDate(today).isSame(date, "day");
  }

  public addDays(date: string, days: number) {
    const newDate = this.momentFromShortDate(date).add(days, "day");
    return newDate.toDate();
  }

  public subtractDays(date: string, days: number) {
    const newDate = this.momentFromShortDate(date).subtract(days, "day");
    return newDate.toDate();
  }

  private convertToLognDateFormat(format: DateFormats): LongDateFormats {
    switch (format) {
      case DateFormats.MMDDYYYY:
        return LongDateFormats.MMDDYYYY;
      case DateFormats.DDMMYYYY:
        return LongDateFormats.DDMMYYYY;
      case DateFormats.ISO:
        return LongDateFormats.ISO;
      default:
        return LongDateFormats.MMDDYYYY;
    }
  }

  private convertToDateFormat(format: string): DateFormats {
    switch (format) {
      case DateFormats.MMDDYYYY:
        return DateFormats.MMDDYYYY;
      case DateFormats.DDMMYYYY:
        return DateFormats.DDMMYYYY;
      case DateFormats.ISO:
        return DateFormats.ISO;
      default:
        return DateFormats.MMDDYYYY;
    }
  }

  //#region Error Handling
  private throwErrorIfNull(value: any): void {
    if (!value) {
      this.throwErrorNullArgument();
    }
  }

  private throwErrorIfNotAValidMoment(_moment: Moment): void {
    if (!this.isMoment(_moment)) {
      this.throwErrorInvalidDate(_moment);
    }
  }

  private throwErrorInvalidDate(obj: any): void {
    this.throwError(this.Errors.InvalidDate, obj);
  }

  private throwErrorInvalidFormat(obj: any): void {
    this.throwError(this.Errors.InvalidFormat, obj);
  }

  private throwErrorNullArgument(): void {
    this.throwError(this.Errors.NullArgument, null);
  }

  private throwError(errorCode: string, obj: string | object) {
    if (!errorCode) errorCode = this.Errors.Unknown;

    if (!obj) obj = "EMPTY MESSAGE.";

    let message;
    let dataType;

    if (typeof obj === "string") {
      dataType = "string";
      message = obj;
    } else if (obj instanceof Date) {
      dataType = "Date";
      message = obj.toString();
    } else if (isMoment(obj)) {
      dataType = "Moment";
      message = obj.toString();
    } else {
      dataType = "unknown";
      this.throwErrorInvalidFormat(JSON.stringify(obj));
    }

    throw new Error(`${obj} ERROR MESSAGE: [${dataType}]: ${message}`);
  }

  private readonly Errors = {
    InvalidDate: "Not a valid date.",
    InvalidFormat: "Not a valid date format.",
    NullArgument: "Null argument.",
    Unknown: "Unknown error."
  };
  //#endregion
}
