import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
//import { Observable } from "rxjs/internal/Observable";
import { Observable } from "rxjs";
import { BaseDataService } from "./base-data-service";
import { AppConfigService } from "@tmhcc/global-services";
import { DateService } from "@tmhcc/framwork/services";
import { CommonContentService } from "@tmhcc/content-service";
import {
       // DashboardSearchRequest,
       // DashboardSearchResponse,
        DwldPolicySearchRequest,
        DwldPolicySearchResponse,
        nwPolicyDownloadRequest,
        ttbPolicyDownloadRequest,
        thimbleGetDocumentRequest,
        thimbleGetDocumentResponse,
        thimbledocument,
        thimblePolicyDownloadRequest,
       // policyVersions,
        //clsPolicyVersions
} from "./def/quote-policy-search";
import { policyDetail,quotesData, policyData, DashboardGrid,DashboardGridDwld,thimbleDocument } from "@tmhcc/models";
import { map, catchError } from "rxjs/operators";
import { saveAs } from 'file-saver';
import datad from "./policy_mock_data.json";
import  *  as  jsonData  from  'libs/data-service/policy_mock_data.json';
import { result } from "@tmhcc/util";
import { HttpOptions } from "@tmhcc/framwork/http";
import { environment } from 'apps/policy-admin/src/environments/environment';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class PolicyDownloadService extends BaseDataService {
  protected _key: any;
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    private dateService: DateService,
    private commonContentService: CommonContentService
  ) {
    super(http, configService);
     this._key=JSON.parse((CryptoJS.AES.decrypt(environment.apiConfig.misc, environment.en_key._k).toString(CryptoJS.enc.Utf8)));
  }  

  getTTBPolicyDetail(policyId: any, transactionId:any) : Observable<policyDetail>
  {    
      let baseURL = this.ttbServiceBaseUrl;   
      let ttbServiceApiKey = this.ttbServiceApiKey;
      const url = `${baseURL}GetPolicy/${policyId}/${transactionId}`;
      // return this.http.get(url).pipe(
      //   map(data => this.mapToPolicyDetail(data))
      //   );
      const headerDict = {
        'Content-Type': 'application/json',
        'x-api-key': ttbServiceApiKey
      }
      const requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
      };         
      return this.http.get<policyDetail>(url,requestOptions).pipe(
        map(data => this.mapToPolicyDetail(data)),
        catchError(this.handleError)
      );
  }
  getNWPolicyDetail(policyId: any) : Observable<policyDetail>
  {    
      let baseURL = this.nextWaveServiceBaseUrl;   
      let ttbServiceApiKey = this.ttbServiceApiKey;
      const url = `${baseURL}GetPolicy/${policyId}`;      
      const headerDict = {
        'Content-Type': 'application/json',
        'x-api-key': ttbServiceApiKey
      }
      const requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
      };         
      return this.http.get<policyDetail>(url,requestOptions).pipe(
        map(data => this.mapToPolicyDetailNW(data)),
        catchError(this.handleError)
      );
  }
  getThimblePolicyDetail(policyId: any) : Observable<policyDetail>
  {   
      let baseURL = this.thimbleServiceBaseUrl;   
      let ttbServiceApiKey = this.thimbleServiceAPIKey;
      const url = `${baseURL}GetPolicy/${policyId}`;      
      const headerDict = {
        'Content-Type': 'application/json',
        'x-api-key': ttbServiceApiKey
      }
      const requestOptions = {                                                                                                                                                                                 
        headers: new HttpHeaders(headerDict), 
      };         
      return this.http.get<policyDetail>(url,requestOptions).pipe(
        map(data => this.mapToPolicyDetailThimble(data)),
        catchError(this.handleError)
      );
  }
  getThimbleDocumentInfo(payload: thimbleGetDocumentRequest) : Observable<thimbledocument>{
      let baseUrl = this.thimbleServiceBaseUrl;
      let apiKey =this.thimbleServiceAPIKey;
      const url = `${baseUrl}SearchDocument`
     
      return this.postv1<thimbledocument>(url, { body: payload },apiKey).pipe(
        map(data => this.mapToThimbleDocResponse(data)) ,
        catchError(this.handleError)     
      );
  }
  private mapToThimbleDocResponse(response:any)
  {
    if (!response) return;    
      let data = response.document;
      let document = new thimbleDocument();
      document.carrierPolicyNumber = data.carrierPolicyNumber;      
      document.transactionID = data.transactionID;
      document.folderID = data.folderID;
      document.fileID = data.fileID;
      document.documentFileName = data.documentFileName;
      document.docOriginalFileName = data.docOriginalFileName;
      document.docType = data.docType;
      return document;
  }
  private mapToPolicyDetail(response:any) : policyDetail {
    //debugger;
      if (!response) return;
      let data = response.policy;
      let objPolicyDetail = new policyDetail();
      objPolicyDetail.ProductCode = data.carrierPolicyNumber;
      objPolicyDetail.TransactionID = data.transactionID;           
      objPolicyDetail.Address = data.insuredAddress.street;       
      objPolicyDetail.TotalPremium = data.covers.grossPremium
      objPolicyDetail.TransactionType = data.transactionType;
      objPolicyDetail.InsuredName = data.insured.insuredName;
      objPolicyDetail.City = data.insuredAddress.city;
      objPolicyDetail.ClientType = "TTB" ;        
      let CreatedDate = this.dateService.momentFromLongISO(
         data.creationDate
       );
     objPolicyDetail.CreationDate = this.dateService.toShortDate(CreatedDate);
      if (!this.dateService.isValidDate(data.policyEffectiveDate)) {
        throw new Error(this.commonContentService.getErrors().invalidDate);
      }
      objPolicyDetail.PolicyEffectiveDate = this.dateService.toShortDate(
        this.dateService.moment(data.policyEffectiveDate)
      ); 
      if (!this.dateService.isValidDate(data.policyExpirationDate)) {
        throw new Error(this.commonContentService.getErrors().invalidDate);
      } 
      objPolicyDetail.PolicyExpirationDate = this.dateService.toShortDate(
        this.dateService.moment(data.policyExpirationDate)
      );           
      objPolicyDetail.PolicyNumber =  data.carrierPolicyNumber;
      objPolicyDetail.EndorsementNumber = data.endorsementNumber;
      return objPolicyDetail;
   }

   private mapToPolicyDetailNW(response:any) : policyDetail {
    //debugger;
      if (!response) return;
      let data = response.policy;
      let objPolicyDetail = new policyDetail();
      objPolicyDetail.ProductCode = data.number;
               
           
      objPolicyDetail.TotalPremium = data.totalPremium;
      objPolicyDetail.TransactionType = "ADD";
      objPolicyDetail.InsuredName = data.insuredName;
                
     
      if (!this.dateService.isValidDate(data.dateFrom)) {
        throw new Error(this.commonContentService.getErrors().invalidDate);
      }
      objPolicyDetail.PolicyEffectiveDate = this.dateService.toShortDate(
        this.dateService.moment(data.dateFrom)
      ); 
      if (!this.dateService.isValidDate(data.dateTo)) {
        throw new Error(this.commonContentService.getErrors().invalidDate);
      } 
      objPolicyDetail.PolicyExpirationDate = this.dateService.toShortDate(
        this.dateService.moment(data.dateTo)
      );           
      objPolicyDetail.PolicyNumber =  data.number;
      objPolicyDetail.ClientType = "NW"   ; 
      
      return objPolicyDetail;
   }
   private mapToPolicyDetailThimble(response:any) : policyDetail {
    //debugger;
      if (!response) return;
      let data = response.policy;
      let objPolicyDetail = new policyDetail();
      objPolicyDetail.ProductCode = data.carrierPolicyNumber;
      objPolicyDetail.TransactionID = data.transactionID;      
           
      objPolicyDetail.TotalPremium = data.grossPremium;
      objPolicyDetail.TransactionType = "ADD";
      objPolicyDetail.InsuredName = data.insured.insuredName;
                
     
      if (!this.dateService.isValidDate(data.policyEffectiveDate)) {
        throw new Error(this.commonContentService.getErrors().invalidDate);
      }
      objPolicyDetail.PolicyEffectiveDate = this.dateService.toShortDate(
        this.dateService.moment(data.policyEffectiveDate)
      ); 
      if (!this.dateService.isValidDate(data.policyExpirationDate)) {
        throw new Error(this.commonContentService.getErrors().invalidDate);
      } 
      objPolicyDetail.PolicyExpirationDate = this.dateService.toShortDate(
        this.dateService.moment(data.policyExpirationDate)
      );           
      objPolicyDetail.PolicyNumber =  data.carrierPolicyNumber;
      objPolicyDetail.ClientType = "Thimble"   ; 
    //   let CreatedDate = this.dateService.momentFromLongISO(
    //     data.creationDate
    //   );
    //  objPolicyDetail.CreationDate = this.dateService.toShortDate(CreatedDate);
     objPolicyDetail.City = data.insuredAddress.city;
     objPolicyDetail.Address = data.insuredAddress.street;     
      
      return objPolicyDetail;
   }

  getDwldPolicyDataMock(servicetype: any,payload: DwldPolicySearchRequest): Observable<DashboardGridDwld> {   
    const data =  datad;
   //const url = `${this.dwldServiceBaseUrl}/api/v1/GetPolicies`;
   let baseURL : any;
   let serviceAPIKey: any;   
   if(servicetype === 'NW')
   {
    baseURL = this.nextWaveServiceBaseUrl + "SearchPolicies";
    serviceAPIKey = this._key.nextWaveServiceAPIKey;
   }
   else if(servicetype === 'TTB')
   {
      baseURL = this.ttbServiceBaseUrl + "SearchPolicies";
      serviceAPIKey = this._key.ttbServiceAPIKey;
   }
   else if(servicetype === 'Thimble')
   {
    baseURL = this.thimbleServiceBaseUrl + "SearchPolicies";
    serviceAPIKey = this._key.thimbleServiceAPIKey;
   }
   //debugger;
   const url = `${baseURL}`;
    return this.postv1<DwldPolicySearchResponse>(url, { body: payload },serviceAPIKey).pipe(
      map(data => this.mapToQuoteGridRow(data,servicetype))      
    );
  }
 
  nwDownloadFile(payload: nwPolicyDownloadRequest): any {
    const urllink = `${this.nwPolicyDownloadServiceBaseUrl}/api/v1/GetDocument`;
    const headers = { 'Content-Type': 'application/json', 'x-api-key': this._key.nextWaveServiceAPIKey };
    return this.http.post(urllink,payload, {headers, responseType: 'blob'});
  }
  ttbDownloadFile(payload: ttbPolicyDownloadRequest): any {
    const urllink = `${this.ttbPolicyDownloadServiceBaseUrl}/api/v1/GetDocument`;
    const headers = { 'Content-Type': 'application/json', 'x-api-key': this._key.ttbServiceAPIKey };
    return this.http.post(urllink,payload, {headers, responseType: 'blob'});
  }
  thimbleDownloadFile(payload: thimblePolicyDownloadRequest): any {
    const urllink = `${this.thimbleServiceBaseUrl}DownloadDocument`;
    const headers = { 'Content-Type': 'application/json', 'x-api-key': this._key.thimbleServiceAPIKey };
    return this.http.post(urllink,payload, {headers, responseType: 'blob'});
  }

  getJson() : Observable<any> {
    const _jsonURL = '/policy-mock-data.json';
    return this.http.get(_jsonURL).pipe(
    map((response:any) => response.json()),
    catchError(this.handleError)
    );    
  }
  getMockJson() : Observable<DashboardGridDwld> {
    let _jsonURL = jsonData;
    //let data = this.http.get(_jsonURL);
   // let d = this.mapToQuoteGridRow(_jsonURL.default[0]);
    return null;
  }
  private maptoDoc(data: any): any {
    return data;
  } 

  private mapToQuoteGridRow(data: any,servicetype:any): DashboardGridDwld {//debugger;
    let dashboardGrid = new DashboardGridDwld();
    if ((!data || !data.policyVersions) && (!data || !data.policyVersions)) return;    
   
       let policyGridRows: policyDetail[] = [];

      for (let index = 0; index < data.policyVersions.length; index++) {
        const element = data.policyVersions[index];
        
        let row = new policyDetail();
        if(servicetype === "NW")
          {
            row.ProductCode = element.productCode;
            //row.TransactionID = null;
            //row.InsuredName = element.Insured.InsuredName;
            row.Address = element.insuredAddress.street;       
            row.TotalPremium = element.totalPremium;
            row.TransactionType = element.transactionType;
            row.InsuredName = element.insured.insuredName;
            row.City = element.insuredAddress.city;
            row.Country = element.insuredAddress.Country;
            if (!this.dateService.isValidDate(element.policyEffectiveDate)) {
              throw new Error(this.commonContentService.getErrors().invalidDate);
            }            
            //row.PolicyEffectiveDate = element.policyEffectiveDate;//this.dateService.toShortDate(CreatedDate);
            row.PolicyEffectiveDate = this.dateService.toShortDate(
              this.dateService.moment(element.policyEffectiveDate)
            ); 
            if (!this.dateService.isValidDate(element.policyExpirationDate)) {
              throw new Error(this.commonContentService.getErrors().invalidDate);
            }           
           // row.PolicyExpirationDate = element.policyExpirationDate;//this.dateService.toShortDate(ExpirationDate);
            row.PolicyExpirationDate = this.dateService.toShortDate(
              this.dateService.moment(element.policyExpirationDate)
            ); 
            row.PolicyNumber =  element.covers[0].carrierPolicyNumber;           
            policyGridRows.push(row);
         }
      else if(servicetype === 'TTB')
        {
          row.ProductCode = element.carrierPolicyNumber;
          row.TransactionID = element.transactionID;
            //row.InsuredName = element.Insured.InsuredName;
            row.Address = element.insuredAddress.street;       
            row.TotalPremium = element.covers.grossPremium
            row.TransactionType = element.transactionType;
            row.InsuredName = element.insured.insuredName;
            row.City = element.insuredAddress.city;
           // row.Country = element.insuredAddress.country;
           let CreatedDate = this.dateService.momentFromLongISO(
            element.creationDate
          );
           row.CreationDate = this.dateService.toShortDate(CreatedDate);
            if (!this.dateService.isValidDate(element.policyEffectiveDate)) {
              throw new Error(this.commonContentService.getErrors().invalidDate);
            }            
            //row.PolicyEffectiveDate = element.policyEffectiveDate;
            row.PolicyEffectiveDate = this.dateService.toShortDate(
              this.dateService.moment(element.policyEffectiveDate)
            );   

            if (!this.dateService.isValidDate(element.policyExpirationDate)) {
              throw new Error(this.commonContentService.getErrors().invalidDate);
            }          
            //row.PolicyExpirationDate = element.policyExpirationDate;

            row.PolicyExpirationDate = this.dateService.toShortDate(
              this.dateService.moment(element.policyExpirationDate)
            );           
            row.PolicyNumber =  element.carrierPolicyNumber;            
          policyGridRows.push(row);
        }
        else if(servicetype === 'Thimble')
        {
            row.ProductCode = element.carrierPolicyNumber;          
            row.Address = element.insuredAddress.street;       
            row.TotalPremium = element.grossPremium
            row.TransactionType = element.transactionType;
            row.InsuredName = element.insured.insuredName;
            row.City = element.insuredAddress.city;           
           let CreatedDate = this.dateService.momentFromLongISO(
            element.creationDate
          );
           row.CreationDate = this.dateService.toShortDate(CreatedDate);
            if (!this.dateService.isValidDate(element.policyEffectiveDate)) {
              throw new Error(this.commonContentService.getErrors().invalidDate);
            }      
            
            row.PolicyEffectiveDate = this.dateService.toShortDate(
              this.dateService.moment(element.policyEffectiveDate)
            );   

            if (!this.dateService.isValidDate(element.policyExpirationDate)) {
              throw new Error(this.commonContentService.getErrors().invalidDate);
            }          
           
            row.PolicyExpirationDate = this.dateService.toShortDate(
              this.dateService.moment(element.policyExpirationDate)
            );           
            row.PolicyNumber =  element.carrierPolicyNumber;            
          policyGridRows.push(row);
        }
        
      }
     dashboardGrid.policyDetail  = policyGridRows;
    
    return dashboardGrid;
  }
  private jsonData() 
  {
     const data =  {
      "policyVersions": [
          {
              "transactionType": "ADD",
              "productCode": "EC-TOURNAMENT",
              "insured": {
                  "insuredName": "Indoor Hockey"
              },
              "insuredAddress": {
                  "street": "80 Bent St.",
                  "suiteOrUnit": "",
                  "city": "Dover",
                  "state": "DE",
                  "zipCode": "19901",
                  "country": "USA"
              },
              "policyEffectiveDate": "2021-01-21T00:00:00",
              "policyExpirationDate": "2021-03-27T00:01:00",
              "totalPremium": 492.00000,
              "covers": [
                  {
                      "coverageCode": "GL",
                      "grossPremium": 492.00000,
                      "sumInsuredLimit": 80000.0,
                      "brokerNumber": "7930",
                      "carrierCode": "44000120",
                      "carrierPolicyNumber": "21HA000266"
                  },
                  {
                      "coverageCode": "TRIA",
                      "grossPremium": 5.00000,
                      "sumInsuredLimit": 80000.0,
                      "brokerNumber": "7930",
                      "carrierCode": "44000120",
                      "carrierPolicyNumber": "21HA000266"
                  }
              ],
              "events": [
                  {
                      "eventName": "Hockey Tournament",
                      "eventStartDate": "2021-03-23T00:00:00",
                      "eventEndDate": "2021-03-27T00:01:00",
                      "eventNumber": 1,
                      "pmlCategory": 2,
                      "eventType": "HOCKEY",
                      "aggregateLimit": 80000.0,
                      "venueName": "Dover Ice Arena",
                      "venueAddress1": "110 Portland Ave",
                      "venueAddress2": "",
                      "venueCity": "Dover",
                      "venueState": "NH",
                      "venuePostalCode": "03820",
                      "venueCountry": "US"
                  }
              ]
          },
          {
              "transactionType": "ADD",
              "productCode": "EC-TOURNAMENT",
              "insured": {
                  "insuredName": "kevin"
              },
              "insuredAddress": {
                  "street": "1 st",
                  "suiteOrUnit": "",
                  "city": "Dover",
                  "state": "DE",
                  "zipCode": "19901",
                  "country": "USA"
              },
              "policyEffectiveDate": "2021-01-21T00:00:00",
              "policyExpirationDate": "2021-03-01T00:01:00",
              "totalPremium": 1730.00000,
              "covers": [
                  {
                      "coverageCode": "GL",
                      "grossPremium": 1730.00000,
                      "sumInsuredLimit": 200000.0,
                      "brokerNumber": "7930",
                      "carrierCode": "44000120",
                      "carrierPolicyNumber": "21HA000267"
                  },
                  {
                      "coverageCode": "TRIA",
                      "grossPremium": 18.00000,
                      "sumInsuredLimit": 200000.0,
                      "brokerNumber": "7930",
                      "carrierCode": "44000120",
                      "carrierPolicyNumber": "21HA000267"
                  }
              ],
              "events": [
                  {
                      "eventName": "DE game",
                      "eventStartDate": "2021-02-28T00:00:00",
                      "eventEndDate": "2021-03-01T00:01:00",
                      "eventNumber": 1,
                      "pmlCategory": 2,
                      "eventType": "BASKETBALL",
                      "aggregateLimit": 200000.0,
                      "venueName": "DE palace",
                      "venueAddress1": "1 st",
                      "venueAddress2": "",
                      "venueCity": "Dover",
                      "venueState": "DE",
                      "venuePostalCode": "19901",
                      "venueCountry": "US"
                  }
              ]
          }
      ]
    };
    
  }
}
