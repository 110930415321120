import {
  DashboardSearchRequest,
  PolicySearchRequest,
  DwldPolicySearchRequest,
  KnrPolicySearchRequest  
} from "@tmhcc/data-service";
import { SearchType,KnrPolicySearchResponse } from "@tmhcc/models";


export class SetSearchType {
  public static readonly type = "[SetSearchType] action";
  constructor(public searchType: SearchType) {}
}
export class DasboardData {
  public static readonly type = "[DashboardData] action";
  constructor(public dashboardSearchRequest: DashboardSearchRequest) {}
}

export class DashboardDataDwld {
  public static readonly type = "[DashboardDataDwld] action";
  constructor(public searchType:any,public dwldDashboardSearchRequest: DwldPolicySearchRequest) {}
}

export class KnrDashboardData {
  public static readonly type = "[KnrDashboardData] action";
  constructor(public knrdashboardSearchRequest: KnrPolicySearchRequest) {}
}
