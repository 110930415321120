<form [formGroup]="frmGroup">
  <label *ngIf="fieldInfo.showLabel" [fieldInfo]="fieldInfo" acForLable>
    {{ fieldInfo.label
    }}<span class="required-star" *ngIf="fieldInfo.validation?.required"
      >*</span
    >
  </label>

  <span
    *ngIf="InputConfig?.numericConfig?.type == 'currency'; else numberTemplate"
  >
    <hcc-custom-validation [validations]="fieldInfo.validation">
      <input
        HccMyCurrency
        type="text"
        [value]="value"
        (input)="change($event.target.value)"
        (blur)="touch()"
        [control]="control"
        [formGroup]="frmGroup"
        class="form-control"
        [formControlName]="control"
        inputValidateRef
        acInputNumber
        [fieldInfo]="fieldInfo"
        [readOnly]="InputConfig?.isDisabled"
      />
    </hcc-custom-validation>
  </span>

  <ng-template #numberTemplate>
    <hcc-custom-validation [validations]="fieldInfo.validation">
      <input
        type="text"
        [value]="value"
        (input)="change($event.target.value)"
        (blur)="touch()"
        [control]="control"
        [formGroup]="frmGroup"
        class="form-control"
        [formControlName]="control"
        inputValidateRef
        acInputNumber
        [fieldInfo]="fieldInfo"
        [readOnly]="InputConfig?.isDisabled"
      />
    </hcc-custom-validation>
  </ng-template>
</form>
