<div class="container">
  <ec-page-header></ec-page-header>
  <div class="row">
    <div class="col-md-12 ">
      <ec-quote-policy-info></ec-quote-policy-info>
      <ec-page-tab></ec-page-tab>
      <div class="clearfix"></div>
      <div class="row mt-4">
        <div class="col-md-5">
          <form [formGroup]="viewSummaryForm">
            <div class="form-group row">
              <label for="" class="col-md-6 sub-header"
                ><strong>{{ getLabelsName.policynum }}</strong></label
              >
              <div class="col-md-6">
                {{ bindSummaryFieldValues.policyNumber }}
              </div>
            </div>

            <div class="form-group row">
              <label for="" class="col-md-6 sub-header"
                ><strong>{{ getLabelsName.insured }}</strong></label
              >
              <div class="col-md-6">
                <span
                  *ngFor="let addressdetail of getContactAddress; let j = index"
                >
                  {{ addressdetail.StreetAddress }}<br />
                  {{ addressdetail.City }} {{ addressdetail.ZipCode }}<br />
                  {{ addressdetail.CountryCode }}
                </span>
              </div>
            </div>

            <div class="form-group row">
              <label for="" class="col-md-6 sub-header"
                ><strong>{{ getLabelsName.agency }}</strong></label
              >
              <div class="col-md-6">
                <span
                  *ngFor="let agencyAddressData of agencyAddress; let j = index"
                >
                  {{ agencyAddressData.StreetAddress }}<br />
                  {{ agencyAddressData.City }} {{ agencyAddressData.ZipCode
                  }}<br />
                  {{ agencyAddressData.CountryCode }}
                </span>
              </div>
            </div>

            <h5 class="sub-header mb-2 mt-4">
              {{ getLabelsName.policyperiod }}
            </h5>
            <div class="form-group row">
              <label for="" class="col-md-6 col-form-label"
                ><strong>{{ getLabelsName.policyEffLabl }} *</strong></label
              >
              <div class="col-md-6">
                <div
                  class="input-group date-field col-md-12 nopadding"
                  [ngClass]="{ isDisabled: isDateDisabled == true }"
                >
                  <hcc-datepicker-input
                    [control]="PloicyEffectiveDateConfig.fieldInfo.fieldName"
                    [frmGroup]="viewSummaryForm"
                    [fieldInfo]="PloicyEffectiveDateConfig.fieldInfo"
                    [InputConfig]="PloicyEffectiveDateConfig.config"
                  >
                  </hcc-datepicker-input>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label for="" class="col-md-6 col-form-label"
                ><strong
                  >{{ getLabelsName.summaryPolicyExpiryDate }} *</strong
                ></label
              >
              <div class="col-md-6">
                <div
                  class="input-group date-field col-md-12 nopadding"
                  [ngClass]="{ isDisabled: isDateDisabled == true }"
                >
                  <hcc-datepicker-input
                    [control]="PloicyExpirationDateConfig.fieldInfo.fieldName"
                    [frmGroup]="viewSummaryForm"
                    [fieldInfo]="PloicyExpirationDateConfig.fieldInfo"
                    [InputConfig]="PloicyExpirationDateConfig.config"
                  >
                  </hcc-datepicker-input>
                  <!-- <div class="input-group-append">
                  <span class="input-group-text"
                    ><span class="fa fa-calendar-check-o"></span
                  ></span>
                </div>-->
                </div>
              </div>
            </div>

            <h5 class="sub-header mb-2 mt-4">
              {{ getLabelsName.premiumsummary }}
            </h5>
            <div class="form-group row">
              <label for="" class="col-md-6"
                ><strong>{{ getLabelsName.premium }}</strong></label
              >
              <div class="col-md-6">
                <span *ngIf="bindSummaryFieldValues.premium">{{
                  bindSummaryFieldValues.premium | currency: "USD"
                }}</span>
              </div>
            </div>
            <h5 class="sub-header mb-2 mt-4">{{ getLabelsName.commission }}</h5>
            <div class="form-group row">
              <label for="" class="col-md-6"
                ><strong *ngIf="bindSummaryFieldValues.commissionRate"
                  >{{ bindSummaryFieldValues.commissionRate }} %</strong
                ></label
              >
              <div class="col-md-6">
                {{ bindSummaryFieldValues.commissionValue | currency: "USD" }}
              </div>
            </div>
            <h5 class="sub-header mb-2 mt-4">
              {{ getLabelsName.taxesandfee }}
            </h5>
            <div
              class="form-group row"
              *ngFor="let surplustaxes of surplustaxesAndFees"
            >
              <label for="" class="col-md-6"
                ><strong>{{ surplustaxes.TaxType }}</strong></label
              >
              <div class="col-md-6">
                {{ surplustaxes.WrittenAmount | currency: "USD" }}
              </div>
            </div>
          </form>
        </div>

        <div class="col-md-7">
          <h3 class="sub-header">{{ getLabelsName.policyFormHeading }}</h3>
          <ac-grid-table
            (childEventForNavigation)="selectEventRecord($event)"
            [parentDivName]="'QuoteVersion'"
            [gridSetting]="gridSetting"
            [records]="recordsList"
            [columnSettings]="tableSettingsForQuoteStep"
          ></ac-grid-table>
        </div>
      </div>
    </div>
  </div>
  <div class="button-row">
    <button type="button" (click)="onBackClick()" class="btn btn-primary">
      {{ getLabelsName.back }}
    </button>
    <button
      *ngIf="!hideBindButton"
      type="button"
      [disabled]="isBindButtonDisabled"
      (click)="onBindPolicy()"
      class="btn btn-primary"
    >
      {{ getLabelsName.bindpolicy }}
    </button>
  </div>
</div>
