import { State, Action, StateContext, Store } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import {
  QuotePolicySearchService,
  DashboardSearchRequest,
  PolicyDownloadService
} from "@tmhcc/data-service";
import {
  SearchType,
  quotesData,
  policyData,
  DashboardGrid,
  PolicyGridRow,
  
  DashboardGridDwld,
  policyDetail
} from "@tmhcc/models";
import { DasboardData, SetSearchType,DashboardDataDwld } from "./dashbard.actions";

// export interface DashboardStore {
//   searchType: SearchType;
//   policyList: policyData[];
//   quoteList: quotesData[];
// }
//==
export interface DashboardStoreDwld {  
  dwldPolicyDataList: policyDetail[];  
}

// @State<DashboardStore>({
//   name: "Dashboard",
//   defaults: {
//     searchType: SearchType.QUOTE,
//     policyList: [],
//     quoteList: []
//   }
// })


// @Injectable()
// export class DashboardState {
//   constructor(
//     private quotePolicySearchService: QuotePolicySearchService,
//     private store: Store
//   ) {}

//   @Action(DasboardData)
//   getDashboardData(
//     ctx: StateContext<DashboardStore>,
//     { dashboardSearchRequest }: DasboardData
//   ) {
//     return this.quotePolicySearchService
//       .getDashboardData(dashboardSearchRequest)
//       .pipe(
//         tap((data: DashboardGrid) => {
//           const state = ctx.getState();
//           ctx.setState({
//             ...state,
//             quoteList: [...data.quotesData],
//             policyList: [...data.policyData]
//           });
//         })
//       );
//   }

//   @Action(SetSearchType)
//   setSearchType(
//     ctx: StateContext<DashboardStore>,
//     { searchType }: SetSearchType
//   ) {
//     const state = ctx.getState();
//     ctx.setState({
//       ...state,
//       searchType: searchType
//     });
//   }
// }

//==
@State<DashboardStoreDwld>({
  name: "DwldDashboard",
  defaults: {
    dwldPolicyDataList: []
  }
})

@Injectable()
export class DashboardStateDwld {
  constructor(
    private policyDownloadService: PolicyDownloadService,
    private store: Store
  ) {}

  @Action(DashboardDataDwld)
  getDwldDashboardData(
    ctx: StateContext<DashboardStoreDwld>,
    {searchType, dwldDashboardSearchRequest }: DashboardDataDwld
  ) {
    return this.policyDownloadService
      .getDwldPolicyDataMock(searchType,dwldDashboardSearchRequest)
      .pipe(
        tap((data: DashboardGridDwld) => {
          const state = ctx.getState();
          ctx.setState({
            ...state,            
            dwldPolicyDataList: [...data.policyDetail]
          });
        })
      );
  }

  // @Action(SetSearchType)
  // setSearchType(
  //   ctx: StateContext<DashboardStoreDwld>,
  //   { searchType }: SetSearchType
  // ) {
  //   const state = ctx.getState();
  //   ctx.setState({
  //     ...state,
  //     searchType: searchType
  //   });
  // }
}

