<form [formGroup]="frmGroup">
  <label *ngIf="fieldInfo.showLabel" [fieldInfo]="fieldInfo" acForLable>
    {{ fieldInfo.label
    }}<span class="required-star" *ngIf="fieldInfo.validation?.required"
      >*</span
    >
  </label>
  <hcc-custom-validation
    [validations]="fieldInfo.validation"
    *ngIf="!InputConfig?.readonlyConfig?.readOnly; else readonlyTemplate"
  >
    <input
      type="text"
      inputValidateRef
      [value]="value"
      [formControlName]="control"
      class="form-control"
      acInputText
      [control]="control"
      [fieldInfo]="fieldInfo"
      readonly
    />
  </hcc-custom-validation>
  <ng-template #readonlyTemplate>
    <p
      class="form-control disablePara"
      *ngIf="InputConfig?.readonlyConfig?.type == 3; else staticTemplate"
    >
      {{ frmGroup.get(control).value | hccCurrency }}
    </p>
    <ng-template #staticTemplate>
      <p class="form-control disablePara">
        {{ frmGroup.get(control).value }}
      </p>
    </ng-template>
  </ng-template>
</form>
