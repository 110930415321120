import { Injectable } from "@angular/core";
import { FieldTypes } from "@tmhcc/framwork/form-inputs";
import { Validators } from "@angular/forms";
import { CommonContentService } from "@tmhcc/content-service";
import { QuoteGeneralRequest } from "libs/data-service/quote-general.def";
@Injectable()
export class QuoteGeneralDefinitionService {
  constructor(public commonContentService: CommonContentService) {}
  // quote Information config fields
  quoteInformationConfig = {
    quoteFieldConfig: [
      {
        fieldInfo: {
          fieldName: QuoteGeneralRequest.QuoteNumber,
          label: this.commonContentService.getLabels().quoteNumber,
          fieldType: FieldTypes.Readonly,
          showLabel: true
        },
        config: {
          isDisabled: true
        }
      },
      {
        fieldInfo: {
          fieldName: QuoteGeneralRequest.PolicyTypes,
          label: this.commonContentService.getLabels().ecoProductTypeLbl,
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: this.commonContentService.getLabels()
              .quotePolicyTypeValidationText
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: [],
            optionConfig: {
              displayKey: "Description",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      }
    ]
  };
  // Insured Address field
  insuredSectionFields = {
    insuredConfigField: [
      {
        fieldInfo: {
          fieldName: QuoteGeneralRequest.InsuredName,
          label: this.commonContentService.getLabels().quoteInsuredLabel,
          fieldType: FieldTypes.Text,
          showLabel: true,
          validation: {
            required: this.commonContentService.getLabels()
              .quoteInsuredValidaionText
          }
        },
        config: {
          validation: [Validators.required]
        }
      }
    ]
  };
  addressFields = {
    addressConfigField: [
      {
        fieldInfo: {
          fieldName: QuoteGeneralRequest.Address,
          label: this.commonContentService.getLabels().quoteAddressFieldLbl,
          fieldType: FieldTypes.Text,
          showLabel: true,
          validation: {
            required: this.commonContentService.getLabels()
              .quoteAddressValidationText
          }
        },
        config: {
          validation: [Validators.required]
        }
      }
    ]
  };
  insuredSectionAddressFields = {
    insuredAddressConfigField: [
      {
        fieldInfo: {
          fieldName: QuoteGeneralRequest.City,
          label: this.commonContentService.getLabels().quoteCityFieldLbl,
          fieldType: FieldTypes.Text,
          showLabel: true,
          validation: {
            required: this.commonContentService.getLabels()
              .quoteCityRequiredValidationText,
            pattern: this.commonContentService.getLabels().quoteValidCityText
          }
        },
        config: {
          validation: [Validators.required, Validators.pattern(/^[a-zA-Z\s]*$/)]
        }
      },
      {
        fieldInfo: {
          fieldName: QuoteGeneralRequest.StateOrProvinces,
          label: this.commonContentService.getLabels().quoteStateFieldLbl,
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: this.commonContentService.getLabels()
              .quoteStateValidationText
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: [],
            optionConfig: {
              displayKey: "Description",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      },
      {
        fieldInfo: {
          fieldName: QuoteGeneralRequest.ZipCode,
          label: this.commonContentService.getLabels().quoteZipCodeFieldLbl,
          fieldType: FieldTypes.Numeric,
          showLabel: true,
          validation: {
            required: this.commonContentService.getLabels()
              .quoteZiprequiredValidationText,
            maxlength: this.commonContentService.getLabels()
              .quoteZipMaxlengthValidationText,
            minlength: this.commonContentService.getLabels()
              .quoteZipMinlengthValidationText
          }
        },
        config: {
          validation: [
            Validators.required,
            Validators.maxLength(5),
            Validators.minLength(3)
          ]
        }
      },
      {
        fieldInfo: {
          fieldName: QuoteGeneralRequest.Countries,
          label: this.commonContentService.getLabels().quoteCountryFieldLbl,
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: this.commonContentService.getLabels()
              .quoteCountryValidationText
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: [],
            optionConfig: {
              displayKey: "Description",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      }
    ]
  };
  //Broker Information Section config fields
  brokerSectionConfig = {
    brokerConfigField: [
      {
        fieldInfo: {
          fieldName: QuoteGeneralRequest.BrokerName,
          label: this.commonContentService.getLabels().quoteBrokerFieldLBl,
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: this.commonContentService.getLabels()
              .quoteBrokerValidationText
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: [],
            optionConfig: {
              displayKey: "FullName",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      },
      {
        fieldInfo: {
          fieldName: QuoteGeneralRequest.BrokerNumber,
          label: this.commonContentService.getLabels().quoteSeriesFieldLBL,
          fieldType: FieldTypes.Text,
          showLabel: true,
          validation: {
            required: " "
          }
        },
        colClass: "col-md-3",
        config: {
          validation: [Validators.required]
        }
      }
    ]
  };
  //Broker Information Section config fields
  underwriterSectionConfig = {
    underwriterConfigField: [
      {
        fieldInfo: {
          fieldName: QuoteGeneralRequest.Underwriters,
          label: this.commonContentService.getLabels().quoteUnderwriterFieldLbl,
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: this.commonContentService.getLabels()
              .quoteUnderwriterValidationText
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: [],
            optionConfig: {
              displayKey: "Description",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      },
      {
        fieldInfo: {
          fieldName: QuoteGeneralRequest.UnderwriterAssistants,
          label: this.commonContentService.getLabels().quoteUWAssistentFieldLbl,
          fieldType: FieldTypes.LookupList,
          showLabel: true
        },
        config: {
          selectConfig: {
            selectedOption: [],
            options: [],
            optionConfig: {
              displayKey: "Description",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      }
    ]
  };
}
