import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  OnDestroy
} from "@angular/core";
import { QuoteStepStoreService } from "../../quote/store/facade/quote-step-store.service";
import { QuotePolicyStepStore } from "../../quote/store/state/quote-policy-step.state";
import {
  EcoMasterData,
  ProducerCommissions,
  ElementUpdateRequest
} from "@tmhcc/data-service";
import {
  InputConfig,
  FieldInfo,
  FieldTypes
} from "@tmhcc/framwork/form-inputs";
import {
  FormBuilder,
  FormGroup,
  Validators,
  ValidatorFn,
  ValidationErrors
} from "@angular/forms";
import {
  PolicyTxnData,
  EventGridRow,
  ElementUpdateRequests,
  UWQuotes,
  LOBRisks,
  EcoMaster
} from "@tmhcc/models";
import * as _ from "lodash";
import { DateService, Moment } from "@tmhcc/framwork/services";
import { RiskModel, RiskResponseType } from "../events/event.config";
import { ecaConstant } from "../../shared/eco-constant";
import { LoaderService } from "@tmhcc/framwork/loader/src";
import { CommonContentService } from "@tmhcc/content-service";
import moment from "moment-timezone";
import { Subscription } from "rxjs";
import { CoreInputValidationService } from "@tmhcc/framwork/core-inputs/src";
@Component({
  selector: "ec-policy-summary",
  templateUrl: "./policy-summary.component.html",
  styleUrls: ["./policy-summary.component.scss"]
})
export class PolicySummaryComponent implements OnInit, OnChanges, OnDestroy {
  quotePolicyStepStore: QuotePolicyStepStore;
  policyTypeId: number;
  producerCommissions: any;
  setCarriername:any[];
  writingCompanyId: number;
  commanRate: number;
  policyType: string;
  additonalSummary: any[];
  LimitOfInsurance: number;
  policySummary: FormGroup;
  uWQuotesData: any;
  commissionValue: number;
  taxesAndFee: any[];
  producerCommissionsObj: ProducerCommissions;
  _CommissionObj: any;
  defaultCommRate: number;
  returnproducerCommissions: any;
  isPolicyBound: boolean;
  policyDateValues: PolicyDateValues;
  endorsementsDropDowns: EndorsementsDropDowns;
  getUserConfigurableDataElements: any;
  elementUpdateRequest: ElementUpdateRequest[] = [];
  sendElementUpdateRequestData: any;
  carrierWritingCompanyId: number;
  _GetpolicyExpDate: any;
  _GetpolicyEffDate: any;
  selectedWritingCompanyId: number;
  showpremimuAmount: number;
  _UWQuotaSharesData: any;
  policyNumber: string;
  taxesAndFees: any;
  getLabelsName: any;
  uWQuotesDataCollection: any;
  quoteStepStoreServiceInstance: Subscription;
  @Input() currentUWQuoteID: any;
  @Output() isValidPolicyChildForm: EventEmitter<any> = new EventEmitter<any>();
  policyTxnData: PolicyTxnData;

  constructor(
    private quoteStepStoreService: QuoteStepStoreService,
    private ecoMasterData: EcoMasterData,
    private formBuilder: FormBuilder,
    private dateService: DateService,
    private commonContentServices: CommonContentService,
    private loaderService: LoaderService,
    private validationService: CoreInputValidationService
  ) {
    this.getLabelsName = this.commonContentServices.getLabels();
  }

  policyExp: InputConfig = {
    config: {
      datePickerConfig: {
        dateInputFormat: this.dateService.getFormat(),
        isCalanderRequired: true
      }
    },
    fieldInfo: {
      fieldName: "policyExp",
      label: "Date Picker",
      fieldType: FieldTypes.Date,
      showLabel: false,
      validation: {
        invalidDate: "Invalid date"
      }
    }
  };

  policyEff: InputConfig = {
    config: {
      datePickerConfig: {
        dateInputFormat: this.dateService.getFormat(),
        isCalanderRequired: true
      }
    },
    fieldInfo: {
      fieldName: "policyEff",
      label: "Date Picker",
      fieldType: FieldTypes.Date,
      showLabel: false,
      validation: {
        pastDate: "Can not be a past date",
        invalidDate: "Invalid date"
      }
    }
  };

  ngOnChanges() {
    if (this.currentUWQuoteID) {
      this.calculatePolicyDates();
      this.policySummary.setValidators(
        this.policyDateValidator("policyEff", "policyExp", {
          ...this.policyDateValues
        })
      );
      this.additionalSummaryData(this.currentUWQuoteID);
      this.showCommissionRate();
      this.getCommissionPercentage();
      setTimeout(() => {
        this.setDefaultPolciDates();
      }, 0);
    }
  }

  ngOnInit(): void {
    this.isPolicyBound = false;
    this.populatePolicyEffExpDates();
    this.initFormGroup();
    this.getPolicySummary();
    
    this.getCommissionPercentage();
    this.setEndorsementsDropDownValues();
    this.policySummary.controls["producerCommission"].valueChanges.subscribe(
      value => {
        let valid: any = /^[0-9%`.-]*$/;
        valid = valid.test(value);
        if (!valid) {
          this.policySummary.controls.producerCommission.setValue("");
        }
      }
    );
  }

  setCarrierNameValue() {
    console.log(this.setCarriername);
    if(this.setCarriername){
      this.setCarriername= this.setCarriername.filter(
        items => (items.StateExpDate && this.dateService.isAfterDate(
          this.dateService.toShortDate(
           this.dateService.moment(
             
            items.StateExpDate
           ),
        ),
        this.dateService.toShortDate(
           this.dateService.moment(
            this.quoteStepStoreService.data.policyTxnData.PolicyEff
            
           )
         )
       )) || !items.StateExpDate
      );
    }
setTimeout(() => {
  if (this.selectedWritingCompanyId && this.setCarriername) {
    this.setCarriername.forEach((CarriernameObject: any) => {
      if (
        CarriernameObject.WritingCompanyId == this.selectedWritingCompanyId
      ) {
        const setCareerNamedefault = CarriernameObject.WritingCompany;
        this.policySummary.get("CarrierName").setValue(setCareerNamedefault);
        this.carrierWritingCompanyId = this.selectedWritingCompanyId;
      }
    });
  }
}, 500);
   
  }
  additionalSummaryData(currentUWQuoteID) {
    if (this.additonalSummary) {
      this.additonalSummary.forEach(element => {
        if (element.UWQuoteId === Number(currentUWQuoteID)) {
          if (element.Description == "Event Limit of Insurance") {
            this.LimitOfInsurance = element.Amount;
          }
          if (element.Description == "SelectedPremSum") {
            this.showpremimuAmount = element.Amount;
          }
        }
      });
    }
  }

  populatePolicyEffExpDates() {
    if (this.quoteStepStoreService.data) {
      this.policyTxnData = this.quoteStepStoreService.data.policyTxnData;
    }

    if (
      this.policyTxnData &&
      this.policyTxnData.PolicyMovement &&
      this.policyTxnData.PolicyMovement.length !== 0
    ) {
      if (
        this.policyTxnData.ProcessStatusCode === "*" ||
        this.policyTxnData.ProcessStatusCode === "I"
      ) {
        this.isPolicyBound = true;
        this.policyNumber = this.policyTxnData.PolicyNum
          ? this.policyTxnData.PolicyNum.toString()
          : null;
      } else {
        this.isPolicyBound = false;
      }
    }
  }

  setDefaultPolciDates() {
    if (
      this.quoteStepStoreService.data &&
      this.quoteStepStoreService.data.policyTxnData &&
      this.quoteStepStoreService.data.policyTxnData.PolicyEff &&
      this.quoteStepStoreService.data.policyTxnData.PolicyExp
    ) {
      const effDate = this.dateService.toShortDate(
        this.dateService.momentFromLongISO(
          this.quoteStepStoreService.data.policyTxnData.PolicyEff
        )
      );
      const expDate = this.dateService.toShortDate(
        this.dateService.momentFromLongISO(
          this.quoteStepStoreService.data.policyTxnData.PolicyExp
        )
      );
      if (effDate) {
        const today = moment();
        if (
          this.dateService.isBeforeDate(
            this.dateService.toShortDate(
              this.dateService.moment(
                effDate
              )
            ),
            this.dateService.toShortDate(
              this.dateService.moment(
                today
              )
            )
          )
        ) {
         
          this.policySummary.get("policyEff").setValue(effDate);
        }
        else{
          const effDateValue=this.dateService.toShortDate(
              today
          );
          this.policySummary.get("policyEff").setValue(effDateValue);
        }
      }
      
      this.policySummary.get("policyExp").setValue(expDate);
    }
  }

  calculatePolicyDates() {
    this.getEventFromPolicy();
  }

  getEventFromPolicy() {
    this.policyDateValues = {
      firstStartDate: "",
      lastEndDate: ""
    };
    const uWQuotes: UWQuotes = this.quoteStepStoreService.data.policyTxnData.UWQuotes.find(
      (value: UWQuotes) => {
        return (
          this.currentUWQuoteID &&
          Number(this.currentUWQuoteID) === value.UWQuoteId
        );
      }
    );
    if (uWQuotes && uWQuotes.LOBs && uWQuotes.LOBs.length !== 0) {
      const risks = uWQuotes.LOBs[0].Risks; // As per service team, always using LOBs' 1st element
      if (risks && risks.length !== 0) {
        risks.forEach((lOBRisk: LOBRisks) => {
          if (
            lOBRisk.RiskTypeCode !== "LOB" &&
            lOBRisk.RiskDataElements &&
            lOBRisk.RiskDataElements.ElementUpdateRequests
          ) {
            lOBRisk.RiskDataElements.ElementUpdateRequests.forEach(
              (value: ElementUpdateRequests) => {
                const riskObject = _.pickBy(
                  RiskModel,
                  riskVal => riskVal.name === value.ElementCode
                );
                const riskKey = _.keys(riskObject)[0];
                if (riskKey) {
                  if (
                    riskObject[riskKey].type === RiskResponseType.DateResponse
                  ) {
                    if (value.ElementCode === "StartDt") {
                      if (this.policyDateValues.firstStartDate) {
                        if (
                          this.dateService.isBeforeDate(
                            this.dateService.toShortDate(
                              this.dateService.momentFromLongISO(
                                value.DateValue
                              )
                            ),
                            this.dateService.toShortDate(
                              this.dateService.momentFromLongISO(
                                this.policyDateValues.firstStartDate
                              )
                            )
                          )
                        ) {
                          this.policyDateValues.firstStartDate =
                            value.DateValue;
                        }
                      } else {
                        this.policyDateValues.firstStartDate = value.DateValue;
                      }
                    } else {
                      if (this.policyDateValues.lastEndDate) {
                        if (
                          this.dateService.isAfterDate(
                            this.dateService.toShortDate(
                              this.dateService.momentFromLongISO(
                                value.DateValue
                              )
                            ),
                            this.dateService.toShortDate(
                              this.dateService.momentFromLongISO(
                                this.policyDateValues.lastEndDate
                              )
                            )
                          )
                        ) {
                          this.policyDateValues.lastEndDate = value.DateValue;
                        }
                      } else {
                        this.policyDateValues.lastEndDate = value.DateValue;
                      }
                    }
                  }
                }
              }
            );
          }
        });
      }
    }
  }

  /**
   * Using getPolicySummary method we get the producerCommission value.
   */
  getPolicySummary() {
    this.quoteStepStoreService.data$.subscribe((data: QuotePolicyStepStore) => {
      this.quotePolicyStepStore = data;
      if (
        this.quotePolicyStepStore &&
        this.quotePolicyStepStore.policyTxnData
      ) {
        const policyData = this.quotePolicyStepStore.policyTxnData;
        this.policyType = policyData.PolicyTypeDescription;
        this.additonalSummary = policyData.AdditionalSummaries;
        this.policyTypeId = this.quotePolicyStepStore.policyTxnData.PolicyTypeId;
        this.uWQuotesData = this.quotePolicyStepStore.policyTxnData.UWQuotes;

        if (this.uWQuotesData && this.uWQuotesData.length !== 0) {
          this.updateUWQuoteProperties(
            this.uWQuotesData[this.uWQuotesData.length - 1]
          );

          this.showCommissionRate();
          this.additionalSummaryData(this.currentUWQuoteID);
        }
        this.setCarrierName();
        console.log("test");
      }
    });
  }

  showCommissionRate() {
    this.taxesAndFees=[];
    this.uWQuotesDataCollection = this.quotePolicyStepStore.policyTxnData.UWQuotes;
    if (
      this.uWQuotesDataCollection &&
      this.uWQuotesDataCollection.length !== 0
    ) {
      this.uWQuotesDataCollection.forEach(quoteselements => {
        const taxAndFeesDetails =
          quoteselements.TaxesAndFees.ConsolidatedTaxesAndFeesResponseItems;
        if (
          taxAndFeesDetails &&
          taxAndFeesDetails.length !== 0 &&
          quoteselements.UWQuoteId == this.currentUWQuoteID
        ) {
          this.taxesAndFees =
            quoteselements.TaxesAndFees.ConsolidatedTaxesAndFeesResponseItems;
        }
        if (
          quoteselements.WritingCompanyId &&
          quoteselements.UWQuoteId == this.currentUWQuoteID
        ) {
          this.writingCompanyId = quoteselements.WritingCompanyId;
          this.selectedWritingCompanyId = this.writingCompanyId;
          this.setCarrierNameValue(); 
        }

        this._CommissionObj = quoteselements.ProducerCommissions;
        if (
          this._CommissionObj &&
          this._CommissionObj.length !== 0 &&
          quoteselements.UWQuoteId == this.currentUWQuoteID
        ) {
          this._CommissionObj.forEach((CommissionsObject: any) => {
            this.commanRate = CommissionsObject.CommRate;
            if (CommissionsObject.OverrideComm == true) {
              let commrate: any = this.commanRate * 100;
              commrate = parseFloat(commrate);
              commrate = commrate.toFixed(2);
              this.commanRate = commrate;
              this.policySummary
                .get("producerCommission")
                .setValue(this.commanRate + "%");
            } else {
              this.defaultCommRate = CommissionsObject.DefaultCommRate;
              if (this.defaultCommRate) {
                let _defaultCR: any = this.defaultCommRate * 100;
                _defaultCR = parseFloat(_defaultCR);
                _defaultCR = _defaultCR.toFixed(2);
                this.defaultCommRate = _defaultCR;
                this.policySummary
                  .get("producerCommission")
                  .setValue(this.defaultCommRate + "%");
              }
            }
          });
        }
      });
    }
  }

  updateUWQuoteProperties(uWQuotesInstance: UWQuotes) {
    const SelectedUwQuoteId = sessionStorage.selectedUwQuoteId;
    if (SelectedUwQuoteId) {
      this.currentUWQuoteID = SelectedUwQuoteId;
    } else {
      this.currentUWQuoteID = uWQuotesInstance.UWQuoteId;
      sessionStorage.selectedUwQuoteId = this.currentUWQuoteID;
    }
  }
  /**
   * Using setCarrierName method we get the CarrierName DropDown Valye value.
   */
  setCarrierName() {
    this.FrSelectConfig.config.selectConfig.options=[];
    const {
      WritingCompaniesByStates,
      UserConfigurableDataElements
    } = this.ecoMasterData.getMasterData();
    this.setCarriername = WritingCompaniesByStates;
    this.getUserConfigurableDataElements = UserConfigurableDataElements;
    if (
      this.getUserConfigurableDataElements &&
      this.getUserConfigurableDataElements.length !== 0
    ) {
      this.sendndorsementsLossDropboxValue();
    }
    if (this.setCarriername) {
      //Sorting
      this.setCarriername= this.setCarriername.filter(
        items => (items.StateExpDate && this.dateService.isAfterDate(
          this.dateService.toShortDate(
           this.dateService.moment(
            items.StateExpDate

           ),
        ),
        this.dateService.toShortDate(
           this.dateService.moment(
            this.quoteStepStoreService.data.policyTxnData.PolicyEff
           )
         )
       )) || !items.StateExpDate
      );
      
      
    }
    this.setCarriername = this.setCarriername.sort((a: any, b: any) =>
    a.WritingCompany.localeCompare(b.WritingCompany)
    );
    
    if (this.setCarriername && this.setCarriername.length !== 0) {
      if (this.quotePolicyStepStore.policyTxnData) {
        const _PolicyTypeID = this.quotePolicyStepStore.policyTxnData
          .PolicyTypeId;
        const _CountryCode = this.quotePolicyStepStore.policyTxnData
          .CountryCode;
        const _StateId = this.quotePolicyStepStore.policyTxnData.StateId;

        this.setCarriername = this.setCarriername.filter(function(
          carrierObj: any
        ) {
          return (
            carrierObj.PolicyTypeId == _PolicyTypeID &&
            carrierObj.CountryCode == _CountryCode &&
             carrierObj.StateId == _StateId 
          );
        });
      }
      this.setCarrierNameValue();
    }
    this.setCarriername.forEach((CarriernameObject: any) => {
      this.FrSelectConfig.config.selectConfig.options.push(
        CarriernameObject.WritingCompany
      );
    });
    
 
    this.policySummary.controls["CarrierName"].valueChanges.subscribe(value => {
      const selectedValue = value;
      this.setCarriername.forEach((CarriernameObject: any) => {
        if (selectedValue == CarriernameObject.WritingCompany) {
          this.carrierWritingCompanyId = CarriernameObject.WritingCompanyId;
        }
      });
    });
  }
  /**
   * Set the Endorsement dropdown value at the time of loading page
   */
 
  sendndorsementsLossDropboxValue() {
    this.elementUpdateRequest = [];
    this.getUserConfigurableDataElements.forEach((configData: any) => {
      if (configData.ElementCode.toLowerCase() == "fll") {
        //Check drop down value is saved or not before
        let sendElementUpdateRequest: ElementUpdateRequest = {
          DataGroupId: configData.DataGroupId,
          DataGroupName: configData.DataGroupName
            ? configData.DataGroupName
            : "",
          IsArray: configData.GroupIsArray,
          ElementId: configData.ElementId,
          ElementName: configData.ElementName,
          ResponseType: configData.ResponseType,
          SelectedDataElementResponseIds: []
        };
        this.elementUpdateRequest.push(sendElementUpdateRequest);

        //On Change Drop Down
        this.policySummary.controls["endorsementsLimit"].valueChanges.subscribe(
          value => {
            if (value) {
              if (value.toLowerCase() == "yes") {
                let yesID = configData.DataElementResponses.find(
                  elements => elements.ResponseCode == "YES"
                );
                this.elementUpdateRequest.forEach((elementsarr: any) => {
                  if (elementsarr.ElementId == configData.ElementId) {
                    elementsarr.SelectedDataElementResponseIds[0] =
                      yesID.DataElementResponseId;
                  }
                });
              }
              if (value.toLowerCase() == "no") {
                let noID = configData.DataElementResponses.find(
                  elements => elements.ResponseCode == "NO"
                );
                this.elementUpdateRequest.forEach((elementsarr: any) => {
                  if (elementsarr.ElementId == configData.ElementId) {
                    elementsarr.SelectedDataElementResponseIds[0] =
                      noID.DataElementResponseId;
                  }
                });
              }
            }
          }
        );

        /**
         * Set Dropdown Endorsements Limit Value
         */
        if (
          this.quotePolicyStepStore.policyTxnData.policyDataElements
            .ElementUpdateRequests.length !== 0
        ) {
          this.quotePolicyStepStore.policyTxnData.policyDataElements.ElementUpdateRequests.forEach(
            _ElementUpdateRequests => {
              if (configData.ElementId == _ElementUpdateRequests.ElementId) {
                if (
                  _ElementUpdateRequests.SelectedDataElementResponseIds
                    .length !== 0
                ) {
                  let getselectedID =
                    _ElementUpdateRequests.SelectedDataElementResponseIds[0];
                  //Here we set the SelectedDataElementResponseIds data if we have
                  this.elementUpdateRequest.forEach((elementsarr: any) => {
                    if (elementsarr.ElementId == configData.ElementId) {
                      elementsarr.SelectedDataElementResponseIds[0] = getselectedID;
                    }
                  });
                  configData.DataElementResponses.forEach(
                    (responseElement: any) => {
                      if (
                        responseElement.DataElementResponseId == getselectedID
                      ) {
                        this.policySummary
                          .get("endorsementsLimit")
                          .setValue(
                            responseElement.ResponseCode
                              ? responseElement.ResponseCode
                              : responseElement.ResponseName
                          );
                      }
                    }
                  );
                }
              }
            }
          );
        }
      }
      if (configData.ElementCode.toLowerCase() == "cl") {
        let sendElementUpdateRequest: ElementUpdateRequest = {
          DataGroupId: configData.DataGroupId,
          DataGroupName: configData.DataGroupName
            ? configData.DataGroupName
            : "",
          IsArray: configData.GroupIsArray,
          ElementId: configData.ElementId,
          ElementName: configData.ElementName,
          ResponseType: configData.ResponseType,
          SelectedDataElementResponseIds: []
        };
        this.elementUpdateRequest.push(sendElementUpdateRequest);

        //On Change Drop Down
        this.policySummary.controls["endorsementsLoss"].valueChanges.subscribe(
          value => {
            if (value) {
              if (value.toLowerCase() == "yes") {
                let yesID = configData.DataElementResponses.find(
                  elements => elements.ResponseCode == "YES"
                );
                this.elementUpdateRequest.forEach((elementsarr: any) => {
                  if (elementsarr.ElementId == configData.ElementId) {
                    elementsarr.SelectedDataElementResponseIds[0] =
                      yesID.DataElementResponseId;
                  }
                });
              }
              if (value.toLowerCase() == "no") {
                let noID = configData.DataElementResponses.find(
                  elements => elements.ResponseCode == "NO"
                );
                this.elementUpdateRequest.forEach((elementsarr: any) => {
                  if (elementsarr.ElementId == configData.ElementId) {
                    elementsarr.SelectedDataElementResponseIds[0] =
                      noID.DataElementResponseId;
                  }
                });
              }
            }
          }
        );

        /**
         * Set Dropdown Endorsements Limit Value
         */
        if (
          this.quotePolicyStepStore.policyTxnData.policyDataElements
            .ElementUpdateRequests.length !== 0
        ) {
          this.quotePolicyStepStore.policyTxnData.policyDataElements.ElementUpdateRequests.forEach(
            elementUpdateRequestsData => {
              if (configData.ElementId == elementUpdateRequestsData.ElementId) {
                if (
                  elementUpdateRequestsData.SelectedDataElementResponseIds
                    .length !== 0
                ) {
                  let getselectedID =
                    elementUpdateRequestsData.SelectedDataElementResponseIds[0];
                  this.elementUpdateRequest.forEach((elementsarr: any) => {
                    if (elementsarr.ElementId == configData.ElementId) {
                      elementsarr.SelectedDataElementResponseIds[0] = getselectedID;
                    }
                  });
                  configData.DataElementResponses.forEach(
                    (responseElement: any) => {
                      if (
                        responseElement.DataElementResponseId == getselectedID
                      ) {
                        this.policySummary
                          .get("endorsementsLoss")
                          .setValue(
                            responseElement.ResponseCode
                              ? responseElement.ResponseCode
                              : responseElement.ResponseName
                          );
                      }
                    }
                  );
                }
              }
            }
          );
        }
      }
    });

    this.sendElementUpdateRequestData = this.elementUpdateRequest;
  }

  setEndorsementsDropDownValues() {
    this.endorsementsDropDowns = {
      active: "Yes",
      inactive: "No"
    };
    this.endorsementsLimitSelectConfig.config.selectConfig.options.push(
      this.endorsementsDropDowns.active,
      this.endorsementsDropDowns.inactive
    );
    this.endorsementsLossSelectConfig.config.selectConfig.options.push(
      this.endorsementsDropDowns.active,
      this.endorsementsDropDowns.inactive
    );
  }

  initFormGroup() {
    const controls = {};
    controls[this.FrSelectList.fieldName] = ["", Validators.required];
    controls[this.endorsementsLimitSelectList.fieldName] = [
      "",
      Validators.required
    ];
    controls[this.endorsementsLossSelectList.fieldName] = [
      "",
      Validators.required
    ];
    controls[this.ProducerCommissions.fieldName] = [""];
    controls[this.policyExp.fieldInfo.fieldName] = [];
    controls[this.policyEff.fieldInfo.fieldName] = [];
    this.policySummary = this.formBuilder.group(controls);
  }

  /**
   * For Send ProducerCommissions Data to Save API
   */

  public getCommissionPercentage() {
    this.returnproducerCommissions = [];
    if (this.commanRate > 1) {
      this.commanRate = this.commanRate / 100;
    }
    let producerCommissions: ProducerCommissions[] = [];
    this.uWQuotesData = this.quotePolicyStepStore.policyTxnData.UWQuotes;
    if (this.uWQuotesData && this.uWQuotesData.length !== 0) {
      this.uWQuotesData.forEach((uWQuotesDataObject: any) => {
        this.writingCompanyId = uWQuotesDataObject.WritingCompanyId;
        this._CommissionObj = uWQuotesDataObject.ProducerCommissions;
        if (uWQuotesDataObject.UWQuoteId == this.currentUWQuoteID) {
          if (this._CommissionObj && this._CommissionObj.length !== 0) {
            this._CommissionObj.forEach((CommissionsObject: any) => {
              let _Commissions: ProducerCommissions = {
                UWQuoteId: uWQuotesDataObject.UWQuoteId,
                UWLOBId: CommissionsObject.UWLOBId,
                PolicyProducerContactId:
                  CommissionsObject.PolicyProducerContactId,
                OverrideComm: CommissionsObject.OverrideComm,
                CommRate: this.commanRate,
                DefaultCommRate: CommissionsObject.DefaultCommRate,
                CommAmt: CommissionsObject.CommAmt
              };
              producerCommissions.push(_Commissions);
            });
          }
          //If ProducerCommissions Array is empty
          else {
            let _Commissions: ProducerCommissions = {
              PolicyProducerContactId: this.quotePolicyStepStore.policyTxnData
                .PolicyProducerContactId,
              UWQuoteId: uWQuotesDataObject.UWQuoteId,
              CommRate: this.commanRate ? this.commanRate : 0
            };
            producerCommissions.push(_Commissions);
          }
        }
      });
    }
    this.returnproducerCommissions = producerCommissions;
  }

  /**
   * Check Form Validation
   */

  onChangeProducerCommissions(event: any) {
    let producerCommissionValue: any = event.toString();
    producerCommissionValue = producerCommissionValue.replace(/[%]/g, "");
    producerCommissionValue = parseFloat(producerCommissionValue);
    producerCommissionValue = producerCommissionValue.toFixed(2);
    if (producerCommissionValue > 100 || !event || event == "%") {
      this.policySummary.get("producerCommission").setValue("");
      this.commanRate = 0;
      this.getCommissionPercentage();
    } else {
      this.commanRate = producerCommissionValue / 100;
      this.policySummary
        .get("producerCommission")
        .setValue(producerCommissionValue + "%");
      this.getCommissionPercentage();
    }
  }

  public policySummaryValidate(data: any) {
    if (data === true) {
      if (this.policySummary.valid === true) {
        this.isValidPolicyChildForm.emit(true);
      } else {
        this.isValidPolicyChildForm.emit(false);
      }
    }
  }
  /* Policy Summary Field Section***/

  FrSelectList: FieldInfo = {
    fieldName: "CarrierName",
    label: "Select",
    fieldType: FieldTypes.LookupList,
    placeholder: "Select",
    showLabel: false,
    validation: {
      required: "This is requered field"
    }
  };

  FrSelectConfig: InputConfig = {
    config: {
      selectConfig: {
        options: [],
        optionConfig: {
          displayKey: "WritingCompany",
          search: false
        },
        multiple: false
      }
    },
    fieldInfo: this.FrSelectList
  };

  /**
   * Endorsements Drop Down
   */

  endorsementsLossSelectList: FieldInfo = {
    fieldName: "endorsementsLoss",
    label: "Select",
    fieldType: FieldTypes.LookupList,
    placeholder: "Select",
    showLabel: false,
    validation: {
      required: "This is requered field"
    }
  };

  endorsementsLossSelectConfig: InputConfig = {
    config: {
      selectConfig: {
        options: [],
        optionConfig: {
          displayKey: "id",
          search: false
        },
        multiple: false
      }
    },
    fieldInfo: this.endorsementsLossSelectList
  };

  endorsementsLimitSelectList: FieldInfo = {
    fieldName: "endorsementsLimit",
    label: "Select",
    fieldType: FieldTypes.LookupList,
    placeholder: "Select",
    showLabel: false,
    validation: {
      required: "This is requered field"
    }
  };

  endorsementsLimitSelectConfig: InputConfig = {
    config: {
      selectConfig: {
        options: [],
        optionConfig: {
          displayKey: "id",
          search: false
        },
        multiple: false
      }
    },
    fieldInfo: this.endorsementsLimitSelectList
  };

  ProducerCommissions: FieldInfo = {
    fieldName: "producerCommission",
    label: "ProducerCommissions",
    fieldType: FieldTypes.Text,
    showLabel: false
  };

  public policyDateValidator(
    effDate: string,
    expDate: string,
    policyDateValues: PolicyDateValues
  ): ValidatorFn {
    return (formGroup: FormGroup): ValidationErrors => {
      const eff = formGroup.controls[effDate];
      const exp = formGroup.controls[expDate];
      const today = moment();

      if (eff.value) {
        if (
          !moment(eff.value).isSame(today, "day") &&
          moment(eff.value).diff(today) < 0
        ) {
          // eff.setErrors({ pastDate: true });
        } else if (
          policyDateValues &&
          policyDateValues.firstStartDate &&
          !moment(eff.value).isSame(
            moment(policyDateValues.firstStartDate),
            "day"
          ) &&
          !moment(eff.value).isSameOrBefore(
            moment(policyDateValues.firstStartDate)
          )
        ) {
          eff.setErrors({ invalidDate: true });
        } else if (
          policyDateValues &&
          policyDateValues.firstStartDate &&
          moment(eff.value).isSame(moment(exp.value), "day")
        ) {
          eff.setErrors({ invalidDate: true });
        } else {
          eff.setErrors(null);
        }
      }
      if (exp.value) {
        if (
          policyDateValues &&
          policyDateValues.lastEndDate &&
          !moment(exp.value).isSame(
            moment(policyDateValues.lastEndDate),
            "day"
          ) &&
          !moment(exp.value).isSameOrAfter(moment(policyDateValues.lastEndDate))
        ) {
          exp.setErrors({ invalidDate: true });
        } else {
          exp.setErrors(null);
        }
      }
      return;
    };
  }

  ngOnDestroy(): void {
    if (this.quoteStepStoreServiceInstance) {
      this.quoteStepStoreServiceInstance.unsubscribe();
    }
  }
}

export interface PolicyDateValues {
  firstStartDate: string;
  lastEndDate: string;
}

export interface EndorsementsDropDowns {
  active: string;
  inactive: string;
}
