export const environment = {
  production: true,
  oktaWidgetConfig: {
    clientId: "0oa1ae7mau4hkhnyU0h8",
    redirectUri: "https://papiadmin-dev.tmhcc.com/callback",
    issuer: "https://hcc-dev.oktapreview.com/oauth2/default",
    scope: ["openid", "email", "profile", "address", "phone", "offline_access"]
  },
  en_key: {
    "_k" : "QrEty34VC2BYUdFge7TeDpM9G0Xtjk8DS0"
  },
  apiConfig: {
    "misc": "U2FsdGVkX1+p3xuJJjsdfNOOVCJJjWYo3JcTQLqB9wZCmGfBFXhd+eW5hhHy9hp+BzgVpDjaaJV487K/zw9qOfjcYDpCWBmtoDuaVkQg8Non1r1DkeTpmDqqsfaBv7FyFLY4U/j4FfXEwLBRxnu64G0CDBJiaqAaLXr9y/Ajana3B27F4EEM2PgyDUYWZB3ws5UkWHl2PrwcG5WvmGVhj3o1fVfJWZWj8dn02Me7P6+nkfjrwJ54uxsWXVqGzgx1BqqZ25Grf3L/NXeEB/yOZicnQfors1x60Ag/kib2CsO32K8QP67dLF0/uIIrAi6VWrg3/SuCwTQaeFUfhIpjP5A2YJVwtisPU0hsMOWrJqAN0u27v+k4lyQkfkjUQTsYFJ3OAO/k+neFLpvCIt2skCSt4/n4r+fsqWw3zFCtcOYpr/2kUN2S+UJnTlhNQQAZZ3BsOP8Duyb2zuttYFbvzwMvCOZXOWcXDytAp3ko8SRlsg9fNFxEMfhP8q2tekfrwtPGgnQQ/DvYQ1l6FcN6nC3l8RbBx/tus+u7Co8mUCWHaqjvRZseHz/36B1vZaJWnOlw2EVINCGpb2xZLHBGXZSxkfhYW2/2L3/Zr71beNbQX1dqghIAKrvfXwvngAoXuhbg61a8F9Y7HRp9fqSBMG+HUCb5Y5v/OMsUITmyhQp1gwK+2LcazNzeoWUcS4PdJXWORipAlk96r7OayGxFV0r4pj3Gt2zLxlvec1WjDs/3viF5RvbWRhhUUVDJsLBHtzqa2FKwD96CMmPxusY4GLYyc/h1nOLKGH6KknRfPmeYVJF1W9aRcSeXZ5JAa9iasz6uNOr9BWSeLZz5zPv7Wmu26mrvdkxWvaogNKqpXqTzWnt6JWa4b/hF/p8xGWzlR+JXuiM0fDhA0hvFM4OJqmshS1zO/5VjaAJhMK0VOVZfbGbwWWr4K+HWIhKK3ljDB7SvxiUUcQCUjiZ7qLDBsFsdtIlMUqGDzS/e1h8pHlWsV1zxtbok0/ZRMhBMaVylNJTPcMk1Kxol02vecDqF8xxIURudO85f0vUXfz2orNN8PQnEcnSbO6QTPDJlJhaJl1QOO8nDUaHxUFxOhCSXzDAIcGp1JJgg+BRzWDdf0hXetIqwEbfVKRf67c76LjQhnuE0c792jNtoc85aAzyMjmyZqGRjlFn/aQo+URvl4uoCmzAlLGOAJZom8q4qYM0OKfSTUzKHEt6lxwv5mH+S7iFp3InqLjRYk1k56qxPSlzEBYGCmZat+GWMzLtapwQlQPRpq4PnDc1VJylkBR1p3qinQHPFX2f4yXPb1TwlQmwQNx4Du/vV4W0yqqv0jqS9wKb3bXZIa+jw8JyQiC+F/ZqjWWgW1+rL/nVnJuUH78GXhvTXUJVrANuQ69ZykCGZ8EsUwAt5qIe2SRTNCWbww7Wyt0jt+Pc5Hc8kMtfjo0f58iOEMAzWI3AWWJIlU8VghhH1lnT5TlBUyWXRaD5HROcgHO44KHQLsATJ/imqSRn8FmeuhdEqi0Q8Ag/iq+ttIXZgo6ujXfXyOyDUl0ty3JtaVwh0Y0eayLg7aofoYHLYKtHAheasLC0XmMWrfr7Hn+94VXWiq03VywFnUZRs9yGHznyvZ4cO2Ns62g088jRO4tLPRbnvMgLvfG09ZwRiw/u8DnSKYt8cxaBd/ssWMqEYCQBGS9tWERaAto9W7kgblo2OuhN1FikG++hsyR3Yhu4eH9a0F6VLrO/WWcXzXbzB5rgKaVqURz3vRX2cxmA="
  },
  appConfig: {
      "appName": "TMHCC EVENT CANCELLATION",
      "baseHref": "/eco",
      "appKey": "tmhcc-event-cancellation",
      "datePattern": "MM/DD/YYYY",
      "currencyPattern": "",
      "dataServiceBaseUrl": "https://beyondapi-nonprod.tmhcc.com/DEV",
      "contentServiceBaseUrl": "",
      "boxUrl": "https://hcdevbox.azurewebsites.net",
      "smartStreetAPIKey":"29050599823958456",
      "logging": {
        "console": true,
        "appInsightEnabled": false,
        "appInsights": {
          "instrumentationKey": "9a51af73-2d5e-4414-8084-94717542b129",
          "enableAutoRouteTracking": false
        }
      }
   }
};
