import {
  Component,
  forwardRef,
  Input,
  ViewEncapsulation,
  OnInit
} from "@angular/core";
import { InputBase } from "../input-base";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
@Component({
  selector: "hcc-select",
  templateUrl: "./select-list.component.html",
  styleUrls: ["./select-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectListComponent),
      multi: true
    }
  ]
})
export class SelectListComponent extends InputBase<string> implements OnInit {
  constructor() {
    super();
  }

  isDependentDropdown = false;

  ngOnInit() {
    if (this.InputConfig.selectConfig.hasOwnProperty("optionConfig")) {
      this.InputConfig.selectConfig.optionConfig["height"] = "250px";
    }
    if (this.InputConfig.selectConfig.hasOwnProperty("dependent")) {
      let dependentMapper = this.InputConfig.selectConfig.dependent.mapper;
      this.isDependentDropdown = true;
      let mapperValue = this.frmGroup.get(
        this.InputConfig.selectConfig.dependent.field
      ).value;
      this.dependentSelectBoxMapper(mapperValue, dependentMapper);
      this.frmGroup
        .get(this.InputConfig.selectConfig.dependent.field)
        .valueChanges.subscribe(data => {
          this.dependentSelectBoxMapper(data, dependentMapper);
        });
    }
  }

  dependentSelectBoxMapper(data, dependentMapper) {
    if (data[dependentMapper.key] == dependentMapper.value) {
      this.isDependentDropdown = false;
    } else {
      this.isDependentDropdown = true;
    }
  }

  getPlaceHolder(): string {
    if (this.InputConfig.selectConfig.placeholder) {
      return this.InputConfig.selectConfig.placeholder;
    } else {
      return "Please Select";
    }
  }
}
