<p>{{ parentComponentData }}</p>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary"
    data-dismiss="modal"
    (click)="onSave()"
  >
    YES
  </button>
  <button type="button" (click)="onClose()" class="btn btn-secondary">
    NO
  </button>
</div>
