import { Moment } from "@tmhcc/framwork/services";

  // internal - should not be used outside of library
  export class KnrPolicySearchResponse {
    policySearchResults: KnrPolicySearchResult[];
  }
  
  export class KnrPolicySearchResult {
    QuoteId:string;
    QuoteNumber: string;
    PolicyNumber: string; 
    Underwriter: string;
    Broker: string;
    Limit: string;
    Status: string;
    GrossPremium: string;
    InsuredName: string;
  }