import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { BaseDataService } from "./base-data-service";
import { AppConfigService } from "@tmhcc/global-services";
import { DateService } from "@tmhcc/framwork/services";
import { CommonContentService } from "@tmhcc/content-service";

import { CreateNewQuoteModel } from "@tmhcc/models";
import { map, catchError } from "rxjs/operators";
import {
  QuoteCreateRequest,
  QuoteCreateResponse
} from "./def/quote-new-bussiness";

@Injectable()
export class QuoteCreateBussinessService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    private dateService: DateService,
    private commonContentService: CommonContentService
  ) {
    super(http, configService);
  }

  getPolicyData: any;

  createNewQuote(
    payload: QuoteCreateRequest
  ): Observable<CreateNewQuoteModel[]> {
    const url = `${this.serviceBaseUrl}/CreateNewBusinessECO`;

    return this.post<QuoteCreateResponse>(url, { body: payload }).pipe(
      map(data => {
        this.getPolicyData = data;
        return this.getPolicyData;
      }),
      catchError(this.handleError)
    );
  }
}
