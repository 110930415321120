import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Routes, RouterModule } from "@angular/router";
import { DocumentComponent } from "./document.component";
import { PageHeaderModule } from "../../shared/page-header/page-header.module";
import { QuotePolicyInfoModule } from "../../shared/quote-policy-info/quote-policy-info.module";
import { PolicySummaryModule } from "../policy-summary/policy-summary.module";
import { PageTabInfoModule } from "../../shared/page-tab/page-tab-info.module";

const routes: Routes = [{ path: "", component: DocumentComponent }];

@NgModule({
  declarations: [DocumentComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    PageHeaderModule,
    QuotePolicyInfoModule,
    PolicySummaryModule,
    PageTabInfoModule
  ]
})
export class DocumentModule {}
