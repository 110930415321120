<div class="container">
  <ec-page-header></ec-page-header>
  <div class="col-md-12 ">
    <ec-quote-policy-info></ec-quote-policy-info>
    <ec-page-tab></ec-page-tab>
    <div class="row">
      <div class="col-12 col-md-12 mb-3 mt-3">
        <div class="tab-content" id="nav-tabContent">
          <div *ngIf="quoteVersionFrm">
            <form [formGroup]="quoteVersionFrm">
              <div
                class="tab-pane fade show active"
                id="nav-home"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div class="form-row mt-4 mb-2">
                  <div class="col-md-8 col-lg-4">
                    <h5 class="header-tab">
                      {{ getLabels.eventInformationLbl }}
                    </h5>
                  </div>
                  <div class="col-md-4 col-lg-2">
                    <div class="quote-version" *ngIf="isQuoteVersion">
                      <div [ngClass]="{ formDisabled: isPolicyBind == true }">
                        <hcc-select
                          [control]="selectConfig.fieldInfo.fieldName"
                          [frmGroup]="quoteVersionFrm"
                          [fieldInfo]="selectConfig.fieldInfo"
                          [InputConfig]="selectConfig.config"
                        >
                        </hcc-select>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-md-12 col-lg-6 d-flex justify-content-end"
                    [ngClass]="{ formDisabled: isPolicyBind == true }"
                  >
                    <div class="dropdown mr-3">
                      <button
                        class="btn btn-secondary dropdown-toggle events-options"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        [disabled]="isPolicyBind"
                      >
                        {{ getLabels.optionsLbl }}
                        <i
                          class="fa fa-bars ml-2"
                          style="font-size: 18px;"
                          aria-hidden="true"
                        ></i>
                      </button>

                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <a
                          tabindex="1"
                          class="dropdown-item"
                          href="{{
                            baseURL
                          }}-/media/Project/EventCancellation/EventsTemplate.xlsx"
                          download
                        >
                          <em
                            class="fa fa-download mr-2"
                            style="font-size: 24px;"
                          ></em
                          >{{ getLabels.downloadTemplateLbl }}</a
                        >
                        <!-- <a class="dropdown-item"><em class="fa fa-upload"></em> Upload Template</a> -->
                        <ec-spreadsheet
                          class=""
                          (spreadsheetUploadEvent)="spreadsheetUploaded($event)"
                        >
                        </ec-spreadsheet>
                        <a
                          tabindex="3"
                          href="javascript:;"
                          class="dropdown-item"
                          (click)="copyQuote()"
                          ><i
                            class="fa fa-files-o"
                            aria-hidden="true"
                            style="font-size: 24px;"
                          ></i>
                          {{ getLabels.copyQuoteLbl }}</a
                        >
                      </div>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <a
                          class="dropdown-item"
                          href="{{
                            baseURL
                          }}-/media/Project/EventCancellation/EventsTemplate.xlsx"
                          download
                        >
                          <em
                            class="fa fa-download mr-2"
                            style="font-size: 24px;"
                          ></em
                          >{{ getLabels.downloadTemplateLbl }}</a
                        >
                        <!-- <a class="dropdown-item"><em class="fa fa-upload"></em> Upload Template</a> -->
                        <ec-spreadsheet
                          class="dropdown-item"
                          (spreadsheetUploadEvent)="spreadsheetUploaded($event)"
                        >
                        </ec-spreadsheet>
                        <a class="dropdown-item" (click)="copyQuote()"
                          ><i
                            class="fa fa-files-o"
                            aria-hidden="true"
                            style="font-size: 24px;"
                          ></i>
                          {{ getLabels.copyQuoteLbl }}</a
                        >
                      </div>
                    </div>
                    <div>
                      <button
                        class="btn btn-secondary mt-sm"
                        (click)="openNewEventDialog()"
                        [disabled]="isPolicyBind"
                      >
                        {{ getLabels.addNewEventLbl }}
                      </button>
                    </div>
                  </div>
                </div>
                <div class="form-row mb-2">
                  <div class="col-md-8 col-lg-4">
                    <a
                      *ngIf="selectedRecords && selectedRecords.length > 0"
                      class="delete-selected"
                      (click)="deleteSelected()"
                      [disabled]="isPolicyBind"
                      >Delete Selected</a
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-12">
                    <div *ngIf="showTable" class="step2event-table">
                      <ac-grid-table
                        (childEventForNavigation)="selectEventRecord($event)"
                        (childEvent)="onGridRowSelect($event)"
                        [invalidRecordList]="invalidRecordList"
                        [gridSetting]="gridSetting"
                        [records]="eventRecords"
                        [parentDivName]="'eventListTable'"
                        [isCheckbox]="true"
                        [columnSettings]="eventColumnSettings"
                        class="event-info-table"
                      ></ac-grid-table>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="nav-profile"
                role="tabpanel"
                aria-labelledby="nav-profile-tab"
              >
                <div
                  class="tab-pane fade show active"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  Documents
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <hr class="divider-dark" />
    <div class="button-row">
      <button
        type="button"
        (click)="backButtonPressed()"
        class="btn btn-primary"
      >
        Back
      </button>
      <button
        type="button"
        [disabled]="hasNoEvent || isPolicyBind"
        (click)="saveRefreshClicked()"
        class="btn btn-primary"
      >
        SAVE & REFRESH
      </button>
      <button
        type="button"
        [disabled]="hasNoEvent"
        (click)="nextClicked()"
        class="btn btn-primary"
      >
        Next
      </button>
    </div>
  </div>
</div>
