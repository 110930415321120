<div *ngIf="!formSkipCheck">
  <div *ngIf="!isDeleteDialog">
    <div class="modal-header">
      <h5 class="modal-title">{{ title }}</h5>
      <button type="button" class="close" (click)="onDialogCancel()">
        <span aria-hidden="true">×</span>
      </button>
    </div>

    <div class="modal-body">
      <ul class="popup-form">
        <li *ngFor="let item of subjectivityList; let j = index">
          <div
            [ngClass]="item.isBlank ? 'error-info' : ''"
            class="custome-selectbox"
          >
            <input
              type="text"
              list="one"
              maxlength="350"
              [(ngModel)]="item.description"
              (change)="saveCode($event, item)"
              placeholder="Enter New or Select"
            />
            <datalist id="one">
              <option
                *ngFor="let data of dialogData.data.subjList; let i = index"
              >
                {{ data.name }}
              </option>
            </datalist>
          </div>
          <div class="delete-icon">
            <i
              class="fa fa-trash"
              aria-hidden="true"
              (click)="onDeleteClicked(item)"
            ></i>
          </div>
        </li>
      </ul>
      <div class="button-row">
        <button
          type="button"
          (click)="onDialogCancel()"
          class="btn btn-primary"
        >
          CANCEL
        </button>
        <button
          type="button"
          (click)="addNewSubjectivity()"
          class="btn btn-primary"
        >
          ADD NEW SUBJECTIVITY
        </button>

        <button type="button" (click)="onSaved()" class="btn btn-primary">
          SAVE
        </button>

        <div class="clearfix"></div>
      </div>
    </div>
  </div>
  <div *ngIf="isDeleteDialog">
    <div class="modal-header">
      <h5 class="modal-title">Alert</h5>
      <button type="button" class="close" (click)="deleteEventCancel()">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
      Are you sure you want to delete subjectivity?
    </div>

    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        data-dismiss="modal"
        (click)="deleteEventCancel()"
      >
        No
      </button>
      <button
        type="button"
        class="btn btn-primary"
        (click)="deleteEventConfirmed()"
      >
        Yes
      </button>
    </div>
  </div>
</div>

<div *ngIf="formSkipCheck">
  <div class="modal-header">
    <h5 class="modal-title">Alert</h5>
    <button type="button" class="close" (click)="popupSkipCancel()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    Exiting this window will cause any unsaved data to be lost. Are you sure you
    want to continue?
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      data-dismiss="modal"
      (click)="popupSkipCancel()"
    >
      No
    </button>
    <button type="button" class="btn btn-primary" (click)="popupSkipConfirm()">
      Yes
    </button>
  </div>
</div>
