import { State, Action, StateContext, Store } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { Injectable } from "@angular/core";
import { EventGridRow, SaveEventModel, LOBs } from "@tmhcc/models";
import {
  AddEvent,
  AddEvents,
  EditEvent,
  DeleteAllAddedEvents,
  SaveEvents,
  DeleteEvents
} from "./quote.actions";
import { SaveEventService } from "@tmhcc/data-service";
import { tap, timeout, catchError } from "rxjs/operators";
import { LoaderService } from "@tmhcc/framwork/loader/src";
import { throwError } from "rxjs";

export interface QuoteStore {
  eventList: EventGridRow[];
  saveStatus: any;
  lobs: LOBs[];
}

@State<QuoteStore>({
  name: "Quote",
  defaults: {
    eventList: [],
    saveStatus: {},
    lobs: []
  }
})
@Injectable()
export class QuoteState {
  constructor(
    private saveEventService: SaveEventService,
    private loaderService: LoaderService
  ) {}

  @Action(AddEvent)
  addEvent(ctx: StateContext<QuoteStore>, { eventGridRow }: AddEvent) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      eventList: [...state.eventList, eventGridRow]
    });
  }

  @Action(AddEvents)
  addEvents(ctx: StateContext<QuoteStore>, { eventGridRows }: AddEvents) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      eventList: [...state.eventList, ...eventGridRows]
    });
  }

  @Action(DeleteAllAddedEvents)
  deleteAllAddedEvents(
    ctx: StateContext<QuoteStore>,
    {}: DeleteAllAddedEvents
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      eventList: []
    });
  }

  @Action(DeleteEvents)
  deleteEvents(ctx: StateContext<QuoteStore>, { eventIds }: DeleteEvents) {
    const state = ctx.getState();
    const filteredEvents = state.eventList.filter((value: EventGridRow) => {
      return !eventIds.some(id => id === value.customID);
    });
    ctx.setState({
      ...state,
      eventList: [...filteredEvents]
    });
  }

  @Action(EditEvent)
  editEvent(ctx: StateContext<QuoteStore>, { eventGridRow }: EditEvent) {
    const state = ctx.getState();
    let indexOfInsertion;
    const filteredEventList = state.eventList.filter(
      (value: EventGridRow, i) => {
        if (value.customID !== eventGridRow.customID) {
          return true;
        } else {
          indexOfInsertion = i;
          return false;
        }
      }
    );
    let editedList: EventGridRow[] = [...filteredEventList];
    editedList.splice(indexOfInsertion, 0, eventGridRow);
    console.log(indexOfInsertion);
    ctx.setState({
      ...state,
      eventList: [...editedList]
    });
  }

  @Action(SaveEvents)
  saveEvents(ctx: StateContext<QuoteStore>, { saveEventRequest }: SaveEvents) {
    const state = ctx.getState();
    this.loaderService.show();
    return this.saveEventService.saveEvent(saveEventRequest).pipe(
      tap((data: SaveEventModel) => {
        if (data.Status === "SUCCESS") {
          ctx.setState({
            ...ctx.getState(),
            saveStatus: {
              isSuccess: true
            },
            lobs: [...data.LOBs]
          });
        } else {
          ctx.setState({
            ...ctx.getState(),
            saveStatus: {
              isSuccess: false
            }
          });
        }
        this.loaderService.hide();
      }),
      catchError(err => {
        this.loaderService.hide();
        return throwError(err);
      })
    );
  }
}
