<div class="form-section">
    <form [formGroup]="frmPolicyDetails">
      <div class="form-row form-group mt-2">
        <div *ngFor="let items of policyDetailsInputFields" 
           [ngClass]="items.fieldInfo.fieldName === 'TransactionID' ? 'col-sm-6 col-lg-6' : 'col-sm-6 col-lg-6'"
        > 
        <div *ngIf="items.fieldInfo.fieldName === 'PolicyNumber'">
          <b>{{ items.fieldInfo.label }}</b> {{dialogData.data.policyDetailGridRow.PolicyNumber}}
        </div> 
        <div *ngIf="items.fieldInfo.fieldName === 'TransactionID' && dialogData.data.policyDetailGridRow.ClientType === 'TTB'">
          <b>{{ items.fieldInfo.label }}</b> {{dialogData.data.policyDetailGridRow.TransactionID}}
        </div> 
        <div *ngIf="items.fieldInfo.fieldName === 'TransactionType'">
          <b>{{ items.fieldInfo.label }}</b> {{dialogData.data.policyDetailGridRow.TransactionType}}
        </div> 
        <div *ngIf="items.fieldInfo.fieldName === 'ProductCode'">
          <b>{{ items.fieldInfo.label }}</b> {{dialogData.data.policyDetailGridRow.ProductCode}}
        </div> 
        <div *ngIf="items.fieldInfo.fieldName === 'InsuredName'">
          <b>{{ items.fieldInfo.label }}</b> {{dialogData.data.policyDetailGridRow.InsuredName}}
        </div> 
        <div *ngIf="items.fieldInfo.fieldName === 'Address'">
          <b>{{ items.fieldInfo.label }}</b> {{dialogData.data.policyDetailGridRow.Address}}
        </div> 

        
        <div *ngIf="items.fieldInfo.fieldName === 'City'">
          <b>{{ items.fieldInfo.label }}</b> {{dialogData.data.policyDetailGridRow.City}}
        </div>  
        <div *ngIf="items.fieldInfo.fieldName === 'CreationDate'">
          <b>{{ items.fieldInfo.label }}</b> {{dialogData.data.policyDetailGridRow.CreationDate}}
        </div>  
        <div *ngIf="items.fieldInfo.fieldName === 'PolicyEffectiveDate'">
          <b>{{ items.fieldInfo.label }}</b> {{dialogData.data.policyDetailGridRow.PolicyEffectiveDate}}
       </div>  
       <div *ngIf="items.fieldInfo.fieldName === 'PolicyExpirationDate'">
        <b>{{ items.fieldInfo.label }}</b> {{dialogData.data.policyDetailGridRow.PolicyExpirationDate}}
       </div> 
       <div *ngIf="items.fieldInfo.fieldName === 'TotalPremium'">
        <b>{{ items.fieldInfo.label }}</b> ${{dialogData.data.policyDetailGridRow.TotalPremium}}
       </div>        
        </div>
        <div>          
      </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="button-row pb-0">
            <button
              type="button"
              id="cancel-btn"
              class="btn btn-secondary"
              (click)="closeModel()"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              type="button"
              id="save-btn"
              class="btn btn-primary"
              (click)="onDownloadLinkClick(dialogData.data.policyDetailGridRow)"
              data-dismiss="modal"
              [disabled]="dialogData.data.policyDetailGridRow.TransactionType === 'CNL' ? true : false"
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  
