export class RatingResponse{
    QuoteId:string;
    QuoteNumber: string;
    FinalPremiums:FinalPremiums;
    Premiums:Premiums;
}
export class FinalPremiums {
    OneYear: number;
    TwoYear: number;
    ThreeYear: number;   
  }
  export class Premiums{
    Base: number;
    MedEvac: number;
    ModBase: number;  
    ModLimit: number;
    WPV: number;  
  }