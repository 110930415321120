import { Moment } from "@tmhcc/framwork/services";

export class ClaimGridRow {
  claimId: number;
  claimNum: string;
  policyNum: string;
  lossDate: Moment;
  claimStatus: string;
  claimHandler: string;
  insuredName: string;
  claimTitle: string;

  //   constructor(){}
}
