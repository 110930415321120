import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnrViewSearchComponent } from './knr-view-search.component';
import { GridModule } from "@tmhcc/framwork/grid";
import { NgxsModule } from "@ngxs/store";
import { KnrPolicyQuoteService } from "libs/data-service/kidnap-ransom/knr-policy-quote.service";
import { QuotePolicyStepState } from "../../quote/store/state/quote-policy-step.state";
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { Routes, RouterModule } from "@angular/router";
@NgModule({
  declarations: [KnrViewSearchComponent],
  imports: [
    CommonModule,   
    GridModule,
    NgxsModule,   
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [KnrPolicyQuoteService],
  exports: [KnrViewSearchComponent],
})
export class KnrViewSearchModule { }
