import {
  Component,
  Type,
  ComponentFactoryResolver,
  ViewChild,
  OnDestroy,
  ComponentRef,
  AfterViewInit,
  ChangeDetectorRef
} from "@angular/core";
import { InsertionDirective } from "../insertion.directive";
import { Subject } from "rxjs";
import { DialogRef } from "../dialog-ref";
import { DialogConfig } from "../dialog-config";

@Component({
  selector: "app-dialog",
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.css"]
})
export class DialogComponent implements AfterViewInit, OnDestroy {
  componentRef: ComponentRef<any>;
  modalSetting: any;

  @ViewChild(InsertionDirective, { static: false })
  insertionPoint: InsertionDirective;

  private readonly _onClose = new Subject<any>();

  public onClose = this._onClose.asObservable();

  childComponentType: Type<any>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectRef: ChangeDetectorRef,
    private dialogRef: DialogRef,
    private dialogSettings: DialogConfig
  ) {
    this.modalSetting = this.dialogSettings.modalSetting;
    document.body.classList.add("overflow-hidden");
  }

  ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);
    this.changeDetectRef.detectChanges();
  }

  onOverlayClicked(evt: MouseEvent) {
    this.dialogRef.close();
  }

  onDialogClicked(evt: MouseEvent) {
    evt.stopPropagation();
  }

  loadChildComponent(componentType: Type<any>) {
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      componentType
    );

    let viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);

    //this.dialogSettings = this.componentRef.instance.dialogData;
  }

  ngOnDestroy() {
    if (this.componentRef) {
      document.body.classList.remove("overflow-hidden");
      this.componentRef.destroy();
    }
  }

  close() {
    document.body.classList.remove("overflow-hidden");
    this._onClose.next();
  }
}
