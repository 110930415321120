export class BreadcrumbProgressControl {
  titles: string[];
  progressIndex: number;
  clickedIndex: number;
  isDisabled?: boolean[];
  constructor() {
    this.progressIndex = 0;
    this.clickedIndex = 0;
  }
}
