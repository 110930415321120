import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { BaseDataService } from "./base-data-service";
import { AppConfigService } from "@tmhcc/global-services";
import { DateService } from "@tmhcc/framwork/services";
import { CommonContentService } from "@tmhcc/content-service";
import {
  DashboardSearchRequest,
  DashboardSearchResponse
} from "./def/quote-policy-search";
import { quotesData, policyData, DashboardGrid } from "@tmhcc/models";
import { map, catchError } from "rxjs/operators";

@Injectable()
export class QuotePolicySearchService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    private dateService: DateService,
    private commonContentService: CommonContentService
  ) {
    super(http, configService);
  }

  getDashboardData(payload: DashboardSearchRequest): Observable<DashboardGrid> {
    const url = `${this.serviceBaseUrl}/GetDashBoardGridData`;
    return this.post<DashboardSearchResponse>(url, { body: payload }).pipe(
      map(data => this.mapToQuoteGridRow(data)),
      catchError(this.handleError)
    );
  }

  private mapToQuoteGridRow(data: DashboardSearchResponse): DashboardGrid {
    let dashboardGrid = new DashboardGrid();
    if ((!data || !data.quotesData) && (!data || !data.policyData)) return;
    if (data.quotesData) {
      let quoteGridRows: quotesData[] = [];
      for (let index = 0; index < data.quotesData.length; index++) {
        const element = data.quotesData[index];

        let row = new quotesData();
        row.QuoteNumber = element.QuoteNumber;
        row.InsuredName = element.InsuredName;
        row.BrokerName = element.BrokerName;
        row.Underwriter = element.Underwriter;
        row.Status = element.Status;
        row.GrossPremium = element.GrossPremium;
        row.PolicyTxnID = element.PolicyTxnID;

        if (!this.dateService.isValidDate(element.CreatedDate)) {
          throw new Error(this.commonContentService.getErrors().invalidDate);
        }
        let CreatedDate = this.dateService.momentFromLongISO(
          element.CreatedDate
        );
        row.CreatedDate = this.dateService.toShortDate(CreatedDate);

        if (!this.dateService.isValidDate(element.ExpirationDate)) {
          throw new Error(this.commonContentService.getErrors().invalidDate);
        }
        if (!this.dateService.isValidDate(element.CreatedDate)) {
          throw new Error(this.commonContentService.getErrors().invalidDate);
        }
        let ExpirationDate = this.dateService.momentFromLongISO(
          element.ExpirationDate
        );
        row.ExpirationDate = this.dateService.toShortDate(ExpirationDate);

        quoteGridRows.push(row);
      }
      dashboardGrid.quotesData = quoteGridRows;
    }
    if (data.policyData) {
      let policyGridRows: policyData[] = [];

      for (let index = 0; index < data.policyData.length; index++) {
        const element = data.policyData[index];

        let row = new policyData();
        row.PolicyNumber = element.PolicyNumber;
        row.InsuredName = element.InsuredName;
        row.BrokerName = element.BrokerName;
        row.Underwriter = element.Underwriter;
        row.GrossPremium = element.GrossPremium;
        row.PolicyTxnID = element.PolicyTxnID;
        if (!this.dateService.isValidDate(element.CreatedDate)) {
          throw new Error(this.commonContentService.getErrors().invalidDate);
        }
        let CreatedDate = this.dateService.momentFromLongISO(
          element.CreatedDate
        );
        row.CreatedDate = this.dateService.toShortDate(CreatedDate);

        if (!this.dateService.isValidDate(element.ExpirationDate)) {
          throw new Error(this.commonContentService.getErrors().invalidDate);
        }
        let ExpirationDate = this.dateService.momentFromLongISO(
          element.ExpirationDate
        );
        row.ExpirationDate = this.dateService.toShortDate(ExpirationDate);

        policyGridRows.push(row);
      }
      dashboardGrid.policyData = policyGridRows;
    }
    return dashboardGrid;
  }
}
