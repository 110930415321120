export enum AllowedRoute {
  GeneralInfo = "/info",
  Events = "/events",
  Quote = "/quote",
  Bind = "/bind",
  Dashboard = "/dashboard",
  Subjectivity = "/subjectivity",
  Document = "/document",
  Forms = "/policy-forms",
  Summary = "/bind-summary"
}
