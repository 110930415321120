import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { EventsComponent } from "./events.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CoreInputsModule } from "@tmhcc/framwork/core-inputs";
import { DialogModule, DialogConfig } from "@tmhcc/framwork/dialog";
import { EventDialogComponent } from "./event-dialog/event-dialog.component";
import { QuoteStoreService } from "../store/facade/quote-store.service";
import { GridModule } from "@tmhcc/framwork/grid";
import { SpreadsheetComponent } from "./spreadsheet/spreadsheet.component";
import { UnsavedDataGaurd } from "./unsaved-data.guard";
import { PageHeaderModule } from "../../shared/page-header/page-header.module";
import { QuotePolicyInfoModule } from "../../shared/quote-policy-info/quote-policy-info.module";

import { NgxsModule } from "@ngxs/store";
import { QuoteCreateState } from "../store/state/quote-create.state";
import {
  QuoteCreateBussinessService,
  SaveEventService,
  QuoteStepService,
  IssueNewBusinessService
} from "@tmhcc/data-service";
import { QuoteState } from "../store/state/quote.state";
import { QuotePolicyStepState } from "../store/state/quote-policy-step.state";
import { AlertModule } from "@tmhcc/framwork/alert";
import {
  CustomPopupAlertService,
  CustomPopupAlertModule
} from "@tmhcc/framwork/custom-popup-alert/src";
import { LoaderService } from "@tmhcc/framwork/loader/src";
import { ValidationDialogComponent } from "./validation-dialog/validation-dialog.component";
import { PageTabInfoModule } from "../../shared/page-tab/page-tab-info.module";

const routes: Routes = [
  { path: "", component: EventsComponent, canDeactivate: [UnsavedDataGaurd] }
];

@NgModule({
  declarations: [EventsComponent, EventDialogComponent, SpreadsheetComponent],
  imports: [
    RouterModule.forChild(routes),
    [NgxsModule.forFeature([QuoteState, QuotePolicyStepState])],
    ReactiveFormsModule,
    CoreInputsModule,
    CommonModule,
    DialogModule,
    GridModule,
    PageHeaderModule,
    QuotePolicyInfoModule,
    PageTabInfoModule
  ],
  providers: [
    DialogConfig,
    QuoteStoreService,
    UnsavedDataGaurd,
    QuoteCreateBussinessService,
    SaveEventService,
    QuoteStepService,
    IssueNewBusinessService
  ],
  entryComponents: [EventDialogComponent, ValidationDialogComponent],
  exports: [RouterModule]
})
export class EventsModule {}
