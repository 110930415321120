import { Directive, ElementRef } from "@angular/core";

@Directive({
  selector: "[acPageTabItem]"
})
export class PageTabItemDirective {
  constructor(private el: ElementRef) {}

  focus() {
    this.el.nativeElement.focus();
  }
}
