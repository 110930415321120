import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import {
  DialogService,
  DialogConfig,
  DialogSize
} from "@tmhcc/framwork/dialog";
import { EventDialogComponent } from "./event-dialog/event-dialog.component";
import { QuoteStore } from "../store/state/quote.state";
import { EventDialogConfigData } from "./event-dialog/event-dialog-field.config";
import { QuoteStoreService } from "../store/facade/quote-store.service";
import {
  EventGridRow,
  PolicyTxnData,
  EcoMaster,
  EventDeductibleType
} from "@tmhcc/models";
import { DateService, DateFormats } from "@tmhcc/framwork/services";
import { ColumnDefinition, Types, GridOptions } from "@tmhcc/framwork/grid";
import { EventConfig, RiskModel, RiskResponseType } from "./event.config";
import { Router } from "@angular/router";
import { CommonContentService, Labels } from "@tmhcc/content-service";
import {
  SaveEventRequest,
  EcoMasterData,
  UpdateEventDetails,
  Risk,
  CreateActivityLogItem,
  RiskDataElements,
  ElementUpdateRequest,
  LOBRisks,
  ElementUpdateRequests,
  CopyQuoteService,
  CopyQuoteRequest
} from "@tmhcc/data-service";
import { QuoteCreateStore } from "../store/state/quote-create.state";
import { QuoteStepStoreService } from "../store/facade/quote-step-store.service";
import {
  QuotePolicyStepStore,
  QuotePolicyStepState
} from "../store/state/quote-policy-step.state";
import { LoaderService } from "@tmhcc/framwork/loader/src";
import { DialogContainerComponent, AlertContainerComponent } from "@tmhcc/global-services";
import { PolicyTxnRequest, UWQuotes } from "@tmhcc/data-service";
import * as _ from "lodash";
import { OktaAuthService } from "@okta/okta-angular";
import { CustomPopupAlertService } from "@tmhcc/framwork/custom-popup-alert/src";
import {
  UserConfigurableDataElements,
  DataElementRespons,
  WritingCompaniesByStates
} from "@tmhcc/data-service/def/eco-master";
import {
  FieldInfo,
  FieldTypes,
  InputConfig
} from "@tmhcc/framwork/form-inputs";
import { Subscription } from "rxjs";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngxs/store";
import {
  SaveEvents,
  DeleteAllAddedEvents,
  AddEvents
} from "../store/state/quote.actions";
import { map, take, pairwise } from "rxjs/operators";
import { QuotePolicyTxn } from "../store/state/quote-policy-step.action";
import { ValidationDialogComponent } from "./validation-dialog/validation-dialog.component";
import { ecaConstant } from "../../shared/eco-constant";
import { CoreInputValidationService } from "@tmhcc/framwork/core-inputs/src";

export interface EventGridColumns {
  index: number;
  id: string;
  eventType?: string;
  eventDescription?: string;
  eventVenue?: string;
  startDate?: string;
  endDate?: string;
  limitOfInsurance?: string;
  address?: string;
  city?: string;
  country?: string;
  baseRate?: number;
  checked?: boolean;
  eventDeductible?: number;
  eventDeductibleType?: string;
  revenue:number;
  expenses:number;
  insPersSpkr1:string;
  insPersSpkr2:string;
  insPersSpkr3:string;
  insPersSpkr4:string;
  insPersSpkr5:string;
}

@Component({
  selector: "ec-events",
  templateUrl: "./events.component.html",
  styleUrls: ["./events.component.scss"]
})
export class EventsComponent extends EventConfig implements OnInit, OnDestroy {
  quoteStore: QuoteStore;
  eventColumnSettings: ColumnDefinition[];
  eventRecords: EventGridColumns[];
  gridSetting: GridOptions;
  showTable: boolean;
  selectedRecords: EventGridColumns[];
  invalidRecordList: number[];
  isSpreadsheetUploaded: boolean;
  currentTablePage: number;
  quoteVersionList: any[];
  getLabels: Labels;
  hasUnsavedData: boolean;
  quoteCreateStore: QuoteCreateStore;
  policyTxnStoreData: PolicyTxnData;
  policyMasterData: EcoMaster;
  eventsFromPolicy: EventGridRow[];
  hideOnDialogBox: boolean;
  userName;
  quotePolicyStepStore: QuotePolicyStepStore;
  valueSub = new Subscription();
  quoteVersionFrm: FormGroup;
  currentUWQuoteID: number;
  currentWritingCompanyId: number;
  policyTxnIdValue: string;
  recordValidationTriggered: boolean;
  baseURL: string;
  quoteStoreServiceInstance: Subscription;
  quoteStepStoreServiceInstance: Subscription;
  nextPageClicked: boolean;
  saveEventsServiceInstace: Subscription;
  eventDeductibleValueId: number;
  eventDeductiblePercentId: number;
  hasNoEvent: boolean;
  quotePolicyTxnServiceInstance: Subscription;
  isPolicyBind: boolean;
  deleteAllEventServiceInstance: Subscription;
  deleteAllEventsInstance2: Subscription;
  addEventsServiceInstance: Subscription;
  quotePolicyTxnServiceInstance2: Subscription;
  isFormValid: boolean = true;
  fieldSelectList: FieldInfo = {
    fieldName: "quoteVersion",
    label: "Select Quote",
    fieldType: FieldTypes.LookupList,
    showLabel: false,
    placeholder: "Select Quote",
    validation: {
      required: "Select Quote Version"
    }
  };
  selectConfig: InputConfig = {
    config: {
      selectConfig: {
        selectedOption: [],
        options: [],
        optionConfig: {
          displayKey: "Description",
          search: false
        },
        multiple: false
      }
    },
    fieldInfo: this.fieldSelectList
  };
  isQuoteVersion: boolean;
  prevQuoteVersion: any;
  canCopyQuote: boolean;

  constructor(
    public formbuilder: FormBuilder,
    private dialogService: DialogService,
    private dialogConfig: DialogConfig<EventDialogConfigData>,
    private quoteStoreService: QuoteStoreService,
    public dateService: DateService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private contentService: CommonContentService,
    private ecoMasterData: EcoMasterData,
    private loaderService: LoaderService,
    private quoteStepStoreService: QuoteStepStoreService,
    private oktaAuth: OktaAuthService,
    private copyQuoteService: CopyQuoteService,
    private store: Store,
    private validationService: CoreInputValidationService
  ) {
    super(dateService);
    this.getLabels = this.contentService.getLabels();
    this.showTable = false;
  }
  policyTxnRequest: PolicyTxnRequest = {
    PolicyTxnID: this.policyTxnIdValue
  };

  ngOnDestroy(): void {
    this.showTable = false;
    if (this.quoteStoreServiceInstance) {
      this.quoteStoreServiceInstance.unsubscribe();
    }
    if (this.quoteStepStoreServiceInstance) {
      this.quoteStepStoreServiceInstance.unsubscribe();
    }
    if (this.saveEventsServiceInstace) {
      this.saveEventsServiceInstace.unsubscribe();
    }
    if (this.quotePolicyTxnServiceInstance) {
      this.quotePolicyTxnServiceInstance.unsubscribe();
    }
    if (this.deleteAllEventServiceInstance) {
      this.deleteAllEventServiceInstance.unsubscribe();
    }
    if (this.deleteAllEventsInstance2) {
      this.deleteAllEventsInstance2.unsubscribe();
    }
    if (this.addEventsServiceInstance) {
      this.addEventsServiceInstance.unsubscribe();
    }
    if (this.quotePolicyTxnServiceInstance2) {
      this.quotePolicyTxnServiceInstance2.unsubscribe();
    }
  }

  async ngOnInit() {
    this.gripSetup();
    const quoteStepStoreData = this.quoteStepStoreService.data;
    if (
      quoteStepStoreData &&
      quoteStepStoreData.policyTxnData &&
      quoteStepStoreData.policyTxnData.PolicyTxnId &&
      quoteStepStoreData.policyTxnData.PolicyTypeId
    ) {
      if (
        quoteStepStoreData.policyTxnData.ProcessStatusCode == "*" ||
        quoteStepStoreData.policyTxnData.ProcessStatusCode == "I"
      ) {
        this.isPolicyBind = true;
      }
      this.policyTxnIdValue = quoteStepStoreData.policyTxnData.PolicyTxnId;
      this.userName = await this.oktaAuth.getUser();
      this.hasNoEvent = false;
      this.nextPageClicked = false;
      this.loaderService.show();
      this.recordValidationTriggered = false;
      this.hasUnsavedData = false;
      // this.quoteStoreService.deleteAllAddedEvents();
      this.eventRecords = [];
      this.selectedRecords = [];
      this.isSpreadsheetUploaded = false;
      this.initFormGroup();
      this.subscriptions();
      /**
       * in Below line we geting the base url
       * this.baseURL=window.location.protocol + "//" + window.location.host + "/"
       */
      this.baseURL = location.origin + "/";
      if (this.quoteVersionFrm) {
        
        this.prevQuoteVersion = this.quoteVersionFrm.get("quoteVersion").value;
        this.quoteVersionFrm.controls["quoteVersion"].valueChanges.subscribe(
          value => {
            if (value) {
              const UwQuoteId = value.UWQuoteId;
              if(this.prevQuoteVersion && this.prevQuoteVersion.Description !== value.Description) {
                this.loadQuoteVersionData(UwQuoteId,value);
              }
            }
          }
        );
        // this.quoteVersionFrm.controls["quoteVersion"].valueChanges.pipe(pairwise()).subscribe(
        //   ([prev, next]) => {
        //     if (next) {
        //       const UwQuoteId = next.UWQuoteId;
        //       this.loadQuoteVersionData(UwQuoteId,prev);
        //     }
        //   }
        // );
      }

      //this.quoteVersionFrm.get("quoteVersion").setValue({UWQuoteId:this.policyTxnStoreData.UWQuotes[0].UWQuoteId,Description:this.policyTxnStoreData.UWQuotes[0].QuoteDescription});
    } else {
      this.router.navigate(["/dashboard"]);
    }
    if(this.quoteVersionFrm){
      this.quoteVersionFrm.valueChanges.subscribe(value => {
        if (!this.quoteVersionFrm.invalid) {
          this.isFormValid = true;
        } else {
          this.isFormValid = false;
        }
      });
    }
  }
  initFormGroup() {
    const controls = {};
    controls[this.fieldSelectList.fieldName] = [
      this.selectConfig.config.selectConfig.selectedOption,
      Validators.required
    ];

    this.quoteVersionFrm = this.formbuilder.group(controls);
  }

  subscriptions() {
    this.quoteStoreServiceInstance = this.quoteStoreService.data$.subscribe(
      (data: QuoteStore) => {
        this.quoteStore = data;
        if (data.eventList && data.eventList.length === 0) {
          this.hasNoEvent = true;
        } else {
          this.hasNoEvent = false;
        }
        if (data && !this.nextPageClicked) {
          this.mapGridValues(data.eventList);
          this.cdr.detectChanges();
        }
      }
    );
    //this.quoteStepStoreService.setPolcyTxnData(this.policyTxnRequest);

    this.quoteStepStoreServiceInstance = this.quoteStepStoreService.data$.subscribe(
      (data: QuotePolicyStepStore) => {
        this.quotePolicyStepStore = data;
        this.hasUnsavedData = false;
        //this.loaderService.show();
        if (data && data.policyTxnData && !this.nextPageClicked) {
          //Policy Master ECO call
          this.policyTxnStoreData = data.policyTxnData;
          if (data.policyTxnData.PolicyTypeId) {
            this.loaderService.show();
            // setTimeout(() => {
            //   this.loaderService.hide();
            // }, 5000);
            this.policyMasterData = this.ecoMasterData.getMasterData();
            this.recordValidationTriggered = false;
            //Render Events if any
            if (
              this.quotePolicyStepStore.policyTxnData.ProcessStatusCode ==
                "*" ||
              this.quotePolicyStepStore.policyTxnData.ProcessStatusCode == "I"
            ) {
              this.isPolicyBind = true;
              const UWQuotesInstance = data.policyTxnData.UWQuotes;
              UWQuotesInstance.forEach(element => {
                if (element.QuoteStatus === "A") {
                  this.currentUWQuoteID = element.UWQuoteId;
                  sessionStorage.selectedUwQuoteId = this.currentUWQuoteID;
                }
              });
              this.setQuoteVersionList(UWQuotesInstance);
              if (this.quoteVersionList.length > 1) {
                this.isQuoteVersion = true;
              }
            } else {
              const UWQuotesInstance = data.policyTxnData.UWQuotes;
              if (UWQuotesInstance && UWQuotesInstance.length !== 0) {
                // UWQuotesInstance[UWQuotesInstance.length - 1].;
                this.updateUWQuoteProperties(
                  UWQuotesInstance[UWQuotesInstance.length - 1]
                );
                this.setQuoteVersionList(UWQuotesInstance);
                this.generateEventRecords(UWQuotesInstance);
                if (this.quoteVersionList.length > 1) {
                  this.isQuoteVersion = true;
                }
              }
            }
            const UWQuotesInstance = data.policyTxnData.UWQuotes;
            if (UWQuotesInstance && UWQuotesInstance.length !== 0) {
              // UWQuotesInstance[UWQuotesInstance.length - 1].;
              this.updateUWQuoteProperties(
                UWQuotesInstance[UWQuotesInstance.length - 1]
              );
              this.setQuoteVersionList(UWQuotesInstance);
              this.generateEventRecords(UWQuotesInstance);
              if (this.quoteVersionList.length > 1) {
                this.isQuoteVersion = true;
              }
            }
          }
        }
      }
    );
  }

  updateUWQuoteProperties(uWQuotesInstance: UWQuotes) {
    const selectedUwQuoteId = sessionStorage.selectedUwQuoteId;
    if (selectedUwQuoteId) {
      this.currentUWQuoteID = selectedUwQuoteId;
    } else {
      this.currentUWQuoteID = uWQuotesInstance.UWQuoteId;
      sessionStorage.selectedUwQuoteId = this.currentUWQuoteID;
    }
    this.currentWritingCompanyId = uWQuotesInstance.WritingCompanyId;
  }

  setQuoteVersionList(UWQuoteList) {
    if (UWQuoteList) {
      this.quoteVersionList = [];
      UWQuoteList.forEach(items => {
        this.quoteVersionList.push({
          UWQuoteId: items.UWQuoteId,
          Description: items.QuoteDescription
        });
        // this.quoteVersionFrm.get("quoteVersion").setValue({
        //   UWQuoteId: items.UWQuoteId,
        //   Description: items.QuoteDescription
        // });
      });
      this.onloadSetQuoteVersion();
      this.selectConfig.config.selectConfig.options = this.quoteVersionList;
    }
  }
  onloadSetQuoteVersion() {
    const SelectedUwQuoteId = sessionStorage.selectedUwQuoteId;
    if (SelectedUwQuoteId) {
      this.quoteVersionList.forEach(element => {
        if (element.UWQuoteId == SelectedUwQuoteId) {
          this.quoteVersionFrm.get("quoteVersion").setValue({
            UWQuoteId: element.UWQuoteId,
            Description: element.Description
          });
        }
      });
    } else {
      const lastUWQuote = this.quoteVersionList[
        this.quoteVersionList.length - 1
      ];
      this.quoteVersionFrm.get("quoteVersion").setValue({
        UWQuoteId: lastUWQuote.UWQuoteId,
        Description: lastUWQuote.Description
      });
    }
    if (this.quoteVersionList.length > 1) {
      this.isQuoteVersion = true;
    }
  }
  mapGridValues(eventList: EventGridRow[]) { 
    if (eventList && eventList.length !== 0) {
      this.eventRecords = [];
      this.cdr.detectChanges();
      this.showTable = false;
      eventList.forEach((value: EventGridRow, i) => {
        let startDt = "";
        let endDt = "";
        if (value.eventStartDate) {
          startDt = this.dateService.toShortDate(
            this.dateService.moment(value.eventStartDate.toISOString())
          );
        }
        if (value.eventEndDate) {
          endDt = this.dateService.toShortDate(
            this.dateService.moment(value.eventEndDate.toISOString())
          );
        }

        const eventGridColumns: EventGridColumns = {
          index: i,
          id: value.customID,
          eventType: value.eventType,
          eventDescription: value.eventDescription || "< Empty >",
          eventVenue: value.eventVenue,
          startDate: startDt,
          endDate: endDt,
          limitOfInsurance: value.eventLimitOfInsurance,
          address: value.address,
          city: value.city,
          country: value.country,
          baseRate: value.baseRate,
          checked: false,
          revenue:value.revenue,
          expenses:value.expenses,
          insPersSpkr1:value.insPersSpkr1,
          insPersSpkr2:value.insPersSpkr2,
          insPersSpkr3:value.insPersSpkr3,
          insPersSpkr4:value.insPersSpkr4,
          insPersSpkr5:value.insPersSpkr5
        
        };
        if (value.eventDeductible && Number(value.eventDeductible)) {
          eventGridColumns.eventDeductible = Number(value.eventDeductible);
          eventGridColumns.eventDeductibleType = value.eventDeductibleType;
        }
        this.cdr.detectChanges();
        this.eventRecords.push(eventGridColumns);
        if (this.isSpreadsheetUploaded && this.currentTablePage) {
          // let table = this.elementRef.nativeElement.querySelector('table').DataTable();
          // table.page(this.currentTablePage);
        }
        this.cdr.detectChanges();
        this.showTable = true;
      });
      /*
      this.eventRecords = this.limitOfInsuranceSort();
      console.log("this.eventRecords===========================",this.eventRecords);
      */
    } else {
      this.showTable = false;
    }
  }

  /**
   * Function  limitOfInsuranceSort is sorting the limitOfInsurance column into the grid Table
   */
  /*
  limitOfInsuranceSort() {
    return this.eventRecords.sort((a:any, b:any)=> a.limitOfInsurance - b.limitOfInsurance)
  }
  */
  gripSetup() {
    this.gridSetting = this.gridSettingConfig;
    this.eventColumnSettings = this.eventColumnSettingsConfig;
  }

  openNewEventDialog(
    defaultValue?: EventGridRow,
    isDialogForEdit: boolean = false
  ) {
    this.hideOnDialogBox = true;
    this.dialogConfig.modalSetting = this.dialogModalSettingConfig;

    this.dialogConfig.data = {
      eventGridRow: defaultValue ? defaultValue : null,
      isDialogForEdit: isDialogForEdit
    };

    const ref = this.dialogService.open(
      EventDialogComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {
      if (this.dialogConfig.data.isDialogForEdit) {
        this.validateEventList();
        if (this.recordValidationTriggered) {
          this.hasUnsavedData = true;
          this.validateEventList();
        }
      }
      if (this.dialogConfig.data.addedEditedEvents) {
        this.hasUnsavedData = true;
      }
      if (result !== undefined) {
      } else {
      }
      this.hideOnDialogBox = false;
    });
  }

  nextClicked() {
    if (this.isPolicyBind == true) {
      this.router.navigate(["/quote"]);
    } else {
      if (this.validateEventList()) {
        if (!this.isFormValid) {
          this.validationService.setFormSubmission(true);
        } else if (this.hasUnsavedData) {
          this.saveEvents(true);
        } else {
          this.router.navigate(["/quote"]);
        }
      } else {
        this.openEventValidationDialog(
          "Please validate the events to proceed.",
          this.dialogModalSettingConfigForValidate
        );
      }
    }
  }

  saveRefreshClicked() {
    if (this.isPolicyBind == true) {
      this.router.navigate(["/quote"]);
    } else {
      if (this.validateEventList()) {
        this.saveEvents();
      } else {
        this.openEventValidationDialog(
          "Please validate the events to proceed.",
          this.dialogModalSettingConfigForValidate
        );
      }
    }
  }

  validateEventList(): boolean {
    this.recordValidationTriggered = true;
    this.invalidRecordList = [];
    if (this.eventRecords) {
      this.eventRecords.forEach((value: EventGridColumns) =>
        this.validateSpreadsheetConfig(value, this.invalidRecordList)
      );
    }

    if (this.invalidRecordList.length === 0) {
      return true;
    } else {
      return false;
    }
  }

  onGridRowSelect(event: any) {
    this.selectedRecords = [];
    this.selectedRecords = [...event];
  }

  selectEventRecord(event: any) {
    // let table = this.elementRef.nativeElement.querySelector('table').DataTable();
    // this.currentTablePage = table.page();
    const result = this.quoteStoreService.getEventById(event.id);
    this.openNewEventDialog(result, true);
  }

  spreadsheetUploaded(event: boolean) {
    this.isSpreadsheetUploaded = event;
    this.hasUnsavedData = true;
    this.validateEventList();
  }

  backButtonPressed() {
    // this.quoteStoreService.deleteAllAddedEvents();
    this.router.navigate(["info"]);
  }

  openEventDeleteConfirmationDialog(text, config) {
    const content = {
      value: text
    };
    this.dialogConfig.modalSetting = config;
    this.dialogConfig.data = content;
    const ref = this.dialogService.open(
      DialogContainerComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {
      if (!result) {
        this.hasUnsavedData = true;
        this.removeSelectedEvents();
      } else {
        this.selectedRecords = [];
        this.quoteStore = this.quoteStoreService.data;
        if (this.quoteStoreService.data) {
          this.mapGridValues(this.quoteStoreService.data.eventList);
        }
      }
    });
  }

  openEventValidationDialog(text, config) {
    const content = {
      value: text
    };
    this.dialogConfig.modalSetting = config;
    this.dialogConfig.data = content;
    const ref = this.dialogService.open(
      ValidationDialogComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {
      // if (!result) {
      //   this.hasUnsavedData = true;
      //   this.removeSelectedEvents();
      // } else {
      //   this.selectedRecords = [];
      //   this.quoteStore = this.quoteStoreService.data;
      //   if (this.quoteStoreService.data) {
      //     this.mapGridValues(this.quoteStoreService.data.eventList);
      //   }
      // }
    });
  }

  copyQuote() {
    if(!this.canCopyQuote){
      this.copyQuoteAlert();
    }
    else if (this.hasUnsavedData) {
      this.checkedDirtyPage();
    } else {
      const copyQuoteRequest: CopyQuoteRequest = {
        PolicyTxnId: Number(this.policyTxnStoreData.PolicyTxnId),
        UWQuoteId: this.currentUWQuoteID
      };
      this.loaderService.show();
      this.copyQuoteService
        .createCopyQuote(copyQuoteRequest)
        .subscribe(data => {
          if (data && data.CopyQuoteResponselist) {
            this.loaderService.hide();
            const policyTxnId = data.PolicyTxnId;
            const UwQuoteId = data.CopyQuoteResponselist.UWQuoteId;
            const Description = data.CopyQuoteResponselist.QuoteDescription;
            this.quoteVersionList.push({
              UWQuoteId: UwQuoteId,
              Description: Description
            });
            const getPolicyTxnRequest: PolicyTxnRequest = {
              PolicyTxnID: this.policyTxnStoreData.PolicyTxnId.toString()
            };
            this.quotePolicyTxnServiceInstance = this.store
              .dispatch(new QuotePolicyTxn(getPolicyTxnRequest))
              .subscribe(() => {
                this.loaderService.hide();
                this.isQuoteVersion = true;
                this.currentUWQuoteID = UwQuoteId;
                sessionStorage.selectedUwQuoteId = UwQuoteId;
                this.quoteVersionFrm
                  .get("quoteVersion")
                  .setValue({ UWQuoteId: UwQuoteId, Description: Description });
                this.loadQuoteVersionData(UwQuoteId);
              });
          } else {
            this.loaderService.hide();
          }
        });
    }
  }
  checkedDirtyPage() {
    this.openDirtyDialogBOx();
  }
  copyQuoteAlert(){
    this.copyQuoteAlerDialog();
  }
  copyQuoteAlerDialog(){
    const content = {
      value: this.contentService.getLabels().copyQuoteAlertWarning
    };
    this.dialogConfig.data = content;
    this.dialogConfig.modalSetting = this.dialogCopyalertModalSetting;
    const ref = this.dialogService.open(
      AlertContainerComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {
      if (result) {
        return false;
      } 
    });
    return false;
  }
  checkedVersionDirtyPage(UwQuoteId) {
    this.openVersionDirtyDialogBOx(UwQuoteId);
  }
  openVersionDirtyDialogBOx(UwQuoteId) {
    const content = {
      value: this.contentService.getLabels().dirtyQuoteVersionChkMsg
    };
    this.dialogConfig.data = content;
    this.dialogConfig.modalSetting = this.dialogQuoteVersionModalSetting;
    const ref = this.dialogService.open(
      AlertContainerComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {
      if (result) {
        if(this.quoteVersionFrm){
          this.quoteVersionFrm.get("quoteVersion").setValue(this.prevQuoteVersion);
        }
        
      } 
    });
    return false;
  }
  openDirtyDialogBOx() {
    const content = {
      value: this.contentService.getLabels().dirtyFormMsg
    };
    this.dialogConfig.data = content;
    this.dialogConfig.modalSetting = this.dialogModalSettingConfigForDirtycheck;
    const ref = this.dialogService.open(
      DialogContainerComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {
      if (result) {
        return false;
      } else {
        this.hasUnsavedData = false;
        this.copyQuote();
      }
    });
    return false;
  }
  loadQuoteVersionData(UwQuoteId,value?:any) {
    if (this.hasUnsavedData) {
      this.checkedVersionDirtyPage(UwQuoteId);
    } 
    else{
      if(value){
        this.prevQuoteVersion = value;
      }
      this.loaderService.show();
      this.currentUWQuoteID = UwQuoteId;
      sessionStorage.selectedUwQuoteId = UwQuoteId;
      this.generateEventRecords(this.quotePolicyStepStore.policyTxnData.UWQuotes);
    }   
  }
  removeSelectedEvents() {
    const ids = [];
    this.selectedRecords.forEach((record: EventGridColumns) => {
      ids.push(record.id);
    });
    this.quoteStoreService.deleteEvents(ids);
    this.selectedRecords = [];
    if (this.invalidRecordList) {
      this.validateEventList();
    }
  }

  deleteSelected() {
    this.openEventDeleteConfirmationDialog(
      "Are you sure you want to delete selected events?",
      this.dialogModalSettingConfigForDelete
    );
  }

  saveEvents(routeToNext?: boolean) {
    this.loaderService.show();
    const saveEventRequest = this.prepareSaveEventRequest();
    if (routeToNext) {
      this.nextPageClicked = true;
    }
    this.saveEventsServiceInstace = this.store
      .dispatch(new SaveEvents(saveEventRequest))
      .subscribe(
        data => {
          if (
            routeToNext &&
            data.Quote &&
            data.Quote.saveStatus &&
            data.Quote.saveStatus.isSuccess
          ) {
            this.quoteStoreServiceInstance.unsubscribe();
            this.quoteStepStoreServiceInstance.unsubscribe();
            this.saveEventsServiceInstace.unsubscribe();
            if (data.PolicyTxnData.policyTxnData.PolicyTxnId) {
              const getPolicyTxnRequest: PolicyTxnRequest = {
                PolicyTxnID: data.PolicyTxnData.policyTxnData.PolicyTxnId.toString()
              };
              this.loaderService.show();
              this.quotePolicyTxnServiceInstance = this.store
                .dispatch(new QuotePolicyTxn(getPolicyTxnRequest))
                .subscribe(
                  () => {
                    this.loaderService.hide();
                    this.hasUnsavedData = false;
                    this.router.navigate(["/quote"]);
                  },
                  () => {
                    this.loaderService.hide();
                  }
                );
            }
          }
          if (
            !routeToNext &&
            data.Quote &&
            data.Quote.saveStatus &&
            data.Quote.saveStatus.isSuccess
          ) {
            this.loaderService.show();
            this.deleteAllEventServiceInstance = this.store
              .dispatch(new DeleteAllAddedEvents())
              .subscribe(() => {
                if (data.PolicyTxnData.policyTxnData.PolicyTxnId) {
                  const getPolicyTxnRequest: PolicyTxnRequest = {
                    PolicyTxnID: data.PolicyTxnData.policyTxnData.PolicyTxnId.toString()
                  };
                  this.loaderService.show();
                  this.store
                    .dispatch(new QuotePolicyTxn(getPolicyTxnRequest))
                    .pipe(take(1))
                    .subscribe(
                      () => {
                        this.hasUnsavedData = false;
                        this.loaderService.hide();
                      },
                      () => {
                        this.loaderService.hide();
                      }
                    );
                }
              });
          }
          this.nextPageClicked = false;
        },
        () => {
          this.loaderService.hide();
        }
      );
  }

  prepareSaveEventRequest(): SaveEventRequest {
    const updateEventDetails = this.getUpdateEventDetailsRequest();
    let saveEventRequest: SaveEventRequest;
    saveEventRequest = {
      PolicyTxnId: this.policyTxnStoreData.PolicyTxnId
        ? Number(this.policyTxnStoreData.PolicyTxnId)
        : null,
      UpdateEventDetails: updateEventDetails
    };
    return saveEventRequest;
  }

  getUpdateEventDetailsRequest(): UpdateEventDetails { 
    let updateEventDetails: UpdateEventDetails;
    const selectedWritingCompany: WritingCompaniesByStates = this.ecoMasterData
      .getMasterData()
      .WritingCompaniesByStates.find((value: WritingCompaniesByStates) => {
        return (
          value.StateId === this.policyTxnStoreData.StateId &&
          value.WritingCompanyCode &&
          value.WritingCompanyCode.trim() === "HCC"
        );
      });
    updateEventDetails = {
      PolicyTxnId: this.policyTxnStoreData.PolicyTxnId
        ? Number(this.policyTxnStoreData.PolicyTxnId)
        : null,
      UWQuoteId:
        this.policyTxnStoreData && this.currentUWQuoteID
          ? this.currentUWQuoteID
          : null,
      WritingCompanyId: selectedWritingCompany
        ? selectedWritingCompany.WritingCompanyId
        : null,
      QuoteDescription: "UW Quote Desc", // As per service team, pass any string
      Risks: this.getRisksRequest(),
      CreateActivityLogItem: this.getCreateActivityLogItemRequest()
    };
    return updateEventDetails;
  }

  getCreateActivityLogItemRequest(): CreateActivityLogItem {
    let createActivityLogItem: CreateActivityLogItem;
    if (this.policyTxnStoreData.CreateActivityLogItem) {
      createActivityLogItem = {
        PolicyTxnId: this.policyTxnStoreData.CreateActivityLogItem.PolicyTxnId,
        ActivityType: ecaConstant.step2,
        ActivityDate: this.dateService.now().toISOString(),
        Description: ecaConstant.step2,
        LoggedInUser: this.userName
      };
    }
    return createActivityLogItem;
  }

  getRisksRequest(): Risk[] {
    const risks: Risk[] = [];
    if (this.quoteStore.eventList && this.quoteStore.eventList.length !== 0) {
      this.quoteStore.eventList.forEach((eventGridRow: EventGridRow) => {
        const risk: Risk = {
          RiskTypeCode: "FCH", // As per service team
          StateId: this.policyTxnStoreData.StateId,
          RiskDataElements: this.getRiskDataElementsRequest(eventGridRow),
          CurrencyCode: this.policyTxnStoreData.CurrencyCode,
          RiskValueCurrencyCode: this.policyTxnStoreData.CurrencyCode,
          RiskDescription: "" // Need discussion
        };
        risks.push(risk);
      });
    }
    return risks;
  }

  getRiskDataElementsRequest(eventGridRow: EventGridRow): RiskDataElements {
    let riskDataElements: RiskDataElements;
    riskDataElements = {
      ElementUpdateRequests: this.getElementUpdateRequestsRequest(eventGridRow)
    };
    return riskDataElements;
  }

  getElementUpdateRequestsRequest(
    eventGridRow: EventGridRow
  ): ElementUpdateRequest[] { 
    const elementUpdateRequests: ElementUpdateRequest[] = [];
    const eventDeductibleType = eventGridRow.eventDeductibleType;
    for (const key in eventGridRow) {
      if (eventGridRow.hasOwnProperty(key)) {
        let responseType: string;
        let cmbData: any;
        const dataElement = this.policyMasterData.UserConfigurableDataElements.find(
          value => {
            if (RiskModel.hasOwnProperty(key)) {
              if (value.ElementCode === RiskModel[key].name) {
                cmbData = value.DataElementResponses;
                responseType = RiskModel[key].type;
                return true;
              }
            }
            return false;
          }
        );
        if (RiskModel[key]) {
          const responseTypeField = _.invert(RiskResponseType)[responseType];
          const elementUpdateReq: ElementUpdateRequest = this.prepareRiskElements(
            key,
            eventGridRow[key],
            dataElement,
            responseTypeField,
            cmbData,
            eventDeductibleType
          );
          elementUpdateRequests.push(elementUpdateReq);
        }
      }
    }
    return elementUpdateRequests;
  }

  prepareRiskElements(
    key: string,
    value: any,
    config: any,
    responseTypeField: any,
    cmbData: any,
    eventDeductibleType: string
  ): ElementUpdateRequest { 
    let elementUpdateReq: ElementUpdateRequest;
    if (RiskModel[key]) {
      elementUpdateReq = {
        DataGroupId: config.DataGroupId,
        ElementId: config.ElementId,
        ElementCode: RiskModel[key].name,
        ResponseType: responseTypeField
      };
      if (RiskModel[key].rowNum) {
        elementUpdateReq.RowNum = RiskModel[key].rowNum;
      }
      if (RiskModel[key].type === RiskResponseType.ChooseOneComboResponse) {
        const eventTypeCode = this.getEventTypeCodeFromValue(value);
        elementUpdateReq.SelectedDataElementResponseIds = [eventTypeCode];
      } else if (RiskModel[key].type === RiskResponseType.DateResponse) {
        let dateValueField = null;
        if (value) {
          dateValueField = this.dateService.toLongISO(
            this.dateService.moment(value.toISOString())
          );
        }
        elementUpdateReq.DateValue = dateValueField;
      } else if (RiskModel[key].type === RiskResponseType.DecimalResponse) {
        let decimalValueField = null;
        decimalValueField = value ? value / 100 : null;
        elementUpdateReq.DecimalValue = decimalValueField;
      } else if (RiskModel[key].type === RiskResponseType.FreeTextResponse) {
        if (RiskModel[key].isAP && !RiskModel[key].isAPLabel) {
          let freeTextValueField = null;
          freeTextValueField = Number(value / 100) + "";
          elementUpdateReq.FreeTextValue = freeTextValueField;
        } else {
          let freeTextValueField = null;
          freeTextValueField = value ? value : null;
          elementUpdateReq.FreeTextValue = freeTextValueField;
        }
      } else if (RiskModel[key].type === RiskResponseType.MoneyResponse) {
        let moneyValueField = null;
        moneyValueField = value ? Number(value) : null;
        elementUpdateReq.MoneyValue = moneyValueField;
      } else if (RiskModel[key].type === RiskResponseType.CombinationResponse) {
        let valId: number;
        let perId: number;
        if (cmbData && cmbData.length > 1) {
          if (cmbData[0].ResponseCode === "EventDedValue") {
            valId = cmbData[0].DataElementResponseId;
            perId = cmbData[1].DataElementResponseId;
          } else if (cmbData[0].ResponseCode === "EventDedPer") {
            valId = cmbData[1].DataElementResponseId;
            perId = cmbData[0].DataElementResponseId;
          }
        }
        if (eventDeductibleType === EventDeductibleType.VALUE) {
          elementUpdateReq.SelectedDataElementResponseIds = [
            valId ? valId : 28035
          ];
          elementUpdateReq.DecimalValue = value;
        } else if (eventDeductibleType === EventDeductibleType.PERCENTAGE) {
          elementUpdateReq.SelectedDataElementResponseIds = [
            perId ? perId : 28045
          ];
          elementUpdateReq.DecimalValue = value/100;
        } else if (!eventDeductibleType) {
          elementUpdateReq.SelectedDataElementResponseIds = [
            valId ? valId : 28035
          ];
          elementUpdateReq.DecimalValue = 0;
        }
      }
    }
    this.cleanEmptyProperties(elementUpdateReq);
    return elementUpdateReq;
  }

  generateEventRecords(uWQuotes: UWQuotes[]) {
    const UWQuoteId = this.currentUWQuoteID;
    const filteredUWQuote: UWQuotes = uWQuotes.find(
      (value: UWQuotes) => value.UWQuoteId == UWQuoteId
    );
    if (
      filteredUWQuote &&
      filteredUWQuote.LOBs &&
      filteredUWQuote.LOBs.length !== 0
    ) {
      const risks = filteredUWQuote.LOBs[0].Risks; // As per service team, always using LOBs' 1st element
      if (risks && risks.length !== 0) {
        this.getEventFromPolicy(risks);
      }
    }
    setTimeout(() => {
      this.loaderService.hide();
    }, 500);
  }

  getEventFromPolicy(risks: LOBRisks[]) {
    const dataElement = this.policyMasterData.UserConfigurableDataElements.find(
      value => {
        return value.ElementCode === "EventDed";
      }
    );
    if(dataElement && dataElement.DataElementResponses){
      const cmbData = dataElement.DataElementResponses;
      if (cmbData && cmbData.length > 1) {
        if (cmbData[0].ResponseCode === "EventDedValue") {
          this.eventDeductibleValueId = cmbData[0].DataElementResponseId;
          this.eventDeductiblePercentId = cmbData[1].DataElementResponseId;
        } else if (cmbData[0].ResponseCode === "EventDedPer") {
          this.eventDeductibleValueId = cmbData[1].DataElementResponseId;
          this.eventDeductiblePercentId = cmbData[0].DataElementResponseId;
        }
      }
    }
   
    this.eventsFromPolicy = [];
    risks.forEach((lOBRisk: LOBRisks) => {
      if (
        lOBRisk.RiskTypeCode !== "LOB" &&
        lOBRisk.RiskDataElements &&
        lOBRisk.RiskDataElements.ElementUpdateRequests
      ) {
        const event = new EventGridRow();
        this.canCopyQuote=true;
        lOBRisk.RiskDataElements.ElementUpdateRequests.forEach(
          (value: ElementUpdateRequests) => {
            if (
              value.ElementCode === "AP1" ||
              value.ElementCode === "AP2" ||
              value.ElementCode === "AP3"
            ) {
              if (value.RowNum === 1) {
                event.additionalPerilLabel1 = value.FreeTextValue;
              } else if (value.RowNum === 2) {
                event.additionalPerilLabel2 = value.FreeTextValue;
              } else if (value.RowNum === 3) {
                event.additionalPerilLabel3 = value.FreeTextValue;
              }
            }
            //---
            else if(value.ElementCode === "Revenue"){
               event.revenue = value.MoneyValue ? value.MoneyValue : 0;
            }
            else if(value.ElementCode === "Expenses"){
              event.expenses = value.MoneyValue ? value.MoneyValue : 0;
            }           
                         
            //--
            //==
            else if (value.ElementCode === "InsPersSpkr") {
              if (value.RowNum === 1) {
                event.insPersSpkr1 = value.FreeTextValue;                  
              } else if (value.RowNum === 2) {
                event.insPersSpkr2 = value.FreeTextValue;
              } else if (value.RowNum === 3) {
                event.insPersSpkr3 = value.FreeTextValue;
              }
              else if (value.RowNum === 4) {
                event.insPersSpkr4 = value.FreeTextValue;
              }
              else if (value.RowNum === 5) {
                event.insPersSpkr5 = value.FreeTextValue;
              }
            }
            //==
            else if (value.ElementCode === "RatingInput") {
              if (value.RowNum === 1) {
                event.additionalPerilValue1 = value.DecimalValue
                  ? (value.DecimalValue * 100).toFixed(3).toString()
                  : null;
              } else if (value.RowNum === 2) {
                event.additionalPerilValue2 = value.DecimalValue
                  ? (value.DecimalValue * 100).toFixed(3).toString()
                  : null;
              } else if (value.RowNum === 3) {
                event.additionalPerilValue3 = value.DecimalValue
                  ? (value.DecimalValue * 100).toFixed(3).toString()
                  : null;
              }
            } else {
              const riskObject = _.pickBy(
                RiskModel,
                riskVal => riskVal.name === value.ElementCode
              );
              const riskKey = _.keys(riskObject)[0];
              let riskValue: any;
              if (riskKey) {
                if (
                  riskObject[riskKey].type ===
                  RiskResponseType.ChooseOneComboResponse
                ) {
                  if (value.SelectedDataElementResponseIds.length !== 0) {
                    riskValue = this.getSelectDropValue(
                      riskKey,
                      value.SelectedDataElementResponseIds
                    );
                  }
                } else if (
                  riskObject[riskKey].type === RiskResponseType.DateResponse
                ) {
                  riskValue = value.DateValue
                    ? this.dateService.moment(value.DateValue)
                    : null;
                } else if (
                  riskObject[riskKey].type === RiskResponseType.DecimalResponse
                ) {
                  riskValue = value.DecimalValue
                    ? (value.DecimalValue * 100).toFixed(3)
                    : null;
                } else if (
                  riskObject[riskKey].type === RiskResponseType.FreeTextResponse
                ) {
                  riskValue = value.FreeTextValue;
                } else if (
                  riskObject[riskKey].type === RiskResponseType.MoneyResponse
                ) {
                  riskValue = value.MoneyValue;
                } else if (
                  riskObject[riskKey].type ===
                  RiskResponseType.CombinationResponse
                ) {
                  if (
                    value.SelectedDataElementResponseIds[0] ===
                    this.eventDeductiblePercentId
                  ) {
                    event.eventDeductibleType = EventDeductibleType.PERCENTAGE;
                    riskValue = value.DecimalValue
                    ? (value.DecimalValue * 100) + ""
                      : null;
                  } else if (
                    value.SelectedDataElementResponseIds[0] ===
                    this.eventDeductibleValueId
                  ) {
                    event.eventDeductibleType = EventDeductibleType.VALUE;
                    riskValue = value.DecimalValue
                      ? value.DecimalValue + ""
                      : null;
                  }
                }
                event[riskKey] = riskValue;
              }
            }
          }
        );
        this.cdr.detectChanges();
        this.eventsFromPolicy.push(event);
      }
    });
    this.deleteAllEventsInstance2 = this.store
      .dispatch(new DeleteAllAddedEvents())
      .subscribe(() => {
        this.addEventsServiceInstance = this.store
          .dispatch(new AddEvents(this.eventsFromPolicy))
          .subscribe();
      });
  }

  getEventTypeCodeFromValue(eventTypeValue: string) {
    if (this.policyMasterData.UserConfigurableDataElements) {
      const { DataElementResponses }: UserConfigurableDataElements =
        this.policyMasterData.UserConfigurableDataElements.find(
          (element: UserConfigurableDataElements) => {
            return element.ElementCode === "EventType";
          }
        ) || null;
      if (DataElementResponses && DataElementResponses.length !== 0) {
        const ResponseValue = DataElementResponses.find(
          (value: DataElementRespons) => {
            return value.ResponseValue === eventTypeValue;
          }
        );
        if (ResponseValue) {
          return ResponseValue.DataElementResponseId;
        }
      }
    }
  }

  getSelectDropValue(
    riskKey: string,
    SelectedDataElementResponseIds: number[]
  ): any {
    if (riskKey === "eventType") {
      if (this.policyMasterData.UserConfigurableDataElements) {
        const { DataElementResponses }: UserConfigurableDataElements =
          this.policyMasterData.UserConfigurableDataElements.find(
            (element: UserConfigurableDataElements) => {
              return element.ElementCode === "EventType";
            }
          ) || null;
        if (DataElementResponses) {
          const { ResponseValue } =
            DataElementResponses.find((type: DataElementRespons) => {
              return (
                type.DataElementResponseId === SelectedDataElementResponseIds[0]
              );
            }) || null;
          return ResponseValue;
        }
      }
    }
  }

  cleanEmptyProperties(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }
}
