import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnrRatingComponent } from './knr-rating.component';
import { GridModule } from "@tmhcc/framwork/grid";
import { NgxsModule } from "@ngxs/store";
import { KnrPolicyQuoteService } from "libs/data-service/kidnap-ransom/knr-policy-quote.service";
import { QuotePolicyStepState } from "../../quote/store/state/quote-policy-step.state";
import { ReactiveFormsModule,FormsModule,FormGroupDirective } from '@angular/forms';
import { InputTextModule } from "@tmhcc/framwork/form-inputs";
import { Routes, RouterModule } from "@angular/router";
import { DialogModule, DialogConfig } from '@tmhcc/framwork/dialog';
import { OktaAuthGuard } from '@okta/okta-angular';
import { CoreInputsModule } from "@tmhcc/framwork/core-inputs";

const routes: Routes = [
    {
      path: "account-setup/rating",
      component: KnrRatingComponent   
    }
  ];
  
  @NgModule({
    declarations: [KnrRatingComponent],
    imports: [
      CommonModule,    
      NgxsModule,   
      ReactiveFormsModule,
      FormsModule,
      RouterModule.forChild(routes),
      CoreInputsModule,
      InputTextModule    
    ],
    providers: [    
      DialogConfig,
      OktaAuthGuard,
      FormGroupDirective,
      KnrPolicyQuoteService,      
    ],
    exports: [RouterModule,KnrRatingComponent]
  })
  export class KnrRatingModule { }