import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PageTabItemComponent } from "./page-tab-item/page-tab-item.component";
import { PageTabItemPipe } from "./page-tab-item/page-tab-item.pipe";
import { PageTabItemDirective } from "./page-tab-item/page-tab-item.directive";
import { RouterModule } from "@angular/router";

@NgModule({
  imports: [CommonModule, RouterModule],
  declarations: [PageTabItemComponent, PageTabItemPipe, PageTabItemDirective],
  exports: [PageTabItemComponent]
})
export class PageTabModule {}
