import { Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Subscription } from "rxjs/internal/Subscription";
import { CoreInput } from "./core-input.def";
import { ValueAccessorBase, FieldInfo } from "@tmhcc/framwork/form-inputs";

export class InputBase<T> extends ValueAccessorBase<T> implements CoreInput {
  @Input() fieldInfo: FieldInfo;
  @Input() frmGroup: FormGroup;
  @Input() control: string;
  @Input() InputConfig?: any;

  valuesSub: Subscription = new Subscription();

  cleanUp() {
    if (this.valuesSub) {
      this.valuesSub.unsubscribe();
    }
  }

  listenToValueChanges(fieldName: string, cb: () => void) {
    this.valuesSub.add(
      this.frmGroup.controls[fieldName].valueChanges.subscribe(() => {
        // console.log('value changed');

        cb();
      })
    );
  }

  constructor() {
    super();
  }

  detectChanges() {}
}
