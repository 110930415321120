import { Injectable } from "@angular/core";
import { Select } from "@ngxs/store";
import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { Observable } from "rxjs";
import { DasboardData, SetSearchType,DashboardDataDwld } from "../state/dashbard.actions";
import { DashboardStateDwld,DashboardStoreDwld } from "../state/dashbard.state";
import { DashboardSearchRequest,DwldPolicySearchRequest } from "@tmhcc/data-service";
import { SearchType } from "@tmhcc/models";

@Injectable({ providedIn: "root" })
export class DashoardPolicyDownloadService {
  @Select(DashboardStateDwld)
  public datad$: Observable<DashboardStoreDwld>;

  constructor() {}

  @Dispatch()
  public getDwldPolicyData(searchType:any,dashboardSearchRequestDwld: DwldPolicySearchRequest) {
    return new DashboardDataDwld(searchType,dashboardSearchRequestDwld);
  }

  // @Dispatch()
  // public setSearchType(searchType: SearchType) {
  //   return new SetSearchType(searchType);
  // }
}
