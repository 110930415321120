import { Component, OnInit, OnDestroy } from "@angular/core";
import { DialogConfig, DialogRef, DialogSize } from "@tmhcc/framwork/dialog";
import { FormGroup, FormBuilder } from "@angular/forms";
import { SubjectivityGridRow, SubjectivityGrid } from "@tmhcc/models";
import { LoaderService } from "@tmhcc/framwork/loader/src";
import {
  KnRSubjectivityDialogFieldConfig,
  KnRSubjectivityDialogConfigData
} from "./subjectivity-dialog.config";
import { CoreInputValidationService } from "@tmhcc/framwork/core-inputs";
import {
  EcoMasterData,
  SubjectivityService, 
  GetPolicySubjectivitiesRequest
} from "@tmhcc/data-service";
import { CommonContentService } from "@tmhcc/content-service";
import { Router } from "@angular/router";
import {KnrPolicyQuoteService} from "libs/data-service/kidnap-ransom/knr-policy-quote.service";
import {KnRSaveSubjectiviyRequest,KnRSubjectivityRequest} from "libs/data-service/def/knrsubjectivity";
@Component({
  selector: "knr-subjectivity-dialog",
  templateUrl: "./subjectivity-dialog.component.html",
  styleUrls: ["./subjectivity-dialog.component.scss"]
})
export class KnRSubjectivityDialogComponent extends KnRSubjectivityDialogFieldConfig
  implements OnInit, OnDestroy {
  constructor(
    private loaderService: LoaderService,    
    public dialogData: DialogConfig<KnRSubjectivityDialogConfigData>,
    private dialogRef: DialogRef,
    private validationService: CoreInputValidationService,
    public contentService: CommonContentService,   
    private knrPolicyQuoteService:KnrPolicyQuoteService,
    private router: Router
  ) {
    super(dialogData, contentService);
  }
  frmGroup: FormGroup;
  public selectBoxData: any;
  public lengths: number;
  public subjectivityList: SubjectivityGridRow[] = [];
  policyTxnIdValue: string;
  isDeleteDialog: boolean;
  selectedRecord: SubjectivityGridRow;
  inputPlaceholder: string;
  isChanged: boolean;
  formSkipCheck: boolean;
  title: string;  
  ngOnInit(): void {
    this.formSkipCheck = false;
    this.title = "ADD/EDIT SUBJECTIVITIES";
    this.loaderService.show();    
    this.getSubjectivityList();
    this.isDeleteDialog = false;
    this.knrPolicyQuoteService.getSubjectivityConfigData().subscribe(data => {      
      this.dialogData.data = {
        ...this.dialogData.data,
        subjList: this.getSubjectivityData(data)
      };
      this.loaderService.hide();
     });    
  } 

  getSubjectivityList() {
    this.subjectivityList = [];
    this.loaderService.show();   
    if (sessionStorage.getItem('_quoteId') !== undefined ||
      sessionStorage.getItem('_quoteId') !== null) {
      let payload: any;
      payload = sessionStorage.getItem('_quoteId');
      this.loaderService.show();    
      this.knrPolicyQuoteService.getSubjectivity(payload).subscribe(data => {       
        if (data && data.subjectivityData) {
          this.subjectivityList = data.subjectivityData;
        }
      });
      this.loaderService.hide();
    }
  }

  public saveCode(e, record): void {
    this.isChanged = true;
    let newRow: SubjectivityGridRow;
    const name = e.target.value;
    if (record) {
      newRow = {
        policySubjectivityId: Number(record.policySubjectivityId),
        checked: record.checked,
        description: name,
        isBlank: false
      };
    } else {
      newRow = {
        policySubjectivityId: 0,
        checked: false,
        description: name,
        isBlank: false
      };
    }
  }

  onSaved() {
    if (this.validateList()) {
      this.loaderService.show();
      const request = this.prepareSaveRequest();
      return this.knrPolicyQuoteService
        .saveSubjectivity(request)
        .subscribe(data => {         
          if (data) {
            this.loaderService.hide();
            this.dialogRef.close(true);
          } else {
          }
        });
    }
  }

  validateList(): boolean {
    this.subjectivityList.filter(value => {
      if (value.description === "" || value.description === "") {
        value.isBlank = true;
      } else {
        value.isBlank = false;
      }
    });
    if (
      this.subjectivityList.filter(
        item => item.description === "" || item.description === ""
      ).length > 0
    ) {
      return false;
    }
    return true;
  }

  prepareSaveRequest(): KnRSaveSubjectiviyRequest {
    let saveRequest: KnRSaveSubjectiviyRequest;
    saveRequest = {
      QuoteID: sessionStorage.getItem('_quoteId'),
      Subjectivities: this.mapSubjectivityData()
    };
    return saveRequest;
  }

  mapSubjectivityData() {
    let list: KnRSubjectivityRequest[] = [];
    if (!this.subjectivityList) return list;
    if (this.subjectivityList) {
      for (let index = 0; index < this.subjectivityList.length; index++) {
        const element = this.subjectivityList[index];

        let record: KnRSubjectivityRequest;
        record = {
          Description: element.description,
          IsResolved: element.checked,
          Id: element.policySubjectivityId          
        };

        list.push(record);
      }
    }
    return list;
  }

  deleteEventCancel() {
    this.dialogData.modalSetting.dialogSize = DialogSize.LG;
    this.isDeleteDialog = false;
  }

  deleteEventConfirmed() {
    this.dialogData.modalSetting.dialogSize = DialogSize.LG;
    this.isDeleteDialog = false;
    const index: number = this.subjectivityList.indexOf(this.selectedRecord);
    if (index !== -1) {
      this.subjectivityList.splice(index, 1);
    }
    if (
      this.subjectivityList.filter(
        item => item.description === "" || item.description === ""
      ).length > 0
    ) {
      this.isChanged = true;
    } else {
      this.isChanged = false;
    }
  }
  onDeleteClicked(record) {
    this.dialogData.modalSetting.dialogSize = DialogSize.LG;
    this.isDeleteDialog = true;
    this.selectedRecord = record;
  }
  addNewSubjectivity() {   
    this.isChanged = true;
    let record: SubjectivityGridRow;
    record = {
      policySubjectivityId: 0,
      checked: false,
      description: "",
      isBlank: false
    };
    this.subjectivityList.push(record);
  }

  onClose() {
    this.dialogRef.close(false);
  }

  onDialogCancel() {
    if (this.isChanged) {
      this.dialogData.modalSetting.dialogSize = DialogSize.LG;
      this.formSkipCheck = true;
    } else {
      document.body.classList.remove("overflow-hidden");
      this.dialogRef.close(false);
    }
  }

  onDialogSave() {}

  onControlValueChange(feildName: string, val: string) {
    this.frmGroup.get(feildName).setValue(val);
  }

  ngOnDestroy(): void {
    this.validationService.setFormSubmission(false);
  }

  popupSkipCancel() {
    this.dialogData.modalSetting.dialogSize = DialogSize.LG;
    this.formSkipCheck = false;
  }

  popupSkipConfirm() {
    this.dialogData.modalSetting.dialogSize = DialogSize.LG;
    document.body.classList.remove("overflow-hidden");
    this.dialogRef.close(false);
  }
}
