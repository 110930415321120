import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SaveChangeGaurd } from "@tmhcc/global-services";
import { AllowedRoute } from "../page-header/page-header-navigation/page-header-navigation-def";
import { PageHeaderService } from "../page-header/page-header.service";

interface PageTabItem {
  label: string;
  id: number;
  url: string;
  step: number;
}

@Component({
  selector: "ec-page-tab",
  templateUrl: "./page-tab-info.component.html",
  styleUrls: ["./page-tab-info.component.scss"]
})
export class PagTabInfoComponent implements OnInit {
  constructor(
    private router: Router,
    private saveChangeGaurd: SaveChangeGaurd,
    private pageHeaderService: PageHeaderService
  ) {}
  selected: number;
  badgeCount: number;

  readonly routerItemsStep2: PageTabItem[] = [
    { label: "Event Details", id: 1, url: AllowedRoute.Events, step: 1 },
    { label: "Documents", id: 2, url: AllowedRoute.Document, step: 1 }
  ];

  readonly routerItemsStep3: PageTabItem[] = [
    { label: "Quote Details", id: 1, url: AllowedRoute.Quote, step: 2 },
    { label: "Subjectivities", id: 2, url: AllowedRoute.Subjectivity, step: 2 },
    { label: "Documents", id: 3, url: AllowedRoute.Document, step: 2 }
  ];

  readonly routerItemsStep4: PageTabItem[] = [
    { label: "Quote Details", id: 4, url: AllowedRoute.Bind, step: 3 },
    { label: "Subjectivities", id: 5, url: AllowedRoute.Subjectivity, step: 3 },
    { label: "Documents", id: 6, url: AllowedRoute.Document, step: 3 },
    { label: "Forms", id: 7, url: AllowedRoute.Forms, step: 3 },
    { label: "Summary", id: 8, url: AllowedRoute.Summary, step: 3 }
  ];

  ngOnInit() {
    this.pageHeaderService.getBadgeCount.subscribe(count => {
      this.badgeCount = count;
    });
    this.routerItemsStep4.forEach(element => {
      if (this.router.url == element.url) {
        this.selected = element.id;
      }
    });
  }

  tabClicked({ item }) {
    this.saveChangeGaurd.setRoute(item.url);
    this.pageHeaderService.setBadgeCounter(item.step);
    this.router.navigate([item.url]);
  }
}
