import { Component, ChangeDetectorRef, OnInit } from "@angular/core";
import { DialogService, DialogConfig } from "@tmhcc/framwork/dialog";
import {
  SubjectivityGridRow,
  SubjectivityGrid,
  PolicyTxnData,
  PolicyTxnRequest
} from "@tmhcc/models";
import { DateService } from "@tmhcc/framwork/services";
import { CommonContentService, Labels } from "@tmhcc/content-service";
import { KnRSubjectivityDialogConfigData } from "./subjectivity-dialog/subjectivity-dialog.config";
import { KnRSubjectivityConfig } from "./subjectivity.config";
import { KnRSubjectivityDialogComponent } from "./subjectivity-dialog/subjectivity-dialog.component";
import { GridOptions, ColumnDefinition } from "@tmhcc/framwork/grid/src";
import {
  SubjectivityService,
  GetPolicySubjectivitiesRequest,
  SaveSubjectiviyRequest,
  SubjectivityRequest,
  SaveMiscellaneousChangesRequest
} from "@tmhcc/data-service";
import { Router } from "@angular/router";
import { LoaderService } from "@tmhcc/framwork/loader/src";
//import { QuoteStepStoreService } from "../store/facade/quote-step-store.service";
//import { QuotePolicyStepStore } from "../store/state/quote-policy-step.state";
import { take } from "rxjs/operators";
import { AllowedRoute } from "../../shared/page-header/page-header-navigation/page-header-navigation-def";
import { PageHeaderService } from "../../shared/page-header/page-header.service";
import { KnrPolicyQuoteService } from "libs/data-service/kidnap-ransom/knr-policy-quote.service";
import { KnRSaveMiscellaneousChangesRequest,KnRSubjectivityRequest,KnRSaveSubjectiviyRequest} from "libs/data-service/def/knrsubjectivity";
import {
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  ControlContainer
} from '@angular/forms';
import {      
  KnRSubjectivityGridRow
} from "libs/models/classes/knr-subjectivities-data.model";
import { ToastrService } from 'ngx-toastr';
import { DialogContainerComponent, AlertContainerComponent } from "@tmhcc/global-services";
@Component({
  selector: "knr-subjectivity",
  templateUrl: "./subjectivity.component.html",
  styleUrls: ["./subjectivity.component.scss"],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective
    }
  ]
})
export class KnRSubjectivityComponent extends KnRSubjectivityConfig
  implements OnInit {
  constructor(
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    public dateService: DateService,
    private contentService: CommonContentService,
    private dialogConfig: DialogConfig<KnRSubjectivityDialogConfigData>,
    private subjectivityService: SubjectivityService,
    private router: Router,
    private pageHeaderService: PageHeaderService,
    //private quoteStepStoreService: QuoteStepStoreService,
    private loaderService: LoaderService,
    private knrPolicyQuoteService: KnrPolicyQuoteService,
    private parentform: FormGroupDirective,
    private alertservice: ToastrService,
  ) {
    super(contentService);
    this.getLabels = this.contentService.getLabels();
  }
  getLabels: Labels;
  hasUnsavedData: boolean;
  gridSetting: GridOptions;
  records: SubjectivityGridRow[];
  selectedRecords: SubjectivityGridRow[];
  columnSettings: ColumnDefinition[];
  policyTxnStoreData: PolicyTxnData;
  defaultList: SubjectivityGrid;
  showTable: boolean;
  policyTxnIdValue: string;
  isPolicyBound: boolean;
  disableAddEditSub: boolean;
  index: number;  

  ngOnInit(): void {
    var element = document.getElementById("bannerCaption");
    element.innerHTML = "Kidnap & Ransom"
   // this.loaderService.show();
    this.isPolicyBound = false;
    this.hasUnsavedData = false;
    this.showTable = false;
    this.records = [];
    this.selectedRecords = [];   
    this.getSubjectivityList();
    this.gridSetup();
    this.getProgressIndex();
  }

  getProgressIndex() {
    this.pageHeaderService.getBadgeCount.subscribe(badgeCount => {
      this.index = badgeCount;
    });
  }  

  getSubjectivityList() {
    if (sessionStorage.getItem('_quoteId') !== undefined ||
      sessionStorage.getItem('_quoteId') !== null) {
      let payload: any;
      payload = sessionStorage.getItem('_quoteId');
      this.loaderService.show();
      //this.payload.PolicyTxnId = Number(this.policyTxnIdValue);
      this.knrPolicyQuoteService.getSubjectivity(payload).subscribe(data => {
        if (data && data.subjectivityData) {
          this.defaultList = data;
          this.records = [];
          this.cdr.detectChanges();
          this.records = data.subjectivityData;
          this.loaderService.hide();
        }
        else {
          this.loaderService.hide();
        }
      });
    }
  }

  gridSetup() {
    this.gridSetting = this.gridSettingConfig;
    if (this.isPolicyBound) {
      this.columnSettings = this.columnSettingsEditableConfig;
    } else {
      this.columnSettings = this.columnSettingsReadOnlyConfig;
    }
  }

  onChecked(record: any) {   
    if (record.key === 'delete') {     
      this.deleteConfirmOptionForms(
        'Are you sure you want to delete selected subjectivities?',
        this.dialogModalSettingConfigForDelete,
        record
      );
    } else {
      record.checked = !record.checked;
      this.onSaveClicked();
    }    
  }
  deleteConfirmOptionForms(text, config, record) {
    let content = {
      value: text
    };

    //this.selectedRecords = deleteFormId;
    this.dialogConfig.modalSetting = config;
    this.dialogConfig.data = content;
    const ref = this.dialogService.open(
      DialogContainerComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {
      if (result) {
      } else {
        this.records.splice(this.getIndexFromArray(this.records, record.value), 1);
        //this.onSaveClicked();
        const request = this.prepareSubjectivitySaveRequest();
        this.saveMiscellaneous(request);
      }
    });
  }
  getIndexFromArray(arrayToCompare, objectToCompare) {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < arrayToCompare.length; i++) {
      if (this.compareObjects(arrayToCompare[i], objectToCompare)) {
        return i;
      }
    }
  }
   compareObjects(o1, o2) {
    return Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every(p => o1[p] === o2[p])
      ? true
      : false;
  }
  selectRecord(record: any) {}

  openNewSubjectivityDialog() {
    this.dialogConfig.modalSetting = this.dialogModalSettingConfig;
    this.dialogConfig.data = {
      subjectivity: this.defaultList ? this.defaultList : null
    };

    const ref = this.dialogService.open(
      KnRSubjectivityDialogComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {
      if (result) {
        this.getSubjectivityList();
      }
      console.log(result);
    });
  }

  onBackClicked() {    
      this.router.navigate(["/knr-dashboard/account-setup/rating/quote-info-coverages"]);    
  }

  onSaveSubjectivity(){
    if (this.isPolicyBound) {
      const request = this.prepareMiscellaneousSaveRequest();
      this.saveMiscellaneous(request);
    } else {
      const request = this.prepareSubjectivitySaveRequest();
      const routToNext=true;
      this.saveSubjectivity(request,routToNext);
    }
  }



  onSaveClicked() {
    const request = this.prepareMiscellaneousSaveRequest();
      this.saveMiscellaneous(request);  
  }
  saveMiscellaneous(request: KnRSaveMiscellaneousChangesRequest) {   
     this.loaderService.show();
    this.knrPolicyQuoteService
      .saveSubjectivity(request)
      .subscribe(data => {
      //  this.parentform.form.markAsPristine();
          this.alertservice.success('Subjectivities saved successfully.');
        this.loaderService.hide();
      });
  }
  prepareSubjectivitySaveRequest(): KnRSaveSubjectiviyRequest {
    let saveRequest: KnRSaveSubjectiviyRequest;
    saveRequest = {
      QuoteID: sessionStorage.getItem("_quoteId"),
      Subjectivities: this.prepareSubjectivityList()
    };
    return saveRequest;
  }
  prepareMiscellaneousSaveRequest(): KnRSaveMiscellaneousChangesRequest {
    let saveMiscellaneousRequest: KnRSaveMiscellaneousChangesRequest;
    saveMiscellaneousRequest = {
      QuoteID: sessionStorage.getItem("_quoteId"),
      Subjectivities: this.prepareSubjectivityList()
    };
    return saveMiscellaneousRequest;
  }

  prepareSubjectivityList(): KnRSubjectivityRequest[] {
    const subjectivityList: KnRSubjectivityRequest[] = [];
    if (this.records && this.records.length !== 0) {
      this.records.forEach((gridRow: KnRSubjectivityGridRow) => {
        let subjectivity: KnRSubjectivityRequest;
        subjectivity = {
          IsResolved: gridRow.checked,
          Id: gridRow.policySubjectivityId,
          Description: gridRow.description          
        };
        subjectivityList.push(subjectivity);
      });
    }
    return subjectivityList;
  }

  saveSubjectivity(request,routToNext) {
    this.loaderService.show();
    this.subjectivityService.saveSubjectivity(request).subscribe(data => {
      if (data) {
        //   this.records = data.subjectivityData;
        this.loaderService.hide();
        if (this.index === 2 && routToNext) {
          this.router.navigate([AllowedRoute.Quote]);
        } else if (this.index === 3 && routToNext) {
          this.router.navigate([AllowedRoute.Summary]);
        }
      }
      this.loaderService.hide();
    });
  } 
}
