import { State, Action, StateContext, Store } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";

import {KnrPolicyQuoteService} from "@tmhcc/data-service"
import {  
  KnrPolicySearchResult,
  KnrPolicySearchResponse
} from "@tmhcc/models";
import { DasboardData, SetSearchType,KnrDashboardData } from "./knr-dashbard.actions";

export interface KnrDashboardStore {  
  knrPolicyDataList: KnrPolicySearchResult[];  
}

@State<KnrDashboardStore>({
  name: "KnrDashboard",
  defaults: {
    knrPolicyDataList: []
  }
})

@Injectable()
export class KnrDashboardState {
  constructor(
    private knrPolicyQuoteService: KnrPolicyQuoteService,
    private store: Store
  ) {}

  @Action(KnrDashboardData)
  getKnrDashboardData(
    ctx: StateContext<KnrDashboardStore>,
    { knrdashboardSearchRequest } : KnrDashboardData
  ) {
    return this.knrPolicyQuoteService
      .getKNRQuotePolicyList(knrdashboardSearchRequest)
      .pipe(
        tap((data: KnrPolicySearchResponse) => {
          const state = ctx.getState();
          ctx.setState({
            ...state,         
            knrPolicyDataList: [...data.policySearchResults]
          });
        })
      );
  }
}

