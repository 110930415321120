import { State, Action, StateContext, Store } from "@ngxs/store";
import { patch } from "@ngxs/store/operators";
import { Injectable } from "@angular/core";
import { CreateNewQuoteModel } from "@tmhcc/models";
import { CreateNewQuote } from "./quote-create.actions";
import { QuoteCreateBussinessService } from "@tmhcc/data-service";
import { tap } from "rxjs/operators";

export interface QuoteCreateStore {
  quoteInfoData: CreateNewQuoteModel[];
}

@State<QuoteCreateStore>({
  name: "Info",
  defaults: {
    quoteInfoData: []
  }
})
@Injectable()
export class QuoteCreateState {
  QuoteBussines: [];
  constructor(
    private quoteCreateBussinessService: QuoteCreateBussinessService,
    private store: Store
  ) {}

  @Action(CreateNewQuote)
  createNewQuote(
    ctx: StateContext<QuoteCreateStore>,
    { quoteCreateRequest }: CreateNewQuote
  ) {
    return this.quoteCreateBussinessService
      .createNewQuote(quoteCreateRequest)
      .pipe(
        tap((data: CreateNewQuoteModel[]) => {
          const state = ctx.getState();
          ctx.patchState({
            quoteInfoData: data
          });
        })
      );
  }
}
