import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { OktaAuthService } from "@okta/okta-angular";

@Injectable()
export class OktaAuthGuard implements CanActivate {
  constructor(private okta: OktaAuthService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authenticated = await this.okta.isAuthenticated();
    if (authenticated) {
      return true;
    }
    sessionStorage.setItem("okta-app-url", state.url || this.router.url);
    this.router.navigate(["/login"]);
    return false;
  }
}
