import { EventGridRow } from "@tmhcc/models";
import { createUniqueId } from "libs/util/uuid";
import { SaveEventRequest } from "@tmhcc/data-service";

export class AddEvent {
  public static readonly type = "[AddEvent] action";
  constructor(public eventGridRow: EventGridRow) {}
}

export class AddEvents {
  public static readonly type = "[AddEvents] action";
  constructor(public eventGridRows: EventGridRow[]) {
    if (eventGridRows && eventGridRows.length !== 0) {
      eventGridRows.forEach((value: EventGridRow) => {
        if (!value.customID) {
          value.customID = createUniqueId();
        }
      });
    }
  }
}

export class EditEvent {
  public static readonly type = "[EditEvent] action";
  constructor(public eventGridRow: EventGridRow) {}
}

export class DeleteAllAddedEvents {
  public static readonly type = "[DeleteAllAddedEvents] action";
  constructor() {}
}

export class SaveEvents {
  public static readonly type = "[SaveEvents] action";
  constructor(public saveEventRequest: SaveEventRequest) {}
}

export class DeleteEvents {
  public static readonly type = "[DeleteEvents] action";
  constructor(public eventIds: string[]) {}
}
