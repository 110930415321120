<hr class="divider-dark" />
<div class="button-row">
  <button
    type="button"
    class="btn btn-primary mr-2"
    data-dismiss="modal"
    [hidden]="badgeCount != 0"
    (click)="changeStep('cancel')"
  >
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-primary mr-2"
    data-dismiss="modal"
    [hidden]="badgeCount == 0"
    [ngStyle]="{'cursor': badgeCount == 0 ? 'not-allowed': 'pointer'}"
    (click)="changeStep('back')"
  >
    Back
  </button>
  <button
    type="button"
    class="btn btn-primary"
    data-dismiss="modal"
    [hidden]="badgeCount !== 0"
    (click)="changeStep('savenext')"
  >
    Save & Next
  </button>
  <button
    type="button"
    class="btn btn-primary"
    [hidden]="badgeCount == 0"
    data-dismiss="modal"
    (click)="changeStep('next')"
  >
    Next
  </button>

  <button
    type="button"
    class="btn btn-primary"
    [hidden]="badgeCount == 0"
    data-dismiss="modal"
    (click)="changeStep('save')"
  >
    Save & Refresh
  </button>
</div>
