<div class="container" style="width: 85%;">  
    <div *ngIf="RatingFormGroup">
      <form [formGroup]="RatingFormGroup" (submit)="onSubmit()">
        <h5 class="header-tab mt-3">Base Premium:</h5>
        <div class="form-row mt-3">
            <div class="col-sm-6 col-md-4" *ngFor="let items of basePremiumInfoField">
                <hcc-input-generator
                [frmGroup]="RatingFormGroup"
                [fieldInfo]="items.fieldInfo"
                [control]="items.fieldInfo.fieldName"
                [InputConfig]="items?.config"
             ></hcc-input-generator>
            </div>
        </div>
        <h5 class="header-tab mt-3">Modified Base Premium:</h5>
        <div class="form-row mt-3">
            <div class="col-sm-6 col-md-4" *ngFor="let items of modifiedBasePremiumInfoField">
                <hcc-input-generator
                [frmGroup]="RatingFormGroup"
                [fieldInfo]="items.fieldInfo"
                [control]="items.fieldInfo.fieldName"
                [InputConfig]="items?.config"
             ></hcc-input-generator>
            </div>
        </div>
        <h5 class="header-tab mt-3">Limit Modification:</h5>
        <div class="form-row mt-3">
            <div class="col-sm-6 col-md-4" *ngFor="let items of limitModificationInfoField">
                <hcc-input-generator
                [frmGroup]="RatingFormGroup"
                [fieldInfo]="items.fieldInfo"
                [control]="items.fieldInfo.fieldName"
                [InputConfig]="items?.config"
             ></hcc-input-generator>
            </div>
        </div>
        <h5 class="header-tab mt-3">Coverage Modifiers:</h5>
        <div class="form-row mt-3">
            <div class="col-sm-6 col-md-4" *ngFor="let items of coverageModifiersInfoFields">
                <hcc-input-generator
                [frmGroup]="RatingFormGroup"
                [fieldInfo]="items.fieldInfo"
                [control]="items.fieldInfo.fieldName"
                [InputConfig]="items?.config"
             ></hcc-input-generator>
            </div>
        </div>
        <h5 class="header-tab mt-3">Risk Quality Modifiers:</h5>
        <div class="form-row mt-3">
            <div class="col-sm-10 col-md-10" *ngFor="let items of riskQualityModifiersInfoFields">
                <hcc-input-generator
                [frmGroup]="RatingFormGroup"
                [fieldInfo]="items.fieldInfo"
                [control]="items.fieldInfo.fieldName"
                [InputConfig]="items?.config"
             ></hcc-input-generator>
            </div>
        </div>
        <div class="form-row mt-3">                       
            <div class="col-sm-6 col-md-4" *ngFor="let items of workplaceVoilanceTypeFields">
              <hcc-input-generator
              [frmGroup]="RatingFormGroup"
              [fieldInfo]="items.fieldInfo"
              [control]="items.fieldInfo.fieldName"
              [InputConfig]="items?.config"
            >
            </hcc-input-generator> 
          </div>                           
        </div>  
        <div class="form-row mt-3" *ngIf="isWorkplaceVoilanceShown">    
          <div class="col-sm-6 col-md-4" *ngFor="let items of workplaceVoilanceInputFields">              
            <hcc-input-generator
            [frmGroup]="RatingFormGroup"
            [fieldInfo]="items.fieldInfo"
            [control]="items.fieldInfo.fieldName"
            [InputConfig]="items.config"
            >
            </hcc-input-generator> 
          </div>         
        </div>  
        <div class="form-row mt-3">    
          <div class="col-sm-6 col-md-4" *ngFor="let items of medEvacRadioFields">              
            <hcc-input-generator
            [frmGroup]="RatingFormGroup"
            [fieldInfo]="items.fieldInfo"
            [control]="items.fieldInfo.fieldName"
            [InputConfig]="items.config"
            >
            </hcc-input-generator> 
          </div>         
        </div> 
        <div class="form-row mt-3" *ngIf="isMedEvacShown">    
          <div class="col-sm-6 col-md-4" *ngFor="let items of medEvacInputFields">              
            <hcc-input-generator
            [frmGroup]="RatingFormGroup"
            [fieldInfo]="items.fieldInfo"
            [control]="items.fieldInfo.fieldName"
            [InputConfig]="items.config"
            >
            </hcc-input-generator> 
          </div>         
        </div> 
        <div class="form-row mt-3">
            <div class="col-sm-6 col-md-4" *ngFor="let items of customPremiumInfoFields">
                <hcc-input-generator
                [frmGroup]="RatingFormGroup"
                [fieldInfo]="items.fieldInfo"
                [control]="items.fieldInfo.fieldName"
                [InputConfig]="items?.config"
             ></hcc-input-generator>
            </div>
        </div>
        <h5 class="header-tab mt-3">Final Premiums:</h5>
        <div class="form-row mt-3">
            <div class="col-sm-6 col-md-4" *ngFor="let items of finalPremiumInfoFields">
                <hcc-input-generator
                [frmGroup]="RatingFormGroup"
                [fieldInfo]="items.fieldInfo"
                [control]="items.fieldInfo.fieldName"
                [InputConfig]="items?.config"
             ></hcc-input-generator>
            </div>
        </div>                
        <div class="row text-right mt-4 mb-4">
            <div class="col-md-3"></div>             
              <div class="col-md-3">
                <button
                  [disabled]="false"
                  class="btn btn-primary w-100"
                  type="button"
                  (click)="onBackClick();"
                >
                  Back
                </button>
              </div>
              <div class="col-md-3">
                <button
                  [disabled]="false"
                  class="btn btn-primary w-100"
                  type="submit"                                 
                >
                  Calculate
                </button>
              </div>
              <div class="col-md-3">
                <button
                  [disabled]="IsNextDisabled"
                  class="btn btn-primary w-100"
                  type="button"
                  (click)="GoToQuoteInfoCoverages()"
                >
                  Next
                </button>
              </div>             
          </div>
      </form>
   </div>
</div>
