import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { BaseDataService } from "./base-data-service";
import { AppConfigService } from "@tmhcc/global-services";
import { CommonContentService } from "@tmhcc/content-service";
import { map, catchError } from "rxjs/operators";
import {
  FormsEcomodel,
  EndorsementForms,
  AddOptionalEndorsementFormsECO,
  AddOptionalEndorsementResponse,
  AvailableForms,
  ApproveResponse
} from "@tmhcc/models";
import {
  EndorsmentFormsRequest,
  EndorsementFormsResponse,
  AvailableFormsResponse,
  ApproveQuoteRequest
} from "./def/eco-endorsment-forms";

@Injectable()
export class FormsECOService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    private commonContentService: CommonContentService
  ) {
    super(http, configService);
  }
  getECOEndorsmentForms(
    payload: EndorsmentFormsRequest
  ): Observable<EndorsementForms[]> {
    const url = `${this.serviceBaseUrl}/GetEndorsementFormsECO/${payload.PolicyTxnId}/${payload.UWQuoteID}`;
    return this.get<EndorsementFormsResponse>(url).pipe(
      map(data => this.mapingEndorsementForms(data)),
      catchError(this.handleError)
    );
  }
  /**** Get optional forms API integrations***/
  getOptionalEndorsmentForms(
    payload: EndorsmentFormsRequest
  ): Observable<AvailableForms[]> {
    const url = `${this.serviceBaseUrl}/OptionalEndorsementsECO/${payload.PolicyTxnId}/${payload.UWQuoteID}`;
    return this.get<AvailableFormsResponse>(url).pipe(
      map(data => this.mapingOptionalForms(data)),
      catchError(this.handleError)
    );
  }
  saveApproveQuote(
    payload: ApproveQuoteRequest
  ): Observable<ApproveResponse> {
    const url = `${this.serviceBaseUrl}/ApproveQuote`;
    return this.put<ApproveResponse>(url, {
      body: payload
    }).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
  saveCalculateQuote(
    payload: ApproveQuoteRequest
  ): Observable<ApproveResponse> {
    const url = `${this.serviceBaseUrl}/CalculateQuote`;
    return this.put<ApproveResponse>(url, {
      body: payload
    }).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
  /**** End here***/

  /**** Save forms API integrations***/
  savePolicyForms(
    payload: AddOptionalEndorsementFormsECO
  ): Observable<AddOptionalEndorsementResponse> {
    const url = `${this.serviceBaseUrl}/AddOptionalEndorsementFormsECO`;
    return this.put<AddOptionalEndorsementResponse>(url, {
      body: payload
    }).pipe(
      map(data => {
        return data;
      }),
      catchError(this.handleError)
    );
  }
  /***End here ***/
  /*** Endorsment form data Mapping ***/
  private mapingEndorsementForms(
    data: EndorsementFormsResponse
  ): EndorsementForms[] {
    if (!data) return;
    let endorsementForms: EndorsementForms[] = [];
    if (data.EndorsementForms) {
      for (let index = 0; index < data.EndorsementForms.length; index++) {
        const element = data.EndorsementForms[index];
        let row = new EndorsementForms();
        row.FormName = element.FormName;
        row.FormCode = element.FormCode;
        row.AttachedAs = element.AttachedAs;
        row.PolicyFormId = element.PolicyFormId;
        row.IsCustomEntry = element.IsCustomEntry;
        row.Version = element.Version;
        row.IsChanged = element.IsChanged;
        row.IsAdded = element.IsAdded;
        row.IsUpdated = element.IsUpdated;
        row.IsDeleted = element.IsDeleted;
        row.UWFormId = element.UWFormId;
        row.AllowMultiples = element.AllowMultiples;
        row.CountryCode = element.CountryCode;
        row.Rated = element.Rated;
        row.AddDate = element.AddDate;
        row.FormEff = element.FormEff;
        row.EndorseSeqNum = element.EndorseSeqNum;
        row.ICMEndorseSeqNum = element.ICMEndorseSeqNum;
        endorsementForms.push(row);
      }
    }
    return endorsementForms;
  }
  /**** Optional form Data Mapping here****/
  private mapingOptionalForms(data: AvailableFormsResponse): AvailableForms[] {
    if (!data) return;
    let availableForms: AvailableForms[] = [];
    if (data.AvailableForms) {
      for (let index = 0; index < data.AvailableForms.length; index++) {
        const element = data.AvailableForms[index];
        let row = new AvailableForms();
        row.FormName = element.FormName;
        row.FormCode = element.FormCode;
        row.PolicyFormId = element.PolicyFormId;
        row.IsCustomEntry = element.IsCustomEntry;
        row.Version = element.Version;
        row.IsChanged = element.IsChanged;
        row.IsAdded = element.IsAdded;
        row.IsUpdated = element.IsUpdated;
        row.IsDeleted = element.IsDeleted;
        row.UWFormId = element.UWFormId;
        row.AllowMultiples = element.AllowMultiples;
        row.CountryCode = element.CountryCode;
        row.Rated = element.Rated;
        row.AddDate = element.AddDate;
        row.FormEff = element.FormEff;
        row.EndorseSeqNum = element.EndorseSeqNum;
        row.ICMEndorseSeqNum = element.ICMEndorseSeqNum;
        availableForms.push(row);
      }
    }
    return availableForms;
  }
}
