import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { QuoteSummaryComponent } from "./quote-summary.component";
import { ReactiveFormsModule } from "@angular/forms";
import { CoreInputsModule } from "@tmhcc/framwork/core-inputs";
import { RouterModule, Routes } from "@angular/router";
import { SaveChangeGaurd } from "@tmhcc/global-services";
const routes: Routes = [{ path: "", component: QuoteSummaryComponent }];
@NgModule({
  declarations: [QuoteSummaryComponent],
  imports: [ReactiveFormsModule, CoreInputsModule, CommonModule],
  exports: [QuoteSummaryComponent]
})
export class QuoteSummaryModule {}
