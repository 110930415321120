import { FormGroup, FormControl } from "@angular/forms";
import moment from "moment-timezone";

// custom validator to check that two fields match
export function DateValidator(startDate: string, endDate: string) {
  return (formGroup: FormGroup) => {
    const sd = formGroup.controls[startDate];
    const ed = formGroup.controls[endDate];
    const today = moment();
    if (sd.value) {
      if (
        !moment(sd.value).isSame(today, "day") &&
        moment(sd.value).diff(today) < 0
      ) {
        // sd.setErrors({ pastDate: true });
      } else if (ed.value && !moment(sd.value).isSameOrBefore(ed.value)) {
        sd.setErrors({ invalidDate: true });
      } else {
        sd.setErrors(null);
      }
    }
    if (ed.value) {
      if (
        !moment(sd.value).isSame(today, "day") &&
        moment(ed.value).diff(today) < 0
      ) {
        // ed.setErrors({ pastDate: true });
      } else if (sd.value && !moment(ed.value).isSameOrAfter(sd.value)) {
        ed.setErrors({ invalidDate: true });
      } else {
        ed.setErrors(null);
      }
    }
  };
}
