import {
  DashboardSearchRequest,
  PolicySearchRequest,
  DwldPolicySearchRequest
} from "@tmhcc/data-service";
import { SearchType } from "@tmhcc/models";

// export class GetQuoteList {
//   public static readonly type = "[GetQuoteList] action";
//   constructor(public quoteSearchRequest: QuoteSearchRequest) {
//     //quoteSearchRequest.SubCompanyCode='ECO';
//   }
// }

// export class GetPolicyList {
//   public static readonly type = "[GetPolicyList] action";
//   constructor(public policySearchRequest: PolicySearchRequest) {
//     //policySearchRequest.SubCompanyCode='ECO';
//   }
// }

export class SetSearchType {
  public static readonly type = "[SetSearchType] action";
  constructor(public searchType: SearchType) {}
}
export class DasboardData {
  public static readonly type = "[DashboardData] action";
  constructor(public dashboardSearchRequest: DashboardSearchRequest) {}
}

export class DashboardDataDwld {
  public static readonly type = "[DashboardDataDwld] action";
  constructor(public searchType:any,public dwldDashboardSearchRequest: DwldPolicySearchRequest) {}
}

