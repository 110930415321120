// public - outside of library
export enum QuoteGeneralRequest {
  QuoteNumber = "quoteNumber",
  InsuredName = "insuredName",
  PolicyTypes = "PolicyTypes",
  Address = "address",
  City = "city",
  StateOrProvinces = "StateOrProvinces",
  ZipCode = "zipCode",
  Countries = "Countries",
  BrokerName = "BrokerName",
  BrokerNumber = "BrokerNumber",
  Underwriters = "Underwriters",
  UnderwriterAssistants = "UnderwriterAssistants"
}

export enum QuoteGeneralResponse {
  QuoteNumber = "quoteNumber",
  InsuredName = "insuredName",
  PolicyTypes = "PolicyTypes",
  Address = "address",
  City = "city",
  StateOrProvinces = "state",
  ZipCode = "zipCode",
  Countries = "country",
  BrokerName = "brokerName",
  BrokerNumber = "brokerNumber",
  Underwriters = "Underwriters",
  UnderwriterAssistants = "UnderwriterAssistants"
}
