import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { DashoardStoreService } from "./store/facade/dashbard-store.service";
import {
  DashboardSearchRequest,
  PolicySearchRequest,
  DwldPolicySearchRequest
} from "@tmhcc/data-service";
import { DashoardPolicyDownloadService } from "./store/facade/dwld-dashbard-store.service";
import { DateService } from "@tmhcc/framwork/services";
@Injectable()
export class DashboardResolver implements Resolve<any> {
  constructor(private dashoardStoreService: DashoardStoreService,
    private dwldDashoardStoreService: DashoardPolicyDownloadService,
    private dateService: DateService) {}

  resolve() {  
   
    const startDate = this.dateService.now().subtract(40, "days").toISOString();
    const endDate = this.dateService.now().subtract(3, "days").toISOString();
    const dwlddashboardSearchRequest: DwldPolicySearchRequest = {
      policyNumber:"",
      dateBoundIntervalStart: startDate,
      dateBoundIntervalEnd : endDate
    };
    //debugger;
    let type = sessionStorage.ServiceType ? sessionStorage.ServiceType : 'NW'
    this.dashoardStoreService.getDwldPolicyDataMock(type,dwlddashboardSearchRequest);
    return true;
  }
}
