import { Component, OnInit } from "@angular/core";
import { QuoteStepStoreService } from "../store/facade/quote-step-store.service";
import { Router } from "@angular/router";
import {
  PolicyTxnData,
  FormsEcomodel,
  QuoteOptions,
  EndorsementForms,
  FormsetTypes,
  PolicyTxnRequest,
  PolicyMovement,
  IssueNewBusinessModel
} from "@tmhcc/models";
import { DateService } from "@tmhcc/framwork/services";
import { FormsECOService } from "@tmhcc/data-service/formsECO.service";
import { ColumnDefinition, GridOptions, Types } from "@tmhcc/framwork/grid/src";
import {
  FormBuilder,
  FormGroup,
  Validators,
  NumberValueAccessor
} from "@angular/forms";
import { Subscription, from } from "rxjs";
import {
  InputConfig,
  FieldInfo,
  FieldTypes
} from "@tmhcc/framwork/form-inputs";
import {
  EcoAgencyAgent,
  EndorsmentFormsRequest,
  IssueNewBusinessService
} from "@tmhcc/data-service";
import { ContactRequest } from "@tmhcc/data-service/def/eco-agency-agent";
import { QuotePolicyStepStore } from "../store/state/quote-policy-step.state";
import { IssueNewBusinessRequest, EcoMasterData } from "@tmhcc/data-service";
import { Store } from "@ngxs/store";
import {
  IssueNewBusiness,
  QuotePolicyTxn
} from "../store/state/quote-policy-step.action";
import { LoaderService } from "@tmhcc/framwork/loader/src";
import { PageHeaderService } from "../../shared/page-header/page-header.service";
import { AllowedRoute } from "../../shared/page-header/page-header-navigation/page-header-navigation-def";
import {
  DialogConfig,
  DialogService,
  DialogSize
} from "@tmhcc/framwork/dialog/src";
import { ValidationDialogComponent } from "../events/validation-dialog/validation-dialog.component";
import { CommonContentService } from "@tmhcc/content-service";
interface BindSummaryFields {
  policyNumber: string;
  insuredName: string;
  insuredAddress: string;
  agency: string;
  policyEffDate: string;
  policyExpDate: string;
  premium: number;
  commissionRate: number;
  commissionValue: number;
  surplusTax: string;
}

@Component({
  selector: "ec-bind-summary",
  templateUrl: "./bind-summary.component.html",
  styleUrls: ["./bind-summary.component.scss"]
})
export class BindSummaryComponent implements OnInit {
  isPolicyBound: boolean;
  policyTxnData: PolicyTxnData;
  bindSummaryFieldValues: BindSummaryFields;
  contactRequest: ContactRequest;
  commanRate: number;
  producerCommissions: number;
  policyTxnId: any;
  uwQuoteId: any;
  hideBindButton: boolean;
  tableSettingsForQuoteStep: ColumnDefinition[];
  gridSetting: GridOptions;
  commonContentService: any;
  recordsList: any;
  viewSummaryForm: FormGroup;
  subscription: Subscription;
  getContactAddress: any;
  policyProducerContactId: number;
  agencyAddress: any;
  quoteStepStoreData: any;
  uWQuotesData: any;
  _CommissionObj: any;
  routeIndex: number;
  isDateDisabled: boolean;
  isBindButtonDisabled: boolean;
  surplustaxesAndFees: any;
  getLabelsName: any;
  currentUWQuoteID: any;

  constructor(
    private quoteStepStoreService: QuoteStepStoreService,
    private formBuilder: FormBuilder,
    private router: Router,
    private commonContentServices: CommonContentService,
    private dateService: DateService,
    private formEcoservice: FormsECOService,
    private ecoMasterData: EcoMasterData,
    private store: Store,
    private dialogConfig: DialogConfig<any>,
    private dialogService: DialogService,
    private pageHeaderService: PageHeaderService,
    private loaderService: LoaderService,
    private ecoAgencyAgent: EcoAgencyAgent,
    private issueNewBusinessService: IssueNewBusinessService
  ) {
    this.getLabelsName = this.commonContentServices.getLabels();
  }

  ngOnInit(): void {
    this.recordsList = [];
    this.gridSetting = {
      pageLength: 10,
      searching: false,
      paging: false,
      ordering: false
      //emptyTable: this.commonContentService.getLabels().emptyTableMsg
    };

    this.isPolicyBound = false;
    this.hideBindButton = false;
    this.isBindButtonDisabled = true;
    // const quoteStepStoreData = this.quoteStepStoreService.data;
    this.quoteStepStoreService.data$.subscribe((data: QuotePolicyStepStore) => {
      this.quoteStepStoreData = data;
      if (
        this.quoteStepStoreData &&
        this.quoteStepStoreData.policyTxnData &&
        this.quoteStepStoreData.policyTxnData.PolicyTxnId
      ) {
        this.policyTxnData = this.quoteStepStoreData.policyTxnData;
        if (
          this.policyTxnData.ProcessStatusCode === "*" ||
          this.policyTxnData.ProcessStatusCode === "I"
        ) {
          this.isPolicyBound = true;
          this.hideBindButton = true;
        }
        this.policyTxnData.PolicyMovement.forEach(
          (objPolicyMovement: PolicyMovement) => {
            if (
              objPolicyMovement.ProcessStatusCode &&
              objPolicyMovement.ProcessStatusCode === "A"
            ) {
              this.isBindButtonDisabled = false;
            }
          }
        );
      } else {
        this.router.navigate(["/dashboard"]);
      }
    });

    this.initFormGroup();
    this.populateData();
    if (this.policyTxnData.InsuredContactId) {
      this.getContactData(this.policyTxnData.InsuredContactId);
    }
    if (this.policyTxnData.PolicyProducerContactId) {
      this.getContactData(this.policyTxnData.PolicyProducerContactId);
    }
    this.setTableData();
    this.getFormEcoData();

    /**
     * Date Picker code Start
     */
    this.PloicyEffectiveDateConfig = {
      config: {
        isDisabled: true,
        datePickerConfig: {
          dateInputFormat: this.dateService.getFormat(),
          isCalanderRequired: false
        }
      },
      fieldInfo: this.PloicyEffectiveDateInfo
    };

    this.PloicyExpirationDateConfig = {
      config: {
        isDisabled: true,
        datePickerConfig: {
          dateInputFormat: this.dateService.getFormat(),
          isCalanderRequired: false
        }
      },
      fieldInfo: this.PloicyExpirationDateInfo
    };

    this.getProgressIndex();
  }

  getProgressIndex() {
    this.pageHeaderService.getBadgeCount.subscribe(badgeCount => {
      this.routeIndex = badgeCount;
    });
  }

  populateData() {
    let policyNumber: BindSummaryFields["policyNumber"];
    let insuredName: BindSummaryFields["insuredName"];
    let insuredAddress: BindSummaryFields["insuredAddress"];
    let agency: BindSummaryFields["agency"];
    let policyEffDate: BindSummaryFields["policyEffDate"];
    let policyExpDate: BindSummaryFields["policyExpDate"];
    let premium: BindSummaryFields["premium"];
    let commissionRate: BindSummaryFields["commissionRate"];
    let commissionValue: BindSummaryFields["commissionValue"];
    let surplusTax: BindSummaryFields["surplusTax"];

    if (this.isPolicyBound) {
      policyNumber = String(this.policyTxnData.PolicyNum);
    } else {
      policyNumber = "TBD";
    }

    if (this.policyTxnData.PolicyEff) {
      policyEffDate = this.dateService.toShortDate(
        this.dateService.momentFromLongISO(this.policyTxnData.PolicyEff)
      );
      this.viewSummaryForm.get("PloicyEffectivedate").setValue(policyEffDate);
      this.viewSummaryForm.controls["PloicyEffectivedate"].disable();
      this.isDateDisabled = true;
    }
    if (this.policyTxnData.PolicyExp) {
      policyExpDate = this.dateService.toShortDate(
        this.dateService.momentFromLongISO(this.policyTxnData.PolicyExp)
      );
      this.viewSummaryForm.get("PloicyExpirationdate").setValue(policyExpDate);
      this.viewSummaryForm.controls["PloicyExpirationdate"].disable();
      this.isDateDisabled = true;
    }

    if (
      this.policyTxnData.UWQuotes &&
      this.policyTxnData.UWQuotes.length !== 0
    ) {
      this.uWQuotesData = this.policyTxnData.UWQuotes;
      this.uWQuotesData.forEach((uWQuotesDataObject: any) => {
        if (uWQuotesDataObject.QuoteStatus == "A") {
          this.surplustaxesAndFees = [];
          this.currentUWQuoteID = uWQuotesDataObject.UWQuoteId;
          if (
            uWQuotesDataObject.TaxesAndFees
              .ConsolidatedTaxesAndFeesResponseItems &&
            uWQuotesDataObject.TaxesAndFees
              .ConsolidatedTaxesAndFeesResponseItems.length !== 0
          ) {
            this.surplustaxesAndFees =
              uWQuotesDataObject.TaxesAndFees.ConsolidatedTaxesAndFeesResponseItems;
          }

          this._CommissionObj = uWQuotesDataObject.ProducerCommissions;
          if (this._CommissionObj && this._CommissionObj.length !== 0) {
            this._CommissionObj.forEach((CommissionsObject: any) => {
              this.commanRate = CommissionsObject.CommRate;
              if (CommissionsObject.OverrideComm == true) {
                let _commRate: any = this.commanRate * 100;
                _commRate = _commRate.toString();
                _commRate = parseFloat(_commRate);
                _commRate = _commRate.toFixed(2);
                commissionRate = _commRate;
              } else {
                if (CommissionsObject.DefaultCommRate) {
                  let _defaultRate: any =
                    CommissionsObject.DefaultCommRate * 100;
                  _defaultRate = _defaultRate.toString();
                  _defaultRate = parseFloat(_defaultRate);
                  _defaultRate = _defaultRate.toFixed(2);
                  commissionRate = _defaultRate;
                }
              }
              if (CommissionsObject.CommAmt) {
                commissionValue = CommissionsObject.CommAmt;
              }
            });
          }
        }
      });
    }
    //Show Premium Amount
    if (
      this.policyTxnData.AdditionalSummaries &&
      this.policyTxnData.AdditionalSummaries.length !== 0
    ) {
      this.policyTxnData.AdditionalSummaries.forEach(
        additionalSummariesData => {
          if (
            additionalSummariesData.UWQuoteId == this.currentUWQuoteID &&
            additionalSummariesData.Description == "SelectedPremSum"
          ) {
            premium = additionalSummariesData.Amount;
          }
        }
      );
    }

    // Setting all the populated value
    this.bindSummaryFieldValues = {
      policyNumber: policyNumber,
      insuredName: insuredName,
      insuredAddress: insuredAddress,
      agency: agency,
      policyEffDate: policyEffDate,
      policyExpDate: policyExpDate,
      premium: premium,
      commissionRate: commissionRate,
      commissionValue: commissionValue,
      surplusTax: surplusTax
    };
  }

  /**
   * Make GetEndorsementFormsECO API call to show Form Data in  View summary section
   */

  getFormEcoData() {
    if (this.policyTxnData) {
      this.policyTxnId = this.policyTxnData.PolicyTxnId;
    }

    if (
      this.policyTxnData.UWQuotes &&
      this.policyTxnData.UWQuotes.length !== 0
    ) {
      this.policyTxnData.UWQuotes.forEach((uWQuotesObj: any) => {
        if (uWQuotesObj.UWQuoteId) {
          this.uwQuoteId = uWQuotesObj.UWQuoteId;
        }
      });
    }
    /**
     * TODO
     */
    if (this.policyTxnId && this.uwQuoteId) {
      const bindFormsRequest: EndorsmentFormsRequest = {
        PolicyTxnId: this.policyTxnId,
        UWQuoteID: this.uwQuoteId
      };

      this.formEcoservice
        .getECOEndorsmentForms(bindFormsRequest)
        .subscribe(data => {
          if (data && data.length !== 0) {
            this.recordsList = data;
          }
        });
    }
  }

  /**
   * Get Contact Service call to get INSURED and Agency Contact address.
   */
  getContactData(getContactID: number) {
    if (getContactID === this.policyTxnData.InsuredContactId) {
      this.contactRequest = { ContactId: this.policyTxnData.InsuredContactId };
    }
    if (getContactID === this.policyTxnData.PolicyProducerContactId) {
      this.contactRequest = {
        ContactId: this.policyTxnData.PolicyProducerContactId
      };
    }
    this.ecoAgencyAgent
      .ecogetcontactDetails(this.contactRequest)
      .subscribe(resp => {
        if (resp.Addresses && resp.Addresses.length !== 0) {
          if (resp.ContactId === this.policyTxnData.InsuredContactId) {
            this.getContactAddress = resp.Addresses;
          }
          if (resp.ContactId === this.policyTxnData.PolicyProducerContactId) {
            this.agencyAddress = resp.Addresses;
          }
        }
      });
  }

  /**
   * initializing the Datepicker value and also apply validation values
   */
  initFormGroup() {
    const controls = {};
    controls[this.PloicyEffectiveDateInfo.fieldName] = [
      "",
      Validators.required
    ];
    controls[this.PloicyExpirationDateInfo.fieldName] = [
      "",
      Validators.required
    ];
    this.viewSummaryForm = this.formBuilder.group(controls);
  }

  onBackClick() {
    if (this.routeIndex === 2) {
      this.router.navigate([AllowedRoute.Quote]);
    } else if (this.routeIndex === 3) {
      this.router.navigate([AllowedRoute.Bind]);
    }
  }

  onBindPolicy() {
    const issueNewBusinessRequest: IssueNewBusinessRequest = this.prepareNewBusinessRequest();
    this.loaderService.show();
    this.issueNewBusinessService
      .issueNewBusiness(issueNewBusinessRequest)
      .subscribe((data: IssueNewBusinessModel) => {
        if (data && data.PolicyTxnId && data.Status === "SUCCESS") {
          const getPolicyTxnRequest: PolicyTxnRequest = {
            PolicyTxnID: data.PolicyTxnId.toString()
          };
          this.loaderService.show();
          this.store
            .dispatch(new QuotePolicyTxn(getPolicyTxnRequest))
            .subscribe(
              quotePolicyData => {
                this.bindSummaryFieldValues = {
                  ...this.bindSummaryFieldValues,
                  policyNumber:
                    quotePolicyData.PolicyTxnData.policyTxnData.PolicyNum
                };
                this.openSaveRequestDialog(
                  "Policy generated",
                  `Policy number ${quotePolicyData.PolicyTxnData.policyTxnData.PolicyNum} is generated successfully.`
                );
                this.loaderService.hide();
              },
              () => {
                this.loaderService.hide();
              }
            );
        } else {
          this.openSaveRequestDialog(
            "Policy generated unsuccessful",
            `Something went wrong.`
          );
          this.loaderService.hide();
        }
      });
  }

  openSaveRequestDialog(title: string, text: string) {
    const content = {
      value: text
    };
    this.dialogConfig.modalSetting = {
      title: title,
      overflow: true,
      hideFooter: true,
      dialogSize: DialogSize.LG,
      hideSave: true
    };
    this.dialogConfig.data = content;
    const ref = this.dialogService.open(
      ValidationDialogComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {});
  }

  prepareNewBusinessRequest(): IssueNewBusinessRequest {
    const filteredFormSet: FormsetTypes = this.ecoMasterData
      .getMasterData()
      .FormsetTypes.find((value: FormsetTypes) => {
        return value.FormSetTypeCode === "NB";
      });
    const issueNewBusinessRequest: IssueNewBusinessRequest = {
      PolicyTxnId: this.policyTxnId,
      FormSetId: filteredFormSet.FormSetId,
      RecalculateQuote: true
    };
    return issueNewBusinessRequest;
  }

  setTableData() {
    this.tableSettingsForQuoteStep = [
      {
        fieldInfo: {
          fieldName: "FormName",
          label: "FORM NAME"
        },
        columnType: Types.default
      },
      {
        fieldInfo: {
          fieldName: "FormCode",
          label: "FORM NUMBER"
        },
        columnType: Types.default
      }
    ];
  }

  selectEventRecord(event: any) {
    const result = event;
  }

  /**
   * Datepicker Code
   */

  PloicyEffectiveDateInfo: FieldInfo = {
    fieldName: "PloicyEffectivedate",
    label: "Date Picker",
    fieldType: FieldTypes.Date,
    showLabel: false
  };
  PloicyEffectiveDateConfig: InputConfig;

  PloicyExpirationDateInfo: FieldInfo = {
    fieldName: "PloicyExpirationdate",
    label: "Date Picker",
    fieldType: FieldTypes.Date,
    showLabel: false
  };

  PloicyExpirationDateConfig: InputConfig;
}
