import { Component, OnInit, Input, forwardRef } from "@angular/core";
import { InputBase } from "../input-base";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "hcc-ready-only",
  templateUrl: "./read-only.component.html",
  styleUrls: ["./read-only.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ReadyOnlyComponent),
      multi: true
    }
  ]
})
export class ReadyOnlyComponent extends InputBase<string> {
  constructor() {
    super();
  }
}
