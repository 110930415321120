<h1 class="body-header">
  <span>
    <a (click)="backBtn()"
      ><i class="fa fa-arrow-circle-o-left back-btn" aria-hidden="true"></i
    ></a>
    Create New Quote</span
  >
</h1>
<div class="clearfix"></div>
<div class="col-12 col-md-10 mx-auto mt-3">
  <div class="wizard-stepper mb-4">
    <div class="wizard-inner">
      <div class="connecting-line"></div>
      <div class="row no-gutters">
        <breadcrumb-progress
          class="col item step-count"
          [entity]="breadCrumb2"
          (navigateToIndex)="navigate($event)"
        ></breadcrumb-progress>
      </div>
    </div>
  </div>
</div>
