import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PagTabInfoComponent } from "./page-tab-info.component";
import { RouterModule } from "@angular/router";
import { PageTabModule } from "@tmhcc/framwork/page-tab";

@NgModule({
  declarations: [PagTabInfoComponent],
  imports: [CommonModule, RouterModule, PageTabModule],
  exports: [PagTabInfoComponent]
})
export class PageTabInfoModule {}
