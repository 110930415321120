<div class="col-md-12 mt-2 search-container">
  <select
  id="APIOptions"
  name="APIOptions"
  class="form-control"
  ngClass="{ 'border-danger': errorMessage }" 
  (change) = "itemChange($event.target.value)"> 
  <option selected value="NW">Next Wave</option>
  <option  value="TTB">Two Three Bird</option>
  <option  value="Thimble">Thimble</option>
  <option  value="Facilitron">Facilitron</option>
</select>
</div>
<div class="container-fluid">
  <!-- <h5 class="header-tab mt-2">{{ searchByValueHeading }}</h5> -->
  <h5 class="header-tab mt-2">Policy List</h5>
  <div class="row">
    <div class="col-md-12">
      <div id="eco-search-result">
        <div
          *ngIf="selectedSearchType == 'NW' && policyDetailsListNW.length > 0"
        >
          <ac-grid-table
            (childEventForNavigation)="onViewPolicyDetailClick($event)"
            [parentDivName]="'quoteSearchResponse'"
            [gridSetting]="gridSetting"
            [records]="policyDetailsListNW"
            [columnSettings]="tableSettingsForPolicyDetailsNW"
          ></ac-grid-table>
        </div>
        <div
          *ngIf="selectedSearchType == 'NW' && policyDetailsListNW.length == 0"
        >
          <ac-grid-table
            [parentDivName]="'quoteSearchResponse'"
            [gridSetting]="gridSetting"
            [records]="policyDetailsListNW"
            [columnSettings]="tableSettingsForPolicyDetailsNW"
          ></ac-grid-table>
        </div>
        <div
          *ngIf="
          selectedSearchType == 'TTB' && policyDetailsListTTB.length > 0
          "
        >
          <ac-grid-table
            (childEventForNavigation)="onViewPolicyDetailClick($event)"
            [parentDivName]="'policySearchResponse'"
            [gridSetting]="gridSetting"
            [records]="policyDetailsListTTB"
            [columnSettings]="tableSettingsForPolicyDetailsTTB"
          ></ac-grid-table>
        </div> 
        <div
          *ngIf="
          selectedSearchType == 'TTB' && policyDetailsListTTB.length == 0
          "
        >
          <ac-grid-table
            [parentDivName]="'quoteSearchResponse'"
            [gridSetting]="gridSetting"
            [records]="policyDetailsListTTB"
            [columnSettings]="tableSettingsForPolicyDetailsTTB"
          ></ac-grid-table>
        </div>
        
        <div
        *ngIf="
        selectedSearchType == 'Thimble' && policyDetailsListThimble.length > 0
        "
      >
        <ac-grid-table
          (childEventForNavigation)="onViewPolicyDetailClick($event)"
          [parentDivName]="'policySearchResponse'"
          [gridSetting]="gridSetting"
          [records]="policyDetailsListThimble"
          [columnSettings]="tableSettingsForPolicyDetailsThimble"
        ></ac-grid-table>
      </div> 
      <div
        *ngIf="
        selectedSearchType == 'Thimble' && policyDetailsListThimble.length == 0
        "
      >
        <ac-grid-table
          [parentDivName]="'quoteSearchResponse'"
          [gridSetting]="gridSetting"
          [records]="policyDetailsListThimble"
          [columnSettings]="tableSettingsForPolicyDetailsThimble"
        ></ac-grid-table>
      </div>

         <!-- ##   -->
         <!-- <div
         *ngIf="policyDetailsList.length > 0"
          >
          <ac-grid-table
          (childEventForNavigation)="onDownloadLinkClick($event)"
          [parentDivName]="'quoteSearchResponses'"
          [gridSetting]="gridSetting"
          [records]="policyDetailsList"
          [columnSettings]="tableSettingsForPolicyDetails"
        ></ac-grid-table>
        </div> -->
          <!-- ##  -->

      </div>
    </div>
  </div>
  <hr />
</div>
<ac-loader *ngIf="isLoading" [loaderMessage]="'Loading'"></ac-loader>
