import { Injectable } from "@angular/core";
import { FieldTypes } from "@tmhcc/framwork/form-inputs";
import { Validators } from "@angular/forms";
import { CommonContentService } from "@tmhcc/content-service";
import { DateService } from "@tmhcc/framwork/services";
import { BsDatepickerDirective } from "ngx-bootstrap/datepicker";
@Injectable()
export class AccountSetupDefinitionService {
  constructor() {}
  
  productTypeConfig(data:any,policytype:any) {
    return{
      productTypeConfigList: [
        {          
          fieldInfo: {
            fieldName: "quotenumber",
            label: "Quote Number",            
            fieldType: FieldTypes.Text,
            showLabel: true
          },
          config: { 
            isDisabled: true              
          },
         // colClass: "col-md-6",          
      },     
      {
        fieldInfo: {
          fieldName: "lob",
          label: "LOB:",
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: "Please select"
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: data,
            optionConfig: {
              displayKey: "LOB",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      },
      {
        fieldInfo: {
          fieldName: "policytype",
          label: "Policy Type:",
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: "Please select"
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: policytype,
            optionConfig: {
              displayKey: "PolicyType",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      },
      {
        fieldInfo: {
          fieldName: "carrier",
          label: "Carrier:",
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: "Please select"
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: [],
            optionConfig: {
              displayKey: "Carrier",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      },
      {
        fieldInfo: {
          fieldName: "currency",
          label: "Currency:",
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: "Please select"
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: [],
            optionConfig: {
              displayKey: "Currency",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      },
      {
        fieldInfo: {
          fieldName: "territory",
          label: "Territory:",
          fieldType: FieldTypes.Textarea,
          showLabel: true,
          validation: {
            required: "Required"
          }
        },
        config: {
          validation: [Validators.required]
        }
      }
    ]
  }
 };
 firstNamedInsuredConfig(countryNames:any,stateNames:any) {
    return{
      firstNamedInsuredConfigList: [
        {          
          fieldInfo: {
            fieldName: "insuredname",
            label: "Insured Name",            
            fieldType: FieldTypes.Text,
            showLabel: true,
            validation: {
              required: "Enter insured name"
            }  
          },
          config: { 
            isDisabled: false,
            validation: [Validators.required],          
          }               
      },  
      {
        fieldInfo: {          
          fieldName: 'radiocodenamed',
          label: 'Code Named Policy:',
          fieldType: FieldTypes.Radio,
          showLabel: true,               
        },
        config: {         
          isDisabled: false,
          radioCofig: {
            radioGroup: [
              {
                checked: false,
                name:'rbyes',
                label: 'Yes',
                value: 'radiocodenamed', 
                style: 'col-6 col-lg-6 pl-0 float-left'               
              },
              {
                checked: true,
                name:'rbno',
                label: 'No',
                value: 'radiocodenamed', 
                style: 'col-6 col-lg-6 pl-0 float-left'               
              }
            ]
          }
        },
        isDisabled: false,  
        colClass: 'col-3'      
      }, 
      {          
        fieldInfo: {
          fieldName: "codename",
          label: "Code Name",            
          fieldType: FieldTypes.Text,
          showLabel: false 
        },
        config: {           
          isDisabled: false,
          isHidden:true                   
        }               
     }, 
     {
        fieldInfo: {
          fieldName: "country",
          label: "Country:",
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: "Please select"
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: countryNames,
            optionConfig: {
              displayKey: "description",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      },
      {          
        fieldInfo: {
          fieldName: "address",
          label: "Address",            
          fieldType: FieldTypes.Text,
          showLabel: true,
          validation: {
            required: "Required"
          }    
        },
        config: { 
          isDisabled: false,
          validation: [Validators.required],            
        }               
      },
      {          
        fieldInfo: {
          fieldName: "city",
          label: "City",            
          fieldType: FieldTypes.Text,
          showLabel: true,
          validation: {
            required: "Required"
          }    
        },
        config: { 
          isDisabled: false,
          validation: [Validators.required],              
        }               
      },
      {
        fieldInfo: {
          fieldName: "stateprovince",
          label: "State/Province:",
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: "Please select"
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: stateNames,
            optionConfig: {
              displayKey: "description",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
       },
        {          
        fieldInfo: {
          fieldName: "zip",
          label: "Zip",            
          fieldType: FieldTypes.Text,
          showLabel: true,
          validation: {
            required: "Required"
          }    
        },
        config: { 
          isDisabled: false,
          validation: [Validators.required],             
        }               
      },
      {          
      fieldInfo: {
        fieldName: "class",
        label: "Class",            
        fieldType: FieldTypes.Text,
        showLabel: true,
        validation: {
          required: "Required"
        }  
        },
      config: { 
        isDisabled: false,
        validation: [Validators.required],            
        }               
      }]
    }
  };
  
underwritingInformationConfig(underwriters:any,assunderwriters:any) {
    return{
       underwritingInformationConfigList: [
        {
          fieldInfo: {
            fieldName: "underwriter",
            label: "Underwriter:",
            fieldType: FieldTypes.LookupList,
            showLabel: true,
            validation: {
              required: "Please select"
            }
          },
          config: {
            validation: [Validators.required],
            selectConfig: {
              selectedOption: [],
              options: underwriters,
              optionConfig: {
                displayKey: "name",
                search: true,
                limitTo: 3
              },
              multiple: false
            }
          }
        },
        {
          fieldInfo: {
            fieldName: "underwriterassistant",
            label: "Underwriter Assistant:",
            fieldType: FieldTypes.LookupList,
            showLabel: true,           
          },
          config: {            
            selectConfig: {
              selectedOption: [],
              options: assunderwriters,
              optionConfig: {
                displayKey: "name",
                search: true,
                limitTo: 3
              },
              multiple: false
            }
          }
        },
       ]  
      }
    }

brokerInformationConfig(broker:any) {
  return{
    brokerInformationConfigList: [
      {
        fieldInfo: {
          fieldName: "broker",
          label: "Broker:",
          fieldType: FieldTypes.LookupList,
          showLabel: true,
          validation: {
            required: "Please select"
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: broker,
            optionConfig: {
              displayKey: "name",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      },
      {
        fieldInfo: {
          fieldName: "brokercommission",
          label: "Broker Commission %:",
          fieldType: FieldTypes.Text,
          showLabel: true,
          validation: {
            required: "Required"
          }
        },
        config: {
          validation: [Validators.required]
        }
      },
      {
        fieldInfo: {
          fieldName: "brokernumber",
          label: "Broker Number:",
          fieldType: FieldTypes.Text,
          showLabel: true                                 
        },
        config: {          
          isDisabled:true                  
        }
      },
      {
        fieldInfo: {
          fieldName: "brokeraddress",
          label: "Broker Address:",
          fieldType: FieldTypes.Text,
          showLabel: true,                   
        },
        config: {          
          isDisabled:true
        }
      },
      {
        fieldInfo: {
          fieldName: "brokercity",
          label: "Broker City:",
          fieldType: FieldTypes.Text,
          showLabel: true,                   
        },
        config: {          
          isDisabled:true
        }
      },
      {
        fieldInfo: {
          fieldName: "brokerstate",
          label: "Broker State:",
          fieldType: FieldTypes.Text,
          showLabel: true,                   
        },
        config: {          
          isDisabled:true
        }
      },
      {
        fieldInfo: {
          fieldName: "brokerzip",
          label: "Broker Zip:",
          fieldType: FieldTypes.Text,
          showLabel: true,                  
        },
        config: {          
          isDisabled:true
        }
      },
      {
        fieldInfo: {
          fieldName: "brokercontactname",
          label: "Broker Contact Name:",
          fieldType: FieldTypes.Text,
          showLabel: true,
          validation: {
            required: "Required"
          }
        },
        config: {
          validation: [Validators.required]
        }
      }
      ]  
    }
  }
}