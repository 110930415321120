<div *ngIf="!isCustomizedGrid">
  <table
    class="table table-striped table-bordered mb-3 mx-0"
    style="width: 100%;"
    datatable
    [dtOptions]="dtOptions"
  >
    <thead>
      <tr>
        <th *ngIf="isCheckbox || isRadioBox">
          <input
            *ngIf="isCheckbox"
            id="selectAllCheckboxId"
            type="checkbox"
            (change)="selectAllCheckbox($event, records)"
          />
        </th>
        <th *ngIf="linkData !== undefined && linkData !== null">
          {{ linkData.fieldInfo.label }}
        </th>
        <th *ngIf="checkboxData !== undefined && checkboxData !== null">
          {{ checkboxData.fieldInfo.label }}
        </th>
        <th
          *ngFor="let map of columnMaps; let i = index; trackBy: trackByFun"
          [ngClass]="{ 'empty-th': map.columnType == 'empty' }"
        >
          <span>{{ map.fieldInfo.label 
          }}</span>
          <span class="subLabel" *ngIf="map.fieldInfo.subLabel">{{map.fieldInfo.subLabel}}</span>
        </th>
        <th *ngFor="let data of dateData">
          {{data.fieldInfo.label}}
        </th>
        <th *ngIf="iconData !== undefined && iconData !== null">
          {{ iconData.fieldInfo.label }}
        </th>
        <th *ngIf="viewData !== undefined && viewData !== null">
          {{ viewData.fieldInfo.label }}         
        </th>
        <th *ngIf="isDeleteOption" class="pr-2 text-center">
          Action
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let record of records; let i = index; trackBy: trackByFun"
        id="tableRow_{{ i }}"
        [ngClass]="validateRecord(i, record.IsDeleted, record.readOnly)"
      >
        <td *ngIf="isCheckbox">
          <div class="form-check pl-2 pr-2">
            <input
              id="checkBoxId_{{ i }}"
              type="checkbox"
              [checked]="record.checked"
              (change)="selectCheckValue($event, record)"
            />
            <span class="form-check-sign">
              <span class="check"></span>
            </span>
          </div>
        </td>
        <td *ngIf="isRadioBox">
          <div class="form-check pl-2 pr-2">
            <div class="radio">
              <span>
                <input
                  type="radio"
                  name="selectedRow"
                  (change)="selectRadioValue($event, record)"
                />
              </span>
            </div>
          </div>
        </td>
        <td *ngIf="linkData !== undefined && linkData !== null">
          <span
            ><a href="javascript:void(0);" (click)="selectedLink(record)">
              {{ record[linkData.fieldInfo.fieldName] }}
            </a></span
          >
        </td>
        <td *ngIf="checkboxData !== undefined && checkboxData !== null">
          <input
            id="checkBoxId_{{ i }}"
            type="checkbox"
            [readonly]="checkboxData.fieldInfo.fieldType"
            [checked]="record.checked"
            (change)="isChecked(record)"
          />
        </td>
        <td
          *ngFor="let map of columnMaps"
          [ngClass]="{ 'empty-td': map.columnType == 'empty' }"
          [class]="map?.alignment"
        >
          <span>{{
            record[map.fieldInfo.fieldName] | gridCellFormatter: map.columnType
          }}</span>
        </td>
        <td *ngFor="let data of dateData">
          <span style="display:none">{{ record[data.fieldInfo.fieldName] | date: 'yyyy-MM-dd'}}</span>
          <span >
          {{ record[data.fieldInfo.fieldName] }}
          </span>
        </td>
        <td *ngIf="iconData !== undefined && iconData !== null">
          <span>
            <i
              *ngIf="
                record.infoIconText &&
                iconData.fieldInfo.optionalField === 'icon'
              "
              class="fa fa-info-circle ml-2 info-icon-size primary-color"
              data-toggle="tooltip"
              data-placement="top"
              [title]="record.infoIconText"
            ></i>
            <i
              *ngIf="
                record.FormCode && iconData.fieldInfo.optionalField === 'icon'
              "
              class="fa fa-trash-o md-font"
              aria-hidden="true"
              (click)="isDeleteRecord(record)"
              [ngClass]="{ disabled: record.AttachedAs == 'Mandatory' }"
            ></i>
          </span>
        </td>
        <td *ngIf="viewData !== undefined && viewData !== null">
          <button
            class="btn btn-primary btn-sm"
            (click)="viewRowRecords(record)"
            type="button"
          >
            Detail
          </button>       
        </td>
        <td *ngIf="isDeleteOption">
          <button
          (click)="deleteRow(record)"
          class="delete-link btn {{ disabledClass }}"
        >
          <i class="fa fa-trash text-primary fa-lg" aria-hidden="true"></i>
        </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<!-- <div *ngIf="isCheckbox">
  <button class="btn btn-primary" (click)="saveSelectedValues()">
    Save Selected Values
  </button>
  &nbsp;
  <button class="btn btn-primary" (click)="resetCheckBox()">clear</button>
</div> -->
<div *ngIf="isCustomizedGrid">
  <table
    class="table table-striped table-bordered mb-3 mx-0"
    style="width: 100%;"
    datatable
    [dtOptions]="dtOptions"
  >
    <thead>
      <tr>
        <th *ngIf="isCheckbox || isRadioBox">
          <input
            *ngIf="isCheckbox"
            id="selectAllCheckboxId"
            type="checkbox"
            (change)="selectAllCheckbox($event, records)"
          />
        </th>
        <th
          *ngFor="let map of columnMaps; let i = index; trackBy: trackByFun"
          [ngClass]="{ 'empty-th': map.columnType == 'empty' }"
        >
          <div
            *ngIf="map.columnEventType == 'radioCheckBoxMatrix'"
            class="radioCheckBoxMatrix matrixTopLabel"
            [ngClass]="{ disabled: isReadOnly == true }"
          >
            <span>{{ map.fieldInfo.label }}</span>
            <span class="subLabel" *ngIf="map.fieldInfo.subLabel">{{map.fieldInfo.subLabel}}</span>
           
          </div>
          <div class="mt-2 text-center matrix"  *ngIf="map.columnEventType == 'radioCheckBoxMatrix'">
            <input
              id="selectAllRadioId_{{ i }}"
              class="{{ map.controlInfo.controlName }}"
              type="checkbox"
              (change)="
                selectAllRadioButton(
                  $event,
                  records,
                  i,
                  map.controlInfo.controlName
                )
              "
            />
          </div>
          <div
            *ngIf="map.columnEventType == 'matrixCheckBox'"
            class="radioCheckBoxMatrix matrixTopLabel"
            [ngClass]="{ disabled: isReadOnly == true }"
          >
            <span>{{ map.fieldInfo.label }}</span>
            <span class="subLabel" *ngIf="map.fieldInfo.subLabel">{{map.fieldInfo.subLabel}}</span>
            
          </div>
          <div class="mt-2 text-center matrix" *ngIf="map.columnEventType == 'matrixCheckBox'">
            <input
              id="matrixCheckBox"
              type="checkbox"
              (change)="
                selectAllMatrixCheckBox(
                  $event,
                  records,
                  i,
                  map.controlInfo.controlName
                )
              "
            />
          </div>
          <div
            *ngIf="
              map.columnEventType != 'radioCheckBoxMatrix' &&
              map.columnEventType != 'matrixCheckBox'
            "
          >
            <span>{{ map.fieldInfo.label }}</span>
            <span class="subLabel" *ngIf="map.fieldInfo.subLabel">{{map.fieldInfo.subLabel}}</span>
          </div>
        </th>
        <th *ngIf="isDeleteOption" class="pr-2 text-center">
          Action
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let record of records; let i = index; trackBy: trackByFun"
        id="tableRow_{{ i }}"
        [ngClass]="validateRecord(i, record.IsDeleted, record.readOnly)"
      >
        <td *ngIf="isCheckbox">
          <div class="form-check pl-2 pr-2">
            <input
              id="checkBoxId_{{ i }}"
              type="checkbox"
              [checked]="record.checked"
              (change)="selectCheckValue($event, record)"
            />
            <span class="form-check-sign">
              <span class="check"></span>
            </span>
          </div>
        </td>
        <td *ngIf="isRadioBox">
          <div class="form-check pl-2 pr-2">
            <div class="radio">
              <span>
                <input
                  type="radio"
                  name="selectedRow"
                  (change)="selectRadioValue($event, record)"
                />
              </span>
            </div>
          </div>
        </td>
        <td
          *ngFor="let map of columnMaps"
          [ngClass]="{ 'empty-td': map.columnType == 'empty' }"
        >
          <span *ngIf="map.columnType == 'link'"
            ><a href="javascript:void(0);" (click)="selectedLink(record)">
              {{ record[map.fieldInfo.fieldName] }}
            </a></span
          >
          <span *ngIf="map.columnType == 'checkbox'">
            <input
              id="checkBoxId_{{ i }}"
              type="checkbox"
              
              [checked]="record.checked"
              (change)="isChecked(record)"
            />
          </span>

          <span *ngIf="map.columnType == 'view'">
            <button
              class="btn btn-primary btn-sm"
              (click)="viewRowRecords(record)"
              type="button"
            >
              Detail
            </button>
          </span>
          
          <span *ngIf="map.columnType != 'link' && map.columnType != 'view'  && map.columnType != 'string'" >{{
            record[map.fieldInfo.fieldName] | gridCellFormatter: map.columnType
          
          }}</span>
          <span *ngIf="map.columnType == 'string'" >{{
            record[map.fieldInfo.fieldName] 
          
          }}</span>
          <i
            *ngIf="
              record.infoIconText && map.fieldInfo.optionalField === 'icon'
            "
            class="fa fa-info-circle ml-2 info-icon-size primary-color"
            data-toggle="tooltip"
            data-placement="top"
            [title]="record.infoIconText"
          ></i>
          <i
            *ngIf="record.FormCode && map.fieldInfo.optionalField === 'icon'"
            class="fa fa-trash-o md-font"
            aria-hidden="true"
            (click)="isDeleteRecord(record)"
            [ngClass]="{ disabled: record.AttachedAs == 'Mandatory' }"
          ></i>
          <div
            *ngIf="map.columnEventType == 'radioCheckBoxMatrix'"
            class="mt-2 text-center"
            [ngClass]="{ disabled: record.readOnly == true }"
          >
            <input
              id="radioButtonId_{{ i }}"
              class="radioButton_{{ j }}"
              type="radio"
              [checked]="record[map.controlInfo.controlName]"
              (click)="isRadioSelected(record)"
              (change)="
                selectRadioValueList(
                  $event,
                  record,
                  j,
                  map.controlInfo.controlName
                )
              "
              name="radio_{{ i }}"
              [disabled]="record[readOnly]"
            />
          </div>
          <div
            *ngIf="map.columnEventType == 'matrixCheckBox'"
            class="mt-2 text-center"
            [ngClass]="{ disabled: record.readOnly == true }"
          >
            <div
              *ngIf="
                record.option4 &&
                record.tradeShowPolicytype ==  false
              "
            >
              <input
                id="checkBoxId_{{ i }}"
                type="checkbox"
                [checked]="record[map.controlInfo.controlName]"
                (change)="
                  isMatrixOptionalChecked(
                    $event,
                    record,
                    j,
                    map.controlInfo.controlName
                  )
                "
                [disabled]="record[readOnly]"
              />
            </div>
            <div
              *ngIf="
                record.option5 &&
                record.tradeShowPolicytype == true 
              "
              class="readOnly"
              [ngClass]="{ disabled: record.readOnly == true }"
            >
              <input
                id="checkBoxId_{{ i }}"
                type="checkbox"
                [checked]="record[map.controlInfo.controlName]"
                (change)="
                  isMatrixOptionalChecked(
                    $event,
                    record,
                    j,
                    map.controlInfo.controlName
                  )
                "
              />
            </div>
          </div>
        </td>
        <td *ngIf="isDeleteOption">
          <a href="javascript:void(0);" class="delete-link">
            <i
              class="fas fa-trash-alt"
              (click)="deleteRow(record)"
              aria-hidden="true"
            ></i>
          </a>
        </td>
      </tr>
    </tbody>
  </table>
</div>
