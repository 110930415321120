import { NgModule } from "@angular/core";
import { Routes, RouterModule, Router } from "@angular/router";
import { EcoRoutes } from "@tmhcc/eco";
import { SimplePageComponent } from "./simple-page/simple-page.component";

import {
  OktaAuthGuard,
  OktaLoginComponent,
  LogoutComponent
} from "@tmhcc/framwork/okta-login";
import { OktaCallbackComponent } from "@okta/okta-angular";
// import { SaveChangeGaurd } from '@tmhcc/global-services';

export function onAuthRequired(oktaAuth, injector) {
  // Redirect the user to your custom login page
  const router = injector.get(Router);
  router.navigate(["/login"]);
}

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "callback", //callback url
        component: OktaCallbackComponent
      },
      {
        path: "logout",
        component: LogoutComponent
      },
      {
        path: "simple",
        component: SimplePageComponent
      },
      {
        path: "login",
        component: OktaLoginComponent
      },
      // {
      //   path: "",
      //   redirectTo: "dashboard",
      //   pathMatch: "full"
      // },
      ...EcoRoutes,
      { path: "", redirectTo: "dashboard", pathMatch: "full" },
      { path: "**", redirectTo: "dashboard" }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
