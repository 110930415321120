import { ValueAccessorBase } from "./value-accessor-base";

export class ElementBase<T> extends ValueAccessorBase<T> {
  constructor() {
    super();
    this.init();
  }

  private init() {}
}
