import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
@Injectable({
  providedIn: "root"
})
export class PageHeaderService {
  bindCounter: any;
  constructor() {}
  private badgetCount = new BehaviorSubject<number>(0);
  getBadgeCount = this.badgetCount.asObservable();

  setBindBadageCounter(bindCounter) {
    this.bindCounter = bindCounter;
  }
  setBadgeCounter(count) {
    if (this.bindCounter) {
      this.badgetCount.next(this.bindCounter);
    } else {
      this.badgetCount.next(count);
    }
  }
}
