import { Component, OnInit, OnDestroy } from "@angular/core";
import { DialogConfig, DialogRef, DialogSize } from "@tmhcc/framwork/dialog";
import { FormGroup, FormBuilder } from "@angular/forms";
import { SubjectivityGridRow, SubjectivityGrid } from "@tmhcc/models";
import { LoaderService } from "@tmhcc/framwork/loader/src";
import {
  SubjectivityDialogFieldConfig,
  SubjectivityDialogConfigData
} from "./subjectivity-dialog.config";
import { CoreInputValidationService } from "@tmhcc/framwork/core-inputs";
import {
  EcoMasterData,
  SubjectivityService,
  SubjectivityRequest,
  SaveSubjectiviyRequest,
  GetPolicySubjectivitiesRequest
} from "@tmhcc/data-service";
import { CommonContentService } from "@tmhcc/content-service";
import { Router } from "@angular/router";
import { QuoteStepStoreService } from "../../store/facade/quote-step-store.service";
import { QuotePolicyStepStore } from "../../store/state/quote-policy-step.state";
import { take } from "rxjs/operators";
import { AllowedRoute } from "../../../shared/page-header/page-header-navigation/page-header-navigation-def";

@Component({
  selector: "subjectivity-dialog",
  templateUrl: "./subjectivity-dialog.component.html",
  styleUrls: ["./subjectivity-dialog.component.scss"]
})
export class SubjectivityDialogComponent extends SubjectivityDialogFieldConfig
  implements OnInit, OnDestroy {
  constructor(
    private loaderService: LoaderService,
    private ecoMasterData: EcoMasterData,
    private subjectivityService: SubjectivityService,
    public dialogData: DialogConfig<SubjectivityDialogConfigData>,
    private dialogRef: DialogRef,
    private validationService: CoreInputValidationService,
    public contentService: CommonContentService,
    private quoteStepStoreService: QuoteStepStoreService,
    private router: Router
  ) {
    super(dialogData, contentService);
  }
  frmGroup: FormGroup;
  public selectBoxData: any;
  public lengths: number;
  public subjectivityList: SubjectivityGridRow[];
  policyTxnIdValue: string;
  isDeleteDialog: boolean;
  selectedRecord: SubjectivityGridRow;
  inputPlaceholder: string;
  isChanged: boolean;
  formSkipCheck: boolean;
  title: string;
  payload: GetPolicySubjectivitiesRequest = {
    PolicyTxnId: Number(this.policyTxnIdValue)
  };

  ngOnInit(): void {
    this.formSkipCheck = false;
    this.title = "ADD/EDIT SUBJECTIVITIES";
    this.loaderService.show();
    this.initPolicyId();
    this.isDeleteDialog = false;
    const { ConfigSubjectivities } = this.ecoMasterData.getMasterData();
    this.dialogData.data = {
      ...this.dialogData.data,
      subjList: this.getSubjectivityData(ConfigSubjectivities)
    };
    this.loaderService.hide();
  }

  initPolicyId() {
    this.quoteStepStoreService.data$
      .pipe(take(1))
      .subscribe((data: QuotePolicyStepStore) => {
        if (data && data.policyTxnData && data.policyTxnData.PolicyTxnId) {
          this.policyTxnIdValue = data.policyTxnData.PolicyTxnId;
          this.getSubjectivityList();
        } else {
          this.dialogRef.close(false);
          this.router.navigate([AllowedRoute.Dashboard]);
        }
      });
  }

  getSubjectivityList() {
    this.subjectivityList = [];
    this.loaderService.show();
    this.payload.PolicyTxnId = Number(this.policyTxnIdValue);
    this.subjectivityService.getSubjectivity(this.payload).subscribe(data => {
      if (data && data.subjectivityData) {
        this.subjectivityList = data.subjectivityData;
      }
    });
    this.loaderService.hide();
  }

  public saveCode(e, record): void {
    this.isChanged = true;
    let newRow: SubjectivityGridRow;
    const name = e.target.value;
    if (record) {
      newRow = {
        policySubjectivityId: Number(record.policySubjectivityId),
        checked: record.checked,
        description: name,
        isBlank: false
      };
    } else {
      newRow = {
        policySubjectivityId: 0,
        checked: false,
        description: name,
        isBlank: false
      };
    }
  }

  onSaved() {
    if (this.validateList()) {
      const request = this.prepareSaveRequest();
      return this.subjectivityService
        .saveSubjectivity(request)
        .subscribe(data => {
          if (data && data.Status == "SUCCESS") {
            this.dialogRef.close(true);
          } else {
          }
        });
    }
  }

  validateList(): boolean {
    this.subjectivityList.filter(value => {
      if (value.description === "" || value.description === "") {
        value.isBlank = true;
      } else {
        value.isBlank = false;
      }
    });
    if (
      this.subjectivityList.filter(
        item => item.description === "" || item.description === ""
      ).length > 0
    ) {
      return false;
    }
    return true;
  }

  prepareSaveRequest(): SaveSubjectiviyRequest {
    let saveRequest: SaveSubjectiviyRequest;
    saveRequest = {
      PolicyTxnId: Number(this.policyTxnIdValue),
      Subjectivities: this.mapSubjectivityData()
    };
    return saveRequest;
  }

  mapSubjectivityData() {
    let list: SubjectivityRequest[] = [];
    if (!this.subjectivityList) return list;
    if (this.subjectivityList) {
      for (let index = 0; index < this.subjectivityList.length; index++) {
        const element = this.subjectivityList[index];

        let record: SubjectivityRequest;
        record = {
          SubjectivityDescription: element.description,
          IsResolved: element.checked,
          PolicySubjectivityId: element.policySubjectivityId,
          SubjectivityTypeId: 1
        };

        list.push(record);
      }
    }
    return list;
  }

  deleteEventCancel() {
    this.dialogData.modalSetting.dialogSize = DialogSize.LG;
    this.isDeleteDialog = false;
  }

  deleteEventConfirmed() {
    this.dialogData.modalSetting.dialogSize = DialogSize.LG;
    this.isDeleteDialog = false;
    const index: number = this.subjectivityList.indexOf(this.selectedRecord);
    if (index !== -1) {
      this.subjectivityList.splice(index, 1);
    }
    if (
      this.subjectivityList.filter(
        item => item.description === "" || item.description === ""
      ).length > 0
    ) {
      this.isChanged = true;
    } else {
      this.isChanged = false;
    }
  }

  onDeleteClicked(record) {
    this.dialogData.modalSetting.dialogSize = DialogSize.LG;
    this.isDeleteDialog = true;
    this.selectedRecord = record;
  }

  addNewSubjectivity() {
    this.isChanged = true;
    let record: SubjectivityGridRow;
    record = {
      policySubjectivityId: 0,
      checked: false,
      description: "",
      isBlank: false
    };
    this.subjectivityList.push(record);
  }

  onClose() {
    this.dialogRef.close(false);
  }

  onDialogCancel() {
    if (this.isChanged) {
      this.dialogData.modalSetting.dialogSize = DialogSize.LG;
      this.formSkipCheck = true;
    } else {
      document.body.classList.remove("overflow-hidden");
      this.dialogRef.close(false);
    }
  }

  onDialogSave() {}

  onControlValueChange(feildName: string, val: string) {
    this.frmGroup.get(feildName).setValue(val);
  }

  ngOnDestroy(): void {
    this.validationService.setFormSubmission(false);
  }

  popupSkipCancel() {
    this.dialogData.modalSetting.dialogSize = DialogSize.LG;
    this.formSkipCheck = false;
  }

  popupSkipConfirm() {
    this.dialogData.modalSetting.dialogSize = DialogSize.LG;
    document.body.classList.remove("overflow-hidden");
    this.dialogRef.close(false);
  }
}
