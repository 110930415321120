import { DateService } from "@tmhcc/framwork/services";
import { DialogConfig, DialogSize } from "@tmhcc/framwork/dialog";
import { CommonContentService, Labels } from "@tmhcc/content-service";
import { SubjectivityGrid } from "@tmhcc/models";
import { Subjectivity } from "@tmhcc/data-service";
import { ConfigSubjectivities } from "@tmhcc/data-service/def/eco-master";

export interface SubjectivityDialogConfigData {
  subjectivity?: SubjectivityGrid;
  subjList?: SubjectivityData[];
  isDialogForEdit?: boolean;
  value?: any;
}

interface SubjectivityData {
  name?: string;
  id?: string;
}

export class SubjectivityDialogFieldConfig {
  constructor(
    public dialogData: DialogConfig<SubjectivityDialogConfigData>,
    public contentService: CommonContentService
  ) {
    this.getLabels = this.contentService.getLabels();
  }
  getLabels: Labels;

  dialogModalSettingConfigForDelete = {
    title: "Delete confirmation",
    hideHeaderX: true,
    overflow: true,
    hideFooter: true,
    dialogSize: DialogSize.LG
  };

  getSubjectivityData(subList: ConfigSubjectivities[]): any[] {
    let subData = [];
    subList.forEach((sub: ConfigSubjectivities) => {
      if (sub.SubjectivityDescription) {
        subData.push({
          name: sub.SubjectivityDescription,
          id: sub.DefaultSubjectivityTypeId
        });
      }
    });
    return subData;
  }
}
