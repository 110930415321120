import {RatingDefinitionService} from "./config/knr-rating-config"
import { Component, OnInit,OnDestroy } from '@angular/core';
import { CommonContentService } from "@tmhcc/content-service";
import { DateService } from "@tmhcc/framwork/services";
import { InputConfig } from "@tmhcc/framwork/form-inputs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { FieldInfo } from "@tmhcc/framwork/form-inputs";
import { CoreInputValidationService } from '@tmhcc/framwork/core-inputs';
import { LoaderService } from "@tmhcc/framwork/loader";
import {KnrPolicyQuoteService} from "libs/data-service/kidnap-ransom/knr-policy-quote.service";
import {Router} from "@angular/router";
import {      
  QuoteResponseData,QuoteDetail,
  UpdateQuoteRequest,
  ProductTypeUpdate,
  Insured,
  UnderwritingInformation,
  BrokerInformation,
  BrokerAddress,
  InsuredAddress
} from "libs/models/classes/knr-quote-details.model";
import { ToastrService } from 'ngx-toastr';
import {RatingRequest} from "libs/data-service/def/knr-rating";
import {      
  RatingResponse,FinalPremiums,Premiums
} from "libs/models/classes/knr-rating.model";
import { AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { CurrencyService } from "@tmhcc/framwork/services";
@Component({
  selector: 'ec-knr-rating',
  templateUrl: './knr-rating.component.html',
  styleUrls: ['./knr-rating.component.scss']
})
export class KnrRatingComponent extends RatingDefinitionService implements OnInit, AfterViewChecked  {

  createQuoteFormControl: Object = {};
  valueSub = new Subscription();
  RatingFormGroup: FormGroup;
  basePremiumInfoField: InputConfig[];  
  modifiedBasePremiumInfoField: InputConfig[];
  limitModificationInfoField: InputConfig[];
  coverageModifiersInfoFields:  InputConfig[];
  riskQualityModifiersInfoFields:  InputConfig[];
  // workplaceVoilanceInfoFields: FieldInfo[];
  // medEvacInfoFields: FieldInfo[];
  customPremiumInfoFields: InputConfig[];
  finalPremiumInfoFields: InputConfig[];
  workplaceVoilanceTypeFields: InputConfig[];
  medEvacRadioFields : InputConfig[];
  medEvacInputFields : InputConfig[];
  workplaceVoilanceInputFields : InputConfig[];
  valuSub = new Subscription();
  isMedEvacShown: boolean = false;
  isWorkplaceVoilanceShown: boolean = false;
  IsNextDisabled: boolean = true;
  constructor(
    public commonContentService: CommonContentService,
    private dateService: DateService,
    private formBuilder: FormBuilder,
    private validationService: CoreInputValidationService,
    private loaderService: LoaderService,
    private knrPolicyQuoteService: KnrPolicyQuoteService,
    private toasterService: ToastrService,
    private router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private customCurrencyPipe: CurrencyService
    ) {      
      super();
    }

  ngOnInit(): void {
    this.IsNextDisabled = true;
    this.loaderService.show();     
      this.knrPolicyQuoteService.getRatingData().subscribe(data=>{      
        this.loaderService.hide();
      
        this.basePremiumInfoField = this.basePremiumConfig(data.BasePremium).basePremiumConfigList;
        this.modifiedBasePremiumInfoField = this.modifiedBasePremiumConfig(data.ModifiedBasePremium).modifiedBasePremiumConfigList;
        this.limitModificationInfoField = this.limitModificationConfig(data.LimitModification).limitModificationConfigList;
        this.coverageModifiersInfoFields = this.coverageModifiersConfig(data.CoverageModifiers).coverageModifiersConfigList;
        this.riskQualityModifiersInfoFields = this.riskQualityModifiersConfig(data.RiskQualityModifiers).riskQualityModifiersConfigList;    
        this.customPremiumInfoFields = this.customPremiumConfig().customPremiumConfigList;
        this.finalPremiumInfoFields = this.finalPremiumConfig().finalPremiumConfigList;

        this.workplaceVoilanceTypeFields = this.workplaceVoilancesConfig().workplaceVoilancesConfigList;
        this.medEvacRadioFields = this.medEvacRadioConfig().medEvacRadioConfigList;
        this.medEvacInputFields = this.medEvacInputConfig().medEvacInputConfigList;

        this.workplaceVoilanceInputFields = this.workplaceVoilanceInputConfig(data.WorkplaceViolence).workplaceVoilanceInputConfigList;
        this.validationService.setFormSubmission(false);
        this.setFormControl(); 
        this.onSearchFormChanges();
        this.updateSelectValue();

        //workplace radio btn change
        this.workplacevoilanceRadioBtnChange();
     }); 
  }
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }
  updateSelectValue(){
    // this.RatingFormGroup.controls['deductible'].valueChanges.subscribe(data =>{      
    //   if(data !== null){
    //     this.RatingFormGroup.controls['deductible'].setValue( ('$' + data), { emitEvent: false });
    //    }      
    // });
    //limit modification
    // this.RatingFormGroup.controls['limitofinsurence'].valueChanges.subscribe(data =>{      
    //   if(data !== null){
    //     this.RatingFormGroup.controls['limitofinsurence'].setValue( ('$' + data), { emitEvent: false });
    //    }      
    // });
    // this.RatingFormGroup.controls['dissappearenceandinvst'].valueChanges.subscribe(data =>{      
    //   if(data !== null){
    //     this.RatingFormGroup.controls['dissappearenceandinvst'].setValue( ('$' + data), { emitEvent: false });
    //    }      
    // });
    // this.RatingFormGroup.controls['treatresponse'].valueChanges.subscribe(data =>{      
    //   if(data !== null){
    //     this.RatingFormGroup.controls['treatresponse'].setValue( ('$' + data), { emitEvent: false });
    //    }      
    // });
    // this.RatingFormGroup.controls['recallresponse'].valueChanges.subscribe(data =>{      
    //   if(data !== null){
    //     this.RatingFormGroup.controls['recallresponse'].setValue( ('$' + data), { emitEvent: false });
    //    }      
    // });
    // this.RatingFormGroup.controls['personalbelongings'].valueChanges.subscribe(data =>{      
    //   if(data !== null){
    //     this.RatingFormGroup.controls['personalbelongings'].setValue( ('$' + data), { emitEvent: false });
    //    }      
    // });
    // this.RatingFormGroup.controls['travelsecevolimit'].valueChanges.subscribe(data =>{      
    //   if(data !== null){
    //     this.RatingFormGroup.controls['travelsecevolimit'].setValue( ('$' + data), { emitEvent: false });
    //    }      
    // });
    // this.RatingFormGroup.controls['expsecevolimit'].valueChanges.subscribe(data =>{      
    //   if(data !== null){
    //     this.RatingFormGroup.controls['expsecevolimit'].setValue( ('$' + data), { emitEvent: false });
    //    }      
    // });
  }
  workplacevoilanceRadioBtnChange(){
    this.RatingFormGroup.controls['workplacevoilance'].valueChanges.subscribe(value =>{   
      if(value === 'Yes') 
       {
        this.workplaceVoilanceInputFields.forEach((element:InputConfig) =>{
          //debugger;
         if(element.fieldInfo.fieldName != 'wpvpremium')
          {             
            element.config.validation = [
              Validators.required
            ];
            element.fieldInfo.validation = {
              required: "Please select"
            };
            this.RatingFormGroup.controls[element.fieldInfo.fieldName]
                .setErrors({ required: true });   
            this.RatingFormGroup.controls[element.fieldInfo.fieldName]
                .setValidators([Validators.required,Validators.nullValidator]);  
           }          
        });       
          this.isWorkplaceVoilanceShown = true; 
      }       
      else if(value === null || value === '' || value === 'No')
       { 
        this.workplaceVoilanceInputFields.forEach((element:InputConfig) =>{
          //debugger;
         if(element.fieldInfo.fieldName != 'wpvpremium')
          { 
            if(value === 'No'){
            this.RatingFormGroup.controls[element.fieldInfo.fieldName]
            .setValue(null);  
            } 
            this.RatingFormGroup.controls[element.fieldInfo.fieldName]
                 .setErrors(null);   
          }       
          });   
          this.isWorkplaceVoilanceShown = false; 
       } 
     });
  }
  onSearchFormChanges() {
    this.valuSub.add(
      this.RatingFormGroup.valueChanges.subscribe(value => { 
        this.RatingFormGroup = this.RatingFormGroup;
        if(this.RatingFormGroup.controls.medeva.value)
        {         
          let control = this.RatingFormGroup.controls['costs'];
          let controlnot = this.RatingFormGroup.controls['nooftraveldays'];
          if(this.RatingFormGroup.controls.medeva.value === 'Yes')
          {    
            //set validation
           // this.validationService.setFormSubmission(true);
            this.medEvacInputFields.forEach((element:InputConfig) =>{
              //debugger;
              if(element.fieldInfo.fieldName === 'nooftraveldays')
              { 
                let controlnot = this.RatingFormGroup.controls['nooftraveldays'];
                if(controlnot.value === null || controlnot.value === '')
                  {
                    element.config.validation = [
                      Validators.required
                    ];
                    element.fieldInfo.validation = {
                      required: "Required field"
                    } ;
                    controlnot.setErrors({ required: true });   
                    this.RatingFormGroup.controls[element.fieldInfo.fieldName].setValidators([Validators.required,Validators.nullValidator]);                                     
                  }
                  else if(controlnot.value != ''|| controlnot.value != null)
                  {
                    controlnot.setErrors(null);                  
                  }
               }
              else if(element.fieldInfo.fieldName === 'costs')
              { 
                let control = this.RatingFormGroup.controls['costs'];
                if(control.value === null || control.value === '')
                  {
                    element.config.validation = [
                      Validators.required
                    ];
                    element.fieldInfo.validation = {
                      required: "Please select"
                    } ;                      
                    control.setErrors({ required: true });     
                    this.RatingFormGroup.controls[element.fieldInfo.fieldName].setValidators([Validators.required,Validators.nullValidator]);              
                  }
                else if(control.value != ''|| control.value != null)
                {
                  control.setErrors(null);                 
                }
              }
            });
            this.isMedEvacShown = true; 
          }
          else
          {
            control.setErrors(null);
            controlnot.setErrors(null);
            this.isMedEvacShown = false;           
          }
         // this.validationService.setFormSubmission(true);
        }         
      })
    );
  }  

  setFormControl(){
    // debugger;
     this.loaderService.show();
     const formControls = {};   
     this.basePremiumInfoField.forEach((element: InputConfig) => {      
       let validators;
       if (element.config && element.config.validation) {
         validators = element.config.validation;
       } else {
         validators = null;
       }
       formControls[element.fieldInfo.fieldName] = [
         element.fieldInfo.value,
         Validators.compose(validators)
       ];     
     });  
     this.modifiedBasePremiumInfoField.forEach((element: InputConfig) => {      
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      formControls[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        Validators.compose(validators)
      ];     
    });  
    this.limitModificationInfoField.forEach((element: InputConfig) => {      
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      formControls[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        Validators.compose(validators)
      ];     
     });  
     this.coverageModifiersInfoFields.forEach((element: InputConfig) => {      
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      formControls[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        Validators.compose(validators)
      ];     
     }); 
     this.riskQualityModifiersInfoFields.forEach((element: InputConfig) => {      
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      formControls[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        Validators.compose(validators)
      ];     
     });   
     
     this.workplaceVoilanceTypeFields.forEach((element: InputConfig) => {      
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      formControls[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        null
      ];     
     }); 
     this.medEvacRadioFields.forEach((element: InputConfig) => {      
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      formControls[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        null
      ];     
     });
     this.medEvacInputFields.forEach((element: InputConfig) => {      
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      formControls[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        Validators.compose(validators)
      ];     
     });
     this.customPremiumInfoFields.forEach((element: InputConfig) => {      
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      formControls[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        Validators.compose(validators)
      ];     
     }); 
    this.finalPremiumInfoFields.forEach((element: InputConfig) => {      
      let validators;
      if(element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      formControls[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        Validators.compose(validators)
      ];     
     }); 
     this.workplaceVoilanceInputFields.forEach((element: InputConfig) => {      
      let validators;
      if(element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      formControls[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        Validators.compose(validators)
      ];     
     });

     this.RatingFormGroup = this.formBuilder.group(formControls);
     this.loaderService.hide();    
   } 
   onSubmit(){
    this.validationService.setFormSubmission(true);
    if (this.RatingFormGroup.valid) {
       this.loaderService.show();
      let formValue = new QuoteDetail();
      const payload = this.mapRatingForm();
      this.knrPolicyQuoteService.calculateRating(payload).subscribe((data: RatingResponse) =>{
        this.loaderService.hide();
        this.IsNextDisabled = false;
        this.toasterService.success(
          'Rating done successfully'
        );  
        if(data)
        {
          this.RatingFormGroup.controls['basepremium'].setValue(this.customCurrencyPipe.toCurrency((data.Premiums.Base.toFixed(2))));
          this.RatingFormGroup.controls['modifiedbasepremium'].setValue(this.customCurrencyPipe.toCurrency(data.Premiums.ModBase.toFixed(2)));
          this.RatingFormGroup.controls['modifiedlimitpremium'].setValue(this.customCurrencyPipe.toCurrency(data.Premiums.ModLimit.toFixed(2)));
          this.RatingFormGroup.controls['oneyearpremium'].setValue(this.customCurrencyPipe.toCurrency(data.FinalPremiums.OneYear.toFixed(2)));
          this.RatingFormGroup.controls['twoyearpremium'].setValue(this.customCurrencyPipe.toCurrency(data.FinalPremiums.TwoYear.toFixed(2)));
          this.RatingFormGroup.controls['threeyearpremium'].setValue(this.customCurrencyPipe.toCurrency(data.FinalPremiums.ThreeYear.toFixed(2)));
          this.RatingFormGroup.controls['medevacpremium'].setValue(this.customCurrencyPipe.toCurrency(data.Premiums.MedEvac.toFixed(2)));
          this.RatingFormGroup.controls['wpvpremium'].setValue(this.customCurrencyPipe.toCurrency(data.Premiums.WPV.toFixed(2)));
          
        }
        //this.router.navigate(['/knr-dashboard']);     
      }); 
    }
   }
   replaceCurrencySign(value:any) : any{
    return value.replace(
      /[,$,R$,kr,€,$,₹,¥,₩,R,£,zł,Rs]/g,
      ''
    );
   }
   mapRatingForm(): RatingRequest {
    let quoteRecord = new UpdateQuoteRequest(); 
    let requestData: RatingRequest;   
    if(sessionStorage.getItem('_quoteId') !== undefined || 
       sessionStorage.getItem('_quoteId') !== null ){ 
       requestData  = { 
        QuoteID : sessionStorage.getItem('_quoteId'),        
        ApplicationQuestions: {
           Base : {
                    KeyEmployees : this.RatingFormGroup.controls['keyemployees'].value,
                    AllOtherEmployees : this.RatingFormGroup.controls['allotheremployees'].value,
                    AssetSize : this.RatingFormGroup.controls['assetsize'].value,
                    WorldwideExposure : this.RatingFormGroup.controls['worldwideexpo'].value,
                    CompanyVisibility : this.RatingFormGroup.controls['companyvisibility'].value,
                    IndividualVisibility : this.RatingFormGroup.controls['individualvisibility'].value
                 },
        ModBase : {
                    GrossRevenue :this.RatingFormGroup.controls['grossrevenue'].value,
                    Deductible : this.RatingFormGroup.controls['deductible'].value,
                    SpecialIndustry : this.RatingFormGroup.controls['specialindustry'].value,
                    Claims : this.RatingFormGroup.controls['claims'].value        
                 },
        Limits : {   
                    LimitOfInsurance : this.RatingFormGroup.controls['limitofinsurence'].value,
                    DisappearanceAndInvestigation : this.RatingFormGroup.controls['dissappearenceandinvst'].value,
                    ThreatResponse : this.RatingFormGroup.controls['treatresponse'].value,
                    RecallResponse : this.RatingFormGroup.controls['recallresponse'].value,
                    PersonalBelongings : this.RatingFormGroup.controls['personalbelongings'].value,
                    TravelSecurityEvacOption : this.RatingFormGroup.controls['travelsecevoption'].value,
                    TravelSecurityEvacLimit : this.RatingFormGroup.controls['travelsecevolimit'].value,
                    ExpatriateSecurityEvacOption : this.RatingFormGroup.controls['expsecevoption'].value,
                    ExpatriateSecurityEvacLimit : this.RatingFormGroup.controls['expsecevolimit'].value
                 },
      Coverage : {
                  ADAndDLimit : this.RatingFormGroup.controls['adanddlimit'].value,
                  ChildAbductionLimit : this.RatingFormGroup.controls['childabductionlimit'].value,
                  WWInclusiveDeductible : this.RatingFormGroup.controls['wwinclusibledeductible'].value,
                  TerritorialInclusiveDeductible : this.RatingFormGroup.controls['terrinclusibledeductible'].value,
                  WWInclusiveAggregate : this.RatingFormGroup.controls['wwinclusiveaggrigate'].value,
                  TerritorialExclusions : this.RatingFormGroup.controls['territorialexclusions'].value,
                  TerritorialSublimit : this.RatingFormGroup.controls['territorialsubinclaggr'].value,
                  AmendedTravel : this.RatingFormGroup.controls['amendedtravel'].value,
                  AmendedInsuredPersons : this.RatingFormGroup.controls['amendedinsuredpersons'].value,
                  LossOfEarnings : this.RatingFormGroup.controls['lossofearnings'].value,
                  AmendedThreat : this.RatingFormGroup.controls['amendedthreat'].value,
                  AmendedDisappearance : this.RatingFormGroup.controls['amendeddisappearance'].value,
                  ExclusionaryEndorsement : this.RatingFormGroup.controls['exclusionaryendorsement'].value 
                 },
        Risk :  {
                  RiskAdvisory : this.RatingFormGroup.controls['riskadvisory'].value,
                  RiskTravel : this.RatingFormGroup.controls['risktravel'].value,
                  RiskConsultant : this.RatingFormGroup.controls['riskconsultant'].value,
                  RiskCMRecom : this.RatingFormGroup.controls['riskcmrecom'].value,
                  RiskCMPlan : this.RatingFormGroup.controls['riskcmplan'].value,
                  RiskProduct : this.RatingFormGroup.controls['riskproduct'].value,
                  RiskProductAnimal : this.RatingFormGroup.controls['riskproductanimal'].value,
                  RiskUnityPortal : this.RatingFormGroup.controls['riskunityportal'].value,
                  RiskUnityGuidelines : this.RatingFormGroup.controls['riskunityguidelines'].value,
                  RiskUnityMeeting : this.RatingFormGroup.controls['riskunitymeeting'].value,
                  RiskUnityProposal : this.RatingFormGroup.controls['riskunityproposal'].value,
                  RiskUnityTelephone : this.RatingFormGroup.controls['riskunitytelephone'].value,
                  RiskCountryReports : this.RatingFormGroup.controls['riskcountryreports'].value
          
                },
       WPV :    {
                  WPVCoverage :
                  (this.RatingFormGroup.controls['workplacevoilance'].value === '' 
                   || this.RatingFormGroup.controls['workplacevoilance'].value === null) 
                   ? 'No' : this.RatingFormGroup.controls['workplacevoilance'].value, 
                  NumberOfEmployees : this.RatingFormGroup.controls['noofemployees'].value,  
                  LimitOfLiability : this.RatingFormGroup.controls['limitofliability'].value, 
                  BusinessOperations : this.RatingFormGroup.controls['businessoperations'].value, 
                  NumberOfLocations : this.RatingFormGroup.controls['nooflocations'].value, 
                  WorkplaceReduction : this.RatingFormGroup.controls['workplacereductions'].value,  
                  WorkplacePractices : this.RatingFormGroup.controls['worplacepractices'].value,  
                  SecurityControls : this.RatingFormGroup.controls['securitycontrols'].value, 
                  PriorClaims : this.RatingFormGroup.controls['priorclaims'].value, 
                  FinancialStability : this.RatingFormGroup.controls['financialstability'].value, 
                  YearsInBusiness : this.RatingFormGroup.controls['yearsinbusiness'].value, 
                  MandAActivity : this.RatingFormGroup.controls['maactivity'].value, 
                  RiskComplexity : this.RatingFormGroup.controls['riskcompexity'].value                  
                },
       MedEvac : {
                  MedEvacCoverage: (this.RatingFormGroup.controls['medeva'].value === '' 
                                   || this.RatingFormGroup.controls['medeva'].value === null) 
                                   ? 'No' : this.RatingFormGroup.controls['medeva'].value,                   
                   TravelDays: this.RatingFormGroup.controls['nooftraveldays'].value === '' ? 4 : this.RatingFormGroup.controls['nooftraveldays'].value,
                   Costs: this.RatingFormGroup.controls['costs'].value === '' ? 'Above Average' : this.RatingFormGroup.controls['costs'].value
                 },
      Custom :   {
                   Premium : this.RatingFormGroup.controls['custompremium'].value      
                 } 
        }
      }    
   }  
   return requestData;
  } 
  onBackClick(){
    this.router.navigate(['/knr-dashboard/account-setup']);
  }
  GoToQuoteInfoCoverages(){
    this.router.navigate(['/knr-dashboard/account-setup/rating/quote-info-coverages']);
  }
}
