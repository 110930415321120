<!--Header Start Here-->
<header class="header" style="display: none;">
  <div class="top-navigation">
    <div class="container">
      <nav class="navbar navbar-expand-md navbar-light custom-navbar">
        <div class="collapse navbar-collapse justify-content-end">
          <ul class="navbar-nav" *ngIf="isAuthenticated">
            <li class="nav-item ">
              <a class="nav-link" routerLink="dashboard">
                DASHBOARD<span class="sr-only">(current)</span></a
              >
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="claim-admin">PAPI ADMIN</a>
            </li>

            <li class="nav-item">
              <a class="nav-link" *ngIf="!isAuthenticated" routerLink="/login">
                LOGIN
              </a>
              <a class="nav-link" *ngIf="isAuthenticated" (click)="logout()">
                LOGOUT
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>
<!--/. Header End Here-->
<app-loader *ngIf="isLoading" [loaderMessage]="'Loading..'"></app-loader>
<!-- <ac-custom-popup-alert></ac-custom-popup-alert> -->
<router-outlet></router-outlet>
<!-- <ac-custom-popup-alert></ac-custom-popup-alert> -->
