import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { ColumnDefinition, Types, GridOptions } from "@tmhcc/framwork/grid";
import { CommonContentService } from "@tmhcc/content-service";
import { DashoardStoreService} from "../store/facade/dashbard-store.service";
import { DashboardStoreDwld } from "../store/state/dashbard.state";
import { QuoteStepStoreService } from "../../quote/store/facade/quote-step-store.service";
import { QuotePolicyStepStore } from "../../quote/store/state/quote-policy-step.state";
import { SearchType,DashboardGridDwld,policyDetail } from "@tmhcc/models";
import { LoaderService } from "@tmhcc/framwork/loader";
import { PolicyTxnRequest,DwldPolicySearchRequest,DwldPolicySearchResponse,nwPolicyDownloadRequest,ttbPolicyDownloadRequest,thimblePolicyDownloadRequest} from "@tmhcc/data-service";
import { ecaConstant } from "../../shared/eco-constant";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { QuotePolicyTxn } from "../../quote/store/state/quote-policy-step.action";
import { EcoMasterData, EcoMasterRequest } from "@tmhcc/data-service";
import { PageHeaderService } from "../../shared/page-header/page-header.service";
//import { DashoardPolicyDownloadService } from "../store/facade/dwld-dashbard-store.service";
import { PolicyDownloadService } from "libs/data-service/policy-download.service";
import  *  as  jsonData  from  'libs/data-service/policy_mock_data.json';
import { DateService } from "@tmhcc/framwork/services";
import * as fileSaver from 'file-saver';
import { PolicyDetailDialogConfigData } from "../policy-dialog/policy-dialog-config";
import {
  DialogService,
  DialogConfig,
  DialogSize
} from "@tmhcc/framwork/dialog";
//import { BoxService } from "libs/data-service/box-service";
import { PolicyDialogComponent } from "../policy-dialog/policy-dialog.component";
import {thimbleGetDocumentRequest,thimbleGetDocumentResponse,thimbledocument} from "@tmhcc/data-service/def/quote-policy-search";
@Component({
  selector: "ec-view-search",
  templateUrl: "./view-search.component.html",
  styleUrls: ["./view-search.component.scss"]
})
export class ViewSearchComponent implements OnInit {
  show = true;
  searchList: any;
  selectedList: any;
  searchTypeLabel: string;
  searchByValue: any;
  searchByValueHeading: string;
  searchBy = SearchType;
  searchByEnum = SearchType;
  recordsList: any;
  policyRecordsList: any;
  tableSettingsForQuoteSearchResult: ColumnDefinition[];
  tableSettingsForPolicySearchResult: ColumnDefinition[];
  gridSetting: GridOptions;
 // dashboardStoreData: DashboardStore;
  quoteStepStoreData: QuotePolicyStepStore;
  showTable: boolean;
  dashboardStoreDataDwld: DashboardStoreDwld;
  tableSettingsForPolicyDetailsNW: ColumnDefinition[];
  tableSettingsForPolicyDetailsTTB: ColumnDefinition[];
  tableSettingsForPolicyDetailsThimble: ColumnDefinition[];
  policyDetailsList : any;
  selectedSearchType : any;
  policyDetailsListNW:any;
  policyDetailsListTTB:any;
  hideOnDialogBox: boolean;
  policyDetailsListThimble : any;
  
  dialogModalSettingConfig = {
    title: "Policy Details",
    overflow: true,
    hideFooter: true,
    dialogSize: DialogSize.LG
  };
  constructor(
    private commonContentService: CommonContentService,
    private dashoardStoreService: DashoardStoreService,
    private quoteStepStoreService: QuoteStepStoreService,
    private loaderService: LoaderService,
    private ecoMasterData: EcoMasterData,
    private cdr: ChangeDetectorRef,
    private pageHeaderService: PageHeaderService,
    private router: Router,
    private store: Store,
    private downloadPolicyService: PolicyDownloadService,//DashoardPolicyDownloadService,
    private dateService: DateService,
    private dialogConfig: DialogConfig<PolicyDetailDialogConfigData>,
    private dialogService: DialogService,
   // private boxService: BoxService,
  ) {}
  isLoading: boolean;
  ngOnInit(): void {
    this.gridSetting = {
      pageLength: 5,
      searching: false,
      paging: true,
      ordering: true,
      orderAscending: "desc",
      orderBy: 1,
      emptyTable: this.commonContentService.getLabels().emptyTableMsg
    };
    sessionStorage.removeItem("selectedUwQuoteId");

    sessionStorage.removeItem("ServiceType");

    this.showTable = false;
    this.searchByValueHeading = this.commonContentService.getLabels().quoteTableHeading;
    this.setCoumnHeadingForPolicyDetailsNW();
    this.setCoumnHeadingForPolicyDetailsTTB();
    //this.loadDashboardData();
    //sessionStorage.ServiceType = "NW";
    this.selectedSearchType=sessionStorage.ServiceType;
    let type = 'NW'
    if(sessionStorage.ServiceType)
    {
      type = sessionStorage.ServiceType;
    }
    else
    {
      sessionStorage.ServiceType = type;
    }
    this.loadDwldDashboardData(type);
  }
 
  loadDwldDashboardData(servicetype:any) {//debugger;
    /*....Load the search result ...*/
    this.loaderService.show();
    this.policyDetailsListTTB = [];
    this.policyDetailsListNW = [];    
    const endDate = this.dateService.now().subtract(5, "days").toISOString();
    const startDate = this.dateService.now().subtract(700, "days").toISOString();
      
     this.dashoardStoreService.data$.subscribe((data: DashboardStoreDwld) => {    
   //this.downloadPolicyService.getDwldPolicyDataMock(servicetype,dwlddashboardSearchRequest).subscribe((data: DashboardGridDwld) => {   
    
      this.policyDetailsListNW = [];
      this.policyDetailsListTTB = [];
      this.cdr.detectChanges();
      this.selectedSearchType = servicetype;
      if(sessionStorage.ServiceType === 'NW')
      {
        this.setCoumnHeadingForPolicyDetailsNW();
        this.policyDetailsListNW = data.dwldPolicyDataList;
        this.policyDetailsListTTB = [];
        this.policyDetailsListThimble = [];
      }
      else if(sessionStorage.ServiceType === 'TTB')
      {
        this.setCoumnHeadingForPolicyDetailsTTB();
        this.policyDetailsListTTB = data.dwldPolicyDataList;
        this.policyDetailsListNW = [];
        this.policyDetailsListThimble = [];
      }
      else if(sessionStorage.ServiceType === 'Thimble')
      {
        this.setCoumnHeadingForPolicyDetailsThimble();
        this.policyDetailsListThimble = data.dwldPolicyDataList;
        this.policyDetailsListNW = [];
        this.policyDetailsListTTB = [];
      }        
      
      setTimeout(() => {
        this.loaderService.hide();
      }, 4000);
    });
    this.router.navigate(["/dashboard"]);
  }

  itemChange(val){//debugger;
    this.loaderService.show();
    sessionStorage.ServiceType = val;
    this.selectedSearchType = val;
    const endDate = this.dateService.now().subtract(15, "days").toISOString();
    const startDate = this.dateService.now().subtract(30, "days").toISOString();
    const dwlddashboardSearchRequest: DwldPolicySearchRequest = {
      policyNumber: "",
      dateBoundIntervalStart: startDate, // "2021-01-21T00:00:00.000Z",
      dateBoundIntervalEnd : endDate, //"2021-11-23T00:00:00.000Z"      
     };  
    
    this.dashoardStoreService.getDwldPolicyDataMock(val,dwlddashboardSearchRequest);
    this.loadDwldDashboardData(val)
   // this.router.navigate(["/dashboard"]);
    // setTimeout(() => {
    //   this.loaderService.hide();      
    //  }, 3000);
  }

  onViewPolicyDetailClick(event){  
    this.loaderService.show(); 
    if(sessionStorage.ServiceType == 'NW')
    {
      this.downloadPolicyService.getNWPolicyDetail(event.PolicyNumber).subscribe((data:policyDetail) => {          
        this.openPolicyDetailDialog(data, true);
        this.loaderService.hide();
      });
    }
    else if (sessionStorage.ServiceType == 'TTB')
    {
     this.downloadPolicyService.getTTBPolicyDetail(event.PolicyNumber, event.TransactionID).subscribe((data:policyDetail) => {          
      this.openPolicyDetailDialog(data, true);
      this.loaderService.hide();
    });    
   }
   else if (sessionStorage.ServiceType == 'Thimble')
   {
     this.downloadPolicyService.getThimblePolicyDetail(event.PolicyNumber).subscribe((data:policyDetail) => {          
    
     //call thimble document API
      const payload: thimbleGetDocumentRequest = {
        carrierPolicyNumber : data.PolicyNumber,     
        docType: 'POL'      
      };           
      this.downloadPolicyService.getThimbleDocumentInfo(payload).subscribe((tdata:thimbledocument) =>{     
        data.BoxFileId = tdata.fileID;
        this.openPolicyDetailDialog(data, true);
        this.loaderService.hide();
      });    
   });    
  }
  }
  openPolicyDetailDialog(
    defaultValue?: policyDetail,
    isDialogForEdit: boolean = false
  ) {
    //this.loaderService.show();
    this.hideOnDialogBox = true;
    this.dialogConfig.modalSetting = this.dialogModalSettingConfig;

    this.dialogConfig.data = {
      policyDetailGridRow: defaultValue ? defaultValue : null,
      isDialogForEdit: isDialogForEdit
    };
   
    const ref = this.dialogService.open(
      PolicyDialogComponent,
      this.dialogConfig
    );
    //this.loaderService.hide();  
    
  }

  onDownloadLinkClick(event) {//debugger;
    //alert('hello');
    let serviceType = sessionStorage.ServiceType;
    if(serviceType === undefined || serviceType === 'NW')
    {
      const policyDocRequest: nwPolicyDownloadRequest = {
        policyNumber: event.PolicyNumber,
        docType : "Policy"
       };  
        this.downloadPolicyService.nwDownloadFile(policyDocRequest).subscribe(
          response => {
            const blob: any = new Blob([response], { type: 'application/pdf' });
    
            const urls: string = window.URL.createObjectURL(blob);
            //const filename = urls.split('/').pop();
            const filename = event.PolicyNumber + '_Policy';    
            fileSaver.saveAs(blob, filename);
          }
        );
    }
    else if(serviceType === 'TTB'){
      const policyDocRequest: ttbPolicyDownloadRequest = {
        carrierPolicyNumber: event.PolicyNumber,
        transactionID : event.TransactionID,
        docType : "Policy"
       };  
        this.downloadPolicyService.ttbDownloadFile(policyDocRequest).subscribe(
          response => {
            const blob: any = new Blob([response], { type: 'application/pdf' });
    
            const urls: string = window.URL.createObjectURL(blob);
            //const filename = urls.split('/').pop();
            const filename = event.PolicyNumber + '_Policy';    
            fileSaver.saveAs(blob, filename);
          }
        );
    }
    else
      {       
        const policyDocRequest: thimblePolicyDownloadRequest = {
          boxFileId: event.PolicyNumber,          
          docType : "Policy"
         };  
          this.downloadPolicyService.thimbleDownloadFile(policyDocRequest).subscribe(
            response => {
              const blob: any = new Blob([response], { type: 'application/pdf' });      
              const urls: string = window.URL.createObjectURL(blob);            
              const filename = event.PolicyNumber + '_Policy';    
              fileSaver.saveAs(blob, filename);
            }
          );
      }
   
  
  }
  private mapToQuoteGridRow(data: any): DashboardGridDwld {
    let dashboardGrid = new DashboardGridDwld();
    if ((!data || !data.default[0].policyVersions) && (!data || !data.default[0].policyVersions)) return;    
   
       let policyGridRows: policyDetail[] = [];

      for (let index = 0; index < data.default[0].policyVersions.length; index++) {
        const element = data.default[0].policyVersions[index];

        let row = new policyDetail();
        row.ProductCode = element.productCode;
        //row.InsuredName = element.Insured.InsuredName;
        row.Address = element.insuredAddress.street;       
        row.TotalPremium = element.totalPremium;
        row.TransactionType = element.transactionType;
        row.InsuredName = element.insured.insuredName;
        row.City = element.insuredAddress.city;
        let CreatedDate = this.dateService.momentFromLongISO(
          element.creationDate
        );
        row.CreationDate = this.dateService.toShortISO(CreatedDate);
        if (!this.dateService.isValidDate(element.policyEffectiveDate)) {
          throw new Error(this.commonContentService.getErrors().invalidDate);
        }
        
        row.PolicyEffectiveDate = element.policyEffectiveDate;//this.dateService.toShortDate(CreatedDate);

        if (!this.dateService.isValidDate(element.policyExpirationDate)) {
          throw new Error(this.commonContentService.getErrors().invalidDate);
        }
       
        row.PolicyExpirationDate = element.policyExpirationDate;//this.dateService.toShortDate(ExpirationDate);
        row.PolicyNumber =  element.covers[0].carrierPolicyNumber;
        policyGridRows.push(row);
      }
     dashboardGrid.policyDetail  = policyGridRows;
    
    return dashboardGrid;
  }

  /**
   * latestCreatedRecort() Short the data and show Latest Record at top
   */
  // latestCreatedRecort() {
  //   return this.dashboardStoreData.quoteList.slice().sort((a, b) => {
  //     return b.QuoteNumber.localeCompare(a.QuoteNumber);
  //   });
  // }

  
  getQuotePolicyDetails(value) {
    if (value) {
      const policyTxnID = value.PolicyTxnID;
      const policyTxnRequest: PolicyTxnRequest = {
        PolicyTxnID: policyTxnID
      };
      this.loaderService.show();
      this.store
        .dispatch(new QuotePolicyTxn(policyTxnRequest))
        .subscribe(data => {
          sessionStorage.setItem("policyTxnID", policyTxnID);
          this.quoteStepStoreData = data.PolicyTxnData;
          this.loaderService.hide();
          if (
            this.quoteStepStoreData &&
            this.quoteStepStoreData.policyTxnData
          ) {
            this.loaderService.show();
            this.ecoMasterData
              .fetchMasterDataAsyncWithPolicyType(
                this.quoteStepStoreData.policyTxnData.PolicyTypeId
              )
              .subscribe(
                () => {
                  this.loaderService.hide();
                  this.redirectToSavedStep(this.quoteStepStoreData);
                },
                () => {
                  this.loaderService.hide();
                }
              );
          }
        });
    }
  }
  /*** Redirecto to last Activity */
  redirectToSavedStep(policyData) {
    if (policyData) {
      let lastActivityType =
        policyData.policyTxnData.CreateActivityLogItem.ActivityType;
      if (
        policyData.policyTxnData.ProcessStatusCode == "*" ||
        policyData.policyTxnData.ProcessStatusCode == "I"
      ) {
        this.router.navigate(["/bind-summary"]);
      } else {
        if (lastActivityType == ecaConstant.step1) {
          this.router.navigate(["/info"]);
        } else if (lastActivityType == ecaConstant.step2) {
          this.router.navigate(["/events"]);
        } else if (lastActivityType == ecaConstant.step3) {
          this.router.navigate(["/quote"]);
        } else if (lastActivityType == ecaConstant.step4) {
          this.router.navigate(["/bind"]);
        }
      }
    }
  }
  setCoumnHeadingBySearchType(SearchByName: string) {
    if (SearchByName == SearchType.QUOTE) {
      this.tableSettingsForQuoteSearchResult = [
        {
          fieldInfo: {
            fieldName: "QuoteNumber",
            label: this.commonContentService.getLabels().quoteNumber
          },
          columnType: Types.link
        },
        {
          fieldInfo: {
            fieldName: "CreatedDate",
            label: this.commonContentService.getLabels().creationDate
          },
          columnType: Types.date
        },
        {
          fieldInfo: {
            fieldName: "ExpirationDate",
            label: this.commonContentService.getLabels().expirationDate
          },
          columnType: Types.date
        },
        {
          fieldInfo: {
            fieldName: "InsuredName",
            label: this.commonContentService.getLabels().insuredName
          },
          columnType: Types.default
        },
        {
          fieldInfo: {
            fieldName: "BrokerName",
            label: this.commonContentService.getLabels().broker
          },
          columnType: Types.default
        },
        {
          fieldInfo: {
            fieldName: "Underwriter",
            label: this.commonContentService.getLabels().underwriter
          },
          columnType: Types.default
        },
        {
          fieldInfo: {
            fieldName: "Status",
            label: this.commonContentService.getLabels().status
          },
          columnType: Types.default
        },
        {
          fieldInfo: {
            fieldName: "GrossPremium",
            label: this.commonContentService.getLabels().grossPremium
          },
          columnType: Types.currency
        }
      ];
    } else {
      this.tableSettingsForPolicySearchResult = [
        {
          fieldInfo: {
            fieldName: "PolicyNumber",
            label: this.commonContentService.getLabels().policyNumber
          },
          columnType: Types.link
        },
        {
          fieldInfo: {
            fieldName: "CreatedDate",
            label: this.commonContentService.getLabels().policyEffectiveDate
          },
          columnType: Types.date
        },
        {
          fieldInfo: {
            fieldName: "ExpirationDate",
            label: this.commonContentService.getLabels().expirationDate
          },
          columnType: Types.date
        },
        {
          fieldInfo: {
            fieldName: "InsuredName",
            label: this.commonContentService.getLabels().insuredName
          },
          columnType: Types.default
        },
        {
          fieldInfo: {
            fieldName: "BrokerName",
            label: this.commonContentService.getLabels().broker
          },
          columnType: Types.default
        },
        {
          fieldInfo: {
            fieldName: "Underwriter",
            label: this.commonContentService.getLabels().underwriter
          },
          columnType: Types.default
        },
        {
          fieldInfo: {
            fieldName: "GrossPremium",
            label: this.commonContentService.getLabels().grossPremium
          },
          columnType: Types.currency
        }
      ];
    }
  }

  setCoumnHeadingForPolicyDetailsNW() {
    
      this.tableSettingsForPolicyDetailsNW  = [
   
        {
          fieldInfo: {
            fieldName: "ProductCode",
            label: "Product Code"
          },
          columnType: Types.default
        },
        {
          fieldInfo: {
            fieldName: "InsuredName",
            label: "Insured Name"
          },
          columnType: Types.default
        },
        {
          fieldInfo: {
            fieldName: "Address",
            label: "Insured Address"
          },
          columnType: Types.default
        },
       
        {
          fieldInfo: {
            fieldName: "City",
            label: "City"
          },
          columnType: Types.default
        },
        {
          fieldInfo: {
            fieldName: "Country",
            label: "Country"
          },
          columnType: Types.default
        },
        {
          fieldInfo: {
            fieldName: "PolicyEffectiveDate",
            label: "Effective Date"
          },
          columnType: Types.default
        },
        {
          fieldInfo: {
            fieldName: "PolicyExpirationDate",
            label: "Expiration Date"
          },
          columnType: Types.default
        },
        {
          fieldInfo: {
            fieldName: "TotalPremium",
            label: "Total Premium"
          },
          columnType: Types.currency
        },
        {
          fieldInfo: {
            fieldName: "PolicyNumber",
            label: "Policy Number"            
          },
          columnType: Types.link,
          isSortable: true          
        }
      ];
   
  }

  setCoumnHeadingForPolicyDetailsTTB() {
    
    this.tableSettingsForPolicyDetailsTTB  = [
     { fieldInfo: {
        fieldName: "TransactionID",
        label: "TransactionID"
      },
      columnType: Types.default
     },
     { fieldInfo: {
       fieldName: "TransactionType",
       label: "TransactionType"
     },
     columnType: Types.default
    },
    {
        fieldInfo: {
          fieldName: "ProductCode",
          label: "Product Code"
        },
        columnType: Types.default
      },
      {
        fieldInfo: {
          fieldName: "InsuredName",
          label: "Insured Name"
        },
        columnType: Types.default
      },
      {
        fieldInfo: {
          fieldName: "Address",
          label: "Insured Address"
        },
        columnType: Types.default
      },
     
      {
        fieldInfo: {
          fieldName: "City",
          label: "City"
        },
        columnType: Types.default
      },
      {
        fieldInfo: {
          fieldName: "CreationDate",
          label: "Creation Date"
        },
        columnType: Types.default
      },
      {
        fieldInfo: {
          fieldName: "PolicyEffectiveDate",
          label: "Effective Date"
        },
        columnType: Types.default
      },
      {
        fieldInfo: {
          fieldName: "PolicyExpirationDate",
          label: "Expiration Date"
        },
        columnType: Types.default
      },
      {
        fieldInfo: {
          fieldName: "TotalPremium",
          label: "Total Premium"
        },
        columnType: Types.currency
      },
      {
        fieldInfo: {
          fieldName: "PolicyNumber",
          label: "Policy Number"            
        },
        columnType: Types.link,
        isSortable: true          
      }
    ];
 
}

setCoumnHeadingForPolicyDetailsThimble() {
    
  this.tableSettingsForPolicyDetailsThimble  = [
  //  { 
  //   fieldInfo: {
  //     fieldName: "TransactionID",
  //     label: "TransactionID"
  //   },
  //   columnType: Types.default
  //  },
   { fieldInfo: {
     fieldName: "TransactionType",
     label: "TransactionType"
   },
   columnType: Types.default
  },
  {
      fieldInfo: {
        fieldName: "ProductCode",
        label: "Product Code"
      },
      columnType: Types.default
    },
    {
      fieldInfo: {
        fieldName: "InsuredName",
        label: "Insured Name"
      },
      columnType: Types.default
    },
    {
      fieldInfo: {
        fieldName: "Address",
        label: "Insured Address"
      },
      columnType: Types.default
    },
   
    {
      fieldInfo: {
        fieldName: "City",
        label: "City"
      },
      columnType: Types.default
    },
    {
      fieldInfo: {
        fieldName: "CreationDate",
        label: "Creation Date"
      },
      columnType: Types.default
    },
    {
      fieldInfo: {
        fieldName: "PolicyEffectiveDate",
        label: "Effective Date"
      },
      columnType: Types.default
    },
    {
      fieldInfo: {
        fieldName: "PolicyExpirationDate",
        label: "Expiration Date"
      },
      columnType: Types.default
    },
    {
      fieldInfo: {
        fieldName: "TotalPremium",
        label: "Total Premium"
      },
      columnType: Types.currency
    },
    {
      fieldInfo: {
        fieldName: "PolicyNumber",
        label: "Policy Number"            
      },
      columnType: Types.link,
      isSortable: true          
    }
  ];

}

  // transactionType: string
  // productCode: string;
  // insuredName: string;
  // address:     string;
  // city : string;
  // country: string;   
  // policyEffectiveDate: string;
  // policyExpirationDate: string;
  // totalPremium : Number; 
}
