<div class="col-md-12 mt-2 search-container"> 
    <form [formGroup]="searchForm" novalidate (submit)="onSubmit()">
      <div class="form-row mt-2 mb-3">        
        <div class="form-group col-md-6 col-lg-2">
          <label for="">Quote Number:</label>          
          <input
            type="text"
            class="form-control"
            acInputText
            [value]="entityNumberValQuotes"
            formControlName="entitySearchFieldQuotes"
            [fieldInfo]="quotesNumber"
            [name]="quotesNumber.fieldName"                          
            updateEvent="blur"
            autocomplete="off"
          />
        </div>
        <div class="form-group col-md-6 col-lg-2">
            <label for="">Policy Number:</label>          
            <input
              type="text"
              class="form-control"
              acInputText
              [value]="entityNumberValPolicy"
              formControlName="entitySearchFieldPolicy"
              [fieldInfo]="policyNumber"
              [name]="policyNumber.fieldName"                          
              updateEvent="blur"
              autocomplete="off"
            />
        </div>
        <div class="form-group col-md-6 col-lg-3">
          <label for="">Insured Name:</label>         
          <input
            type="text"
            class="form-control"
            acInputText
            [value]="insuredNameVal"
            formControlName="insuredNameField"
            [fieldInfo]="insuredName"
            [name]="insuredName.fieldName"           
            updateEvent="blur"
            autocomplete="off"
          />
        </div>       
        <div class="col-md-6 col-lg-2 mt-4 text-right">
          <button
            [disabled]="false"
            class="btn btn-secondary w-100"
            type="submit"
          >
            SEARCH
          </button>
        </div>
        <div class="col-md-6 col-lg-3 mt-4 text-right">
          <button
            [disabled]="false"
            class="btn btn-secondary w-100"
            type="button"
            (click)="onCreateQuoteClick();"
          >
            Create new quote
          </button>
        </div>
      </div>
    </form>
    
  </div>
  