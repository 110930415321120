import {
  Directive,
  forwardRef,
  HostBinding,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
  Attribute,
  HostListener,
  OnInit,
  Input
} from "@angular/core";
import { TextFormElement } from "../text/text-form-element";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { FieldInfo } from "../field-info";

@Directive({
  selector: "label[acForLable]",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputLabelDirective),
      multi: true
    }
  ]
})
export class InputLabelDirective implements OnInit {
  @HostBinding("attr.for") forLabel: string;
  @Input() fieldInfo: FieldInfo;

  constructor(protected renderer: Renderer2, protected el: ElementRef) {}

  ngOnInit() {
    this.forLabel = this.buildForLabel();
  }

  protected buildForLabel() {
    if (!this.fieldInfo || !this.fieldInfo.fieldName) {
      return this.el.nativeElement.getAttribute("id");
    }
    return `${this.fieldInfo.fieldName}`;
  }
}
