export const ecaConstant = {
  step1: "Step1Save",
  step2: "Step2Save",
  step3: "Step3Save",
  step4: "Step4Save",
  saveStep4Description: "saved step 4 details",
  dataGroupName: "ECO Quote Options",
  moneyResponse: "MoneyResponse",
  selected: "Selected",
  option: "Option",
  money: "Money",
  checkBoxResponse: "CheckBoxResponse"
};
export const APModel = {
  additionalPerilLabel1: {
    name: "AP1",
    isAP: true,
    isAPLabel: true,
    rowNum: 1
  },
  additionalPerilLabel2: {
    name: "AP1",
    isAP: true,
    isAPLabel: true,
    rowNum: 2
  },
  additionalPerilLabel3: {
    name: "AP1",
    isAP: true,
    isAPLabel: true,
    rowNum: 3
  },
  bioChemTerrIncl: "BioChemTerr",
  communicableDisease: "Communicable Disease",
  limitedWar: "LimitedWar",
  nonAppearance: "NonAppearance",
  nuclearHazard: "NuclearHazard",
  war: "War"
};
export enum RiskResponseType {
  FreeTextResponse = "TXT",
  MoneyResponse = "MNY",
  DateResponse = "DT ",
  ChooseOneComboResponse = "SLT",
  DecimalResponse = "DEC"
}
export const RiskModel = {
  additionalPerilValue1: {
    name: "RatingInput",
    type: RiskResponseType.FreeTextResponse,
    isAP: true,
    isAPLabel: false,
    rowNum: 1
  },
  additionalPerilValue2: {
    name: "RatingInput",
    type: RiskResponseType.FreeTextResponse,
    isAP: true,
    isAPLabel: false,
    rowNum: 2
  },
  additionalPerilValue3: {
    name: "RatingInput",
    type: RiskResponseType.FreeTextResponse,
    isAP: true,
    isAPLabel: false,
    rowNum: 3
  },
  additionalPerilLabel1: {
    name: "AP1",
    type: RiskResponseType.FreeTextResponse,
    isAP: true,
    isAPLabel: true,
    rowNum: 1
  },
  additionalPerilLabel2: {
    name: "AP1",
    type: RiskResponseType.FreeTextResponse,
    isAP: true,
    isAPLabel: true,
    rowNum: 2
  },
  additionalPerilLabel3: {
    name: "AP1",
    type: RiskResponseType.FreeTextResponse,
    isAP: true,
    isAPLabel: true,
    rowNum: 3
  },
  address: {
    name: "VenueAdd",
    type: RiskResponseType.FreeTextResponse
  },
  baseRate: {
    name: "BaseRate",
    type: RiskResponseType.DecimalResponse
  },
  bioChemTerrIncl: {
    name: "BioChemTerr",
    type: RiskResponseType.DecimalResponse
  },
  city: {
    name: "VenueCty",
    type: RiskResponseType.FreeTextResponse
  },
  civilCommotion: {
    name: "Civil Commotion",
    type: RiskResponseType.DecimalResponse
  },
  communicableDisease: {
    name: "Communicable Disease",
    type: RiskResponseType.DecimalResponse
  },
  country: {
    name: "Country",
    type: RiskResponseType.FreeTextResponse
  },
  csTornadoHail: {
    name: "CSTH",
    type: RiskResponseType.DecimalResponse
  },
  cyber: {
    name: "Cyber",
    type: RiskResponseType.DecimalResponse
  },
  earthquake: {
    name: "Earthquake",
    type: RiskResponseType.DecimalResponse
  },
  era: {
    name: "ERA",
    type: RiskResponseType.DecimalResponse
  },
  eventDeductible: {
    name: "EventDed",
    type: RiskResponseType.MoneyResponse
  },
  eventDescription: {
    name: "EventDesc",
    type: RiskResponseType.FreeTextResponse
  },
  eventEndDate: {
    name: "EndDt",
    type: RiskResponseType.DateResponse
  },
  eventLimitOfInsurance: {
    name: "EventLimit",
    type: RiskResponseType.MoneyResponse
  },
  eventStartDate: {
    name: "StartDt",
    type: RiskResponseType.DateResponse
  },
  eventType: {
    name: "EventType",
    type: RiskResponseType.ChooseOneComboResponse
  },
  eventVenue: {
    name: "VenueNm",
    type: RiskResponseType.FreeTextResponse
  },
  limitedTerrorism: {
    name: "LimitedTerrorism",
    type: RiskResponseType.DecimalResponse
  },
  limitedWar: {
    name: "LimitedWar",
    type: RiskResponseType.DecimalResponse
  },
  nationalMounting: {
    name: "NationalMounting",
    type: RiskResponseType.DecimalResponse
  },
  nonAppearance: {
    name: "NonAppearance",
    type: RiskResponseType.DecimalResponse
  },
  nucBioChemTerrorism: {
    name: "NucBioChemTerrorism",
    type: RiskResponseType.DecimalResponse
  },
  nuclearHazard: {
    name: "NuclearHazard",
    type: RiskResponseType.DecimalResponse
  },
  state: {
    name: "VenueSt",
    type: RiskResponseType.FreeTextResponse
  },
  strike: {
    name: "Strike",
    type: RiskResponseType.DecimalResponse
  },
  terrorism: {
    name: "Terrorism",
    type: RiskResponseType.DecimalResponse
  },
  war: {
    name: "War",
    type: RiskResponseType.DecimalResponse
  },
  wildfire: {
    name: "Wildfire",
    type: RiskResponseType.DecimalResponse
  },
  windstorm: {
    name: "Windstorm",
    type: RiskResponseType.DecimalResponse
  },
  winterWeather: {
    name: "WinterWeather",
    type: RiskResponseType.DecimalResponse
  },
  zipCode: {
    name: "VenueZp",
    type: RiskResponseType.FreeTextResponse
  },
  revenue: {
    name: "Revenue",
    type: RiskResponseType.MoneyResponse
  },
  expenses: {
    name: "Expenses",
    type: RiskResponseType.MoneyResponse
  },
  insPersSpkr1: {
    name: "InsPersSpkr1",
    type: RiskResponseType.FreeTextResponse
  },
  insPersSpkr2: {
    name: "InsPersSpkr2",
    type: RiskResponseType.FreeTextResponse
  },
  insPersSpkr3: {
    name: "InsPersSpkr3",
    type: RiskResponseType.FreeTextResponse
  },
  insPersSpkr4: {
    name: "InsPersSpkr4",
    type: RiskResponseType.FreeTextResponse
  },
  insPersSpkr5: {
    name: "InsPersSpkr5",
    type: RiskResponseType.FreeTextResponse
  }
};
