<div class="overlay-container"></div>
<div
  class="modal fade show customStyle-modal"
  style="display:block"
  role="dialog"
  [style.width]="modalSetting.width + 'px'"
  [style.height]="modalSetting.height + 'px'"
  [style.overflow]="modalSetting.overflow ? 'auto' : 'hidden'"
>
  <div
    class="modal-dialog"
    [ngClass]="modalSetting.dialogSize ? modalSetting.dialogSize : ''"
  >
    <div
      class="modal-dialog"
      [ngClass]="modalSetting.dialogSize ? modalSetting.dialogSize : ''"
    >
      <div class="modal-content">
        <div class="modal-header" *ngIf="!modalSetting.hideHeader">
          <h5 class="modal-title">{{ modalSetting!.title }}</h5>
          <button
            type="
       button"
            *ngIf="!modalSetting.hideHeaderX"
            class="close"
            (click)="close()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <ng-template appInsertion> </ng-template>
        </div>
        <div class="modal-footer" *ngIf="!modalSetting.hideFooter">
          <button
            type="button"
            *ngIf="!modalSetting.hideSave"
            class="btn btn-primary"
            (click)="save()"
          >
            {{ modalSetting!.SaveButton }}
          </button>
          <button type="button" class="btn btn-secondary" (click)="close()">
            {{ modalSetting!.CancelButton }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
