import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NgxsModule } from "@ngxs/store";
import { DialogModule, DialogConfig } from '@tmhcc/framwork/dialog';
import { ReactiveFormsModule,FormsModule,FormGroupDirective } from '@angular/forms';
import { OktaAuthGuard } from '@okta/okta-angular';
import { CommonModule } from "@angular/common";

import { KnrPolicyQuoteService } from "libs/data-service/kidnap-ransom/knr-policy-quote.service";

import { QuoteInfoCoveragesComponent } from './quote-info-coverages.component';
//import { AccountSetupDefinitionService } from './config/knr-account-setup-config';
import { CoreInputsModule } from "@tmhcc/framwork/core-inputs";
const routes: Routes = [
  {
    path: "account-setup/rating/quote-info-coverages",
    component: QuoteInfoCoveragesComponent   
  }
];

@NgModule({
  declarations: [QuoteInfoCoveragesComponent],
  imports: [
    CommonModule,    
    NgxsModule,   
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild(routes),
    CoreInputsModule    
  ],
  providers: [    
    DialogConfig,
    OktaAuthGuard,
    FormGroupDirective,
    KnrPolicyQuoteService,
   // AccountSetupDefinitionService  
  ],
  exports: [RouterModule,QuoteInfoCoveragesComponent]
})
export class QuoteInfoCoveragesModule { }
