import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { BaseDataService } from "./base-data-service";
import { AppConfigService } from "@tmhcc/global-services";
import {
  EcoAgencyRequest,
  EcoAgencyResponse,
  ContactRequest,
  ContactResponse
} from "./def/eco-agency-agent";

@Injectable()
export class EcoAgencyAgent extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }
  EcoAgencyAgentData(payload: EcoAgencyRequest): Observable<EcoAgencyResponse> {
    const url = `${this.serviceBaseUrl}/GetAgencyAgentForSubcompany/${payload.SubCompanyCode}`;
    return this.get<EcoAgencyResponse>(url);
  }
  ecogetcontactDetails(payload: ContactRequest): Observable<ContactResponse> {
    const url = `${this.serviceBaseUrl}/GetContact/${payload.ContactId}`;
    return this.get<ContactResponse>(url);
  }
}
