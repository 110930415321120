<form [formGroup]="frmGroup">
  <label *ngIf="fieldInfo.showLabel" [fieldInfo]="fieldInfo" acForLable>
    {{ fieldInfo.label }}<span *ngIf="fieldInfo.validation?.required">*</span>
  </label>
  <hcc-custom-validation [validations]="fieldInfo.validation">
    <input
      type="email"
      [value]="value"
      class="form-control"
      acInputEmail
      [control]="control"
      [formGroup]="frmGroup"
      inputValidateRef
      [formControlName]="control"
      [ngClass]="{
        'border-danger': frmGroup.get(fieldInfo.fieldName).hasError('pattern')
      }"
      [formGroup]="frmGroup"
      [fieldInfo]="fieldInfo"
    />
  </hcc-custom-validation>
</form>
