import { Component, forwardRef } from "@angular/core";
import { InputBase } from "../input-base";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "hcc-email-input",
  templateUrl: "./email-input.component.html",
  styleUrls: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailInputComponent),
      multi: true
    }
  ]
})
export class EmailInputComponent extends InputBase<string> {
  constructor() {
    super();
  }
}
