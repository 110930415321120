<div class="modal-header px-0 pt-0">
  <h5 class="modal-title" id="newEventLabel">FORMS</h5>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="onClose()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body px-0">
  <form [formGroup]="formgroup">
    <div class="form-group row">
      <div class="col-11">
        <hcc-select
          [control]="FormsSelectConfig.fieldInfo.fieldName"
          [frmGroup]="formgroup"
          [fieldInfo]="FormsSelectConfig.fieldInfo"
          [InputConfig]="FormsSelectConfig.config"
        >
        </hcc-select>
      </div>
    </div>
  </form>
</div>
<div class="modal-footer px-0 pb-0">
  <button
    type="button"
    class="btn btn-primary"
    data-dismiss="modal"
    (click)="onClose()"
  >
    Cancel
  </button>
  <button type="button" class="btn btn-primary" (click)="saveOptionalForms()">
    Save
  </button>
</div>
