import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseDataService } from "./base-data-service";
import { AppConfigService } from "@tmhcc/global-services";
import { isEmpty } from "@tmhcc/util";
import { PolicyMasterData, Country } from "@tmhcc/models";
import { PolicyMasterResponse } from "./def/policy-master";

@Injectable()
export class PolicyMasterDataService extends BaseDataService {
  private masterDataCached: PolicyMasterData;

  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http, configService);
  }

  get masterData() {
    if (!this.masterDataCached) {
      throw new Error("Master data must be fetched before it can be used.");
    }

    return this.masterDataCached;
  }

  get countries() {
    if (!this.masterData.countries) {
      throw new Error("Corrupt master data: Countries.");
    }

    return this.masterData.countries;
  }

  getCountryById(id: string): Country {
    if (isEmpty(id)) {
      return;
    }

    for (let index = 0; index < this.countries.length; index++) {
      const element = this.countries[index];
      if (element.id === id) {
        return element;
      }
    }
  }

  async fetchMasterDataAsync(subCompanyCode: string) {
    if (isEmpty(subCompanyCode)) {
      throw new Error("SubCompany must be set for claim master data.");
    }

    const url = `${this.serviceBaseUrl}/PolicyMasterData/${subCompanyCode}`;

    await this.get<PolicyMasterResponse>(url)
      .toPromise()
      .then(data => {
        this.masterDataCached = this.masterPolicyMapper(data);
      });

    return this.masterDataCached;
  }

  private masterPolicyMapper(data: PolicyMasterResponse): PolicyMasterData {
    let claimMasterData = new PolicyMasterData();

    if (data.Countries) {
      data.Countries.forEach(item => {
        claimMasterData.countries.push(new Country(item.Id, item.Description));
      });
    }

    return claimMasterData;
  }
}
