import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { BreadcrumbProgressControl } from "./breadcrumb-progress.control";

@Component({
  selector: "breadcrumb-progress",
  templateUrl: "./breadcrumb-progress.component.html",
  styleUrls: ["./breadcrumb-progress.component.scss"]
})
export class BreadcrumbProgressComponent implements OnInit {
  @Input() entity: BreadcrumbProgressControl;
  @Output() navigateToIndex = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  getClasses(index: number) {
    let cssClass = index <= this.entity.progressIndex ? "active" : "";
    if (index === 0) {
      cssClass += " first-element";
    } else if (this.entity.titles.length - 1 === index) {
      cssClass += " last-element";
    }
    return cssClass;
  }

  stepNavigate(entity, clicked) {
    if (!entity.isDisabled[clicked] && entity.progressIndex !== clicked) {
      entity.clickedIndex = clicked;
      this.navigateToIndex.emit(entity);
    }
  }
}
