import {
  Component,
  OnInit,
  AfterViewInit,
  ChangeDetectorRef
} from "@angular/core";
import { OktaAuthService } from "@okta/okta-angular";
import { EcoMasterData, EcoMasterRequest } from "@tmhcc/data-service";
import {
  Router,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationStart
} from "@angular/router";
import { LoaderService } from "@tmhcc/framwork/loader";
import { AppConfigService } from '@tmhcc/global-services';
import { environment } from 'apps/policy-admin/src/environments/environment';

@Component({
  selector: "ec-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, AfterViewInit {
  title = "policy-admin";
  isAuthenticated: boolean;
  isLoading: boolean;

  constructor(
    public oktaAuth: OktaAuthService,
    private masterData: EcoMasterData,
    public router: Router,
    public loaderService: LoaderService,
    private cdr: ChangeDetectorRef,
    protected configService: AppConfigService
  ) {
    // Subscribe to authentication state changes
    this.oktaAuth.$authenticationState.subscribe(
      (isAuthenticated: boolean) => (this.isAuthenticated = isAuthenticated)
    );

    this.loaderService.isLoading.subscribe(loader => {
      //Remove Error
      setTimeout(() => {
        this.isLoading = loader;
      }, 200);
      // this.cdr.detectChanges();
    });
    this.loaderService.show();
  }

  async ngOnInit() {
    // Get the authentication state for immediate use
    this.isAuthenticated = await this.oktaAuth.isAuthenticated();
    if (this.isAuthenticated) {
      this.removeClass("topheader", "hide");
      await this.setWelcomeUser();
    } else {
      this.addClass("topheader", "hide");
    }
  }

  ngAfterViewInit() {
    this.loaderService.hide();
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // this.loaderService.show();
      } else if (
        event instanceof NavigationEnd ||
        event instanceof NavigationCancel
      ) {
        setTimeout(() => {
          // this.loaderService.hide();
        }, 50);
      }
    });
  }

  dashboardLink() {
    const elem = document.querySelector("#dashboardHeader");
    if (elem) {
      elem.addEventListener(
        "click",
        this.navigateToDashboard.bind(this),
        false
      );
    }
  }

  navigateToDashboard() {
    sessionStorage.removeItem("SelectedUwQuoteId");
    this.router.navigate(["/dashboard"]);
  }

  async setWelcomeUser() {
    const userClaims = await this.oktaAuth.getUser();

    // user name is exposed directly as property
    var element = document.getElementById("headerUserName");
    //debugger;
    element.innerHTML = userClaims.name;

    this.dashboardLink();

    // this.oktaAuth.getUser().then(user => {
    //   var element = document.getElementById('headerUserName');
    //   debugger;
    //   element.innerHTML = user.given_name;
    // })
  }
  addClass(elementId, className) {
    if (document.getElementById(elementId)) {
      var element = document.getElementById(elementId);
      if (element.classList) {
        element.classList.add(className);
      }
    }
  }
  removeClass(elementId, className) {
    if (document.getElementById(elementId)) {
      var element = document.getElementById(elementId);
      if (element.classList) {
        element.classList.remove(className);
      }
    }
  }

  login() {
    this.oktaAuth.loginRedirect("/dashboard");
  }
  async logout() {//debugger;
    this.addClass("topheader", "hide");
    sessionStorage.clear();
    localStorage.clear();
    const url = `${environment.appConfig.baseHref}/login`;
    await this.oktaAuth.logout(url);
  }
}
