import { ClaimSearchResponse } from "@tmhcc/data-service";

export const claimSearchMockup: ClaimSearchResponse = {
  claimSearchResults: [
    {
      ClaimId: 91125,
      ClaimNum: "AVD-20-00003",
      PolicyNum: "1333499-1",
      LossDate: "2020-05-07T00:00:00.0000000Z",
      ClaimStatus: "Open",
      ClaimHandler: "Dumbre, Snehal",
      InsuredName: "NOKIA, INC. - Mockup Data"
    },
    {
      ClaimId: 91128,
      ClaimNum: "AVD-20-00006",
      PolicyNum: "1333499-1",
      LossDate: "2020-05-13T00:00:00.0000000Z",
      ClaimStatus: "Open",
      ClaimHandler: "Dumbre, Snehal",
      InsuredName: "NOKIA, INC."
    }
  ]
};
