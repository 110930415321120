import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PageHeaderComponent } from "../page-header/page-header.component";
import { RouterModule } from "@angular/router";
import {
  LibModuleModule,
  BreadcrumbProgressModule
} from "@tmhcc/framwork/breadcrumb-progress";

import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [PageHeaderComponent],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    LibModuleModule,
    BreadcrumbProgressModule
  ],
  exports: [PageHeaderComponent]
})
export class PageHeaderModule {}
