import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfigService } from "@tmhcc/global-services";
import { AppConfig } from "@tmhcc/models";
import { Observable } from "rxjs/internal/Observable";
import { HttpOptions, BaseHttpService } from "@tmhcc/framwork/http";
import { environment } from 'apps/policy-admin/src/environments/environment';
import * as CryptoJS from 'crypto-js';

export abstract class BaseDataService extends BaseHttpService {
  protected config: AppConfig;
  protected serviceBaseUrl: string;
  protected dwldServiceBaseUrl: string;
  protected policyDownloadServiceBaseUrl: string;

  //NextWave  
  protected nextWaveServiceBaseUrl: string;  
  protected nwPolicyDownloadServiceBaseUrl: string;
  //TTB
  protected ttbServiceBaseUrl: string;  
  protected ttbPolicyDownloadServiceBaseUrl: string;
  protected ttbServiceApiKey: string;

  //Thimble
  protected thimbleServiceBaseUrl: string;    
  protected thimbleServiceAPIKey: string;

  //Kidnap & Ransom
  protected knrServiceBaseUrl : string;  
  protected knrServiceAPIKey : string;
  protected _key: any;
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService
  ) {
    super(http);
    this._key = JSON.parse((CryptoJS.AES.decrypt(environment.apiConfig.misc, environment.en_key._k).toString(CryptoJS.enc.Utf8)));
    this.config = environment.appConfig;
    this.serviceBaseUrl = environment.appConfig.dataServiceBaseUrl;
    this.dwldServiceBaseUrl = this._key.policyListServiceBaseUrl;
    this.policyDownloadServiceBaseUrl = this._key.policyDownloadServiceBaseUrl;

    this.nextWaveServiceBaseUrl = this._key.nextWaveServiceBaseUrl;
    this.nwPolicyDownloadServiceBaseUrl = this._key.nextWaveDownloadServiceBaseUrl;
    this.ttbServiceBaseUrl = this._key.ttbServiceBaseUrl;
    this.ttbPolicyDownloadServiceBaseUrl = this._key.ttbDownloadServiceBaseUrl;
    this.ttbServiceApiKey = this._key.ttbServiceAPIKey;

    //Thimble config
    this.thimbleServiceBaseUrl = this._key.thimbleServiceBaseUrl;    
    this.thimbleServiceAPIKey = this._key.thimbleServiceAPIKey;

    //Kidnap & Ransom
    this.knrServiceBaseUrl = this._key.knrServiceBaseUrl;    
    this.knrServiceAPIKey = this._key.knrServiceAPIKey;
  }

  protected get<T>(url: string, options?: HttpOptions): Observable<T> {
    if (!options) options = {};
    options.headers = this.appendDefaultHeaders(options.headers);

    return this.http.get<T>(url, options);
  }

  protected post<T>(url: string, options?: HttpOptions): Observable<T> {
    if (!options) options = {};
    options.headers = this.appendDefaultHeaders(options.headers);

    return this.http.post<T>(url, options.body, {
      params: options.params,
      headers: options.headers
    });
  }

  protected put<T>(url: string, options?: HttpOptions): Observable<T> {
    if (!options) options = {};
    options.headers = this.appendDefaultHeaders(options.headers);

    return this.http.put<T>(url, options.body, {
      params: options.params,
      headers: options.headers
    });
  }

  private appendDefaultHeaders(headers?: HttpHeaders): HttpHeaders {
    if (!headers) {
      headers = new HttpHeaders();
    }

    return headers
      .set("x-api-key", this._key.dataServiceApiKey)
      .set("content-type", "application/json");
  }

  protected postv1<T>(url: string, options?: HttpOptions, serviceapikey?: string): Observable<T> {
    if (!options) options = {};
    options.headers = this.appendDwldHeaders(options.headers,serviceapikey);
    return this.http.post<T>(url, options.body, {
      params: options.params,
      headers: options.headers
    });
  }

  protected postv2<T>(url: string, options?: HttpOptions) {
    if (!options) options = {};
    options.headers = this.appendDwldHeaders(options.headers);
    return this.http.post<any>(url, options.body, {
      params: options.params,
      headers: options.headers
    }).toPromise();
  }

  private appendDwldHeaders(headers?: HttpHeaders,serviceapikey? : string): HttpHeaders {
    if (!headers) {
      headers = new HttpHeaders();
    }
    
    return headers
      .set("x-api-key", serviceapikey)
      .set("Content-Type", "application/json");      
   }
}
