import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { SearchPolicyComponent } from "./search-policy.component";
import { InputTextModule } from "@tmhcc/framwork/form-inputs";

@NgModule({
  declarations: [SearchPolicyComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BsDatepickerModule,
    InputTextModule,
    FormsModule
  ],
  exports: [SearchPolicyComponent]
})
export class SearchPolicyModule {}
