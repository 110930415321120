import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
//import { DashoardStoreService } from "./store/facade/dashbard-store.service";
import {  
  KnrPolicySearchRequest
} from "@tmhcc/data-service";
import { KnrDashoardStoreService } from "./knr-store/facade/knr-dashbard-store.service";
import { DateService } from "@tmhcc/framwork/services";
@Injectable()
export class KnrDashboardResolver implements Resolve<any> {
  constructor(private knrdashoardStoreService: KnrDashoardStoreService,    
    private dateService: DateService) {}

  resolve() { 
   
    const payload : KnrPolicySearchRequest = {
      quoteNumber: "",
      policyNumber: "",
      insuredName: "",
      isSortAscending: true,
      sortBy: "",
      page: 0,
      pageSize: 100
   };
    //debugger;    
    this.knrdashoardStoreService.getKnrPolicyData(payload);
    return true;
  }
}
