import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { OktaAuthService } from "@okta/okta-angular";
import { EcoMasterData, EcoMasterRequest } from "@tmhcc/data-service";
import { AllowedRoute } from "./shared/page-header/page-header-navigation/page-header-navigation-def";
import { Observable, Subject } from "rxjs";
import { LoaderService } from "@tmhcc/framwork/loader/src";

@Injectable()
export class PreloadMasterDataGuard implements CanActivate {
  allowNavigation$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private ecoMasterData: EcoMasterData,
    private router: Router,
    private loader: LoaderService
  ) {}

  payload: EcoMasterRequest = {
    SubCompanyCode: "ECO"
  };

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    this.loader.show();
    this.ecoMasterData.fetchMasterDataAsync(this.payload).subscribe(
      () => {
        this.loader.hide();
        return this.allowNavigation$.next(true);
      },
      () => {
        this.loader.hide();
        return this.allowNavigation$.next(false);
      }
    );
    return this.allowNavigation$;
  }
}
