<div class="conatiner">
  <div class="row md-3">
    <div class="col-md-12">
      <h4
        class="header-tab mt-2 justify-content-between align-items-center d-flex"
      >
        <span *ngIf="tableLabel">{{ tableLabel }}</span>
        <span class="d-flex" *ngIf="GridSetting.addRow">
          <a
            href="javascript:void(0);"
            (click)="addRowInForm()"
            class="delete-link ml-3"
            ><i class="far fa-plus-square"></i> Add Reserve</a
          >
        </span>
      </h4>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <form [formGroup]="parentForm.form" (submit)="saveData()">
        <table
          class="table table-striped table-bordered mb-3"
          datatable
          [dtOptions]="dtOptions"
        >
          <thead>
            <tr>
              <th *ngFor="let columns of GridDefinition.Columns">
                {{ columns.fieldInfo.label }}
              </th>
              <th *ngIf="GridSetting.deleteRow" class="pr-2 text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody class="table-body" formArrayName="tableRows">
            <tr
              id="table-row-{{ i }}"
              [formGroupName]="i"
              *ngFor="
                let gridControl of parentForm.form.get('tableRows')['controls'];
                let i = index
              "
            >
              <td *ngFor="let columns of GridDefinition.Columns">
                <hcc-input-generator
                  [frmGroup]="gridControl"
                  [fieldInfo]="columns.fieldInfo"
                  [control]="columns.fieldInfo.fieldName"
                  [InputConfig]="columns.config"
                ></hcc-input-generator>
              </td>
              <td *ngIf="GridSetting.deleteRow">
                <a href="javascript:void(0);" class="delete-link">
                  <i
                    class="fas fa-trash-alt"
                    (click)="deleteIndividualRow(i)"
                    aria-hidden="true"
                  ></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <div class="button-row">
          <button type="button" class="btn btn-secondary">Cancel</button>
          <button type="button" class="btn btn-secondary">Back</button>
          <button type="submit" class="btn btn-primary">
            Save &amp; Next
          </button>
        </div> -->
      </form>
    </div>
  </div>
  <hr />
</div>
