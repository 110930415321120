import { Component, OnInit } from "@angular/core";
import { BreadcrumbProgressControl } from "@tmhcc/framwork/breadcrumb-progress";
import { CommonContentService } from "@tmhcc/content-service";
import { Router, Route, ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { PageHeaderService } from "./page-header.service";
import { AllowedRoute } from "./page-header-navigation/page-header-navigation-def";
import { QuotePolicyStepStore } from "../../quote/store/state/quote-policy-step.state";
import { take } from "rxjs/operators";
import { QuoteStepStoreService } from "../../quote/store/facade/quote-step-store.service";
import { PolicyTxnData } from "@tmhcc/models";
@Component({
  selector: "ec-page-header",
  templateUrl: "./page-header.component.html",
  styleUrls: ["./page-header.component.scss"]
})
export class PageHeaderComponent implements OnInit {
  breadCrumb1: BreadcrumbProgressControl;
  breadCrumb2: BreadcrumbProgressControl;
  valueSub = new Subscription();
  routersPath: string;
  url: string;
  backwardRoute: string;
  isPolicyBind: boolean;
  quotePolicyStepStore: QuotePolicyStepStore;
  policyTxnStoreData: PolicyTxnData;
  sub: Subscription;
  page: number;
  constructor(
    private commonContentService: CommonContentService,
    private pageHeaderService: PageHeaderService,
    private router: Router,
    private route: ActivatedRoute,
    private quoteStepStoreService: QuoteStepStoreService
  ) {
    this.routersPath = router.url;
  }

  ngOnInit() {
    this.sub = this.route.queryParams.subscribe(params => {
      this.page = +params["q"] || 0;
    });
    this.quoteStepStoreService.data$
      .pipe(take(1))
      .subscribe((data: QuotePolicyStepStore) => {
        this.quotePolicyStepStore = data;
        if (
          this.quotePolicyStepStore &&
          this.quotePolicyStepStore.policyTxnData
        ) {
          this.policyTxnStoreData = this.quotePolicyStepStore.policyTxnData;
          if (
            (this.policyTxnStoreData.ProcessStatusCode === "*" ||
              this.policyTxnStoreData.ProcessStatusCode === "I") &&
            this.page != 1
          ) {
            this.isPolicyBind = true;
            this.pageHeaderService.setBindBadageCounter(3);
          } else {
            this.pageHeaderService.setBindBadageCounter(0);
          }
        }
      });
    this.breadCrumb2 = new BreadcrumbProgressControl();
    this.breadCrumb2.titles = [
      this.commonContentService.getLabels().stepFirstLbl,
      this.commonContentService.getLabels().stepSecondLbl,
      this.commonContentService.getLabels().stepThirdLbl,
      this.commonContentService.getLabels().stepLastLbl
    ];
    this.breadCrumb2.progressIndex = 0;
    this.updateProgressIndex();
    this.updateStepNavigation();
  }

  updateStepNavigation() {
    let disabledBadges = [];
    this.valueSub.add(
      this.pageHeaderService.getBadgeCount.subscribe(badgeCount => {
        this.breadCrumb2.progressIndex = badgeCount;
        if (badgeCount === 0) {
          disabledBadges = [false, true, true, true];
        } else if (badgeCount === 1) {
          disabledBadges = [false, false, true, true];
        } else if (badgeCount === 2) {
          disabledBadges = [false, false, false, true];
        } else {
          disabledBadges = [false, false, false, false];
        }
        this.breadCrumb2.isDisabled = disabledBadges;
      })
    );
  }

  navigate(entity) {
    this.updatePath(entity.clickedIndex);
    this.router.navigate([this.url]);
  }

  updatePath(progressIndex) {
    if (progressIndex === 0) {
      this.url = AllowedRoute.GeneralInfo;
    } else if (progressIndex === 1) {
      this.url = AllowedRoute.Events;
    } else if (progressIndex === 2) {
      this.url = AllowedRoute.Quote;
    } else if (progressIndex === 3) {
      this.url = AllowedRoute.Bind;
    } else {
      this.url = AllowedRoute.Dashboard;
    }
  }

  updateProgressIndex() {
    const path = this.router.url;
    if (path.includes(AllowedRoute.GeneralInfo)) {
      this.breadCrumb2.progressIndex = 0;
    } else if (path === AllowedRoute.Events) {
      this.breadCrumb2.progressIndex = 1;
    } else if (path === AllowedRoute.Quote) {
      this.breadCrumb2.progressIndex = 2;
    } else if (
      path === AllowedRoute.Bind ||
      path === AllowedRoute.Summary ||
      path === AllowedRoute.Forms
    ) {
      this.breadCrumb2.progressIndex = 3;
    } else if (
      path === AllowedRoute.Subjectivity ||
      path === AllowedRoute.Document
    ) {
      this.pageHeaderService.getBadgeCount.subscribe(badgeCount => {
        this.breadCrumb2.progressIndex = badgeCount;
      });
    } else {
      this.breadCrumb2.progressIndex = 0;
    }
    this.pageHeaderService.setBadgeCounter(this.breadCrumb2.progressIndex);
  }

  backBtn() {
    const path = this.router.url;
    if (path.includes(AllowedRoute.GeneralInfo)) {
      this.backwardRoute = AllowedRoute.Dashboard;
    } else if (path === AllowedRoute.Events) {
      this.backwardRoute = AllowedRoute.GeneralInfo;
    } else if (path === AllowedRoute.Quote) {
      this.backwardRoute = AllowedRoute.Events;
    } else if (
      path === AllowedRoute.Bind ||
      path === AllowedRoute.Summary ||
      path === AllowedRoute.Forms
    ) {
      this.backwardRoute = AllowedRoute.Quote;
    } else if (
      path === AllowedRoute.Subjectivity ||
      path === AllowedRoute.Document
    ) {
      if (this.breadCrumb2.progressIndex === 2) {
        this.backwardRoute = AllowedRoute.Events;
      } else if (this.breadCrumb2.progressIndex === 3) {
        this.backwardRoute = AllowedRoute.Summary;
      }
    } else {
      this.backwardRoute = AllowedRoute.Dashboard;
    }
    this.router.navigate([this.backwardRoute]);
  }
}
