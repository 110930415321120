<div class="container"> 
  <div class="row">
    <div class="col-md-12 ">     
      <div class="clearfix"></div>
      <div class="row">
        <div class="col-12 col-md-12 mb-3 mt-3">
          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade show active"
              id="subjectivities"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <div class="form-row mt-4 mb-2">
                <div class="col-md-8 col-lg-4">
                  <h5 class="header-tab">
                    {{ getLabels.subjectivityHeadingLbl }} :
                  </h5>
                </div>
                <div class="col-md-12 col-lg-6 mb-3 text-right">
                  <button
                    [disabled]="disableAddEditSub"
                    class="btn btn-secondary float-right"
                    (click)="openNewSubjectivityDialog()"
                  >
                    {{ getLabels.addEditSubjectivityLbl }}
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-md-10 subjectivities-table">
                  <div *ngIf="records.length > 0">
                    <ac-grid-table
                      (childEventForChecked)="onChecked($event)"
                      [gridSetting]="gridSetting"
                      [records]="records"
                      [parentDivName]="'listTable'"
                      [columnSettings]="columnSettings"
                      [isCustomizedGrid]="true"
                      [isDeleteOption]="true"
                      (eventForStandardGrid)="onChecked($event)"   
                      [selectedRecords]="records"                                   
                    ></ac-grid-table>
                  </div>
                  <div *ngIf="records.length <= 0">
                    <ac-grid-table                     
                      [gridSetting]="gridSetting"
                      [records]="records"
                      [parentDivName]="'listTable'"
                      [columnSettings]="columnSettings"
                      [isCustomizedGrid]="true"
                      [isDeleteOption]="true"                      
                    ></ac-grid-table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="divider-dark" />
      <div class="button-row">
        <button type="button" (click)="onBackClicked()" class="btn btn-primary">
          Back
        </button>
        <button type="button" (click)="onSaveClicked()" class="btn btn-primary">
          SAVE & REFRESH
        </button>
        <button type="button" (click)="onSaveSubjectivity()" class="btn btn-primary disabled">
          Next
        </button>
      </div>
    </div>
  </div>
</div>
