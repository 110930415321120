<div class="container">
  <ec-page-header></ec-page-header>
  <div class="row">
    <div class="col-md-12 ">
      <ec-quote-policy-info></ec-quote-policy-info>
      <ec-page-tab></ec-page-tab>
      <div class="clearfix"></div>
      <div class="row">
        <div class="col-12 col-md-12 mb-3 mt-3">
          <div class="tab-content" id="nav-tabContent">
            <div
              class="tab-pane fade show active"
              id="document"
              role="tabpanel"
              aria-labelledby="nav-profile-tab"
            >
              <div class="form-row mt-4 mb-2">
                <div class="col-md-8 col-lg-4">
                  <h5 class="header-tab">
                    <span>{{
                      showBoxFolder
                        ? "Click below to open documents."
                        : "No document found."
                    }}</span>
                  </h5>
                </div>
              </div>
              <div class="row">
                <div class="form-row mt-4 mb-4">
                  <div *ngIf="showBoxFolder">
                    <div class="col-md-7 col-lg-12">
                      <button
                        (click)="openBoxView()"
                        class="btn btn-secondary float-left"
                        data-toggle="modal"
                        data-target="#addForms"
                      >
                        VIEW BOX FOLDER
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="divider-dark" />
      <div class="button-row">
        <button type="button" (click)="back()" class="btn btn-primary">
          Back
        </button>

        <button type="button" (click)="next()" class="btn btn-primary">
          Next
        </button>
      </div>
    </div>
  </div>
</div>
