<form [formGroup]="policySummary">
  <div class="card bg-light quote-summary">
    <div class="card-body">
      <h5 class="header-tab text-center mb-3">
        {{ getLabelsName.policySummary }}
      </h5>
      <div class="form-group row">
        <label for="" class="col-md-6"
          ><strong>{{ getLabelsName.policyNumber }}:</strong></label
        >
        <div class="col-md-6" *ngIf="!isPolicyBound">TBD</div>
        <div class="col-md-6" *ngIf="isPolicyBound">{{ policyNumber }}</div>
      </div>
      <div class="form-group row">
        <label for="" class="col-md-6"
          ><strong>{{ getLabelsName.ecoProductTypeLbl }}:</strong></label
        >
        <div class="col-md-6">{{ policyType }}</div>
      </div>
      <div class="form-group row">
        <label for="" class="col-md-6"
          ><strong>{{ getLabelsName.limitOfInsurance }}</strong></label
        >
        <div class="col-md-6">{{ LimitOfInsurance | currency: "USD" }}</div>
      </div>
      <div class="form-group row">
        <label for="" class="col-md-6"
          ><strong>{{ getLabelsName.premium }}:</strong></label
        >
        <div class="col-md-6">
          <span *ngIf="showpremimuAmount">{{
            showpremimuAmount | currency: "USD"
          }}</span>
        </div>
      </div>
      <div class="form-group row" *ngFor="let taxesandFees of taxesAndFees">
        <label for="" class="col-md-6"
          ><strong>{{ taxesandFees.TaxType }}:</strong></label
        >
        <div class="col-md-6">
          {{ taxesandFees.WrittenAmount | currency: "USD" }}
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="col-md-6 col-form-label"
          ><strong>{{ getLabelsName.commissionsmall }}</strong></label
        >
        <div class="col-md-6">
          <hcc-text-input
            [control]="ProducerCommissions.fieldName"
            [frmGroup]="policySummary"
            [fieldInfo]="ProducerCommissions"
            (blurEvent)="onChangeProducerCommissions($event)"
          >
          </hcc-text-input>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="col-md-6 col-form-label"
          ><strong>{{ getLabelsName.policyEffLabl }}</strong></label
        >
        <div class="col-md-6">
          <div class="input-group date-field col-md-12 nopadding" id="">
            <hcc-datepicker-input
              [control]="policyEff.fieldInfo.fieldName"
              [frmGroup]="policySummary"
              [fieldInfo]="policyEff.fieldInfo"
              [InputConfig]="policyEff.config"
            >
            </hcc-datepicker-input>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="col-md-6 col-form-label"
          ><strong>{{ getLabelsName.policyExpLbl }}</strong></label
        >
        <div class="col-md-6">
          <div class="input-group date-field col-md-12 nopadding" id="">
            <hcc-datepicker-input
              [control]="policyExp.fieldInfo.fieldName"
              [frmGroup]="policySummary"
              [fieldInfo]="policyExp.fieldInfo"
              [InputConfig]="policyExp.config"
            >
            </hcc-datepicker-input>
          </div>
        </div>
      </div>
      <h5 class="sub-header mb-2 mt-4">{{ getLabelsName.endorsement }}</h5>
      <div class="form-group row">
        <label for="" class="col-md-6 col-form-label pt-0"
          ><strong>{{
            getLabelsName.changewithRespecttoCalculationofLoss
          }}</strong></label
        >
        <div class="col-md-6">
          <hcc-select
            [control]="endorsementsLossSelectConfig.fieldInfo.fieldName"
            [frmGroup]="policySummary"
            [fieldInfo]="endorsementsLossSelectConfig.fieldInfo"
            [InputConfig]="endorsementsLossSelectConfig.config"
          >
          </hcc-select>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="col-md-6 col-form-label pt-0"
          ><strong>{{ getLabelsName.firstLossLimitEndorsement }}</strong></label
        >
        <div class="col-md-6">
          <hcc-select
            [control]="endorsementsLimitSelectConfig.fieldInfo.fieldName"
            [frmGroup]="policySummary"
            [fieldInfo]="endorsementsLimitSelectConfig.fieldInfo"
            [InputConfig]="endorsementsLimitSelectConfig.config"
          >
          </hcc-select>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="col-md-12 col-form-label">
          <h5 class="sub-header mt-3 mb-0 pb-0">
            {{ getLabelsName.carrierName }}
          </h5>
        </label>
        <div class="col-md-12">
          <hcc-select
            [control]="FrSelectConfig.fieldInfo.fieldName"
            [frmGroup]="policySummary"
            [fieldInfo]="FrSelectConfig.fieldInfo"
            [InputConfig]="FrSelectConfig.config"
          >
          </hcc-select>
        </div>
      </div>
    </div>
  </div>
</form>
