import {
  Directive,
  forwardRef,
  HostBinding,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
  Attribute,
  HostListener,
  OnInit
} from "@angular/core";

import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { TextFormElement } from "../text/text-form-element";

@Directive({
  selector: "input[acRadiobutton]",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadiobuttonDirective),
      multi: true
    }
  ]
})
export class RadiobuttonDirective extends TextFormElement {
  formClass = false;
  maring = true;

  @HostListener("change", ["$event"])
  onchange(event) {
    this.change(event.target.value);
  }

  constructor(
    protected renderer: Renderer2,
    protected el: ElementRef,
    protected csRef: ChangeDetectorRef,
    @Attribute("updateEvent") protected updateEvent
  ) {
    super(renderer, el, csRef, updateEvent);
  }
}
