import { Injectable } from "@angular/core";
import * as _ from "../eco/assets/smartystreets-sdk-1.4.4.js";
import { AppConfigService } from '@tmhcc/global-services';
import { environment } from 'apps/policy-admin/src/environments/environment';

@Injectable({
  providedIn: "root"
})
export class SmartyStreetSdkService {
  constructor(protected configService: AppConfigService) {
    
  }

  AutocompleteAddress(term: string): any {
    const SmartyStreetsCore = _.core;
    const websiteKey = environment.appConfig.smartStreetAPIKey; // Website Key
    const Lookup = _.usAutocomplete.Lookup;
    const credentials = new SmartyStreetsCore.SharedCredentials(websiteKey);
    const clientBuilder = new SmartyStreetsCore.ClientBuilder(credentials);
    const client = clientBuilder.buildUsAutocompleteClient();
    const lookup = new Lookup(term);
    return client.send(lookup);
  }
  StreetAddress(
    term: string,
    street2: string,
    secondary: string,
    city: string,
    state: string
  ): any {
    const SmartyStreetsCore = _.core;
    const websiteKey =environment.appConfig.smartStreetAPIKey; // Website Key
    const Lookup = _.usStreet.Lookup;
    const credentials = new SmartyStreetsCore.SharedCredentials(websiteKey);
    const clientBuilder = new SmartyStreetsCore.ClientBuilder(credentials);
    const client = clientBuilder.buildUsStreetApiClient();
    const lookup = new Lookup(term, street2, secondary, city, state);
    return client.send(lookup);
  }
}
