import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import { DialogService, DialogConfig } from "@tmhcc/framwork/dialog";
import { Router } from "@angular/router";
import { CommonContentService } from "@tmhcc/content-service";
import { FormsDialogComponent } from "./forms-dialog/forms-dialog.component";
import {
  FormsDialogConfigData,
  FormsDialogConfig
} from "./forms-dialog/forms-dialog.config";
import { ColumnDefinition, Types, GridOptions } from "@tmhcc/framwork/grid";
import {
  FormsECOService,
  UWQuotes,
  EndorsmentFormsRequest,
  EndorsementForms,
  AddOptionalEndorsementFormsECO,
  OptionalEndorsementForms,
  CreateActivityLogItem,
  EcoMasterData,
  ApproveQuoteRequest
} from "@tmhcc/data-service";
import { QuoteStepStoreService } from "../store/facade/quote-step-store.service";
import { QuotePolicyStepStore } from "../store/state/quote-policy-step.state";
import { LoaderService } from "@tmhcc/framwork/loader/src";
import {
  DialogContainerComponent,
  SaveChangeGaurd
} from "@tmhcc/global-services";
import { FieldInfo, FieldTypes } from "@tmhcc/framwork/form-inputs/src";
import { FormBuilder, FormGroup } from "@angular/forms";
import { DateService } from "@tmhcc/framwork/services";
import {
  ElementUpdateRequestObj,
  EcoMaster,
  PolicyTxnRequest
} from "@tmhcc/models";
import { Subscription } from "rxjs";
import { QuotePolicyTxn } from '../store/state/quote-policy-step.action';
import { Store } from '@ngxs/store';
import { take } from 'rxjs/operators';

@Component({
  selector: "ec-forms",
  templateUrl: "./forms.component.html",
  styleUrls: ["./forms.component.scss"]
})
export class FormsComponent extends FormsDialogConfig
  implements OnInit, OnDestroy {
  tableSettingsPolicyforms: ColumnDefinition[];
  recordsList: any;
  gridSetting: GridOptions;
  getLabels: any;
  quotePolicyStepStore: QuotePolicyStepStore;
  currentUWQuoteID: any;
  optionalFormList: any[];
  selectedRecords: [];
  SelcetedFormValue: EndorsementForms[];
  endoseformgroup: FormGroup;
  showTable: boolean;
  isPolicyBind: boolean;
  policyMasterData: EcoMaster;
  quoteStepServiceInstance: Subscription;
  constructor(
    private dialogService: DialogService,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialogConfig: DialogConfig<FormsDialogConfigData>,
    public contentService: CommonContentService,
    public formsECOService: FormsECOService,
    public quoteStepStoreService: QuoteStepStoreService,
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef,
    private saveChangeGaurd: SaveChangeGaurd,
    private dateService: DateService,
    private ecoMasterData: EcoMasterData,
    private store: Store,
  ) {
    super(contentService);
    this.getLabels = this.contentService.getLabels();
  }

  ngOnInit(): void {
    this.gridSetting = {
      searching: false,
      paging: false,
      ordering: false,
      responsive: true,
      emptyTable: this.getLabels.emptyTableMsg
    };
    this.initFormGroup();
    this.policyMasterData = this.ecoMasterData.getMasterData();
    this.columnNameMapping();
    this.recordsList = [];
    this.getPolicyEcoSubscribeData();
    this.saveChangeGaurd.setFormGroup(this.endoseformgroup);
    
  }
  initFormGroup() {
    const controls = {};
    controls[this.fieldcommentInfo.fieldName] = [""];
    this.endoseformgroup = this.formBuilder.group(controls);
  }
  getPolicyEcoSubscribeData() {
    this.quoteStepServiceInstance = this.quoteStepStoreService.data$.subscribe(
      (data: QuotePolicyStepStore) => {
        if (data && data.policyTxnData) {
          this.quotePolicyStepStore = data;
          if (
            this.quotePolicyStepStore &&
            this.quotePolicyStepStore.policyTxnData
          ) {
            if (
              this.quotePolicyStepStore.policyTxnData.ProcessStatusCode ==
                "*" ||
              this.quotePolicyStepStore.policyTxnData.ProcessStatusCode == "I"
            ) {
              this.isPolicyBind = true;
            }
            const policyTxnId = this.quotePolicyStepStore.policyTxnData
              .PolicyTxnId;
            const UWQuotesInstance = this.quotePolicyStepStore.policyTxnData
              .UWQuotes;
            if (UWQuotesInstance && UWQuotesInstance.length !== 0) {
              this.updateUWQuoteProperties(
                UWQuotesInstance[UWQuotesInstance.length - 1]
              );
            }
            const uwQuoteId = this.currentUWQuoteID;
            this.recordsList=[];
            this.getEndorseMentForms(policyTxnId, uwQuoteId);
            this.getOptionaLEndorsmentForms(policyTxnId, uwQuoteId);
            this.showNoteValues();
          }
        }
        else{
          this.router.navigate(['/dashboard']);
        }
      }
    );
  }
  showNoteValues(){
    const policydataelement=this.quotePolicyStepStore.policyTxnData.policyDataElements;
    if(policydataelement){
      const dataElements = this.policyMasterData.UserConfigurableDataElements.filter(
        items => items.ElementCode === "NOTES"
      );
      policydataelement.ElementUpdateRequests.forEach(element => {
        if(element.ElementId==dataElements[0].ElementId && element.ElementName==dataElements[0].ElementName){
          this.endoseformgroup.get("comment").setValue(element.FreeTextValue);
        }
      });
    }
  }
  updateUWQuoteProperties(uWQuotesInstance: UWQuotes) {
    const SelectedUwQuoteId = sessionStorage.selectedUwQuoteId;
    if (SelectedUwQuoteId) {
      this.currentUWQuoteID = SelectedUwQuoteId;
    } else {
      this.currentUWQuoteID = uWQuotesInstance.UWQuoteId;
    }
  }
  getEndorseMentForms(policyTxnId, uwQuoteId) {
    this.loaderService.show();
    if (policyTxnId && uwQuoteId) {
      const policyFormsRequest: EndorsmentFormsRequest = {
        PolicyTxnId: policyTxnId,
        UWQuoteID: uwQuoteId
      };
      this.formsECOService
        .getECOEndorsmentForms(policyFormsRequest)
        .subscribe(data => {
          if (data) {
            this.mapGridValues(data);
            this.loaderService.hide();
          }
        });
    }
  }
  EcoCalculateQuote(routeToNext?: boolean){
    const formCalculateRequest = this.formAprroveRequest();
    this.formsECOService.saveCalculateQuote(formCalculateRequest).subscribe(data => {
      if (data) {
        this.EcoApproveQuote(routeToNext);
      }
    });
  }
  formAprroveRequest():ApproveQuoteRequest{
    let approveQuoteRequest={
      PolicyTxnId: this.quotePolicyStepStore.policyTxnData.PolicyTxnId
        ? Number(this.quotePolicyStepStore.policyTxnData.PolicyTxnId)
        : null,
      UWQuoteId: this.currentUWQuoteID,
    }
    return approveQuoteRequest;
  }
  EcoApproveQuote(routeToNext?: boolean){
    const formApproveRequest = this.formAprroveRequest();
    this.formsECOService.saveApproveQuote(formApproveRequest).subscribe(data => {
      if (data) {
        this.recordsList = [];
        this.loaderService.hide();
        this.cdr.detectChanges();
        const policyTxnId = this.quotePolicyStepStore.policyTxnData.PolicyTxnId;
        const uwQuoteId = this.currentUWQuoteID;
        this.endoseformgroup.markAsPristine();
        const getPolicyTxnRequest: PolicyTxnRequest = {
          PolicyTxnID: policyTxnId
        };
        this.loaderService.show();
        this.store
          .dispatch(new QuotePolicyTxn(getPolicyTxnRequest))
          .pipe(take(1))
          .subscribe(
            () => {
              this.loaderService.hide();
              if (routeToNext) {
                this.router.navigate(["/bind-summary"]);
              }
            },
            () => {
              this.loaderService.hide();
            }
          );
        
      }
    });
  }
  getOptionaLEndorsmentForms(policyTxnId, uwQuoteId) {
    this.loaderService.show();
    if (policyTxnId && uwQuoteId) {
      const policyFormsRequest: EndorsmentFormsRequest = {
        PolicyTxnId: policyTxnId,
        UWQuoteID: uwQuoteId
      };
      this.formsECOService
        .getOptionalEndorsmentForms(policyFormsRequest)
        .subscribe(data => {
          if (data) {
            this.optionalFormList = data;
            this.loaderService.hide();
          }
        });
    }
  }
  mapGridValues(endormentFormsData) {
    this.showTable = false;
    if (endormentFormsData && endormentFormsData.length !== 0) {
      endormentFormsData.forEach((value: EndorsementForms) => {
        let rows: EndorsementForms = {
          FormName: value.FormName,
          FormCode: value.FormCode,
          AttachedAs: value.AttachedAs,
          PolicyFormId: value.PolicyFormId,
          IsCustomEntry: value.IsCustomEntry,
          IsChanged: value.IsChanged,
          UWFormId: value.UWFormId ? value.UWFormId : 0,
          IsAdded: value.IsAdded ? value.IsAdded : false,
          IsUpdated: false,
          IsDeleted: value.IsDeleted ? value.IsDeleted : false,
          CountryCode: value.CountryCode,
          Rated: value.Rated,
          AddDate: value.AddDate,
          EndorseSeqNum: value.EndorseSeqNum,
          ICMEndorseSeqNum: value.ICMEndorseSeqNum,
          AllowMultiples: value.AllowMultiples,
          Version: value.Version,
          FormEff: value.FormEff
        };
        this.cdr.detectChanges();
        this.recordsList.push(rows);
        this.cdr.detectChanges();
        this.showTable = true;
      });
    } else {
      this.showTable = true;
    }
  }
  deleteOptionalForm($event) {
    this.deleteConfirmOptionForms(
      "Are you sure you want to delete selected forms?",
      this.dialogModalSettingConfigForDelete,
      $event.PolicyFormId
    );
  }
  deleteConfirmOptionForms(text, config, deleteFormId) {
    let content = {
      value: text
    };
    this.selectedRecords = deleteFormId;
    this.dialogConfig.modalSetting = config;
    this.dialogConfig.data = content;
    const ref = this.dialogService.open(
      DialogContainerComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {
      if (result) {
      } else {
        this.recordsList.forEach((element, i) => {
          if (element.PolicyFormId == this.selectedRecords) {
            this.endoseformgroup.markAsDirty();
            element.IsUpdated = false;
            element.IsDeleted = true;
            element.IsAdded = false;
            //this.recordsList.splice(i,1);
            this.cdr.detectChanges();
          }
        });
      }
    });
  }
  optionalEndorsementForms(): OptionalEndorsementForms[] {
    const optionalEndorsementForms: OptionalEndorsementForms[] = [];
    this.recordsList.forEach(element => {
      if (element.AttachedAs !== "Mandatory") {
      }
      const forms: OptionalEndorsementForms = {
        PolicyFormId: element.PolicyFormId,
        FormCode: element.FormCode,
        FormName: element.FormName,
        IsCustomEntry: element.IsCustomEntry,
        IsChanged: element.IsChanged,
        UWFormId: element.UWFormId ? element.UWFormId : 0,
        IsAdded: element.UWFormId != 0 ? false : true,
        IsUpdated:
          element.UWFormId != 0 && element.IsDeleted == false ? true : false,
        IsDeleted: element.IsDeleted ? true : false,
        CountryCode: element.CountryCode,
        Rated: element.Rated,
        AddDate: element.AddDate,
        EndorseSeqNum: element.EndorseSeqNum,
        ICMEndorseSeqNum: element.ICMEndorseSeqNum,
        AllowMultiples: element.AllowMultiples,
        Version: element.Version,
        FormEff: element.FormEff
      };
      optionalEndorsementForms.push(forms);
    });
    return optionalEndorsementForms;
  }
  prepareFormsSaveRequest(): AddOptionalEndorsementFormsECO {
    let addOptionalEndorsementFormsECO: AddOptionalEndorsementFormsECO;
    addOptionalEndorsementFormsECO = {
      PolicyTxnId: this.quotePolicyStepStore.policyTxnData.PolicyTxnId
        ? Number(this.quotePolicyStepStore.policyTxnData.PolicyTxnId)
        : null,
      IsMultiRisk: false,
      UWQuoteId: this.currentUWQuoteID,
      OptionalEndorsementForms: this.optionalEndorsementForms(),
      CreateActivityLogItem: this.getCreateActivityLogItemRequest(),
      PolicyDataElements: {
        ElementUpdateRequests: this.getPolicyDataElement()
      }
    };
    return addOptionalEndorsementFormsECO;
  }
  getPolicyDataElement(): ElementUpdateRequestObj[] {
    const policyDataElement: ElementUpdateRequestObj[] = [];
    const dataElements = this.policyMasterData.UserConfigurableDataElements.filter(
      items => items.ElementCode === "NOTES"
    );
    const policyelement: ElementUpdateRequestObj = {
      DataGroupId: dataElements[0].DataGroupId,
      DataGroupName: "ECO Filing Responsibility",
      IsArray: false,
      ElementId: dataElements[0].ElementId,
      ElementName: dataElements[0].ElementName,
      ResponseType: dataElements[0].ResponseType,
      FreeTextValue: this.endoseformgroup.get("comment").value,
      BooleanValue: false
    };
    policyDataElement.push(policyelement);
    return policyDataElement;
  }
  getCreateActivityLogItemRequest(): CreateActivityLogItem {
    let createActivityLogItem: CreateActivityLogItem;
    if (
      this.quotePolicyStepStore.policyTxnData &&
      this.quotePolicyStepStore.policyTxnData.CreateActivityLogItem
    ) {
      createActivityLogItem = {
        PolicyTxnId: Number(
          this.quotePolicyStepStore.policyTxnData.PolicyTxnId
        ),
        ActivityType:
          "StepENAIChange" ||
          this.quotePolicyStepStore.policyTxnData.CreateActivityLogItem
            .ActivityType,
        ActivityDate: this.dateService.now().toISOString(),
        Description:
          "saved forms details" ||
          this.quotePolicyStepStore.policyTxnData.CreateActivityLogItem
            .Description,
        Amount: this.quotePolicyStepStore.policyTxnData.CreateActivityLogItem
          .Amount
          ? this.quotePolicyStepStore.policyTxnData.CreateActivityLogItem.Amount
          : 0,
        LoggedInUser: this.quotePolicyStepStore.policyTxnData
          .CreateActivityLogItem.LoggedInUser
      };
    }
    return createActivityLogItem;
  }
  backButtonPressed() {
    this.router.navigate(["/quote"]);
  }
  saveAndRefresh() {
    if (this.isPolicyBind) {
      this.router.navigate(["/bind-summary"]);
    } else {
      this.saveForms(false);
    }
  }
  saveAndNext() {
    if (!this.isPolicyBind) {
      this.saveForms(true);
    }
  }
  saveForms(routeToNext?: boolean) {
    this.loaderService.show();
    const formSaveRequest = this.prepareFormsSaveRequest();
    this.formsECOService.savePolicyForms(formSaveRequest).subscribe(data => {
      if (data) {
        this.endoseformgroup.markAsPristine();
        this.EcoCalculateQuote(routeToNext);
        // this.recordsList = [];
        // this.loaderService.hide();
        // this.cdr.detectChanges();
        // const policyTxnId = this.quotePolicyStepStore.policyTxnData.PolicyTxnId;
        // const uwQuoteId = this.currentUWQuoteID;
        // this.endoseformgroup.markAsPristine();
        // const getPolicyTxnRequest: PolicyTxnRequest = {
        //   PolicyTxnID: policyTxnId
        // };
        // this.loaderService.show();
        // this.store
        //   .dispatch(new QuotePolicyTxn(getPolicyTxnRequest))
        //   .pipe(take(1))
        //   .subscribe(
        //     () => {
        //       this.loaderService.hide();
        //     },
        //     () => {
        //       this.loaderService.hide();
        //     }
        //   );
        // if (routeToNext) {
        //   this.router.navigate(["/bind-summary"]);
        // }
      }
    });
  }
  openFormsDialog() {
    this.dialogConfig.modalSetting = this.dialogModalSettingConfig;
    this.dialogConfig.data = {
      optionalForms: this.optionalFormList ? this.optionalFormList : null
    };
    const ref = this.dialogService.open(
      FormsDialogComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {
      if (result) {
        if (result.optionalFormVsalue) {
          this.endoseformgroup.markAsDirty();
          let endorsment: EndorsementForms[] = [];
          endorsment.push(result.optionalFormVsalue);
          this.mapGridValues(endorsment);
        }
      }
    });
  }
  columnNameMapping() {
    this.tableSettingsPolicyforms = [
      {
        fieldInfo: {
          fieldName: "FormName",
          label: this.getLabels.policyFormName
        },
        columnType: Types.string
      },
      {
        fieldInfo: {
          fieldName: "FormCode",
          label: this.getLabels.policyFormNumber
        },
        columnType: Types.string
      },
      {
        fieldInfo: {
          fieldName: "deleteForms",
          label: this.getLabels.policyFormDelete,
          optionalField: "icon"
        },
        columnType: Types.default
      }
    ];
  }
  fieldcommentInfo: FieldInfo = {
    fieldName: "comment",
    label: "Note:",
    fieldType: FieldTypes.Textarea,
    showLabel: true
  };

  ngOnDestroy(): void {
    if (this.quoteStepServiceInstance) {
      this.quoteStepServiceInstance.unsubscribe();
    }
  }
}
