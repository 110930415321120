import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CoreInputsModule } from "@tmhcc/framwork/core-inputs";
import { DialogModule, DialogConfig } from "@tmhcc/framwork/dialog";
import { QuoteStoreService } from "../store/facade/quote-store.service";
import { GridModule } from "@tmhcc/framwork/grid";
import { PageHeaderModule } from "../../shared/page-header/page-header.module";
import { QuotePolicyInfoModule } from "../../shared/quote-policy-info/quote-policy-info.module";
import { NgxsModule } from "@ngxs/store";
import {
  QuoteCreateBussinessService,
  SaveEventService,
  QuoteStepService
} from "@tmhcc/data-service";
import { QuoteState } from "../store/state/quote.state";
import { QuotePolicyStepState } from "../store/state/quote-policy-step.state";
import { BindSummaryComponent } from "./bind-summary.component";
import { UnsavedDataGaurd } from "../events/unsaved-data.guard";
import { PageTabInfoModule } from "../../shared/page-tab/page-tab-info.module";

const routes: Routes = [{ path: "", component: BindSummaryComponent }];

@NgModule({
  declarations: [BindSummaryComponent],
  imports: [
    RouterModule.forChild(routes),
    [NgxsModule.forFeature([QuoteState, QuotePolicyStepState])],
    ReactiveFormsModule,
    CoreInputsModule,
    CommonModule,
    DialogModule,
    GridModule,
    PageHeaderModule,
    QuotePolicyInfoModule,
    PageTabInfoModule
  ],
  providers: [
    DialogConfig,
    QuoteStoreService,
    UnsavedDataGaurd,
    QuoteCreateBussinessService,
    SaveEventService,
    QuoteStepService
  ],
  exports: [RouterModule]
})
export class BindSummaryModule {}
