import { Types, Controls } from "@tmhcc/framwork/grid";
import { CommonContentService } from "@tmhcc/content-service";
import { DialogSize } from "@tmhcc/framwork/dialog/src";
export class BindConfig {
  getLabels: any;
  hideHeaderX: any;
  constructor(public contentService: CommonContentService) {
    this.getLabels = this.contentService.getLabels();
  }

  gridSettingConfig = {
    pageLength: 10,
    searching: false,
    paging: true,
    ordering: false,
    emptyTable: this.contentService.getLabels().emptyTableMsg
  };

  standardColumnHeadingSettings = [
    {
      fieldInfo: {
        fieldName: "eventDescription",
        label: this.contentService.getLabels().eventName
      },
      columnType: Types.default
    },
    {
      fieldInfo: {
        fieldName: "option1",
        label: this.contentService.getLabels().standardPolicyOption1Label
      },
      columnType: Types.currency,
      columnEventType: Controls.radioCheckBoxMatrix,
      controlInfo: {
        controlName: "checkedOption1"
      }
    },
    {
      fieldInfo: {
        fieldName: "option2",
        label: this.contentService.getLabels().standardPolicyOption2Label
      },
      columnType: Types.currency,
      columnEventType: Controls.radioCheckBoxMatrix,
      controlInfo: {
        controlName: "checkedOption2"
      }
    },
    {
      fieldInfo: {
        fieldName: "option3",
        label: this.contentService.getLabels().standardPolicyOption3Label
      },
      columnType: Types.currency,
      columnEventType: Controls.radioCheckBoxMatrix,
      controlInfo: {
        controlName: "checkedOption3"
      }
    },
    {
      fieldInfo: {
        fieldName: "empty",
        label: ""
      },
      columnType: Types.empty
    },
    {
      fieldInfo: {
        fieldName: "option4",
        label: this.contentService.getLabels().standardAdditionalPerlsLabel,
        optionalField: "icon"
      },
      columnType: Types.currency,
      columnEventType: Controls.matrixCheckBox,
      controlInfo: {
        controlName: "checkedOption4"
      }
    }
  ];
  tradeShowColumnHeadingSettings = [
    {
      fieldInfo: {
        fieldName: "eventDescription",
        label: this.contentService.getLabels().eventName
      },
      columnType: Types.default
    },
    {
      fieldInfo: {
        fieldName: "option1",
        label: this.contentService.getLabels().tradeShowPolicyOption1Label,
        subLabel:this.contentService.getLabels().tradeShowPolicyOption1SubLabel
      },
      columnType: Types.currency,
      columnEventType: Controls.radioCheckBoxMatrix,
      controlInfo: {
        controlName: "checkedOption1"
      }
    },
    {
      fieldInfo: {
        fieldName: "option2",
        label: this.contentService.getLabels().tradeShowPolicyOption2Label,
        subLabel:this.contentService.getLabels().tradeShowPolicyOption2SubLabel
      },
      columnType: Types.currency,
      columnEventType: Controls.radioCheckBoxMatrix,
      controlInfo: {
        controlName: "checkedOption2"
      }
    },
    {
      fieldInfo: {
        fieldName: "option3",
        label: this.contentService.getLabels().tradeShowPolicyOption3Label,
        subLabel:this.contentService.getLabels().tradeShowPolicyOption3SubLabel
      },
      columnType: Types.currency,
      columnEventType: Controls.radioCheckBoxMatrix,
      controlInfo: {
        controlName: "checkedOption3"
      }
    },
    {
      fieldInfo: {
        fieldName: "option4",
        label: this.contentService.getLabels().tradeShowPolicyOption4Label,
        subLabel:this.contentService.getLabels().tradeShowPolicyOption4SubLabel
      },
      columnType: Types.currency,
      columnEventType: Controls.radioCheckBoxMatrix,
      controlInfo: {
        controlName: "checkedOption4"
      }
    },
    {
      fieldInfo: {
        fieldName: "empty",
        label: ""
      },
      columnType: Types.empty
    },
    {
      fieldInfo: {
        fieldName: "option5",
        optionalField: "icon",
        label: this.contentService.getLabels().tradeShowAdditionalPerlsLabel
      },
      columnType: Types.currency,
      columnEventType: Controls.matrixCheckBox,
      controlInfo: {
        controlName: "checkedOption5"
      }
    }
  ];
  dialogModalSetting = {
    title: "Invalid Record",
    hideHeader: false,
    hideFooter: true,
    hideSave: false,
    hideHeaderX: this.hideHeaderX,
    SaveButton: "Yes",
    CancelButton: "No",
    dialogSize: DialogSize.LG
  };
}
