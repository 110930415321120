import { Component, forwardRef, EventEmitter, Output } from "@angular/core";
import { InputBase } from "../input-base";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "hcc-text-input",
  templateUrl: "./text-input.component.html",
  styleUrls: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextInputComponent),
      multi: true
    }
  ]
})
export class TextInputComponent extends InputBase<string> {
  @Output() blurEvent = new EventEmitter();
  constructor() {
    super();
  }

  touchedInput(event) {
    this.blurEvent.emit(event.target.value);
  }
}
