import { FormGroup } from "@angular/forms";

export function EventDeductibleValidator(
  radioControl: string,
  valueControl: string
) {
  return (formGroup: FormGroup) => {
    const radioField = formGroup.controls[radioControl];
    const valueField = formGroup.controls[valueControl];
    if (!radioField.value && valueField.value) {
      valueField.setErrors({ radioSelection: true });
    } else if (valueField.value && !/^\d*\.?\d*$/.test(valueField.value)) {
      valueField.setErrors({ pattern: true });
    } else if (radioField.value === "percent" && valueField.value > 100) {
      valueField.setErrors({ exceedPercent: true });
    } else {
      valueField.setErrors(null);
    }
  };
}
