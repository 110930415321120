import { isEmpty } from "@tmhcc/util";

export class PolicyMasterData {
  countries: Country[] = [];
  underWriter: UnderWriter[] = [];
}

export class Country {
  id: string;
  description: string;

  constructor(id: string, description: string) {
    if (isEmpty(id) || isEmpty(description)) {
      throw new Error("invalid country");
    }

    this.id = id;
    this.description = description;
  }
}
export class UnderWriter {
  id: string;
  description: string;

  constructor(id: string, description: string) {
    if (isEmpty(id) || isEmpty(description)) {
      throw new Error("invalid country");
    }

    this.id = id;
    this.description = description;
  }
}
