import { DateService } from "@tmhcc/framwork/services";
import { DialogConfig, DialogSize } from "@tmhcc/framwork/dialog";
import { CommonContentService, Labels } from "@tmhcc/content-service";
import { SubjectivityGrid } from "@tmhcc/models";
import { Subjectivity } from "@tmhcc/data-service";
import { ConfigSubjectivities } from "@tmhcc/data-service/def/eco-master";
import { SubjectivitiesDataResponse,Subjectivities } from "@tmhcc/models/classes/knr-subjectivities-data.model";

export interface KnRSubjectivityDialogConfigData {
  subjectivity?: SubjectivityGrid;
  subjList?: SubjectivityData[];
  isDialogForEdit?: boolean;
  value?: any;
}

interface SubjectivityData {
  name?: string;
  id?: string;
}

export class KnRSubjectivityDialogFieldConfig {
  constructor(
    public dialogData: DialogConfig<KnRSubjectivityDialogConfigData>,
    public contentService: CommonContentService
  ) {
    this.getLabels = this.contentService.getLabels();
  }
  getLabels: Labels;

  dialogModalSettingConfigForDelete = {
    title: "Delete confirmation",
    hideHeaderX: true,
    overflow: true,
    hideFooter: true,
    dialogSize: DialogSize.LG
  };

  getSubjectivityData(subList: SubjectivitiesDataResponse): any[] {
    let subData = [];
    subList.subjectivities.forEach((sub: Subjectivities) => {
      if (sub.value) {
        subData.push({
          name: sub.value,
          id: sub.id
        });
      }
    });
    return subData;
  }
}
