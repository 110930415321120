import { DateService } from "@tmhcc/framwork/services";
import { FieldTypes,InputConfig } from "@tmhcc/framwork/form-inputs";
import { EventGridRow, policyDetail } from "@tmhcc/models";
import { DialogConfig } from "@tmhcc/framwork/dialog";

import { Country, StateOrProvince } from "@tmhcc/data-service";
import { CommonContentService, Labels } from "@tmhcc/content-service";
import { Validators } from "@angular/forms";
import {
  UserConfigurableDataElements,
  DataElementRespons
} from "@tmhcc/data-service/def/eco-master";
import { DialogSize } from "@tmhcc/framwork/dialog";

export interface PolicyDetailDialogConfigData {
    policyDetailGridRow?: policyDetail;    
    isDialogForEdit?: boolean;
    value?: any;
    addedEditedEvents?: boolean;
    openAsAlert?: boolean;
  }

export class PolicyDetailDialogFieldConfig {
   
    constructor(
        private dateService: DateService,
        public dialogData: DialogConfig<PolicyDetailDialogConfigData>,
        public contentService: CommonContentService
      ) {}
      defaultInitialValue =
      (this.dialogData.data && this.dialogData.data.policyDetailGridRow) ||
      new policyDetail();
     
    
          policyDetailsFields: InputConfig[] = [
            {
              config: {
                value:  "",
                optionField: 1
              },
              fieldInfo: {
                fieldName: "PolicyNumber",
                label: "Policy Number: ",
                decimal: 0,
                fieldType: FieldTypes.Text,
                showLabel: true,
                value:  ""
              },
              colClass: "col-md-6"
            },
            {
              config: {
                value:  "",
                optionField: 1
              },
              fieldInfo: {
                fieldName: "TransactionID",
                label: "Transaction ID: ",
                decimal: 0,
                fieldType: FieldTypes.Text,
                showLabel: true,
                value:  ""
              },
              colClass: "col-md-6"
            },
            {
              config: {
                value:  "",
                optionField: 1
              },
              fieldInfo: {
                fieldName: "TransactionType",
                label: "Transaction Type: ",
                decimal: 0,
                fieldType: FieldTypes.Text,
                showLabel: true,
                value:  ""
              },
              colClass: "col-md-6"
            },
            {
              config: {
                value:  "",
                optionField: 1
              },
              fieldInfo: {
                fieldName: "ProductCode",
                label: "Product Code: ",
                decimal: 0,
                fieldType: FieldTypes.Text,
                showLabel: true,
                value:  ""
              },
              colClass: "col-md-6"
            },
            {
              config: {
                value:  "",
                optionField: 1
              },
              fieldInfo: {
                fieldName: "InsuredName",
                label: "Insured Name: ",
                decimal: 0,
                fieldType: FieldTypes.Text,
                showLabel: true,
                value:  ""
              },
              colClass: "col-md-6"
            },
            {
              config: {
                value:  "",
                optionField: 1
              },
              fieldInfo: {
                fieldName: "Address",
                label: "Insured Address: ",
                decimal: 0,
                fieldType: FieldTypes.Text,
                showLabel: true,
                value:  ""
              },
              colClass: "col-md-6"
            },
            {
              config: {
                value:  "",
                optionField: 1
              },
              fieldInfo: {
                fieldName: "City",
                label: "City: ",
                decimal: 0,
                fieldType: FieldTypes.Text,
                showLabel: true,
                value:  ""
              },
              colClass: "col-md-6"
            },
            {
                config: {
                  value:  "",
                  optionField: 1
                },
                fieldInfo: {
                  fieldName: "CreationDate",
                  label: "Creation Date: ",
                  decimal: 0,
                  fieldType: FieldTypes.Text,
                  showLabel: true,
                  value:  ""
                },
                colClass: "col-md-6"
              },
              {
                config: {
                  value:  "",
                  optionField: 1
                },
                fieldInfo: {
                  fieldName: "PolicyEffectiveDate",
                  label: "Effective Date: ",
                  decimal: 0,
                  fieldType: FieldTypes.Text,
                  showLabel: true,
                  value:  ""
                },
                colClass: "col-md-6"
              },
              {
                config: {
                  value:  "",
                  optionField: 1
                },
                fieldInfo: {
                  fieldName: "PolicyExpirationDate",
                  label: "Expiration Date: ",
                  decimal: 0,
                  fieldType: FieldTypes.Text,
                  showLabel: true,
                  value:  ""
                },
                colClass: "col-md-6"
              },
              {
                config: {
                  value:  "",
                  optionField: 1
                },
                fieldInfo: {
                  fieldName: "TotalPremium",
                  label: "Premium: ",
                  decimal: 0,
                  fieldType: FieldTypes.Text,
                  showLabel: true,
                  value:  ""
                },
                colClass: "col-md-6"
              } 
                                    
          ];
  }
     
      