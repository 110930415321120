import {
  Component,
  OnInit,
  ElementRef,
  Output,
  EventEmitter
} from "@angular/core";
import * as XLSX from "xlsx";
import { EventGridRow, EventDeductibleType } from "@tmhcc/models";
import { DateService } from "@tmhcc/framwork/services";
import { QuoteStoreService } from "../../store/facade/quote-store.service";
import { CommonContentService, Labels } from "@tmhcc/content-service";
import { EcoMasterData } from "@tmhcc/data-service";
import {
  UserConfigurableDataElements,
  DataElementRespons
} from "@tmhcc/data-service/def/eco-master";

const SSModel = {
  additionalPeril1: "Additional Peril 1",
  additionalPeril2: "Additional Peril 2",
  additionalPeril3: "Additional Peril 3",
  address: "Address",
  baseRate: "Base Rate",
  bioChemTerrIncl: "Bio-Chem Terr. Incl. Mal. Use",
  city: "City",
  civilCommotion: "Civil Commotion",
  communicableDisease: "Communicable Disease",
  country: "Country",
  csTornadoHail: "CS/Tornado/Hail",
  cyber: "Cyber",
  earthquake: "Earthquake",
  era: "ERA",
  eventDeductibleValue: "Event Deductible Value",
  eventDeductiblePercent: "Event Deductible Percentage",
  eventDescription: "Event Name",
  eventEndDate: "Event End Date",
  eventLimitOfInsurance: "Event Limit of Insurance",
  eventStartDate: "Event Start Date",
  eventType: "Event Type",
  eventVenue: "Event Venue",
  limitedTerrorism: "Limited Terrorism",
  limitedWar: "Limited War",
  nationalMounting: "National Mourning",
  nonAppearance: "Non-Appearance",
  threat:"Threat",
  nuclearHazard: "Nuclear Hazard",
  state: "State",
  strike: "Strike",
  tria:"TRIA",
  war: "War",
  wildfire: "Wildfire",
  windstorm: "Windstorm",
  winterWeather: "Winter Weather",
  zipCode: "Zip Code",
  revenue: "Revenue",
  expenses: "Expenses",
  insPersSpkr1: "Insured Person/Speaker1",
  insPersSpkr2: "Insured Person/Speaker2",
  insPersSpkr3: "Insured Person/Speaker3",
  insPersSpkr4: "Insured Person/Speaker4",
  insPersSpkr5: "Insured Person/Speaker5"
};

@Component({
  selector: "ec-spreadsheet",
  templateUrl: "./spreadsheet.component.html",
  styleUrls: ["./spreadsheet.component.scss"]
})
export class SpreadsheetComponent implements OnInit {
  eventGridRows: EventGridRow[];
  getLabels: Labels;
  @Output() spreadsheetUploadEvent: EventEmitter<any> = new EventEmitter();
  constructor(
    private dateService: DateService,
    private quoteStoreService: QuoteStoreService,
    private elementRef: ElementRef,
    private masterData: EcoMasterData,
    private contentService: CommonContentService
  ) {
    this.getLabels = this.contentService.getLabels();
  }

  ngOnInit(): void {}

  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = event => {
      const data = reader.result;
      workBook = XLSX.read(data, {
        type: "binary",
        cellDates: true,
        dateNF: "mm/dd/yyyy;@"
      });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet, { raw: false });
        return initial;
      }, {});
      this.getObject(jsonData);
    };
    reader.readAsBinaryString(file);
    if (this.elementRef.nativeElement.querySelector("#file-upload")) {
      this.elementRef.nativeElement.querySelector("#file-upload").value = "";
    }
  }

  getObject(jsonData: any) {
    this.eventGridRows = [];
    for (const key in jsonData) {
      if (jsonData.hasOwnProperty(key)) {
        for (const ikey in jsonData[key]) {
          if (jsonData[key].hasOwnProperty(ikey)) {
            this.mapEventGridRows(jsonData[key][ikey]);
          }
        }
      }
    }
    this.quoteStoreService.addEvents(this.eventGridRows);
    this.spreadsheetUploadEvent.emit(true);
  }

  mapEventGridRows(arg: any) {
    const { UserConfigurableDataElements } = this.masterData.getMasterData();
    let eventTypeData: PolicyData[] = this.getPolicyType(
      UserConfigurableDataElements
    );
    const row = new EventGridRow();
    row.additionalPerilLabel1 = "Additional Peril 1";
    row.additionalPerilLabel2 = "Additional Peril 2";
    row.additionalPerilLabel3 = "Additional Peril 3";
    row.additionalPerilValue1 = arg[SSModel.additionalPeril1];
    row.additionalPerilValue2 = arg[SSModel.additionalPeril2];
    row.additionalPerilValue3 = arg[SSModel.additionalPeril3];
    row.address = arg[SSModel.address];
    row.baseRate = arg[SSModel.baseRate];
    row.bioChemTerrIncl = arg[SSModel.bioChemTerrIncl];
    row.city = arg[SSModel.city];
    row.civilCommotion = arg[SSModel.civilCommotion];
    row.communicableDisease = arg[SSModel.communicableDisease];
    row.country = arg[SSModel.country];
    row.csTornadoHail = arg[SSModel.csTornadoHail];
    row.cyber = arg[SSModel.cyber];
    row.earthquake = arg[SSModel.earthquake];
    row.era = arg[SSModel.era];
    if (arg[SSModel.eventDeductibleValue]) {
      row.eventDeductibleType = EventDeductibleType.VALUE;
      row.eventDeductible = arg[SSModel.eventDeductibleValue];
    } else if (arg[SSModel.eventDeductiblePercent]) {
      row.eventDeductibleType = EventDeductibleType.PERCENTAGE;
      row.eventDeductible = arg[SSModel.eventDeductiblePercent];
    } else {
      row.eventDeductibleType = EventDeductibleType.VALUE;
      row.eventDeductible = undefined;
    }
    row.eventDescription = arg[SSModel.eventDescription];
    if (
      arg[SSModel.eventEndDate] &&
      this.dateService.isValidDate(arg[SSModel.eventEndDate])
    ) {
      row.eventEndDate = this.dateService.moment(arg[SSModel.eventEndDate]);
    }
    if (
      arg[SSModel.eventStartDate] &&
      this.dateService.isValidDate(arg[SSModel.eventStartDate])
    ) {
      row.eventStartDate = this.dateService.moment(arg[SSModel.eventStartDate]);
    }
    row.eventLimitOfInsurance = arg[SSModel.eventLimitOfInsurance];
    const eventTypeIndex = eventTypeData.findIndex(value => {
      if (
        value.name &&
        arg[SSModel.eventType] &&
        value.name.toLowerCase() === arg[SSModel.eventType].toLowerCase()
      ) {
        row.eventType = value.name;
        return true;
      } else {
        return false;
      }
    });
    if (eventTypeIndex === -1) {
      row.eventType = undefined;
    }
    row.eventVenue = arg[SSModel.eventVenue];
    row.limitedTerrorism = arg[SSModel.limitedTerrorism];
    row.limitedWar = arg[SSModel.limitedWar];
    row.nationalMounting = arg[SSModel.nationalMounting];
    row.nonAppearance = arg[SSModel.nonAppearance];
    row.threat = arg[SSModel.threat];
    row.nuclearHazard = arg[SSModel.nuclearHazard];
    row.state = arg[SSModel.state];
    row.strike = arg[SSModel.strike];
    row.tria = arg[SSModel.tria];
    row.war = arg[SSModel.war];
    row.wildfire = arg[SSModel.wildfire];
    row.windstorm = arg[SSModel.windstorm];
    row.winterWeather = arg[SSModel.winterWeather];
    row.zipCode = arg[SSModel.zipCode];
    row.revenue = arg[SSModel.revenue];
    row.expenses = arg[SSModel.expenses];
    row.insPersSpkr1 = arg[SSModel.insPersSpkr1];
    row.insPersSpkr2 = arg[SSModel.insPersSpkr2];
    row.insPersSpkr3 = arg[SSModel.insPersSpkr3];
    row.insPersSpkr4 = arg[SSModel.insPersSpkr4];
    row.insPersSpkr5 = arg[SSModel.insPersSpkr5];
    this.eventGridRows.push(row);
  }

  getPolicyType(elements: UserConfigurableDataElements[]) {
    const policyData: PolicyData[] = [];
    if (elements && elements.length !== 0) {
      const { DataElementResponses }: UserConfigurableDataElements =
        elements.find((element: UserConfigurableDataElements) => {
          return element.ElementCode === "EventType";
        }) || null;
      if (DataElementResponses && DataElementResponses.length !== 0) {
        DataElementResponses.forEach((eventType: DataElementRespons) => {
          policyData.push({
            name: eventType.ResponseValue,
            id: eventType.DataElementResponseId
          });
        });
      }
    }
    return policyData;
  }
}

interface PolicyData {
  name?: string;
  id?: number;
}
