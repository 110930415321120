<form [formGroup]="frmGroup">
  <label *ngIf="fieldInfo.showLabel" [fieldInfo]="fieldInfo" acForLable>
    {{ fieldInfo.label }}<span *ngIf="fieldInfo.validation?.required">*</span>
  </label>
  <hcc-custom-validation [validations]="fieldInfo.validation">
    <textarea
      [value]="value"
      [formControlName]="control"
      class="form-control"
      acInputTextArea
      inputValidateRef
      [control]="control"
      [formGroup]="frmGroup"
      [fieldInfo]="fieldInfo"
    ></textarea>
  </hcc-custom-validation>
</form>
