import { State, Action, StateContext, Store } from "@ngxs/store";
import { tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import {
  PolicyTxnData,
  UWQuotes,
  IssueNewBusinessModel,
  EndorsementForms
} from "@tmhcc/models";
import {
  QuotePolicyTxn,
  UpdateQuotePolicyTxnData,
  IssueNewBusiness,
  UpdatePolicyData,
  AddOptionalForm
} from "./quote-policy-step.action";
import {
  QuoteStepService,
  PolicyTxnRequest,
  IssueNewBusinessService
} from "@tmhcc/data-service";
import { LoaderService } from "@tmhcc/framwork/loader/src";
import { updateItem, patch } from "@ngxs/store/operators";

export interface QuotePolicyStepStore {
  policyTxnData?: PolicyTxnData;
  optionalFormData?: EndorsementForms[];
}

@State<QuotePolicyStepStore>({
  name: "PolicyTxnData",
  defaults: {
    policyTxnData: null,
    optionalFormData: []
  }
})
@Injectable()
export class QuotePolicyStepState {
  QuoteBussines: [];
  constructor(
    private _quoteStepService: QuoteStepService,
    private _issueNewBusinessService: IssueNewBusinessService,
    private store: Store,
    private loaderService: LoaderService
  ) {}

  @Action(QuotePolicyTxn)
  quotePolicyTxn(
    ctx: StateContext<QuotePolicyStepStore>,
    { polcyTxnRequest }: QuotePolicyTxn
  ) {
    return this._quoteStepService.setQuotePolicyStepData(polcyTxnRequest).pipe(
      tap((data: PolicyTxnData) => {
        const state = ctx.getState();
        ctx.setState({
          ...state,
          policyTxnData: { ...data }
        });
      })
    );
  }

  @Action(IssueNewBusiness)
  issueNewBusiness(
    ctx: StateContext<QuotePolicyStepStore>,
    { issueNewBusinessRequest }: IssueNewBusiness
  ) {
    return this._issueNewBusinessService
      .issueNewBusiness(issueNewBusinessRequest)
      .pipe(
        tap((data: IssueNewBusinessModel) => {
          console.log(data);
        })
      );
  }

  @Action(UpdateQuotePolicyTxnData)
  updateQuotePolicyTxnData(
    ctx: StateContext<QuotePolicyStepStore>,
    { uwQuoteId, payload }: UpdateQuotePolicyTxnData
  ) {
    ctx.setState(
      patch({
        policyTxnData: patch({
          UWQuotes: updateItem<UWQuotes>(
            uWQuote => uWQuote.UWQuoteId === uwQuoteId,
            patch({ LOBs: [...payload] })
          )
        })
      })
    );
  }
  /*** update Policy Data ***/
  @Action(UpdatePolicyData)
  UpdatePolicyData(
    ctx: StateContext<QuotePolicyStepStore>,
    { payload }: UpdatePolicyData
  ) {
    ctx.setState(
      patch({
        policyTxnData: patch({
          UWQuotes: [...payload]
        })
      })
    );
  }

  /**** Add Optional Forms***/
  @Action(AddOptionalForm)
  addOptionalForm(
    ctx: StateContext<QuotePolicyStepStore>,
    { optionalForms }: AddOptionalForm
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      optionalFormData: [...state.optionalFormData, optionalForms]
    });
  }
}
