import { Component, forwardRef, ViewChild } from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { InputBase } from "../input-base";

@Component({
  selector: "hcc-number-input",
  templateUrl: "./number-input.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputComponent),
      multi: true
    }
  ]
})
export class NumberInputComponent extends InputBase<string> {
  constructor() {
    super();
  }
}
