import { Injectable, Type } from "@angular/core";
import { InputTemplateService, CoreInput } from "./core-input.def";
import { TextInputComponent } from "./text/text-input.component";
import { NumberInputComponent } from "./number/number-input.component";
import { TextareaInputComponent } from "./textarea/textarea.component";
import { ReadyOnlyComponent } from "./read-only/read-only.component";
import { RadioButtonComponent } from "./radio-button/radio-button.component";
import { CheckboxComponent } from "./checkbox/checkbox.component";
import { SelectListComponent } from "./select-list/select-list.component";
import { SwitchButtonComponent } from "./switch-button/switch-button.component";
import { DatePickerComponent } from "./date-picker/date-picker.component";
import { FieldInfo, FieldTypes } from "@tmhcc/framwork/form-inputs";

@Injectable()
export class InputTemplateBaseService implements InputTemplateService {
  constructor() {}

  getTemplate(fieldInfo: FieldInfo): Type<CoreInput> {
    switch (fieldInfo.fieldType) {
      case FieldTypes.Text:
        return TextInputComponent;
      case FieldTypes.Numeric:
        return NumberInputComponent;
      case FieldTypes.Textarea:
        return TextareaInputComponent;
      case FieldTypes.Checkbox:
        return CheckboxComponent;
      case FieldTypes.Date:
        return DatePickerComponent;
      case FieldTypes.Radio:
        return RadioButtonComponent;
      case FieldTypes.LookupList:
        return SelectListComponent;
      case FieldTypes.ToggleSwitch:
        return SwitchButtonComponent;
      case FieldTypes.Readonly:
        return ReadyOnlyComponent;
      default:
        return TextInputComponent;
    }
  }
}
