import { Injectable } from "@angular/core";
import { Select } from "@ngxs/store";
import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { Observable } from "rxjs";
import { CreateNewQuote } from "../state/quote-create.actions";
import {
  QuoteCreateState,
  QuoteCreateStore
} from "../state/quote-create.state";
import { CreateNewQuoteModel } from "@tmhcc/models";

@Injectable({ providedIn: "root" })
export class QuoteCreateStoreService {
  @Select(QuoteCreateState)
  public data$: Observable<QuoteCreateStore>;
  public data: QuoteCreateStore;

  constructor() {
    this.data$.subscribe(value => (this.data = value));
  }

  @Dispatch()
  public createNewQuote(quoteCreateRequest: CreateNewQuoteModel) {
    return new CreateNewQuote(quoteCreateRequest);
  }
}
