<form [formGroup]="frmGroup">
  <label *ngIf="fieldInfo.showLabel" [fieldInfo]="fieldInfo" acForLable>
    {{ fieldInfo.label }}<span *ngIf="fieldInfo.validation?.required">*</span>
  </label>
  <hcc-custom-validation [validations]="fieldInfo.validation">
    <input
      type="text"
      [value]="value"
      (change)="change($event.target.value)"
      (blur)="touch()"
      inputValidateRef
      [control]="control"
      [formGroup]="frmGroup"
      class="form-control"
      [formControlName]="control"
      [ngbTypeahead]="search"
    />
  </hcc-custom-validation>
</form>
