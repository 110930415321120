import { NgModule } from "@angular/core";
import {
  CommonModule,
  CurrencyPipe,
  DatePipe,
  TitleCasePipe
} from "@angular/common";
import { GridCellFormatterPipe } from "./grid-cell-formatter.pipe";
import { ShortDatePipe, LongDatePipe } from "./date/date.pipe";
import { HccCurrencyPipe } from "./currency.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [
    GridCellFormatterPipe,
    ShortDatePipe,
    LongDatePipe,
    HccCurrencyPipe
  ],
  providers: [
    CurrencyPipe,
    DatePipe,
    TitleCasePipe,
    GridCellFormatterPipe,
    ShortDatePipe,
    LongDatePipe,
    HccCurrencyPipe
  ],
  exports: [GridCellFormatterPipe, ShortDatePipe, LongDatePipe, HccCurrencyPipe]
})
export class PipesModule {}
