import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { retry, catchError } from "rxjs/operators";
import { throwError } from "rxjs/internal/observable/throwError";

@Injectable()
export class BaseHttpInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headers = req.headers.set("content-type", "application/json");

    return next
      .handle(
        req.clone({
          headers,
          params: req.params
        })
      )
      .pipe(
        // retry(1),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      );
  }
}
