<div *ngIf="badgeCount == 1">
  <ac-page-tab-item
    [data]="routerItemsStep2"
    [useRouter]="false"
    [selectedId]="selected"
    (itemClicked)="tabClicked($event)"
  >
  </ac-page-tab-item>
</div>
<div *ngIf="badgeCount == 2">
  <ac-page-tab-item
    [data]="routerItemsStep3"
    [useRouter]="false"
    [selectedId]="selected"
    (itemClicked)="tabClicked($event)"
  >
  </ac-page-tab-item>
</div>
<div *ngIf="badgeCount == 3">
  <ac-page-tab-item
    [data]="routerItemsStep4"
    [useRouter]="false"
    [selectedId]="selected"
    (itemClicked)="tabClicked($event)"
  >
  </ac-page-tab-item>
</div>
