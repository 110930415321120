<div class="container">
  <ec-page-header></ec-page-header>
  <h5 class="header-tab mt-3">{{ getLabels.quoteHeading }}</h5>
  <div *ngIf="frmGroup">
    <form [formGroup]="frmGroup" [attr.disabled]="isPolicyBind">
      <div class="form-row mt-3">
        <div class="col-sm-6 col-md-3" *ngFor="let items of quoteInfoField">
          <hcc-input-generator
            [frmGroup]="frmGroup"
            [fieldInfo]="items.fieldInfo"
            [control]="items.fieldInfo.fieldName"
            [InputConfig]="items?.config"
          ></hcc-input-generator>
        </div>
      </div>

      <hr />
      <h5 class="header-tab mt-3">{{ getLabels.insuredHeading }}</h5>
      <div class="form-row" *ngFor="let items of insuredInfoField">
        <div class="col-sm-6 col-md-5 ">
          <hcc-input-generator
            [frmGroup]="frmGroup"
            [fieldInfo]="items.fieldInfo"
            [control]="items.fieldInfo.fieldName"
            [InputConfig]="items?.config"
          ></hcc-input-generator>
        </div>
      </div>

      <div class="form-row" *ngFor="let items of addressInfoField">
        <div class="col-sm-6 col-md-5">
          <hcc-input-generator
            [frmGroup]="frmGroup"
            [fieldInfo]="items.fieldInfo"
            [control]="items.fieldInfo.fieldName"
            [InputConfig]="items?.config"
            (keyup)="onChange($event)"
          ></hcc-input-generator>
          <div
            class="address-search-inputbox"
            *ngIf="items.fieldInfo.fieldName == 'address'"
          >
            <div class="combobox" *ngIf="listHidden">
              <div class="combobox-options">
                <list-item
                  [ngClass]="{ selected: i === selectedIndex }"
                  *ngFor="let address of addressOptions; let i = index"
                  (click)="selectAddress(address)"
                  [value]="address.text"
                  >{{ address.text }}</list-item
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div
          class="col-sm-6 col-md-3 "
          *ngFor="let items of insuredAddressInfoField"
        >
          <hcc-input-generator
            [frmGroup]="frmGroup"
            [fieldInfo]="items.fieldInfo"
            [control]="items.fieldInfo.fieldName"
            [InputConfig]="items?.config"
          ></hcc-input-generator>
        </div>
      </div>
      <hr />
      <h5 class="header-tab mt-3">{{ getLabels.brokerHeading }}</h5>
      <div class="form-row mt-5">
        <div class="col-sm-6" *ngFor="let items of brokerInfoFieild" [ngClass]="items.colClass ? items.colClass : 'col-md-5'">
          <hcc-input-generator
            [frmGroup]="frmGroup"
            [fieldInfo]="items.fieldInfo"
            [control]="items.fieldInfo.fieldName"
            [InputConfig]="items?.config"
          ></hcc-input-generator>
        </div>
      </div>
      <hr />
      <h5 class="header-tab mt-3">{{ getLabels.underwriterHeading }}</h5>
      <div class="form-row mt-3">
        <div
          class=" col-sm-6 col-md-3 "
          *ngFor="let items of underwriterInfoField"
        >
          <hcc-input-generator
            [frmGroup]="frmGroup"
            [fieldInfo]="items.fieldInfo"
            [control]="items.fieldInfo.fieldName"
            [InputConfig]="items?.config"
          ></hcc-input-generator>
        </div>
      </div>
      <div class="button-row" *ngIf="isPolicyBind">
        <button
          type="button"
          (click)="cancelBackToDashboard()"
          class="btn btn-primary"
        >
          Cancel
        </button>

        <button
          type="button"
          (click)="nextToAfterBind()"
          class="btn btn-primary"
        >
          Next
        </button>
      </div>
      <ec-page-navigation
        *ngIf="!isPolicyBind"
        [isLastStep]="false"
        [forwardRoute]="routeState.forwardRoute"
        [defaultRoute]="routeState.defaultRoute"
        (saveNextEvent)="saveNextEvent($event)"
        (cancelEvent)="cancelBackToDashboard($event)"
      ></ec-page-navigation>
    </form>
  </div>
</div>
