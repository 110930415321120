export class RatingDataResponse {
    constructor(
        public BasePremium:BasePremium,
        public ModifiedBasePremium:ModifiedBasePremium,
        public LimitModification:LimitModification,
        public CoverageModifiers:CoverageModifiers,
        public RiskQualityModifiers:RiskQualityModifiers,
        public WorkplaceViolence:WorkplaceViolence
    ){ }
    
}
export class BasePremium{
    assetSize: AssetSize[];
    companyVisibility:CompanyVisibility[];
    individualVisibility:IndividualVisibility[];
}
export class ModifiedBasePremium{
    grossRevenue:GrossRevenue[];
    deductible:Deductible[];
    specialIndustry:SpecialIndustry[];
    claims:Claims[];
}
export class LimitModification{
    limitOfInsurance:LimitOfInsurance[];
    disappearanceAndInvestigation:DisappearanceAndInvestigation[];
    threatResponse:ThreatResponse[];
    recallResponse:RecallResponse[];
    personalBelongings:PersonalBelongings[];
    travelSecurityEvacOption:TravelSecurityEvacOption[];
    travelSecurityEvacLimit:TravelSecurityEvacLimit[];
    expatriateSecurityEvacOption:ExpatriateSecurityEvacOption[];
    expatriateSecurityEvacLimit:ExpatriateSecurityEvacLimit[];
}
export class CoverageModifiers{
    aDandDLimit:ADandDLimit[];
    childAbductionLimit:ChildAbductionLimit[];
    wwInclusiveDeductible:WwInclusiveDeductible[];
    territorialInclusiveDeductible:TerritorialInclusiveDeductible[];
    wwInclusiveAggregate:WwInclusiveAggregate[];
    territorialExclusions:TerritorialExclusions[];
    territorialSubLimitInclusiveAggregate:TerritorialSubLimitInclusiveAggregate[];
    amendedTravel:AmendedTravel[];
    amendedInsuredPersons:AmendedInsuredPersons[];
    lossOfEarnings:LossOfEarnings[];
    amendedThreat:AmendedThreat[];
    amendedDisappearance:AmendedDisappearance[];
    exclusionaryEndorsement:ExclusionaryEndorsement[];
}
export class RiskQualityModifiers{
    riskAdvisory :RiskAdvisory[];
    riskTravel:RiskTravel[] 
    riskConsultant : RiskConsultant[]
    riskCMRecom :RiskCMRecom[];
    riskCMPlan: RiskCMPlan[];
    riskProduct :RiskProduct[];
    riskProductAnimal : RiskProductAnimal[];
    riskUnityPortal : RiskUnityPortal[];
    riskUnityGuidelines : RiskUnityGuidelines[];
    riskUnityMeeting :RiskUnityMeeting[];
    riskUnityProposal :RiskUnityProposal[];
    riskUnityTelephone : RiskUnityTelephone[];
    riskCountryReports :RiskCountryReports[];
}
export class WorkplaceViolence{
    numberOfEmployees :NumberOfEmployees[];
    limitOfLiability:LimitOfLiability[] 
    businessOperations : BusinessOperations[]
    numberOfLocations :NumberOfLocations[];
    workplaceReduction: WorkplaceReduction[];
    workplacePractices :WorkplacePractices[];
    securityControls : SecurityControls[];
    priorClaims : PriorClaims[];
    financialStability : FinancialStability[];
    yearsInBusiness :YearsInBusiness[];
    mandAActivity :MandAActivity[];
    riskComplexity : RiskComplexity[];
}

export class AssetSize{
    id:number;
    value:string;
}
export class CompanyVisibility{
    id:number;
    value:string;
}
export class IndividualVisibility{
    id:number;
    value:string;
}
export class GrossRevenue{
    id:number;
    value:string;
 }
export class Deductible{
   id:number;
   value:string;
}
export class SpecialIndustry{
    id:number;
    value:string;
}
export class Claims{
    id:number;
    value:string;
}
export class LimitOfInsurance{
    id:number;
    value:string;
}
export class DisappearanceAndInvestigation{
    id:number;
    value:string;
}
export class ThreatResponse{
    id:number;
    value:string;
}
export class RecallResponse{
    id:number;
    value:string;
}
export class PersonalBelongings{
    id:number;
    value:string;
}
export class TravelSecurityEvacOption{
    id:number;
    value:string;
}
export class TravelSecurityEvacLimit{
    id:number;
    value:string;
}
export class ExpatriateSecurityEvacOption{
    id:number;
    value:string;
}
export class ExpatriateSecurityEvacLimit{
    id:number;
    value:string;
}

export class ADandDLimit{
    id:number;
    value:string;
}
export class ChildAbductionLimit{
    id:number;
    value:string;
}
export class WwInclusiveDeductible{
    id:number;
    value:string;
}
export class TerritorialInclusiveDeductible{
    id:number;
    value:string;
}
export class WwInclusiveAggregate{
    id:number;
    value:string;
}
export class TerritorialExclusions{
    id:number;
    value:string;
}
export class TerritorialSubLimitInclusiveAggregate{
    id:number;
    value:string;
}
export class AmendedTravel{
    id:number;
    value:string;
}
export class AmendedInsuredPersons{
    id:number;
    value:string;
}
export class LossOfEarnings{
    id:number;
    value:string;
}
export class AmendedThreat{
    id:number;
    value:string;
}
export class AmendedDisappearance{
    id:number;
    value:string;
}
export class ExclusionaryEndorsement{
    id:number;
    value:string;
} 

export class RiskAdvisory{
    id:number;
    value:string;
}
export class RiskTravel{
    id:number;
    value:string;
}
export class RiskConsultant{
    id:number;
    value:string;
}
export class RiskCMRecom{
    id:number;
    value:string;
}
export class RiskCMPlan{
    id:number;
    value:string;
}
export class RiskProduct{
    id:number;
    value:string;
}
export class RiskProductAnimal{
    id:number;
    value:string;
}
export class RiskUnityPortal{
    id:number;
    value:string;
}
export class RiskUnityGuidelines{
    id:number;
    value:string;
}
export class RiskUnityMeeting{
    id:number;
    value:string;
}
export class RiskUnityProposal{
    id:number;
    value:string;
}
export class RiskUnityTelephone{
    id:number;
    value:string;
}
export class RiskCountryReports{
    id:number;
    value:string;
}

//workplace voilance

export class NumberOfEmployees{
    id:number;
    value:string;
}
export class LimitOfLiability{
    id:number;
    value:string;
}
export class BusinessOperations{
    id:number;
    value:string;
}
export class NumberOfLocations{
    id:number;
    value:string;
}
export class WorkplaceReduction{
    id:number;
    value:string;
}
export class WorkplacePractices{
    id:number;
    value:string;
}
export class SecurityControls{
    id:number;
    value:string;
}
export class PriorClaims{
    id:number;
    value:string;
}
export class FinancialStability{
    id:number;
    value:string;
}
export class YearsInBusiness{
    id:number;
    value:string;
}
export class MandAActivity{
    id:number;
    value:string;
}
export class RiskComplexity{
    id:number;
    value:string;
}