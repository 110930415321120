
export class AccountSetupResponse{
    constructor(
       public LinesOfBusiness:LinesOfBusiness[],       
       public PolicyType:PolicyType[],
       public Countries:Countries[],
       public StatesOrProvinces:StatesOrProvinces[],
       public Underwriters:Underwriters[],
       public AssistantUnderwriters:AssistantUnderwriters[],
       public Brokers:Brokers[]
      ){}    
  }
export class LinesOfBusiness{
    id:number;
    code: string;  
    Carrier:Carrier[];  
    Currencies:Currencies[];
}
export class Carrier
{
    id:number;
    name:string;
}
export class Currencies{
    id:number;
    description:string;
}
export class PolicyType{
    id:number;
    type:string;
}
export class Countries{
    id:number;
    description:string;
}
export class StatesOrProvinces{
     id: number;
     countryCode: string;
     code: string;
     description: string;
}
export class Underwriters{
    id:number;
    name:string;
}
export class AssistantUnderwriters{
    id: number;
    name:string;
}
export class Brokers{
    id: number;
    name: string;
    number: string;
    address: string;
    city: string;
    state: string;
    zip: string;
}
