import {
  Component,
  Input,
  ContentChild,
  OnInit,
  OnDestroy,
  ViewEncapsulation
} from "@angular/core";
import { InputRefDirective } from "./custom-validation.directive";
import { CoreInputValidationService } from "./custom-validation.service";
import { Subscription } from "rxjs";

@Component({
  selector: "hcc-custom-validation",
  templateUrl: "./custom-validation.component.html",
  styleUrls: ["./custom-validation.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class CustomValidationComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() validations: { [index: string]: string };
  @Input() info: string;

  valueSub = new Subscription();
  isFormsubmitted: boolean = false;

  constructor(private inputValidation: CoreInputValidationService) {}

  @ContentChild(InputRefDirective) input: InputRefDirective;

  get isError() {
    return this.input.hasError;
  }

  get errorMessages() {
    const errors = this.input.errors;
    const messages = [];
    const keys = Object.keys(this.validations);

    keys.forEach(key => {
      if (errors[key]) {
        messages.push(this.validations[key]);
      }
    });
    return messages;
  }

  ngOnInit() {
    this.valueSub.add(
      this.inputValidation.formSubmission.subscribe(submitState => {
        this.isFormsubmitted = submitState;
      })
    );
  }

  ngOnDestroy() {
    this.valueSub.unsubscribe();
  }
}
