import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { PageHeaderModule } from "../../shared/page-header/page-header.module";
import { QuotePolicyInfoModule } from "../../shared/quote-policy-info/quote-policy-info.module";
import { QuoteStepComponent } from "./quote-step.component";
import { QuoteSummaryModule } from "../quote-summary/quote-summary.module";
import { GridModule } from "@tmhcc/framwork/grid";
import { PageTabInfoModule } from "./../../shared/page-tab/page-tab-info.module";

import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CoreInputsModule } from "@tmhcc/framwork/core-inputs";
import { QuoteStoreService } from "../store/facade/quote-store.service";
import {
  SaveEventService,
  QuoteStepService,
  IssueNewBusinessService,
  DownloadQuoteService
} from "@tmhcc/data-service";

import { UnsavedDataGaurd } from "./unsaved-data.guard";
const routes: Routes = [
  { path: "", component: QuoteStepComponent, canDeactivate: [UnsavedDataGaurd] }
];

@NgModule({
  declarations: [QuoteStepComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PageHeaderModule,
    QuotePolicyInfoModule,
    QuoteSummaryModule,
    GridModule,
    PageTabInfoModule,
    CoreInputsModule
  ],
  providers: [
    UnsavedDataGaurd,
    QuoteStoreService,
    SaveEventService,
    QuoteStepService,
    IssueNewBusinessService,
    DownloadQuoteService
  ],
  exports: [RouterModule]
})
export class QuoteStepModule {}
