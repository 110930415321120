import { Observable } from "rxjs";
import { CanDeactivate, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { FormGroup } from "@angular/forms";
import {
  DialogService,
  DialogConfig,
  DialogSize
} from "@tmhcc/framwork/dialog";
import { DialogContainerComponent } from "./DialogContainer/dialog-container.component";
import { CommonContentService } from "@tmhcc/content-service";
import { AllowedRoute } from "@tmhcc/eco/src/lib/shared/page-header/page-header-navigation/page-header-navigation-def";
export interface ComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class SaveChangeGaurd implements CanDeactivate<ComponentDeactivate> {
  formGroup: FormGroup;
  hideHeader: boolean;
  hideFooter: boolean;
  hideSave: boolean;
  hideHeaderX: boolean;
  titleText: string;
  backwardRoute: string;
  tabClicked: boolean;
  tabUrl: string;

  constructor(
    private dialogService: DialogService,
    private dialogConfig: DialogConfig,
    private contentService: CommonContentService,
    public router: Router
  ) {
    this.tabClicked = false;
    this.tabUrl = "";
  }

  canDeactivate() {
    return this.formGroup.dirty ? this.DeactivateRoute() : true;
  }

  setFormGroup(formGroup: FormGroup) {
    this.tabClicked = false;
    this.tabUrl = "";
    this.formGroup = formGroup;
    this.getBackwardRoute();
  }

  setRoute(url: string) {
    this.tabClicked = true;
    this.tabUrl = url;
  }

  getBackwardRoute() {
    this.tabClicked = false;
    const path = this.router.url;
    if (path.includes(AllowedRoute.GeneralInfo)) {
      this.backwardRoute = AllowedRoute.Dashboard;
    } else if (path === AllowedRoute.Events) {
      this.backwardRoute = AllowedRoute.GeneralInfo;
    } else if (
      path === AllowedRoute.Quote ||
      path === AllowedRoute.Subjectivity
    ) {
      this.backwardRoute = AllowedRoute.Events;
    } else if (path === AllowedRoute.Bind || path === AllowedRoute.Forms) {
      this.backwardRoute = AllowedRoute.Quote;
    } else {
      this.backwardRoute = AllowedRoute.Dashboard;
    }
  }

  DeactivateRoute(): Observable<boolean> | boolean {
    let content = {
      value: this.contentService.getLabels().dirtyFormMsg
    };
    this.dialogConfig.data = content;

    this.dialogConfig.modalSetting = {
      title: this.titleText,
      hideHeader: this.hideHeader,
      hideFooter: true,
      hideSave: this.hideSave,
      hideHeaderX: this.hideHeaderX,
      SaveButton: "YES",
      CancelButton: "NO",
      dialogSize: DialogSize.SM
    };
    const ref = this.dialogService.open(
      DialogContainerComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {
      if (result) {
      } else {
        this.formGroup.markAsPristine();
        if (!this.tabClicked && this.backwardRoute) {
          this.router.navigate([this.backwardRoute]);
        } else if (this.tabClicked && this.tabUrl) {
          this.router.navigate([this.tabUrl]);
        } else {
          sessionStorage.removeItem("selectedUwQuoteId");
          this.router.navigate([AllowedRoute.Dashboard]);
        }
      }
    });
    return false;
  }
}
