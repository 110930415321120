import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { ColumnDefinition, Types, GridOptions } from "@tmhcc/framwork/grid";
import { CommonContentService } from "@tmhcc/content-service";
import {
  InputConfig,
  FieldTypes,
  FieldInfo
} from "@tmhcc/framwork/form-inputs/src";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { QuoteStepStoreService } from "../store/facade/quote-step-store.service";
import { QuotePolicyStepStore } from "../store/state/quote-policy-step.state";
import { Router } from "@angular/router";
import { DateService } from "@tmhcc/framwork/services";
import { QuoteStoreService } from "../store/facade/quote-store.service";
import * as _ from "lodash";
import {
  PolicyTxnData,
  EcoMaster,
  PolicyTxnRequest,
  CreateActivityLogItemObj
} from "@tmhcc/models";
import { QuoteOptions, UWQuotes, EventGridRow } from "@tmhcc/models";
import { QuoteSummaryComponent } from "../quote-summary/quote-summary.component";
import {
  ecaConstant,
  APModel,
  RiskModel,
  RiskResponseType
} from "../../shared/eco-constant";
import {
  SaveEventRequest,
  EcoMasterData,
  UpdateEventDetails,
  Risk,
  CreateActivityLogItem,
  RiskDataElements,
  ElementUpdateRequest,
  LOBRisks,
  UpdateUserConfigurableDataElements,
  UpdateAndDownloadQuoteECO,
  DownloadQuoteService,
  CreateActivityLogItemData
} from "@tmhcc/data-service";
import { QuoteStore } from "../store/state/quote.state";
import { LoaderService } from "@tmhcc/framwork/loader/src";
import { CoreInputValidationService } from "@tmhcc/framwork/core-inputs/src";
import { take } from "rxjs/operators";
import { QuoteStepConfig } from "./quote-step-config";
import { Store } from '@ngxs/store';
import { QuotePolicyTxn } from '../store/state/quote-policy-step.action';
import { Subscription } from 'rxjs';
@Component({
  selector: "ec-quote-step",
  templateUrl: "./quote-step.component.html",
  styleUrls: ["./quote-step.component.scss"]
})
export class QuoteStepComponent extends QuoteStepConfig
  implements OnInit, OnDestroy {
  @ViewChild(QuoteSummaryComponent) childComponent: QuoteSummaryComponent;
  recordsList: QuoteOptions[];
  quoteTableType: number;
  columnSetting: ColumnDefinition[];
  gridSetting: GridOptions;
  quoteOptionForm: FormGroup;
  quotePolicyStepStore: QuotePolicyStepStore;
  currentUWQuoteID: number;
  getLabels: any;
  showOptionTable: boolean;
  customQuoteIconTexts: any;
  policyType: string;
  quoteStore: QuoteStore;
  quoteVersionList: any[];
  policyMasterData: EcoMaster;
  policyTxnStoreData: PolicyTxnData;
  userName;
  isQuoteVersion: boolean;
  currentWritingCompanyId: number;
  isFormValid: boolean;
  childFormValidCheck: boolean;
  submitted: boolean;
  isPolicyBind: boolean;
  form: any;
  selectedQuoteID: number;
  QuotePolicyServiceInstance:Subscription;
  constructor(
    private formbuilder: FormBuilder,
    private router: Router,
    private quoteStoreService: QuoteStoreService,
    public dateService: DateService,
    private quoteStepStoreService: QuoteStepStoreService,
    private commonContentService: CommonContentService,
    private loaderService: LoaderService,
    private ecoMasterData: EcoMasterData,
    private validationService: CoreInputValidationService,
    private downloadQuoteService: DownloadQuoteService,
    private store:Store
  ) {
    super(commonContentService);
    this.getLabels = this.commonContentService.getLabels();
  }
  fieldSelectList: FieldInfo = {
    fieldName: "quoteVersion",
    label: "Select Quote",
    fieldType: FieldTypes.LookupList,
    showLabel: false,
    placeholder: "Select Quote",
    validation: {
      required: "Select quote"
    }
  };
  selectConfig: InputConfig = {
    config: {
      selectConfig: {
        selectedOption: [],
        options: [],
        optionConfig: {
          displayKey: "Description",
          search: false,
          limitTo: 3
        },
        multiple: false
      }
    },
    fieldInfo: this.fieldSelectList
  };
  ngOnInit(): void {
    this.gridSetting = this.gridSettingConfig;
    this.customQuoteIconTexts = {};
    this.initFormGroup();
    this.ecoPolicyData();
    this.quoteOptionForm.controls["quoteVersion"].valueChanges.subscribe(
      value => {
        if (value) {
          const policyTxnId = this.policyTxnStoreData.PolicyTxnId;
          const UwQuoteId = value.UWQuoteId;
          this.loadQuoteVersionData(policyTxnId, UwQuoteId);
        }
      }
    );
    this.quoteStoreService.data$.subscribe((data: QuoteStore) => {
      this.quoteStore = data;
    });
    this.quoteOptionForm.valueChanges.subscribe(value => {
      if (!this.quoteOptionForm.invalid) {
        this.isFormValid = true;
      } else {
        this.isFormValid = false;
      }
    });
  }
  ngAfterViewInit() {
    this.quoteOptionForm.addControl(
      "childForm",
      this.childComponent.quoteSummaryForm
    );
    this.childComponent.quoteSummaryForm.setParent(this.quoteOptionForm);
  }

  ecoPolicyData() {
    this.quoteStepStoreService.data$
      .pipe(take(1))
      .subscribe((data: QuotePolicyStepStore) => {
        this.quotePolicyStepStore = data;
        if (
          this.quotePolicyStepStore &&
          this.quotePolicyStepStore.policyTxnData
        ) {
          if (
            this.quotePolicyStepStore.policyTxnData.ProcessStatusCode == "*" ||
            this.quotePolicyStepStore.policyTxnData.ProcessStatusCode == "I"
          ) {
            this.isPolicyBind = true;
            this.quoteOptionForm.disable();
            const UWQuotesInstance = data.policyTxnData.UWQuotes;
            UWQuotesInstance.forEach(element => {
              if(element.QuoteStatus==="A"){
                this.currentUWQuoteID=element.UWQuoteId;
                sessionStorage.selectedUwQuoteId=this.currentUWQuoteID;
              }
            });
            this.setQuoteVersionList(UWQuotesInstance);
          }
          else{
            const UWQuotesInstance = data.policyTxnData.UWQuotes;
            if (UWQuotesInstance && UWQuotesInstance.length !== 0) {
              // UWQuotesInstance[UWQuotesInstance.length - 1].;
              this.updateUWQuoteProperties(
                UWQuotesInstance[UWQuotesInstance.length - 1]
              );
              this.setQuoteVersionList(UWQuotesInstance);
            }
          }
          this.policyTxnStoreData = this.quotePolicyStepStore.policyTxnData;
          this.policyMasterData = this.ecoMasterData.getMasterData();
          //Render Events if any
         
          if (this.quotePolicyStepStore.policyTxnData.UWQuotes) {
            this.quoteOptionData(
              this.quotePolicyStepStore.policyTxnData.UWQuotes
            );
          }
        }
      });
  }

  updateUWQuoteProperties(uWQuotesInstance: UWQuotes) {
    const SelectedUwQuoteId = sessionStorage.selectedUwQuoteId;
    if (SelectedUwQuoteId) {
      this.currentUWQuoteID = SelectedUwQuoteId;
    } else {
      this.currentUWQuoteID = uWQuotesInstance.UWQuoteId;
      sessionStorage.selectedUwQuoteId = this.currentUWQuoteID;
    }
    this.selectedQuoteID = this.currentUWQuoteID;
  }
  quoteOptionData(uWQuotes: UWQuotes[]) {
    this.loaderService.show();
    this.showOptionTable = false;
    const UWQuoteId = this.currentUWQuoteID;
    const filteredUWQuote: UWQuotes = uWQuotes.find(
      (value: UWQuotes) => value.UWQuoteId == UWQuoteId
    );
    if (
      filteredUWQuote &&
      filteredUWQuote.LOBs &&
      filteredUWQuote.LOBs.length !== 0
    ) {
      const risks = filteredUWQuote.LOBs[0].Risks;
      if (risks && risks.length !== 0) {
        this.recordsList = this.getQuoteOptionFromPolicy(risks);
        if (this.recordsList.length >= 0) {
          this.setCustomQuoteIcon();
          this.showOptionTable = true;
          this.loaderService.hide();
        }
      }
    }
    this.policyType = this.quotePolicyStepStore.policyTxnData.PolicyTypeDescription;
    // if (this.policyType == "Standard Event") {
    //   this.columnSetting = this.standardColumnHeadingSettings;
    // } else {
      this.columnSetting = this.tradeShowColumnHeadingSettings;
    //}
  }

  setCustomQuoteIcon() {
    for (var key in this.customQuoteIconTexts) {
      if (
        this.customQuoteIconTexts.hasOwnProperty(key) &&
        this.recordsList[Number(key)]
      ) {
        this.recordsList[Number(key)].infoIconText = this.customQuoteIconTexts[
          key
        ];
      }
    }
  }

  getQuoteOptionFromPolicy(risks: LOBRisks[]): QuoteOptions[] {
    this.loaderService.show();
    const quoteOptions: QuoteOptions[] = [];
    risks.forEach((lOBRisk: LOBRisks) => {
      if (lOBRisk.RiskTypeCode == "FCH") {
        const quoteOptionData = new QuoteOptions();
        let index = quoteOptions.length;
        let custmadditionalPerilLabel = {};
        let custmadditionalPerilvalue = {};
        lOBRisk.RiskDataElements.ElementUpdateRequests.forEach(element => {
          if (
            element.ElementCode === "AP1" ||
            element.ElementCode === "AP2" ||
            element.ElementCode === "AP3"
          ) {
            if (element.RowNum === 1) {
              quoteOptionData.additionalPerilLabel1 = element.FreeTextValue;
              custmadditionalPerilLabel[1] = element.FreeTextValue;
            } else if (element.RowNum === 2) {
              quoteOptionData.additionalPerilLabel2 = element.FreeTextValue;
              custmadditionalPerilLabel[2] = element.FreeTextValue;
            } else if (element.RowNum === 3) {
              quoteOptionData.additionalPerilLabel3 = element.FreeTextValue;
              custmadditionalPerilLabel[3] = element.FreeTextValue;
            }
          } else if (element.ElementCode === "RatingInput") {
            if (element.RowNum === 1) {
              quoteOptionData.additionalPerilValue1 = element.FreeTextValue
                ? (Number(element.FreeTextValue) * 100).toFixed(3).toString()
                : null;
              custmadditionalPerilvalue[1] = element.DecimalValue
                ? element.DecimalValue + ""
                : null;
            } else if (element.RowNum === 2) {
              quoteOptionData.additionalPerilValue2 = element.FreeTextValue
                ? (Number(element.FreeTextValue) * 100).toFixed(3).toString()
                : null;
              custmadditionalPerilvalue[2] = element.DecimalValue
                ? element.DecimalValue + ""
                : null;
            } else if (element.RowNum === 3) {
              quoteOptionData.additionalPerilValue3 = element.FreeTextValue
                ? (Number(element.FreeTextValue) * 100).toFixed(3).toString()
                : null;
              custmadditionalPerilvalue[3] = element.DecimalValue
                ? element.DecimalValue + ""
                : null;
            }
          } else {
            let riskObject = _.pickBy(
              RiskModel,
              riskValue => riskValue.name === element.ElementCode
            );
            let riskKey = _.keys(riskObject)[0];
            let riskValue: any;
            if (riskKey) {
              if (riskObject[riskKey].type === RiskResponseType.DateResponse) {
                riskValue = element.DateValue
                  ? this.dateService.moment(element.DateValue)
                  : null;
              } else if (
                riskObject[riskKey].type === RiskResponseType.DecimalResponse
              ) {
                riskValue = element.DecimalValue
                  ? (element.DecimalValue * 100).toFixed(3)
                  : null;
              } else if (
                riskObject[riskKey].type === RiskResponseType.FreeTextResponse
              ) {
                riskValue = element.FreeTextValue;
              } else if (
                riskObject[riskKey].type === RiskResponseType.MoneyResponse
              ) {
                riskValue = element.MoneyValue;
              }
              quoteOptionData[riskKey] = riskValue;
            }
          }
          if (element.ElementCode == "Money") {
            if (element.RowNum === 1) {
              quoteOptionData.option1 = element.MoneyValue
                ? element.MoneyValue
                : null;
            }
            if (element.RowNum === 2) {
              quoteOptionData.option2 = element.MoneyValue
                ? element.MoneyValue
                : null;
            }
            if (element.RowNum === 3) {
              quoteOptionData.option3 = element.MoneyValue
                ? element.MoneyValue
                : null;
              quoteOptionData.empty = "";
            }
            if (element.RowNum === 4) {
              quoteOptionData.option4 = element.MoneyValue
                ? element.MoneyValue
                : null;
            }
            if (element.RowNum === 5) {
              quoteOptionData.option5 = element.MoneyValue
                ? element.MoneyValue
                : null;
            }
          }
          if (
            element.ElementCode === APModel.bioChemTerrIncl &&
            element.DecimalValue
          ) {
            this.addCustomQuoteIconText(index, element.ElementName);
          }
          if (
            element.ElementCode === APModel.communicableDisease &&
            element.DecimalValue
          ) {
            this.addCustomQuoteIconText(index, element.ElementName);
          }
          if (
            element.ElementCode === APModel.limitedWar &&
            element.DecimalValue
          ) {
            this.addCustomQuoteIconText(index, element.ElementName);
          }
          if (
            element.ElementCode === APModel.nonAppearance &&
            element.DecimalValue
          ) {
            this.addCustomQuoteIconText(index, element.ElementName);
          }
          if (
            element.ElementCode === APModel.nuclearHazard &&
            element.DecimalValue
          ) {
            this.addCustomQuoteIconText(index, element.ElementName);
          }
          if (element.ElementCode === APModel.war && element.DecimalValue) {
            this.addCustomQuoteIconText(index, element.ElementName);
          }
        });
        this.addCustomQuoteIconTextAP(
          index,
          custmadditionalPerilLabel,
          custmadditionalPerilvalue
        );
        quoteOptions.push(quoteOptionData);
      }
    });
    return quoteOptions;
  }

  addCustomQuoteIconTextAP(index: number, labelObj: {}, valueObj: {}) {
    for (var key in labelObj) {
      if (labelObj.hasOwnProperty(key)) {
        if (labelObj[key]) {
          labelObj[key] = labelObj[key].trim();
          if (labelObj[key].slice(-1) === ":") {
            labelObj[key] = labelObj[key].substring(
              0,
              labelObj[key].length - 1
            );
            labelObj[key] = labelObj[key].trim();
          }
        }
      }
    }
    if (valueObj && valueObj[1] && labelObj[1]) {
      this.addCustomQuoteIconText(index, labelObj[1]);
    }
    if (valueObj && valueObj[2] && labelObj[2]) {
      this.addCustomQuoteIconText(index, labelObj[2]);
    }
    if (valueObj && valueObj[3] && labelObj[3]) {
      this.addCustomQuoteIconText(index, labelObj[3]);
    }
  }

  addCustomQuoteIconText(index: number, elementName: string) {
    if (this.customQuoteIconTexts && this.customQuoteIconTexts[index]) {
      this.customQuoteIconTexts[index] =
        this.customQuoteIconTexts[index] + ", " + elementName;
    } else {
      this.customQuoteIconTexts[index] = "Include " + elementName;
    }
  }

  setQuoteVersionList(UWQuoteList) {
    if (UWQuoteList) {
      this.quoteVersionList = [];
      UWQuoteList.forEach(items => {
        this.quoteVersionList.push({
          UWQuoteId: items.UWQuoteId,
          Description: items.QuoteDescription
        });
      });
      this.onloadSetQuoteVersion();
      this.selectConfig.config.selectConfig.options = this.quoteVersionList;
      this.quoteOptionForm.markAsPristine();
    }
  }
  onloadSetQuoteVersion() {
    if (this.quoteVersionList.length > 1) {
      this.isQuoteVersion = true;
      const SelectedUwQuoteId = sessionStorage.selectedUwQuoteId;
      this.currentUWQuoteID = SelectedUwQuoteId;
      this.selectedQuoteID = this.currentUWQuoteID;
      if (SelectedUwQuoteId) {
        this.quoteVersionList.forEach(element => {
          if (element.UWQuoteId == SelectedUwQuoteId) {
            this.quoteOptionForm.get("quoteVersion").setValue({
              UWQuoteId: element.UWQuoteId,
              Description: element.Description
            });
          }
        });
      } else {
        const lastUWQuote = this.quoteVersionList[
          this.quoteVersionList.length - 1
        ];
        this.quoteOptionForm.get("quoteVersion").setValue({
          UWQuoteId: lastUWQuote.UWQuoteId,
          Description: lastUWQuote.Description
        });
      }
    }
  }
  cleanEmptyProperties(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }
  prepareSaveEventRequest(): SaveEventRequest {
    const updateEventDetails = this.getUpdateEventDetailsRequest();
    let saveEventRequest: SaveEventRequest;
    saveEventRequest = {
      PolicyTxnId: this.policyTxnStoreData.PolicyTxnId
        ? Number(this.policyTxnStoreData.PolicyTxnId)
        : null,

      UpdateEventDetails: updateEventDetails
    };
    return saveEventRequest;
  }
  //WritingCompanyId: this.policychildComponent.carrierWritingCompanyId,
  getEndorsementDatafields(): UpdateUserConfigurableDataElements {
    let updateUserConfigurableDataElements: UpdateUserConfigurableDataElements = {};
    updateUserConfigurableDataElements.ElementUpdateRequests = this.childComponent._ElementUpdateRequestArray;
    return updateUserConfigurableDataElements;
  }
  getUpdateEventDetailsRequest(): UpdateEventDetails {
    let updateEventDetails: UpdateEventDetails;
    updateEventDetails = {
      PolicyTxnId: this.policyTxnStoreData.PolicyTxnId
        ? Number(this.policyTxnStoreData.PolicyTxnId)
        : null,
      UWQuoteId: this.currentUWQuoteID
        ? this.currentUWQuoteID
        : Number(this.policyTxnStoreData.UWQuotes[0].UWQuoteId),
      WritingCompanyId: 1169,
      StateId: this.childComponent.bindStateIDValue,
      UpdateUserConfigurableDataElement: this.getEndorsementDatafields(),
      QuoteDescription: "UW Quote Desc", // As per service team, pass any string
      Risks: this.filterRiskRequest(),
      CreateActivityLogItem: this.getCreateActivityLogItemRequest()
    };
    return updateEventDetails;
  }
  getCreateActivityLogItemRequest(): CreateActivityLogItemData {
    let createActivityLogItem: CreateActivityLogItemData;
    if (this.policyTxnStoreData.CreateActivityLogItem) {
      createActivityLogItem = {
        PolicyTxnId: Number(this.policyTxnStoreData.PolicyTxnId),
        ActivityType:
          ecaConstant.step3 ||
          this.policyTxnStoreData.CreateActivityLogItem.ActivityType,
        ActivityDate: this.dateService.now().toISOString(),
        Description:
          "saved step 3" ||
          this.policyTxnStoreData.CreateActivityLogItem.Description,
        LoggedInUser: this.userName
      };
    }
    return createActivityLogItem;
  }
  filterRiskRequest(): Risk[] {
    let quoteRisk: Risk[] = [];
    this.quoteStepStoreService.data$.subscribe((data: QuotePolicyStepStore) => {
      this.quotePolicyStepStore = data;
      if (
        this.quotePolicyStepStore &&
        this.quotePolicyStepStore.policyTxnData
      ) {
        let UWQuotes = this.quotePolicyStepStore.policyTxnData.UWQuotes;
        UWQuotes.forEach(element => {
          if (element.UWQuoteId == this.currentUWQuoteID) {
            const filteredUWQuote: UWQuotes = UWQuotes.find(
              (value: UWQuotes) => value.UWQuoteId == this.currentUWQuoteID
            );

            if (filteredUWQuote.LOBs && filteredUWQuote.LOBs.length !== 0) {
              const risksList = filteredUWQuote.LOBs[0].Risks;
              if (risksList && risksList.length !== 0) {
                risksList.forEach(items => {
                  if (items.RiskTypeCode == "FCH") {
                    let risk: Risk = {
                      RiskTypeCode: items.RiskTypeCode, // As per service team
                      StateId: this.policyTxnStoreData.StateId,
                      //UWLocations: [],  // Optional Field
                      RiskDataElements: items.RiskDataElements,
                      CurrencyCode: this.policyTxnStoreData.CurrencyCode,
                      RiskValueCurrencyCode: this.policyTxnStoreData
                        .CurrencyCode,
                      RiskDescription: "" // Need discussion
                    };
                    quoteRisk.push(risk);
                  }
                });
              }
            }
          }
        });
      }
    });
    return quoteRisk;
  }

  getRisksRequest(): Risk[] {
    const risks: Risk[] = [];
    if (this.quoteStore.eventList && this.quoteStore.eventList.length !== 0) {
      this.quoteStore.eventList.forEach((eventGridRow: EventGridRow) => {
        const risk: Risk = {
          RiskTypeCode: "FCH", // As per service team
          StateId: this.policyTxnStoreData.StateId,
          RiskDataElements: this.getRiskDataElementsRequest(eventGridRow),
          CurrencyCode: this.policyTxnStoreData.CurrencyCode,
          RiskValueCurrencyCode: this.policyTxnStoreData.CurrencyCode,
          RiskDescription: "" // Need discussion
        };
        risks.push(risk);
      });
    }
    return risks;
  }
  getRiskDataElementsRequest(eventGridRow: EventGridRow): RiskDataElements {
    let riskDataElements: RiskDataElements;
    riskDataElements = {
      ElementUpdateRequests: this.getElementUpdateRequestsRequest(eventGridRow)
    };
    return riskDataElements;
  }

  getElementUpdateRequestsRequest(
    eventGridRow: EventGridRow
  ): ElementUpdateRequest[] {
    let elementUpdateRequests: ElementUpdateRequest[] = [];

    for (var key in eventGridRow) {
      if (eventGridRow.hasOwnProperty(key)) {
        let responseType: string;
        let rowNumValue: number;
        let isAP: boolean = false;
        const dataElement = this.policyMasterData.UserConfigurableDataElements.find(
          value => {
            if (RiskModel.hasOwnProperty(key)) {
              if (RiskModel[key].isAP) {
                isAP = true;
                rowNumValue = RiskModel[key].rowNum;
              }
              if (value.ElementCode === RiskModel[key].name) {
                responseType = RiskModel[key].type;
                return true;
              }
            }
            return false;
          }
        );
        if (dataElement) {
          let elementUpdateReq: ElementUpdateRequest;

          let responseTypeField = _.invert(RiskResponseType)[responseType];
          let freeTextValueField: string;
          if (isAP) {
            if (eventGridRow[key] && Number(eventGridRow[key] / 100)) {
              freeTextValueField = Number(eventGridRow[key] / 100) + "";
            } else {
              freeTextValueField = eventGridRow[key];
            }
          } else if (responseType === RiskResponseType.FreeTextResponse) {
            freeTextValueField = eventGridRow[key] ? eventGridRow[key] : null;
          }
          let dateValueField =
            responseType === RiskResponseType.DateResponse
              ? eventGridRow[key]
              : null;
          let decimalValueField =
            responseType === RiskResponseType.DecimalResponse
              ? eventGridRow[key] / 100
              : null;
          let selectedDataField: number[] = null;
          let moneyValueField =
            responseType === RiskResponseType.MoneyResponse
              ? eventGridRow[key]
              : null;
          elementUpdateReq = {
            DataGroupId: dataElement.DataGroupId,
            ElementId: dataElement.ElementId,
            ElementCode: dataElement.ElementCode,
            ResponseType: responseTypeField,
            FreeTextValue: freeTextValueField,
            DateValue: dateValueField,
            DecimalValue: decimalValueField,
            SelectedDataElementResponseIds: selectedDataField,
            MoneyValue: moneyValueField,
            RowNum: rowNumValue ? rowNumValue : null
          };
          this.cleanEmptyProperties(elementUpdateReq);
          elementUpdateRequests.push(elementUpdateReq);
        }
      }
    }
    return elementUpdateRequests;
  }
  backButtonPressed() {
    this.router.navigate(["/events"]);
  }

  public isChildFormValid(formValid: any) {
    this.childFormValidCheck = formValid;
  }
  saveAndRefresh() {
    //this.saveAndNext();
    //this.loaderService.show();
    this.saveAndNext(false);
  }
  prepareUpdateAndDownloadQuoteECO(): UpdateAndDownloadQuoteECO {
    let updateAndDownloadQuoteECO: UpdateAndDownloadQuoteECO;
    updateAndDownloadQuoteECO = {
      PolicyTxnId: this.policyTxnStoreData.PolicyTxnId
        ? Number(this.policyTxnStoreData.PolicyTxnId)
        : null,
      UWQuoteId: this.currentUWQuoteID, //dynamic uWQuoteId
      DownloadFlag: false,
      StateId: this.childComponent.bindStateIDValue,
      PolicyDataElements: this.getEndorsementDatafields(),
      CreateActivityLogItem: this.getCreateActivityLogItemRequest()
    };
    return updateAndDownloadQuoteECO;
  }
  saveAndNext(routeToNext?: boolean) {
    this.submitted = true;
    this.childComponent.validateChildForm(this.submitted);
    if (
      this.quoteVersionList.length > 1 &&
      (!this.isFormValid || !this.childFormValidCheck)
    ) {
      this.validationService.setFormSubmission(true);
    } else if (!this.childFormValidCheck) {
      this.validationService.setFormSubmission(true);
    } else {
      this.quoteOptionForm.markAsPristine();
      this.loaderService.show();
      const downloadQuoteECOData = this.prepareUpdateAndDownloadQuoteECO();
      this.downloadQuoteService
        .updateAndDownloadQuoteECO(downloadQuoteECOData)
        .subscribe(data => {
          if (data.Status === "SUCCESS") {
            this.loaderService.hide();
            /**
             * To Update the store
             */
            if (this.policyTxnStoreData.PolicyTxnId) {
              const getPolicyTxnRequest: PolicyTxnRequest = {
                PolicyTxnID: this.policyTxnStoreData.PolicyTxnId.toString()
              };
              //this.quoteStepStoreService.setPolcyTxnData(getPolicyTxnRequest);

              this.QuotePolicyServiceInstance = this.store
              .dispatch(new QuotePolicyTxn(getPolicyTxnRequest))
              .subscribe(
                () => {
                  this.loaderService.hide();
                //  this..markAsPristine();
                  
                    if (routeToNext) {
                      this.router.navigate(["/bind"]);
                    }
                },
                () => {
                  this.loaderService.hide();
                }
              );
              
            }

          } else {
            this.loaderService.hide();
          }
        });
    }
  }
  initFormGroup() {
    const controls = {};
    controls[this.fieldSelectList.fieldName] = ["", Validators.required];
    this.quoteOptionForm = this.formbuilder.group(controls);
  }
  loadQuoteVersionData(policyTxnId, UwQuoteId) {
    if (policyTxnId) {
      this.loaderService.show();
      this.currentUWQuoteID = UwQuoteId;
      sessionStorage.selectedUwQuoteId = UwQuoteId;
      this.selectedQuoteID = this.currentUWQuoteID;
      this.quoteStepStoreService.data$.subscribe(
        (data: QuotePolicyStepStore) => {
          this.quotePolicyStepStore = data;
          if (data) {
            // const UWQuotes = this.quotePolicyStepStore.policyTxnData.UWQuotes.filter(
            //   items => items.UWQuoteId === this.currentUWQuoteID
            // );
            this.quoteOptionData(
              this.quotePolicyStepStore.policyTxnData.UWQuotes
            );
          }
        }
      );
    }
  }

  ngOnDestroy() {
    this.validationService.setFormSubmission(false);
    if(this.QuotePolicyServiceInstance){
      this.QuotePolicyServiceInstance.unsubscribe();
    }
  }
}
