import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { QuotePolicyInfoComponent } from "./quote-policy-info.component";

@NgModule({
  declarations: [QuotePolicyInfoComponent],
  imports: [CommonModule],
  exports: [QuotePolicyInfoComponent]
})
export class QuotePolicyInfoModule {}
