<form [formGroup]="frmGroup">
  <label *ngIf="fieldInfo.showLabel" [fieldInfo]="fieldInfo" acForLable>
    {{ fieldInfo.label }}<span *ngIf="fieldInfo.validation?.required">*</span>
  </label>
  <hcc-custom-validation [validations]="fieldInfo.validation">
    <input
      type="text"
      inputValidateRef
      [value]="value"
      [formControlName]="control"
      class="form-control"
      acInputText
      [control]="control"
      [fieldInfo]="fieldInfo"
      [placeholder]="fieldInfo.placeholder ? fieldInfo.placeholder : ''"
      (blur)="touchedInput($event)"
      [readOnly]="InputConfig?.isDisabled"
      [hidden] = "InputConfig?.isHidden"
    />
  </hcc-custom-validation>
</form>
