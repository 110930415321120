import { DialogConfig, DialogSize } from "@tmhcc/framwork/dialog";
import { CommonContentService, Labels } from "@tmhcc/content-service";
import { QuoteStepStoreService } from "../../store/facade/quote-step-store.service";
import { EndorsementForms } from "@tmhcc/models";

export interface FormsDialogConfigData {
  optionalForms?: any[];
  isDialogForEdit?: boolean;
  value?: any;
}
export interface FormsOptionConfigData {
  isDialogForEdit?: boolean;
  value?: any;
}

export class FormsDialogConfig {
  constructor(public contentService: CommonContentService) {
    this.getLabels = this.contentService.getLabels();
  }
  getLabels: Labels;

  dialogModalSettingConfigForDelete = {
    title: "Delete confirmation",
    hideHeaderX: true,
    overflow: true,
    hideFooter: true,
    dialogSize: DialogSize.LG
  };
  dialogModalSettingConfig = {
    overflow: true,
    hideFooter: true,
    dialogSize: DialogSize.LG,
    hideHeaderX: true,
    hideHeader: true
  };
}
