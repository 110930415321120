import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import {
  QuotePolicyStepStore,
  QuotePolicyStepState
} from "../../quote/store/state/quote-policy-step.state";
import { QuoteStepStoreService } from "../../quote/store/facade/quote-step-store.service";
import { PageHeaderService } from "../../shared/page-header/page-header.service";
@Component({
  selector: "ec-dashbard-nav",
  templateUrl: "./dashbard-nav.component.html",
  styleUrls: ["./dashbard-nav.component.scss"]
})
export class DashbardNavComponent implements OnInit {
  quotePolicyStepStore: QuotePolicyStepStore;
  constructor(
    private router: Router,
    private quoteStepStoreService: QuoteStepStoreService,
    private pageHeaderService: PageHeaderService
  ) {}
  createQuotePage() {
    this.pageHeaderService.setBindBadageCounter(0);
    this.router.navigate(["info"], { queryParams: { q: 1 } });
  }
  tabNavigation() {
    this.router.navigate(["info"], { queryParams: { q: 1 } });
  }
  ngOnInit(): void {}
}
