import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PolicySummaryComponent } from "./policy-summary.component";
import { Routes } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { CoreInputsModule } from "@tmhcc/framwork/core-inputs";
import { NgxsModule } from "@ngxs/store";
import { QuotePolicyStepState } from "../store/state/quote-policy-step.state";
import { QuoteState } from "../store/state/quote.state";
const routes: Routes = [{ path: "", component: PolicySummaryComponent }];

@NgModule({
  declarations: [PolicySummaryComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CoreInputsModule,
    [NgxsModule.forFeature([QuoteState, QuotePolicyStepState])]
  ],
  exports: [PolicySummaryComponent]
})
export class PolicySummaryModule {}
