import { Injectable } from "@angular/core";
import { AppConfigService } from "libs/global-services";

import { CurrencyTypes } from "./def/currency.def";
import { isEmpty } from "libs/util/null-util";
import { CurrencyPipe } from "@angular/common";
import { isNumber } from "util";
import { environment } from 'apps/policy-admin/src/environments/environment';
@Injectable()
export class CurrencyService {
  readonly defaultCurrency: CurrencyTypes;

  public constructor(
    configService: AppConfigService,
    private currencyPipe: CurrencyPipe
  ) {
    this.defaultCurrency = this.convertToCurrencyType(
      environment.appConfig.currencyPattern
    );
  }

  public getDefaultCurrencyType(): CurrencyTypes {
    return this.defaultCurrency;
  }

  // public toCurrency(value: number | string, currencyType?: string): string {
  //   // if (isEmpty(currencyType)) {
  //   //   currencyType = this.defaultCurrency;
  //   // }

  //   currencyType = this.convertToCurrencyType(currencyType);
  //   if (value) {
  //     return this.currencyPipe
  //       .transform(value, currencyType, "symbol-narrow", "1.2-2")
  //       .replace(/[$€₹]/g, "");
  //   }
  //   return "";
  // }

  public toCurrency(value: number | string, currencyType?: string): string {
    // if (isEmpty(currencyType)) {
    //   currencyType = this.defaultCurrency;
    // }
    value = typeof value === 'undefined' ? 0 : value;

    currencyType = this.convertToCurrencyType(currencyType);
    const formattedValues = this.currencyPipe.transform(
      Number(value),
      currencyType,
      'symbol-narrow',
      '1.2-2'
    );
    // .replace(/[$€₹]/g, '');
    return formattedValues;
  }

  public isValidCurrency(value: number | string): boolean {
    if (isEmpty(value) && !isNumber(value)) {
      return false;
    }

    return true;
  }

  public toNumber(): number {
    return 0;
  }

  private convertToCurrencyType(format: string): CurrencyTypes {
    if (isEmpty(format)) {
      format = "";
    }

    switch (format.toUpperCase()) {
      case CurrencyTypes.AUD:
        return CurrencyTypes.AUD;
      case CurrencyTypes.BRL:
        return CurrencyTypes.BRL;
      case CurrencyTypes.CAD:
        return CurrencyTypes.CAD;
      case CurrencyTypes.DKK:
        return CurrencyTypes.DKK;
      case CurrencyTypes.EUR:
        return CurrencyTypes.EUR;
      case CurrencyTypes.HKD:
        return CurrencyTypes.HKD;
      case CurrencyTypes.INR:
        return CurrencyTypes.INR;
      case CurrencyTypes.JPY:
        return CurrencyTypes.JPY;
      case CurrencyTypes.AED:
        return CurrencyTypes.AED;
      case CurrencyTypes.CHF:
        return CurrencyTypes.CHF;
      case CurrencyTypes.SEK:
        return CurrencyTypes.SEK;
      case CurrencyTypes.KRW:
        return CurrencyTypes.KRW;
      case CurrencyTypes.ZAR:
        return CurrencyTypes.ZAR;
      case CurrencyTypes.GBP:
        return CurrencyTypes.GBP;
      case CurrencyTypes.PLN:
        return CurrencyTypes.PLN;
      case CurrencyTypes.PKR:
        return CurrencyTypes.PKR;
      case CurrencyTypes.NOK:
        return CurrencyTypes.NOK;
      case CurrencyTypes.USD:
        return CurrencyTypes.USD;
      default:
        return CurrencyTypes.USD;
    }
  }
}
