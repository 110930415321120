import { FormGroup, FormControl } from "@angular/forms";
import moment from "moment-timezone";

// custom validator to check that two fields match
export function QuoteExpirtionDateValidator(
  expDate: string,
  latestEventDate: string,
  showDownload: boolean
) {
  return (formGroup: FormGroup) => {
    const ed = formGroup.controls[expDate];
    const today = moment();
    const isSameday = moment(ed.value).isSame(moment(latestEventDate), "day");
    const isSameOrBefore = moment(ed.value).isSameOrBefore(
      moment(latestEventDate)
    );

    if (!ed.value) {
      ed.setErrors({ required: true });
    } else if (
      !moment(ed.value).isSame(today, "day") &&
      moment(ed.value).diff(today) < 0
    ) {
      ed.setErrors({ pastDate: true });
    } else if (ed.value && !isSameday && !isSameOrBefore) {
      ed.setErrors({ invalidDate: true });
    } else {
      ed.setErrors(null);
    }
  };
}
