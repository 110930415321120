import {
  Component,
  OnInit,
  ComponentRef,
  Input,
  Inject,
  ViewContainerRef,
  Renderer2,
  ElementRef,
  OnDestroy,
  ComponentFactoryResolver,
  forwardRef
} from "@angular/core";
import { FormGroupDirective, NG_VALUE_ACCESSOR } from "@angular/forms";
import { CoreInput, InputTemplateService } from "../core-input.def";
import { IINPUT_TEMPLATE_SERVICE } from "../injection-tokens";
import { InputBase } from "../input-base";

@Component({
  selector: "hcc-input-generator",
  templateUrl: "./input-generator.component.html",
  styleUrls: ["./input-generator.component.sass"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputControlComponent),
      multi: true
    }
  ]
})
export class InputControlComponent extends InputBase<any>
  implements OnInit, OnDestroy {
  private componentRef: ComponentRef<CoreInput>;
  // private valueChangeSub: Subscription = new Subscription();

  constructor(
    @Inject(IINPUT_TEMPLATE_SERVICE)
    protected temlateService: InputTemplateService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private viewContainerRef: ViewContainerRef,
    private renderer: Renderer2,
    private el: ElementRef,
    @Inject(FormGroupDirective) public form: FormGroupDirective
  ) {
    super();
  }

  ngOnInit() {
    this.setControlConfig();
  }

  setControlConfig() {
    const inputConstruct = this.temlateService.getTemplate(this.fieldInfo);
    this.componentRef = this.createComponent(inputConstruct);
    this.createInstance();
    this.renderer.appendChild(
      this.el.nativeElement,
      this.componentRef.location.nativeElement
    );
  }

  createComponent<T>(componentName): ComponentRef<InputBase<T>> {
    this.viewContainerRef.clear();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory<
      InputBase<T>
    >(componentName);
    const componentRef = this.viewContainerRef.createComponent(
      componentFactory
    );
    return componentRef;
  }

  createInstance() {
    this.componentRef.instance.fieldInfo = this.fieldInfo;
    this.componentRef.instance.frmGroup = this.frmGroup;
    this.componentRef.instance.control = this.control;
    this.componentRef.instance.InputConfig = this.InputConfig;
  }

  ngOnDestroy() {
    // if (this.valueChangeSub) {
    //   this.valueChangeSub.unsubscribe();
    // }
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
