import { Provider } from "@angular/core";
import { ClaimSearchService } from "./cliam-search.service";
import { SaveEventService } from "./save-event.service";
import { QuotePolicySearchService } from "./quote-policy-search.service";
import { EcoMasterData } from "./eco-master.service";
import { EcoAgencyAgent } from "./eco-agency-agent.service";
import { CopyQuoteService } from "./copy-quote.service";
import { IssueNewBusinessService } from "./issue-new-business.service";
import { FormsECOService } from "./formsECO.service";
import { PolicyMasterDataService } from "./policy-master.service";

import { PolicyDownloadService } from "./policy-download.service";
import { KnrPolicyQuoteService } from "libs/data-service/kidnap-ransom/knr-policy-quote.service";

// services
export * from "./cliam-search.service";
export * from "./quote-policy-search.service";
export * from "./smarty-street-sdk.service";
export * from "./eco-master.service";
export * from "./eco-master.service";
export * from "./quote-create-bussiness.service";
export * from "./eco-agency-agent.service";
export * from "./save-event.service";
export * from "./quote-step.service";
export * from "./copy-quote.service";
export * from "./subjectivity.service";
export * from "./form-validation.service";
export * from "./issue-new-business.service";
export * from "./formsECO.service";
export * from "./policy-master.service";
export * from "./download-quote.service";

export * from "./policy-download.service";
export * from "./kidnap-ransom/knr-policy-quote.service";

// definitions
// export * from './def/get-dashboard';
export * from "./def/claim-search";
export * from "./def/policy-search";
export * from "./def/quote-policy-search";
export * from "./def/eco-dashboard-search";
export * from "./def/quote-general.def";
export * from "./def/eco-agency-agent";
export * from "./def/events";
export * from "./def/quote-policy-step";
export * from "./def/copy-quote";
export * from "./def/subjectivity";
export * from "./def/updateAndDownloadQuoteECO";
export {
  EcoMasterRequest,
  EcoMasterResponse,
  Country,
  StateOrProvince,
  Underwriters,
  TermTypes,
  PolicyTypes,
  SubmissionTypes,
  RenewalOptions,
  UnderwriterAssistants,
  PaymentPlans
} from "../data-service/def/eco-master";
export * from "./def/issue-new-business";
export * from "./def/eco-endorsment-forms";
export * from "./def/knr-quote-policy-search"
export * from "./def/knrsubjectivity"
export const DataServiceProviders: Provider[] = [
  ClaimSearchService,
  QuotePolicySearchService,
  EcoMasterData,
  EcoAgencyAgent,
  SaveEventService,
  CopyQuoteService,
  IssueNewBusinessService,
  FormsECOService,
  PolicyMasterDataService,
  PolicyDownloadService,
  KnrPolicyQuoteService
];
