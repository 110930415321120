import {
  InputConfig,
  FieldInfo,
  ControlInfo
} from "@tmhcc/framwork/form-inputs";

export interface GridDefinition {
  Columns: Array<InputConfig>;
  Rows: any;
  RowsPerPage?: number;
}

export interface ColumnDefinition {
  fieldInfo: FieldInfo;
  validation?: [];
  optionField?: any;
  columnType?: Types; // required to prashant
  isSortable?: boolean;
  columnEventType?: Controls;
  controlInfo?: ControlInfo;
  alignment?: string;
}

export const enum AddRowTypes {
  Link = 1,
  Popup = 2
}

// export interface popUpDataConfig{
//   config : Array;
// }
export enum EventTypeFromGrid {
  radioBox = 'radioBox',
  checkBox = 'checkBox',
  link = 'link',
  delete = 'delete',
  view = 'view',
  info = 'Info',
  edit = 'edit' 
}

export interface GridOptions {
  addRow?: boolean;
  deleteRow?: boolean;
  paging?: boolean;
  pageLength?: number;
  searching?: boolean;
  ordering?: boolean;
  orderBy?: number;
  orderAscending?: string;
  isCheckboxRequired?: boolean;
  responsive?: boolean;
  addRowType?: AddRowTypes;
  popDataConfig?: Array<any>;
  emptyTable?: string;
}

export interface AccordionGrid {
  columnDefination: ColumnDefinition[];
  gridOptions: GridOptions;
  data: any;
}

export enum Types {
  link = "link",
  default = "default",
  date = "date",
  string = "string",
  currency = "currency",
  view = "view",
  checkbox = "checkbox",
  empty = "empty"
}
export enum Controls {
  radioCheckBoxMatrix = "radioCheckBoxMatrix",
  matrixCheckBox = "matrixCheckBox"
}
