<div class="card bg-light quote-summary">
  <div class="card-body">
    <form [formGroup]="quoteSummaryForm">
      <h5 class="header-tab text-center mb-3">
        {{ getLabels.quoteSummaryHeading }}
      </h5>
      <div class="form-group row">
        <label for="" class="col-md-6"><strong>Product:</strong></label>
        <div class="col-md-6">{{ policyType }}</div>
      </div>
      <div class="form-group row" *ngIf="showStandardSummary">
        <label for="" class="col-md-6"
          ><strong>{{ getLabels.limitOfInsurance }}</strong></label
        >
        <div class="col-md-6" *ngIf="showStandardSummary">
          <div class="limit-insurance">
            <div>{{ LimitOfInsurance | currency: "USD" }}</div>
          </div>
        </div>
      </div>
      <div class="form-group row" *ngIf="!showStandardSummary">
        <label for="" class="col-md-6"
          ><strong>{{ getLabels.limitOfInsurance }}</strong></label
        >
      </div>
      <div class="form-group" *ngIf="!showStandardSummary">
        <div class="row">
          <div class="col-md-6">Section I - Coverage:</div>
          <div class="col-md-6">{{ LimitOfInsurance | currency: "USD" }}</div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p class="pl-2">A: Event Cancellation</p>
            <p class="pl-2">B: ERA</p>
            <p class="pl-2">C: Failure to Vacate Venue</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Section II - Coverage:</div>
          <div class="col-md-6">$250,000 per loss</div>
        </div>
        <div class="row">
          <div class=" col-md-6">
            <p class="pl-2">D: Physical Loss To Personal Property</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">Section III - Coverage:</div>
          <div class="col-md-6">$250,000 per loss</div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <p class="pl-2">E: Door Registration Receipts</p>
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="" class="col-md-6"
          ><strong>{{ getLabels.policyEffLabl }}</strong></label
        >
        <div class="col-md-6">TBD</div>
      </div>
      <div class="form-group row">
        <label for="" class="col-md-6"
          ><strong>{{ getLabels.policyExpLbl }}</strong></label
        >
        <div class="col-md-6">{{ policyExpDate }}</div>
      </div>

      <div class="row form-group">
        <label for="" class="col-md-6 col-form-label"
          ><strong> {{ getLabels.FillingResponsibilityLabel }}:</strong></label
        >
        <div class="col-md-6">
          <hcc-select
            [control]="FrSelectConfig.fieldInfo.fieldName"
            [frmGroup]="quoteSummaryForm"
            [fieldInfo]="FrSelectConfig.fieldInfo"
            [InputConfig]="FrSelectConfig.config"
          >
          </hcc-select>
        </div>
      </div>
      <div class="row form-group" *ngIf="showTaxState">
        <label for="" class="col-md-6 col-form-label"
          ><strong>{{ getLabels.QuoteSummaryTaxState }}:</strong></label
        >
        <div class="col-md-6">
          <hcc-select
            [control]="TaxSelectConfig.fieldInfo.fieldName"
            [frmGroup]="quoteSummaryForm"
            [fieldInfo]="TaxSelectConfig.fieldInfo"
            [InputConfig]="TaxSelectConfig.config"
          >
          </hcc-select>
        </div>
      </div>

      <h5 class="sub-header mb-2 mt-4">
        {{ getLabels.QuoteSummaryEventInformation }}
      </h5>
      <div class="form-group " *ngFor="let info of premiumSummary">
        <div class="event-info row">
          <label for="" class="col-md-6"
            ><strong>{{ info.Description }}:</strong>
          </label>
          <div class="col-md-6">{{ info.Amount | currency }}</div>
        </div>
      </div>
      <div class="row">
        <div class="col info-font">
          {{ getLabels.summaryTextInfo }}
        </div>
      </div>
    </form>
    <div *ngIf="isPolicyBind" class="row">
      <div class="col text-center my-3">
        <button
          type="button"
          [disabled]="isPolicyBind"
          tabindex="0"
          class="toggleQuoteDownloadButton"
        >
          {{ getLabels.QuoteSummaryDownLoadQuoteHeading }}
        </button>
      </div>
    </div>
    <form *ngIf="!isPolicyBind" [formGroup]="quoteDownloadForm" (ngSubmit)="onDownloadQuote()">
      <div class="row">
        <div class="col text-center my-3">
          <button
            type="button"
            (click)="toggle()"
            tabindex="0"
            class="toggleQuoteDownloadButton"
          >
            {{ getLabels.QuoteSummaryDownLoadQuoteHeading }}
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col" *ngIf="showDownloadDiv">
          <div class="form-group row mt-3">
            <label for="" class="col-md-6 col-form-label"
              ><strong>Quote Expiration Date</strong></label
            >
            <div class="col-md-6 isQuoteDownloadFormInput">
              <div
                [ngClass]="{
                  hasError:
                    isQuoteDownloadFormError && isQuoteDownloadFormSubmitted
                }"
              >
                <hcc-datepicker-input
                  [control]="QuoteExpirationDateConfig.fieldInfo.fieldName"
                  [frmGroup]="quoteDownloadForm"
                  [fieldInfo]="QuoteExpirationDateConfig.fieldInfo"
                  [InputConfig]="QuoteExpirationDateConfig.config"
                >
                </hcc-datepicker-input>
                <ng-container *ngIf="isQuoteDownloadFormSubmitted">
                  <span class="text-danger"> {{ quoteDownloadErrorMsg }}</span>
                </ng-container>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-center">
              <button type="submit" class="btn btn-primary">
                {{ getLabels.download }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
