import { Provider } from "@angular/core";
import { CommonContentService } from "./common-content.service";

// def
export * from "./def/common-content";

// services
export * from "./common-content.service";

export const ContentServiceProviders: Provider[] = [CommonContentService];
