import { Component, OnInit,OnDestroy } from '@angular/core';
import {AccountSetupDefinitionService} from "./../config/knr-account-setup-config";
import { CommonContentService } from "@tmhcc/content-service";
import { DateService } from "@tmhcc/framwork/services";
import { InputConfig } from "@tmhcc/framwork/form-inputs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { FieldInfo } from "@tmhcc/framwork/form-inputs";
import { CoreInputValidationService } from '@tmhcc/framwork/core-inputs';
import { LoaderService } from "@tmhcc/framwork/loader";
import {KnrPolicyQuoteService} from "libs/data-service/kidnap-ransom/knr-policy-quote.service";
import {Router} from "@angular/router";
import {      
  QuoteResponseData,QuoteDetail,
  UpdateQuoteRequest,
  ProductTypeUpdate,
  Insured,
  UnderwritingInformation,
  BrokerInformation,
  BrokerAddress,
  InsuredAddress
} from "libs/models/classes/knr-quote-details.model";
import { Address, KnrPolicySearchResponse } from '@tmhcc/models';
import { ToastrService } from 'ngx-toastr';
import {AccountSetupResponse, LinesOfBusiness, PolicyType,Carrier} from "libs/models/classes/knr-account-data.model";
//import { debug } from 'console';
@Component({
  selector: 'ec-knr-account-setup',
  templateUrl: './knr-account-setup.component.html',
  styleUrls: ['./knr-account-setup.component.scss']
})

export class KnrAccountSetupComponent extends AccountSetupDefinitionService
 implements OnInit{ 
  createQuoteFormControl: Object = {};
  valueSub = new Subscription();
  AccFormGroup: FormGroup;
  accountSetupInfoField: InputConfig[];
  firstNamedSetupInfoField:InputConfig[]; 
  underwritingInfoField: InputConfig[];
  brokerInformationInfoField: InputConfig[];
  accountSetupData: AccountSetupResponse;
  constructor(
    public commonContentService: CommonContentService,
    private dateService: DateService,
    private formBuilder: FormBuilder,
    private validationService: CoreInputValidationService,
    private loaderService: LoaderService,
    private knrPolicyQuoteService: KnrPolicyQuoteService,
    private toasterService: ToastrService,
    private router: Router
    ) {      
      super();
    }
  ngOnInit(): void {  
    this.getPageControlsData();        
  } 
  getQuoteDetails(quoteId:any)
  {
    this.loaderService.show();
   // let quoteId = sessionStorage.getItem('_quoteId');
    this.knrPolicyQuoteService.getQuoteDetails(quoteId).subscribe(data =>{       
       this.setQuoteDetailFormData(data);
       this.loaderService.hide();
    });
  }
  getPageControlsData()
  {
    this.loaderService.show();   
    this.knrPolicyQuoteService.getAccountSetupData().subscribe(data =>{  
     this.accountSetupData = data;
    let lob = this.getLineofBusinessArray(data.LinesOfBusiness);
    let policytype = this.getLPolicyTypeArray(data.PolicyType);
    this.accountSetupInfoField = this.productTypeConfig(lob,policytype).productTypeConfigList;

    //firstname insured field
    let countryNames = data.Countries.map((item) => {
      return item.description ;
    });
    let stateNames = data.StatesOrProvinces.map((item) => {
      return item.description ;
    });
    this.firstNamedSetupInfoField = this.firstNamedInsuredConfig(countryNames,stateNames).firstNamedInsuredConfigList;
    
    //underwriter fields
    let underwriters = data.Underwriters.map((item) => {
      return item.name;
    });
    let asstunderwriters = data.AssistantUnderwriters.map((item) => {
      return item.name;
    });
    this.underwritingInfoField = this.underwritingInformationConfig(underwriters,asstunderwriters).underwritingInformationConfigList;
    //broker fields
    let brokerName = data.Brokers.map((item) => {
      return item.name ;
    });
    this.brokerInformationInfoField = this.brokerInformationConfig(brokerName).brokerInformationConfigList;    
    this.setFormControl();  
    this.codeNamedPolicyRadioBtnChange();
    if(sessionStorage.getItem('_quoteId') != undefined)
      this.getQuoteDetails(sessionStorage.getItem('_quoteId'));     
    this.onFormChange();
    this.loaderService.hide();
    //call lob value change
    this.onLOBChange();
    });
  }
  getLineofBusinessArray(lineofbusiness:LinesOfBusiness[]){   
    let lob=[];
    lineofbusiness.forEach(element => {
      lob.push(element.code);
     });
     return lob;
  }
  getLPolicyTypeArray(data:PolicyType[]){   
    let policyType=[];
    data.forEach(element => {
      policyType.push(element.type);
     });
     return policyType;
  }
  setQuoteDetailFormData(data:any){    
    this.accountSetupInfoField.forEach((element: InputConfig) => {
      if (element.fieldInfo.fieldName === 'quotenumber') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.productType.quoteNumber);
      }
      else if (element.fieldInfo.fieldName === 'lob') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.productType.lob);
      }
      else if (element.fieldInfo.fieldName === 'policytype') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.productType.policyType);
      }
      else if (element.fieldInfo.fieldName === 'carrier') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.productType.carrier);
      }
      else if (element.fieldInfo.fieldName === 'currency') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.productType.currency);
      }
      else if (element.fieldInfo.fieldName === 'territory') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.productType.territory);
      }
    });
    //Insured field setup
    this.firstNamedSetupInfoField.forEach((element: InputConfig) => {
      if (element.fieldInfo.fieldName === 'insuredname') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.insured.insuredName);
      }
      else if (element.fieldInfo.fieldName === 'codename') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.insured.codeName);
      }
      // else if (element.fieldInfo.fieldName === 'namedpolicy') {
      //   if(data.quoteDetails.insured.namedPolicy === 'true')
      //   {
      //    this.AccFormGroup
      //     .get(element.fieldInfo.fieldName)
      //     .setValue('Yes');
      //   }
      //   else{
      //     this.AccFormGroup
      //     .get(element.fieldInfo.fieldName)
      //     .setValue('No');
      //   }
      // }
      else if (element.fieldInfo.fieldName === 'radiocodenamed') {        
        if(data.quoteDetails.insured.namedPolicy === 'Yes')
        {
          element.config.radioCofig.radioGroup.forEach(element => {
            if (element.label == 'Yes') {
              element.checked = true;
            } 
          });
         this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue('Yes');
        }
        else{
          element.config.radioCofig.radioGroup.forEach(element => {
            if (element.label == 'No') {
              element.checked = true;
            }
          });
          this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue('No');
        }
      }
      else if (element.fieldInfo.fieldName === 'country') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.insured.address.country);
      }
      else if (element.fieldInfo.fieldName === 'country') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.insured.address.country);
      }
      else if (element.fieldInfo.fieldName === 'address') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.insured.address.suiteOrUnit);
      }
      else if (element.fieldInfo.fieldName === 'city') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.insured.address.city);
      }
      else if (element.fieldInfo.fieldName === 'stateprovince') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.insured.address.state);
      }
      else if (element.fieldInfo.fieldName === 'stateprovince') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.insured.address.state);
      }
      else if (element.fieldInfo.fieldName === 'zip') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.insured.address.zipCode);
      }
      else if (element.fieldInfo.fieldName === 'class') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.insured.class);
       }
    });
    //Underwriting information
    this.underwritingInfoField.forEach((element: InputConfig) => {
      if (element.fieldInfo.fieldName === 'underwriter') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.underwritingInformation.underwriter);
        }
      else if (element.fieldInfo.fieldName === 'underwriterassistant') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.underwritingInformation.underwriterAssistant);
       }
    });
   //broker information  section   
    this.brokerInformationInfoField.forEach((element:InputConfig) =>{
      if (element.fieldInfo.fieldName === 'broker') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.brokerInformation.brokerName);
        }
      else if (element.fieldInfo.fieldName === 'brokercommission') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.brokerInformation.brokerCommissionPercentage);
       } 
       else if (element.fieldInfo.fieldName === 'brokercommission') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.brokerInformation.brokerCommissionPercentage);
       } 
       else if (element.fieldInfo.fieldName === 'brokernumber') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.brokerInformation.brokerNumber);
       } 
       else if (element.fieldInfo.fieldName === 'brokeraddress') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.brokerInformation.brokerAddress.suiteOrUnit);
       } 
       else if (element.fieldInfo.fieldName === 'brokercity') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.brokerInformation.brokerAddress.city);
       } 
       else if (element.fieldInfo.fieldName === 'brokercity') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.brokerInformation.brokerAddress.city);
       } 
       else if (element.fieldInfo.fieldName === 'brokerstate') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.brokerInformation.brokerAddress.state);
       } 
       else if (element.fieldInfo.fieldName === 'brokerzip') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.brokerInformation.brokerAddress.zipCode);
       } 
       else if (element.fieldInfo.fieldName === 'brokercontactname') {
        this.AccFormGroup
          .get(element.fieldInfo.fieldName)
          .setValue(data.quoteDetails.brokerInformation.contactName);
       }
    });
  }
  onFormChange()
  {
    this.AccFormGroup
    .get('broker')
    .valueChanges.subscribe((data:string) => {    
      let brokerdata = this.accountSetupData.Brokers.filter(e => e.name === data);       
      this.brokerInformationInfoField.forEach((element)=>{        
        if(brokerdata.length > 0){
            if(element.fieldInfo.fieldName === 'brokernumber'){
            element.fieldInfo.value = brokerdata[0].number;  
            this.AccFormGroup.controls['brokernumber'].setValue(brokerdata[0].number);              
            }
            if(element.fieldInfo.fieldName === 'brokeraddress'){
              element.fieldInfo.value = brokerdata[0].address; 
              this.AccFormGroup.controls['brokeraddress'].setValue( brokerdata[0].address);              
            }
            if(element.fieldInfo.fieldName === 'brokercity'){
              element.fieldInfo.value = brokerdata[0].city; 
              this.AccFormGroup.controls['brokercity'].setValue(brokerdata[0].city);               
            }
            if(element.fieldInfo.fieldName === 'brokerstate'){
              element.fieldInfo.value = brokerdata[0].state; 
              this.AccFormGroup.controls['brokerstate'].setValue(brokerdata[0].state);              
            }
            if(element.fieldInfo.fieldName === 'brokerzip'){
              element.fieldInfo.value = brokerdata[0].zip;
              this.AccFormGroup.controls['brokerzip'].setValue(brokerdata[0].zip);               
            }
          }
      });

    }); 
  }
  setFormControl(){
   // debugger;
    this.loaderService.show();
    const formControls = {};   
    this.accountSetupInfoField.forEach((element: InputConfig) => {      
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      formControls[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        Validators.compose(validators)
      ];
     // formControls[element.fieldInfo.fieldName] = ["", Validators.required];
    });    
    this.firstNamedSetupInfoField.forEach((element: InputConfig) => {      
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      formControls[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        Validators.compose(validators)
      ];
     // formControls[element.fieldInfo.fieldName] = ["", Validators.required];
    });   
    this.underwritingInfoField.forEach((element: InputConfig) => {      
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      formControls[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        Validators.compose(validators)
      ];
     // formControls[element.fieldInfo.fieldName] = ["", Validators.required];
    });   
    this.brokerInformationInfoField.forEach((element: InputConfig) => {      
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      formControls[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        Validators.compose(validators)
      ];
     // formControls[element.fieldInfo.fieldName] = ["", Validators.required];
    });   
    this.AccFormGroup = this.formBuilder.group(formControls);
    this.loaderService.hide();
   // this.validationService.setFormSubmission(true);
  } 
  onSubmit(){
    this.validationService.setFormSubmission(true);
    if (this.AccFormGroup.valid) {
       this.loaderService.show();
      let formValue = new QuoteDetail();
      const payload = this.mapNewQuoteForm();
      this.knrPolicyQuoteService.updateQuote(payload).subscribe((data: QuoteDetail) =>{        
        this.toasterService.success(
          'Quote updated successfully'
        ); 
        sessionStorage.setItem('_quoteId',data.QuoteId);
        this.loaderService.hide();         
        setTimeout(() => {
          this.router.navigate(['/knr-dashboard/account-setup/rating'])
        }
        , 2000);
      }); 
    }
  }
  onLOBChange(){
    this.AccFormGroup
    .controls['lob']
    .valueChanges.subscribe(value => {      
      let carrierdata = this.accountSetupData.LinesOfBusiness.filter(e => e.code === value);
      let carrierNames = carrierdata[0]['carrier'].map((item) => {
        return item.name;
     });
     let currencydata = this.accountSetupData.LinesOfBusiness.filter(e => e.code === value);
     let currencyNames = currencydata[0]['currencies'].map((item) => {
       return item.description ;
    });
    this.accountSetupInfoField.forEach((element)=>{
        if(element.fieldInfo.fieldName === 'carrier'){
        element.config.selectConfig.options = carrierNames;
        element.config.selectConfig.optionConfig.displayKey = 'name';
        // this.AccFormGroup
        // .get(element.fieldInfo.fieldName)
        // .setValue(this.dialogConfig.data.Country);
        }
    });
    this.accountSetupInfoField.forEach((element)=>{
      if(element.fieldInfo.fieldName === 'currency'){
        element.config.selectConfig.options = currencyNames;
        element.config.selectConfig.optionConfig.displayKey = 'description';       
      }
     });
  });
}
codeNamedPolicyRadioBtnChange(){
  this.AccFormGroup.controls['radiocodenamed'].valueChanges.subscribe(value =>{   
   
      this.firstNamedSetupInfoField.forEach((element:InputConfig) =>{
        //debugger;
       if(element.fieldInfo.fieldName === 'codename')
        {             
          if(value === 'Yes'){  
            //this.AccFormGroup.controls[element.fieldInfo.fieldName].enable();   
            element.config.isHidden = false;   
            element.fieldInfo.showLabel = true; 
            element.config.validation = [
              Validators.required
            ];
            element.fieldInfo.validation = {
              required: "Required field"
            };
            this.AccFormGroup.controls[element.fieldInfo.fieldName]
                .setErrors({ required: true });   
            this.AccFormGroup.controls[element.fieldInfo.fieldName]
                .setValidators([Validators.required,Validators.nullValidator]);  
          }  
          else if(value === '' || value === null || value === 'No')
          {   
            element.config.isHidden = true;  
            element.fieldInfo.showLabel = false;         
            //this.AccFormGroup.controls[element.fieldInfo.fieldName].disable();  
            this.AccFormGroup.controls[element.fieldInfo.fieldName]
            .setValue(null);              
            this.AccFormGroup.controls[element.fieldInfo.fieldName]
                  .setErrors(null);   
          } 
        }       
      });   
   });
}
mapNewQuoteForm(): UpdateQuoteRequest {
          let quoteRecord = new UpdateQuoteRequest(); 
          if(sessionStorage.getItem('_quoteId') !== undefined 
            || sessionStorage.getItem('_quoteId') !== null )    
          {
          //edit
              quoteRecord.QuoteId = sessionStorage.getItem('_quoteId');
              quoteRecord.QuoteNumber = this.AccFormGroup.controls['quotenumber'].value;
          }
          else
          { 
              quoteRecord.QuoteId = null;
              quoteRecord.QuoteNumber = null;
          }
          quoteRecord.PolicyFrom = '2023-06-14T00:00:00';
          quoteRecord.PolicyTo = '2023-06-24T00:00:00';
          quoteRecord.TimeZoneOffset = '-05:00';          
          //product type
          let product = new ProductTypeUpdate();
          product.Lob = this.AccFormGroup.controls['lob'].value;
          product.PolicyType = this.AccFormGroup.controls['policytype'].value;
          product.Carrier = this.AccFormGroup.controls['carrier'].value;
          product.Currency = this.AccFormGroup.controls['currency'].value;
          product.Territory = this.AccFormGroup.controls['territory'].value;
          quoteRecord.ProductType = product;
          //insured
          let insured = new Insured();
          insured.InsuredName = this.AccFormGroup.controls['insuredname'].value;
          insured.CodeName = this.AccFormGroup.controls['codename'].value;
         // insured.NamedPolicy = this.AccFormGroup.controls['namedpolicy'].value;
         insured.NamedPolicy = this.AccFormGroup.controls['radiocodenamed'].value;
         
          let address = new InsuredAddress();
          address.Street = this.AccFormGroup.controls['address'].value;
          address.SuiteOrUnit = this.AccFormGroup.controls['address'].value;
          address.City = this.AccFormGroup.controls['city'].value;
          address.State = this.AccFormGroup.controls['stateprovince'].value;
          address.ZipCode = this.AccFormGroup.controls['zip'].value;
          address.Country = this.AccFormGroup.controls['country'].value;
          insured.Address = address;
          insured.Class = this.AccFormGroup.controls['class'].value;
          quoteRecord.Insured = insured;
          //Underwriting
          let underwriter = new UnderwritingInformation();
          underwriter.Underwriter = this.AccFormGroup.controls['underwriter'].value;
          underwriter.UnderwriterAssistant = this.AccFormGroup.controls['underwriterassistant'].value;
          quoteRecord.UnderwritingInformation = underwriter;
          //broker info
          let broker = new BrokerInformation();
          broker.BrokerName = this.AccFormGroup.controls['broker'].value;
          broker.BrokerNumber = this.AccFormGroup.controls['brokernumber'].value;
          broker.BrokerCommissionPercentage = Number(this.AccFormGroup.controls['brokercommission'].value);          
          let brokeraddress = new BrokerAddress();
          brokeraddress.SuiteOrUnit = this.AccFormGroup.controls['brokeraddress'].value;
          brokeraddress.Street = this.AccFormGroup.controls['brokeraddress'].value;
          brokeraddress.City = this.AccFormGroup.controls['brokercity'].value;
          brokeraddress.State = this.AccFormGroup.controls['brokerstate'].value;
          brokeraddress.ZipCode = this.AccFormGroup.controls['brokerzip'].value;
          brokeraddress.Country = this.AccFormGroup.controls['country'].value;
          broker.BrokerAddress = brokeraddress;
          broker.ContactName = this.AccFormGroup.controls['brokercontactname'].value;
          quoteRecord.BrokerInformation = broker;
        
        return quoteRecord;
    } 
    onBackClick(){
      this.router.navigate(['/knr-dashboard'])
    }
    onRatingClick()
    {
      this.router.navigate(['/knr-dashboard/account-setup/rating'])
    }
  }

