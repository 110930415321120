import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { DataTableDirective } from "angular-datatables";

@Injectable({
  providedIn: "root"
})
export class FormValidationService {
  myMethod$: Observable<any>;
  private myMethodSubject = new Subject<any>();

  constructor() {
    this.myMethod$ = this.myMethodSubject.asObservable();
  }

  isFormValidate(data) {
    // I have data! Let's return it so subscribers can use it!
    // we can do stuff with data if we want
    this.myMethodSubject.next(data);
    return data;
  }
}
