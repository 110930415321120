<form [formGroup]="frmGroup">
  <div class="form-check">
    <hcc-custom-validation [validations]="fieldInfo.validation">
      <input
        type="checkbox"
        [checked]="value"
        inputValidateRef
        updateEvent="change"
        [formControlName]="control"
        acCheckbox
        [control]="control"
        [formGroup]="frmGroup"
        [fieldInfo]="fieldInfo"
        class="form-check-input"
      />
    </hcc-custom-validation>
    <label *ngIf="fieldInfo.showLabel" [fieldInfo]="fieldInfo" acForLable>
      {{ fieldInfo.label }}<span *ngIf="fieldInfo.validation?.required">*</span>
    </label>
  </div>
</form>
