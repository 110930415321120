import { Component, OnInit } from '@angular/core';
import { FieldInfo, FieldTypes } from "@tmhcc/framwork/form-inputs";
import { FormGroup, FormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";
import { KnrDashoardStoreService } from "../knr-store/facade/knr-dashbard-store.service";
import { KnrDashboardStore } from '../knr-store/state/knr-dashbard.state';
import { LoaderService } from "@tmhcc/framwork/loader";
import {      
  KnrPolicySearchRequest
} from "@tmhcc/data-service/def/knr-quote-policy-search";
import {
  Router,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationStart
} from "@angular/router";
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'ec-knr-search',
  templateUrl: './knr-search.component.html',
  styleUrls: ['./knr-search.component.scss']
})
export class KnrSearchComponent implements OnInit {
  entityNumberValQuotes: string = null;
  insuredNameValQuotes: string = null;  
  insuredNameVal: string = null;
  entityNumberValPolicy: string = null;
  insuredNameValPolicy: string = null;  
  searchFirstTime: boolean = false;
  searchForm: FormGroup;  
  
  policyNumber: FieldInfo = {
    fieldName: "policyNumber",
    label: "policyNumber",
    fieldType: FieldTypes.Text
  };
  quotesNumber: FieldInfo = {
    fieldName: "quotesNumber",
    label: "quotesNumber",
    fieldType: FieldTypes.Text
  };
  insuredName: FieldInfo = {
    fieldName: "insuredName",
    label: "insuredName",
    fieldType: FieldTypes.Text
  };
  valuSub = new Subscription();
  isSubmitValid: boolean = false;
  dashboardStoreData: KnrDashboardStore;
  constructor(
    private fb: FormBuilder,
    private knrDashoardStoreService: KnrDashoardStoreService,
    private loaderservice: LoaderService,
    private router:Router,
    private activatedRoute: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.buildSearchForm();
    this.setSubscription();
    this.onSearchFormChanges();
  }

  setSubscription() {
    //this.knrDashoardStoreService.setSearchType(this.searchBy);
    //this.dashoardStoreService.getDashboardData({ SubCompanyCode: "ECO" });
    this.knrDashoardStoreService.datad$.subscribe((data: KnrDashboardStore) => {
      this.dashboardStoreData = data;
    });
  }
  onSearchFormChanges() {
    this.valuSub.add(
      this.searchForm.valueChanges.subscribe(value => {
        this.searchForm = this.searchForm;
        if (
          this.searchForm.valid &&
          (this.searchForm.controls.entitySearchFieldQuotes.value ||
            this.searchForm.controls.entitySearchFieldPolicy.value ||            
            this.searchForm.controls.insuredNameField.value)
        ) {
          this.isSubmitValid = true;
        } else {
          this.isSubmitValid = false;
        }
      })
    );
  }  

  buildSearchForm() {
    this.searchForm = this.fb.group({
      entitySearchFieldQuotes: [this.entityNumberValQuotes],
      entitySearchFieldPolicy: [this.entityNumberValPolicy],
      insuredNameField: [this.insuredNameVal]      
    });
  }  
  onSubmit() {
    this.searchFirstTime = true;
    if (this.searchForm.valid) {
      this.loaderservice.show();
      if (this.searchForm.get("insuredNameField").value) {
        this.searchForm
          .get("insuredNameField")
          .setValue(this.searchForm.get("insuredNameField").value.trim());
      }
      if (this.searchForm.get("entitySearchFieldQuotes").value) {
        this.searchForm
          .get("entitySearchFieldQuotes")
          .setValue(this.searchForm.get("entitySearchFieldQuotes").value.trim());
      }
      if (this.searchForm.get("entitySearchFieldPolicy").value) {
        this.searchForm
          .get("entitySearchFieldPolicy")
          .setValue(this.searchForm.get("entitySearchFieldPolicy").value.trim());
      }
    
      const {
        entitySearchFieldQuotes,
        entitySearchFieldPolicy,
        insuredNameField        
      } = this.searchForm.value;  

      this.searchPolicies(
        entitySearchFieldQuotes,
        entitySearchFieldPolicy,
        insuredNameField       
      );      
    }    
  }
  searchPolicies(
    quotesNumber: string,
    policyNumber: string,
    insuredName: string    
  ) {   
   
    // const knrdashboardSearchRequest: KnrPolicySearchRequest = {
    //   RandomQuote: quotesNumber ? quotesNumber : '',
    //   RandomPolicy: policyNumber ? policyNumber : '',
    //   insured: insuredName ? insuredName : ''     
    // };  
    const knrdashboardSearchRequest : KnrPolicySearchRequest = {
      quoteNumber: quotesNumber ? quotesNumber : '',
      policyNumber: policyNumber ? policyNumber : '',
      insuredName: insuredName ? insuredName : '' ,
      isSortAscending: true,
      sortBy: "",
      page: 0,
      pageSize: 10
   };  
    this.knrDashoardStoreService.getKnrPolicyData(knrdashboardSearchRequest);
  }
  onCreateQuoteClick()
  {
    //this.router.navigate(['/account-setup'], {relativeTo: this.activatedRoute});
    this.router.navigate(['/knr-dashboard/account-setup']);
  }
}
