import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ViewSearchComponent } from "./view-search.component";
import { GridModule } from "@tmhcc/framwork/grid";
import { NgxsModule } from "@ngxs/store";
import { QuoteStepService } from "@tmhcc/data-service";
import { QuotePolicyStepState } from "../../quote/store/state/quote-policy-step.state";
import { ReactiveFormsModule,FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ViewSearchComponent],
  imports: [
    CommonModule,
    GridModule,
    NgxsModule,
    [NgxsModule.forFeature([QuotePolicyStepState])],
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [ViewSearchComponent],
  providers: [QuoteStepService]
})
export class ViewSearchModule {}
