import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { AllowedRoute } from "./page-header-navigation-def";
import { PageHeaderService } from "./../page-header.service";

@Component({
  selector: "ec-page-navigation",
  templateUrl: "./page-header-navigation.html",
  styleUrls: ["./page-header-navigation.scss"]
})
export class PageHeaderNavigationComponent implements OnInit {
  badgeCount: number;

  @Input() backwardRoute: AllowedRoute;
  @Input() forwardRoute: AllowedRoute;
  @Input() defaultRoute: AllowedRoute;
  @Input() isLastStep: boolean;
  @Input() isValid: boolean;

  @Output() badgeCountEmitter = new EventEmitter<number>();
  @Output() saveNextEvent = new EventEmitter();
  @Output() cancelEvent = new EventEmitter();
  @Output() nextEvent = new EventEmitter();

  constructor(private pageHeaderService: PageHeaderService) {}

  ngOnInit() {
    this.pageHeaderService.getBadgeCount.subscribe(count => {
      this.badgeCount = count;
    });
  }

  changeStep(action) {
    let path;
    if (
      action === "back" ||
      action === "cancel" ||
      action === "save" ||
      action === "next" ||
      action === "savenext"
    ) {
      this.isValid = true;
    }
    if (this.isValid) {
      switch (action) {
        case "back":
          path = this.backwardRoute;
          break;
        case "save":
          path = this.defaultRoute;
          break;
        case "savenext":
          path = this.forwardRoute;
          break;
        case "cancel":
          path = this.backwardRoute;
          break;
        case "next":
          path = this.forwardRoute;
          break;
        default:
          path = this.defaultRoute;
          break;
      }

      if (path === AllowedRoute.GeneralInfo) {
        this.badgeCount = 0;
      } else if (path === AllowedRoute.Events) {
        this.badgeCount = 1;
      } else if (
        path === AllowedRoute.Quote ||
        path === AllowedRoute.Subjectivity
      ) {
        this.badgeCount = 2;
      } else if (
        path === AllowedRoute.Bind ||
        path === AllowedRoute.Summary ||
        path === AllowedRoute.Forms
      ) {
        this.badgeCount = 3;
      } else {
        this.badgeCount = 0;
      }
      this.pageHeaderService.setBadgeCounter(this.badgeCount);
    }
    if (action === "save") {
      this.saveNextEvent.emit(action);
    }
    if (action === "savenext") {
      this.saveNextEvent.emit(action);
    }
    if (action === "cancel") {
      this.cancelEvent.emit(action);
    }
    if (action === "next") {
      this.nextEvent.emit(action);
    }
    if (action === "back") {
      this.cancelEvent.emit(action);
    }
  }

  saveData() {
    this.saveNextEvent.emit("finish");
  }
}
