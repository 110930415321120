import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { BaseDataService } from "./base-data-service";
import { AppConfigService } from "@tmhcc/global-services";
import { CommonContentService } from "@tmhcc/content-service";
import { map, catchError } from "rxjs/operators";
import { PolicyTxnRequest, PolicyTxnResponse } from "./def/quote-policy-step";
import { PolicyTxnData } from "@tmhcc/models/classes/quote-step.model";
import { policyData } from "@tmhcc/models";

@Injectable()
export class QuoteStepService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    private commonContentService: CommonContentService
  ) {
    super(http, configService);
  }
  QuotePolicyData: any;
  setQuotePolicyStepData(payload: PolicyTxnRequest): Observable<PolicyTxnData> {
    const url = `${this.serviceBaseUrl}/GetPolicyECO/${payload.PolicyTxnID}`;
    return this.get<PolicyTxnResponse>(url).pipe(
      map(data => this.mapToQuotePolicyDataStep(data)),
      catchError(this.handleError)
    );
  }
  private mapToQuotePolicyDataStep(data: PolicyTxnResponse): PolicyTxnData {
    if (!data) return;
    if (data) {
      let policyTxData = new PolicyTxnData();
      policyTxData.CountryCode = data.CountryCode;
      policyTxData.CurrencyCode = data.CurrencyCode;
      policyTxData.InsuredContactId = data.InsuredContactId;
      policyTxData.PolicyTxnId = data.PolicyTxnId;
      policyTxData.PolicyTypeDescription = data.PolicyTypeDescription;
      policyTxData.StateId = data.StateId;
      policyTxData.TxnEff = policyTxData.TxnEff;
      policyTxData.User1Id = data.User1Id;
      policyTxData.User2Id = data.User2Id;
      policyTxData.TermTypeId = data.TermTypeId;
      policyTxData.TxnBillToContactId = data.TxnBillToContactId;
      policyTxData.RateDate = data.RateDate;
      policyTxData.NextRenewalOptionId = data.NextRenewalOptionId;
      policyTxData.PolicyTypeId = data.PolicyTypeId;
      policyTxData.PolicyYear = data.PolicyYear;
      policyTxData.SubmissionTypeId = data.SubmissionTypeId;
      policyTxData.TxnPayPlanId = data.TxnPayPlanId;
      policyTxData.DateReceived = data.DateReceived;
      policyTxData.PolicyExp = data.PolicyExp;
      policyTxData.PolicyEff = data.PolicyEff;
      policyTxData.PolicyMovement = data.PolicyMovement;
      policyTxData.PolicyProducerContactId = data.PolicyProducerContactId;
      policyTxData.PolicyNum = data.PolicyNum;
      policyTxData.TxnPayPlanId = data.TxnPayPlanId;
      policyTxData.PolicyTxnNum = data.PolicyTxnNum;
      policyTxData.PolicyTermNum = data.PolicyTermNum;
      policyTxData.CreateActivityLogItem = data.CreateActivityLogItem;
      policyTxData.PolicyContacts = data.PolicyContacts;
      policyTxData.UWQuotes = data.UWQuotes;
      policyTxData.ProcessStatusCode = data.ProcessStatusCode;
      policyTxData.TxnStatusCode = data.TxnStatusCode;
      policyTxData.AdditionalSummaries = data.AdditionalSummaries;
      policyTxData.policyDataElements = data.policyDataElements;
      policyTxData.DMSFolderId = data.DMSFolderId;
      return policyTxData;
    }
  }
}
