import { NgModule } from "@angular/core";
import { DashboardModule } from "./dashboard/dashboard.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DashbardNavModule } from "./dashboard/dashbard-nav/dashbard-nav.module";
import { GeneralInfoModule } from "./quote/general-info/general-info.module";
import { RouterModule } from "@angular/router";
import { PageHeaderModule } from "./shared/page-header/page-header.module";
import { SaveChangeGaurd } from "@tmhcc/global-services";
import { EventsModule } from "./quote/events/events.module";
import { LoadingModule, LoaderService } from "@tmhcc/framwork/loader";
import { UnsavedDataGaurd } from "./quote/events/unsaved-data.guard";
import { QuotePolicyInfoModule } from "./shared/quote-policy-info/quote-policy-info.module";
import { QuoteStepModule } from "./quote/quote-step/quote-step.module";
import { QuoteSummaryModule } from "./quote/quote-summary/quote-summary.module";
import { DocumentModule } from "./quote/document/document.module";
import { KnrDashboardModule } from './knr-dashboard/knr-dashboard.module';
//import { KnrAccountSetupModule } from './knr-dashboard/knr-account-setup/knr-account-setup.module';
@NgModule({
  imports: [
    DashboardModule,
    GeneralInfoModule,
    EventsModule,
    BrowserAnimationsModule,
    DashbardNavModule,
    RouterModule,
    PageHeaderModule,
    LoadingModule,
    QuotePolicyInfoModule,
    QuoteStepModule,
    QuoteSummaryModule,
    DocumentModule,
    KnrDashboardModule      
  ],
  exports: [],
  providers: [SaveChangeGaurd, UnsavedDataGaurd, LoaderService]  
})
export class EcoModule {}
