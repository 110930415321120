
export class QuoteInfoCoveragesResponse{
    constructor(
       public TermLengths:TermLengths[],       
       public LimitsOfLiabilityForInsuranceLosses:LimitsOfLiabilityForInsuranceLosses[],
       public AdditionalCoveredEventExpenses:AdditionalCoveredEventExpenses[],      
      ){}    
  }
export class LimitsOfLiabilityForInsuranceLosses{   
    Ransom: Ransom[];
    RansomDeductible: RansomDeductible[];
    PersonalBelongings: PersonalBelongings[];
    Transit: Transit[];
    LegalLiability: LegalLiability[];
    AdditionalExpenses: AdditionalExpenses[];
    CrisisResponseFees: CrisisResponseFees[];
    RecallExpenses: RecallExpenses[];
    AaForAllInsuredLosses: AaForAllInsuredLosses[];
    AccidentalDAndDPerInsuredPerson: AccidentalDAndDPerInsuredPerson[];
    AccidentalDAndDPerEvent: AccidentalDAndDPerEvent[];   
}
export class TermLengths
{
    id:number;
    value:string;
}
export class AdditionalCoveredEventExpenses{    
    ThreatResponseExpense: ThreatResponseExpense[];
    ThreatAnnualAggregate: ThreatAnnualAggregate[];
    ThreadIndemnityPeriod: ThreadIndemnityPeriod[];
    ThreatWaitingPeriod: ThreatWaitingPeriod[];
    DisappearanceInvestigationExpense: DisappearanceInvestigationExpense[];
    DisappearanceAnnualAggregate: DisappearanceAnnualAggregate[];
    DisappearanceIndemnityPeriod: DisappearanceIndemnityPeriod[];
    DisappearanceWaitingPeriod: DisappearanceWaitingPeriod[];  
}

export class Ransom{
    id:number;
    value:string;
}
export class RansomDeductible{
    id:number;
    value:string;
}
export class PersonalBelongings{
    id:number;
    value:string;
}
export class Transit{
     id: number;    
     value: string;
}
export class LegalLiability{
    id:number;
    value:string;
}
export class AdditionalExpenses{
    id: number;
    value:string;
}
export class CrisisResponseFees{
    id: number;
    value: string;   
}

export class RecallExpenses{
    id: number;
    value:string;
}
export class AaForAllInsuredLosses{
    id: number;
    value: string;   
}
export class AccidentalDAndDPerInsuredPerson{
    id: number;
    value:string;
}
export class AccidentalDAndDPerEvent{
    id: number;
    value: string;   
}

//
export class ThreatResponseExpense{
    id: number;    
    value: string;
}
export class ThreatAnnualAggregate{
   id:number;
   value:string;
}
export class ThreadIndemnityPeriod{
   id: number;
   value:string;
}
export class ThreatWaitingPeriod{
   id: number;
   value: string;   
}

export class DisappearanceInvestigationExpense{
   id: number;
   value:string;
}
export class DisappearanceAnnualAggregate{
   id: number;
   value: string;   
}
export class DisappearanceIndemnityPeriod{
   id: number;
   value:string;
}
export class DisappearanceWaitingPeriod{
   id: number;
   value: string;   
}