import { BrowserModule } from "@angular/platform-browser";
import { NgModule, ErrorHandler } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppComponent } from "./app.component";
import { GetProviders } from "@tmhcc/startup";
import { environment } from "../environments/environment";
import { NgxsModule } from "@ngxs/store";
import { NgxsDispatchPluginModule } from "@ngxs-labs/dispatch-decorator";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { AppRoutingModule } from "./app-routing.module";
import { QuoteState, PreloadMasterDataGuard } from "@tmhcc/eco";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
// import {
//   CustomPopupAlertModule,
//   CustomPopupAlertService
// } from '@tmhcc/framwork/custom-popup-alert';
import { OktaAuthGuard } from "@tmhcc/framwork/okta-login";
import { OktaAuthModule, OKTA_CONFIG, OktaConfig } from "@okta/okta-angular";
import { LoadingModule, LoaderService } from "@tmhcc/framwork/loader";
import { SaveChangeGaurd } from "@tmhcc/global-services";
import { DialogService, DialogConfig, DialogRef } from "@tmhcc/framwork/dialog";
import { LoggingService } from "@tmhcc/framwork/logging/src";
import { ToastrService, ToastrModule } from "ngx-toastr";
import {
  ErrorHandlerService,
  ErrorInterceptor
} from "@tmhcc/framwork/services";

const config: OktaConfig = {
  issuer: environment.oktaWidgetConfig.issuer,
  redirectUri: environment.oktaWidgetConfig.redirectUri,
  clientId: environment.oktaWidgetConfig.clientId,
  scopes: environment.oktaWidgetConfig.scope,
  pkce: false,
  onSessionExpired: () => {}
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    // CustomPopupAlertModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot([], {
      developmentMode: true
    }),
    NgxsDispatchPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    FontAwesomeModule,
    OktaAuthModule,
    LoadingModule,
    ToastrModule.forRoot({preventDuplicates: true})
  ],
  providers: [
    OktaAuthGuard,
    LoaderService,
    LoggingService,
    ToastrService,
    SaveChangeGaurd,
    DialogConfig,
    DialogService,
    DialogRef,
    PreloadMasterDataGuard,
    // CustomPopupAlertService,
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: OKTA_CONFIG, useValue: config },
    ...GetProviders("TMHCC EVENT CANCELLATION")
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
