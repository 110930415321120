import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AppConfigService } from "@tmhcc/global-services";
import { QuoteStepStoreService } from "../store/facade/quote-step-store.service";
import { QuotePolicyStepStore } from "../store/state/quote-policy-step.state";
import { AllowedRoute } from "../../shared/page-header/page-header-navigation/page-header-navigation-def";
import { take } from "rxjs/internal/operators/take";
import { PageHeaderService } from "../../shared/page-header/page-header.service";
import { environment } from 'apps/policy-admin/src/environments/environment';

@Component({
  selector: "ec-document",
  templateUrl: "./document.component.html",
  styleUrls: ["./document.component.scss"]
})
export class DocumentComponent implements OnInit {
  nextPageClicked: boolean;
  showBoxFolder = false;
  documentId = "";
  isPolicyBound = false;
  index: number;

  constructor(
    private router: Router,
    private configService: AppConfigService,
    private pageHeaderService: PageHeaderService,
    private quoteStepStoreService: QuoteStepStoreService
  ) {}

  ngOnInit(): void {
    this.isPolicyBound = false;
    this.showBoxFolder = false;

    this.setPolicyData();

    this.pageHeaderService.getBadgeCount.subscribe(badgeCount => {
      this.index = badgeCount;
    });
  }

  setPolicyData() {
    this.quoteStepStoreService.data$
      .pipe(take(1))
      .subscribe((data: QuotePolicyStepStore) => {
        if (data && data.policyTxnData && data.policyTxnData.PolicyTxnId) {
          if (data && data.policyTxnData && data.policyTxnData.DMSFolderId) {
            this.documentId = data.policyTxnData.DMSFolderId;
            if (this.documentId) {
              this.showBoxFolder = true;
            }
          }
        } else {
          this.router.navigate([AllowedRoute.Dashboard]);
        }
      });
  }

  openBoxView() {
    if (environment.appConfig.boxUrl) {
      if (this.documentId) {
        const boxUrl = `${environment.appConfig.boxUrl}?folderid=${this.documentId}`;
        window.open(boxUrl, "_blank");
      }
    } else {
      throw new Error("Invalid Box url.");
    }
  }

  back() {
    if (this.isPolicyBound) {
      if (this.index === 2) {
        this.router.navigate([AllowedRoute.Quote]);
      } else if (this.index === 3) {
        this.router.navigate([AllowedRoute.Summary]);
      }
    } else {
      if (this.index === 2) {
        this.router.navigate([AllowedRoute.Quote]);
      } else if (this.index === 3) {
        this.router.navigate([AllowedRoute.Bind]);
      } else if (this.index === 1) {
        this.router.navigate([AllowedRoute.Events]);
      }
    }
  }

  next() {
    if (this.index === 2) {
      this.router.navigate([AllowedRoute.Quote]);
    } else if (this.index === 3) {
      this.router.navigate([AllowedRoute.Summary]);
    } else if (this.index === 1) {
      this.router.navigate([AllowedRoute.Events]);
    }
  }
}
