import { Component, OnInit,ChangeDetectorRef, OnDestroy, } from '@angular/core';
import { DateService } from "@tmhcc/framwork/services";
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { DialogConfig, DialogRef, DialogSize } from "@tmhcc/framwork/dialog";
import { PolicyDetailDialogConfigData } from "../policy-dialog/policy-dialog-config";
import {
  PolicyDetailDialogFieldConfig
} from "./policy-dialog-config";
import { CommonContentService } from "@tmhcc/content-service";
import { InputConfig, FieldInfo } from "@tmhcc/framwork/form-inputs";
import { policyDetail } from '@tmhcc/models';
import { LoaderService } from "@tmhcc/framwork/loader";
import { PolicyDownloadService } from "libs/data-service/policy-download.service";
import { PolicyTxnRequest,DwldPolicySearchRequest,DwldPolicySearchResponse,nwPolicyDownloadRequest,ttbPolicyDownloadRequest,thimblePolicyDownloadRequest} from "@tmhcc/data-service";
import * as fileSaver from 'file-saver';

@Component({
  selector: 'ec-policy-dialog',
  templateUrl: './policy-dialog.component.html',
  styleUrls: ['./policy-dialog.component.scss']
})

export class PolicyDialogComponent extends PolicyDetailDialogFieldConfig implements OnInit, OnDestroy  {
  //policyDetailsFields: InputConfig[];
  frmPolicyDetails:FormGroup;
  defaultInitialValue: policyDetail;
  dialogSetting: string;
  fieldGroupNameArray: Array<string> = ['policyDetailsFields'];
  policyDetailsInputFields: InputConfig[];

  constructor(
    private formBuilder: FormBuilder,
    public dialogData: DialogConfig<PolicyDetailDialogConfigData>,
    private dialogRef: DialogRef,
    dateService: DateService,
    // private quoteStoreService: QuoteStoreService,
    // private validationService: CoreInputValidationService,
    // private masterData: EcoMasterData,
     public contentService: CommonContentService,
    private cdr: ChangeDetectorRef,
    private loaderservice: LoaderService,
    private downloadPolicyService: PolicyDownloadService,
    private loaderService: LoaderService,
    ) {
      super(dateService, dialogData, contentService);
     }
  
  isDisabled : boolean = false;
  
  ngOnInit(): void {     
    this.dialogData.data = {
      ...this.dialogData.data      
    };  
    this.loaderservice.show();
    let data = this.fieldGroupNameArray;
    this.setInitialData();   
    
    setTimeout(() => {
      this.loaderservice.hide();
    }, 1000);    
    this.policyDetailsInputFields = this.policyDetailsFields;
  }
  setInitialData() {
    this.setFormControl();
  }
  setFormControl() {
    let formControls = {};
    this.fieldGroupNameArray.forEach(fieldGroupName => {
      this[fieldGroupName].forEach((element: InputConfig) => {
        
        let validators;
        if (element.config && element.config.validation) {
          validators = element.config.validation;
        } else {
          validators = null;
        }

        formControls[element.fieldInfo.fieldName] = [
          '',
          Validators.compose(validators)
        ];
      });
      this.frmPolicyDetails = this.formBuilder.group(formControls);
    });

    //(this.addLocationDetailsFormGroup.get('AddlInfo') as FormArray).removeAt(0);
  }
  downloadPolicy(){   
 }
 closeModel(){
  this.dialogRef.close();
 } 
 ngOnDestroy(): void { 
}
onDownloadLinkClick(event) { 
  this.loaderService.show();
  let serviceType = sessionStorage.ServiceType;
  if(serviceType === undefined || serviceType === 'NW')
  {
    const policyDocRequest: nwPolicyDownloadRequest = {
      policyNumber: event.PolicyNumber,
      docType : "Policy"
     };  
      this.downloadPolicyService.nwDownloadFile(policyDocRequest).subscribe(
        response => {
          const blob: any = new Blob([response], { type: 'application/pdf' });
  
          const urls: string = window.URL.createObjectURL(blob);
          //const filename = urls.split('/').pop();
          const filename = event.PolicyNumber + '_Policy';    
          fileSaver.saveAs(blob, filename);
          this.loaderService.hide();
        }
      );
  }
  else if(serviceType === 'TTB'){
    const policyDocRequest: ttbPolicyDownloadRequest = {
      carrierPolicyNumber: event.PolicyNumber,
      transactionID : event.TransactionID,
      docType : "Policy"
     }; // debugger;
      this.downloadPolicyService.ttbDownloadFile(policyDocRequest).subscribe(
        response => {
          const blob: any = new Blob([response], { type: 'application/pdf' });
  
          const urls: string = window.URL.createObjectURL(blob);
          //const filename = urls.split('/').pop();
          let filename = event.PolicyNumber + '_Policy'; 
          if(event.TransactionType === 'CHG')  
           {
             filename = event.PolicyNumber + '_Policy' + '_END' + event.EndorsementNumber;
           }
          fileSaver.saveAs(blob, filename);
          this.loaderService.hide();
        }
      );
  }
  else if(serviceType === 'Thimble')
  {
   // debugger;
    const policyDocRequest: thimblePolicyDownloadRequest = {
      boxFileId: event.BoxFileId,          
      docType : "Policy"
     };  
      this.downloadPolicyService.thimbleDownloadFile(policyDocRequest).subscribe(
        response => {
          const blob: any = new Blob([response], { type: 'application/pdf' });      
          const urls: string = window.URL.createObjectURL(blob);            
          const filename = event.PolicyNumber + '_Policy';    
          fileSaver.saveAs(blob, filename);
          this.loaderService.hide();
        }
      );
  }
 }
}
