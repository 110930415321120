import { Injectable } from "@angular/core";
import { Select } from "@ngxs/store";
import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { Observable } from "rxjs";
import { DasboardData, SetSearchType,DashboardDataDwld } from "../state/dashbard.actions";
import { DashboardStateDwld,DashboardStoreDwld } from "../state/dashbard.state";
import { DashboardSearchRequest,DwldPolicySearchRequest } from "@tmhcc/data-service";
import { SearchType } from "@tmhcc/models";

@Injectable({ providedIn: "root" })
export class DashoardStoreService {
  // @Select(DashboardState)
  // public data$: Observable<DashboardStore>;

  @Select(DashboardStateDwld)
  public data$: Observable<DashboardStoreDwld>;

  constructor() {}

  // @Dispatch()
  // public getDashboardData(dashboardSearchRequest: DashboardSearchRequest) {
  //   return new DasboardData(dashboardSearchRequest);
  // }

  // @Dispatch()
  // public setSearchType(searchType: SearchType) {
  //   return new SetSearchType(searchType);
  // }

  @Dispatch()
  public getDwldPolicyDataMock(searchType:any,dashboardSearchRequest: DwldPolicySearchRequest) {
    return new DashboardDataDwld(searchType,dashboardSearchRequest);
  }
}

