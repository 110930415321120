import { Moment } from "@tmhcc/framwork/services";

export class quotesData {
  QuoteNumber: string;
  CreatedDate: string;
  ExpirationDate: string;
  InsuredName: string;
  BrokerName: string;
  Underwriter: string;
  Status: string;
  GrossPremium: Number;
  PolicyTxnID: Number;
}
export class policyData {
  PolicyNumber: string;
  CreatedDate: string;
  ExpirationDate: string;
  InsuredName: string;
  BrokerName: string;
  Underwriter: string;
  GrossPremium: Number;
  PolicyTxnID: Number;
}

export class DashboardGrid {
  quotesData: quotesData[];
  policyData: policyData[];
}

export class DashboardGridDwld {
  policyDetail: policyDetail[]; 
}

export class policyDetail{
  TransactionType: string;
  TransactionID?:string;
  ProductCode: string;
  InsuredName: string;
  Address:     string;
  City : string;
  Country: string;   
  CreationDate: string;  
  PolicyEffectiveDate: string;
  PolicyExpirationDate: string;
  TotalPremium : Number; 
  PolicyNumber : string;
  EndorsementNumber : string;
  ClientType:string;
  BoxFileId: string;
} 
export class thimbleDocument{
  carrierPolicyNumber: string;
  transactionID: string;
  folderID: string;
  fileID: string;
  documentFileName: string;
  docOriginalFileName: string
  docType: string;
}
