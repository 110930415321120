<diV class="pageTab mt-3" role="member">
  <ng-container *ngIf="useRouter; else buttons">
    <span
      class="tabGap"
      [class.tabGapCount]="!i.count || i.count <= 0"
      *ngFor="let i of data"
    >
      <a
        [routerLink]="i.url"
        role="menuitemradio"
        class="pageTabButton"
        routerLinkActive="active-page"
        [hidden]="i.disabled"
        acPageTabItem
      >
        {{ i.label }}
        <span class="page-tab-count" *ngIf="i.count && i.count > 0">{{
          i.count
        }}</span>
      </a>
    </span>
  </ng-container>
  <ng-template #buttons>
    <span
      class="tabGap"
      [class.tabGapCount]="!i.count || i.count <= 0"
      *ngFor="let i of data; let ind = index"
    >
      <button
        role="menuitemradio"
        class="cs-button pageTabButton"
        [class.active-page]="i | pageTabItem: selectedId"
        [disabled]="i.disabled"
        (click)="click(i, ind)"
        acPageTabItem
      >
        {{ i.label }}
        <span class="page-tab-count" *ngIf="i.count && i.count > 0">{{
          i.count
        }}</span>
      </button>
    </span>
  </ng-template>
</diV>
