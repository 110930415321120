import {
  Input,
  OnInit,
  ElementRef,
  HostBinding,
  Renderer2
} from "@angular/core";
import { ElementBase } from "./element-base";
import { FieldInfo } from "./field-info";
import { FormBaseElement } from "./form-base-element";

export abstract class FormElementBase<T> extends ElementBase<T>
  implements FormBaseElement, OnInit {
  @Input() fieldInfo: FieldInfo;

  @HostBinding("attr.title") title: string;
  @HostBinding("attr.aria-label") label: string;
  @HostBinding("tabindex") tabIndex = 0;

  constructor(protected renderer: Renderer2, protected el: ElementRef) {
    super();
  }

  setDisabledState(isDisabled: boolean) {
    this.renderer.setProperty(this.el.nativeElement, "disabled", isDisabled);
    this.tabIndex = isDisabled ? -1 : 0;
  }

  ngOnInit() {
    const id = this.buildId();
    if (id) {
      this.renderer.setProperty(this.el.nativeElement, "id", id);
      this.renderer.setProperty(this.el.nativeElement, "name", id);
    }
    this.label = this.buildLabel();
    this.title = this.buildTitle();
  }

  protected buildLabel() {
    return (
      this.label ||
      (this.fieldInfo && this.fieldInfo.label ? this.fieldInfo.label : "")
    );
  }

  protected buildTitle() {
    return this.title || this.buildLabel();
  }

  protected buildId() {
    if (!this.fieldInfo || !this.fieldInfo.fieldName) {
      return this.el.nativeElement.getAttribute("id");
    }
    return `${this.fieldInfo.fieldName}`;
    // return `${this.fieldInfo.fieldName}_${this.el.nativeElement.tagName}`;
  }
}
