import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KnrSearchComponent } from './knr-search.component';
import { GridModule } from "@tmhcc/framwork/grid";
import { NgxsModule } from "@ngxs/store";
import { KnrPolicyQuoteService } from "libs/data-service/kidnap-ransom/knr-policy-quote.service";
import { QuotePolicyStepState } from "../../quote/store/state/quote-policy-step.state";
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { InputTextModule } from "@tmhcc/framwork/form-inputs";
import { Routes, RouterModule } from "@angular/router";
@NgModule({
  declarations: [KnrSearchComponent],
  imports: [
    CommonModule,   
    GridModule,
    NgxsModule,   
    ReactiveFormsModule,
    FormsModule,
    InputTextModule
  ],
  providers: [KnrPolicyQuoteService],
  exports: [KnrSearchComponent],
})
export class KnrSearchModule { }
