import { Moment } from "@tmhcc/framwork/services";

export class PolicyTxnData {
  PolicyNum?: number;
  PolicyTxnId?: string;
  InsuredContactId?: number;
  PolicyProducerContactId?: number;
  SubCompanyName?: string;
  PolicyTypeId?: number;
  PolicyTypeDescription?: string;
  TermTypeId?: number;
  User1Id?: number;
  User2Id?: number;
  NextRenewalOptionId?: number;
  SubmissionTypeId?: number;
  PolicyYear?: string;
  CountryCode?: string;
  StateId?: number;
  TxnStatusCode?: string;
  TxnEff?: string;
  CurrencyCode?: string;
  TxnPayPlanId?: number;
  TxnBillToContactId?: number;
  PolicyTermNum: number;
  PolicyTxnNum: number;
  PolicyEff?: any;
  PolicyExp?: any;
  DateReceived?: string;
  RateDate?: string;
  ProcessStatusCode?: string;
  //policyDataElements: policyDataElements[];
  policyDataElements: policyDataElements;
  PolicyMovement?: PolicyMovement[];
  CreateActivityLogItem?: CreateActivityLogItemObj;
  PolicyContacts?: PolicyContacts[];
  AdditionalSummaries?: AdditionalSummaries[];
  UWQuotes: UWQuotes[];
  DMSFolderId: string;
}
export class policyDataElements {
  ElementUpdateRequests: ElementUpdateRequests[];
}
export class PolicyMovement {
  PolicyId: number;
  PolicyTxnId: number;
  PortalStatus: number;
  Status: string;
  ProcessStatusCode?: string;
}

export class AdditionalSummaries {
  Description: string;
  Amount: number;
  UWQuoteId?: number;
}
export class UWQuotes {
  UWQuoteId: number;
  QuoteDescription: string;
  LOBs: LOBs[];
  ProducerCommissions: ProducerCommissions[];
  WritingCompanyId: number;
  QuoteStatus?:string;
  UWQuotaShares: UWQuotaShares[];
  TaxesAndFees?: ConsolidatedTaxesAndFeesResponseItems[];
}

export class UWQuotaShares {
  QuotaSharePremium: number;
}

export class ConsolidatedTaxesAndFeesResponseItems {
  TaxesAndFees?: number;
  TaxType?:string;
  WrittenAmount?:number;
}

export class ProducerCommissions {
  CommRate?: number;
  DefaultCommRate?: number;
  CommAmt?: number;
  UWQuoteId?: number;
  UWLOBId?: number;
  PolicyProducerContactId?: number;
  OverrideComm?: boolean;
  IsPayNet?: boolean;
}

export class LOBs {
  StateId: number;
  Risks: LOBRisks[];
}

export class LOBRisks {
  RiskDataElements: RiskDataElements;
  RiskTypeCode: string;
  Coverages: Coverages[];
  UWRiskId?: number;
}
export class Coverages {
  CovTypeCode?: string;
  PerOccurLimit?: number;
  PremiumAmount: number;
  CovDescription?: string;
}
export class RiskDataElements {
  ElementUpdateRequests: ElementUpdateRequests[];
}

export class ElementUpdateRequests {
  ElementCode: string;
  ElementId: number;
  ElementName?: string;
  ResponseType: string;
  FreeTextValue: string;
  DateValue: string;
  SelectedDataElementResponseIds: number[];
  DecimalValue: number;
  MoneyValue: number;
  RowNum: number;
  DataGroupName?: string;
  BooleanValue?: boolean = false;
}
export class PolicyTxnRequest {
  PolicyTxnID?: string;
}
export class QuotePolcyTxnData {
  policyTxnData: PolicyTxnData;
}
export class CreateActivityLogItemObj {
  PolicyTxnId?: number;
  ActivityType?: string;
  ActivityDate?: string;
  Description?: string;
  LoggedInUser?: string;
  Amount?: number;
}
export class PolicyContacts {
  ContactId?: number;
  FullName?: string;
}
export class QuoteOptions {
  customId?: number;
  UWRiskId?: number;
  RiskTypeCode: string;
  eventVenue?: string;
  option1?: number;
  option2?: number;
  option3?: number;
  option4?: number;
  option5?: number;
  checkedOption1?: boolean;
  checkedOption2?: boolean;
  checkedOption3?: boolean;
  checkedOption4?: boolean;
  checkedOption5?: boolean;
  AdditionalPerls?: number;
  infoIconText?: string;
  customID: string;
  additionalPerilLabel1: string;
  additionalPerilLabel2: string;
  additionalPerilLabel3: string;
  additionalPerilValue1: string;
  additionalPerilValue2: string;
  additionalPerilValue3: string;
  address: string;
  baseRate: number;
  bioChemTerrIncl: number;
  city: string;
  civilCommotion: number;
  communicableDisease: number;
  country: string;
  csTornadoHail: number;
  cyber: number;
  earthquake: number;
  era: number;
  eventDeductible: number;
  eventDescription: string;
  eventEndDate: Moment;
  eventLimitOfInsurance: number;
  eventStartDate: Moment;
  eventType: string;
  limitedTerrorism: number;
  limitedWar: number;
  nationalMounting: number;
  nonAppearance: number;
  nucBioChemTerrorism: number;
  nuclearHazard: number;
  state: string;
  strike: number;
  terrorism: number;
  war: number;
  wildfire: number;
  windstorm: number;
  winterWeather: number;
  zipCode: number;
  empty?: string;
  tradeShowPolicytype?: boolean;
  standardPolicyType?: boolean;
  readOnly?: boolean;
}
