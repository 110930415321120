import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { PageHeaderModule } from "../../shared/page-header/page-header.module";
import { QuotePolicyInfoModule } from "../../shared/quote-policy-info/quote-policy-info.module";
import { BindComponent } from "./bind.component";
import { PolicySummaryModule } from "../policy-summary/policy-summary.module";
import { GridModule } from "@tmhcc/framwork/grid";
import { PageTabInfoModule } from "./../../shared/page-tab/page-tab-info.module";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CoreInputsModule } from "@tmhcc/framwork/core-inputs";
import { SaveChangeGaurd } from "@tmhcc/global-services";
const routes: Routes = [
  { path: "", component: BindComponent, canDeactivate: [SaveChangeGaurd] }
];

@NgModule({
  declarations: [BindComponent],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    ReactiveFormsModule,
    PageHeaderModule,
    QuotePolicyInfoModule,
    PolicySummaryModule,
    GridModule,
    PageTabInfoModule,
    CoreInputsModule
  ],
  providers: [SaveChangeGaurd]
})
export class BindModule {}
