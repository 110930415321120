import { Component, OnInit } from '@angular/core';
import { QuoteInfoCoveragesDefinitionService } from "./config/quote-info-coverage-config"
import { DateService } from "@tmhcc/framwork/services";
import { InputConfig, FieldInfo } from "@tmhcc/framwork/form-inputs";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { LoaderService } from "@tmhcc/framwork/loader";
import { KnrPolicyQuoteService } from "libs/data-service/kidnap-ransom/knr-policy-quote.service";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from 'rxjs/operators';
import { CoreInputValidationService } from '@tmhcc/framwork/core-inputs';
import { QuoteInfoCoveragesRequest } from "libs/data-service/def/knr-quote-info-coverages";
import { ToastrService } from 'ngx-toastr';
import moment from "moment-timezone";
import {Router} from "@angular/router";
@Component({
  selector: 'ec-quote-info-coverages',
  templateUrl: './quote-info-coverages.component.html',
  styleUrls: ['./quote-info-coverages.component.scss']
})
export class QuoteInfoCoveragesComponent extends QuoteInfoCoveragesDefinitionService implements OnInit {
  termInfoFields: InputConfig[];
  limitLossesForInsuranceLossesFields: InputConfig[];
  additionalCoveredEventFields: InputConfig[];
  valueSub = new Subscription();
  QuoteInfoCovFormGroup: FormGroup;
  constructor(
    private dataService: DateService,
    private formBuilder: FormBuilder,
    private loaderService: LoaderService,
    private knrPolicyQuoteService: KnrPolicyQuoteService,
    private validationService: CoreInputValidationService,
    private toastrService: ToastrService,
    private router: Router
  ) {
    super(dataService);
  }

  ngOnInit(): void {
    this.knrPolicyQuoteService.getQuoteInfoCoveragesData().subscribe(data => {      
      this.termInfoFields = this.termInfoConfig(data).termInfoConfigList;
      this.limitLossesForInsuranceLossesFields = this.limitOfLiability(data.LimitsOfLiabilityForInsuranceLosses).limitOfLiabilityList;
      this.additionalCoveredEventFields = this.additionalCoveredEvent(data.AdditionalCoveredEventExpenses).additionalCoveredEventList;
      this.setFormControl();
      this.termlengthValueChange();
      this.validationService.setFormSubmission(false);
    });
  }
  onSubmit() {
    this.validationService.setFormSubmission(true);
    if (this.QuoteInfoCovFormGroup.valid) {
      this.loaderService.show();
      const payload = this.mapQuoteInfoCoveragesForm();
      this.knrPolicyQuoteService.AddQuoteInfoCoverages(payload).subscribe((data: any) => {
        this.loaderService.hide();
        this.toastrService.success(
          'Quote Info coverages created successfully'
        );
      });
    }
  }
  mapQuoteInfoCoveragesForm(): QuoteInfoCoveragesRequest {
    let requestData: QuoteInfoCoveragesRequest;
    if (sessionStorage.getItem('_quoteId') !== undefined ||
      sessionStorage.getItem('_quoteId') !== null) {
      requestData = {
        QuoteID: sessionStorage.getItem('_quoteId'),
        TermLength: this.QuoteInfoCovFormGroup.controls['termlength'].value,
        EffectiveDate: this.QuoteInfoCovFormGroup.controls['effectivedate'].value,
        ExpirationDate: this.QuoteInfoCovFormGroup.controls['expirationdate'].value,
        AdditionalEntities: this.QuoteInfoCovFormGroup.controls['additionalentities'].value,
        LimitsOfLiabilityForInsuranceLosses: {
          Ransom: this.QuoteInfoCovFormGroup.controls['ransom'].value,
          RansomDeductible: this.QuoteInfoCovFormGroup.controls['ransomdeductible'].value,
          PersonalBelongings: this.QuoteInfoCovFormGroup.controls['personalbelongings'].value,
          Transit: this.QuoteInfoCovFormGroup.controls['transit'].value,
          LegalLiability: this.QuoteInfoCovFormGroup.controls['legalliability'].value,
          AdditionalExpenses: this.QuoteInfoCovFormGroup.controls['additionalexpenses'].value,
          CrisisResponseFees: this.QuoteInfoCovFormGroup.controls['crisisresponsefees'].value,
          RecallExpenses: this.QuoteInfoCovFormGroup.controls['recallexpenses'].value,
          AAForAllInsuredLosses: this.QuoteInfoCovFormGroup.controls['aaforallinsuredlosses'].value,
          AccidentalDAndDPerInsuredPerson: this.QuoteInfoCovFormGroup.controls['accidentalddperinsuredperson'].value,
          AccidentalDAndDPerEvent: this.QuoteInfoCovFormGroup.controls['accidentalperevent'].value,
        },
        AdditionalCoveredEventExpenses: {
          ThreatResponseExpense: this.QuoteInfoCovFormGroup.controls['threatresponseexpense'].value,
          ThreatAnnualAggregate: this.QuoteInfoCovFormGroup.controls['threatannualaggregate'].value,
          ThreadIndemnityPeriod: this.QuoteInfoCovFormGroup.controls['threatindeminityperiod'].value,
          ThreatWaitingPeriod: this.QuoteInfoCovFormGroup.controls['threatwaitingperiod'].value,
          DisappearanceInvestigationExpense: this.QuoteInfoCovFormGroup.controls['disappearanceinvestigationexpense'].value,
          DisappearanceAnnualAggregate: this.QuoteInfoCovFormGroup.controls['disappearnaceannualaggregate'].value,
          DisappearanceIndemnityPeriod: this.QuoteInfoCovFormGroup.controls['disappearanceindeminityperiod'].value,
          DisappearanceWaitingPeriod: this.QuoteInfoCovFormGroup.controls['disappearancewaitingperiod'].value,
        }
      }
    }
    return requestData;
  }
  setFormControl() {
    // debugger;
    this.loaderService.show();
    const formControls = {};
    this.termInfoFields.forEach((element: InputConfig) => {
      // debugger;  
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      formControls[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        Validators.compose(validators)
      ];
    });
    this.limitLossesForInsuranceLossesFields.forEach((element: InputConfig) => {
      // debugger;  
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      formControls[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        Validators.compose(validators)
      ];
    });
    this.additionalCoveredEventFields.forEach((element: InputConfig) => {
      // debugger;  
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      formControls[element.fieldInfo.fieldName] = [
        element.fieldInfo.value,
        Validators.compose(validators)
      ];
    });
    this.QuoteInfoCovFormGroup = this.formBuilder.group(formControls);
    this.loaderService.hide();
  }
  termlengthValueChange() {
    this.QuoteInfoCovFormGroup.controls['termlength'].valueChanges.pipe(distinctUntilChanged()).subscribe(value => {     
      if (value) {    
        let termLength = this.termInfoFields[0];
        let effectiveDate = this.termInfoFields[1];
        let expirationDate = this.termInfoFields[2];
        if (value === '12 Months') {
          let dDate = this.dataService.toShortDate(this.dataService.moment(this.QuoteInfoCovFormGroup.get('effectivedate').value.toISOString()));
          let sDate = this.dataService.moment(dDate).add('12', 'months');
          this.QuoteInfoCovFormGroup
            .get('expirationdate')
            .setValue(this.dataService.toShortDate(sDate));
        }
        else if (value === '24 Months') {
          let dDate = this.dataService.toShortDate(this.dataService.moment(this.QuoteInfoCovFormGroup.get('effectivedate').value.toISOString()));
          let sDate = this.dataService.moment(dDate).add('24', 'months');
          this.QuoteInfoCovFormGroup
            .get('expirationdate')
            .setValue(this.dataService.toShortDate(sDate));
        }
        else if (value === '36 Months') {
          let dDate = this.dataService.toShortDate(this.dataService.moment(this.QuoteInfoCovFormGroup.get('effectivedate').value.toISOString()));
          let sDate = this.dataService.moment(dDate).add('36', 'months');
          this.QuoteInfoCovFormGroup
            .get('expirationdate')
            .setValue(this.dataService.toShortDate(sDate));
        }
        else if (value === 'Custom') {
          expirationDate.config.datePickerConfig.isCalanderRequired = true;
          expirationDate.config.isDisabled = false;
        }
        if (value !== 'Custom') {
          expirationDate.config.datePickerConfig.isCalanderRequired = false;
          expirationDate.config.isDisabled = true;
        }
      }
    });
    this.QuoteInfoCovFormGroup.controls['effectivedate'].valueChanges.pipe(distinctUntilChanged()).subscribe(value => {   
      if (value) {    
        let trmLengthFrmValue = this.QuoteInfoCovFormGroup.get('termlength').value;
        if (trmLengthFrmValue === '12 Months') {
          let dDate = this.dataService.toShortDate(this.dataService.moment(value.toISOString()));
          let sDate = this.dataService.moment(dDate).add('12', 'months');
          this.QuoteInfoCovFormGroup
            .get('expirationdate')
            .setValue(this.dataService.toShortDate(sDate));
        }
        else if (trmLengthFrmValue === '24 Months') {
          let dDate = this.dataService.toShortDate(this.dataService.moment(value.toISOString()));
          let sDate = this.dataService.moment(dDate).add('24', 'months');
          this.QuoteInfoCovFormGroup
            .get('expirationdate')
            .setValue(this.dataService.toShortDate(sDate));
        }
        else if (trmLengthFrmValue === '36 Months') {
          let dDate = this.dataService.toShortDate(this.dataService.moment(value.toISOString()));
          let sDate = this.dataService.moment(dDate).add('36', 'months');
          this.QuoteInfoCovFormGroup
            .get('expirationdate')
            .setValue(this.dataService.toShortDate(sDate));
        }
        else if (trmLengthFrmValue === 'Custom') {
          this.DateValidator('effectivedate', 'expirationdate', 'start');
        }
      }
    });
    this.QuoteInfoCovFormGroup.controls['expirationdate'].valueChanges.subscribe(value => {
      let trmLengthFrmValue = this.QuoteInfoCovFormGroup.get('termlength').value;
      if (trmLengthFrmValue === 'Custom') {  
          this.DateValidator('effectivedate', 'expirationdate','end');
       }
     });
  }
  DateValidator(startDate: string, endDate: string,type:string) {
      const sd = this.QuoteInfoCovFormGroup.controls[startDate];
      const ed = this.QuoteInfoCovFormGroup.controls[endDate];
      const today = moment();
      if (sd.value && type === 'start') {
         if (ed.value && (moment(sd.value).diff(ed.value) > 0 || moment(sd.value).isSame(ed.value, "day"))) {
          sd.setErrors({ invalidDate: true });
          sd.setValidators([Validators.required,Validators.nullValidator]); 
        } else {
           sd.setErrors(null);
           ed.setErrors(null);
        }
      }
      if (ed.value && type === 'end') {
        if (sd.value && (moment(ed.value).diff(sd.value) < 0 || moment(sd.value).isSame(ed.value, "day"))) {
          ed.setErrors({ invalidDate: true });
          ed.setValidators([Validators.required,Validators.nullValidator]); 
        } else {
          ed.setErrors(null);
          sd.setErrors(null);
        }
      }  
  }
  GoToSubjectivity()
  {
    this.router.navigate(['/knr-dashboard/account-setup/rating/quote-info-coverages/subjectivity']);
  }
}
