import { Injectable } from "@angular/core";
import { CanDeactivate, Router } from "@angular/router";
import { Observable, Subject } from "rxjs";
import {
  DialogService,
  DialogConfig,
  DialogSize
} from "@tmhcc/framwork/dialog";
import { DialogContainerComponent } from "@tmhcc/global-services";
import { EventsComponent } from "./events.component";
import { CommonContentService } from "@tmhcc/content-service";

@Injectable()
export class UnsavedDataGaurd implements CanDeactivate<EventsComponent> {
  hideHeader: boolean;
  hideFooter: boolean;
  hideSave: boolean;
  hideHeaderX: boolean;
  titleText: string;
  navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dialogService: DialogService,
    private dialogConfig: DialogConfig,
    public router: Router,
    private contentService: CommonContentService
  ) {}

  canDeactivate(component: EventsComponent): boolean | Observable<boolean> {
    if (component.hasUnsavedData) {
      this.DeactivateRoute();
    } else {
      return true;
    }
    return this.navigateAwaySelection$;
  }

  DeactivateRoute() {
    let content = {
      value: this.contentService.getLabels().dirtyFormMsg
    };
    this.dialogConfig.data = content;

    this.dialogConfig.modalSetting = {
      title: this.titleText,
      hideHeader: this.hideHeader,
      hideFooter: true,
      hideSave: this.hideSave,
      hideHeaderX: this.hideHeaderX,
      SaveButton: "YES",
      CancelButton: "NO",
      dialogSize: DialogSize.LG
    };
    const ref = this.dialogService.open(
      DialogContainerComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {
      if (result) {
        this.navigateAwaySelection$.next(false);
      } else {
        this.navigateAwaySelection$.next(true);
      }
    });
  }
}
