<div class="col-sm-12 text-right">
  <h1 class="body-header">
    <!-- <span>Header</span> -->
    <span
      class="pull-right"
      (keyup.enter)="tabNavigation()"
      (click)="createQuotePage()"
    >
      <a class="btn btn-secondary" tabindex="0">CREATE NEW QUOTE</a>
    </span>
  </h1>
</div>
