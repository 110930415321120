import {
  Directive,
  forwardRef,
  HostBinding,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
  Attribute,
  HostListener,
  Input
} from "@angular/core";
import { TextFormElement } from "../text/text-form-element";
import { NG_VALUE_ACCESSOR, FormGroup } from "@angular/forms";

@Directive({
  selector: "input[acInputEmail]",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputEmailDirective),
      multi: true
    }
  ]
})
export class InputEmailDirective extends TextFormElement {
  @HostBinding("autocomplete") autocomplete = "off";
  @Input() formGroup: FormGroup;
  @HostListener("input", ["$event"])
  onChange(event) {
    this.change(event.target.value);
  }

  validationEmail(data) {
    let pattern = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/g;
    if (!pattern.test(data.toLowerCase())) {
      return false;
    }
    return true;
  }

  @HostListener("blur", ["$event"])
  onBlur(event) {
    const validateEmail = this.validationEmail(event.target.value);
    if (!validateEmail && event.target.value !== "") {
      this.formGroup.controls[this.fieldInfo.fieldName].setErrors({
        pattern: true
      });
    }
  }

  constructor(
    protected renderer: Renderer2,
    protected el: ElementRef,
    protected csRef: ChangeDetectorRef,
    @Attribute("updateEvent") protected updateEvent
  ) {
    super(renderer, el, csRef, updateEvent);
  }
}
