import {
  OnInit,
  OnDestroy,
  HostBinding,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
  HostListener
} from "@angular/core";
import { FormElement } from "../form-element";
import { FormBaseElement } from "../form-base-element";

export abstract class TextFormElement extends FormElement<string>
  implements FormBaseElement, OnInit, OnDestroy {
  @HostBinding("attr.maxlength") fieldLength: number;

  @HostBinding("attr.pattern") fieldPattern: string;
  @HostListener("blur", ["$event"])
  onInputBlur() {
    this.touch();
  }
  private listenerDestroy: any;
  private valueChanged = false;

  constructor(
    protected renderer: Renderer2,
    protected el: ElementRef,
    protected cdRef: ChangeDetectorRef,
    protected updateEvent?: string
  ) {
    super(renderer, el);
  }

  getFieldLength(): number {
    if (
      !this.fieldInfo ||
      this.fieldInfo.fieldLength === 0 ||
      this.fieldInfo.fieldLength === -1
    ) {
      return null;
    } else {
      return this.fieldInfo.fieldLength;
    }
  }

  getFeildPattern(): string {
    if (!this.fieldInfo || this.fieldInfo.pattern === "") {
      return null;
    } else {
      return this.fieldInfo.pattern;
    }
  }

  @HostListener("keyup", ["$event"])
  input(event: KeyboardEvent) {
    const value = this.el.nativeElement.value || "";
    if (value === (this.innerValue || "")) {
      return;
    }
  }

  private setElementProperty(value: string) {
    this.renderer.setProperty(this.el.nativeElement, "value", value);
  }

  writeValueFromInput(value): void {
    this.innerValue = value;
    this.setElementProperty(value);
  }

  writeValue(value: string): void {
    super.writeValue(value);
    this.valueChanged = false;
    this.setElementProperty(value);
  }

  ngOnInit() {
    super.ngOnInit();
    this.fieldLength = this.getFieldLength();

    this.updateEvent = this.updateEvent || "keyup";

    if (this.updateEvent !== "keyup") {
      this.listenerDestroy = this.renderer.listen(
        this.el.nativeElement,
        this.updateEvent,
        () => {
          if (!this.valueChanged) {
            return;
          }
          const val = this.innerValue;
          this.valueChanged = false;
          this.change(val);
        }
      );
    }
  }

  ngOnDestroy() {
    if (this.listenerDestroy) {
      this.listenerDestroy();
    }
  }
}
