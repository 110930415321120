import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ec-simple-page",
  templateUrl: "./simple-page.component.html",
  styleUrls: ["./simple-page.component.css"]
})
export class SimplePageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
