import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CommonModule } from "@angular/common";
import { GeneralInfoComponent } from "./general-info.component";
import { PageHeaderModule } from "../../shared/page-header/page-header.module";
import { ReactiveFormsModule } from "@angular/forms";
import { CoreInputsModule } from "@tmhcc/framwork/core-inputs";
import { QuoteCreateResolver } from "./quote-create-resolver";
import { NgxsModule } from "@ngxs/store";
import { QuoteCreateState } from "../store/state/quote-create.state";
import { QuoteCreateBussinessService } from "@tmhcc/data-service";
import { PageHeaderNavigationComponent } from "./../../shared/page-header/page-header-navigation/page-header-navigation";
import { UnsavedDataGaurd } from "./unsaved-data.guard";
const routes: Routes = [
  {
    path: "",
    component: GeneralInfoComponent,
    canDeactivate: [UnsavedDataGaurd]
  }
];

@NgModule({
  declarations: [GeneralInfoComponent, PageHeaderNavigationComponent],
  imports: [
    RouterModule.forChild(routes),
    [NgxsModule.forFeature([QuoteCreateState])],
    ReactiveFormsModule,
    CoreInputsModule,
    CommonModule,
    PageHeaderModule
  ],
  exports: [RouterModule, PageHeaderNavigationComponent],
  providers: [
    QuoteCreateResolver,
    QuoteCreateBussinessService,
    UnsavedDataGaurd
  ]
})
export class GeneralInfoModule {}
