<div class="container">  
    <div>
      <form [formGroup]="QuoteInfoCovFormGroup" (submit)="onSubmit()">
        <h3 class="header-tab mt-3">Quote Info/Coverages</h3>
        <div class="form-row mt-3">
            <div class="col-sm-6 col-md-3" *ngFor="let items of termInfoFields">
                <hcc-input-generator
                [frmGroup]="QuoteInfoCovFormGroup"
                [fieldInfo]="items.fieldInfo"
                [control]="items.fieldInfo.fieldName"
                [InputConfig]="items?.config"
             ></hcc-input-generator>
            </div>
        </div>     
        <h5 class="header-tab mt-3">Limits of Liability for Insurance Losses</h5>
        <div class="form-row mt-3">
            <div class="col-sm-6 col-md-3" *ngFor="let items of limitLossesForInsuranceLossesFields">
                <hcc-input-generator
                [frmGroup]="QuoteInfoCovFormGroup"
                [fieldInfo]="items.fieldInfo"
                [control]="items.fieldInfo.fieldName"
                [InputConfig]="items?.config"
             ></hcc-input-generator>
            </div>
        </div> 
        <h5 class="header-tab mt-3">Additional Covered Event Expenses</h5>
        <div class="form-row mt-3">
            <div class="col-sm-6 col-md-3" *ngFor="let items of additionalCoveredEventFields">
                <hcc-input-generator
                [frmGroup]="QuoteInfoCovFormGroup"
                [fieldInfo]="items.fieldInfo"
                [control]="items.fieldInfo.fieldName"
                [InputConfig]="items?.config"
             ></hcc-input-generator>
            </div>
        </div>                      
        <div class="row text-right mt-4 mb-4">
            <div class="col-md-3"></div>             
              <div class="col-md-3">
                <button
                  [disabled]="false"
                  class="btn btn-primary w-100"
                  type="button"                 
                >
                  Back
                </button>
              </div>
              <div class="col-md-3">
                <button
                  [disabled]="false"
                  class="btn btn-primary w-100"
                  type="submit"                                 
                >
                  Save
                </button>
              </div>
              <div class="col-md-3">
                <button
                  [disabled]="false"
                  class="btn btn-primary w-100"
                  type="button"  
                  (click) = "GoToSubjectivity()"               
                >
                  Next
                </button>
              </div>             
          </div>
      </form>
   </div>
</div>
