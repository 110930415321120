<div class="custom-radio-wrap">
  <label
    [disabled]="true"
    [ngbTooltip]="toolTipText"
    tooltipClass="my-custom-class"
    *ngIf="fieldInfo.showLabel"
    [fieldInfo]="fieldInfo"
    acForLable
    [fieldRequired]="fieldInfo.required"
  >
    {{ fieldInfo.label
    }}<span class="required-star" *ngIf="fieldInfo.required">*</span>
  </label>
</div>
<div [formGroup]="frmGroup" class="custom-radio-wrap">
  <div
    *ngFor="let item of InputConfig?.radioCofig?.radioGroup"
    [class]="item.style"
  >
    <!-- <hcc-custom-validation [validations]="fieldInfo.validations"> -->
    <input
      *ngIf="InputConfig?.isDisabled"
      type="radio"
      [name]="item.value"
      [value]="item.value"
      class="m-1 mr-0"
      [formGroup]="frmGroup"
      acRadiobutton
      (change)="OnRadioChange(item.label)"
      [formControlName]="control"
      [fieldInfo]="fieldInfo"
      [checked]="item.checked"
      disabled
    />

    <input
      *ngIf="!InputConfig?.isDisabled"
      type="radio"
      [name]="item.value"
      [value]="item.value"
      class="m-1 mr-0"
      [formGroup]="frmGroup"
      acRadiobutton
      (change)="OnRadioChange(item.label)"
      [formControlName]="control"
      [fieldInfo]="fieldInfo"
      [checked]="item.checked"
    />
    <!-- </hcc-custom-validation> -->
    <label *ngIf="fieldInfo.showLabel">
      {{ item.label
      }}<span class="required-star" *ngIf="fieldInfo.required"></span>
    </label>
  </div>
</div>

