import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  OnDestroy,
  OnChanges
} from "@angular/core";
import { CommonContentService } from "@tmhcc/content-service";
import {
  InputConfig,
  FieldInfo,
  FieldTypes
} from "@tmhcc/framwork/form-inputs";
import { quoteSummaryDefinition } from "./quote-summary-config";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CoreInputValidationService } from "@tmhcc/framwork/core-inputs";
import { DateService } from "@tmhcc/framwork/services";
import { QuoteStepStoreService } from "../../quote/store/facade/quote-step-store.service";
import { QuotePolicyStepStore } from "../../quote/store/state/quote-policy-step.state";
//import { EcoMasterData } from "@tmhcc/data-service";
import * as _ from "lodash";
import {
  EcoMasterData,
  ElementUpdateRequest,
  DownloadQuoteService,
  UpdateAndDownloadQuoteECO,
  UpdateUserConfigurableDataElements
} from "@tmhcc/data-service";
import {
  UserConfigurableDataElements,
  DataElementRespons,
  StateOrProvince
} from "@tmhcc/data-service/def/eco-master";
import {
  UWQuotes,
  LOBRisks,
  ElementUpdateRequests,
  PolicyTxnRequest
} from "@tmhcc/models";
import { QuoteExpirtionDateValidator } from "./quote-expiration-date.validator";
import { RiskModel, RiskResponseType } from "../events/event.config";
import { LoaderService } from "@tmhcc/framwork/loader/src";
import { take } from "rxjs/operators";
import { Store } from "@ngxs/store";
import { QuotePolicyTxn } from "../store/state/quote-policy-step.action";
import { Subscription } from "rxjs";
import moment from "moment-timezone";
import {
  DialogConfig,
  DialogService,
  DialogSize
} from "@tmhcc/framwork/dialog/src";
import { ValidationDialogComponent } from "../events/validation-dialog/validation-dialog.component";

@Component({
  selector: "ec-quote-summary",
  templateUrl: "./quote-summary.component.html",
  styleUrls: ["./quote-summary.component.scss"]
})
export class QuoteSummaryComponent extends quoteSummaryDefinition
  implements OnInit, OnChanges, OnDestroy {
  quotePolicyStepStore: QuotePolicyStepStore;
  getLabels: any;
  policyType: string;
  policyExpDate: string;
  quoteSummaryForm: FormGroup;
  quoteDownloadForm: FormGroup;
  additonalSummary: any[];
  showDownloadDiv: boolean;
  showTaxState: boolean;
  LimitOfInsurance: number;
  stateList: any[];
  currentUWQuoteID: number;
  coverages: any;
  showStandardSummary: boolean;
  ischildForm = false;
  premiumSummary = [];
  latestEventStartDate: string;
  getUserConfigurableDataElementsData: any;
  _ElementUpdateRequestArray: ElementUpdateRequest[] = [];
  bindStateIDValue: number;
  allstateList: any;
  isQuoteDownloadFormSubmitted = false;
  isQuoteDownloadFormError = false;
  quoteDownloadErrorMsg: string;
  taxesAndFees: any;
  updatedStateList = [];
  @Output() isChildFormValid: EventEmitter<any> = new EventEmitter<any>();
  quotePolicyTxnServiceInstance: Subscription;
  @Input() selectedQuoteID: number;
  isPolicyBind: boolean;
  constructor(
    private formBuilder: FormBuilder,
    public contentService: CommonContentService,
    private validationService: CoreInputValidationService,
    public dateService: DateService,
    private quoteStepStoreService: QuoteStepStoreService,
    private masterData: EcoMasterData,
    private downloadQuoteService: DownloadQuoteService,
    private loaderService: LoaderService,
    private dialogConfig: DialogConfig<any>,
    private dialogService: DialogService,
    private store: Store
  ) {
    super(contentService, dateService);
    this.getLabels = this.contentService.getLabels();
  }

  /* Quote Summary Field Section***/
  FrSelectList: FieldInfo = {
    fieldName: "FillingResponsibility",
    label: "Select",
    fieldType: FieldTypes.LookupList,
    placeholder: "Select",
    showLabel: false,
    validation: {
      required: "Select Filing Responsibility"
    }
  };

  FrSelectConfig: InputConfig = {
    config: {
      selectConfig: {
        options: [],
        optionConfig: {
          displayKey: "ResponseValue",
          search: true,
          limitTo: 3
        },
        multiple: false
      }
    },
    fieldInfo: this.FrSelectList
  };
  TaxStateSelectList: FieldInfo = {
    fieldName: "TaxState",
    label: "Select",
    fieldType: FieldTypes.LookupList,
    placeholder: "Select",
    showLabel: false,
    validation: {
      required: "Select Tax State"
    }
  };

  TaxSelectConfig: InputConfig = {
    config: {
      selectConfig: {
        options: [],
        optionConfig: {
          displayKey: "Description",
          search: true,
          limitTo: 3
        },
        multiple: false
      }
    },
    fieldInfo: this.TaxStateSelectList
  };
  QuoteExpirtionDateInfo: FieldInfo = {
    fieldName: "QuoteExpirtionDate",
    label: "Date Picker",
    fieldType: FieldTypes.Date,
    showLabel: false
  };

  QuoteExpirationDateConfig: InputConfig;

  public validateChildForm(data: any) {
    if (data === true) {
      if (this.quoteSummaryForm.valid === true) {
        this.isChildFormValid.emit(true);
      } else {
        this.isChildFormValid.emit(false);
      }
    }
  }
  ngOnChanges() {
    this.loaderService.show();
    setTimeout(() => this.loaderService.hide(), 500);
    this.currentUWQuoteID = this.selectedQuoteID;
    this.additionalSummaryData(this.currentUWQuoteID);
    // Quote Download Form Seup
    this.getEventFromPolicy();
    this.defaultQuoteExpDate();
    
  }

  defaultQuoteExpDate() {
    const defaultQuoteExpDate = this.dateService.addDays(
      this.dateService.toShortDate(this.dateService.now()),
      14
    );
    const quoteDownloadControls = {};
    if(defaultQuoteExpDate && this.latestEventStartDate) {
      const defaultShortDate = this.dateService.toShortDate(this.dateService.moment(defaultQuoteExpDate));
      const latestStartShortDate = this.dateService.toShortDate(this.dateService.moment(this.latestEventStartDate));
      let resultDate;
      if(this.dateService.isBeforeDate(defaultShortDate, latestStartShortDate) ||
        this.dateService.isSameDate(defaultShortDate, latestStartShortDate)) {
        resultDate = defaultShortDate;
      } else {
        resultDate = latestStartShortDate;
      }
      quoteDownloadControls[this.QuoteExpirtionDateInfo.fieldName] = [
        resultDate
      ];
      this.quoteDownloadForm = this.formBuilder.group(quoteDownloadControls, {
        validators: [
          QuoteExpirtionDateValidator(
            "QuoteExpirtionDate",
            this.latestEventStartDate,
            this.showDownloadDiv
          )
        ]
      });
      this.quoteDownloadForm.valueChanges.subscribe(() => {
        this.getQuoteDownloadValidation();
      }); 
    }
  }

  ngOnInit(): void {
    this.initFormGroup();
    this.ecoPolicySubscribe();
    this.formControlValueChanged();
    const {
      StateOrProvinces,
      Countries,
      UserConfigurableDataElements,
      TaxesAndFees
    } = this.masterData.getMasterData();
    this.getUserConfigurableDataElementsData = UserConfigurableDataElements;
    this.allstateList = StateOrProvinces;
    this.taxesAndFees = TaxesAndFees;
    if (UserConfigurableDataElements) {
      let ResponseArr = [];
      const { DataElementResponses }: UserConfigurableDataElements =
        UserConfigurableDataElements.find(
          (element: UserConfigurableDataElements) => {
            return element.ElementCode === "FR";
          }
        ) || null;
      if (DataElementResponses && DataElementResponses.length !== 0) {
        const ResponseValue = DataElementResponses.find(
          (value: DataElementRespons) => {
            if (value.ResponseValue) {
              this.FrSelectConfig.config.selectConfig.options.push(value);
            }
          }
        );
      }
    }
    if (StateOrProvinces) {
      this.stateList = StateOrProvinces.filter(
        items => items.CountryCode === "USA"
      );
      this.stateList.forEach(stateElement => {
        this.taxesAndFees.forEach(taxeselement => {
          if (stateElement.Id == taxeselement.StateId) {
            const stateListadd: StateOrProvince = {
              CountryCode: stateElement.CountryCode,
              Code: stateElement.Code,
              Id: stateElement.Id,
              Description: stateElement.Description,
              IsoCode: stateElement.IsoCode
            };
            this.updatedStateList.push(stateListadd);
          }
        });
      });
      //Removeed duplicate State ID
      this.updatedStateList = this.updatedStateList.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj["Id"]).indexOf(obj["Id"]) === pos;
      });

      this.stateList = this.updatedStateList;

      this.TaxSelectConfig.config.selectConfig.options = this.stateList;
    }
    this.QuoteExpirationDateConfig = {
      config: {
        datePickerConfig: {
          dateInputFormat: this.dateService.getFormat(),
          isCalanderRequired: true
        },
        isDisabled: true,
        validation: [Validators.required]
      },
      fieldInfo: this.QuoteExpirtionDateInfo
    };

    this.sendFillingResponsibilityData();
    this.getStateID();
  }
  formControlValueChanged() {
    const TaxState = this.quoteSummaryForm.get("TaxState");
    this.quoteSummaryForm.controls[
      "FillingResponsibility"
    ].valueChanges.subscribe(value => {
      if (value) {
        this.showTaxState = value.ResponseValue
          ? value.ResponseValue == "HCC"
          : false;
        if (value.ResponseValue == "HCC") {
          TaxState.setValidators([Validators.required]);
        }
        else{
          this.quoteSummaryForm.get("TaxState").clearValidators();
          this.quoteSummaryForm.get("TaxState").updateValueAndValidity();
        }
      } else {
        this.quoteSummaryForm.get("TaxState").clearValidators();
        this.quoteSummaryForm.get("TaxState").updateValueAndValidity();
        this.showTaxState = false;
      }
    });
  }
  ecoPolicySubscribe() {
    this.quoteStepStoreService.data$.subscribe((data: QuotePolicyStepStore) => {
      this.quotePolicyStepStore = data;

      if (
        this.quotePolicyStepStore &&
        this.quotePolicyStepStore.policyTxnData
      ) {
        if (
          this.quotePolicyStepStore.policyTxnData.ProcessStatusCode == "*" ||
          this.quotePolicyStepStore.policyTxnData.ProcessStatusCode == "I"
        ) {
          this.quoteSummaryForm.disable();
          this.isPolicyBind = true;
        }
        this.premiumSummary = [];
        const policyData = this.quotePolicyStepStore.policyTxnData;
        this.policyType = policyData.PolicyTypeDescription;
        if (this.policyType == "Standard Event") {
          this.showStandardSummary = true;
        } else {
          this.showStandardSummary = false;
        }

        if (policyData.PolicyExp) {
          this.policyExpDate = this.dateService.toShortDate(
            this.dateService.momentFromLongISO(policyData.PolicyExp)
          );
        }
        const uWQuotes: UWQuotes = policyData.UWQuotes.find(
          (value: UWQuotes) => {
            return (
              this.selectedQuoteID &&
              Number(this.selectedQuoteID) === value.UWQuoteId
            );
          }
        );
        this.updateUWQuoteProperties(uWQuotes);
        this.tradeShowAdditionalSummary(policyData.UWQuotes);
        this.additonalSummary = policyData.AdditionalSummaries;
        this.additionalSummaryData(this.currentUWQuoteID);
      }
    });
  }
  additionalSummaryData(currentUWQuoteID) {
    this.premiumSummary = [];
    if (this.additonalSummary) {
      this.additonalSummary.forEach(element => {
        if (element.UWQuoteId == currentUWQuoteID) {
          if (element.Description == "Event Limit of Insurance") {
            this.LimitOfInsurance = element.Amount;
          }
          if (element.Description == "Option 1") {
            this.premiumSummary.push({
              Description: element.Description,
              Amount: element.Amount
            });
          }
          if (element.Description == "Option 2") {
            this.premiumSummary.push({
              Description: element.Description,
              Amount: element.Amount
            });
          }
          if (element.Description == "Option 3") {
            this.premiumSummary.push({
              Description: element.Description,
              Amount: element.Amount
            });
          }
          if (element.Description == "Option 4") {
            this.premiumSummary.push({
              Description: element.Description,
              Amount: element.Amount
            });
          }
          if (element.Description == "Additional Perils") {
            this.premiumSummary.push({
              Description: element.Description,
              Amount: element.Amount
            });
          }
        }
      });
    }
  }
  initFormGroup() {
    const controls = {};
    controls[this.FrSelectList.fieldName] = ["", Validators.required];
    controls[this.TaxStateSelectList.fieldName] = [""];
    this.quoteSummaryForm = this.formBuilder.group(controls);
  }

  private getQuoteDownloadValidation() {
    const errors = this.quoteDownloadForm.get(
      this.QuoteExpirtionDateInfo.fieldName
    ).errors;
    const isValid = this.quoteDownloadForm.get(
      this.QuoteExpirtionDateInfo.fieldName
    ).invalid;
    if (isValid) {
      this.isQuoteDownloadFormError = true;
    } else {
      this.isQuoteDownloadFormError = false;
    }
    if (errors && this.isQuoteDownloadFormSubmitted) {
      if (errors.required) {
        this.quoteDownloadErrorMsg = "Required field";
      } else if (errors.pastDate) {
        this.quoteDownloadErrorMsg = "Can not be past date";
      } else if (errors.invalidDate) {
        this.quoteDownloadErrorMsg = "Invalid date";
      } else {
        this.quoteDownloadErrorMsg = null;
      }
    } else {
      this.quoteDownloadErrorMsg = null;
    }
  }

  updateUWQuoteProperties(uWQuotesInstance: UWQuotes) {
    const SelectedUwQuoteId = sessionStorage.selectedUwQuoteId;
    if (SelectedUwQuoteId) {
      this.currentUWQuoteID = SelectedUwQuoteId;
    } else {
      this.currentUWQuoteID = uWQuotesInstance.UWQuoteId;
      sessionStorage.selectedUwQuoteId = this.currentUWQuoteID;
    }
  }
  tradeShowAdditionalSummary(uWQuotes: UWQuotes[]) {
    const UWQuoteId = this.currentUWQuoteID;
    const filteredUWQuote: UWQuotes = uWQuotes.find(
      (value: UWQuotes) => value.UWQuoteId == UWQuoteId
    );
    if (filteredUWQuote.LOBs && filteredUWQuote.LOBs.length !== 0) {
      const risks = filteredUWQuote.LOBs[0].Risks;
      if (risks && risks.length !== 0) {
        risks.forEach(element => {
          if (element.RiskTypeCode == "FCH") {
            this.coverages = element.Coverages;
          }
        });
      }
    }
  }

  toggle() {
    this.showDownloadDiv = !this.showDownloadDiv;
  }

  getEventFromPolicy() {
    this.latestEventStartDate = "";
    const uWQuotes: UWQuotes = this.quoteStepStoreService.data.policyTxnData.UWQuotes.find(
      (value: UWQuotes) => {
        return (
          this.selectedQuoteID &&
          Number(this.selectedQuoteID) === value.UWQuoteId
        );
      }
    );
    if (uWQuotes && uWQuotes.LOBs && uWQuotes.LOBs.length !== 0) {
      const risks = uWQuotes.LOBs[0].Risks; // As per service team, always using LOBs' 1st element
      if (risks && risks.length !== 0) {
        risks.forEach((lOBRisk: LOBRisks) => {
          if (
            lOBRisk.RiskTypeCode !== "LOB" &&
            lOBRisk.RiskDataElements &&
            lOBRisk.RiskDataElements.ElementUpdateRequests
          ) {
            lOBRisk.RiskDataElements.ElementUpdateRequests.forEach(
              (value: ElementUpdateRequests) => {
                const riskObject = _.pickBy(
                  RiskModel,
                  riskVal => riskVal.name === value.ElementCode
                );
                const riskKey = _.keys(riskObject)[0];
                if (riskKey) {
                  if (
                    riskObject[riskKey].type === RiskResponseType.DateResponse
                  ) {
                    if (value.ElementCode === "StartDt") {
                      if (this.latestEventStartDate) {
                        if (
                          this.dateService.isBeforeDate(
                            this.dateService.toShortDate(
                              this.dateService.moment(value.DateValue)
                            ),
                            this.dateService.toShortDate(
                              this.dateService.moment(this.latestEventStartDate)
                            )
                          )
                        ) {
                          this.latestEventStartDate = value.DateValue;
                        }
                      } else {
                        this.latestEventStartDate = value.DateValue;
                      }
                    }
                  }
                }
              }
            );
          }
        });
      }
    }
  }

  ngOnDestroy() {
    if (this.quotePolicyTxnServiceInstance) {
      this.quotePolicyTxnServiceInstance.unsubscribe();
    }
    this.validationService.setFormSubmission(false);
  }

  onDownloadQuote() {
    this.isQuoteDownloadFormSubmitted = true;
    this.getQuoteDownloadValidation();
    const getPolicyTxnRequest: PolicyTxnRequest = {
      PolicyTxnID: this.quoteStepStoreService.data.policyTxnData.PolicyTxnId.toString()
    };
    if (this.quoteDownloadForm.valid) {
      this.loaderService.show();
      this.quotePolicyTxnServiceInstance = this.store
        .dispatch(new QuotePolicyTxn(getPolicyTxnRequest))
        .subscribe(
          () => {
            const filingElementId = this.masterData
              .getMasterData()
              .UserConfigurableDataElements.find(
                (value: UserConfigurableDataElements) => {
                  return value.ElementCode === "FR";
                }
              );
            if (filingElementId && filingElementId.ElementId) {
              const policyFRData = this.quoteStepStoreService.data.policyTxnData.policyDataElements.ElementUpdateRequests.find(
                (value: ElementUpdateRequests) => {
                  return value.ElementId === filingElementId.ElementId;
                }
              );
              if (
                policyFRData.SelectedDataElementResponseIds &&
                policyFRData.SelectedDataElementResponseIds[0]
              ) {
                const downloadQuoteECOData = this.prepareUpdateAndDownloadQuoteECO(
                  policyFRData
                );
                this.loaderService.show();
                this.downloadQuoteService
                  .updateAndDownloadQuoteECO(downloadQuoteECOData)
                  .pipe(take(1))
                  .subscribe((data) => {
                    this.loaderService.hide();
                    if(data.Status === "SUCCESS") {
                      this.defaultQuoteExpDate();
                      this.openSaveRequestDialog(
                        this.getLabels.quoteDownloadSuccessTitle,
                        this.getLabels.quoteDownloadSuccessDesc
                      );
                    }
                  });
              } else {
                this.defaultQuoteExpDate();
                this.loaderService.hide();
                this.openSaveRequestDialog(
                  this.getLabels.quotePreventDownloadTitle,
                  this.getLabels.quotePreventDownloadDesc
                );
              }
            }
          },
          () => {
            this.loaderService.hide();
          }
        );
    }
  }

  openSaveRequestDialog(title: string, text: string) {
    const content = {
      value: text
    };
    this.dialogConfig.modalSetting = {
      title: title,
      overflow: true,
      hideFooter: true,
      dialogSize: DialogSize.LG,
      hideSave: true
    };
    this.dialogConfig.data = content;
    const ref = this.dialogService.open(
      ValidationDialogComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {});
  }

  prepareUpdateAndDownloadQuoteECO(
    frData: ElementUpdateRequests
  ): UpdateAndDownloadQuoteECO {
    let updateAndDownloadQuoteECO: UpdateAndDownloadQuoteECO;
    const quoteExpDate = this.dateService.toLongISO(this.dateService.moment(this.quoteDownloadForm.get(this.QuoteExpirtionDateInfo.fieldName).value));
    updateAndDownloadQuoteECO = {
      PolicyTxnId: this.quotePolicyStepStore.policyTxnData.PolicyTxnId
        ? Number(this.quotePolicyStepStore.policyTxnData.PolicyTxnId)
        : null,
      UWQuoteId: this.currentUWQuoteID?this.currentUWQuoteID:this.quotePolicyStepStore.policyTxnData.UWQuotes[0].UWQuoteId,
      DownloadFlag: true,
      StateId: this.quotePolicyStepStore.policyTxnData.StateId,
      QuoteExpiryDate: quoteExpDate,
      PolicyDataElements: {
        ElementUpdateRequests: [frData]
      }
    };
    return updateAndDownloadQuoteECO;
  }

  getStateID() {
    this.quoteSummaryForm.controls["TaxState"].valueChanges.subscribe(value => {
      if (value) {
        this.bindStateIDValue = value.Id;
        //this.bindSummaryData.emit(this.bindStateIDValue);
      }
    });

    let FillingResponsibilityDropDownValue = this.quoteSummaryForm.get(
      "FillingResponsibility"
    ).value;
    if (
      this.quotePolicyStepStore.policyTxnData.StateId &&
      FillingResponsibilityDropDownValue.ResponseValue != "Broker"
    ) {
      this.allstateList = this.allstateList.filter(
        items => items.CountryCode === "USA"
      );
      this.stateList.forEach(stateObj => {
        if (stateObj.Id == this.quotePolicyStepStore.policyTxnData.StateId) {
          this.quoteSummaryForm.get("TaxState").setValue(stateObj);
          this.bindStateIDValue = stateObj.Id;
          //this.bindSummaryData.emit(this.bindStateIDValue);
        }
      });
    } else {
      this.bindStateIDValue = 0;
      //this.bindSummaryData.emit(this.bindStateIDValue);
    }
  }

  /**
   * Send Data to Save API.
   * Filling Responsibility: Data
   */

  sendFillingResponsibilityData() {
    this._ElementUpdateRequestArray = [];
    if (this.getUserConfigurableDataElementsData) {
      this.getUserConfigurableDataElementsData =
        this.getUserConfigurableDataElementsData.find(
          (element: UserConfigurableDataElements) => {
            return element.ElementCode.toLowerCase() === "fr";
          }
        ) || null;

      let objElementUpdateRequest: ElementUpdateRequest = {
        DataGroupId: this.getUserConfigurableDataElementsData.DataGroupId,
        DataGroupName: this.getUserConfigurableDataElementsData.DataGroupName
          ? this.getUserConfigurableDataElementsData.DataGroupName
          : "",
        IsArray: this.getUserConfigurableDataElementsData.GroupIsArray,
        ElementId: this.getUserConfigurableDataElementsData.ElementId,
        ElementName: this.getUserConfigurableDataElementsData.ElementName,
        ResponseType: this.getUserConfigurableDataElementsData.ResponseType,
        SelectedDataElementResponseIds: []
      };
      this._ElementUpdateRequestArray.push(objElementUpdateRequest);
    }

    this.quoteSummaryForm.controls[
      "FillingResponsibility"
    ].valueChanges.subscribe(value => {
      if (value) {
        let selectedFillingResponsibilityValue = value.ResponseValue
          ? value.ResponseValue
          : value.ResponseName;
        this.getUserConfigurableDataElementsData.DataElementResponses.forEach(
          (elementDataObj: any) => {
            if (
              elementDataObj.ResponseName === selectedFillingResponsibilityValue
            ) {
              this._ElementUpdateRequestArray[0].SelectedDataElementResponseIds[0] =
                elementDataObj.DataElementResponseId;
            }
            if (value.ResponseValue == "Broker") {
              this.bindStateIDValue = 0;
            }
            if (value.ResponseValue != "Broker") {
              this.getStateID();
            }
          }
        );
      }
    });

    //if we get data from getpolicy
    if (
      this.quotePolicyStepStore.policyTxnData.policyDataElements
        .ElementUpdateRequests.length !== 0
    ) {
      this.quotePolicyStepStore.policyTxnData.policyDataElements.ElementUpdateRequests.forEach(
        getPolicyElementObj => {
          if (
            getPolicyElementObj.ElementId ===
            this.getUserConfigurableDataElementsData.ElementId
          ) {
            if (
              getPolicyElementObj.SelectedDataElementResponseIds.length !== 0
            ) {
              this._ElementUpdateRequestArray[0].SelectedDataElementResponseIds[0] =
                getPolicyElementObj.SelectedDataElementResponseIds[0];
              this.getUserConfigurableDataElementsData.DataElementResponses.forEach(
                elements => {
                  if (
                    elements.DataElementResponseId ==
                    getPolicyElementObj.SelectedDataElementResponseIds[0]
                  ) {
                    this.quoteSummaryForm
                      .get("FillingResponsibility")
                      .setValue(elements);
                  }
                }
              );
            }
          }
        }
      );
    }
  }
}
