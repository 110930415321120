import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BreadcrumbProgressComponent } from "./breadcrumb-progress/breadcrumb-progress.component";

@NgModule({
  declarations: [BreadcrumbProgressComponent],
  imports: [CommonModule],
  exports: [BreadcrumbProgressComponent]
})
export class LibModuleModule {}
