import { Injectable } from "@angular/core";
import { SeverityLevel } from "./severity-level.model";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { AppConfigService } from "../../../../global-services/app-config.service";
import { environment } from 'apps/policy-admin/src/environments/environment';
@Injectable()
export class LoggingService {
  appInsights: ApplicationInsights;

  constructor(private appConfig: AppConfigService) {
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appConfig.logging.appInsights
          .instrumentationKey,
        enableAutoRouteTracking: environment.appConfig.logging.appInsights
          .enableAutoRouteTracking // option to log all route changes
      }
    });
    this.appInsights.loadAppInsights();
  }
  logPageView(name?: string, url?: string) {
    if (
      environment.appConfig &&
      environment.appConfig.logging &&
      environment.appConfig.logging.appInsightEnabled
    ) {
      this.appInsights.trackPageView({
        name: name,
        uri: url
      });
    }
  }

  // Log non-exception type errors, e.g. invalid API request
  logError(error: any) {
    this.log(error, SeverityLevel.Error);
  }

  logWarning(error: any) {
    this.log(error, SeverityLevel.Warning);
  }

  logInfo(error: any) {
    this.log(error, SeverityLevel.Information);
  }

  logVerbose(error: any) {
    this.log(error, SeverityLevel.Verbose);
  }

  logCritical(error: any) {
    this.log(error, SeverityLevel.Critical);
  }
  private log(error: any, severityLevel?: SeverityLevel) {
    /// Need to remove dependency of SeverityLevel
    if (
      environment.appConfig &&
      environment.appConfig.logging &&
      environment.appConfig.logging.console
    ) {
      this.sendToConsole(error, severityLevel);
    }
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    if (
      environment.appConfig &&
      environment.appConfig.logging &&
      environment.appConfig.logging.appInsightEnabled
    ) {
      this.appInsights.trackEvent({ name: name }, properties);
    }
  }

  // Need to comment it out
  logException(exception: Error, properties?: { [key: string]: any }) {
    this.log(exception, SeverityLevel.Error);
    if (
      environment.appConfig &&
      environment.appConfig.logging &&
      environment.appConfig.logging.appInsightEnabled
    ) {
      this.appInsights.trackException({
        exception: exception,
        properties: properties
      });
    }
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    if (
      environment.appConfig &&
      environment.appConfig.logging &&
      environment.appConfig.logging.appInsightEnabled
    ) {
      this.appInsights.trackTrace({ message: message }, properties);
    }
  }

  private sendToConsole(
    error: any,
    severityLevel: SeverityLevel = SeverityLevel.Error
  ) {
    switch (severityLevel) {
      case SeverityLevel.Critical:
      case SeverityLevel.Error:
        (<any>console).group(environment.appConfig.appName + " Error: ");
        if (error.message) {
          console.error(error.message);
        } else if (error.stack) {
          console.error(error.stack);
        } else {
          console.error(error);
        }
        (<any>console).groupEnd();
        break;
      case SeverityLevel.Warning:
        (<any>console).group(environment.appConfig.appName + " Error: ");
        console.warn(error);
        (<any>console).groupEnd();
        break;
      case SeverityLevel.Information:
        (<any>console).group(environment.appConfig.appName + " Error: ");
        console.log(error);
        (<any>console).groupEnd();
        break;
    }
  }
}
