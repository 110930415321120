import { Moment } from "@tmhcc/framwork/services";

export class PolicyGridRow {
  PolicyNumber: string;
  EffectiveDate: Moment;
  ExpirationDate: Moment;
  InsuredName: string;
  BrokerName: string;
  Underwriter: string;
  GrossPremium: string;
}
