import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
//import { Observable } from "rxjs/internal/Observable";
import { Observable } from "rxjs";
import { BaseDataService } from "../base-data-service";
import { AppConfigService } from "@tmhcc/global-services";
import { DateService } from "@tmhcc/framwork/services";
import { CommonContentService } from "@tmhcc/content-service";
import { policyDetail,quotesData, policyData, DashboardGrid,DashboardGridDwld,thimbleDocument } from "@tmhcc/models";
import { map, catchError } from "rxjs/operators";
import { saveAs } from 'file-saver';
import  *  as  jsonData  from  'libs/data-service/policy_mock_data.json';
import { result } from "@tmhcc/util";
import { HttpOptions } from "@tmhcc/framwork/http";
import {      
  KnrPolicySearchRequest
} from "../def/knr-quote-policy-search";
import {      
  KnrPolicySearchResponse,KnrPolicySearchResult,
} from "../../models/classes/knr-policy-gird.model";
import {      
  QuoteResponseData,QuoteDetail,UpdateQuoteRequest
} from "../../models/classes/knr-quote-details.model";
import {RatingRequest} from "libs/data-service/def/knr-rating";
import {      
  RatingResponse,FinalPremiums,Premiums
} from "../../models/classes/knr-rating.model";
import {AccountSetupResponse} from "../../models/classes/knr-account-data.model";
import {RatingDataResponse} from "../../models/classes/knr-rating-data.model";
import { NewEntityComponent } from "@tmhcc/framwork/entity-search/src/lib/new-entity/new-entity.component";
import { QuoteInfoCoveragesResponse } from "../../models/classes/knr-quote-info-coverages.model";
import { SubjectivitiesDataResponse,KnRSubjectivityGrid,KnRSubjectivityGridRow } from "../../models/classes/knr-subjectivities-data.model";
import { of, throwError } from 'rxjs';
import { QuoteInfoCoveragesRequest } from "libs/data-service/def/knr-quote-info-coverages";
import { KnRPolicySubjectivityResponse } from "libs/data-service/def/knrsubjectivity";
import {KnRSaveSubjectiviyRequest,KnRSubjectivityRequest} from "libs/data-service/def/knrsubjectivity";
@Injectable()
export class KnrPolicyQuoteService extends BaseDataService {
  accountSetupCachedData:AccountSetupResponse;
  ratingCachedData: RatingDataResponse;
  quoteInfoCoveragesCacheData: QuoteInfoCoveragesResponse;
  subjectivitiesDataResponseCache: SubjectivitiesDataResponse;
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    private dateService: DateService,
    private commonContentService: CommonContentService
  ) {
    super(http, configService);
  }  

  getKNRQuotePolicyList(payload:KnrPolicySearchRequest) : Observable<KnrPolicySearchResponse>
  {    
      let baseURL = this.knrServiceBaseUrl;   
      let knrServiceApiKey = this.knrServiceAPIKey;
      //const url = `${baseURL}Transactions/${payload.RandomQuote}/${payload.RandomPolicy}/${payload.insured}`;
      const url = `${baseURL}search`;   
      return this.postv1<KnrPolicySearchResponse>(url, { body: payload },knrServiceApiKey).pipe(
        map(data => this.mapToQuotePolicyList(data)) ,
        catchError(this.handleError)     
      );
  } 
  private mapToQuotePolicyList(data:any) : KnrPolicySearchResponse {
    //debugger;
    if (!data) return;
    let policyGridRows: KnrPolicySearchResult[] = [];
    let knrDashboardGrid =  new KnrPolicySearchResponse();
    for (let index = 0; index < data.searchDetails.length; index++) {     
          const element = data.searchDetails[index];      
          let row = new KnrPolicySearchResult();  
          row.QuoteId = element.quoteId;    
          row.QuoteNumber = element.quoteNumber;
          row.PolicyNumber = element.policyNumber;
          row.InsuredName = element.insuredName;
          row.Underwriter = element.underwriter;
          row.Broker = element.brokerName;
          row.Limit = element.limit ? this.replaceCurrencySign(element.limit) : "";
          row.Status = element.status;
          row.GrossPremium = element.grossPremium;                   
          policyGridRows.push(row);
       }
       knrDashboardGrid.policySearchResults  = policyGridRows;    
       return knrDashboardGrid;
  } 
  
  getQuoteDetails(quoteId: string) : Observable<QuoteResponseData>{
     let baseURL = this.knrServiceBaseUrl;   
     let knrServiceApiKey = this.knrServiceAPIKey;
     const headerDict = {
      'Content-Type': 'application/json',
      'x-api-key': knrServiceApiKey
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };         
     const url = `${baseURL}quote/${quoteId}`;
    return this.http.get<QuoteResponseData>(url,requestOptions).pipe(
            map(data => this.mapToQuoteDetail(data)),
            catchError(this.handleError)
          );
  }
  private mapToQuoteDetail(data:any) : QuoteResponseData {
    if (!data) return;
     return data;
  }
  replaceCurrencySign(value:any) : any{
    return value.replace(
      /[,$,R$,kr,€,$,₹,¥,₩,R,£,zł,Rs]/g,
      ''
    );
   }
  updateQuote(payload:UpdateQuoteRequest) : Observable<QuoteDetail>
  {    
      let baseURL = this.knrServiceBaseUrl;   
      let knrServiceApiKey = this.knrServiceAPIKey;      
      const url = `${baseURL}generatequote`;   
      return this.postv1<UpdateQuoteRequest>(url, { body: payload },knrServiceApiKey).pipe(
        map(data => this.mapToUpdateQuoteResponse(data)) ,
        catchError(this.handleError)     
      );
  }
  mapToUpdateQuoteResponse(data:any): QuoteDetail{
    let quoteResponse = new QuoteDetail();
    if(!data) return null;
    quoteResponse.QuoteId = data.quoteId;
    quoteResponse.QuoteNumber = data.quoteNumber;
    return quoteResponse;
  }
  calculateRating(payload: RatingRequest) :  Observable<RatingResponse>
  {
    let baseURL = this.knrServiceBaseUrl;   
      let knrServiceApiKey = this.knrServiceAPIKey;      
      const url = `${baseURL}price`;   
      return this.postv1<RatingRequest>(url, { body: payload },knrServiceApiKey).pipe(
        map(data => this.mapToRatingResponse(data)) ,
        catchError(this.handleError)     
      );
  }
  mapToRatingResponse(data:any): RatingResponse{
    let ratingResponse = new RatingResponse();
    if(!data) return null;
    ratingResponse.QuoteId = data.quoteId;
    ratingResponse.QuoteNumber = data.quoteNumber;
    let finalPremium = new FinalPremiums();
    let premiums = new Premiums()
    finalPremium.OneYear = data.finalPremiums.OneYear;
    finalPremium.TwoYear = data.finalPremiums.TwoYear;
    finalPremium.ThreeYear = data.finalPremiums.ThreeYear;
    ratingResponse.FinalPremiums = finalPremium;
    premiums.Base = data.premiums.Base;
    premiums.MedEvac = data.premiums.MedEvac;
    premiums.ModBase = data.premiums.ModBase;
    premiums.ModLimit = data.premiums.ModLimit;
    premiums.WPV = data.premiums.WPV;
    ratingResponse.Premiums = premiums;
    return ratingResponse;
  }
  getAccountSetupData(){
    //debugger;
    if( this.accountSetupCachedData &&
        this.accountSetupCachedData.LinesOfBusiness.length      
      ){
        return of(this.accountSetupCachedData);
     }
    let baseURL = this.knrServiceBaseUrl;   
    let knrServiceApiKey = this.knrServiceAPIKey;
    const headerDict = {
     'Content-Type': 'application/json',
     'x-api-key': knrServiceApiKey
   }
   const requestOptions = {                                                                                                                                                                                 
     headers: new HttpHeaders(headerDict), 
   };         
    const url = `${baseURL}data/account`;
   return this.http.get<AccountSetupResponse>(url,requestOptions).pipe(
           map(data => this.AccountSetupMapper(data)),
           catchError(this.handleError)
         );
  } 
  getSubjectivityConfigData() {
    if( this.subjectivitiesDataResponseCache &&
      this.subjectivitiesDataResponseCache.subjectivities.length      
    ){
      return of(this.subjectivitiesDataResponseCache);
   }
    let baseURL = this.knrServiceBaseUrl;   
    let knrServiceApiKey = this.knrServiceAPIKey;
    const headerDict = {
     'Content-Type': 'application/json',
     'x-api-key': knrServiceApiKey
   }
   const requestOptions = {                                                                                                                                                                                 
     headers: new HttpHeaders(headerDict), 
   };         
    const url = `${baseURL}data/subjectivities`;
   return this.http.get<SubjectivitiesDataResponse>(url,requestOptions).pipe(
           map(data => this.SubjectivitiesSetupMapper(data)),
           catchError(this.handleError)
         );
  }
  getQuoteInfoCoveragesData(){ //QuoteInfoCoveragesesponse
    //debugger;
    if( this.quoteInfoCoveragesCacheData &&
        this.quoteInfoCoveragesCacheData.TermLengths.length      
      ){
        return of(this.quoteInfoCoveragesCacheData);
     }
    let baseURL = this.knrServiceBaseUrl;   
    let knrServiceApiKey = this.knrServiceAPIKey;
    const headerDict = {
     'Content-Type': 'application/json',
     'x-api-key': knrServiceApiKey
   }
   const requestOptions = {                                                                                                                                                                                 
     headers: new HttpHeaders(headerDict), 
   };         
    const url = `${baseURL}data/quoteinfo`;
   return this.http.get<QuoteInfoCoveragesResponse>(url,requestOptions).pipe(
           map(data => this.QuoteInfoCoveragesMapper(data)),
           catchError(this.handleError)
         );
  }
  private QuoteInfoCoveragesMapper(data: any): QuoteInfoCoveragesResponse {    
    let datad = data.data;
    let objdata = new QuoteInfoCoveragesResponse(
                  datad.termLengths,
                  datad.limitsOfLiabilityForInsuranceLosses,
                  datad.additionalCoveredEventExpenses); 
    this.quoteInfoCoveragesCacheData = objdata;
    return objdata;
  }
mapToAccountSetupData(data:any) : AccountSetupResponse {    
    let datad = data.data;
    return new AccountSetupResponse(datad.linesOfBusiness,datad.policyTypes,
                                    datad.countries,datad.statesOrProvinces,
                                    datad.underwriters,datad.assistantUnderwriters,
                                    datad.brokers);
  } 
  clearAccountSetupData(){
    this.accountSetupCachedData = null;
  }
  private AccountSetupMapper(data: any): AccountSetupResponse {
    //debugger;
    let datad = data.data;
    let objdata = new AccountSetupResponse(datad.linesOfBusiness,datad.policyTypes,
      datad.countries,datad.statesOrProvinces,
      datad.underwriters,datad.assistantUnderwriters,
      datad.brokers); 
    this.accountSetupCachedData = objdata;
    return objdata;
  }
  private SubjectivitiesSetupMapper(data: any): SubjectivitiesDataResponse {    
    let datad = data.data;
    let objdata = new SubjectivitiesDataResponse(datad.subjectivities)
    this.subjectivitiesDataResponseCache = objdata;
    return objdata;
  }

  getRatingData(){
    //debugger;
    if( this.ratingCachedData &&
        this.ratingCachedData.BasePremium.companyVisibility.length      
      ){
        return of(this.ratingCachedData);
     }
    let baseURL = this.knrServiceBaseUrl;   
    let knrServiceApiKey = this.knrServiceAPIKey;
    const headerDict = {
     'Content-Type': 'application/json',
     'x-api-key': knrServiceApiKey
   }
   const requestOptions = {                                                                                                                                                                                 
     headers: new HttpHeaders(headerDict), 
   };         
    const url = `${baseURL}data/rating`;
   return this.http.get<RatingDataResponse>(url,requestOptions).pipe(
           map(data => this.RatingMapper(data)),
           catchError(this.handleError)
         );
  }
  private RatingMapper(data: any): RatingDataResponse {
    //debugger;
    let datad = data.data;
    let objdata = new RatingDataResponse(datad.basePremium,datad.modifiedBasePremium,datad.limitModification,
      datad.coverageModifiers,datad.riskQualityModifiers,datad.workplaceViolence);
    this.ratingCachedData = objdata;
    return objdata;
  }

  AddQuoteInfoCoverages(payload: QuoteInfoCoveragesRequest): Observable<string> {
    let baseURL = this.knrServiceBaseUrl;   
      let knrServiceApiKey = this.knrServiceAPIKey;      
      const url = `${baseURL}quoteinfo`;   
      return this.postv1<QuoteInfoCoveragesRequest>(url, { body: payload },knrServiceApiKey).pipe(
        map(data => data.QuoteID) ,
        catchError(this.handleError)     
      );
  }
  getSubjectivity(quoteId:any)  : Observable<KnRSubjectivityGrid>
  {   
      let baseURL = this.knrServiceBaseUrl;   
      let knrServiceApiKey = this.knrServiceAPIKey;
      const headerDict = {
      'Content-Type': 'application/json',
      'x-api-key': knrServiceApiKey
    }
    const requestOptions = {                                                                                                                                                                                 
      headers: new HttpHeaders(headerDict), 
    };         
      const url = `${baseURL}subjectivities/${quoteId}`;
    return this.http.get<KnRSubjectivityGrid>(url,requestOptions).pipe(
            map(data => this.mapToQuoteGridRow(data)),
            catchError(this.handleError)
          );
  }
  mapToQuoteGridRow(data: any): KnRSubjectivityGrid {    
    let subjectivityGrid = new KnRSubjectivityGrid();
    if (!data || !data.subjectivities) return;
    if (data.subjectivities) {
      let gridRows: KnRSubjectivityGridRow[] = [];
      for (let index = 0; index < data.subjectivities.length; index++) {
        const element = data.subjectivities[index];

        let row = new KnRSubjectivityGridRow();
        row.description = element.description;
        row.checked = element.isResolved;
        row.policySubjectivityId = element.id;

        gridRows.push(row);
      }
      subjectivityGrid.subjectivityData = gridRows;
    }
    return subjectivityGrid;
  }
  saveSubjectivity(payload:any): Observable<string>  {
    let baseURL = this.knrServiceBaseUrl;   
    let knrServiceApiKey = this.knrServiceAPIKey;      
    const url = `${baseURL}subjectivities`;   
    return this.postv1<KnRSaveSubjectiviyRequest>(url, { body: payload },knrServiceApiKey).pipe(
      map(data => this.mapSaveSubjectivity(data)) ,
      catchError(this.handleError)     
    );
  }
  mapSaveSubjectivity(data: any) {   
    return data.quoteId;
  }

}
