import { HostBinding, Renderer2, ElementRef } from "@angular/core";
import { FormElementBase } from "./form-element-base";

export abstract class FormElement<T> extends FormElementBase<T> {
  @HostBinding("class.form-control") formClass = true;
  @HostBinding("class.m-2") maring = false;

  constructor(protected renderer: Renderer2, protected el: ElementRef) {
    super(renderer, el);
  }
}
