<div class="form-group">
  <div [ngClass]="{ hasError: isError && isFormsubmitted }">
    <ng-content></ng-content>
    <ng-container *ngIf="isError && isFormsubmitted">
      <span class="text-danger" *ngFor="let msg of errorMessages">
        {{ msg }}</span
      >
    </ng-container>
  </div>
</div>
