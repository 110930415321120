import { Injectable } from "@angular/core";
import { Select } from "@ngxs/store";
import { Dispatch } from "@ngxs-labs/dispatch-decorator";
import { Observable } from "rxjs";
import {
  QuotePolicyTxn,
  UpdateQuotePolicyTxnData,
  IssueNewBusiness,
  UpdatePolicyData,
  AddOptionalForm
} from "../state/quote-policy-step.action";
import {
  QuotePolicyStepStore,
  QuotePolicyStepState
} from "../state/quote-policy-step.state";
import {
  PolicyTxnData,
  PolicyTxnRequest,
  LOBs,
  UWQuotes,
  EndorsementForms
} from "@tmhcc/models";
import { IssueNewBusinessRequest } from "@tmhcc/data-service";

@Injectable({ providedIn: "root" })
export class QuoteStepStoreService {
  @Select(QuotePolicyStepState)
  public data$: Observable<QuotePolicyStepStore>;
  public data: QuotePolicyStepStore;

  constructor() {
    this.data$.subscribe(value => (this.data = value));
  }

  @Dispatch()
  public setPolcyTxnData(policyTxnData: PolicyTxnRequest) {
    return new QuotePolicyTxn(policyTxnData);
  }

  @Dispatch()
  public updatePolcyTxnData(payload: UWQuotes[]) {
    return new UpdatePolicyData(payload);
  }

  @Dispatch()
  public updateQuotePolicyTxnData(uwQuoteId: number, payload: LOBs[]) {
    return new UpdateQuotePolicyTxnData(uwQuoteId, payload);
  }

  @Dispatch()
  public issueNewbusiness(issueNewBusinessRequest: IssueNewBusinessRequest) {
    return new IssueNewBusiness(issueNewBusinessRequest);
  }
  @Dispatch()
  public addOptionalForm(endorsmentForms: EndorsementForms) {
    return new AddOptionalForm({ ...endorsmentForms });
  }
}
