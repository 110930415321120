import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { BaseDataService } from "./base-data-service";
import { AppConfigService } from "@tmhcc/global-services";
import { ClaimSearchRequest, ClaimSearchResponse } from "./def/claim-search";
import { ClaimGridRow } from "@tmhcc/models";
import { DateService } from "@tmhcc/framwork/services";
import { CommonContentService } from "@tmhcc/content-service";
import { claimSearchMockup } from "./ref docs/mockup/claim-search.mockup";

@Injectable()
export class ClaimSearchService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    private dateService: DateService,
    private commonContentService: CommonContentService
  ) {
    super(http, configService);
  }

  searchClaim(payload: ClaimSearchRequest): Observable<ClaimGridRow[]> {
    const url = `${this.serviceBaseUrl}/ClaimSearch`;

    // when getting data from mockup
    return this.returnObservableForStaticData<ClaimGridRow[]>(
      this.mapToClaimGridRow(claimSearchMockup)
    );

    // when getting data from API service.
    // return this.post<ClaimSearchResponse>(url, { body: payload }).pipe(
    //   map(data => this.mapToClaimGridRow(data)),
    //   catchError(this.handleError)
    // );
  }

  private mapToClaimGridRow(data: ClaimSearchResponse): ClaimGridRow[] {
    if (!data || !data.claimSearchResults) return [];

    let claimGridRows: ClaimGridRow[] = [];

    for (let index = 0; index < data.claimSearchResults.length; index++) {
      const element = data.claimSearchResults[index];

      let row = new ClaimGridRow();
      row.claimId = element.ClaimId;
      row.claimNum = element.ClaimNum;
      row.policyNum = element.PolicyNum;
      row.claimStatus = element.ClaimStatus;
      row.claimHandler = element.ClaimHandler;
      row.claimTitle = element.ClaimTitle || "";
      row.insuredName = element.InsuredName;

      if (!this.dateService.isValidDate(element.LossDate)) {
        throw new Error(this.commonContentService.getErrors().invalidLossDate);
      }
      row.lossDate = this.dateService.momentFromLongISO(element.LossDate);

      claimGridRows.push(row);
    }

    return claimGridRows;
  }
}
