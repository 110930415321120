import {
  Component,
  OnInit,
  ViewChild,
  OnDestroy,
  ChangeDetectorRef,
  Input
} from "@angular/core";
import { ColumnDefinition, GridOptions } from "@tmhcc/framwork/grid";
import { CommonContentService } from "@tmhcc/content-service";
import {
  InputConfig,
  FieldTypes,
  FieldInfo
} from "@tmhcc/framwork/form-inputs/src";
import { Validators, FormGroup, FormBuilder } from "@angular/forms";
import { QuoteStepStoreService } from "../store/facade/quote-step-store.service";
import { QuotePolicyStepStore } from "../store/state/quote-policy-step.state";
import { Subscription } from "rxjs";
import { Router } from "@angular/router";
import { Store } from "@ngxs/store";
import { DialogService, DialogConfig } from "@tmhcc/framwork/dialog";

import {
  PolicyTxnData,
  EcoMaster,
  PolicyTxnRequest,
  UWQuotes,
  QuoteOptions
} from "@tmhcc/models";
import {
  SaveChangeGaurd,
  AlertContainerComponent
} from "@tmhcc/global-services";
import * as _ from "lodash";
import {
  SaveEventRequest,
  EcoMasterData,
  UpdateEventDetails,
  Risk,
  CreateActivityLogItem,
  RiskDataElements,
  ElementUpdateRequest,
  LOBRisks,
  ProducerCommissions,
  UpdateUserConfigurableDataElements
} from "@tmhcc/data-service";
import { LoaderService } from "@tmhcc/framwork/loader/src";
import { CoreInputValidationService } from "@tmhcc/framwork/core-inputs/src";
import { DateService, DateFormats } from "@tmhcc/framwork/services";
import { QuotePolicyTxn } from "../store/state/quote-policy-step.action";
import { SaveEvents } from "../store/state/quote.actions";
import { OktaAuthService } from "@okta/okta-angular";
import { PolicySummaryComponent } from "../policy-summary/policy-summary.component";
import { take } from "rxjs/operators";
import { BindConfig } from "./bind-config";
import {
  ecaConstant,
  APModel,
  RiskModel,
  RiskResponseType
} from "../../shared/eco-constant";
@Component({
  selector: "ec-bind",
  templateUrl: "./bind.component.html",
  styleUrls: ["./bind.component.scss"]
})
export class BindComponent extends BindConfig implements OnInit, OnDestroy {
  @ViewChild(PolicySummaryComponent)
  policychildComponent: PolicySummaryComponent;
  recordsList: any[];
  columnSettings: ColumnDefinition[];
  gridSetting: GridOptions;
  frmGroup: FormGroup;
  quotePolicyStepStore: QuotePolicyStepStore;
  currentUWQuoteID: number;
  getLabels: any;
  getErrors: any;
  selectedPremiumOption: Array<any> = [];
  policyMasterData: EcoMaster;
  showOptionTable: boolean;
  customQuoteIconTexts: any;
  policyTxnStoreData: PolicyTxnData;
  nextPageClicked: boolean;
  userName;
  risksList: any[];
  submitted: boolean;
  childFormValidCheck: boolean;
  isFormValid: boolean = false;
  getcommission: any;
  isPolicyBind: boolean;
  quoteVersionList: any[];
  saveEventServiceInstance: Subscription;
  quoteStepStoreServiceInstance: Subscription;
  QuotePolicyServiceInstance: Subscription;
  quoteStepStoreServiceInstance2: Subscription;
  isQuoteVersion: boolean;
  quotePolicyTxnServiceInstance: Subscription;
  quoteStepStoreServiceInstance3: Subscription;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private quoteStepStoreService: QuoteStepStoreService,
    private commonContentService: CommonContentService,
    public dateService: DateService,
    private loaderService: LoaderService,
    private ecoMasterData: EcoMasterData,
    private validationService: CoreInputValidationService,
    private saveChangeGaurd: SaveChangeGaurd,
    private store: Store,
    private oktaAuth: OktaAuthService,
    private cdr: ChangeDetectorRef,
    private dialogService: DialogService,
    private dialogConfig: DialogConfig
  ) {
    super(commonContentService);
    this.getLabels = this.commonContentService.getLabels();
    this.getErrors = this.commonContentService.getErrors();
  }
  fieldSelectList: FieldInfo = {
    fieldName: "quoteVersion",
    label: "Select Quote",
    fieldType: FieldTypes.LookupList,
    showLabel: false,
    placeholder: "Select Quote",
    validation: {
      required: "Select quote"
    }
  };
  selectConfig: InputConfig = {
    config: {
      selectConfig: {
        selectedOption: [],
        options: [],
        optionConfig: {
          displayKey: "Description",
          search: false,
          limitTo: 3
        },
        multiple: false
      }
    },
    fieldInfo: this.fieldSelectList
  };
  async ngOnInit() {
    this.loaderService.show();
    this.gridSetting = this.gridSettingConfig;
    this.customQuoteIconTexts = {};
    this.initFormGroup();
    this.userName = await this.oktaAuth.getUser();
    this.ecoPolicyData();
    this.frmGroup.controls["quoteVersion"].valueChanges.subscribe(value => {
      if (value) {
        this.selectedPremiumOption = [];
        const UwQuoteId = value.UWQuoteId;
        this.loadQuoteVersionData(
          this.quotePolicyStepStore.policyTxnData.PolicyTxnId,
          UwQuoteId
        );
      }
    });
    //this.validationService.setFormSubmission(true);
    this.saveChangeGaurd.setFormGroup(this.frmGroup);
  }
  ecoPolicyData() {
    this.quoteStepStoreServiceInstance3 = this.quoteStepStoreService.data$
      .pipe(take(1))
      .subscribe((data: QuotePolicyStepStore) => {
        this.quotePolicyStepStore = data;
        if (
          this.quotePolicyStepStore &&
          this.quotePolicyStepStore.policyTxnData
        ) {
          this.policyTxnStoreData = this.quotePolicyStepStore.policyTxnData;
          if (
            this.policyTxnStoreData.ProcessStatusCode == "*" ||
            this.policyTxnStoreData.ProcessStatusCode == "I"
          ) {
            this.frmGroup.disable();
            this.isPolicyBind = true;
            const UWQuotesInstance = data.policyTxnData.UWQuotes;
            UWQuotesInstance.forEach(element => {
              if(element.QuoteStatus==="A"){
                this.currentUWQuoteID=element.UWQuoteId;
                sessionStorage.selectedUwQuoteId=this.currentUWQuoteID;
              }
            });
            this.setQuoteVersionList(UWQuotesInstance);
          }
          else{
            const UWQuotesInstance = data.policyTxnData.UWQuotes;
            if (UWQuotesInstance && UWQuotesInstance.length !== 0) {
              this.updateUWQuoteProperties(
                UWQuotesInstance[UWQuotesInstance.length - 1]
              );
              this.setQuoteVersionList(UWQuotesInstance);
            }
          }
          this.policyMasterData = this.ecoMasterData.getMasterData();
          
          if (this.quotePolicyStepStore.policyTxnData.UWQuotes) {
            this.quoteOptionData(
              this.quotePolicyStepStore.policyTxnData.UWQuotes
            );
          }
        }
      });
  }
  updateUWQuoteProperties(uWQuotesInstance: UWQuotes) {
    const SelectedUwQuoteId = sessionStorage.selectedUwQuoteId;
    if (SelectedUwQuoteId) {
      this.currentUWQuoteID = SelectedUwQuoteId;
    } else {
      this.currentUWQuoteID = uWQuotesInstance.UWQuoteId;
      sessionStorage.selectedUwQuoteId = this.currentUWQuoteID;
    }
  }
  quoteOptionData(uWQuotes: UWQuotes[]) {
    this.loaderService.show();
    this.showOptionTable = false;
    const UWQuoteId = this.currentUWQuoteID;
    const filteredUWQuote: UWQuotes = uWQuotes.find(
      (value: UWQuotes) => value.UWQuoteId == UWQuoteId
    );
    if (
      filteredUWQuote &&
      filteredUWQuote.LOBs &&
      filteredUWQuote.LOBs.length !== 0
    ) {
      const risks = filteredUWQuote.LOBs[0].Risks;
      if (risks && risks.length !== 0) {
        this.recordsList = [];
        this.cdr.detectChanges();
        this.recordsList = this.getQuoteOptionFromPolicy(risks);
        if (this.recordsList.length > 0) {
          this.selectedPremiumOption = [];
          this.setCustomQuoteIcon();
          this.cdr.detectChanges();
          this.showOptionTable = true;
          setTimeout(() => {
            this.loaderService.hide();
          }, 500);
        }
        else{
          this.loaderService.hide();
        }
      }
    }
    const policyType = this.quotePolicyStepStore.policyTxnData
      .PolicyTypeDescription;
    // if (policyType == "Standard Event") {
    //   this.selectedPremiumOption = [];
    //   this.columnSettings = this.standardColumnHeadingSettings;
    //   if (this.recordsList && this.recordsList.length) {

    //     this.recordsList.forEach(data => {
    //       if (
    //         data.checkedOption1 ||
    //         data.checkedOption2 ||
    //         data.checkedOption3
    //       ) {
    //         this.selectedPremiumOption.push(data);
    //       }
    //     });
    //   }
    // } else {
      this.columnSettings = this.tradeShowColumnHeadingSettings;
      this.selectedPremiumOption = [];
      if (this.recordsList && this.recordsList.length) {
        this.recordsList.forEach(data => {
          if (
            data.checkedOption1 ||
            data.checkedOption2 ||
            data.checkedOption3 ||
            data.checkedOption4
          ) {
            this.selectedPremiumOption.push(data);
          }
        });
      }
    //}
  }
  getQuoteOptionFromPolicy(risks: LOBRisks[]): QuoteOptions[] {
    //this.loaderService.show();
    let quoteOptions: QuoteOptions[] = [];
    let i = 0;
    risks.forEach((lOBRisk: LOBRisks) => {
      if (lOBRisk.RiskTypeCode == "FCH") {
        let quoteOptionData = new QuoteOptions();
        let index = quoteOptions.length;
        let custmadditionalPerilLabel = {};
        let custmadditionalPerilvalue = {};
        quoteOptionData.UWRiskId = lOBRisk.UWRiskId;
        quoteOptionData.RiskTypeCode = lOBRisk.RiskTypeCode;
        lOBRisk.RiskDataElements.ElementUpdateRequests.forEach(element => {
          if (
            element.ElementCode === "AP1" ||
            element.ElementCode === "AP2" ||
            element.ElementCode === "AP3"
          ) {
            if (element.RowNum === 1) {
              quoteOptionData.additionalPerilLabel1 = element.FreeTextValue;
              custmadditionalPerilLabel[1] = element.FreeTextValue;
            } else if (element.RowNum === 2) {
              quoteOptionData.additionalPerilLabel2 = element.FreeTextValue;
              custmadditionalPerilLabel[2] = element.FreeTextValue;
            } else if (element.RowNum === 3) {
              quoteOptionData.additionalPerilLabel3 = element.FreeTextValue;
              custmadditionalPerilLabel[3] = element.FreeTextValue;
            }
          } else if (element.ElementCode === "RatingInput") {
            if (element.RowNum === 1) {
              quoteOptionData.additionalPerilValue1 = element.FreeTextValue
                ? (Number(element.FreeTextValue) * 100).toFixed(3).toString()
                : null;
              custmadditionalPerilvalue[1] = element.DecimalValue
                ? element.DecimalValue + ""
                : null;
            } else if (element.RowNum === 2) {
              quoteOptionData.additionalPerilValue2 = element.FreeTextValue
                ? (Number(element.FreeTextValue) * 100).toFixed(3).toString()
                : null;
              custmadditionalPerilvalue[2] = element.DecimalValue
                ? element.DecimalValue + ""
                : null;
            } else if (element.RowNum === 3) {
              quoteOptionData.additionalPerilValue3 = element.FreeTextValue
                ? (Number(element.FreeTextValue) * 100).toFixed(3).toString()
                : null;
              custmadditionalPerilvalue[3] = element.DecimalValue
                ? element.DecimalValue + ""
                : null;
            }
          } else {
            let riskObject = _.pickBy(
              RiskModel,
              riskValue => riskValue.name === element.ElementCode
            );
            let riskKey = _.keys(riskObject)[0];
            let riskValue: any;
            if (riskKey) {
              if (riskObject[riskKey].type === RiskResponseType.DateResponse) {
                riskValue = element.DateValue
                  ? this.dateService.moment(element.DateValue)
                  : null;
              } else if (
                riskObject[riskKey].type === RiskResponseType.DecimalResponse
              ) {
                riskValue = element.DecimalValue
                  ? (element.DecimalValue * 100).toFixed(3)
                  : null;
              } else if (
                riskObject[riskKey].type === RiskResponseType.FreeTextResponse
              ) {
                riskValue = element.FreeTextValue;
              } else if (
                riskObject[riskKey].type === RiskResponseType.MoneyResponse
              ) {
                riskValue = element.MoneyValue;
              }
              quoteOptionData[riskKey] = riskValue;
            }
          }
          if (element.DataGroupName == ecaConstant.dataGroupName) {
            if (
              element.ElementCode == ecaConstant.selected &&
              element.ResponseType == ecaConstant.checkBoxResponse
            ) {
              if (element.RowNum === 1) {
                quoteOptionData.checkedOption1 = element.BooleanValue
                  ? element.BooleanValue
                  : false;
              }
              if (element.RowNum === 2) {
                quoteOptionData.checkedOption2 = element.BooleanValue
                  ? element.BooleanValue
                  : false;
              }
              if (element.RowNum === 3) {
                if (
                  this.policyTxnStoreData.ProcessStatusCode == "*" ||
                  this.policyTxnStoreData.ProcessStatusCode == "I"
                ) {
                  quoteOptionData.readOnly = true;
                }
                quoteOptionData.checkedOption3 = element.BooleanValue
                  ? element.BooleanValue
                  : false;
              }
              if (element.RowNum === 4) {
                // quoteOptionData.tradeShowPolicytype = false;
                // quoteOptionData.standardPolicyType = true;
                quoteOptionData.checkedOption4 = element.BooleanValue
                  ? element.BooleanValue
                  : false;
              }
              if (element.RowNum === 5) {
                // quoteOptionData.tradeShowPolicytype = true;
                // quoteOptionData.standardPolicyType = false;
                quoteOptionData.checkedOption5 = element.BooleanValue
                  ? element.BooleanValue
                  : false;
              }
              quoteOptionData.tradeShowPolicytype = true;
            }
            if (
              element.ElementCode == ecaConstant.money &&
              element.ResponseType == ecaConstant.moneyResponse
            ) {
              if (element.RowNum === 1) {
                quoteOptionData.option1 = element.MoneyValue
                  ? element.MoneyValue
                  : null;
              }
              if (element.RowNum === 2) {
                quoteOptionData.option2 = element.MoneyValue
                  ? element.MoneyValue
                  : null;
              }
              if (element.RowNum === 3) {
                quoteOptionData.option3 = element.MoneyValue
                  ? element.MoneyValue
                  : null;
                quoteOptionData.empty = "";
              }
              if (element.RowNum === 4) {
                quoteOptionData.option4 = element.MoneyValue
                  ? element.MoneyValue
                  : null;
              }
              if (element.RowNum === 5) {
                quoteOptionData.option5 = element.MoneyValue
                  ? element.MoneyValue
                  : null;
              }
            }
          }

          if (
            element.ElementCode === APModel.bioChemTerrIncl &&
            element.DecimalValue
          ) {
            this.addCustomQuoteIconText(index, element.ElementName);
          }
          if (
            element.ElementCode === APModel.communicableDisease &&
            element.DecimalValue
          ) {
            this.addCustomQuoteIconText(index, element.ElementName);
          }
          if (
            element.ElementCode === APModel.limitedWar &&
            element.DecimalValue
          ) {
            this.addCustomQuoteIconText(index, element.ElementName);
          }
          if (
            element.ElementCode === APModel.nonAppearance &&
            element.DecimalValue
          ) {
            this.addCustomQuoteIconText(index, element.ElementName);
          }
          if (
            element.ElementCode === APModel.nuclearHazard &&
            element.DecimalValue
          ) {
            this.addCustomQuoteIconText(index, element.ElementName);
          }
          if (element.ElementCode === APModel.war && element.DecimalValue) {
            this.addCustomQuoteIconText(index, element.ElementName);
          }
        });
        this.addCustomQuoteIconTextAP(
          index,
          custmadditionalPerilLabel,
          custmadditionalPerilvalue
        );
        quoteOptionData.customId = i;
        i++;
        
        quoteOptions.push(quoteOptionData);
      }
    });
    return quoteOptions;
  }

  addCustomQuoteIconTextAP(index: number, labelObj: {}, valueObj: {}) {
    for (var key in labelObj) {
      if (labelObj.hasOwnProperty(key)) {
        if (labelObj[key]) {
          labelObj[key] = labelObj[key].trim();
          if (labelObj[key].slice(-1) === ":") {
            labelObj[key] = labelObj[key].substring(
              0,
              labelObj[key].length - 1
            );
            labelObj[key] = labelObj[key].trim();
          }
        }
      }
    }
    if (valueObj && valueObj[1] && labelObj[1]) {
      this.addCustomQuoteIconText(index, labelObj[1]);
    }
    if (valueObj && valueObj[2] && labelObj[2]) {
      this.addCustomQuoteIconText(index, labelObj[2]);
    }
    if (valueObj && valueObj[3] && labelObj[3]) {
      this.addCustomQuoteIconText(index, labelObj[3]);
    }
  }

  setCustomQuoteIcon() {
    for (var key in this.customQuoteIconTexts) {
      if (
        this.customQuoteIconTexts.hasOwnProperty(key) &&
        this.recordsList[Number(key)]
      ) {
        this.recordsList[Number(key)].infoIconText = this.customQuoteIconTexts[
          key
        ];
      }
    }
  }
  addCustomQuoteIconText(index: number, elementName: string) {
    if (this.customQuoteIconTexts && this.customQuoteIconTexts[index]) {
      this.customQuoteIconTexts[index] =
        this.customQuoteIconTexts[index] + ", " + elementName;
    } else {
      this.customQuoteIconTexts[index] = "Include " + elementName;
    }
  }

  setQuoteVersionList(UWQuoteList) {
    if (UWQuoteList) {
      this.quoteVersionList = [];
      UWQuoteList.forEach(items => {
        this.quoteVersionList.push({
          UWQuoteId: items.UWQuoteId,
          Description: items.QuoteDescription
        });
      });
      this.onloadSetQuoteVersion();
      this.selectConfig.config.selectConfig.options = this.quoteVersionList;
      this.frmGroup.markAsPristine();
    }
  }
  onloadSetQuoteVersion() {
    const SelectedUwQuoteId = sessionStorage.selectedUwQuoteId;
    if (SelectedUwQuoteId) {
      this.quoteVersionList.forEach(element => {
        if (element.UWQuoteId == SelectedUwQuoteId) {
          this.frmGroup.get("quoteVersion").setValue({
            UWQuoteId: element.UWQuoteId,
            Description: element.Description
          });
        }
      });
    } else {
      const lastUWQuote = this.quoteVersionList[
        this.quoteVersionList.length - 1
      ];
      this.frmGroup.get("quoteVersion").setValue({
        UWQuoteId: lastUWQuote.UWQuoteId,
        Description: lastUWQuote.Description
      });
    }
    if (this.quoteVersionList.length > 1) {
      this.isQuoteVersion = true;
    }
  }
  ngAfterViewInit() {
    this.frmGroup.addControl(
      "childForm",
      this.policychildComponent.policySummary
    );
    this.policychildComponent.policySummary.setParent(this.frmGroup);
  }
  backButtonPressed() {
    this.router.navigate(["/quote"]);
  }

  // validateSelectedOption() {
  //   const validOptionsSelected = [];
  //   const policyType = this.quotePolicyStepStore.policyTxnData
  //     .PolicyTypeDescription;
  //   if (this.recordsList && this.recordsList.length) {
  //     this.recordsList.forEach(data => {
  //       if (policyType == "Standard Event") {
  //         if (
  //           data.checkedOption1 ||
  //           data.checkedOption2 ||
  //           data.checkedOption3
  //         ) {
  //           validOptionsSelected.push(data);
  //         }
  //       } else {
  //         if (
  //           data.checkedOption1 ||
  //           data.checkedOption2 ||
  //           data.checkedOption3 ||
  //           data.checkedOption4
  //         ) {
  //           validOptionsSelected.push(data);
  //         }
  //       }
  //     });
  //   }
  //   return validOptionsSelected;
  // }

  saveAndRefresh() {
    this.submitted = true;
    this.policychildComponent.policySummaryValidate(this.submitted);
    if (!this.policychildComponent.policySummary.valid) {
      this.validationService.setFormSubmission(true);
    } else {
      const policyType = this.quotePolicyStepStore.policyTxnData.PolicyTypeDescription;
      let availableOptions = 0
      // if (policyType === 'Standard Event') {
      //    availableOptions = 3;
      // } else {
      //    availableOptions = 4;
      // }
      availableOptions = 4;
      this.validateRecordList(availableOptions,false);
    }
  }
  validateRecordList(options,routToNext) {
    let isRowCorrect = 0
    for (let i = 0; i < this.recordsList.length; i++) {
      const ObjectArr = []
      const QuoteItem = this.recordsList[i];
      if (QuoteItem) {
         Object.keys(QuoteItem).forEach((key, index) => {
           for (let opt = 1; opt <= options; opt++) {
              if (key === `checkedOption${opt}`) {
                 const keyVal = Object.values(QuoteItem)[index]
                 if (!keyVal) {
                    ObjectArr.push(key)
                 }
              }
            }
        })
      }
   
      if (ObjectArr.length === options) {
        this.premiumSelectedValidation()
        return false
      } else {
        isRowCorrect++
      }
   }
    if (isRowCorrect == this.recordsList.length) {
      this.saveAndNext(routToNext);
    }
   }
  filterRiskRequest(): Risk[] {
    let recordsList = 0;
    let quoteRisk: Risk[] = [];
    this.quoteStepStoreServiceInstance = this.quoteStepStoreService.data$.subscribe(
      (data: QuotePolicyStepStore) => {
        let quotePolicyStepStore = JSON.parse(JSON.stringify(data));
        if (quotePolicyStepStore && quotePolicyStepStore.policyTxnData) {
          let UWQuotes = quotePolicyStepStore.policyTxnData.UWQuotes;
          UWQuotes.forEach(element1 => {
            if (element1.UWQuoteId == this.currentUWQuoteID) {
              const filteredUWQuote = UWQuotes.find(
                (value: UWQuotes) => value.UWQuoteId == this.currentUWQuoteID
              );

              if (filteredUWQuote.LOBs && filteredUWQuote.LOBs.length !== 0) {
                this.risksList = filteredUWQuote.LOBs[0].Risks;
                if (this.risksList && this.risksList.length !== 0) {
                  if (this.selectedPremiumOption.length) {
                    this.risksList.forEach(items => {
                      if (items.RiskTypeCode == "FCH") {
                        this.selectedPremiumOption.forEach(element => {
                          if (items.UWRiskId == element["UWRiskId"]) {
                            items.RiskDataElements.ElementUpdateRequests.forEach(
                              values => {
                                if (
                                  values.DataGroupName == "ECO Quote Options" &&
                                  values.ResponseType == "CheckBoxResponse" &&
                                  values.ElementCode == "Selected"
                                ) {
                                  if (values.RowNum == 1) {
                                    values.BooleanValue =
                                      element["checkedOption1"];
                                  }
                                  if (values.RowNum == 2) {
                                    values.BooleanValue =
                                      element["checkedOption2"];
                                  }
                                  if (values.RowNum == 3) {
                                    values.BooleanValue =
                                      element["checkedOption3"];
                                  }
                                  if (values.RowNum == 4) {
                                    values.BooleanValue =
                                      element["checkedOption4"];
                                  }
                                  if (values.RowNum == 5) {
                                    values.BooleanValue =
                                      element["checkedOption5"];
                                  }
                                }
                              }
                            );
                          }
                        });
                        let risk: Risk = {
                          UWRiskId: items.UWRiskId,
                          RiskTypeCode: items.RiskTypeCode, // As per service team
                          StateId: this.policyTxnStoreData.StateId,
                          RiskDataElements: items.RiskDataElements,
                          CurrencyCode: this.policyTxnStoreData.CurrencyCode,
                          RiskValueCurrencyCode: this.policyTxnStoreData
                            .CurrencyCode,
                          RiskDescription: "", // Need discussion
                          Coverages: items.Coverages
                        };
                        quoteRisk.push(risk);
                      }
                    });
                  }
                }
              }
            }
          });
        }
      }
    );

    return quoteRisk;
  }
  premiumSelectedValidation() {
    this.openpremiumSelectedValidationnDialog(
      this.getErrors.invalidPemiumCheck
    );
  }
  openpremiumSelectedValidationnDialog(text) {
    const content = {
      value: text
    };
    this.dialogConfig.data = content;
    this.dialogConfig.modalSetting = this.dialogModalSetting;
    const ref = this.dialogService.open(
      AlertContainerComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {
      if (result) {
        return false;
      }
    });
    return false;
  }

  getProducerCommissionsData(): ProducerCommissions[] {
    this.getcommission = this.policychildComponent.returnproducerCommissions;
    return this.getcommission;
  }
  getEndorsementDatafields(): UpdateUserConfigurableDataElements {
    let updateUserConfigurableDataElements: UpdateUserConfigurableDataElements = {};
    updateUserConfigurableDataElements.ElementUpdateRequests = this.policychildComponent.sendElementUpdateRequestData;
    return updateUserConfigurableDataElements;
  }

  setUpdateQuoteOptionRequest(): UpdateEventDetails {
    const getEndorsementDatafieldsData = this.getEndorsementDatafields();
    let updateEventDetails: UpdateEventDetails;
    let policyEffDate = this.policychildComponent.policySummary.get("policyEff")
      .value;
    let policyExpDate = this.policychildComponent.policySummary.get("policyExp")
      .value;
    if (typeof policyEffDate === "string") {
      policyEffDate = new Date(policyEffDate);
    }
    if (typeof policyExpDate === "string") {
      policyExpDate = new Date(policyExpDate);
    }
    if (policyEffDate) {
      policyEffDate = this.dateService.toShortDateWithFormat(
        this.dateService.moment(policyEffDate.toString()),
        DateFormats.ISO
      );
      // policyEffDate = this.dateService.toShortDateWithFormat(this.dateService.momentFromLongISO(policyEffDate.toISOString()), DateFormats.ISO);
    }
    if (policyExpDate) {
      policyExpDate = this.dateService.toShortDateWithFormat(
        this.dateService.moment(policyExpDate.toString()),
        DateFormats.ISO
      );
      // policyExpDate = this.dateService.toShortDateWithFormat(this.dateService.momentFromLongISO(policyExpDate.toISOString()), DateFormats.ISO);
    }
    updateEventDetails = {
      PolicyTxnId: this.policyTxnStoreData.PolicyTxnId
        ? Number(this.policyTxnStoreData.PolicyTxnId)
        : null,
      PolicyEffectiveDate: policyEffDate,
      PolicyExpirationDate: policyExpDate,
      UWQuoteId: this.currentUWQuoteID
        ? this.currentUWQuoteID
        : Number(this.policyTxnStoreData.UWQuotes[0].UWQuoteId),
      WritingCompanyId: this.policychildComponent.carrierWritingCompanyId,
      QuoteDescription: "UW Quote Desc", // As per service team, pass any string
      // ContractMasterId: 123,
      // TransactionType: "NB",   // this current API will handle only new business so always NB
      Risks: this.filterRiskRequest(),
      ProducerCommissions: this.getProducerCommissionsData(),
      CreateActivityLogItem: this.getCreateActivityLogItemRequest()
    };
    if (getEndorsementDatafieldsData != null) {
      updateEventDetails.UpdateUserConfigurableDataElement = getEndorsementDatafieldsData;
    }
    return updateEventDetails;
  }
  getCreateActivityLogItemRequest(): CreateActivityLogItem {
    let createActivityLogItem: CreateActivityLogItem;
    if (this.policyTxnStoreData.CreateActivityLogItem) {
      createActivityLogItem = {
        PolicyTxnId: Number(this.policyTxnStoreData.PolicyTxnId),
        ActivityType:
          ecaConstant.step4 ||
          this.policyTxnStoreData.CreateActivityLogItem.ActivityType,
        ActivityDate: this.dateService.now().toISOString(),
        Description:
          "saved step 4" ||
          this.policyTxnStoreData.CreateActivityLogItem.Description,
        LoggedInUser: this.userName
      };
    }
    return createActivityLogItem;
  }
  prepareSaveRequest(): SaveEventRequest {
    let updateEventDetails = this.setUpdateQuoteOptionRequest();
    let saveEventRequest: SaveEventRequest;
    saveEventRequest = {
      PolicyTxnId: this.policyTxnStoreData.PolicyTxnId
        ? Number(this.policyTxnStoreData.PolicyTxnId)
        : null,
      CalculateFlag: true,
      UpdateEventDetails: updateEventDetails
    };
    return saveEventRequest;
  }

  public isValidPolicyChildForm(formValid: any) {
    this.childFormValidCheck = formValid;
  }
  saveNext() {
    /**
     * Check Form Valid or Not
     */
    if (this.isPolicyBind == true) {
      this.router.navigate(["/bind-summary"]);
    } else {
      this.submitted = true;
      this.policychildComponent.policySummaryValidate(this.submitted);
      if (!this.policychildComponent.policySummary.valid) {
        this.validationService.setFormSubmission(true);
      } else {
        const policyType = this.quotePolicyStepStore.policyTxnData.PolicyTypeDescription;
        let availableOptions = 0
        // if (policyType === 'Standard Event') {
        //    availableOptions = 3;
        // } else {
        //    availableOptions = 4;
        // }
        availableOptions = 4;
        this.validateRecordList(availableOptions,true);
      }
    }
  }
  saveAndNext(routeToNext?: boolean) {
    const saveRequest = this.prepareSaveRequest();
    if (routeToNext) {
      this.nextPageClicked = true;
    }
    this.loaderService.show();
    this.saveEventServiceInstance = this.store
      .dispatch(new SaveEvents(saveRequest))
      .subscribe(data => {
        if (
          routeToNext &&
          data.Quote &&
          data.Quote.saveStatus &&
          data.Quote.saveStatus.isSuccess
        ) {
          if (data.PolicyTxnData.policyTxnData.PolicyTxnId) {
            const getPolicyTxnRequest: PolicyTxnRequest = {
              PolicyTxnID: data.PolicyTxnData.policyTxnData.PolicyTxnId.toString()
            };
            this.QuotePolicyServiceInstance = this.store
              .dispatch(new QuotePolicyTxn(getPolicyTxnRequest))
              .subscribe(
                () => {
                  this.loaderService.hide();
                  this.frmGroup.markAsPristine();
                  this.router.navigate(["/bind-summary"]);
                },
                () => {
                  this.loaderService.hide();
                }
              );
          }
        }
        else if (
          !routeToNext &&
          data.Quote &&
          data.Quote.saveStatus &&
          data.Quote.saveStatus.isSuccess
        ) {
          //Remove Error
          this.loaderService.hide();
          this.frmGroup.markAsPristine();
          if (data.PolicyTxnData.policyTxnData.PolicyTxnId) {
            const getPolicyTxnRequest: PolicyTxnRequest = {
              PolicyTxnID: this.policyTxnStoreData.PolicyTxnId.toString()
            };
            this.quotePolicyTxnServiceInstance = this.store
              .dispatch(new QuotePolicyTxn(getPolicyTxnRequest))
              .subscribe(() => {
                this.loadQuoteVersionData(
                  data.PolicyTxnData.policyTxnData.PolicyTxnId,
                  this.currentUWQuoteID
                );
              });
            this.quoteStepStoreService.setPolcyTxnData(getPolicyTxnRequest);
          }
        }
        else{
          this.loaderService.hide();
        }
        this.nextPageClicked = false;
      });
  }
  initFormGroup() {
    const controls = {};
    controls[this.fieldSelectList.fieldName] = ["", Validators.required];

    this.frmGroup = this.fb.group(controls);
  }
  slectedQuoteOption($event) {
    //
    this.selectedPremiumOption=$event;
    // if($event){
    //   if(this.selectedPremiumOption.length==0){
    //     this.selectedPremiumOption=$event;
    //   }
    //   else{
    //     $event.forEach(event => {
    //       let index = this.getIndexFromArray(this.selectedPremiumOption, event);
    //       if(this.selectedPremiumOption){
    //         this.selectedPremiumOption.splice(index,1);
    //       }
    //       this.selectedPremiumOption.push(event);
    //     })
    //   }
    //   console.log( $event);
      
    //   console.log(this.selectedPremiumOption);
    // }
    
    this.frmGroup.markAsDirty();
  }

    

getIndexFromArray(arrayToCompare, objectToCompare) {
// tslint:disable-next-line: prefer-for-of
  for (let i=0; i<arrayToCompare.length; i++) {
    if (this.compareObjects(arrayToCompare[i], objectToCompare)) {
    return i;
    }
  }
}
 
compareObjects(o1, o2) {
  return Object.keys(o1).length===Object.keys(o2).length&&
  Object.keys(o1).every(p=>o1[p] ===o2[p])
  ?true
  :false;
 }

  loadQuoteVersionData(policyTxnId, UwQuoteId) {
    if (policyTxnId) {
      this.currentUWQuoteID = UwQuoteId;
      sessionStorage.selectedUwQuoteId = UwQuoteId;
      this.quoteStepStoreServiceInstance2 = this.quoteStepStoreService.data$.subscribe(
        (data: QuotePolicyStepStore) => {
          this.quotePolicyStepStore = data;
          const UWQuotes = this.quotePolicyStepStore.policyTxnData.UWQuotes.filter(
            items => items.UWQuoteId == this.currentUWQuoteID
          );
          this.quoteOptionData(UWQuotes);
        }
      );
    }
  }

  ngOnDestroy(): void {
    if (this.saveEventServiceInstance) {
      this.saveEventServiceInstance.unsubscribe();
    }
    if (this.quoteStepStoreServiceInstance) {
      this.quoteStepStoreServiceInstance.unsubscribe();
    }
    if (this.QuotePolicyServiceInstance) {
      this.QuotePolicyServiceInstance.unsubscribe();
    }
    if (this.quoteStepStoreServiceInstance2) {
      this.quoteStepStoreServiceInstance2.unsubscribe();
    }
    if (this.quotePolicyTxnServiceInstance) {
      this.quotePolicyTxnServiceInstance.unsubscribe();
    }
    if (this.quoteStepStoreServiceInstance3) {
      this.quoteStepStoreServiceInstance3.unsubscribe();
    }
    this.validationService.setFormSubmission(false);
  }
}
