import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CommonContentService } from "@tmhcc/content-service";
import { InputConfig } from "@tmhcc/framwork/form-inputs";
import { Subscription } from "rxjs";
import { CoreInputValidationService } from "@tmhcc/framwork/core-inputs";
import { ecaConstant } from "../../shared/eco-constant";
import { OktaAuthService } from "@okta/okta-angular";
import {
  EcoMasterData,
  EcoMasterRequest,
  SmartyStreetSdkService,
  QuoteGeneralRequest,
  ContactRequest,
  EcoAgencyAgent
} from "@tmhcc/data-service";
import { QuoteGeneralDefinitionService } from "./quote-general-field-config";
import { QuoteCreateStoreService } from "../store/facade/quote-create-store.service";
import { DateService } from "@tmhcc/framwork/services";
import { QuoteStepStoreService } from "../store/facade/quote-step-store.service";
import { LoaderService } from "@tmhcc/framwork/loader/src";
import {
  CreateNewQuoteModel,
  InsuredContact,
  NewBusinessSub,
  Address,
  CreateActivityLogItem,
  PolicyTxnRequest
} from "@tmhcc/models";
import { AllowedRoute } from "../../shared/page-header/page-header-navigation/page-header-navigation-def";
import { QuotePolicyStepStore } from "../store/state/quote-policy-step.state";
import { take } from "rxjs/operators";
import { QuotePolicyTxn } from "../store/state/quote-policy-step.action";
import { Store } from "@ngxs/store";
import { CreateNewQuote } from "../store/state/quote-create.actions";
import { PageHeaderService } from "../../shared/page-header/page-header.service";

@Component({
  selector: "ec-general-info",
  templateUrl: "./general-info.component.html",
  styleUrls: ["./general-info.component.scss"]
})
export class GeneralInfoComponent extends QuoteGeneralDefinitionService
  implements OnInit, OnDestroy {
  valueSub = new Subscription();
  frmGroup: FormGroup;
  stateList = [];
  countryValue = [];
  masterDataObj: any;
  isFormValid: boolean = false;
  createQuoteFormControl: Object = {};
  quoteCreateRequest: CreateNewQuoteModel = {} as any;
  getLabels: any;
  addressOptions = <any>[];
  address: any;
  addressData: any;
  list: string[];
  PolicyTypeId: number;
  quotePolicyStepStore: QuotePolicyStepStore;
  listHidden = false;
  selectedIndex = -1;
  streetAddrss: string;
  insuredAddress: number;
  sub: Subscription;
  page: number;
  productTypeId: any;
  InsuredContactId: any;
  PolicyId: number;
  PolicyTxnId: number;
  PolicyTxnNum: number;
  PolicyTermNum: number;
  PolicyDescription: string;
  User1Id: number;
  User2Id: number;
  SubmissionTypesId: number;
  NextRenewalOptionId: number;
  TxnPayPlanId: number;
  TermTypeId: number;
  SubmissionTypeId: any;
  PolicyYear: any;
  PolicyProducerContactId: any;
  countrycode: any;
  storesubscribe: Subscription;
  isPolicyBind: boolean;
  userName;
  constructor(
    private formBuilder: FormBuilder,
    private masterData: EcoMasterData,
    public commonContentService: CommonContentService,
    private validationService: CoreInputValidationService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private _smartyStreetSdkService: SmartyStreetSdkService,
    private quoteCreateStoreService: QuoteCreateStoreService,
    private dateService: DateService,
    private ecoAgencyAgent: EcoAgencyAgent,
    private quoteStepStoreService: QuoteStepStoreService,
    private oktaAuth: OktaAuthService,
    private store: Store,
    private pageHeaderService: PageHeaderService
  ) {
    super(commonContentService);
    this.getLabels = this.commonContentService.getLabels();
  }
  quoteInfoField: InputConfig[];
  insuredInfoField: InputConfig[];
  addressInfoField: InputConfig[];
  brokerInfoFieild: InputConfig[];
  underwriterInfoField: InputConfig[];
  insuredAddressInfoField: InputConfig[];
  // payload for EcoMaster
  payload: EcoMasterRequest = {
    SubCompanyCode: "ECO"
  };
  routeState = {
    backwardRoute: AllowedRoute.Dashboard,
    forwardRoute: "",
    defaultRoute: AllowedRoute.GeneralInfo
  };

  async ngOnInit() {
    this.loaderService.show();
    this.userName = await this.oktaAuth.getUser();
    this.sub = this.route.queryParams.subscribe(params => {
      this.page = +params["q"] || 0;
    });
    this.setEntity();
    this.getMasterData();
    this.getAgencyDetails();
    if (this.page && this.page == 1) {
      this.setFormControl(
        this.quoteInfoField,
        this.insuredInfoField,
        this.addressInfoField,
        this.insuredAddressInfoField,
        this.brokerInfoFieild,
        this.underwriterInfoField,
        this.quotePolicyStepStore
      );
      this.pageHeaderService.setBindBadageCounter(0);
      this.loaderService.hide();
    } else {
      this.storesubscribe = this.quoteStepStoreService.data$
        .pipe(take(1))
        .subscribe(
          (data: QuotePolicyStepStore) => {
            this.quotePolicyStepStore = data;
            if (
              this.quotePolicyStepStore &&
              this.quotePolicyStepStore.policyTxnData
            ) {
              this.setFormControl(
                this.quoteInfoField,
                this.insuredInfoField,
                this.addressInfoField,
                this.insuredAddressInfoField,
                this.brokerInfoFieild,
                this.underwriterInfoField,
                this.quotePolicyStepStore
              );
              this.setEditCreateQuoteData(this.quotePolicyStepStore);
              if (
                this.quotePolicyStepStore.policyTxnData.ProcessStatusCode ==
                  "*" ||
                this.quotePolicyStepStore.policyTxnData.ProcessStatusCode == "I"
              ) {
                this.frmGroup.disable();
                this.isPolicyBind = true;
              } else {
                this.pageHeaderService.setBindBadageCounter(0);
              }
            }
            this.loaderService.hide();
          },
          () => {
            this.loaderService.hide();
          }
        );
    }
    // check for form validity
    if(this.frmGroup){
      this.frmGroup.valueChanges.subscribe(value => {
        if (!this.frmGroup.invalid) {
          this.isFormValid = true;
        } else {
          this.isFormValid = false;
        }
      });
      this.frmGroup.controls[
        QuoteGeneralRequest.BrokerName
      ].valueChanges.subscribe(value => {
        if (value) {
          this.frmGroup
            .get(QuoteGeneralRequest.BrokerNumber)
            .setValue(value.ISeriesBrokerNum);
          this.PolicyProducerContactId = value.ContactId;
        } else {
          this.frmGroup.get(QuoteGeneralRequest.BrokerNumber).setValue("");
          this.PolicyProducerContactId = null;
        }
      });
      this.frmGroup.controls[
        QuoteGeneralRequest.PolicyTypes
      ].valueChanges.subscribe(value => {
        if (value && value.Id) {
          this.loaderService.show();
          this.masterData.fetchMasterDataAsyncWithPolicyType(value.Id).subscribe(
            () => {
              this.loaderService.hide();
            },
            () => {
              this.loaderService.hide();
            }
          );
  
          if (value) {
            this.productTypeId = value.Id;
            this.masterDataObj.TermTypes.forEach(items => {
              if (items.PolicyTypeId == value.Id) {
                this.TermTypeId = items.Id;
              }
            });
            this.masterDataObj.RenewalOptions.forEach(items => {
              if (items.PolicyTypeId == value.Id) {
                this.NextRenewalOptionId = items.Id;
              }
            });
            this.masterDataObj.SubmissionTypes.forEach(items => {
              if (items.PolicyTypeId == value.Id) {
                this.SubmissionTypeId = items.Id;
              }
            });
            this.masterDataObj.PaymentPlans.forEach(items => {
              if (items.PolicyTypeId == value.Id) {
                this.TxnPayPlanId = items.Id;
              }
            });
          }
        }
      });
      this.frmGroup.get(QuoteGeneralRequest.QuoteNumber).disable();
      this.frmGroup.get(QuoteGeneralRequest.BrokerNumber).disable();
    }  
  }
  setFormControl(
    quoteFields,
    insuredFields,
    addressField,
    insuredAddressInfoField,
    brokerFields,
    underwriterFields,
    formData
  ) {
    quoteFields.forEach(element => {
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      if (element.fieldInfo.fieldName == QuoteGeneralRequest.QuoteNumber) {
        this.createQuoteFormControl[element.fieldInfo.fieldName] = [
          formData != null && formData != undefined
            ? formData.policyTxnData.PolicyNum
            : "",
          Validators.compose(validators)
        ];
      }
      if (element.fieldInfo.fieldName == QuoteGeneralRequest.PolicyTypes) {
        this.createQuoteFormControl[element.fieldInfo.fieldName] = [
          formData != null && formData != undefined
            ? formData.policyTxnData.PolicyTypeDescription
            : "",
          Validators.compose(validators)
        ];
      }
    });
    addressField.forEach(element => {
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      this.createQuoteFormControl[element.fieldInfo.fieldName] = [
        "",
        Validators.compose(validators)
      ];
    });

    insuredFields.forEach(element => {
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      this.createQuoteFormControl[element.fieldInfo.fieldName] = [
        "",
        Validators.compose(validators)
      ];
    });
    insuredAddressInfoField.forEach(element => {
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      this.createQuoteFormControl[element.fieldInfo.fieldName] = [
        "",
        Validators.compose(validators)
      ];
    });
    brokerFields.forEach(element => {
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }

      this.createQuoteFormControl[element.fieldInfo.fieldName] = [
        "",
        Validators.compose(validators)
      ];
    });
    underwriterFields.forEach(element => {
      let validators;
      if (element.config && element.config.validation) {
        validators = element.config.validation;
      } else {
        validators = null;
      }
      this.createQuoteFormControl[element.fieldInfo.fieldName] = [
        "",
        Validators.compose(validators)
      ];
    });

    this.frmGroup = this.formBuilder.group(this.createQuoteFormControl);
    if (this.frmGroup.get(QuoteGeneralRequest.QuoteNumber).value) {
      this.frmGroup.get(QuoteGeneralRequest.PolicyTypes).disable();
    }
  }

  getMasterData() {
    this.masterDataObj = this.masterData.getMasterData();
    this.setMasterDataValues(this.masterDataObj);
  }
  agencyData: any;
  getAgencyDetails() {
    this.ecoAgencyAgent.EcoAgencyAgentData(this.payload).subscribe(data => {
      if (data) {
        this.agencyData = data;
        this.agencyData = this.agencyData.sort((a: any, b: any) =>
          a.FullName.localeCompare(b.FullName)
        );
        this.brokerInfoFieild.forEach(field => {
          if (field.fieldInfo.fieldName == QuoteGeneralRequest.BrokerName) {
            this.agencyData.forEach(items => {
              field.config.selectConfig.options = this.agencyData;
            });
          }
        });
      }
    });
  }
  onControlValueChange(feildName: string, val: string) {
    this.frmGroup.get(feildName).setValue(val);
  }
  setEditCreateQuoteData(policyData) {
    this.InsuredContactId = policyData.policyTxnData.InsuredContactId;
    this.PolicyProducerContactId =
      policyData.policyTxnData.PolicyProducerContactId;
    this.productTypeId = policyData.policyTxnData.PolicyTypeId;
    this.PolicyId = policyData.policyTxnData.PolicyId;
    this.PolicyTxnId = policyData.policyTxnData.PolicyTxnId;
    this.PolicyTxnNum = policyData.policyTxnData.PolicyTxnNum;
    this.PolicyTermNum = policyData.policyTxnData.PolicyTermNum;
    this.SubmissionTypeId = policyData.policyTxnData.SubmissionTypeId;
    this.PolicyYear = policyData.policyTxnData.PolicyYear;
    this.NextRenewalOptionId = policyData.policyTxnData.NextRenewalOptionId;
    this.PolicyDescription = policyData.policyTxnData.PolicyTypeDescription;
    this.TxnPayPlanId = policyData.policyTxnData.TxnPayPlanId;
    this.TermTypeId = policyData.policyTxnData.TermTypeId;
    this.User1Id = policyData.policyTxnData.User2Id;
    this.User2Id = policyData.policyTxnData.User1Id;
    this.countrycode = policyData.policyTxnData.CountryCode;

    this.getInsuredContactDetail(this.InsuredContactId, this.countrycode);
    this.getBrokerInformation(policyData.policyTxnData.PolicyProducerContactId);
    this.getUnderWriterDetails(this.User1Id);
    this.getUnderWriterAssitentDetails(this.User2Id);
  }
  getInsuredContactDetail(InsuredContactId, countrycode) {
    this.loaderService.show();
    const contactId: ContactRequest = {
      ContactId: InsuredContactId
    };
    this.ecoAgencyAgent.ecogetcontactDetails(contactId).subscribe(data => {
      if (data) {
        this.frmGroup
          .get(QuoteGeneralRequest.InsuredName)
          .setValue(data.OrgFullName);
        data.Addresses.forEach(items => {
          this.insuredAddress = items.AddressId;
          this.frmGroup
            .get(QuoteGeneralRequest.Address)
            .setValue(items.StreetAddress);
          this.frmGroup.get(QuoteGeneralRequest.City).setValue(items.City);
          this.frmGroup
            .get(QuoteGeneralRequest.ZipCode)
            .setValue(items.ZipCode);
          const {
            StateOrProvinces,
            Countries
          } = this.masterData.getMasterData();
          StateOrProvinces.forEach(element => {
            if (element.Code == items.StateCode && element.CountryCode=='USA') {
              this.frmGroup.get(QuoteGeneralRequest.StateOrProvinces).setValue({
                Description: element.Description,
                StateCode: element.Code,
                Id: element.Id
              });
            }
          });
          if (Countries) {
            Countries.forEach(element => {
              if (element.Id == "USA") {
                this.frmGroup.get(QuoteGeneralRequest.Countries).setValue({
                  CountryCode: element.Id,
                  Description: element.Description
                });
              }
            });
          }
        });
        this.loaderService.hide();
      }
    });
  }
  getBrokerInformation(PolicyProducerContactId) {
    const producerContactId = PolicyProducerContactId;
    const policyProdContactId: ContactRequest = {
      ContactId: producerContactId
    };
    let brokerNumber;
    this.loaderService.show();
    this.ecoAgencyAgent
      .ecogetcontactDetails(policyProdContactId)
      .subscribe(data => {
        if (data) {
          const configurableElement = data.UpdateUserConfigurableDataElement;
          configurableElement.ElementUpdateRequests.forEach(items => {
            if (items.ElementName == "iSeries Broker Num") {
              brokerNumber = items.FreeTextValue;
            }
          });
          this.frmGroup.get(QuoteGeneralRequest.BrokerName).setValue({
            FullName: data.OrgFullName,
            ISeriesBrokerNum: brokerNumber,
            ContactId: this.PolicyProducerContactId
          });
          this.loaderService.hide();
          this.frmGroup.markAsPristine();
        }
      });
  }
  getUnderWriterDetails(User1Id) {
    const { Underwriters } = this.masterData.getMasterData();
    if (Underwriters) {
      Underwriters.forEach(element => {
        if (Number(element.Id) == User1Id) {
          const id = element.Id;
          const description = element.Description;
          this.frmGroup
            .get(QuoteGeneralRequest.Underwriters)
            .setValue({ Id: id, Description: description });
        }
      });
    }
  }
  getUnderWriterAssitentDetails(User2Id) {
    const { UnderwriterAssistants } = this.masterData.getMasterData();
    if (UnderwriterAssistants) {
      UnderwriterAssistants.forEach(element => {
        if (Number(element.Id) == User2Id) {
          const id = element.Id;
          const description = element.Description;
          this.frmGroup
            .get(QuoteGeneralRequest.UnderwriterAssistants)
            .setValue({ Id: id, Description: description });
        }
      });
    }
  }
  setMasterDataValues(masterDataObj) {
    this.insuredAddressInfoField.forEach(field => {
      if (field.fieldInfo.fieldName == QuoteGeneralRequest.Countries) {
        if(masterDataObj && masterDataObj.Countries){
          masterDataObj.Countries.forEach(items => {
            if (items.Id == "USA") {
              this.countryValue.push({
                Description: items.Description,
                CountryCode: items.Id
              });
              field.config.selectConfig.options = this.countryValue;
            }
          });
        }
      }
      if (field.fieldInfo.fieldName == QuoteGeneralRequest.StateOrProvinces) {
        if(masterDataObj && masterDataObj.StateOrProvinces){
          masterDataObj.StateOrProvinces.forEach(items => {
            if (items.CountryCode == "USA") {
              this.stateList.push({
                Description: items.Description,
                StateCode: items.Code,
                Id: items.Id
              });
              field.config.selectConfig.options = this.stateList;
            }
          });
        }
        
      }
    });
    if(masterDataObj){
      this.quoteInfoField.forEach(field => {
        if (field.fieldInfo.fieldName == QuoteGeneralRequest.PolicyTypes) {
          field.config.selectConfig.options = masterDataObj.PolicyTypes;
        }
      });
      this.underwriterInfoField.forEach(field => {
        if (field.fieldInfo.fieldName == QuoteGeneralRequest.Underwriters) {
          field.config.selectConfig.options = masterDataObj.Underwriters;
        }
        if (
          field.fieldInfo.fieldName == QuoteGeneralRequest.UnderwriterAssistants
        ) {
          field.config.selectConfig.options = masterDataObj.UnderwriterAssistants;
        }
      });
    }
   
    this.loaderService.hide();
  }
  setEntity() {
    this.quoteInfoField = this.quoteInformationConfig.quoteFieldConfig;
    this.insuredInfoField = this.insuredSectionFields.insuredConfigField;
    this.addressInfoField = this.addressFields.addressConfigField;
    this.insuredAddressInfoField = this.insuredSectionAddressFields.insuredAddressConfigField;
    this.brokerInfoFieild = this.brokerSectionConfig.brokerConfigField;
    this.underwriterInfoField = this.underwriterSectionConfig.underwriterConfigField;
  }

  saveNextEvent(event) {
    if (this.isPolicyBind) {
      this.router.navigate(["/events"]);
    } else if (!this.isFormValid) {
      this.validationService.setFormSubmission(true);
    } else if (this.isFormValid && !this.frmGroup.dirty) {
      this.router.navigate(["/events"]);
    } else {
      this.loaderService.show();
      const quoteCreateRequest = this.createQuoteBusinessObj();
      this.store
        .dispatch(new CreateNewQuote(quoteCreateRequest))
        .pipe(take(1))
        .subscribe(() => {
          this.quoteCreateStoreService.data$.pipe(take(1)).subscribe(data => {
            if (data.quoteInfoData) {
              const quoteData = data.quoteInfoData;
              const data1 = quoteData["NewBusinessResponse"];
              if (data1) {
                const Ids = data1.PolicyTxnId;
                const policyTxnRequest: PolicyTxnRequest = {
                  PolicyTxnID: Ids
                };
                this.store
                  .dispatch(new QuotePolicyTxn(policyTxnRequest))
                  .pipe(take(1))
                  .subscribe(() => {
                    this.loaderService.hide();
                    this.frmGroup.markAsPristine();

                    this.router.navigate(["events"]);
                  });
              }
            }
          });
        });
    }
  }

  createQuoteBusinessObj() {
    const quoteNumber = this.frmGroup.get(QuoteGeneralRequest.QuoteNumber)
      .value;
    const countryCode = this.frmGroup.get(QuoteGeneralRequest.Countries).value
      .CountryCode;
    const stateCode = this.frmGroup.get(QuoteGeneralRequest.StateOrProvinces)
      .value.StateCode;
    this.User1Id = this.frmGroup.get(QuoteGeneralRequest.Underwriters).value.Id;
    if (this.frmGroup.get(QuoteGeneralRequest.UnderwriterAssistants).value) {
      this.User2Id = this.frmGroup.get(
        QuoteGeneralRequest.UnderwriterAssistants
      ).value.Id;
    }

    this.quoteCreateRequest = new CreateNewQuoteModel();
    this.quoteCreateRequest.InsuredContact = new InsuredContact();
    this.quoteCreateRequest.InsuredContact.Addresses = new Address();
    this.quoteCreateRequest.NewBusinessSub = new NewBusinessSub();
    this.quoteCreateRequest.NewBusinessSub.CreateActivityLogItem = new CreateActivityLogItem();
    this.quoteCreateRequest.InsuredContact.Addresses.City = this.frmGroup.get(
      QuoteGeneralRequest.City
    ).value;
    this.quoteCreateRequest.InsuredContact.Addresses.ZipCode = this.frmGroup.get(
      QuoteGeneralRequest.ZipCode
    ).value;
    this.quoteCreateRequest.InsuredContact.Addresses.StreetAddress = this.frmGroup.get(
      QuoteGeneralRequest.Address
    ).value;
    this.quoteCreateRequest.InsuredContact.Addresses.AddressTypes = [3, 5];
    this.quoteCreateRequest.InsuredContact.Addresses.AddressId = this
      .insuredAddress
      ? this.insuredAddress
      : 0;
    this.quoteCreateRequest.InsuredContact.Addresses.CountryCode = countryCode;
    this.quoteCreateRequest.InsuredContact.Addresses.StateCode = stateCode;
    this.quoteCreateRequest.InsuredContact.ContactId = quoteNumber
      ? quoteNumber
      : null;
    this.quoteCreateRequest.InsuredContact.EntityTypeId = 4;
    this.quoteCreateRequest.InsuredContact.NameTypeId = 0;
    this.quoteCreateRequest.InsuredContact.OrgFullName = this.frmGroup.get(
      QuoteGeneralRequest.InsuredName
    ).value;
    this.quoteCreateRequest.NewBusinessSub.PolicyId = this.PolicyId
      ? this.PolicyId
      : 0;
    this.quoteCreateRequest.NewBusinessSub.PolicyTxnId = this.PolicyTxnId
      ? this.PolicyTxnId
      : 0;
    this.quoteCreateRequest.NewBusinessSub.PolicyTxnNum = this.PolicyTxnNum
      ? this.PolicyTxnNum
      : 0;
    this.quoteCreateRequest.NewBusinessSub.PolicyTermNum = this.PolicyTermNum
      ? this.PolicyTermNum
      : 0;
    this.quoteCreateRequest.NewBusinessSub.InsuredContactId = this
      .InsuredContactId
      ? this.InsuredContactId
      : 0;
    this.quoteCreateRequest.NewBusinessSub.PolicyProducerContactId = this.PolicyProducerContactId;
    this.quoteCreateRequest.NewBusinessSub.SubCompanyCode = "ECO";
    this.quoteCreateRequest.NewBusinessSub.PolicyTypeId = this.productTypeId;
    this.quoteCreateRequest.NewBusinessSub.TermTypeId = this.TermTypeId;
    this.quoteCreateRequest.NewBusinessSub.PolicyDescription = this
      .PolicyDescription
      ? this.PolicyDescription
      : "";
    this.quoteCreateRequest.NewBusinessSub.User1Id = this.User2Id;
    this.quoteCreateRequest.NewBusinessSub.User2Id = this.User1Id;
    this.quoteCreateRequest.NewBusinessSub.NextRenewalOptionId = this.NextRenewalOptionId;
    this.quoteCreateRequest.NewBusinessSub.SubmissionTypeId = this.SubmissionTypeId;
    this.quoteCreateRequest.NewBusinessSub.PolicyYear = this.PolicyYear
      ? this.PolicyYear
      : this.dateService.currentdate().getFullYear();
    this.quoteCreateRequest.NewBusinessSub.CountryCode = countryCode;
    this.quoteCreateRequest.NewBusinessSub.StateId = this.frmGroup.get(
      QuoteGeneralRequest.StateOrProvinces
    ).value.Id;
    this.quoteCreateRequest.NewBusinessSub.CurrencyCode = this.getLabels.currencyCode;
    this.quoteCreateRequest.NewBusinessSub.TxnPayPlanId = this.TxnPayPlanId;
    this.quoteCreateRequest.NewBusinessSub.TxnBillToContactId = 0;
    this.quoteCreateRequest.NewBusinessSub.PolicyEff = this.dateService.toLongISO(
      this.dateService.now()
    );
    this.quoteCreateRequest.NewBusinessSub.CreateActivityLogItem.ActivityType =
      ecaConstant.step1;
    this.quoteCreateRequest.NewBusinessSub.CreateActivityLogItem.CreationDate = this.dateService.currentdate();
    // this.quoteCreateRequest.NewBusinessSub.CreateActivityLogItem.Description =
    //   "Received Date changed with new one.";
    this.quoteCreateRequest.NewBusinessSub.CreateActivityLogItem.ActivityDate = this.dateService.currentdate();
    this.quoteCreateRequest.NewBusinessSub.CreateActivityLogItem.LoggedInUser = this.userName;
    return this.quoteCreateRequest;
  }
  ngOnDestroy() {
    if (this.storesubscribe) {
      this.storesubscribe.unsubscribe();
    }
    this.validationService.setFormSubmission(false);
  }

  cancelBackToDashboard($event) {
    this.router.navigate([AllowedRoute.Dashboard]);
  }
  // smart-street get address integration
  onChange(searchVal: any) {
    if (searchVal.target.value.length > 0) {
      this._smartyStreetSdkService
        .AutocompleteAddress(searchVal.target.value)
        .then(data => {
          if (data.result.length > 0) {
            this.addressOptions = data.result as any[];
            this.listHidden = true;
          }
          if (data.result.length == 0) {
            this.listHidden = false;
          }
        })
        .catch();
    } else {
      this.addressOptions = [];
      this.listHidden = false;
    }
  }
  getStreetAddrss(strretAddress, cityName, stateName) {
    this._smartyStreetSdkService
      .StreetAddress(strretAddress, "", "", cityName, stateName)
      .then(data => {
        if (data) {
          if (data.lookups) {
            data.lookups.forEach(lookup => {
              if (lookup.zipCode) {
                this.frmGroup
                  .get(QuoteGeneralRequest.ZipCode)
                  .setValue(lookup.zipCode);
              } else if (lookup.result.length == 0) {
                this.frmGroup.get(QuoteGeneralRequest.ZipCode).setValue("");
              } else {
                lookup.result.forEach(Candidate => {
                  if (Candidate.components.zipCode) {
                    const zipCode = Candidate.components.zipCode;
                    this.frmGroup
                      .get(QuoteGeneralRequest.ZipCode)
                      .setValue(zipCode);
                  } else {
                    this.frmGroup.get(QuoteGeneralRequest.ZipCode).setValue("");
                  }
                });
              }
            });
          } else {
            this.frmGroup.get(QuoteGeneralRequest.ZipCode).setValue("");
          }
        } else {
          this.frmGroup.get(QuoteGeneralRequest.ZipCode).setValue("");
        }
      });
  }
  // select highlighted item when enter is pressed or any item that is clicked
  selectAddress(addressValue, index) {
    this.addressData = addressValue;
    this.selectedIndex = index;
    const strretAddress = this.addressData.streetLine;
    const cityName = this.addressData.city;
    const stateName = this.addressData.state;
    this.getStreetAddrss(strretAddress, cityName, stateName);
    this.insuredAddressInfoField.forEach(field => {
      //* uncomment once pro Api get
      const { StateOrProvinces, Countries } = this.masterData.getMasterData();
      if (field.fieldInfo.fieldName == QuoteGeneralRequest.StateOrProvinces) {
        if (StateOrProvinces) {
          StateOrProvinces.forEach(items => {
            if (items.Code == this.addressData.state && items.CountryCode=='USA') {
              this.frmGroup.get(QuoteGeneralRequest.StateOrProvinces).setValue({
                Description: items.Description,
                StateCode: items.Code,
                Id: items.Id
              });
            }
          });
        }
      }
      if (field.fieldInfo.fieldName == "city") {
        this.frmGroup
          .get(field.fieldInfo.fieldName)
          .setValue(this.addressData.city);
      }
      if (field.fieldInfo.fieldName == "Countries") {
        Countries.forEach(items => {
          if (items.Id == "USA") {
            this.frmGroup.get(QuoteGeneralRequest.Countries).setValue({
              CountryCode: items.Id,
              Description: items.Description
            });
          }
        });
      }
    });
    this.frmGroup
      .get(QuoteGeneralRequest.Address)
      .setValue(this.addressData.streetLine);
    this.listHidden = false;
  }
  onKeyPress(event) {
    if (!this.listHidden) {
      if (event.key === "Escape") {
        this.selectedIndex = -1;
      }
      if (event.key === "ArrowDown") {
        this.selectedIndex = this.selectedIndex + 1;
        document
          .getElementsByTagName("list-item")
          [this.selectedIndex].scrollIntoView();
      } else if (event.key === "ArrowUp") {
        this.selectedIndex = this.selectedIndex - 1;
        document
          .getElementsByTagName("list-item")
          [this.selectedIndex].scrollIntoView();
      }
    }
  }

  nextToAfterBind() {
    this.router.navigate(["/events"]);
  }
}
