<div class="container-fluid">
    <!-- <h5 class="header-tab mt-2">{{ searchByValueHeading }}</h5> -->
    <h5 class="header-tab mt-2">KnR Policy List</h5>
    <div class="row">
      <div class="col-md-12">
        <div id="eco-search-result">
            <div
            *ngIf="quotePolicyList.length > 0"
          >
            <ac-grid-table
              (childEventForNavigation)="onViewPolicyDetailClick($event)"
              [parentDivName]="'knrquotePolicySearch'"
              [gridSetting]="gridSetting"
              [records]="quotePolicyList"
              [columnSettings]="tblSettingsForKnrQuotePolicyDetails"
            ></ac-grid-table>
          </div>
        </div>
     </div>
    </div>
</div>
