export class DialogConfig<D = any> {
  data?: D;
  modalSetting?: {
    hideHeader?: boolean;
    hideFooter?: boolean;
    title?: string;
    hideHeaderX?: boolean; // header X button;
    hideSave?: boolean;
    overflow?: boolean;
    SaveButton?: string;
    CancelButton?: string;
    dialogSize?: string;
  };
}
