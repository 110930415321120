import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Input,
  Output,
  ViewChildren,
  QueryList,
  EventEmitter,
  AfterViewInit
} from "@angular/core";
import { PageTabItem } from "@tmhcc/framwork/models";
import { PageTabItemDirective } from "./page-tab-item.directive";
import { Router } from "@angular/router";
import { TabRoutes } from "./page-tab.model";
import { AllowedRoute } from "@tmhcc/eco/src/lib/shared/page-header/page-header-navigation/page-header-navigation-def";

@Component({
  selector: "ac-page-tab-item",
  templateUrl: "./page-tab-item.component.html",
  styleUrls: ["./page-tab-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageTabItemComponent implements OnInit, AfterViewInit {
  @Input() useRouter = false;

  @Input() data: PageTabItem[] = [];
  @Input() selectedId: any;
  @ViewChildren(PageTabItemDirective) items: QueryList<PageTabItemDirective>;
  @Output() itemClicked = new EventEmitter<{
    item: PageTabItem;
    index: number;
  }>();
  autoSetFirstItem = false;

  constructor(private router: Router) {}
  TabRoutes;

  ngOnInit(): void {
    const path = this.router.url;
    if (this.data && this.data.length === 2) {
      if (path === AllowedRoute.Events) {
        this.selectedId = this.data[0].id;
      } else if (path === AllowedRoute.Document) {
        this.selectedId = this.data[1].id;
      }
    } else if (path) {
      if (path === TabRoutes.Quote) {
        this.selectedId = this.data[0].id;
      } else if (path === TabRoutes.Subjectivity) {
        this.selectedId = this.data[1].id;
      } else if (path === TabRoutes.Document) {
        this.selectedId = this.data[2].id;
      }
    } else if (!this.selectedId && this.data.length) {
      this.selectedId = this.data[0].id;
    }
  }

  click(item: PageTabItem, index: number) {
    this.itemClicked.emit({ item, index });
  }

  ngAfterViewInit(): void {
    //this.init();
  }
}
