import {
  Directive,
  forwardRef,
  HostBinding,
  Renderer2,
  ElementRef,
  ChangeDetectorRef,
  Attribute,
  HostListener
} from "@angular/core";
import { TextFormElement } from "../text/text-form-element";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Directive({
  selector: "input[acInputDate]",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDateDirective),
      multi: true
    }
  ]
})
export class InputDateDirective extends TextFormElement {
  @HostBinding("autocomplete") autocomplete = "off";
  @HostListener("bsValueChange", ["$event"])
  onchange(event) {
    this.change(event);
  }
  constructor(
    protected renderer: Renderer2,
    protected el: ElementRef,
    protected csRef: ChangeDetectorRef,
    @Attribute("updateEvent") protected updateEvent
  ) {
    super(renderer, el, csRef, updateEvent);
  }
}
