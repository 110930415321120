import { isEmpty as isEmptyUtil } from "@tmhcc/util";

export function isEmpty(value?: any) {
  return isEmptyUtil(value);
}

export function isAllNullOrUndefined(...params: any[]) {
  if (isEmpty(params)) {
    return true;
  }

  for (const param of params) {
    if (!isNullOrUndefined(param)) {
      return false;
    }
  }

  return true;
}

export function isAnyEmpty(...params: any[]) {
  if (isEmpty(params)) {
    return true;
  }

  for (const param of params) {
    if (typeof param === "string") {
      if (isEmpty(param)) {
        return true;
      }
    } else {
      if (isNullOrUndefined(param)) {
        return true;
      }
    }
  }

  return false;
}

export function isNullOrUndefined(val: any) {
  if (val === undefined || val === null) {
    return true;
  }

  return false;
}

export function isNullUndefinedEmptyOrWhiteSpace(val: string) {
  if (val === undefined || val === null) {
    return true;
  }

  if (typeof val === "string") {
    if (val === "") {
      return true;
    } else if (val && val.trim() === "") {
      return true;
    }
  }

  return false;
}

export function isNullUndefinedEmptyOrZero(val: number | string) {
  if (val === undefined || val === null) {
    return true;
  }

  if (typeof val === "string") {
    if (val === "" || val === "0") {
      return true;
    }
  } else if (typeof val === "number") {
    if (val.toString() === "" || val.toString() === "0") {
      return true;
    }
  } else {
    return true;
  }

  return false;
}
