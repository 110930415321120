import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { LoggingService } from "@tmhcc/framwork/logging";
import { ErrorService } from "@tmhcc/framwork/services";
import { ToastrService } from "ngx-toastr";
import { createGuid } from "@tmhcc/util";
import { LoaderService } from "@tmhcc/framwork/loader";

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: HttpErrorResponse) {
    const loader = this.injector.get(LoaderService);
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    const notifier = this.injector.get(ToastrService);

    let message;
    let stackTrace;
    let alertMessage = "Something went wrong, please try again";

    if (error instanceof HttpErrorResponse) {
      // Server Error
      message = errorService.getServerMessage(error);
      stackTrace = errorService.getServerStack(error);
    } else {
      // Client Error
      message = errorService.getClientMessage(error);
      stackTrace = errorService.getClientStack(error);
    }
    loader.hide();
    notifier.error(alertMessage, "", {
      positionClass: "toast-top-right",
      progressBar: true,
      timeOut: 5000,
      tapToDismiss: true
    });

    let prop = {
      Tenant: "TMHHC CLAIM",
      ErrorId: createGuid(),
      ApplicationPlatform: "Web.Frontend",
      Component: "",
      Method: ""
    };
    logger.logException(new Error(stackTrace), prop);
  }
}
