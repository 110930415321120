import { Injectable } from "@angular/core";
import { FieldTypes } from "@tmhcc/framwork/form-inputs";
import { Validators } from "@angular/forms";
import { CommonContentService } from "@tmhcc/content-service";
import { DateService } from "@tmhcc/framwork/services";
import { BsDatepickerDirective } from "ngx-bootstrap/datepicker";
@Injectable()
export class quoteSummaryDefinition {
  constructor(
    public commonContentService: CommonContentService,
    public dateService: DateService
  ) {}

  quoteSummaryTaxFillConfig = {
    quoteTaxFieldConfig: [
      {
        fieldInfo: {
          fieldName: "fillingResponsibility",
          label: "Filing Responsibility",
          fieldType: FieldTypes.LookupList,
          showLabel: false,
          validation: {
            required: "Please select"
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: ["Broker", "HCC"],
            optionConfig: {
              displayKey: "Description",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      }
    ]
  };
  taxStateConfig = {
    taxStateFieldConfig: [
      {
        fieldInfo: {
          fieldName: "TaxState",
          label: "Tax State",
          fieldType: FieldTypes.LookupList,
          showLabel: false,
          validation: {
            required: "Please select State"
          }
        },
        config: {
          validation: [Validators.required],
          selectConfig: {
            selectedOption: [],
            options: ["Test"],
            optionConfig: {
              displayKey: "Description",
              search: true,
              limitTo: 3
            },
            multiple: false
          }
        }
      }
    ]
  };
  // Insured Address field
  quoteSummaryDownloadConfig = {
    downloadFieldConfig: [
      {
        fieldInfo: {
          fieldName: "DownloadDescription",
          label: "Download Description",
          fieldType: FieldTypes.Text,
          showLabel: true,
          validation: {
            required: "Please enter Quote Description"
          }
        },
        config: {
          validation: [Validators.required]
        }
      },
      {
        config: {
          datePickerConfig: {
            dateInputFormat: this.dateService.getFormat(),
            isCalanderRequired: true,
            // minDate: this.mindate,
            outSideClick: false
          },
          optionField: 4,
          validation: [Validators.required]
        },
        fieldInfo: {
          fieldName: "eventStartDate",
          label: "Quote Expiration Date",
          decimal: 0,
          fieldType: FieldTypes.Date,
          showLabel: true,
          value: "",
          validation: {
            required: this.commonContentService.getLabels()
              .esdRequiredValidationLbl
          }
        },
        colClass: "col-md-3"
      }
    ]
  };
}
