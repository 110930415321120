import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/internal/Observable";
import { BaseDataService } from "./base-data-service";
import { map, catchError } from "rxjs/operators";
import { AppConfigService } from "@tmhcc/global-services";
import { DateService } from "@tmhcc/framwork/services";
import { CommonContentService } from "@tmhcc/content-service";
import { DownloadQuoteECO } from "@tmhcc/models";
import {
  DialogConfig,
  DialogService,
  DialogSize
} from "@tmhcc/framwork/dialog/src";
import { ValidationDialogComponent } from "@tmhcc/eco/src/lib/quote/events/validation-dialog/validation-dialog.component";
import { UpdateAndDownloadQuoteECO } from ".";

@Injectable()
export class DownloadQuoteService extends BaseDataService {
  constructor(
    protected http: HttpClient,
    protected configService: AppConfigService,
    private dateService: DateService,
    private dialogConfig: DialogConfig<any>,
    private dialogService: DialogService,
    private commonContentService: CommonContentService
  ) {
    super(http, configService);
  }

  updateAndDownloadQuoteECO(
    payload: UpdateAndDownloadQuoteECO
  ): Observable<DownloadQuoteECO> {
    const url = `${this.serviceBaseUrl}/UpdateAndDownloadQuoteECO`;
    return this.post<DownloadQuoteECO>(url, { body: payload }).pipe(
      map(data => {
        if (data.Status === "FAILURE") {
          this.showFailure();
        }
        return this.mapResponse(data);
      }),
      catchError(this.handleError)
    );
  }

  showFailure() {
    const content = {
      value: "Something went wrong."
    };
    this.dialogConfig.modalSetting = {
      title: "Error",
      overflow: true,
      hideFooter: true,
      dialogSize: DialogSize.LG,
      hideSave: true
    };
    this.dialogConfig.data = content;
    const ref = this.dialogService.open(
      ValidationDialogComponent,
      this.dialogConfig
    );
    ref.afterClosed.subscribe(result => {
      if (!result) {
      } else {
      }
    });
  }

  mapResponse(data: DownloadQuoteECO): DownloadQuoteECO {
    let downloadQuoteECO = new DownloadQuoteECO();
    downloadQuoteECO.Status = data.Status;
    downloadQuoteECO.Exception = data.Exception;
    return downloadQuoteECO;
  }
}
