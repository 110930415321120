import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GridTableComponent } from "./grid-table/grid-table.component";
import { CustomGridTableComponent } from "./custom-grid-table/custom-grid-table.component";
import { DataTablesModule } from "angular-datatables";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CustomService } from "./shared/grid-form.service";
import { PipesModule } from "@tmhcc/framwork/pipes";
import { InputTextModule } from "@tmhcc/framwork/form-inputs";
import { CoreInputsModule } from "@tmhcc/framwork/core-inputs";
@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    DataTablesModule,
    PipesModule,
    InputTextModule,
    CoreInputsModule
  ],
  declarations: [GridTableComponent, CustomGridTableComponent],
  exports: [
    GridTableComponent,
    CustomGridTableComponent,
    FormsModule,
    ReactiveFormsModule,
    PipesModule
  ],
  providers: [CustomService]
})
export class GridModule {}
