import {
  isFunction,
  isNull,
  isUndefined,
  isString,
  isArray,
  isDate,
  isObject
} from "@tmhcc/util";

export function hasValue(val: any, checkEmpty: boolean = true): boolean {
  if (isNull(val) || isUndefined(val)) {
    return false;
  }

  if (checkEmpty) {
    if (isFunction(val)) {
      return true;
    }

    if (isString(val)) {
      return val !== "";
    }

    if (isArray(val)) {
      return val.length > 0;
    }

    if (isDate(val)) {
      return true;
    }

    if (isObject(val)) {
      return Object.getOwnPropertyNames(val).length > 0;
    }
  }

  return true;
}
